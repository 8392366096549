import React from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setInstanceUser } from '../../store/slices/instanceSlice';
import { Formik, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import UserAPIs from '../../APIs/UserAPIs';
import OtherAPIs from '../../APIs/OtherAPIs';
import Tools from '../../helpers/Tools';
import cookieOptions from '../../configs/cookieOptions';
import {
    Button,
    Typography,
    Alert,
    Stack,
    Paper,
    FormLabel,
} from '@mui/material';
import FormikInput from '../FormikComponent/FormikInput';
import ActivitySectors from './ActivitySectors';
import Spinner from '../Spinner/Spinner';
import UploadAvatar from './UploadAvatar';
import EAccountTypes from '../../interfaces/EAccountTypes';
import IUploadFile from '../../models/IUploadFile';
import IUser from '../../models/IUser';

const ProfileForm: React.FC = () => {
    // Use of hooks
    const [alert, setAlert] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<IUser|null>(null);
    const [initialValues, setInitialValues] = React.useState<any>(null);
    const selectedActivitySectors = React.useRef<Array<number>>([]);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);
    const instanceAccountTypeSlug: string|undefined = useAppSelector(state => state.instance.account?.accountType.slug);

    // Use of react-cookie hooks
    const [cookie, setCookie] = useCookies(['instance']);

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        bio: Yup.string()
            .test(
                'min',
                'La présentation doit faire au minimum 250 mots',
                (bio: string|undefined) => EAccountTypes.EDITOR !== instanceAccountTypeSlug || (undefined !== bio && 250 <= Tools.countWords(bio)),
            )
            .test(
                'max',
                'La présentation doit faire au maximum 500 mots',
                (bio: string|undefined) => EAccountTypes.EDITOR !== instanceAccountTypeSlug || (undefined !== bio && 500 >= Tools.countWords(bio)),
            ),
        hasNewsletter: Yup.bool(),
        pricePerWord: Yup.number(),
    });

    // useEffect when component is mounted
    React.useEffect(() => {
        // Call API to get User information
        null === user &&
            UserAPIs.getUser({ id: instanceUserId })
                // On successful API call
                .then(response =>
                    null !== response && response.data[0] &&
                        setUser(response.data[0])
                );
    }, [user, instanceUserId]);

    // useEffect when component is mounted
    React.useEffect(() => {
        if (null !== user) {
            // Set default selected activity sectors
            selectedActivitySectors.current = user.activitySectors?.map(activitySector => activitySector.id) ?? [];
            // Setup initial values of formik form
            setInitialValues({
                avatar: user.avatar ?? undefined,
                bio: user.bio ?? '',
                hasNewsletter: user.hasNewsletter ?? false,
                pricePerWord: user.pricePerWord ?? undefined,
            });
        }
    }, [user]);

    // Callback used to safely upload avatar on our API & update formik values
    const safelyUploadAvatar = React.useCallback(async (values: any) => {
        // Retrieve avatar from formik values
        const avatar = values.avatar;

        // If an avatar was choosen
        if (
            null !== avatar &&
            (avatar instanceof File || avatar instanceof Blob) &&
            avatar.type.includes('image')
        ) {
            // Await API call to upload the avatar
            await OtherAPIs.postUploadFile(avatar)
                // On successful call
                .then((data: IUploadFile) => {
                    // Set avatar value with path of the uploaded avatar
                    values.avatar = data.url;
                });
        }
    }, [])

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback(async (values: any) => {
        if (null !== user) {
            // Await upload of avatar
            await safelyUploadAvatar(values);

            // In any cases, patch user
            UserAPIs.patchUser(user.customerId, Tools.convertToBodyRequest({ ...values, activitySectors: selectedActivitySectors.current }))
                // On successful call
                .then((data: IUser|null) => {
                    if (null !== data) {
                        // Update user hook value
                        setUser(data);
                        // Show success alert
                        setAlert(true);
                        // Scroll to top
                        Tools.scrollInDashboard('top');
                        // Store user name, lastname and avatar in redux
                        dispatch(setInstanceUser({
                            avatar: data.avatar ?? undefined,
                            bio: '' !== values.bio,
                        }));
                        // Change the avatar value in cookie if it exists
                        cookie.instance &&
                            setCookie('instance', {
                                ...cookie.instance,
                                user: {
                                    ...cookie.instance.user,
                                    avatar: data.avatar,
                                    bio: '' !== values.bio,
                                },
                            }, cookieOptions);
                    }
                });
        }
    }, [user, cookie.instance, safelyUploadAvatar, dispatch, setCookie]);

    // useMemo on ActivitySectors to prevent Formik rerenders
    const activitySectors = React.useMemo(() => (
        <ActivitySectors
            selected={user?.activitySectors?.map(activitySector => activitySector.id) ?? []}
            onSelectionChanges={selected => selectedActivitySectors.current = selected}
        />
    ), [user]);

    return (
        null !== user && null !== initialValues ? (
            <>
                {
                    alert && (
                        <Alert sx={{ marginBottom: 3 }} onClose={() => setAlert(false)}>
                            {`Votre profil a été mis à jour avec succès.`}
                        </Alert>
                    )
                }
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormikSubmit}
                >
                    {(formikProps: FormikProps<any>) => (
                        <Form>
                            <Paper sx={{ padding: 5, marginBottom: 3, position: 'relative' }}>
                                <Stack direction={{ xs: 'column', md: 'row' }} columnGap={3} rowGap={3}>
                                    <Stack direction={{ xs: 'row', md: 'column' }} columnGap={3}>
                                        <UploadAvatar
                                            imageSrc={formikProps.values.avatar}
                                            imageAlt={`${user.name.charAt(0)}${user.lastname.charAt(0)}`}
                                            onChange={(image: File) => formikProps.setFieldValue('avatar', image)}
                                        />
                                        <Stack>
                                            <Typography variant='h6' component='p' fontWeight='700'>
                                                {user.username}
                                            </Typography>
                                            <Typography >
                                                {`${user.name} ${user.lastname}`}
                                            </Typography>
                                            <Typography fontStyle='italic' gutterBottom>
                                                {user.email}
                                            </Typography>
                                            <Typography variant='body2'>
                                                {
                                                    instanceAccountTypeSlug ?
                                                        `Compte ${instanceAccountTypeSlug}`
                                                        : 'Développeur'
                                                }
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack rowGap={3}>
                                        <FormikInput
                                            upperLabel='Tarif au mot en centimes'
                                            type='number'
                                            name='pricePerWord'
                                            placeholder='1.4 cents €/mot'
                                            numberFormatProps={{
                                                suffix: ' cents €/mot',
                                                decimalScale: 1,
                                            }}
                                            helperText={`Ce tarif est purement indicatif et peut être un critère de sélection par les donneurs d'ordres.`}
                                        />
                                        <FormikInput
                                            upperLabel='Présentez-vous (entre 250 et 500 mots)'
                                            name='bio'
                                            multiline
                                            minRows={3}
                                            maxRows={15}
                                            countWords
                                            helperText={
                                                <>
                                                    <Typography variant='caption' component='p'>
                                                        {`Cette présentation peut être un critère de sélection par les donneurs d'ordres.`}
                                                    </Typography>
                                                    <Typography variant='caption' component='p'>
                                                        {`Elle sera affichée sur votre profil et sur toutes les pages de recherche des rédacteurs.`}
                                                    </Typography>
                                                    <Typography variant='caption' component='p'>
                                                        {`Pensez à soigner votre présentation (contenu, orthographe...) car celle-ci représentera vos compétences et votre sérieux.`}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                        <Stack>
                                            <FormLabel>
                                                {`Vos secteurs d'activités :`}
                                            </FormLabel>
                                            <Stack rowGap={1} columnGap={1}>
                                                {activitySectors}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Typography variant='caption' color='textSecondary' position='absolute' bottom={5} left={15}>
                                    {`N° client : ${Tools.getIdentifierFromUuid(user.customerId)}`}
                                </Typography>
                            </Paper>
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={!formikProps.isValid}
                            >
                                {`Sauvegarder les changements`}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </>
        ) : (<Spinner />)
    );
};

export default ProfileForm;
