import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { Button } from '@mui/material';
import ICsvData from '../../interfaces/ICsvData';

const ValidateButton: React.FC<{ onValidation: (data: ICsvData) => void }> = props => {
    // Use of redux
    const sortedData = useAppSelector(state => state.importCsv.sortedData);

    return (
        <Button
            variant='contained'
            color='success'
            sx={{ marginLeft: 'auto' }}
            onClick={() => sortedData && props.onValidation(sortedData)}
        >
            {`Terminer`}
        </Button>
    );
};

export default ValidateButton;