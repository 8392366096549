import React from 'react';
import { Tooltip, Box } from '@mui/material';
import RouterLink from '../RouterLink/RouterLink';

const MissingBioTooltip: React.FC<{ wrapWithSpan?: boolean|undefined, followCursor?: boolean|undefined }> = props => {
    return (
        <Tooltip
            followCursor={props.followCursor}
            title={
                <>
                    {`Votre profil est incomplet. Certaines fonctionnalitées sont désactivées.
                    Pour profiter pleinement de ${process.env.REACT_APP_NAME}, veuillez écrire une présentation
                    sur votre profil. `}
                    <RouterLink
                        anchor='Cliquez ici'
                        to='/dashboard/account/user/form'
                        sx={{ pointerEvents: 'unset' }}
                    />
                    {` pour accèder à votre profil.`}
                </>
            }
        >
            {
                props.wrapWithSpan ? (
                    <Box component='span'>
                        {props.children as JSX.Element}
                    </Box>
                ) : (props.children as JSX.Element)
            }
        </Tooltip>
    );
};

export default MissingBioTooltip;
