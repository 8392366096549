import React from 'react';
import Typography from '@mui/material/Typography';

const ToastMessage: React.FC<{ title: string, message: string }> = props => {
    return (
        <>
            <Typography variant='body1'>{props.title}</Typography>
            <Typography variant='body2'>{props.message}</Typography>
        </>
    );
};

export default ToastMessage;
