import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    title: {
        display: 'flex',
        alignItems: 'flex-end',
        color: 'primary.main',
        fontWeight: 700,
        paddingLeft: 4,
        height: 0.7/10,
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 4,
        height: 9.3/10,
    },
    /** ************************* PANEL RATINGS ************************* */
    backgroundRatingBox: {
        borderRadius: 10,
        transform: 'skew(10deg, -15deg)',
        paddingY: 1.5,
        marginY: 2,
        width: 150,
    },
    ratingBox: {
        alignItems: 'center',
        backgroundColor: 'whitesmoke',
        borderRadius: 10,
        transform: 'skew(-10deg, 8deg)',
        justifyContent: 'space-between',
        paddingY: 4,
        paddingX: 3,
        height: 1,
        maxWidth: 1,
        minHeight: '20vh',
        '&>*': {
            transform: 'skewY(7deg)',
        },
    },
    /** ************************* PANEL COUNTDOWNS ************************* */
    countdownText: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 3,
        height: '5vh',
        fontWeight: 700,
        zIndex: 1,
        fontSize: {
            xs: '3vh',
        },
    },
    /** ************************* PANEL OVERVIEW ************************* */
    postNumber: {
        color: 'white',
        fontWeight: 900,
        width: '7vh',
        height: '7vh',
        fontSize: '1.7rem',
    },
    /** ************************* PANEL FUNDS ************************** */
    unlockedAmount: {
        fontWeight: 700,
        lineHeight: 1,
        paddingBottom: '1vh',
        fontSize: {
            xs: '6vh',
        },
    },
    /** ************************* PANEL WALLET ************************* */
    incomeCircle: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'whitesmoke',
        borderRadius: '100%',
        minWidth: 80,
        minHeight: 80,
        width: '8.2vw',
        height: '8.2vw',
        border: '5px solid',
    },
    gainText: {
        fontWeight: 700,
        lineHeight: 1,
        fontSize: {
            xs: '2.5vh',
        },
    },
    whenText: {
        fontSize: {
            xs: '2vh',
        },
    },
    howManyText: {
        paddingTop: '1vh',
        color: 'secondary.main',
        fontSize: {
            xs: '2vh',
        },
    },
};

export default cssStyles;
