import React from 'react';
import useValidationSchema from '../../hooks/useValidationSchema';
import * as Yup from 'yup';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import { Divider, Typography } from '@mui/material';
import FinalForm from '../FinalForm/FinalForm';
import FinalInput from '../FinalComponents/FinalInput';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';

const CurrentRankingTool: React.FC = () => {
    // Use of hooks
    const [ranking, setRanking] = React.useState<number|null|undefined>(undefined);

    // Define validation schema of form
    const validationSchema = useValidationSchema({
        url: Yup
            .string()
            .required("Veuillez renseigner l'URL à tester"),
        keyword: Yup
            .string()
            .required("Veuillez renseigner le mot-clé sur lequel tester votre URL")
    });

    // Callback used to submit form
    async function onSubmit(values: any) {
        // Call API to retrieve current ranking
        return BridgeAPIs.getCurrentRanking(values.url, values.keyword)
            // On successful API call
            .then((data: any) => null !== data && setRanking(data.ranking))
    };

    return (
        <>
            <FinalForm
                initialValues={{ url: '', keyword: '' }}
                validateSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <FinalInput
                    name='url'
                    upperLabel
                    label='URL'
                    placeholder='https:// ...'
                    fullWidth
                    wrapperSx={{ paddingBottom: 3 }}
                />
                <FinalInput
                    name='keyword'
                    upperLabel
                    label='Mot-clé'
                    fullWidth
                    wrapperSx={{ paddingBottom: 3 }}
                />
                <FinalSubmitButton
                    variant='contained'
                    children='Récupérer le positionnement'
                />
            </FinalForm>
            {
                undefined !== ranking && (
                    <>
                        <Divider sx={{ marginY: 4 }} />
                        <Typography>
                            {`Positionnement de votre URL : `}
                            <Typography component='span' fontWeight={700}>
                                {ranking ?? `Non placé`}
                            </Typography>
                        </Typography>
                    </>
                )
            }
        </>
    );
};

export default CurrentRankingTool;
