import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import {
    Stack,
    IconButton,
    Box,
    InputBase,
} from '@mui/material';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

const PasswordRenderer: React.FC<ICellRendererParams> = props => {
    // Use of hooks
    const [visible, isVisible] = React.useState<boolean>(false);

    return (
        props.value ? (
            <Stack direction='row' alignItems='center' spacing={1} width={1}>
                <IconButton onClick={() => isVisible(!visible)}>
                    {
                        visible ?
                            (<VisibilityOffIcon fontSize='small' color='disabled' />)
                            : (<VisibilityIcon fontSize='small' color='disabled' />)
                    }
                </IconButton>
                {
                    visible ? (
                        <Box component='span' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {props.value}
                        </Box>
                    ) : (
                        <InputBase
                            type='password'
                            value='############'
                            size='small'
                            margin='none'
                            readOnly
                            inputProps={{ sx: { padding: 0 } }}
                        />
                    )
                }
            </Stack>
        ): null
    );
};

export default PasswordRenderer;
