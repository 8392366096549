import React from 'react';
import { Visibility } from '@mui/icons-material';
import { Box, Stack, Tooltip, Typography } from '@mui/material';

const TemplateImage: React.FC<{
    templateName: string,
    imagePath: string,
    selected: boolean,
    templateUrl: string,
    onClick: () => void,
}> = props => (
    <Stack alignItems='center'>
        <Typography variant='h6' color={props.selected ? 'secondary.main' : undefined}>{props.templateName}</Typography>
        <Box
            component='img'
            src={props.imagePath}
            alt={props.templateName}
            sx={(theme) => ({
                width: '100%',
                height: 350,
                objectFit: 'cover',
                objectPosition: 'top',
                border: `${props.selected ? theme.palette.secondary.main : 'black'} 2px solid`,
                cursor: 'pointer',
            })}
            onClick={() => props.onClick()}
        />
        <Tooltip title='Voir le thème' placement='right-start'>
            <Visibility
                sx={{
                    width: 50,
                    paddingBottom: 0.5,
                    backgroundColor: props.selected ? 'secondary.main' : 'black',
                    borderBottomRightRadius: '50%',
                    borderBottomLeftRadius: '50%',
                    cursor: 'pointer',
                    fill: 'white',
                }}
                onClick={() => window.open(props.templateUrl, '_blank', 'noopener,noreferrer')}
            />
        </Tooltip>
    </Stack>
);

export default TemplateImage;
