export default interface IPeriodicityType {
    label: string,
    value: string,
    display: string,
    // The number of hours in the periodicity
    periodicity: number,
};

export const periodicityTypes: Array<IPeriodicityType> = [
    { label: 'Heure', value: 'hour', display: 'toutes les heures', periodicity: 1 },
    { label: 'Jour', value: 'day', display: 'tous les jours', periodicity: 24 },
    { label: 'Semaine', value: 'week', display: 'toutes les semaines', periodicity: 168 },
    { label: 'Mois', value: 'month', display: 'tous les mois', periodicity: 730 },
    { label: 'Année', value: 'year', display: 'tous les ans', periodicity: 8760 },
];
