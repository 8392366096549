import { Theme } from '@mui/material';
import { appMenuWidthOpen, appMenuWidthClose } from '../../configs/materialUI';
import ICssStyles from '../../interfaces/ICssStyles';

const makeCssStyles = (theme: Theme): ICssStyles => {
    // Define transitions constantes
    const transitionEnteringScreen: string = theme.transitions.create(['width', 'margin', 'width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    });
    const transitionLeavingScreen: string = theme.transitions.create(['width', 'margin', 'width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    });

    return {
        dashboardMenu: {
            position: 'relative',
            whiteSpace: 'nowrap', // Prevent awkward animation on open/close menu
            overflow: 'hidden', // Prevent awkward animation on open/close menu
            backgroundColor: 'primary.main',
            border: 'none',
            borderRadius: 0,
            paddingY: 2,
            color: 'white',
        },
        dashboardMenuOpen: {
            width: appMenuWidthOpen,
            transition: transitionEnteringScreen,
        },
        dashboardMenuClose: {
            width: appMenuWidthClose,
            transition: transitionLeavingScreen,
        },
        collapseMenuSvg: {
            width: 20,
            height: 20,
            cursor: 'pointer',
            color: 'white',
        },
        appInfo: {
            marginTop: 2,
            marginX: 2,
            cursor: 'pointer',
        },
        avatarMenuOpen: {
            width: 100,
            height: 100,
            transition: transitionEnteringScreen,
        },
        avatarMenuClose: {
            width: 35,
            height: 35,
            transition: transitionLeavingScreen,
        },
        listItem: {
            paddingX: 2,
            color: 'white',
            fontWeight: 700,
            '&:hover, &:is(.active)': {
                '&>.iconWrapper': {
                    backgroundColor: 'secondary.main',
                },
            },
        },
        listItemWrapperIcon: {
            padding: '6px',
            borderRadius: 50,
            backgroundColor: 'transparent',
            transition: 'background-color ease-in-out 0.2s',
        },
        listItemIcon: {
            width: 23,
            height: 23,
        },
        listItemText: {
            fontSize: '0.95rem',
            margin: 0,
            paddingLeft: 2,
        },
        navFooter: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 'auto',
            paddingX: 2,
            color: 'lightgray',
        },
        cgv: {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    };
};

export default makeCssStyles;
