import React from 'react';
import { useTheme } from '@mui/material';
import { Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import Page from './Page';
import Footer from './Footer';
import ICompany from '../../models/ICompany';
import IUser from '../../models/IUser';

const CompanyPage: React.FC<{ user: IUser, company: ICompany, style?: Style|undefined }> = props => {
    // Create css styles using theme & props
    const theme = useTheme();

    return (
        <Page style={props.style}>
            {props.children}
            <Footer style={{ textAlign: 'center', fontSize: 8, color: theme.palette.text.secondary }}>
                <Text>
                    {`${props.company.name}  -  `}
                    {`${props.company.address} ${props.company.postalCode} ${props.company.city}  -  `}
                    {`Tél. : ${props.company.phone ?? `${props.user.phonePrefix}${props.user.phone}`}  -  `}
                    {`Email : ${props.company.email ?? props.user.email}  -  `}
                    {props.company.website && `Site web : ${props.company.website}  -  `}
                    {`Siret : ${props.company.siret}`}
                </Text>
            </Footer>
        </Page>
    );
};

export default CompanyPage;
