import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import {
    Button,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import GenericModal from './GenericModal';
import IOrder from '../../models/IOrder';

const orderRefuseReasons: Array<string> = [
    'Pas le temps de réaliser la mission dans les délais impartis',
    'Le tarif ne correspond pas à la mission',
    "Le contenu de la mission n'est pas approprié à mes secteurs d'activité",
    'Autre',
];

const ModalRefuseOrder: React.FC<{
    name?: string|undefined,
    onValidation: (order: IOrder, reasons: Array<string>, message: string|undefined) => void,
}> = props => {
    // Use of hooks
    const [refuseReasons, setRefuseReasons] = React.useState<Array<string>>([]);
    const [messageValue, setMessageValue] = React.useState<string>('');
    const modalName = React.useRef<string>(props.name ?? 'refuseOrderModal');

    // Use of redux
    const dispatch = useAppDispatch();
    const order: IOrder|null = useAppSelector(state => state.activeModal.params);

    return (
        <GenericModal
            title="Confirmer votre choix"
            name={modalName.current}
        >
            <>
                <Typography gutterBottom>
                    {`Veuillez sélectionner une ou plusieurs raisons de refus :`}
                </Typography>
                <Stack rowGap={2}>
                    <Stack paddingX={2}>
                        <FormGroup>
                            {
                                orderRefuseReasons.map((reason, index) => (
                                    <FormControlLabel
                                        key={index}
                                        label={reason}
                                        name={reason}
                                        control={
                                            <Checkbox onChange={(event, checked) => setRefuseReasons(reasons => 
                                                true === checked ?
                                                    [ ...reasons, event.target.name ]
                                                    : reasons.filter(reason => reason !== event.target.name)
                                            )} />
                                        }
                                    />
                                ))
                            }
                        </FormGroup>
                        <TextField
                            label='Message de refus'
                            variant='outlined'
                            error={refuseReasons.includes('Autre') && '' === messageValue}
                            multiline
                            fullWidth
                            maxRows={3}
                            onChange={(event) => setMessageValue(event.target.value)}
                        />
                        {
                            refuseReasons.includes('Autre') && '' === messageValue && (
                                <FormHelperText error>
                                    {`Le message de refus est obligatoire lorsque vous avez sélectionné la raison "Autre".`}
                                </FormHelperText>
                            )
                        }
                    </Stack>
                    <Divider />
                    <Stack direction='row' justifyContent='space-between'>
                        <Button
                            color='secondary'
                            variant='outlined'
                            onClick={() => {
                                setRefuseReasons([]);
                                setMessageValue('');
                                dispatch(closeModal());
                            }}
                        >
                            {`Retour`}
                        </Button>
                        <Button
                            color='error'
                            variant='outlined'
                            disabled={0 === refuseReasons.length || (refuseReasons.includes('Autre') && '' === messageValue)}
                            onClick={() => {
                                props.onValidation(order!, refuseReasons, messageValue);
                                setRefuseReasons([]);
                                setMessageValue('');
                                dispatch(closeModal());
                            }}
                        >
                            {`Confirmer le refus`}
                        </Button>
                    </Stack>
                </Stack>
            </>
        </GenericModal>
    );
};

export default ModalRefuseOrder;
