import React from 'react';
import moment from 'moment-timezone';
import { FormSpy } from 'react-final-form';
import { CardMedia, Divider, Stack, Typography } from '@mui/material';
import { PublishDraftPostFormType } from './PublishDraftPostStepper';

const SummaryStep: React.FC = () => (
    <FormSpy<PublishDraftPostFormType>
        subscription={{ values: true }}
        render={({ values }) => (
            <Stack gap={2} width={1}>
                {
                    values.toPublishAt && (
                        <>
                            <Typography>
                                <Typography component='span' fontWeight={700}>{`L'article sera publié le : `}</Typography>
                                {moment(values.toPublishAt).format('DD/MM/YYYY HH:mm')}
                            </Typography>
                            <Divider />
                        </>
                    )
                }
                <Typography>
                    <Typography component='span' fontWeight={700}>{`Projet : `}</Typography>
                    {values.project!.label}
                </Typography>
                <Typography>
                    <Typography component='span' fontWeight={700}>{`Type d'article : `}</Typography>
                    {values.postType!.label}
                </Typography>
                <Typography>
                    <Typography component='span' fontWeight={700}>{`Secteur d'activité : `}</Typography>
                    {values.activitySector!.label}
                </Typography>
                <Divider />
                {
                    'intern' === values.destinationChoice.value ? (
                        <>
                            <Typography>
                                <Typography component='span' fontWeight={700}>{`Thématique : `}</Typography>
                                {values.thematic!.label}
                            </Typography>
                            <Typography>
                                <Typography component='span' fontWeight={700}>{`Site : `}</Typography>
                                {values.website!.label}
                            </Typography>
                            <Typography>
                                <Typography component='span' fontWeight={700}>{`Catégorie : `}</Typography>
                                {values.category!.label}
                            </Typography>
                        </>
                    ) : (
                        <Typography>
                            <Typography component='span' fontWeight={700}>{`Site de destination : `}</Typography>
                            {values.externalUrl!}
                        </Typography>
                    )
                }
                <Divider />
                <CardMedia
                    component='img'
                    src={values.image!.url}
                    height={300}
                    sx={{ borderRadius: 1, maxWidth: 500 }}
                />
                <Typography>
                    <Typography component='span' fontWeight={700}>{`Titre : `}</Typography>
                    {values.title!}
                </Typography>
                <Typography>
                    <Typography component='span' fontWeight={700}>{`Meta description : `}</Typography>
                    {values.metaDesc!}
                </Typography>
            </Stack>
        )}
    />
);

export default SummaryStep;
