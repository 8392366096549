import React from 'react';
import { Page as ReactPdfPage, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

// Style to apply to component
const styles = StyleSheet.create({
    page: {
        paddingTop: 20,
        paddingHorizontal: 20,
        paddingBottom: 30,
    },
});

const Page: React.FC<{ style?: Style|undefined, debug?: boolean|undefined }> = props => {
    return (
        <ReactPdfPage style={{ ...styles.page, ...props.style }} debug={props.debug}>
                {props.children}
        </ReactPdfPage>
    );
};

export default Page;
