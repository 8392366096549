import React from 'react';
import moment from 'moment-timezone';
import PaymentAPIs from '../../APIs/PaymentAPIs';
import {
    Typography,
    Stack,
} from '@mui/material';
import PaymentPaper from './PaymentPaper';
import Spinner from '../Spinner/Spinner';
import IPayment from '../../models/IPayment';

const PaymentValidation: React.FC = () => {
    // Use of hooks
    const [payments, setPayments] = React.useState<Array<IPayment>|null>(null);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === payments &&
            // Call API to retrieve Payments to validate
            PaymentAPIs.getPayment({ validated: false, hasInvoice: true })
                // On successful API call, store retrieve Payment resources in our hook
                .then((data: Array<IPayment>|null) => null !== data && setPayments(data));
    }, [payments])

    // Function used to validate a Payment
    function handlePaymentValidation(paymentId: number) {
        // Call API to update Payment resource
        PaymentAPIs.patchPayment(paymentId, { validatedAt: moment().format() })
            // On successful API call
            .then((data: IPayment|null) =>
                // Remove this Payment from our hook value (from the list)
                null !== data && setPayments(payments => payments!.filter(item => item.id !== data.id))
            );
    };

    return (
        <>
            <Typography variant='h5' paddingBottom={3}>
                {`Paiements à valider :`}
            </Typography>
            {
                null !== payments ? (
                    0 < payments.length ? (
                        <Stack spacing={4}>
                            {
                                payments.map((payment: IPayment, index: number) => (
                                    <PaymentPaper
                                        key={index}
                                        payment={payment}
                                        onValidation={() => handlePaymentValidation(payment.id)}
                                    />
                                ))
                            }
                        </Stack>
                    ) : (
                        <Typography variant='h6' component='p' align='center' color='textSecondary'>
                            {`Aucun paiement à valider pour le moment ...`}
                        </Typography>
                    )
                ) : (<Spinner />)
            }
        </>
    );
};

export default PaymentValidation;
