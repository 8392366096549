import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import './Toast.scss';

const Toast: React.FC = () => {
    return (
        <ToastContainer
            icon={false}
            closeButton={false}
            theme='colored'
            position='top-right'
            transition={Slide}
            limit={4}
        />
    );
};

export default Toast;
