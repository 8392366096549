import React from 'react';
import { IconType } from 'react-icons';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
    SxProps,
    Theme,
    SvgIconTypeMap,
    IconButton,
    Box,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import cssStyles from './PostActionButtonStyles';

const PostActionButton: React.FC<{
    icon: IconType|OverridableComponent<SvgIconTypeMap<{},'svg'>>,
    tooltip: string,
    loading?: boolean|undefined,
    disabled?: boolean|undefined,
    onClick?: (() => void)|undefined,
}> = props => {
    // Define Icon component
    const Icon = props.icon;

    // Define multiple times used IconButtonComponent
    const IconButtonComponent: JSX.Element = (
        <IconButton
            disableRipple
            disabled={props.disabled}
            sx={{
                ...cssStyles.actionButton,
                ...(props.disabled && cssStyles.actionButtonDisabled),
            } as SxProps<Theme>}
            onClick={() => props.onClick && props.onClick()}
        >
            {
                !props.loading ? (
                    <Box component={Icon} fill='currentColor' sx={{ height: 25, width: 25 }} />
                ) : (
                    <CircularProgress color="inherit" size={16} />
                )
            }
        </IconButton>
    );

    return (
        props.disabled ?
            IconButtonComponent
            : (
                <Tooltip title={props.tooltip}>
                    {IconButtonComponent}
                </Tooltip>
            )
    );
};

export default PostActionButton;
