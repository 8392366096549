import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import {
    minTitleLength,
    maxTitleLength,
    minMetaDescLength,
    maxMetaDescLength,
} from '../../configs/seoConstraints';
import { Grid, Typography } from '@mui/material';
import FormikInput from '../FormikComponent/FormikInput';
import BriefDisplay from '../BriefDisplay/BriefDisplay';
import KeywordDisplay from '../KeywordDisplay/KeywordDisplay';
import IOrder from '../../models/IOrder';
import IPostFormStepperData from '../../interfaces/IPostFormStepperData';

const MainInfo: React.FC<{ order: IOrder, withConstraints: boolean, formikFormId: string }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();
    const postFormStepperData: IPostFormStepperData = useAppSelector(state => state.activeStepper.data);

    // Define initial values for formik form
    const initialValues: any = {
        title: postFormStepperData.title ?? props.order.title ?? props.order.post?.title ?? '',
        metaDesc: postFormStepperData.metaDesc ?? props.order.metaDesc ?? props.order.post?.metaDesc ?? '',
    };

    // Validation scheme of the formik form
    const validationSchema = Yup.object({
        title: null === props.order.title ?
            // If title was not specified during order creation
            Yup.string()
                .test(
                    'required',
                    "Veuillez spécifier le titre de l'article",
                    (title: string|undefined) => undefined !== title || '' !== title
                )
                .test(
                    'startsWith',
                    `Le titre de l'article doit commencer par '${props.order.keyword.name} :'`,
                    (title: string|undefined) => false === props.withConstraints || (undefined !== title && title.toLowerCase().startsWith(`${props.order.keyword.name.toLowerCase()} :`))
                )
                .test(
                    'min',
                    `Le nombre minimum de caractéres est fixé à ${minTitleLength}`,
                    (title: string|undefined) => false === props.withConstraints || (undefined !== title && minTitleLength < title.length)
                )
                .test(
                    'max',
                    `Le nombre maximum de caractéres est fixé à ${maxTitleLength}`,
                    (title: string|undefined) => false === props.withConstraints || (undefined !== title && maxTitleLength > title.length)
                )
            : Yup.string(),
        metaDesc: null === props.order.metaDesc ?
            // If metaDesc was not specified during order creation
            Yup.string()
                .test(
                    'required',
                    "Veuillez spécifier la meta description de l'article",
                    (metaDesc: string|undefined) => undefined !== metaDesc && '' !== metaDesc
                )
                .test(
                    'min',
                    `Le nombre minimum de caractéres est fixé à ${minMetaDescLength}`,
                    (metaDesc: string|undefined) => false === props.withConstraints || (undefined !== metaDesc && minMetaDescLength < metaDesc.length)
                )
                .test(
                    'max',
                    `Le nombre maximum de caractéres est fixé à ${minMetaDescLength}`,
                    (metaDesc: string|undefined) => false === props.withConstraints || (undefined !== metaDesc && maxMetaDescLength > metaDesc.length)
                )
            : Yup.string(),
    });

    // Callback to hande formik submit
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Store values under data field of active stepper redux state
        // & increment active step
        dispatch(setActiveStepperData({ data: values, action: 'inc' }));
    }, [dispatch]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form id={props.formikFormId}>
                    <KeywordDisplay keyword={props.order.keyword.name} />
                    <BriefDisplay brief={props.order.brief} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h5' gutterBottom>
                                {`Informations de l'article`}
                            </Typography>
                            <Typography variant='body2'>
                                {`Les informations de l'article à compléter`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormikInput
                                name='title'
                                upperLabel={`Titre de l'article`}
                                sx={{ paddingBottom: 3 }}
                                disabled={props.withConstraints ? Boolean(props.order.title) : undefined}
                                helperText={Boolean(props.order.title) ? `Le titre a été défini par le propriétaire de la commande` : undefined}
                                warnings={false === props.withConstraints ? [
                                    {
                                        condition: '' !== props.order.keyword.name && '' !== formikProps.values.title &&
                                                !formikProps.values.title.toLowerCase().startsWith(`${props.order.keyword.name.toLowerCase()} :`),
                                        text: `Votre titre devrait commencer par ${props.order.keyword.name} :`,
                                    },
                                    {
                                        condition: '' !== formikProps.values.title && minTitleLength > formikProps.values.title.length,
                                        text: `Votre titre devrait posséder au minimum ${minTitleLength} caractéres`,
                                    },
                                    {
                                        condition: '' !== formikProps.values.title && maxTitleLength < formikProps.values.title.length,
                                        text: `Votre titre ne devrait pas dépasser ${maxTitleLength} caractéres`,
                                    },
                                ] : undefined}
                            />
                            <FormikInput
                                name='metaDesc'
                                upperLabel={`Meta description de l'article`}
                                multiline
                                disabled={props.withConstraints ? Boolean(props.order.metaDesc) : undefined}
                                helperText={Boolean(props.order.metaDesc) ? `La meta description a été défini par le propriétaire de la commande` : undefined}
                                warnings={false === props.withConstraints ? [
                                    {
                                        condition: '' !== formikProps.values.metaDesc && minMetaDescLength > formikProps.values.metaDesc.length,
                                        text: `Votre meta description devrait posséder au minimum ${minMetaDescLength} caractéres`,
                                    },
                                    {
                                        condition: '' !== formikProps.values.metaDesc && maxMetaDescLength < formikProps.values.metaDesc.length,
                                        text: `Votre meta description ne devrait pas dépasser ${maxMetaDescLength} caractéres`,
                                    },
                                ] : undefined}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default MainInfo;
