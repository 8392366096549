import React from 'react';
import { useNavigate,  } from 'react-router-dom';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import SecurityAPIs from '../../APIs/SecurityAPIs';
import {
    Container,
    Stack,
    Tooltip,
    IconButton,
    Typography,
    Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import FormikInput from '../FormikComponent/FormikInput';
import IUser from '../../models/IUser';

const ForgotPassword: React.FC = () => {
    // Use of hooks
    const [email, setEmail] = React.useState<string|null|undefined>(undefined);

    // Use react-router-dom hooks
    const navigate = useNavigate();

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        email: Yup
            .string()
            .email('Veuillez rentrer une adresse email valide')
            .required('Veuillez renseigner une adresse email'),
    });

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback((values: any, formikHelpers: FormikHelpers<any>) => {
        // Verify if an User exists with this email address
        SecurityAPIs.forgotPassword(values.email)
            // On successful call
            .then((data: IUser) => data ? setEmail(values.email) : setEmail(null))
            // In any cases, retrieve back
            .finally(() => formikHelpers.setSubmitting(false));
    }, [])

    return (
        <>
            <Container component='main' maxWidth='md' sx={{ padding: 5 }}>
                <Stack alignItems='flex-start' spacing={2} paddingBottom={3}>
                    {
                        // Can't goBack if there is no previous location
                        <Tooltip title='Précédent'>
                            <IconButton onClick={() => navigate(-1)}>
                                <ArrowBackIcon fontSize='large' color='secondary' />
                            </IconButton>
                        </Tooltip>
                    }
                    <Typography component='h1' variant='h4' color='primary'>
                        {`Procédure de récupération de mot de passe`}
                    </Typography>
                </Stack>
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleFormikSubmit}
                >
                    {(formikProps: FormikProps<any>) => (
                        <Form>
                            <Typography gutterBottom>
                                {`Renseignez l'adresse email associée à votre compte :`}
                            </Typography>
                            <FormikInput
                                autoFocus
                                label=''
                                size='medium'
                                name='email'
                                onChange={() => setEmail(undefined)}
                            />
                            <LoadingButton
                                variant='contained'
                                type='submit'
                                disabled={!formikProps.isValid}
                                loading={formikProps.isSubmitting}
                                sx={{ marginY: 2 }}
                            >
                                {`Demander un changement de mot de passe`}
                            </LoadingButton>
                        </Form>
                    )}
                </Formik>
                {
                    undefined !== email && (
                        <Alert severity={null === email ? 'error' : 'success'} onClose={() => setEmail(undefined)}>
                        {
                            null === email ?
                                `Aucun compte trouvé avec cette adresse email`
                                : `Un email contenant les instructions pour réinitialiser votre mot de passe vient de vous être envoyé`
                        }
                        </Alert>
                    )
                }
            </Container>
        </>
    );
};

export default ForgotPassword;
