import React from 'react';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { Button, FormHelperText, Grid } from '@mui/material';
import { FormikProps, Formik, Form } from 'formik';
import FormikInput from '../FormikComponent/FormikInput';
import FormikRadio from '../FormikComponent/FormikRadio';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import ImagePreview from '../ImagePreview/ImagePreview';
import ModalPixabayPicker from '../Modal/ModalPixabayPicker';
import IOrderFormStepperData from '../../interfaces/IOrderFormStepperData';
import IRadioOption from '../../interfaces/IRadioOption';

// Define RadioGroup options
export const imageSelectionOptions: Array<IRadioOption> = [
    {
        value: 'none',
        label: "Ne pas choisir d'image à la une",
        helperText: "Ne pas choisir d'image laisse le choix au rédacteur",
    },
    {
        value: 'kw',
        label: "Choisir un mot clé pour l'image à la une",
        helperText: "Ce mot clé sera utilisé par le rédacteur pour choisir l'image à la une.",
    },
    {
        value: 'file',
        label: 'Charger une image à la une depuis mes fichiers',
    },
    {
        value: 'bank',
        label: "Choisir une image à la une depuis une banque d'images",
    },
];

const ImageSelection: React.FC<{ formikFormId: string }> = props => {
    // Use of redux hooks
    const dispatch = useAppDispatch();
    const orderFormState: IOrderFormStepperData = useAppSelector(state => state.activeStepper.data);

    // Define unaware initial values to reset form values
    const unawareInitialValues = React.useRef<any>({
        imageChoice: imageSelectionOptions.find(option => option.value === 'none'),
        imageKeyword: '',
        imageUploaded: undefined,
        imageBank: undefined,
    });

    // Define initial values for formik form
    const initialValues: any = {
        imageChoice: orderFormState.imageChoice ?? unawareInitialValues.current.imageChoice,
        imageKeyword: orderFormState.imageKeyword ?? unawareInitialValues.current.imageKeyword,
        imageUploaded: orderFormState.imageUploaded ?? unawareInitialValues.current.imageUploaded,
        imageBank: orderFormState.imageBank ?? unawareInitialValues.current.imageBank,
    };

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        imageKeyword: Yup
            .string()
            .test(
                'required',
                "Veuillez spécifier un mot-clé pour le choix de l'image à la une",
                function (imageKeyword: string|undefined) {
                    return 'kw' === this.parent.imageChoice ? undefined !== imageKeyword : true;
                }
            ),
        imageUploaded: Yup
            .mixed()
            .test(
                'required',
                'Veuillez sélectionner ou déposer une image à la une depuis vos fichiers',
                function (imageUploaded: File|undefined) {
                    return 'file' === this.parent.imageChoice ? undefined !== imageUploaded : true;
                }
            ),
        imageBank: Yup
            .string()
            .test(
                'required',
                "Veuillez choisir une image à la une depuis la banque d'images",
                function (imageBank: string|undefined) {
                    return 'bank' === this.parent.imageChoice ? undefined !== imageBank : true;
                }
            ),
    });

    // Callback use to reset formik fields to their initial value
    const resetFormikFields = React.useCallback((formikProps: FormikProps<any>, fields: Array<string>) => {
        // Use pre-build formik function
        fields.forEach((field) => formikProps.setFieldValue(field, unawareInitialValues.current[field]));
    }, []);

    // Callback to hande formik submit
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Format values by imageChoice value
        values = {
            ...values,
            imageUploaded: 'file' === values.imageChoice.value ? values.imageUploaded : undefined,
            imageKeyword: 'kw' === values.imageChoice.value ? values.imageKeyword : undefined,
            imageBank: 'bank' === values.imageChoice.value ? values.imageBank : undefined,
        };

        // Store values under data field of active stepper redux state
        // & increment active step
        dispatch(setActiveStepperData({ data: values, action: 'inc' }));
    }, [dispatch]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form id={props.formikFormId}>
                    <ModalPixabayPicker
                        defaultKeyword={orderFormState.keyword?.label ?? undefined}
                        onValidation={(imageSrc) => formikProps.setFieldValue('imageBank', imageSrc)}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormikRadio
                                name='imageChoice'
                                radio={imageSelectionOptions}
                                onChange={() => resetFormikFields(formikProps, ['imageKeyword', 'imageUploaded', 'imageBank'])}
                                sx={{ paddingBottom: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                // User chose to define a keyword
                                'kw' === formikProps.values.imageChoice.value && (
                                    <FormikInput
                                        name='imageKeyword'
                                        placeholder='Spécifiez votre mot-clé'
                                    />
                                )
                            }
                            {
                                // User chose to upload a file or image come from an existing order
                                'file' === formikProps.values.imageChoice.value && (
                                    <ImageDropzone
                                        initialImage={formikProps.values.imageUploaded}
                                        error={Boolean(formikProps.errors.imageUploaded)}
                                        maxImageWeight={800}
                                        maxPreviewSize={1}
                                        keepFileOnUnmount
                                        onDrop={(_, imageBlobUrl: string) => formikProps.setFieldValue('imageUploaded', imageBlobUrl)}
                                        onPreviewClose={() => resetFormikFields(formikProps, ['imageUploaded'])}
                                    />
                                )
                            }
                            {
                                // User chose to define image with image bank
                                'bank' === formikProps.values.imageChoice.value && (
                                    <>
                                        {
                                            undefined === formikProps.values.imageBank ? (
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    onClick={() => dispatch(openModal({ name: 'pixabayPicker' }))}
                                                >
                                                    {`Depuis Pixabay`}
                                                </Button>
                                            ) : (
                                                <ImagePreview
                                                    closable
                                                    imageSrc={formikProps.values.imageBank}
                                                    maxSize={1}
                                                    onPreviewClose={() => resetFormikFields(formikProps, ['imageBank'])}
                                                />
                                            )
                                        }
                                        {
                                            Boolean(formikProps.errors.imageBank) && (
                                                <FormHelperText error sx={{ paddingTop: 2 }}>
                                                    {formikProps.errors.imageBank}
                                                </FormHelperText>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default ImageSelection;
