import React from 'react';
import { useLocation } from 'react-router-dom';
import inlineNavigationConfig from '../../configs/inlineNavigation';
import { Breadcrumbs } from '@mui/material';
import RouterLink from '../RouterLink/RouterLink';
import ILink from '../../interfaces/ILink';
import cssStyles from './InlineNavigationStyles';

const InlineNavigation: React.FC = () => {
    // Use of hooks
    const [links, setLinks] = React.useState<Array<ILink>>([]);

    // Use of react-router-dom hooks
    const { pathname } = useLocation();

    // useEffect when component is mounting
    React.useEffect(() => {
        let setupLinks: Array<ILink> = [];
        let accPathname = '';
        const splitPathname = pathname.split('/').filter((value: string) => value !== '');

        // Setup anchor & pathname for each future RouterLink
        splitPathname.forEach((value: string, index: number) => {
            accPathname = 0 === index ? `/${value}` : `${accPathname}/${value}`;
            setupLinks.push({
                anchor: inlineNavigationConfig[value] ?? value,
                url: accPathname,
            });
        });
        // Change our hook value
        setLinks(setupLinks);
    }, [pathname])

    return (
        /** separator = '›' */
        <Breadcrumbs separator='&rsaquo;' sx={cssStyles.breadcrumbs}>
            {
                links.map((link: ILink, index: number) => (
                    <RouterLink
                        key={index}
                        anchor={link.anchor}
                        to={link.url}
                        color='textSecondary'
                        underline='hover'
                        variant='caption'
                    />
                ))
            }
        </Breadcrumbs>
    );
};

export default InlineNavigation;
