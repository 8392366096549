import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    postPaper: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 2,
    },
    leftSide: {
        position: 'relative',
        flexDirection: 'column',
        justifyContent:'center',
        alignItems:'center',
        padding: 1,
        width: '20%',
        '&::after': {
            position: 'absolute',
            content: "''",
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            width: '1px',
            height: '75%',
            backgroundColor: 'primary.main',
        }
    },
    avatar: {
        width: 30,
        height: 30,
        fontSize: '1rem',
    },
    owner: {
        textAlign: 'center',
        fontWeight: 700,
        lineHeight: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 8/10,
    },
    rightSide: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingY: 1,
        paddingX: 4,
        width: '80%',
    },
    rowData: {
        lineHeight: 1,
        width: '49%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    rowDataSvg: {
        verticalAlign: 'middle',
        width: 24,
        height: 24,
        color: 'primary.main',
    },
    rowDataTitle: {
        fontWeight: 700,
        verticalAlign: 'middle',
    },
    rowDataLabel: {
        color: 'text.secondary',
        verticalAlign: 'middle',
    },
    rowDataHelpSvg: {
        verticalAlign: 'middle',
        width: 20,
        height: 20,
        color: 'text.secondary',
        cursor: 'pointer',
    },
    postFooter: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 1,
    },
};

export default cssStyles;
