import { Theme } from '@mui/material';
import { appMenuWidthOpen } from '../../configs/materialUI';
import ICssStyles from '../../interfaces/ICssStyles';

const makeCssStyles = (theme: Theme): ICssStyles => {
    // Define transitions constantes
    const transitionEnteringScreen: string = theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    });
    const transitionLeavingScreen: string = theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    });

    return {
        dashboardMenuExt: {
            position: 'fixed',
            top: 0,
            height: '100vh',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            backgroundColor: 'primary.light',
            color: 'white',
            zIndex: theme.zIndex.drawer,
        },
        dashboardMenuExtOpen: {
            width: appMenuWidthOpen,
            transition: transitionEnteringScreen,
            '~ #dashboardContent': {
                filter: 'blur(2px) grayscale(60%)',
            },
        },
        dashboardMenuExtClose: {
            display: 'hidden',
            width: 0,
            transition: transitionLeavingScreen,
        },
    };
};

export default makeCssStyles;
