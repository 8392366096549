import React from 'react';
import {
    Rating as MuiRating,
    Stack,
    Typography
} from '@mui/material';

const Rating: React.FC<{
    label?: string|undefined,
    rate: number|string,
    rateCount?: number|undefined,
    decimalCount?: number|undefined,
}> = props => {
    const rate = Number(props.rate).toFixed(undefined !== props.decimalCount ? props.decimalCount : 1);

    return (
        <Stack direction='row' columnGap={0.5} paddingBottom={2}>
            <Typography variant='h5' component='p' >
                {rate}
            </Typography>
            <Stack>
                <MuiRating
                    readOnly
                    value={Number(rate)}
                    precision={0.1}
                />
                {
                    undefined !== props.rateCount && (
                        <Typography variant='caption' paddingLeft={0.6}>
                            {`${props.rateCount} ${props.label ?? 'note(s)'}`}
                        </Typography>
                    )
                }
            </Stack>
        </Stack>
    );
};

export default Rating;
