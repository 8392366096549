import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { setInstanceIsLogged } from '../../store/slices/instanceSlice';
import {
    useTheme,
    Stack,
    Tooltip,
} from '@mui/material';
import {
    Notifications as NotificationsIcon,
    Help as HelpIcon,
    ExitToApp as ExitToAppIcon,
} from '@mui/icons-material';
import HeadbandMissingBio from './HeadbandMissingBio';
import HeadbandWallet from './HeadbandWallet';
import makeCssStyles from './DashboardHeadbandStyles';

const DashboardHeadband: React.FC = () => {
    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme);

    // Use of redux
    const dispatch = useAppDispatch();

    // Callback used to sign out current user
    const handleSignOut = React.useCallback(() => {
        // Dispatch in redux state
        dispatch(setInstanceIsLogged(false));
    }, [dispatch])

    return (
        <Stack sx={cssStyles.headbandContainer} columnGap={2}>
            <HeadbandMissingBio />
            <HeadbandWallet />
            <Tooltip title='Notifications'>
                <NotificationsIcon
                    fontSize='small'
                    sx={cssStyles.icon}
                />
            </Tooltip>
            <Tooltip title="Besoin d'aide ?">
                <HelpIcon
                    fontSize='small'
                    sx={cssStyles.icon}
                />
            </Tooltip>
            <Tooltip title='Se déconnecter'>
                <ExitToAppIcon
                    fontSize='small'
                    sx={cssStyles.icon}
                    onClick={handleSignOut}
                />
            </Tooltip>
        </Stack>
    );
};

export default DashboardHeadband;
