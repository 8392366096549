type TOrderStatuses = 'UNASSIGNED'|'PROPOSE_REFUSED'|'PROPOSED'|'TODO'|'SAVED'|'CORRECTED'|'REFUSED'|
    'UNVERIFIED'|'VERIFIED'|'UNPUBLISHED'|'UNKNOWN'|'UNINDEXED'|'INDEXED'|'PUBLISHED'|'EXTERNAL_PUBLISH'|
    'ARCHIVED';

const EOrderStatuses: Readonly<{ [key in TOrderStatuses]: string }> = Object.freeze({
    UNASSIGNED: 'unassigned',
    PROPOSE_REFUSED: 'propose-refused',
    PROPOSED: 'proposed',
    TODO: 'todo',
    SAVED: 'saved',
    CORRECTED: 'corrected',
    REFUSED: 'refused',
    UNVERIFIED: 'unverified',
    VERIFIED: 'verified',
    UNPUBLISHED: 'unpublished',
    UNKNOWN: 'unknown',
    UNINDEXED: 'unindexed',
    INDEXED: 'indexed',
    PUBLISHED: 'published',
    EXTERNAL_PUBLISH: 'external-publish',
    ARCHIVED: 'archived',
});

export default EOrderStatuses;
