import React from 'react';
import ToolsMenuPage1 from './ToolsMenuPage1';

const ToolsMenu: React.FC = () => {
    return (
        <ToolsMenuPage1 />
    );
};

export default ToolsMenu;
