/**
 * This file defines all languages shortcut that can be used for TranslationTool
 */
export const translationLanguages = {
    'auto': 'Détecter la langue',
    'af': 'Afrikaans',
    'sq': 'Albanais',
    'de': 'Allemand',
    'am': 'Amharique',
    'en': 'Anglais',
    'ar': 'Arabe',
    'hy': 'Arménien',
    'as': 'Assamais',
    'ay': 'Aymara',
    'az': 'Azéri',
    'bm': 'Bambara',
    'eu': 'Basque',
    'bn': 'Bengali',
    'bho': 'Bhodjpouri',
    'be': 'Biélorusse',
    'my': 'Birman',
    'bs': 'Bosniaque',
    'bg': 'Bulgare',
    'ca': 'Catalan',
    'ceb': 'Cebuano',
    'ny': 'Chichewa',
    'zh-CN': 'Chinois',
    'si': 'Cingalais',
    'ko': 'Coréen',
    'co': 'Corse',
    'ht': 'Créole haïtien',
    'hr': 'Croate',
    'da': 'Danois',
    'dv': 'Divéhi',
    'doi': 'Dogri',
    'es': 'Espagnol',
    'eo': 'Espéranto',
    'et': 'Estonien',
    'ee': 'Ewe',
    'fi': 'Finnois',
    'fr': 'Français',
    'fy': 'Frison',
    'gd': 'Gaélique (Écosse)',
    'gl': 'Galicien',
    'cy': 'Gallois',
    'ka': 'Géorgien',
    'el': 'Grec',
    'gn': 'Guarani',
    'gu': 'Gujarati',
    'ha': 'Haoussa',
    'haw': 'Hawaïen',
    'iw': 'Hébreu',
    'hi': 'Hindi',
    'hmn': 'Hmong',
    'hu': 'Hongrois',
    'ig': 'Igbo',
    'ilo': 'Ilocano',
    'id': 'Indonésien',
    'ga': 'Irlandais',
    'is': 'Islandais',
    'it': 'Italien',
    'ja': 'Japonais',
    'jw': 'Javanais',
    'kn': 'Kannada',
    'kk': 'Kazakh',
    'km': 'Khmer',
    'rw': 'Kinyarwanda',
    'ky': 'Kirghiz',
    'gom': 'Konkani',
    'kri': 'Krio',
    'ku': 'Kurde (Kurmandji)',
    'ckb': 'Kurde (Sorani)',
    'lo': 'Laotien',
    'la': 'Latin',
    'lv': 'Letton',
    'ln': 'Lingala',
    'lt': 'Lituanien',
    'lg': 'Luganda',
    'lb': 'Luxembourgeois',
    'mk': 'Macédonien',
    'mai': 'Maïthili',
    'ms': 'Malaisien',
    'ml': 'Malayalam',
    'mg': 'Malgache',
    'mt': 'Maltais',
    'mi': 'Maori',
    'mr': 'Marathi',
    'mni-Mtei': 'Meitei (Manipuri)',
    'lus': 'Mizo',
    'mn': 'Mongol',
    'nl': 'Néerlandais',
    'ne': 'Népalais',
    'no': 'Norvégien',
    'or': 'Odia (Oriya)',
    'om': 'Oromo',
    'ug': 'Ouïgour',
    'uz': 'Ouzbek',
    'ps': 'Pachtô',
    'pa': 'Panjabi',
    'fa': 'Persan',
    'tl': 'Philippin',
    'pl': 'Polonais',
    'pt': 'Portugais',
    'qu': 'Quechua',
    'ro': 'Roumain',
    'ru': 'Russe',
    'sm': 'Samoan',
    'sa': 'Sanscrit',
    'nso': 'Sepedi',
    'sr': 'Serbe',
    'st': 'Sesotho',
    'sn': 'Shona',
    'sd': 'Sindhî',
    'sk': 'Slovaque',
    'sl': 'Slovène',
    'so': 'Somali',
    'su': 'Soundanais',
    'sv': 'Suédois',
    'sw': 'Swahili',
    'tg': 'Tadjik',
    'ta': 'Tamoul',
    'tt': 'Tatar',
    'cs': 'Tchèque',
    'te': 'Telugu',
    'th': 'Thaï',
    'ti': 'Tigrigna',
    'ts': 'Tsonga',
    'tr': 'Turc',
    'tk': 'Turkmène',
    'ak': 'Twi',
    'uk': 'Ukrainien',
    'ur': 'Urdu',
    'vi': 'Vietnamien',
    'xh': 'Xhosa',
    'yi': 'Yiddish',
    'yo': 'Yorouba',
    'zu': 'Zoulou',
};
