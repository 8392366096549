import React from 'react';
import arrayMutators from 'final-form-arrays';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { ArrowBack as ArrowBackIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import FinalInput from '../FinalComponents/FinalInput';
import FinalRadio from '../FinalComponents/FinalRadio';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import FinalForm from '../FinalForm/FinalForm';
import IPostOutline from '../../interfaces/IPostOutline';
import IRadioOption from '../../interfaces/IRadioOption';

const contentTypes: Array<IRadioOption> = [
    {
        label: 'Aucun',
        value: '',
    },
    {
        label: 'Introduction (~100 mots)',
        value: 'introduction'
    },
    {
        label: 'Contenu (~200 mots)',
        value: 'content'
    },
];

interface ITitleElement {
    title: string,
    type: 'h1'|'h2'|'h3',
    contentType: IRadioOption,
};

type FormFields = {
    keyword: string,
    outline: Array<Array<ITitleElement>>,
};

const PostOutlineForm: React.FC<{
    postOutline?: IPostOutline|undefined,
    onSubmit: (values: IPostOutline) => void,
    onPreviousClick: () => void,
}> = props => {
    // Define form initial values based on postOutline from props
    const initialValues: FormFields = props.postOutline ? {
        ...props.postOutline,
        outline: props.postOutline.outline.map(paragraph => paragraph.map(element => ({
            ...element,
            contentType: contentTypes.find(option => option.value === element.contentType) ?? contentTypes[0],
        }))),
    } : {
        keyword: '',
        outline: [],
    };

    return (
        <>
            <Tooltip title='Précédent' placement='right'>
                <IconButton
                    onClick={() => props.onPreviousClick()}
                    sx={{ marginBottom: 3 }}
                >
                    <ArrowBackIcon fontSize='large' color='secondary' />
                </IconButton>
            </Tooltip>
            <FinalForm<FormFields>
                initialValues={initialValues}
                onSubmit={(values) => props.onSubmit({
                    ...values,
                    optimized: props.postOutline?.optimized ?? false,
                    outline: values.outline.map(paragraph => paragraph.map(title => ({
                        title: title.title,
                        type: title.type,
                        contentType: title.contentType.value,
                    }))),
                })}
                mutators={{ ...arrayMutators }}
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                gap={2}
            >
                <FinalInput
                    name='keyword'
                    size='small'
                    fullWidth
                    upperLabel
                    label='Mot clé'
                />
                <Divider flexItem sx={{ marginY: 2 }} />
                <Stack>
                    <Typography variant='h6'>{`Contenu de l'article`}</Typography>
                    {
                        props.postOutline?.optimized && (
                            <Typography variant='caption'>{`Pour atteindre le score d'optimisation optimal, d'autres paragraphes peuvent être générés en complément.`}</Typography>
                        )
                    }
                </Stack>
                <FieldArray<FormFields['outline']>
                    name={`outline`}
                    subscription={{}}
                    render={({ fields: outline }) => (
                        <Stack gap={2} width={1}>
                            {
                                outline.map((paragraphName, index) => (
                                    // For each paragraph
                                    <React.Fragment key={paragraphName}>
                                        <Stack gap={2} paddingLeft={4} borderLeft={2} borderColor='primary.main'>
                                            <Stack direction='row' alignItems='center' columnGap={2}>
                                                <Typography variant='h6'>{`Paragraphe n° ${index + 1}`}</Typography>
                                                <IconButton
                                                    color='error'
                                                    onClick={() => outline.remove(index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Stack>
                                            <FieldArray
                                                name={paragraphName}
                                                subscription={{}}
                                                render={({ fields: pElements }) => (
                                                    // For each paragraph element
                                                    <Stack gap={2}>
                                                        <Stack direction='row' gap={3}>
                                                            <Button
                                                                variant='outlined'
                                                                onClick={() => pElements.push({ title: '', type: 'h1', contentType: { value: 'introduction' }})}
                                                                sx={{ alignSelf: 'flex-start' }}
                                                            >
                                                                {`Ajouter un titre H1`}
                                                            </Button>
                                                            <Button
                                                                variant='outlined'
                                                                onClick={() => pElements.push({ title: '', type: 'h2', contentType: { value: '' }})}
                                                                sx={{ alignSelf: 'flex-start' }}
                                                            >
                                                                {`Ajouter un titre H2`}
                                                            </Button>
                                                            <Button
                                                                variant='outlined'
                                                                onClick={() => pElements.push({ title: '', type: 'h3', contentType: { value: 'content' }})}
                                                                sx={{ alignSelf: 'flex-start' }}
                                                            >
                                                                {`Ajouter un titre H3`}
                                                            </Button>
                                                        </Stack>
                                                        {
                                                            pElements.map((pElementName, index) => (
                                                                <Stack key={pElementName} paddingLeft={2} borderLeft={2} borderColor='secondary.main'>
                                                                    <Field<ITitleElement>
                                                                        name={pElementName}
                                                                        subscription={{ value: true }}
                                                                        render={({ input: { value } }) => (
                                                                            <FinalInput
                                                                                name={`${pElementName}.title`}
                                                                                size='small'
                                                                                fullWidth
                                                                                upperLabel
                                                                                label={`Titre ${value.type.toUpperCase()}`}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <IconButton
                                                                                            color='error'
                                                                                            onClick={() => pElements.remove(index)}
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <FinalRadio
                                                                        row
                                                                        name={`${pElementName}.contentType`}
                                                                        label='Type de contenu à générer'
                                                                        radio={contentTypes}
                                                                    />
                                                                </Stack>
                                                            ))
                                                        }
                                                    </Stack>
                                                )}
                                            />
                                        </Stack>
                                        <Divider sx={{ marginY: 2 }} />
                                    </React.Fragment>
                                ))
                            }
                            <Button
                                variant='outlined'
                                onClick={() => outline.push([])}
                                sx={{ alignSelf: 'flex-start' }}
                            >
                                {`Ajouter un paragraphe`}
                            </Button>
                        </Stack>
                    )}
                />
                <FormSpy<FormFields>
                    subscription={{ values: true }}
                    render={({ values }) => {
                        let estimatedNbrWords = 0;
                        values.outline.forEach(paragraph => paragraph.forEach(element => {
                            estimatedNbrWords += 'content' === element.contentType.value ? 200 : 'introduction' === element.contentType.value ? 100 : 0;
                        }));

                        return (
                            <Typography>{`Nombre de mots estimé : ${estimatedNbrWords}`}</Typography>
                        );
                    }}
                />
                {
                    props.postOutline?.price && (
                        <Typography>{`Prix de la génération : ${props.postOutline.price} €`}</Typography>
                    )
                }
                <FinalSubmitButton
                    variant='contained'
                    enabledOnPristine
                >
                    {`Générer le contenu du plan`}
                </FinalSubmitButton>
            </FinalForm>
        </>
    );
};

export default PostOutlineForm;
