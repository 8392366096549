import React from 'react';
import { FormikProps } from 'formik';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import {
    Stack,
    Chip,
    Typography,
    Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IWpCategory from '../../interfaces/IWpCategory';

const SiteCategories: React.FC<{ forCreation: boolean, formikProps: FormikProps<any> }> = props => {
    // Use of hooks
    const [loading, isLoading] = React.useState<boolean>(false);
    const [siteError, isSiteError] = React.useState<boolean>(false);
    const categories = React.useRef<Array<IWpCategory>|null>(null);

    // Callback used to test website connexion with this app
    const handleConnexionTest = function(values: any) {
        // Loding during API call
        isLoading(true);
        // Call API to try to retrieve categories from site
        BridgeAPIs.getWordPressCategories({
            domain: values.domain,
            login: values.login,
            password: values.passwordApp && '' !== values.passwordApp ? values.passwordApp : values.password,
        })
            // On succesful API call
            .then((data: Array<IWpCategory>|null) => {
                if (null !== data) {
                    categories.current = data;
                } else {
                    isSiteError(true);
                }
            })
            // In any cases, no longer need to load
            .finally(() => isLoading(false));
    };

    // useEffect when form values changes
    React.useEffect(() => {
        isLoading(true);
        isSiteError(false);
        // Reset categories
        categories.current = null;
        // Stop loading after 2s
        setTimeout(() => isLoading(false), 2000);
    }, [props.formikProps.values]);

    return (
        null === categories.current ? (
            <Stack alignItems='flex-start' spacing={1}>
                {
                    siteError && (
                        <>
                            <Typography variant='body2' color='error.main'>
                                {`Impossible de récupérer les catégories depuis le site ${props.formikProps.values.isHttps}${props.formikProps.values.domain}.`}
                            </Typography>
                            <Typography variant='body2' color='error.main'>
                                {`Assurez-vous d'avoir renseigné les bonnes informations du site.`}
                            </Typography>
                        </>
                    )
                }
                <LoadingButton
                    disabled={props.forCreation ?
                        (0 === Object.keys(props.formikProps.touched).length || !props.formikProps.isValid)
                        : !props.formikProps.isValid
                    }
                    loading={loading}
                    variant='outlined'
                    color='secondary'
                    onClick={() => handleConnexionTest(props.formikProps.values)}
                >
                    {`Se connecter au site`}
                </LoadingButton>
                <Typography variant='caption' color='text.secondary'>
                    {`Le temps nécéssaire pour se connecter peut varier suivant le site`}
                </Typography>
            </Stack>
        ) : (
            <>
                {
                    0 < categories.current.length ? (
                        <>
                            <Typography variant='h6' component='p' gutterBottom>
                                {`Les catégories du site`}
                            </Typography>
                            <Stack direction='row' spacing={2}>
                                {
                                    categories.current.map((category: IWpCategory, index: number) => (
                                        <Chip key={index} label={category.name} />
                                    ))
                                }
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Typography variant='h6' component='p'>
                                {`Aucune catégorie n'a été trouvée sur le site.`}
                            </Typography>
                            <Typography variant='body2'>
                                {`Néanmoins, vous pouvez quand même ajouter votre site sur ${process.env.REACT_APP_NAME}.`}
                            </Typography>
                        </>
                    )
                }
                <Divider variant='middle' sx={{ marginY: 4 }} />
                <LoadingButton
                    disabled={props.forCreation ?
                        (0 === Object.keys(props.formikProps.touched).length || !props.formikProps.isValid)
                        : !props.formikProps.isValid
                    }
                    loading={props.formikProps.isSubmitting}
                    variant='contained'
                    type='submit'
                >
                    {props.forCreation ? 'Ajouter le site' : 'Modifier le site'}
                </LoadingButton>
            </>
        )
    );
};

export default SiteCategories;
