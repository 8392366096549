import ISiteSchedule from '../models/ISiteSchedule';
import ISiteScheduleKeyword from '../models/ISiteScheduleKeyword';
import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * SiteScheduleScheduleAPIs class.
 */
export default class SiteScheduleScheduleAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of SiteSchedule ressources.
     *
     * @param query
     */
    public static async getSiteSchedule(query?: any): Promise<Array<ISiteSchedule>|null> {
        return GenericAPIs.apiGetCall('sites_schedules', this.getApiKey(), query);
    }

    /**
     * Get collection of SiteScheduleKeyword ressources.
     *
     * @param query
     */
    public static async getSiteScheduleKeywords(query?: any): Promise<ISiteScheduleKeyword[]|null> {
        return GenericAPIs.apiGetCall('sites_schedules_keywords', this.getApiKey(), query);
    }

    /**
     * Post a SiteSchedule resource.
     *
     * @param body
     */
    public static async postSiteSchedule(body: any) {
        return GenericAPIs.apiPostCall('sites_schedules', body, this.getApiKey());
    }

    /**
     * Post multiple SiteSchedule resources.
     *
     * @param body
     */
    public static async postMultipleSiteSchedules(body: any) {
        return GenericAPIs.apiPostCall(`sites_schedules/multiple_create`, body, this.getApiKey());
    }

    /**
     * Patch a SiteSchedule resource.
     *
     * @param id
     * @param body
     */
    public static async patchSiteSchedule(id: number, body: any): Promise<ISiteSchedule|null> {
        return GenericAPIs.apiPatchCall(`sites_schedules/${id}`, null, body, this.getApiKey());
    }

    /**
     * Delete a SiteSchedule resource.
     *
     * @param id
     */
    public static async deleteSiteSchedule(id: number) {
        return GenericAPIs.apiDeleteCall(`sites_schedules/${id}`, this.getApiKey());
    }
};
