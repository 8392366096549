import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgWriteContent: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#F2F2F2' d='M375.364,21.398v469.205c0,11.73-9.597,21.327-21.327,21.327H21.328C9.597,511.93,0,502.333,0,490.603V94.977L94.907,0.07h259.129C365.767,0.07,375.364,9.668,375.364,21.398z' />
            <g>
                <rect x='56.518' y='168.554' fill='#CDD5DF' width='262.328' height='26.66' />
                <rect x='56.518' y='248.532' fill='#CDD5DF' width='262.328' height='26.66' />
                <rect x='56.518' y='329.58' fill='#CDD5DF' width='262.328' height='26.66' />
                <rect x='56.518' y='409.558' fill='#CDD5DF' width='126.895' height='26.66' />
                <rect x='190.878' y='88.576' fill='#CDD5DF' width='126.895' height='26.66' />
            </g>
            <path fill='#65C5B8' d='M94.907,73.65V0.07L0,94.977h73.58C85.31,94.977,94.907,85.38,94.907,73.65z' />
            <polygon fill='#FFCF5C' points='277.117,426.312 264.321,465.767 256.856,491.36 281.382,482.829 320.838,471.1 322.971,428.444 ' />
            <polygon fill='#334A5E' points='281.375,482.873 256.849,491.404 264.314,465.811 ' />
            <path fill='#54C0EB' d='M451.077,281.593L293.253,438.351c-4.266,4.266-4.266,10.664,0,14.929c4.266,4.265,10.664,4.266,14.929,0l157.031-157.03L451.077,281.593z' />
            <g>
                <path fill='#84DBFF' d='M435.566,266.242L278.809,424.065c-4.266,4.266-4.266,10.664,0,14.929c4.266,4.266,10.664,4.266,14.929,0l157.034-157.05L435.566,266.242z' />
                <path fill='#84DBFF' d='M465.546,295.716L308.265,452.873c-4.266,4.266-2.498,11.699,1.768,15.964c4.266,4.265,10.664,4.266,14.929,0l156.757-156.757L465.546,295.716z' />
            </g>
            <rect x='430.273' y='278.599' transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 588.5153 813.7659)' fill='#FFD15C' width='65.042' height='12.796' />
            <path fill='#FF7058' d='M505.462,257.067l-14.929-15.996c-8.531-8.531-21.327-8.531-29.859,0l-15.996,15.996l45.854,45.854l15.996-15.996C513.992,278.394,513.992,265.598,505.462,257.067z' />
        </SvgIcon>
    );
};

export default SvgWriteContent;
