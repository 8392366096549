import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { clearActiveStepper } from '../../store/slices/activeStepperSlice';
import {
    Container,
    Stack,
    Typography,
} from '@mui/material';
import SvgPegaseo from '../Svg/SvgPegaseo';
import RouterLink from '../RouterLink/RouterLink';
import Stepper from '../Stepper/Stepper';
import MainInfo from './MainInfo';
import SecondaryInfo from './SecondaryInfo';
import SectorPreferences from './SectorPreferences';
import StepperValidation from './StepperValidation';

const SignUp: React.FC = () => {
    // Use of redux
    const dispatch = useAppDispatch();

    // useEffect when component is unmounted
    React.useEffect(() => () => {
        // Clear active stepper redux state
        dispatch(clearActiveStepper());
    }, [dispatch]);

    return (
        <Container component='main' sx={{ position: 'relative', padding: 5 }}>
            <Stack direction='row' flexWrap='wrap' justifyContent='space-between' alignItems='center' paddingBottom={4}>
                <SvgPegaseo primary />
                <Typography variant='body2'>
                    {`Vous possédez déjà un compte ? `}
                    <RouterLink anchor='Identfiez-vous' to='/sign_in' />
                </Typography>
            </Stack>
            <Stepper
                headerWidth={2/3}
                steps={[
                    {
                        label: 'Informations personnelles',
                        component: (<MainInfo formikFormId='mainInfo' />),
                        formikFormId: 'mainInfo',
                    },
                    {
                        label: 'Informations de compte',
                        component: (<SecondaryInfo formikFormId='secondaryInfo' />),
                        formikFormId: 'secondaryInfo',
                    },
                    {
                        label: 'Vos préférences',
                        component: (<SectorPreferences />),
                    }
                ]}
                validationComponent={(<StepperValidation />)}
            />
        </Container>
    );
};

export default SignUp;
