import React from 'react';
import OtherAPIs from '../../APIs/OtherAPIs';
import { SxProps, Theme } from '@mui/material';
import FormikAutocomplete from '../FormikComponent/FormikAutocomplete';
import ICompanyType from '../../models/ICompanyType';

const SelectCompanyType: React.FC<{
    name: string,
    label?: string|undefined,
    upperlabel?: string|undefined,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Use of hooks
    const [companyTypes, setCompanyTypes] = React.useState<Array<ICompanyType>|null>(null);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === companyTypes &&
            // Call API to retrieve CompanyTypes
            OtherAPIs.getCompanyTypes()
                // On successful API call, store data returned resources in hook
                .then((data: Array<ICompanyType>|null) => null !== data && setCompanyTypes(data));
    }, [companyTypes]);

    return (
        <FormikAutocomplete
            {...props}
            loading={null === companyTypes}
            options={
                companyTypes ? companyTypes.map(companyType => ({
                    value: String(companyType.id),
                    label: `${companyType.acronym} : ${companyType.name}`,
                })) : []
            }
            sx={props.sx}
        />
    );
};

export default SelectCompanyType;
