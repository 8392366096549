import React from 'react';
import moment from 'moment-timezone';
import { Typography } from '@mui/material';
import IDestinationWebsite from '../../interfaces/IDestinationWebsite';

const SummaryDestinationWebsite: React.FC<{
    destinationWebsite?: IDestinationWebsite|null|undefined,
    autoPublish?: boolean|undefined,
    toPublishAt?: string|null|undefined,
}> = props => {
    return (
        <>
            <Typography>
                {`Validation de l'article : `}
                {
                    <Typography component='span' color='primary'>
                        {
                            // If post has a destination website
                            props.destinationWebsite ? (
                                // If post will be auto validated
                                props.autoPublish ? `Automatique` : `Non renseigné`
                            ) : `Non renseigné`
                        }
                    </Typography>
                }
            </Typography>
            <Typography>
                {`Publication : `}
                {
                    <Typography component='span' color='primary'>
                        {
                            // If post has a publication date
                            props.toPublishAt ?
                                `le ${moment(props.toPublishAt).format('DD/MM/Y à HH:mm')}`
                                : `Non renseigné`
                        }
                    </Typography>
                }
            </Typography>
            {
                props.destinationWebsite && (
                    <>
                        <Typography>
                            {`Thématique du site : `}
                            <Typography component='span' color='primary'>
                                {props.destinationWebsite.thematic.label}
                            </Typography>
                        </Typography>
                        <Typography>
                            {`Site de destination : `}
                            <Typography component='span' color='primary'>
                                {props.destinationWebsite.website.label}
                            </Typography>
                        </Typography>
                        <Typography>
                            {`Catégorie du site : `}
                            <Typography component='span' color='primary'>
                                {props.destinationWebsite.category.name}
                            </Typography>
                        </Typography>
                    </>
                )
            }
        </>
    );
};

export default SummaryDestinationWebsite;
