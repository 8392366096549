import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

// Style to apply to component
const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        bottom: 15,
        left: 20,
        right: 0,
        paddingRight: 20,
    },
});

const Footer: React.FC<{ style?: Style|undefined }> = props => {
    return (
        <View style={{ ...styles.footer, ...props.style }} fixed>
            {props.children}
        </View>
    );
};

export default Footer;
