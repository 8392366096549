import { Theme } from '@mui/material';
import { appStepperControlsHeight } from '../../configs/materialUI';
import ICssStyles from '../../interfaces/ICssStyles';

const makeCssStyles = (theme: Theme): ICssStyles => ({
    stepperControlsContainer: {
        position: 'fixed',
        height: appStepperControlsHeight,
        bottom: 0,
        left: 0,
        width: 1,
        backgroundColor: theme.palette.grey[200],
        // So it does not recover DashboardMenu
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create('padding-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    stepperControls: {
        position: 'relative',
        flexDirection: 'row',
        paddingX: 2,
        paddingY: 1,
        maxWidth: '1400px',
        margin: 'auto',
    },
    currentStep: {
        [theme.breakpoints.down(800)]: {
            display: 'none',
        },
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
    },
    controlButton: {
        transition: 'background-color 0s',
    },
});

export default makeCssStyles;
