import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    breadcrumbs: {
        display: 'inline-flex',
        alignItems: 'center',
        height: '50px',
        width: 1,
        marginBottom: 3,
        lineHeight: 1,
        borderBottom: '1px solid',
        borderColor: 'divider',
    },
};

export default cssStyles;
