import React from 'react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import useInterval from '../../hooks/useInterval';
import { useAppSelector } from '../../store/hooks';
import DraftPostAPIs from '../../APIs/DraftPostAPIs';
import { CircularProgress, Stack, Switch, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IDraftPost from '../../models/IDraftPost';

const interval: number = Number(process.env.REACT_APP_AUTOSAVE_INTERVAL) * 1000;

const SaveDraftPost: React.FC<{
    keyword: string,
    draftPost?: IDraftPost|undefined,
    price?: number|undefined,
    autosave?: boolean|undefined,
}> = props => {
    // Use of hooks
    const [loading, isLoading] = React.useState<string>('');
    const [draftPost, setDraftPost] = React.useState<IDraftPost|null>(props.draftPost ?? null);
    const [autosave, setAutoSave] = React.useState<boolean>(props.autosave ?? false);

    // Use of redux hooks
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);
    const { html, nbrWords } = useAppSelector(state => state.textEditor.content);
    const { score, words } = useAppSelector(state => state.textEditor.optimization);

    // Use of redux-route-dom hook
    const navigate = useNavigate();

    // Callback to handle Post data saving
    const savePost = React.useCallback((loading: string, redirectToPublish?: boolean|undefined) => {
        // Load during request
        isLoading(loading);

        // Construct data to send
        const bodyRequest = {
            user: instanceUserId,
            keyword: props.keyword,
            html,
            nbrWords,
            optimization: score,
            optimizationWords: words,
            price: props.price,
        };

        // Either create DraftPost or update it if it already exists
        (
            null === draftPost ?
                // Call API to create a DraftPost
                DraftPostAPIs.postDraftPost(bodyRequest)
                // Call API to update DraftPost
                : DraftPostAPIs.patchDraftPost(draftPost.id, bodyRequest)
        )
            // On succesful API call
            .then((data) => {
                // Save DraftPost for later use
                if (null !== data) {
                    true === redirectToPublish ?
                        navigate('/dashboard/documents/draft_posts_publish', { state: data.id })
                        : setDraftPost(data);
                }
            })
            // In any cases, stop loading
            .finally(() => isLoading(''));
    }, [instanceUserId, props.keyword, props.price, html, nbrWords, score, words, draftPost, navigate]);

    // useInterval to handle autosave
    useInterval(() => {
        true === autosave && savePost('auto');
    }, interval, [autosave]);

    return (
        <Stack alignItems='start'>
            <Stack direction='row' gap={2}>
                <LoadingButton
                    variant='outlined'
                    loading={'manual' === loading}
                    onClick={() => savePost('manual')}
                >
                    {`Enregistrer dans mes documents`}
                </LoadingButton>
                <LoadingButton
                    variant='outlined'
                    loading={'manual' === loading}
                    onClick={() => savePost('manual', true)}
                >
                    {`Enregistrer et publier`}
                </LoadingButton>
            </Stack>
            <Stack direction='row' alignItems='center' gap={1}>
                <Switch size='small' checked={autosave} onChange={(e) => setAutoSave(e.target.checked)} />
                <Typography variant='caption'>{`Sauvegarde auto.`}</Typography>
                {
                    'auto' === loading && (
                        <CircularProgress size={15} />
                    )
                }
            </Stack>
            <Typography variant='caption'>
                {`Dernière sauvegarde : ${draftPost?.updatedAt || draftPost?.createdAt ? moment(draftPost?.updatedAt ?? draftPost?.createdAt).format('DD/MM/YYYY à HH:mm:ss') : 'aucune'}`}
            </Typography>
        </Stack>
    );
};

export default SaveDraftPost;
