import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IActiveStepperState {
    activeStep: number,
    data: any,
    loading: boolean,
};

const initialState: IActiveStepperState = {
    activeStep: 0,
    data: {},
    loading: false,
};

export const slice = createSlice({
    name: 'activeStepperState',
    initialState,
    reducers: {
        setActiveStep: (state: IActiveStepperState, action: PayloadAction<number>) => {
            state.activeStep = action.payload;
        },
        incActiveStep: (state: IActiveStepperState) => {
            state.activeStep = state.activeStep + 1;
        },
        decActiveStep: (state: IActiveStepperState) => {
            if (0 < state.activeStep) {
                state.activeStep = state.activeStep - 1;
            }
        },
        incActiveStepBy: (state: IActiveStepperState, action: PayloadAction<number>) => {
            state.activeStep = state.activeStep + action.payload;
        },
        decActiveStepBy: (state: IActiveStepperState, action: PayloadAction<number>) => {
            0 <= state.activeStep - action.payload ?
                state.activeStep = state.activeStep - action.payload
                : state.activeStep = 0;
        },
        setActiveStepperData: (state: IActiveStepperState, action: PayloadAction<{ data: any, action?: 'inc'|'dec'|undefined }>) => {
            state.data = { ...state.data, ...action.payload.data };
            if (undefined !== action.payload.action) {
                if ('dec' === action.payload.action && 0 < state.activeStep) {
                    state.activeStep = state.activeStep - 1;
                } else if ('inc' === action.payload.action) {
                    state.activeStep = state.activeStep + 1;
                }
            }
        },
        setStepperIsLoading: (state: IActiveStepperState, action: PayloadAction<boolean>) => {
            if (action.payload !== state.loading) {
                state.loading = action.payload;
            }
        },
        clearActiveStepper: (state: IActiveStepperState) => {
            // Search for URI in data
            // Retrieve values
            const dataValues: Array<any> = Object.values(state.data);
            // For each value
            dataValues.forEach((value) => {
                // If value type is string and stats with 'blob:', revoke value URI
                'string' === typeof value && value.startsWith('blob:') && URL.revokeObjectURL(value);
            });

            // Return a new value and force createSlice to replace the old one.
            return initialState;
        },
    },
});

export const {
    setActiveStep,
    incActiveStep,
    decActiveStep,
    incActiveStepBy,
    decActiveStepBy,
    setActiveStepperData,
    setStepperIsLoading,
    clearActiveStepper,
} = slice.actions;

export default slice.reducer;
