import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    Chip,
    Typography,
    Stack,
    Button,
} from '@mui/material';
import ModalSingleInput from '../Modal/ModalSingleInput';

const CategoriesManagement: React.FC<{ onChange: (categories: Array<string>) => void }> = props => {
    // Use of hooks
    const [categories, setCategories] = React.useState<Array<string>>([]);

    // Use of redux
    const dispatch = useAppDispatch();

    // useEffect each time our hook value changes
    React.useEffect(() => {
        // Call given prop callback
        props.onChange(categories);
    }, [props, categories])

    return (
        <>
            <ModalSingleInput
                modalTitle='Nouvelle catégorie du site'
                inputLabel='Nom de la catégorie'
                onValidation={value => setCategories([ ...categories, value ])}
            />
            {
                0 === categories.length ? (
                    <Typography variant='body2'>
                        {`Aucune catégorie configurée ...`}
                    </Typography>
                ) : (
                    <Stack direction='row' flexWrap='wrap' gap={1}>
                        {
                            categories.map((value: string, index: number) => (
                                <Chip
                                    key={index}
                                    label={value}
                                    variant='outlined'
                                    onDelete={() => setCategories(categories.filter((_, key) => key !== index))}
                                />
                            ))
                        }
                    </Stack>
                )
            }
            <Button
                variant='outlined'
                onClick={() => dispatch(openModal({ name: 'singleInputModal' }))}
                sx={{ marginTop: 3 }}
            >
                {`Ajouter une catégorie`}
            </Button>
        </>
    );
};

export default CategoriesManagement;
