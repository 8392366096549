import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgManageScheduledPosts: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <g>
                <path fill='#CDD6E0' d='M251.733,222.933L297.6,166.4c-12.8-6.4-23.467-16-32-27.733l-46.933,57.6C231.467,202.667,242.133,212.267,251.733,222.933z'/>
                <path fill='#CDD6E0' d='M353.067,374.4l-66.133-24.533c-3.2,13.867-8.533,27.733-14.933,39.467l66.133,24.533C339.2,400,344.533,386.133,353.067,374.4z'/>
            </g>
            <path fill='#FF7058' d='M297.6,165.333l-10.667,12.8l-25.6,32l-10.667,12.8c-9.6-10.667-21.333-19.2-33.067-26.667l10.667-12.8l26.667-33.067l10.667-12.8C274.133,149.333,284.8,158.933,297.6,165.333z'/>
            <path fill='#FFD15C' d='M353.067,374.4c-8.533,11.733-12.8,25.6-14.933,40.533l-16-5.333l-35.2-12.8L272,391.467c6.4-12.8,11.733-25.6,14.933-40.533l14.933,5.333l35.2,12.8L353.067,374.4z'/>
            <circle fill='#54C0EB' cx='145.07' cy='320' r='145.07'/>
            <path fill='#84DBFF' d='M42.667,336c-8.533,0-16-7.467-16-16c0-65.067,53.333-118.4,118.4-118.4c8.533,0,16,7.467,16,16s-7.467,16-16,16c-48,0-86.4,38.4-86.4,86.4C58.667,329.6,51.2,336,42.667,336z'/>
            <circle fill='#FF7058' cx='337.07' cy='87.47' r='87.47'/>
            <path fill='#F1543F' d='M337.067,153.6c-36.267,0-66.133-29.867-66.133-66.133s29.867-66.133,66.133-66.133S403.2,51.2,403.2,87.467S374.4,153.6,337.067,153.6z'/>
            <circle fill='#FFD15C' cx='424.53' cy='424.53' r='87.47'/>
            <path fill='#F8B64C' d='M424.533,489.6c-36.267,0-66.133-29.867-66.133-66.133s29.867-66.133,66.133-66.133s66.133,29.867,66.133,66.133S460.8,489.6,424.533,489.6z'/>
            <path fill='#F1543F' d='M229.333,182.4c11.733,7.467,23.467,17.067,33.067,26.667l-10.667,12.8c-9.6-10.667-21.333-19.2-33.067-26.667L229.333,182.4z'/>
            <path fill='#F8B64C' d='M301.867,356.267c-3.2,13.867-8.533,27.733-14.933,39.467L272,390.4c6.4-12.8,11.733-25.6,14.933-40.533L301.867,356.267z'/>
        </SvgIcon>
    );
};

export default SvgManageScheduledPosts;
