import React from 'react';
import { setIn } from 'final-form';
import { AnyObjectSchema, ValidationError, object } from 'yup';
import { ObjectShape } from 'yup/lib/object';

/**
 * @type T types of all fields in the form, default to unknown
 */
export type TValidationSchema<T = unknown> = (val: T) => Promise<Record<string, string> | undefined>;

/**
 * Sets the `innerError.message` in an `errors` object at the key
 * defined by `innerError.path`.
 */
const setInError = (errors: Record<string, string>, innerError: ValidationError) =>
    setIn(errors, innerError.path ?? '', innerError.message);

/**
 * Empty object map with no prototype. Used as default
 * value for reducing the `err.inner` array of errors
 * from a `yup~ValidationError`.
 */
const emptyObj: {} = Object.create(null);

/**
 * Takes a `yup` validation schema and returns a function that expects
 * a map of values to validate. If the validation passes, the function resolves to `undefined`
 * (signalling that the values are valid). If the validation doesn't pass, it resolves
 * to a map of invalid field names to errors.
 */
function makeValidate<T = Record<string, unknown>>(schema: AnyObjectSchema): (val: T) => Promise<undefined | Record<string, string>> {
    return async function validate(values: T) {
        try {
            await schema.validate(values, { abortEarly: false });
        } catch (error) {
            return (error as ValidationError).inner.reduce(setInError, emptyObj);
        }
    };
};

/**
 * @type T types of all fields in the form, default to unknown
 */
function useValidationSchema<T>(schema: ObjectShape) {
    return React.useMemo(() => makeValidate<T>(object(schema)), [schema]);
}

export default useValidationSchema;
