import React from 'react';
import { Typography } from '@mui/material';

const Copyright: React.FC<{
    align?: 'right'|'left'|'inherit'|'center'|'justify'|undefined,
    paddingTop?: number|undefined,
}> = props => {
    return (
        <Typography
            paddingTop={props.paddingTop}
            component='p'
            color='textSecondary'
            align={props.align ?? 'center'}
            variant='caption'
        >
            {`Copyright © ${process.env.REACT_APP_NAME}`}
            {` ${new Date().getFullYear()}.`}
        </Typography>
    );
};

export default Copyright;
