import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';

const KeywordDisplay: React.FC<{ keyword: string }> = props => {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Typography variant='h5'>
                        {`Mot clé de l'article`}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography component='p' variant='h6' color='primary'>
                        {props.keyword}
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant='middle' sx={{ marginY: 4 }} />
        </>
    );
};

export default KeywordDisplay;
