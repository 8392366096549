import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * OtherAPIs class.
 */
export default class OtherAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of ScopesGroups.
     *
     * @param query
     */
    public static async getScopesGroups(query?: any) {
        return GenericAPIs.apiGetCall('scopes_groups', this.getApiKey(), query);
    }

    /**
     * Get collection of OrderStatus.
     *
     * @param query
     */
    public static async getOrderStatuses(query?: any) {
        return GenericAPIs.apiGetCall('order_statuses', this.getApiKey(), query);
    }

    /**
     * Get collection of ActivitySectors.
     *
     * @param query
     */
    public static async getActivitySectors(query?: any) {
        return GenericAPIs.apiGetCall('activity_sectors', null, query);
    }

    /**
     * Get collection of SyntaxLevels.
     *
     * @param query
     */
    public static async getSyntaxLevels(query?: any) {
        return GenericAPIs.apiGetCall('syntax_levels', this.getApiKey(), query);
    }

    /**
     * Get collection of CompanyTypes.
     *
     * @param query
     */
    public static async getCompanyTypes(query?: any) {
        return GenericAPIs.apiGetCall('company_types', this.getApiKey(), query);
    }

    /**
     * Get collection of Countries.
     *
     * @param query
     */
    public static async getCountries(query?: any) {
        return GenericAPIs.apiGetCall('countries', this.getApiKey(), query);
    }

    /**
     * Post a UploadFile resource.
     *
     * @param file
     * @param filename
     */
    public static async postUploadFile(file: File|Blob, filename?: string|undefined) {
        // Create FormData ressource with file
        const formData = new FormData();
        formData.append('file', file);
        filename && formData.append('filename', filename);

        // Call API using apiPostFormDataCall
        return GenericAPIs.apiPostFormDataCall('upload_file', formData, this.getApiKey());
    }

    /**
     * Post a BannedWord
     *
     * @param body
     */
    public static async postBannedWord(body: any) {
        return GenericAPIs.apiPostCall('banned_words', body, this.getApiKey());
    }
};
