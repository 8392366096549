import React from 'react';
import Ipv4Management from './Ipv4Management';

const DevelopmentArea: React.FC = () => {
    return (
        <>
            <Ipv4Management />
        </>
    );
};

export default DevelopmentArea;
