import React from 'react';
import { useAppSelector } from '../../store/hooks';
import Tools from '../../helpers/Tools';
import stringToIcon from '../../configs/stringToIcon';
import {
    Paper,
    Tooltip,
    Typography,
    Box,
    Stack,
    Avatar,
    Divider,
} from '@mui/material';
import MissingBioTooltip from '../MissingBioTooltip/MissingBioTooltip';
import FirstServedButton from './FirstServedButton';
import defaultOrderImage from '../../images/default_order_image.png';
import IOrder from '../../models/IOrder';
import cssStyles from './PublicPlaceStyles';

// Define first served button tooltip message
const tooltipMessage = "Vous ne pouvez pas rédiger cet article car vous avez déjà atteint le nombre maximum d'articles à rédiger.";

const PublicPlaceOrder: React.FC<{
    anchor: ((node?: Element | null | undefined) => void) | undefined,
    order: IOrder,
    disableFirstServed: boolean,
}> = props => {
    // Use of redux
    const instanceUserHasBio: boolean = useAppSelector(state => state.instance.user!.bio);

    // Define avatar color for company if any logo is defined
    const companyColor = props.order.project.account.company!.logo ?
        undefined : Tools.stringToColor(props.order.project.account.company!.name);

    // Retrieve Icon of Order ActivitySector
    const Icon = stringToIcon(props.order.activitySector.icon);

    // Format order brief for display
    const orderBrief = props.order.brief ?
        `${props.order.brief.slice(0, 300)}${300 < props.order.brief.length ? ' ...' : ''}`
        : undefined;

    return (
        <Paper ref={props.anchor} sx={cssStyles.orderBox}>
            <Tooltip title={props.order.syntaxLevel.description} followCursor>
                <Typography
                    align='center'
                    sx={{
                        ...cssStyles.orderBoxSyntax,
                        backgroundColor: props.order.syntaxLevel.color,
                        color: theme => theme.palette.getContrastText(props.order.syntaxLevel.color),
                    }}
                >
                    {props.order.syntaxLevel.name}
                </Typography>
            </Tooltip>
            <Box sx={{
                background: `url(${props.order.imageUrl ?? defaultOrderImage}) no-repeat center`,
                backgroundSize: '100%',
            }}>
                {/** Following Stack is used for padding & linear gradient over background image */}
                <Stack direction='row' alignItems='center' spacing={10} sx={cssStyles.orderBoxHeader}>
                        <Stack alignItems='center' spacing={1}>
                            <Avatar
                                alt=''
                                sx={{
                                    ...cssStyles.orderCompanyLogo,
                                    backgroundColor: companyColor,
                                    color: theme => companyColor ? theme.palette.getContrastText(companyColor) : undefined,
                                }}
                                children={props.order.project.account.company!.name[0]}
                            />
                        </Stack>
                        <Box>
                            <Typography variant='h6' component='p'>
                                {`Mot clé de l'article : `}
                                <Typography variant='h6' component='span' fontWeight='bold'>
                                    {props.order.keyword.name}
                                </Typography>
                            </Typography>
                            {
                                props.order.title && (
                                    <Typography fontWeight={700} color='text.secondary'>
                                        {props.order.title}
                                    </Typography>
                                )
                            }
                            <Stack direction='row' alignItems='center' paddingTop={1} spacing={1}>
                                <Icon fontSize='small' />
                                <Typography variant='body2' fontStyle='italic'>
                                    {props.order.activitySector.name}
                                </Typography>
                            </Stack>
                        </Box>
                </Stack>
            </Box>
            <Stack sx={{ padding: 4 }}>
                {
                    orderBrief && (
                        <Typography variant='body2' color='primary' fontWeight='bold' paddingBottom={2}>
                            {`Consignes de rédaction : `}
                            <Typography variant='body2' component='span' color='text.primary'>
                                {orderBrief}
                            </Typography>
                        </Typography>
                    )
                }
                <Stack direction='row' flexWrap='wrap' justifyContent='space-between' alignItems='center' rowGap={2}>
                    <Stack direction='row' flexWrap='wrap' rowGap={2} columnGap={6}>
                        {
                            props.order.minOptimization ? (
                                <Box>
                                    <Typography variant='h4' color='primary' fontWeight='bold'>
                                        {`${props.order.minOptimization} %`}
                                    </Typography>
                                    <Typography>
                                        {`d'optimisation`}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography>
                                        {`Optimisation`}
                                    </Typography>
                                    <Typography variant='h4' color='primary' fontWeight='bold'>
                                        {`Libre`}
                                    </Typography>
                                </Box>
                            )
                        }
                        <Divider orientation='vertical' flexItem />
                        <Box>
                            <Typography variant='h4' color='primary' fontWeight='bold'>
                                {`${props.order.minNbrWords}`}
                            </Typography>
                            <Typography>
                                {`mots min.`}
                            </Typography>
                        </Box>
                        <Divider orientation='vertical' flexItem />
                        <Box>
                            <Typography variant='h4' color='primary' fontWeight='bold'>
                                {`${props.order.price} € HT`}
                            </Typography>
                            <Typography>
                                {`pour la rédaction`}
                            </Typography>
                        </Box>
                        <Divider orientation='vertical' flexItem />
                        <Box>
                            <Typography variant='h4' color='primary' fontWeight='bold'>
                                {`${(Number(props.order.price) / props.order.minNbrWords * 100).toFixed(1)}`}
                                {` c€ HT`}
                            </Typography>
                            <Typography>
                                {`par mot`}
                            </Typography>
                        </Box>
                    </Stack>
                    {
                        false === instanceUserHasBio ? (
                            <MissingBioTooltip wrapWithSpan>
                                <FirstServedButton disabled />
                            </MissingBioTooltip>
                        ) : true === props.disableFirstServed ? (
                            <Tooltip title={tooltipMessage}>
                                <Box component='span'>
                                    <FirstServedButton disabled />
                                </Box>
                            </Tooltip>
                        ) : <FirstServedButton order={props.order} />
                    }
                </Stack>
            </Stack>
        </Paper>
    );
};

export default PublicPlaceOrder;
