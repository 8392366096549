import React from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { useAppSelector } from '../../store/hooks';
import useValidationSchema from '../../hooks/useValidationSchema';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import SiteScheduleScheduleAPIs from '../../APIs/SiteScheduleAPIs';
import { Typography } from '@mui/material';
import CategoriesSchedule, { categoriesScheduleSchema, CategoryScheduleType } from './CategoriesSchedule';
import FinalForm from '../FinalForm/FinalForm';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import Spinner from '../Spinner/Spinner';
import ISelectOption from '../../interfaces/ISelectOption';
import IWpCategory from '../../interfaces/IWpCategory';
import ISite from '../../models/ISite';

type FormType = {
    project: ISelectOption|null,
    postType: ISelectOption|null,
    activitySector: ISelectOption|null,
    categories: Array<CategoryScheduleType>|null,
};

const ScheduleSitePosts: React.FC = () => {
    // Use of hooks
    const [categories, setCategories] = React.useState<Array<IWpCategory>|null>(null);

    // Use of react-router-dom hooks
    const navigate = useNavigate();
    const location = useLocation();
    const site = location.state as ISite;

    // Use of redux hook
    const instanceUserId = useAppSelector(state => state.instance.user!.id);

    // Form validation schema
    const validationSchema = useValidationSchema({
        project: Yup.object().nullable().required('Veuillez renseigner un projet'),
        postType: Yup.object().nullable().required("Veuillez renseigner le type d'article à publier"),
        activitySector: Yup.object().nullable().required("Veuillez renseigner un secteur d'activité"),
        categories: categoriesScheduleSchema,
    });

    // Define form initial values
    const initialValues: FormType = {
        project: null,
        postType: null,
        activitySector: null,
        categories: categories?.map(category => ({
            category: category.name,
            publishData: [],
            keywordTitles: [],
        })) ?? null,
    };

    // Callback to handle form submit & creating publication schedules
    const handleSubmit = (values: FormType) => {
        // Prepare body request
        const bodyRequest = {
            user: instanceUserId,
            site: site.id,
            project: values.project!.value,
            postType: values.postType!.value,
            activitySector: values.activitySector!.value,
            publicationData: values.categories!.map(categoryData => ({
                category: categories!.find(category => category.name === categoryData.category)!,
                publishData: categoryData.publishData.map(publishData => ({
                    ...publishData,
                    estimatedNbrWords: true === publishData.optimized ? undefined : publishData.estimatedNbrWords,
                    endAt: publishData.endAt ?? null,
                    periodicity: true === publishData.hasPeriodicity ?
                        publishData.periodicityType?.periodicity ?? null
                        : null,
                })),
                keywordTitles: categoryData.keywordTitles.map(keywordTitle => ({
                    ...keywordTitle,
                    keyword: keywordTitle.keyword.value,
                })),
            })),
        };

        // Call API to create all SiteSchedules
        SiteScheduleScheduleAPIs.postMultipleSiteSchedules(bodyRequest)
            // On success, redirect to the list of Sites
            .then((data: ISite) => null !== data && navigate('../'));
    };

    // useEffect to retrieve site categories on mount
    React.useEffect(() => {
        null === categories &&
            BridgeAPIs.getWordPressCategoriesBySiteId(site.id)
                .then((data: Array<IWpCategory>|null) => null !== data && setCategories(data));
    }, [categories, site]);

    return (
        null !== categories ? (
            <FinalForm<FormType>
                display='flex'
                flexDirection='column'
                gap={2}
                mutators={{ ...arrayMutators }}
                validateSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={values => handleSubmit(values)}
            >
                <Typography variant='h6' gutterBottom>
                    {`Configurer les catégories trouvées pour le site ${site.domain}`}
                </Typography>
                <CategoriesSchedule name='categories' categories={categories.map(category => category.name)} />
                <FinalSubmitButton variant='contained' enabledOnInvalid>
                    {`Valider`}
                </FinalSubmitButton>
            </FinalForm>
        ) : (
            <Spinner
                size={50}
                height={100}
                typographyProps={{
                    children: 'Chargement des catégories du site...',
                    variant: 'body2',
                }}
            />
        )
    );
};

export default ScheduleSitePosts;
