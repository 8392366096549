import React from 'react';
import useTimeout from '../../hooks/useTimeout';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal, IActiveModalState } from '../../store/slices/activeModalSlice';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import {
    SxProps,
    Theme,
    Box,
    TextField,
    InputAdornment,
    ImageList,
    ImageListItem,
    Pagination,
    Skeleton,
    Stack,
    Button,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import GenericModal from './GenericModal';
import ImageWithLoad from '../ImageWithLoad/ImageWithLoad';
import cssStyles from './ModalStyles';

interface IModalPixabayPicker {
    page: number,
    pagination: number|null,
    selection: string|number|null,
    data: any,
    filter: string,
};

const ModalPixabayPicker: React.FC<{
    defaultKeyword?: string|null|undefined,
    onClose?: (() => void)|undefined,
    onValidation?: ((imageSrc: string) => void)|undefined,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>('pixabayPicker');
    const initialModalData = React.useRef<IModalPixabayPicker>({
        page: 1,
        pagination: null,
        selection: null,
        data: null,
        filter: props.defaultKeyword ?? '',
    })
    const [modalData, setModalData] = React.useState<IModalPixabayPicker>(initialModalData.current);

    // Use of redux
    const dispatch = useAppDispatch();
    const activeModalState: IActiveModalState = useAppSelector(state => state.activeModal);

    // Define const
    const itemPerPage: number = 12;
    const imageHeight: number = 160;

    // Callback used during modal validation
    const handleModalValidation = React.useCallback(() => {
        null !== modalData.data && null !== modalData.selection &&
            // Call given prop callback to validate modal
            props.onValidation && props.onValidation((modalData.selection as string));
        // Submission also close modal
        dispatch(closeModal());
    }, [props, modalData.data, modalData.selection, dispatch])

    // Callback used to call Pixabay API to retrieve hits
    const askPixabayHitsAPI = React.useCallback((filter: string, page: number) => {
        // Use API bridge
        BridgeAPIs.getPixabayHits({ keyword: filter, page: page })
            // On succesful API call
            .then((data: any) =>
                data && setModalData(
                    modalData => ({
                        ...modalData,
                        data: data,
                        pagination: 0 < data.hits.length ? Math.ceil(data.totalHits/itemPerPage) : null,
                    })
                )
            );
    }, [])

    // useEffect as an useTimeout
    useTimeout(() => {
        // Modal is open & filter not empty
        modalName.current === activeModalState.name && '' !== modalData.filter &&
            // Ask for new Pixabay hits
            askPixabayHitsAPI(modalData.filter, modalData.page);
    }, 1000, [activeModalState.name, modalData.filter, modalData.page])

    return (
        <GenericModal
            name={modalName.current}
            title='Sélectionner une image depuis Pixabay'
            onClose={() => setModalData(initialModalData.current)}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <TextField
                    variant='standard'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    placeholder='Chercher sur Pixabay'
                    value={modalData.filter}
                    sx={cssStyles.pixabaySearchInput}
                    onChange={(event) => setModalData({ ...modalData, filter: event.currentTarget.value })}
                />
                {
                    null !== modalData.pagination && modalData.pagination > 1 && (
                        <Pagination
                            shape='rounded'
                            size='small'
                            boundaryCount={2}
                            count={modalData.pagination}
                            page={modalData.page}
                            onChange={(_, page: number) => setModalData({ ...modalData, page: page })}
                        />
                    )
                }
            </Stack>
            <Box component={ImageList} rowHeight={imageHeight} minHeight={488} marginY={3} cols={4} gap={6} alignContent='start'>
                {
                    null === modalData.data ||
                        undefined === modalData.data.hits ||
                            0 === modalData.data.hits.length ? (
                                [...Array(itemPerPage)].map((_, index: number) => (
                                    <Skeleton key={index} variant='rectangular' height={imageHeight} />
                                ))
                    ) : modalData.data.hits.map((hit: any, index: number) => (
                        <ImageListItem key={index}>
                            <ImageWithLoad
                                imageSrc={hit.largeImageURL}
                                height={imageHeight}
                                width={1}
                                onClick={() => setModalData({ ...modalData, selection: hit.webformatURL })}
                                sx={{
                                    ...cssStyles.imageListItem,
                                    ...(hit.webformatURL === modalData.selection && cssStyles.imageListItemSelected),
                                } as SxProps<Theme>}
                            />
                        </ImageListItem>
                    ))
                }
            </Box>
            <Stack direction='row' justifyContent='flex-end'>
                <Button
                    disabled={null === modalData.selection}
                    variant='outlined'
                    color='inherit'
                    onClick={handleModalValidation}
                >
                    {`Valider`}
                </Button>
            </Stack>
        </GenericModal>
    );
};

export default ModalPixabayPicker;
