import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import moment from 'moment-timezone';
import {
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import SvgStyledBox from '../Svg/SvgStyledBox';
import Countdown from '../Countdown/Countdown';
import cssStyles from './HomeEditorStyles';

const CountdownBox: React.FC<{
    date: moment.MomentInput|null|undefined,
    color: string,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Svg styled box for countdowns background
    const svgString = encodeURIComponent(renderToStaticMarkup(
        <SvgStyledBox backgroundColor='whitesmoke' color={props.color} />
    ));

    return (
        <Typography
            color={props.color}
            sx={{
                ...cssStyles.countdownText,
                ...props.sx,
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: 1,
                    height: 1,
                    zIndex: -1,
                    background: `url('data:image/svg+xml;utf8,${svgString}') no-repeat`,
                },
            } as SxProps<Theme>}
        >
            {props.date ? (<Countdown date={props.date} />) : '__:__:__'}
        </Typography>
    );
};

export default CountdownBox;
