import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { Button, Grid, Typography } from '@mui/material';
import FormikInput from '../FormikComponent/FormikInput';

const KeywordChoice: React.FC<{ onChoice: (keyword: string) => void }> = props => {
    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        keyword: Yup
            .string()
            .required('Veuillez spécifier un mot-clé'),
    });

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Call given prop callback
        props.onChoice(values.keyword);
    }, [props]);

    return (
        <Formik
            initialValues={{ keyword: '' }}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form>
                    <Grid container spacing={3} paddingBottom={4}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h5' gutterBottom>
                                {`Mot clé de l'article`}
                            </Typography>
                            <Typography variant='body2'>
                                {`Ce mot clé sera utilisé pour calculer le score d'optimisation de l'article et
                                vous proposez des mots pertinents à inclure dans votre contenu.`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormikInput
                                name='keyword'
                                upperLabel='Mot-clé'
                                size='medium'
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type='submit'
                        variant='contained'
                        disabled={!formikProps.isValid}
                    >
                        {`Valider & commencer à rédiger`}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default KeywordChoice;
