import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import OrderAPIs from '../../APIs/OrderAPIs';
import { Stack } from '@mui/material';
import GenericModal from './GenericModal';
import OrderSummary from '../OrderSummary/OrderSummary';
import { privacyOptions, publicOptions } from '../OrderForm/SecondaryInfo';
import { imageSelectionOptions } from '../OrderForm/ImageSelection';
import Spinner from '../Spinner/Spinner';
import IRadioOption from '../../interfaces/IRadioOption';
import IOrder from '../../models/IOrder';

const ModalOrderSummary: React.FC<{
    name?: string|undefined,
    width?: number|string|undefined,
}> = props => {
    // Use of hooks
    const [order, setOrder] = React.useState<IOrder|null>(null);
    const modalName = React.useRef<string>(props.name ?? 'orderSummaryModal');

    // Use of redux
    const dispatch = useAppDispatch();
    const orderId: number|undefined = useAppSelector(order => order.activeModal.params?.orderId);
    const isOpened: boolean = useAppSelector(state => state.activeModal.name === modalName.current);

    // Callback triggered when closing modal
    const handleClose = React.useCallback(() => {
        // Reset order state
        setOrder(null);
        // Close modal
        dispatch(closeModal());
    }, [dispatch]);

    // useEffect whenever orderId value changes
    React.useEffect(() => {
        // Update order state
        true === isOpened && orderId && null === order &&
            OrderAPIs.getOrder({ id: orderId, detailled: true })
                .then((data: Array<IOrder>|null) =>
                    true === isOpened && data && 1 === data.length &&
                        setOrder(data[0])
                );
    }, [isOpened, order, orderId]);

    return (
        <GenericModal
            name={modalName.current}
            title={`Résumé de la commande`}
            width={props.width ?? 2/3}
            onClose={handleClose}
        >
            {
                order ? (
                    <Stack alignItems='start' spacing={2}>
                        <OrderSummary
                            activitySector={{ label: order.activitySector.name, value: String(order.activitySector.id), icon: order.activitySector.icon }}
                            keyword={{ label: order.keyword.name, value: String(order.keyword.id) }}
                            syntaxLevel={{ label: order.syntaxLevel.name, value: String(order.syntaxLevel.id) }}
                            postType={{ label: order.postType.name, value: String(order.postType.id) }}
                            imageChoice={imageSelectionOptions.find((option: IRadioOption) => option.value === (order.imageKeyword ? 'kw' : null !== order.imageUrl ? 'file' : 'none'))!}
                            privacy={true === order.isPublic ? privacyOptions[0] : privacyOptions[1]}
                            imageUploaded={order.imageUrl ?? undefined}
                            imageBank={order.imageUrl ?? undefined}
                            imageKeyword={order.imageKeyword ?? undefined}
                            title={order.title?? undefined}
                            mandatoryLinks={order.links}
                            minNbrWords={order.minNbrWords}
                            freeOptimization={null === order.minOptimization}
                            minOptimization={order.minOptimization ?? undefined}
                            metaDesc={order.metaDesc ?? undefined}
                            destinationWebsite={
                                order.site && order.siteCategory ? {
                                    thematic: { label: order.site.thematic.name, value: String(order.site.thematic.id) },
                                    website: { label: order.site.domain, value: String(order.site.id) },
                                    category: order.siteCategory,
                                } : undefined
                            }
                            price={order.price ? Number(order.price) : undefined}
                            publicChoice={
                                true === order.isPublic ?
                                    true === order.isByApplyment ?
                                        // Applyment
                                        publicOptions[0]
                                        : true === order.isFirstServed ?
                                            // First served
                                            publicOptions[1]
                                            // Proposition
                                            : publicOptions[2]
                                        : undefined
                            }
                            editor={order.editor ?? order.proposedEditor ?? undefined}
                            autoPublish={order.autoPublish}
                            toPublishAt={order.toPublishAt ?? undefined}
                            deadlineAt={order.deadlineAt ?? undefined}
                            brief={order.brief ?? undefined}
                            shares={order.sharedUsers ?? undefined}
                        />
                    </Stack>
                ) : (<Spinner />)
            }
        </GenericModal>
    );
};

export default ModalOrderSummary;
