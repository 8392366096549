import React from 'react';
import { Stack, Typography, Select, SelectChangeEvent, MenuItem, IconButton } from '@mui/material';
import { Check } from '@mui/icons-material';
import IOrder from '../../models/IOrder';

export type SortType = { value: string, label: string, type: 'string'|'number', getValue: (order: IOrder) => string|number };

// Define sort options
const sortOptions: Array<SortType> = [
    { value: 'publishedAt', label: 'Date de publication', type: 'string', getValue: (order: IOrder) => order.post?.publishedAt ?? '' },
    { value: 'website', label: 'Site de publication', type: 'string', getValue: (order: IOrder) => order.site?.domain ?? new URL(order.post!.wpPostLink!).hostname.replace('www.', '') },
    { value: 'postType', label: "Type d'article", type: 'string', getValue: (order: IOrder) => order.postType.name },
    { value: 'optimization', label: "Score d'optimisation", type: 'number', getValue: (order: IOrder) => order.post?.optimization ?? 0 },
    { value: 'Nombre de mots', label: 'Nombre de mots', type: 'number', getValue: (order: IOrder) => order.post?.nbrWords ?? 0 },
];

const SortOrders: React.FC<{ onChange: (sortBy: SortType, orderBy: string) => void }> = props => {
    // Use of hooks
    const [sortBy, setSortBy] = React.useState<SortType>(sortOptions[0]);
    const [orderBy, setOrderBy] = React.useState<string>('desc');

    return (
        <Stack direction='row' alignItems='flex-start' gap={2}>
            <Stack>
                <Typography variant='body2'>{`Trier par`}</Typography>
                <Select
                    value={sortBy.value}
                    size='small'
                    onChange={(event: SelectChangeEvent) => setSortBy(sortOptions.find(option => option.value === event.target.value)!)}
                >
                    {sortOptions.map(option => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                </Select>
            </Stack>
            <Stack>
                <Typography variant='body2'>{`Ordre d'affichage`}</Typography>
                <Select
                    value={orderBy}
                    size='small'
                    onChange={(event: SelectChangeEvent) => setOrderBy(event.target.value)}
                >
                    <MenuItem value='asc'>{`Croissant`}</MenuItem>
                    <MenuItem value='desc'>{`Décroissant`}</MenuItem>
                </Select>
            </Stack>
            <IconButton
                color='success'
                sx={{ alignSelf: 'flex-end' }}
                onClick={() => {
                    props.onChange(sortBy, orderBy);
                }}
            >
                <Check fontSize='inherit' />
            </IconButton>
        </Stack>
    );
};

export default SortOrders;
