import { useRef, useEffect, useCallback } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';

type IUseSSE = (topic: string, onEventSent: (data: any) => void, heartbeat?: number|undefined) => void;

/**
 * Trigger given callback whenever a message event is sent on given topic
 *
 * @param topic       mercure topic
 * @param onEventSent callback onmessage
 * @param heartbeat   default set to 60 sec.
 */
const useSSE: IUseSSE = (topic, onEventSent, heartbeat = 60) => {
    // Use of hooks
    const eventSourceRef = useRef<EventSourcePolyfill|null>(null);
    const intervalRef = useRef<NodeJS.Timeout|null>(null);
    const topicURIRef = useRef<string>(`${process.env.REACT_APP_API_URL}${topic}`);

    // useCallback used to subscribe to a specific mercure topic
    const subscribeToTopic = useCallback(() => {
        // Create a polyfill event source
        const eventSource = new EventSourcePolyfill(
            `${process.env.REACT_APP_MERCURE_HUB_URL}?topic=${encodeURIComponent(topicURIRef.current)}`,
            {
                headers: { Authorization: `Bearer ${process.env.REACT_APP_MERCURE_HUB_JWT}` },
                // Reconnect every 2 min.
                heartbeatTimeout: 120000,
            }
        );

        // Console log info if development env
        eventSource.onopen = _ => 'dev' === process.env.REACT_APP_ENV &&
            console.info(`OPEN mercure subscription for ${topicURIRef.current}`);

        // Call given callback onmessage with parsed data
        eventSource.onmessage = ({ data }) => onEventSent(JSON.parse(data));

        // Return configured EventSource
        return eventSource;
    }, [onEventSent]);

    // useEffect triggered only once when this hook is called
    useEffect(() => {
        // Prevent opening subscription to bad topics
        if (null === eventSourceRef.current && !topic.includes('null') && !topic.includes('undefined')) {
            // Open subscription to topic
            eventSourceRef.current = subscribeToTopic();

            // setInterval to reconnect every 60 seconds
            intervalRef.current = setInterval(() => {
                // Close previous SSE
                eventSourceRef.current!.close();
                // Re-open SSE on same topic
                eventSourceRef.current = subscribeToTopic();
            }, heartbeat * 1000);
        }
    }, [heartbeat,subscribeToTopic, topic])

    // useEffect when component is unmounting
    useEffect(() => () => {
        if (eventSourceRef.current) {
            // Close open subscription to topic
            eventSourceRef.current.close();
            // Console log info if development env
            console.info(`CLOSE mercure subscription for ${topicURIRef.current}`);
        }
        // Clear interval used for reconnection
        intervalRef.current && clearInterval(intervalRef.current);
    }, [])
};

export default useSSE;
