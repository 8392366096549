import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Stack } from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';

const OnlineRenderer: React.FC<ICellRendererParams> = props => {
    return (
        <Stack>
            {
                true === props.value ?
                    (<CircleIcon sx={{ fontSize: 15 }} color='success' />)
                    : (<CircleIcon sx={{ fontSize: 15 }} color='error' />)
            }
        </Stack>
    );
};

export default OnlineRenderer;
