import React from 'react';
import { useAppSelector } from '../../store/hooks';
import HomeAPIs from '../../APIs/HomeAPIs';
import {
    Stack,
    Typography,
} from '@mui/material';
import HomePanel from '../Home/HomePanel';
import IIncomesData from '../../interfaces/IIncomesData';
import cssStyles from './HomeEditorStyles';

const PanelIncomes: React.FC = () => {
    // Use of hooks
    const [incomesData, setIncomesData] = React.useState<IIncomesData|null>(null);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id!);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === incomesData &&
            // Call API to retrieve editor incomes data
            HomeAPIs.getEditorIncomes({ id: instanceUserId })
                // On successful API call
                .then((data: IIncomesData|null) => null !== data && setIncomesData(data));
    }, [incomesData, instanceUserId])

    return (
        <HomePanel header='vos gains' offsetLeft={2} sx={{ flex: 1 }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' height={1}>
                <Stack sx={{ ...cssStyles.incomeCircle, borderColor: 'secondary.main' }}>
                    <Stack alignItems='center'>
                        <Typography sx={cssStyles.gainText}>
                            {`${incomesData ? incomesData.today.gains.toFixed(2) : '_'} €`}
                        </Typography>
                        <Typography sx={cssStyles.whenText}>
                            {'Jour'}
                        </Typography>
                    </Stack>
                    <Typography sx={cssStyles.howManyText}>
                        {`${incomesData ? incomesData.today.redaction : '_'} article(s)`}
                    </Typography>
                </Stack>
                <Stack sx={cssStyles.incomeCircle}>
                    <Stack alignItems='center'>
                        <Typography sx={cssStyles.gainText}>
                            {`${incomesData ? incomesData.week.gains.toFixed(2) : '_'} €`}
                        </Typography>
                        <Typography sx={cssStyles.whenText}>
                            {'Semaine'}
                        </Typography>
                    </Stack>
                    <Typography sx={cssStyles.howManyText}>
                        {`${incomesData ? incomesData.week.redaction : '_'} article(s)`}
                    </Typography>
                </Stack>
                <Stack sx={cssStyles.incomeCircle}>
                    <Stack alignItems='center'>
                        <Typography sx={cssStyles.gainText}>
                            {`${incomesData ? incomesData.month.gains.toFixed(2) : '_'} €`}
                        </Typography>
                        <Typography sx={cssStyles.whenText}>
                            {'Mois'}
                        </Typography>
                    </Stack>
                    <Typography sx={cssStyles.howManyText}>
                        {`${incomesData ? incomesData.month.redaction : '_'} article(s)`}
                    </Typography>
                </Stack>
                <Stack sx={cssStyles.incomeCircle}>
                    <Stack alignItems='center'>
                        <Typography sx={cssStyles.gainText}>
                            {`${incomesData ? incomesData.year.gains.toFixed(2) : '_'} €`}
                        </Typography>
                        <Typography sx={cssStyles.whenText}>
                            {'Année'}
                        </Typography>
                    </Stack>
                    <Typography sx={cssStyles.howManyText}>
                        {`${incomesData ? incomesData.year.redaction : '_'} article(s)`}
                    </Typography>
                </Stack>
            </Stack>
        </HomePanel>
    );
};

export default PanelIncomes;
