import React from 'react';
import moment from 'moment-timezone';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { Button, FormHelperText, Stack } from '@mui/material';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import ImagePreview from '../ImagePreview/ImagePreview';
import ModalPixabayPicker from '../Modal/ModalPixabayPicker';
import RouterLink from '../RouterLink/RouterLink';
import { Field, useField } from 'react-final-form';
import FinalDatePicker from '../FinalComponents/FinalDatePicker';
import FinalInput from '../FinalComponents/FinalInput';
import FinalRadio from '../FinalComponents/FinalRadio';
import IDraftPost from '../../models/IDraftPost';
import IRadioOption from '../../interfaces/IRadioOption';

const radioOptions: Array<IRadioOption> = [
    {
        label: 'Depuis mon ordinateur',
        value: '0',
    },
    {
        label: 'Depuis mon Pixabay',
        value: '1',
    },
];

const PostStep: React.FC<{ draftPost: IDraftPost }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();

    // Use of final-form hooks
    const {
        input: {
            value: imageValue,
            onChange: imageOnChange
        },
        meta: {
            modified: imageModified,
            error: imageError
        }
    } = useField('image', { subscription: { value: true, pristine: true, modified: true, error: true }});

    return (
        <>
            <ModalPixabayPicker
                defaultKeyword={props.draftPost.keyword}
                onValidation={imageSrc => imageOnChange({ url: imageSrc })}
            />
            <Stack alignItems='flex-start' marginBottom={2}>
                <FinalRadio
                    name='imageSelection'
                    radio={radioOptions}
                    label='Choisir une image'
                    wrapperSx={{ marginBottom: 2 }}
                    onChange={() => imageOnChange(null)}
                />
                <Field<IRadioOption>
                    name='imageSelection'
                    subscription={{ value: true }}
                    render={({ input: { value: imageSelection } }) => (
                        '0' === imageSelection.value ? (
                            <ImageDropzone
                                error={imageModified && imageError}
                                initialImage={imageValue?.url}
                                maxImageWeight={800}
                                maxPreviewSize={500}
                                keepFileOnUnmount
                                sx={{ height: 'unset' }}
                                onDrop={(imageFile, imageBlobUrl) => imageOnChange({ file: imageFile, url: imageBlobUrl })}
                                onPreviewClose={() => imageOnChange(null)}
                            />
                        ) : '1' === imageSelection.value ? (
                            <>
                                {
                                    !imageValue ? (
                                        <Button
                                            variant='outlined'
                                            color={imageModified && imageError ? 'error' : 'primary'}
                                            onClick={() => dispatch(openModal({ name: 'pixabayPicker' }))}
                                        >
                                            {`Depuis Pixabay`}
                                        </Button>
                                    ) : (
                                        <ImagePreview
                                            closable
                                            imageSrc={imageValue?.url}
                                            maxSize={1}
                                            onPreviewClose={() => imageOnChange(null)}
                                        />
                                    )
                                }
                            </>
                        ) : null
                    )}
                />
                {
                    imageError && imageModified && (
                        <FormHelperText error>{imageError}</FormHelperText>
                    )
                }
            </Stack>
            <FinalInput
                name='title'
                size='small'
                label="Titre de l'article"
                upperLabel
                fullWidth
                disabled
            />
            <FormHelperText sx={{ marginBottom: 2 }}>
                {`Le titre de l'article est sélectionné automatiquement dans le contenu HTML.`}
                <br />
                {`Vous pouvez le modifier manuellement en ajoutant une balise H1 au début de votre article.`}
                <br />
                <RouterLink
                    anchor="Cliquez ici pour modifier l'article"
                    to='/dashboard/tools/redact_tool'
                    state={props.draftPost}
                />
            </FormHelperText>
            <FinalInput
                name='metaDesc'
                size='small'
                label="Meta description de l'article"
                upperLabel
                fullWidth
                multiline
                minRows={2}
                maxRows={4}
                wrapperSx={{ marginBottom: 2 }}
            />
            <Field
                name='destinationChoice'
                subscription={{ value: true }}
                render={({ input: { value: destinationChoice } }) => (
                    'intern' === destinationChoice.value && (
                        <FinalDatePicker
                            name='toPublishAt'
                            size='small'
                            label="Voulez-vous publier l'article à une date précise ?"
                            upperLabel
                            // Restrict to h+1 date minimum
                            minDateTime={moment().add(1, 'h').toDate()}
                            views={['day', 'hours']}
                        />
                    )
                )}
            />
        </>
    );
};

export default PostStep;
