import React from 'react';
import moment from 'moment-timezone';
import {
    Stack,
    Typography,
    Tooltip,
} from '@mui/material';
import {
    Timer as TimerIcon,
    HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import Countdown from '../Countdown/Countdown';

const DeadlineTimer: React.FC<{
    deadline: moment.MomentInput,
    withDate?: boolean|undefined,
    text?: string|undefined,
    help?: string|undefined,
    onDeadlineReached?: (() => void)|undefined
}> = props => {
    // Define multiple times used TextComponent
    const TextComponent: JSX.Element = (
        <Typography variant='body2'>
            {props.text ?? 'Temps restant'}
        </Typography>
    );

    return (
        <>
            <Stack direction='row' alignItems='center' columnGap={1}>
                {
                    props.help ? (
                        <Stack direction='row' alignItems='center' columnGap='3px'>
                            {TextComponent}
                            <Tooltip title={props.help} placement='right'>
                                <HelpOutlineIcon sx={{ cursor: 'pointer', fontSize: '1rem' }} />
                            </Tooltip>
                        </Stack>
                    ) : TextComponent
                }
            </Stack>
            <Stack direction='row' alignItems='center' columnGap={1} sx={{ color: 'text.secondary' }}>
                <TimerIcon />
                <Typography component='p' variant='h5' lineHeight={1}>
                    <Countdown date={props.deadline} onTimeElapsed={props.onDeadlineReached}/>
                </Typography>
            </Stack>
            {
                props.withDate && (
                    <Typography variant='caption' color='textSecondary'>
                        {`soit le ${moment(props.deadline).format('DD/MM/Y à H:mm')}`}
                    </Typography>
                )
            }
        </>
    );
};

export default DeadlineTimer;
