import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import {
    Avatar,
    Stack,
    Box,
    Typography,
} from '@mui/material';

const AccountRenderer: React.FC<ICellRendererParams> = props => {
    // Retrieve initial value before getter & formatter
    const initialValue = props.colDef && props.colDef.field && props.data[props.colDef.field];

    return (
        initialValue && initialValue.company && props.value ? (
            <Stack direction='row' alignItems='center' spacing={1.5}>
                <Avatar
                    src={initialValue.company.logo}
                    variant='square'
                    sx={{ fontSize: '0.7rem', fontWeight: 700, width: 30, height: 30 }}
                    children={initialValue.company.name}
                />
                <Box component='span' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {props.value}
                </Box>
            </Stack>
        ) : (
            <Typography variant='caption'>
                {`Non renseigné`}
            </Typography>
        )
    );
};

export default AccountRenderer;
