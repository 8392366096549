import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setImportCsvState } from '../../store/slices/importCsvSlice';
import { DragDropContext, Draggable, Droppable, DropResult, DraggableProvided } from 'react-beautiful-dnd';
import { Button, Stack, StackProps, Typography } from '@mui/material';

const headerTypeToFrench = {
    'int': 'Nombre',
    'string': 'Alphanumérique',
    'bool': 'Booléen (0 ou 1)',
    'array': 'Tableau'
};

const OrganizeColumns: React.FC = () => {
    // Use of redux hooks
    const dispatch = useAppDispatch();
    const { expectedHeaders, dataValidation, csvHeaders, sortedHeaders } = useAppSelector(state => state.importCsv);

    // Callback to reorder headers
    const reorder = (list: Array<string>, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    // Callback to handle end of item drag
    const handleOnDragEnd = React.useCallback((result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        dispatch(setImportCsvState({
            sortedHeaders: reorder(
                sortedHeaders!,
                result.source.index,
                result.destination!.index
            ),
        }));
    }, [dispatch, sortedHeaders]);

    // Callback to render Draggable item
    const renderDraggable = (provided: DraggableProvided, children: React.ReactNode, stackProps?: StackProps | undefined) => (
        <Stack
            justifyContent='center'
            alignItems='center'
            textAlign='center'
            overflow='hidden'
            height={50}
            minWidth={200}
            maxWidth={200}
            ref={provided.innerRef}
            {...stackProps}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            {children}
        </Stack>
    );

    return (
        <>
            <Typography>{`Structure attendue`}</Typography>
            <Stack
                direction='row'
                gap={2}
                padding={3}
                bgcolor='lightgray'
            >
                {expectedHeaders!.map((header) => {
                    const hasValidation = dataValidation && Object.keys(dataValidation).includes(header.value);

                    return (
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            textAlign='center'
                            height={50}
                            minWidth={200}
                            maxWidth={200}
                            bgcolor='lightgoldenrodyellow'
                            overflow='hidden'
                            key={header.value}
                        >
                            <Typography>
                                {header.label}
                                {
                                    hasValidation && true === dataValidation[header.value].required && (
                                        <Typography component='span' color='error.main'>{`*`}</Typography>
                                    )
                                }
                            </Typography>
                            {
                                hasValidation && (
                                    <Typography variant='caption' textOverflow='ellipsis'>{headerTypeToFrench[dataValidation[header.value].type]}</Typography>
                                )
                            }
                        </Stack>
                    )
                })}
            </Stack>
            <Typography marginTop={2}>{`Structure du fichier CSV`}</Typography>
            <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
                <Droppable 
                    droppableId='droppable' 
                    direction='horizontal' 
                    renderClone={(provided, _, rubric) => renderDraggable(
                        provided,
                        sortedHeaders![rubric.source.index],
                        { bgcolor: 'lightgoldenrodyellow' }
                    )}
                >
                    {(droppableProvided) => (
                        <Stack
                            direction='row'
                            gap={2}
                            padding={3}
                            bgcolor='lightgray'
                            overflow='auto'
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                        >
                            {sortedHeaders!.map((header, index) => (
                                <Draggable key={header + index} draggableId={header + index} index={index}>
                                    {(provided) => renderDraggable(
                                        provided,
                                        header,
                                        { bgcolor: index < expectedHeaders!.length ? 'lightgoldenrodyellow' : 'lightcoral' }
                                    )}
                                </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
            <Typography variant='body2' color='textSecondary'>
                {`Vous pouvez déplacer les colonnes pour organiser la structure du fichier CSV par rapport à la structure attendue.`}
                <br />
                {`Les colonnes en rouge seront supprimées lors du traitement.`}
            </Typography>
            <Button
                variant='outlined'
                sx={{ marginTop: 2, alignSelf: 'flex-start' }}
                onClick={() => dispatch(setImportCsvState({ sortedHeaders: csvHeaders }))}
            >
                {`Réinitialiser la structure du fichier CSV`}
            </Button>
        </>
    );
};

export default OrganizeColumns;
