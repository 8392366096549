import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import {
    IconButton,
    Typography,
} from '@mui/material';
import {
    ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';

const CopyCellRenderer: React.FC<ICellRendererParams> = props => {
    return (
        <>
            <IconButton
                size='small'
                title="Copier la valeur de la cellule"
                onClick={() => navigator.clipboard.writeText(props.value)}
            >
                <ContentCopyIcon fontSize='small' />
            </IconButton>
            <Typography variant='caption'>
                {props.value}
            </Typography>
        </>
    );
};

export default CopyCellRenderer;
