import React from 'react';
import { Field } from 'react-final-form';
import stringToIcon from '../../configs/stringToIcon';
import {
    SxProps,
    Theme,
    Stack,
    Autocomplete,
    AutocompleteProps,
    Box,
    TextField,
    CircularProgress,
} from '@mui/material';
import FinalUpperLabel from './FinalUpperLabel';
import FinalFieldMessage from './FinalFieldMessage';
import ISelectOption from '../../interfaces/ISelectOption';

type FinalAutocompleteProps = {
    name: string, // force this prop to not be undefined
    label?: React.ReactNode|undefined,
    upperLabel?: boolean|undefined,
    options: Array<ISelectOption>,
    helperText?: React.ReactNode|undefined,
    wrapperSx?: SxProps<Theme>|undefined,
    onChange?: ((option: ISelectOption|null) => void)|undefined,
};

const FinalAutocomplete: React.FC<Omit<AutocompleteProps<any, any, any, any>, 'renderInput'|'onChange'>&FinalAutocompleteProps> =
({ label, sx, helperText, upperLabel, wrapperSx, ...props }) => {
    // Use of hooks
    const [open, setOpen] = React.useState(false);

    return (
        <Stack sx={{ width: props.fullWidth ? 1 : undefined, ...wrapperSx }}>
            {label && upperLabel && (<FinalUpperLabel name={props.name} label={label} />)}
            <Field
                name={props.name}
                allowNull
                subscription={{ value: true, error: true, touched: true }}
                render={({ input: { onChange, ...inputFinalFormProps }, meta: { error, touched }}) => (
                    <Autocomplete<ISelectOption>
                        {...inputFinalFormProps}
                        {...props}
                        getOptionLabel={(option: ISelectOption) => option.label || ''}
                        isOptionEqualToValue={(option, value) => String(option.value) === String(value.value)}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        renderOption={(forwardProps, option) => {
                            // Retrieve option icon if it exists
                            const Icon = option.icon ? stringToIcon(option.icon) : undefined;

                            return (
                                <Box {...forwardProps} component='li'>
                                    {Icon ? <Icon fontSize='small' sx={{ marginRight: 1 }} /> : null}
                                    {option.label}
                                </Box>
                            )
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={label && !upperLabel ? label : undefined}
                                placeholder={props.placeholder}
                                error={error && touched}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {props.loading ? <CircularProgress color='inherit' size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                                sx={sx}
                            />
                        )}
                        onChange={(_, option) => {
                            onChange(option);
                            props.onChange && props.onChange(option as ISelectOption);
                        }}
                        onInputChange={(_, __, reason) => {
                            if ('clear' === reason) {
                                onChange(null);
                                props.onChange && props.onChange(null);
                            }
                        }}
                    />
                )}
            />
            <FinalFieldMessage name={props.name} helperText={helperText} />
        </Stack>
    );
};

export default FinalAutocomplete;
