import React from 'react';
import useInterval from '../../hooks/useInterval';
import { useAppDispatch } from '../../store/hooks';
import { setTextEditorState } from '../../store/slices/textEditorSlice';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import { LoadingButton } from '@mui/lab';
import IOptimizationData from '../../interfaces/IOptimizationData';

const OptimizationPanelButton: React.FC<{ keyword: string, plainText: string, title: string }> = props => {
    // Define timer between each test
    const intervalOptiData: number = parseInt(process.env.REACT_APP_OPTIMIZATION_INTERVAL!);

    // Use of hooks
    const [timer, setTimer] = React.useState<number|null|undefined>(intervalOptiData);
    const [loading, isLoading] = React.useState<boolean>(false);

    // Use of redux
    const dispatch = useAppDispatch();

    // useInterval triggered when timer is number type
    useInterval(() => {
        // Decrement timer hook value by 1 every second until it reaches 0
        // Once equals 0, it becomes null
        'number' === typeof timer &&
            0 < timer ? setTimer(timer - 1) : setTimer(null);
    }, 1000, [timer]);

    // Callback to handle optimization API Call
    const handleAskOptimization = React.useCallback(() => {
        // Wait until API call ends
        isLoading(true);
        // Call API to retrieve optimization data
        BridgeAPIs.getOptimizationData({
            query: props.keyword,
            content: props.title ? `${props.title} ${props.plainText}` : props.plainText,
        })
            // On successful API call
            .then((data: IOptimizationData) => {
                if (data) {
                    // Store new optimization data in redux state
                    dispatch(setTextEditorState({
                        optimization: {
                            score: data.score,
                            words: data.words,
                            prevPlainText: props.plainText,
                        }
                    }));
                    // Timer for next optimization demand
                    setTimer(parseInt(process.env.REACT_APP_OPTIMIZATION_INTERVAL!));
                }
            })
            // In any cases, no longer need to load
            .finally(() => isLoading(false));
    }, [props, dispatch]);

    return (
        <LoadingButton
            variant='contained'
            type='submit'
            disabled={'number' === typeof timer}
            loading={loading}
            onClick={handleAskOptimization}
        >
            {`Tester mon optimisation ${0 === timer || !timer ? '' : `(${timer}s)`}`}
        </LoadingButton>
    );
};

export default OptimizationPanelButton;