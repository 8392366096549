import React from 'react';
import ProjectsDME from '../components/DashboardMenuExtension/ProjectsDME';

/**
 * Function used to convert dashboard menu extension name to a React Component
 */
 export default function extensionToDMEComponent(extensionName: string|null): React.FC|null {
    const DMEs: { [key: string]: React.FC } = {
        projects: ProjectsDME,
    };

    return extensionName ? DMEs[extensionName] : null;
};
