import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { FormikProps } from 'formik';
import {
    Button,
	Stack,
    Typography,
} from '@mui/material';
import FormikRadio from '../FormikComponent/FormikRadio';
import DestinationWebsite from './DestinationWebsite';
import { unawareInitialValues } from './Duplicate';
import IDuplicateOrder from '../../interfaces/IDuplicateOrder';
import IRadioOption from '../../interfaces/IRadioOption';

// Define RadioGroup options
export const duplicateSiteOptions: Array<IRadioOption> = [
    {
        label: 'Non renseigné',
        value: 'none',
        helperText: "Vous aurez toujours la possibilité d'en renseigner un plus tard.",
    },
    {
        label: 'Choisir un site de destination',
        value: 'site',
    },
];

const DuplicateDestinationWebsite: React.FC<{
    formikProps: FormikProps<any>,
    index: number,
    modalDestinationPicker: string,
}> = props => {
    // Use of redux hooks
    const dispatch = useAppDispatch();

    // Callback use to reset formik fields to their initial value
    const resetFormikFields = React.useCallback((fields: Array<string>) => {
        fields.forEach((field) => props.formikProps.setFieldValue(field, unawareInitialValues[field]));
    }, [props.formikProps]);

    // Get duplicate data at inderx
    const duplicateData: IDuplicateOrder = props.formikProps.values.duplicates[props.index];

    return (
        <>
            <Typography variant='h6'>
                {`Choix du site de destination`}
            </Typography>
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems='start' paddingX={2}>
                <FormikRadio
                    fullWidth={false}
                    name={`duplicates.${props.index}.duplicateSiteChoice`}
                    radio={duplicateSiteOptions}
                    onChange={() => resetFormikFields([`duplicates.${props.index}.destinationWebsite`])}
                />
                {
                    // If user chose to select a site destination
                    'site' === duplicateData.duplicateSiteChoice?.value && (
                        // Website destination is not defined, show button to open modal
                        // Modal 'ModalDestinationPicker' is in parent component DuplicateOrderStep
                        duplicateData.destinationWebsite ? (
                            // Show selected website destination
                            <DestinationWebsite
                                elevation={2}
                                website={duplicateData.destinationWebsite.website.label}
                                category={duplicateData.destinationWebsite.category.name}
                                thematic={duplicateData.destinationWebsite.thematic.label}
                                onDelete={() => resetFormikFields([`duplicates.${props.index}.destinationWebsite`])}
                            />
                        ) : (
                            <Button
                                variant='outlined'
                                color='secondary'
                                onClick={() => dispatch(openModal({
                                    name: props.modalDestinationPicker,
                                    params: { index: props.index }
                                }))}
                            >
                                {`Choisir un site de destination`}
                            </Button>
                        )
                    )
                }
            </Stack>
        </>
    );
};

export default DuplicateDestinationWebsite;
