import React from 'react';
import moment from 'moment-timezone';
import useInterval from '../../hooks/useInterval';

/**
 * This component must be invoked inside a Typography MUI component
 * as it is a simple React.Fragment with brut text
 */
const Countdown: React.FC<{ date: moment.MomentInput, onTimeElapsed?: (() => void)|undefined }> = props => {
    // Use of hooks
    const [duration, setDuration] = React.useState<moment.Duration>(
        moment.duration(moment(props.date).diff(moment()))
    );

    // useInterval to manage a countdown
    useInterval(() => {
        // While countdown is not finished
        duration.asMilliseconds() > 1000 &&
            // Countdown runneth over
            setDuration(duration => moment.duration(duration.asMilliseconds() - 1000))
    }, 1000, [])

    // useEffect when time is elapsed
    React.useEffect(() => {
        // Countdown is over & callback was not trigger
        duration.asMilliseconds() < 1000 && undefined !== props.onTimeElapsed &&
            // Call given callback
            props.onTimeElapsed();
    }, [duration, props])

    return (
        <>
            {
                duration.asMilliseconds() > 1000 ?
                    `${
                        Math.trunc(duration.asHours()) > 24 ?
                            `${duration.days().toString()}j ${duration.hours().toString().padStart(2, '0')}`
                            : Math.trunc(duration.asHours()).toString().padStart(2, '0')
                    }` +
                    `:${duration.minutes().toString().padStart(2, '0')}` +
                    `:${duration.seconds().toString().padStart(2, '0')}`
                    : '00:00:00'
            }
        </>
    );
};

export default Countdown;
