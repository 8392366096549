import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    orderStructureLoading: {
        opacity: 0.5,
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
    },
    orderInfo: {
        position: 'relative',
        width: 8/10,
        paddingY: 2,
        paddingRight: 9,
        paddingLeft: 4,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        backgroundColor: 'white',
        zIndex: 1,
        '&:after': {
            content: '""',
            position: 'absolute',
            height: 1,
            top: 0,
            right: '-39px',
            width: '75px',
            borderRight: '20px solid',
            backgroundColor: 'secondary.main',
            transform: 'skew(-25deg, 0deg)',
            borderColor: 'white',
        }
    },
    orderAction: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: 2/10,
        paddingLeft: 6,
        paddingRight: 2,
        paddingY: 2,
        backgroundColor: 'secondary.main',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
};

export default cssStyles;
