import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import Tools from '../../helpers/Tools';
import { FormikProps } from 'formik';
import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material';
import FormikRadio from '../FormikComponent/FormikRadio';
import DisplayCard from '../DisplayCard/DisplayCard';
import FormikCheckbox from '../FormikComponent/FormikCheckbox';
import FormikDatePicker from '../FormikComponent/FormikDatePicker';
import ModalSelectFreelance from '../Modal/ModalSelectFreelance';
import { unawareInitialValues } from './Duplicate';
import IRadioOption from '../../interfaces/IRadioOption';
import IUser from '../../models/IUser';
import ModalSelectAccountUser from '../Modal/ModalSelectAccountUser';

// Define RadioGroup options
export const duplicateEditorOptions: Array<IRadioOption> = [
    {
        label: 'Non renseigné',
        value: 'none',
        helperText: 'Le choix du rédacteur est optionnel et peut être effectué plus tard.',
    },
    {
        label: 'Même rédacteur que la commande dupliquée',
        value: 'duplicate',
    },
    {
        label: 'Choisir un rédacteur',
        value: 'editor',
    },
];

const DuplicateEditor: React.FC<{
    formikProps: FormikProps<any>,
    index: number,
    isPublic: boolean,
    initialEditor?: IUser|undefined,
}> = props => {
    // Use of redux
    const dispatch = useAppDispatch();

    /**
     * Get formik field value
     *
     * @param name the path of formik variable names separated by a dot. Ex: 'user.name'
     *
     * @returns value of the formik field
     */
    const getFormikPathValue = (name: string): any => Tools.getObjectValueByPath(props.formikProps.values.duplicates, `${props.index}.${name}`);

    /**
     * Get formik path
     *
     * @param name formik field name
     *
     * @returns string name of the formik field
     */
    const getFormikPath = React.useCallback((name: string): string => `duplicates.${props.index}.${name}`, [props.index]);

    // Callback use to reset formik fields to their initial value
    const resetFormikFields = React.useCallback((fields: Array<string>) => {
        fields.forEach((field) => props.formikProps.setFieldValue(getFormikPath(field), unawareInitialValues[field], false));
    }, [props.formikProps, getFormikPath]);

    // Callback to handle duplicateEditorChoice change
    const handleChoiceChange = React.useCallback((option: IRadioOption) => {
        // If user chose to duplicate initial editor
        'duplicate' === option.value ?
            // Change formik field to initial editor
            props.formikProps.setFieldValue(getFormikPath('editor'), props.initialEditor, false)
            // Else reset formik field editor
            : resetFormikFields(['editor'])
    }, [props.formikProps, props.initialEditor, getFormikPath, resetFormikFields]);

    return (
        <>
            <Typography variant='h6'>
                {`Choix du rédacteur`}
            </Typography>
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems='start' paddingX={2}>
                <FormikRadio
                    name={getFormikPath('duplicateEditorChoice')}
                    fullWidth={false}
                    // Remove option 'none' if initial order is public
                    radio={true === props.isPublic ? duplicateEditorOptions.filter(option => 'none' !== option.value) : duplicateEditorOptions}
                    onChange={handleChoiceChange}
                />
                {
                    // If user chose to define editor
                    ('editor' === getFormikPathValue('duplicateEditorChoice.value') || getFormikPathValue('editor')) && (
                        <Stack alignItems='flex-start' spacing={2}>
                            <Box paddingLeft={3}>
                                {
                                    !getFormikPathValue('editor') && (
                                        // Privacy is public && publicChoice is propose, show button to select an editor
                                        'public' === getFormikPathValue('privacy.value') && 'propose' === getFormikPathValue('publicChoice.value') ? (
                                            <>
                                                <ModalSelectFreelance
                                                    modalName='duplicateSelectFreelanceModal'
                                                    onValidation={(_, user: IUser) => {
                                                        // Update editor in formik
                                                        props.formikProps.setFieldValue(getFormikPath('editor'), user, false);
                                                        // Reset linked sub fields
                                                        resetFormikFields(['autoPublish', 'toPublish', 'toPublishAt']);
                                                    }}
                                                />
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    onClick={() => dispatch(openModal({ name: 'duplicateSelectFreelanceModal' }))}
                                                >
                                                    {`Choisir un Freelance`}
                                                </Button>
                                                {
                                                    props.formikProps.errors[getFormikPath('editor')] && (
                                                        <FormHelperText error>
                                                            {props.formikProps.errors[getFormikPath('editor')]}
                                                        </FormHelperText>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            // Privacy is private, show button to select an editor
                                            <>
                                                <ModalSelectAccountUser
                                                    modalName='duplicateSelectEditorModal'
                                                    singleSelect
                                                    scopes={['can_redact_posts']}
                                                    onValidation={(_, [user]) => {
                                                        // Update editor in formik
                                                        user && props.formikProps.setFieldValue(getFormikPath('editor'), user, false);
                                                        // Reset linked sub fields
                                                        resetFormikFields(['autoPublish', 'toPublish', 'toPublishAt']);
                                                    }}
                                                />
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    onClick={() => dispatch(openModal({ name: 'duplicateSelectEditorModal' }))}
                                                >
                                                    {`Choisir un rédacteur`}
                                                </Button>
                                            </>
                                        )
                                    )
                                }
                                {
                                    // Editor is selected
                                    getFormikPathValue('editor') && ('private' === getFormikPathValue('privacy.value') || 'propose' === getFormikPathValue('publicChoice.value')) && (
                                        <Stack direction='row' alignItems='start' spacing={3} padding={'public' === getFormikPathValue('privacy.value') ? 2 : undefined}>
                                            {/* Display editor information */}
                                            <DisplayCard
                                                elevation={2}
                                                width={250}
                                                image={getFormikPathValue('editor.avatar') ?? undefined}
                                                title={`${getFormikPathValue('editor.name')} ${getFormikPathValue('editor.lastname')}`}
                                                onDelete={() => resetFormikFields(['editor', 'autoPublish', 'toPublish', 'toPublishAt'])}
                                            />
                                            {
                                                // If destination is defined, show autoPublish
                                                getFormikPathValue('destinationWebsite') && (
                                                    <Stack spacing={2}>
                                                        {/* Handle autoPublish */}
                                                        <FormikCheckbox
                                                            name={getFormikPath('autoPublish')}
                                                            label="Publier automatiquement l'article à la fin de la rédaction"
                                                            helperText={(
                                                                <>
                                                                    {`Une fois l'article rédigé, il sera automatiquement publié sur le site de destination.`}
                                                                    <br />
                                                                    {`Si vous choisissez de programmer la publication, l'article sera automatiquement validé une fois rédigé.`}
                                                                </>
                                                            )}
                                                            // If checked, open modal to confirm
                                                            onChange={(checked) => true === checked && dispatch(openModal({ name: 'duplicateAutoPublishModal', params: { index: props.index } }))}
                                                        />
                                                        {/* Handle post publish date */}
                                                        <Stack>
                                                            <FormikCheckbox
                                                                name={getFormikPath('toPublish')}
                                                                label='Programmer la publication'
                                                                // Reset linked sub field
                                                                onChange={() => resetFormikFields(['toPublishAt'])}
                                                            />
                                                            <FormikDatePicker
                                                                name={getFormikPath('toPublishAt')}
                                                                helperText={(
                                                                    <>
                                                                        {`Une fois l'article validé, celui-ci sera automatiquement publié sur votre site à une date données.`}
                                                                        <br />
                                                                        {`La publication automatique des articles s'effectue une fois toutes les heures.`}
                                                                    </>
                                                                )}
                                                                views={['day', 'hours']}
                                                                disabled={!Boolean(getFormikPathValue('toPublish'))}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                )
                                            }
                                        </Stack>
                                    )
                                }
                            </Box>
                        </Stack>
                    )
                }
            </Stack>
        </>
    );
};

export default DuplicateEditor;
