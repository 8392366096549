import React from 'react';
import Tools from '../../../helpers/Tools';
import {
    Box,
    IconButton,
    Stack,
    Tooltip
} from '@mui/material';
import { CheckCircle as CheckIcon, Cancel as CancelIcon } from '@mui/icons-material';
import PurifiedHtml from '../../PurifiedHtml/PurifiedHtml';
import TextEditor from '../../TextEditor/TextEditor';

const PostHtml: React.FC<{
    canEdit?: boolean|undefined,
    html: string,
    onChange: (html: string) => void,
}> = props => {
    // Use of hooks
    const [edit, setEdit] = React.useState<boolean>(false);
    const [html, setHtml] = React.useState<string>(props.html);
    const inputRef = React.useRef<HTMLInputElement>(null);

    // Callback to handle input reset
    const handleReset = React.useCallback(() => {
        setHtml(props.html);
        setEdit(false);
    }, [props.html]);

    // Callback to handle input validation
    const handleValidate = React.useCallback(() => {
        setEdit(false);
        props.onChange(html);
    }, [props, html]);

    // Callback to handle input click event
    const handleClick = React.useCallback((event) => {
        // If user click oustide of inputRef container, reset form
        inputRef.current !== event.target && !inputRef.current?.contains(event.target) &&
            handleValidate();
    }, [handleValidate]);

    // Callback to handle escape key press to reset edited field
    const handleEsc = React.useCallback((event: KeyboardEvent) => {
        // If escape key is pressed, reset input
        event.key === 'Escape' && handleReset();
    }, [handleReset]);

    // useEffect whenever edit state changes
    React.useEffect(() => {
        // Listen to keyup and click events
        if (true === edit && inputRef.current) {
            inputRef.current.addEventListener('keyup', handleEsc);
            document.addEventListener('click', handleClick);
        }

        // On unmount, stop listening to click event
        // Keyup event will be removed automatically
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [edit, handleEsc, handleClick]);

    // useEffect whenever props.html value changes
    React.useEffect(() => {
        // Update html state with new value
        setHtml(props.html);
    }, [props.html]);

    return (
        <Box paddingBottom={2}>
            {
                false === edit ? (
                    <PurifiedHtml
                        html={Tools.stripTags(html) ? html : 'Contenu vide'}
                        onDoubleClick={true === props.canEdit ? () => setEdit(true) : undefined}
                    />
                ) : (
                    <Stack
                        spacing={2}
                        direction='row'
                        alignItems='end'
                        ref={inputRef}
                    >
                        <TextEditor
                            standalone
                            allowHeadingOne
                            autoFocus
                            placeholder="Contenu de l'article"
                            initialHtml={html}
                            wrapperSx={{ height: 500}}
                            onChange={html => setHtml(html)}
                        />
                        <Stack>
                            <Tooltip title='Annuler les modifications' placement='right-start'>
                                <IconButton onClick={handleReset}>
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Valider les modifications' placement='right-start'>
                                <IconButton
                                    color='success'
                                    onClick={handleValidate}
                                >
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                )
            }
        </Box>
    );
};

export default PostHtml;
