import React from 'react';
import { useAppSelector } from '../../store/hooks';
import OrderSummary from '../OrderSummary/OrderSummary';
import IOrderFormStepperData from '../../interfaces/IOrderFormStepperData';

const Summary: React.FC = () => {
    // Use of redux hooks
    let orderFormState: IOrderFormStepperData = useAppSelector(state => state.activeStepper.data);

    return (
        <OrderSummary
            activitySector={orderFormState.activitySector!}
            keyword={orderFormState.keyword!}
            syntaxLevel={orderFormState.syntaxLevel!}
            postType={orderFormState.postType!}
            imageChoice={orderFormState.imageChoice!}
            privacy={orderFormState.privacy!}
            imageUploaded={orderFormState.imageUploaded}
            imageBank={orderFormState.imageBank}
            imageKeyword={orderFormState.imageKeyword}
            title={orderFormState.title}
            mandatoryLinks={orderFormState.mandatoryLinks}
            minNbrWords={orderFormState.minNbrWords}
            freeOptimization={orderFormState.freeOptimization}
            minOptimization={orderFormState.minOptimization}
            metaDesc={orderFormState.metaDesc}
            destinationWebsite={orderFormState.destinationWebsite}
            price={orderFormState.price}
            publicChoice={orderFormState.publicChoice}
            editor={orderFormState.editor}
            autoPublish={orderFormState.autoPublish}
            toPublishAt={orderFormState.toPublishAt}
            deadlineAt={orderFormState.deadlineAt}
            brief={orderFormState.brief}
            shares={orderFormState.shares}
            duplicates={orderFormState.duplicates}
        />
    );
};

export default Summary;
