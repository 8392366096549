import { Theme } from '@mui/material';
import ICssStyles from '../../interfaces/ICssStyles';

const makeCssStyles = (theme: Theme): ICssStyles => ({
    stepperControlsWrapper: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: 1,
        height: 54,
        zIndex: theme.zIndex.drawer,
        backgroundColor: 'light' === theme.palette.mode ? theme.palette.grey[200] : theme.palette.grey[900],
    },
    awareDashboardMenu: {
        position: 'absolute',
        // So it does not recover DashboardMenu
        zIndex: theme.zIndex.drawer - 2,
        transition: theme.transitions.create('padding-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    stepperControls: {
        position: 'relative',
        maxWidth: {
            xs: 1370,
        },
        margin: 'auto',
        padding: 1,
    },
    currentStep: {
        [theme.breakpoints.down(800)]: {
            display: 'none',
        },
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
    },
    controlButton: {
        backgroundColor: 'white',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1
    }
});

export default makeCssStyles;
