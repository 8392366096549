import React from 'react';
import { Stack } from '@mui/material';
import NamespacePanel from '../NamespacePanel/NamespacePanel';
import SvgPublishedDraftPosts from '../Svg/SvgPublishedDraftPosts';
import SvgDraftPosts from '../Svg/SvgDraftPosts';

const DocumentsMenu: React.FC = () => {
    return (
        <Stack direction='row' flexWrap='wrap' justifyContent='center' gap={2}>
            <NamespacePanel
                svg={<SvgDraftPosts sx={{ fontSize: '6rem' }} />}
                header='Mes articles'
                text='Liste de mes articles sauvegardés en tant que brouillons.'
                to='draft_posts_listing'
            />
            <NamespacePanel
                svg={<SvgPublishedDraftPosts sx={{ fontSize: '6rem' }} />}
                header='Mes articles publiés'
                text='Liste de mes articles publiés sur un de mes sites.'
                to='draft_posts_published'
            />
        </Stack>
    );
};

export default DocumentsMenu;
