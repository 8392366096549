import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgPostToWrite: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#F2F2F2' d='M311.721,0H22.654C10.921,0,1.321,9.6,1.321,21.333v469.333c0,11.733,9.6,21.333,21.333,21.333h369.067c11.733,0,21.333-9.6,21.333-21.333V101.333L311.721,0z'/>
            <g>
                <path fill='#CDD6E0' d='M311.721,80c0,11.733,9.6,21.333,21.333,21.333h80L311.721,0V80L311.721,80z' />
                <rect x='56.788' y='64' fill='#CDD6E0' width='192' height='26.667' />
                <rect x='56.788' y='145.07' fill='#CDD6E0' width='301.87' height='26.667' />
                <rect x='56.788' y='226.13' fill='#CDD6E0' width='301.87' height='26.667' />
            </g>
            <rect x='221.05' y='307.2' fill='#84DBFF' width='138.67' height='26.667' />
            <path fill='#ACB3BA' d='M157.054,325.333L157.054,325.333c-3.2-3.2-5.333-7.467-5.333-12.8l0,0c0-9.6-7.467-18.133-18.133-18.133l0,0c-4.267,0-9.6-2.133-12.8-5.333l0,0c-6.4-6.4-18.133-6.4-24.533,0l0,0c-3.2,3.2-7.467,5.333-12.8,5.333c-9.6,0-18.133,7.467-18.133,18.133l0,0c0,4.267-2.133,9.6-5.333,12.8l0,0c-6.4,6.4-6.4,18.133,0,24.533l0,0c3.2,3.2,5.333,7.467,5.333,12.8l0,0c0,9.6,7.467,18.133,18.133,18.133c4.267,0,9.6,2.133,12.8,5.333c6.4,6.4,18.133,6.4,24.533,0c3.2-3.2,7.467-5.333,12.8-5.333l0,0c9.6,0,18.133-7.467,18.133-18.133l0,0c0-4.267,2.133-9.6,5.333-12.8l0,0C163.454,343.467,163.454,332.8,157.054,325.333z M109.054,373.333c-19.2,0-35.2-16-35.2-35.2s16-35.2,35.2-35.2s35.2,16,35.2,35.2C145.321,357.333,129.321,373.333,109.054,373.333z'/>
            <circle fill='#CDD6E0' cx='109.05' cy='338.13' r='25.6' />
            <path fill='#FFD15C' d='M477.054,245.333c0,0-22.4-18.133-74.667,37.333l-35.2,35.2c0,0,11.733,7.467,21.333-7.467l34.133-34.133c0,0,11.733-13.867,22.4-2.133L477.054,245.333z'/>
            <path fill='#40596B' d='M475.988,238.933C390.654,329.6,333.054,377.6,239.188,493.867c7.467,7.467,5.333,5.333,12.8,12.8C368.254,412.8,416.254,355.2,506.921,269.867c8.533-7.467,7.467-21.333-1.067-29.867l0,0C497.321,231.467,483.454,230.4,475.988,238.933z'/>
            <g>
                <path fill='#FFD15C' d='M438.654,336c10.667-9.6,20.267-20.267,30.933-30.933L439.721,275.2c-10.667,10.667-21.333,21.333-30.933,30.933L438.654,336z'/>
                <path fill='#FFD15C' d='M270.121,456.533c-9.6,11.733-20.267,23.467-30.933,37.333c7.467,7.467,5.333,5.333,12.8,12.8c12.8-10.667,25.6-20.267,37.333-30.933L270.121,456.533z'/>
            </g>
            <polygon fill='#40596B' points='231.721,514.133 239.188,493.867 251.988,506.667 ' />
        </SvgIcon>
    );
};

export default SvgPostToWrite;
