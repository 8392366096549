import React from 'react';
import { useAppSelector } from '../../store/hooks';
import DraftPostAPIs from '../../APIs/DraftPostAPIs';
import Tools from '../../helpers/Tools';
import { Button, CardMedia, Divider, Stack, Typography } from '@mui/material';
import {
    Key as KeyIcon,
    Numbers as NumbersIcon,
    Percent as PercentIcon,
} from '@mui/icons-material';
import GenericModal from './GenericModal';
import Spinner from '../Spinner/Spinner';
import PurifiedHtml from '../PurifiedHtml/PurifiedHtml';
import IDraftPost from '../../models/IDraftPost';

const ModalViewDraftPost: React.FC<{
    title: string,
    name?: string|undefined,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'viewDraftPostModal');
    const [draftPost, setDraftPost] = React.useState<IDraftPost|null>(null);

    // Use of redux
    const draftPostId: number|null = useAppSelector(state => state.activeModal.params);
    const isOpened: boolean = useAppSelector(state => state.activeModal.name === modalName.current);

    // useEffect on modal open
    React.useEffect(() => {
        // On modal open fetch requested draft post
        true === isOpened && null !== draftPostId &&
            // Call API to retrieve draft post
            DraftPostAPIs.getDraftPost({ id: draftPostId, detailed: true })
                // On successful API call
                .then(response => true === isOpened && undefined !== response?.data && 1 === response.count &&
                    // Update draft post state
                    setDraftPost(response.data[0])
                );
    }, [isOpened, draftPostId]);

    return (
        <GenericModal
            name={modalName.current}
            title={props.title}
            width={2/3}
            height={4/5}
        >
            {
                null !== draftPost ? (
                    <Stack rowGap={3}>
                        <Stack rowGap={1}>
                            {
                                draftPost.order?.post?.imageUrl && (
                                    <CardMedia
                                        component='img'
                                        image={draftPost.order.post.imageUrl}
                                        height={200}
                                        sx={{ borderRadius: 1 }}
                                    />
                                )
                            }
                            <Typography variant='h5' display='flex' alignItems='center' gap={1}>
                                <KeyIcon />
                                {draftPost.keyword}
                            </Typography>
                            <Typography display='flex' alignItems='center'gap={1}>
                                <PercentIcon />
                                {`Optimisation : ${draftPost.optimization}%`}
                            </Typography>
                            <Typography display='flex' alignItems='center'gap={1}>
                                <NumbersIcon />
                                {`Nombre de mots : ${draftPost.nbrWords}`}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack direction='row' gap={2}>
                            <Button
                                variant='outlined'
                                sx={{ alignSelf: 'start' }}
                                onClick={() => navigator.clipboard.writeText(draftPost.html)}
                            >
                                {`Copier le contenu HTML`}
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{ alignSelf: 'start' }}
                                onClick={() => navigator.clipboard.writeText(Tools.stripTags(draftPost.html))}
                            >
                                {`Copier le texte brut`}
                            </Button>
                        </Stack>
                        <PurifiedHtml html={draftPost.html} />
                    </Stack>
                ) : (
                    <Spinner />
                )
            }
        </GenericModal>
    );
};

export default ModalViewDraftPost;
