import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { SxProps, Theme } from '@mui/material';
import FormikAutocomplete from '../FormikComponent/FormikAutocomplete';
import ICountry from '../../models/ICountry';
import ISelectOption from '../../interfaces/ISelectOption';

const SelectCountry: React.FC<{
    name: string,
    label?: string|undefined,
    upperlabel?: string|undefined,
    fullWidth?: boolean|undefined,
    countries?: Array<ICountry>|undefined,
    sx?: SxProps<Theme>|undefined,
    onChange?: ((option: ISelectOption | null) => void)|undefined,
}> = props => {
    // Use of redux
    const countries: Array<ICountry> = useAppSelector(state => state.countries.data!);

    return (
        <FormikAutocomplete
            {...props}
            loading={null === countries}
            options={
                countries ? countries.map(country => ({
                    value: String(country.id),
                    label: country.name,
                })) : []
            }
        />
    );
};

export default SelectCountry;
