import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';
import IOrderBody from '../interfaces/IOrderBody';
import IPaginatorResponse from '../interfaces/IPaginatedResponse';
import IOrderProposal from '../models/IOrderProposal';
import IOrder from '../models/IOrder';

/**
 * OrderAPIs class.
 */
export default class OrderAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of Order ressources.
     *
     * @param query
     */
    public static async getOrder(query: any) {
        return GenericAPIs.apiGetCall('orders', this.getApiKey(), query);
    }

    /**
     * Get collection of Order ressources for public place.
     *
     * @param query
     */
    public static async getOrdersPublicPlace(query: any) {
        return GenericAPIs.apiGetCall('orders/public_place', this.getApiKey(), query);
    }

    /**
     * Get orders statistics
     *
     * @param query
     */
    public static async getOrdersStats(query: any) {
        return GenericAPIs.apiGetCall(`orders/stats`, this.getApiKey(), query);
    }

    /**
     * Post an Order resource.
     *
     * @param body
     */
    public static async postOrder(body: IOrderBody) {
        return GenericAPIs.apiPostCall('orders', body, this.getApiKey());
    }

    /**
     * Update Orders indexing status
     *
     * @param orders
     */
    public static async updateOrderIndexingStatus(orders: Array<number>) {
        return GenericAPIs.apiPostCall(`orders/indexing`, { orders }, this.getApiKey());
    }

    /**
     * Post an Order resource.
     *
     * @param body
     */
    public static async createFromDraftPost(body: IOrderBody): Promise<IOrder|null> {
        return GenericAPIs.apiPostCall('orders/draft_post', body, this.getApiKey());
    }

    /**
     * Patch an Order resource.
     *
     * @param id
     * @param body
     */
    public static async patchOrder(id: number, body: IOrderBody) {
        return GenericAPIs.apiPatchCall(`orders/${id}`, null, body, this.getApiKey());
    }

    /**
     * Patch multiple Order resources at once.
     *
     * @param body
     */
    public static async patchMultipleOrders(body: { orders: Array<number>, data: IOrderBody }) {
        return GenericAPIs.apiPostCall('orders/multiple_update', body, this.getApiKey());
    }

    /**
     * Delete an Order resource.
     *
     * @param id
     */
    public static async deleteOrder(id: number) {
        return GenericAPIs.apiDeleteCall(`orders/${id}`, this.getApiKey());
    }

    /**
     * Delete multiple Order resources at once.
     */
    public static async deleteMultipleOrder(ids: Array<number>) {
        return GenericAPIs.apiPostCall(`orders/multiple_delete`, ids, this.getApiKey());
    }

    /**
     * Get collection of OrderEditor ressources.
     *
     * @param query
     */
    public static async getOrderEditor(query: any) {
        return GenericAPIs.apiGetCall('orders_editors', this.getApiKey(), query);
    }

    /**
     * Get collection of OrderShare ressources.
     *
     * @param query
     */
    public static async getOrderShare(query: any) {
        return GenericAPIs.apiGetCall('orders_shares', this.getApiKey(), query);
    }

    /**
     * Create & delete multiple OrderShare at once
     *
     * @param body
     */
    public static async updateMultipleOrderShare(body: any) {
        return GenericAPIs.apiPostCall('orders_shares/multiple_update', body, this.getApiKey());
    }

    /**
     * Post an OrderRefuseMessage resource.
     *
     * @param body
     */
    public static async postOrderRefuseMessage(body: any) {
        return GenericAPIs.apiPostCall('orders_refuse_messages', body, this.getApiKey());
    }

    /**
     * Get collection of OrderProposal ressources.
     *
     * @param query
     */
    public static async getOrderProposal(query: any): Promise<IPaginatorResponse<IOrderProposal>|null> {
        return GenericAPIs.apiGetCall('orders_proposals', this.getApiKey(), query);
    }

    /**
     * Post an OrderProposal resource.
     *
     * @param body
     */
    public static async postOrderProposal(body: any) {
        return GenericAPIs.apiPostCall('orders_proposals', body, this.getApiKey());
    }
};
