import React from 'react';
import {
    SxProps,
    Theme,
    useTheme,
    SvgIcon,
} from '@mui/material';

const SvgLogoPegaseo: React.FC<{ primary?: boolean|undefined, sx?: SxProps<Theme>|undefined }> = props => {
    // Use of MUI hook
    const theme = useTheme();

    // Define logo colors
    const color1 = props.primary ? theme.palette.secondary.light : '#ccc';
    const color2 = props.primary ? theme.palette.primary.light : '#fff';

    return (
        <SvgIcon
            viewBox='0 0 789.14 555.5'
            xmlns='http://www.w3.org/2000/svg'
            sx={{
                width: 40,
                height: 40,
                ...props.sx,
            }}
        >
            <defs>
                <linearGradient id='gradient' x1='647.86' y1='963.52' x2='1437' y2='963.52' gradientUnits='userSpaceOnUse'>
                    <stop offset='0' stopColor={color1}/>
                    <stop offset='1' stopColor={color2}/>
                </linearGradient>
            </defs>
            <path fill='url(#gradient)' d='M962.71,1241.27q-8.33,0-16.64-.65c-80.76-6.43-160.07-55.17-207-127.18a274.46,274.46,0,0,1-22.73-42.59,87.52,
                87.52,0,0,0-26,21.24c-20.4,25-17,56.53-17,56.81l-25.08-.06c-.23-1.6-5.25-39.28,22.16-72.95q14.51-17.82,36.93-29.35c-16.85-54.06-15.6-112.25,
                4.36-169.27C776.87,691.36,1024.9,686,1035.43,685.9l10.21-.13,2.42,9.92c.71,2.89,6.13,29.74-19.53,82.41-.76,1.58-1.57,3.13-2.4,4.62,28.12,
                7.35,55.55,20.87,62.11,41.7,10.16,32.26,67.75,102.45,88.9,112.66,18.2,8.8,22.88,19.19,22.45,28.06,3.27,5.18,6.09,13.65,10.65,27.32,10.58,
                31.75-4.07,50.32-31.13,70-29.94,21.77-64.68-13.75-81.39-30.82-2.27-2.31-5-5.09-6.6-6.57-2.91-.69-7.25-2-15.2-4.48-21.92-6.8-62.74-19.45-123.89-33A143.24,
                143.24,0,0,1,914.4,974c-16.1,37.29-52.15,73.61-123,78q-29.16,1.83-51.38,8.81a247.4,247.4,0,0,0,20.56,38.56c42.66,65.49,114.51,109.79,187.52,
                115.61,59.83,4.75,123-21.4,189.94-49.09,89.06-36.86,184.55-79.72,298.78-55.67l.17,26.19c-106.48-22.42-199.34,16-289.14,53.18C1083.89,1216.13,
                1023.16,1241.27,962.71,1241.27ZM922.43,949c9.21,5.66,20.75,10.45,35.12,13.61,62.2,13.73,103.69,26.59,126,33.49,6.43,2,12.5,3.88,14.07,4.16,
                5.86.59,9.81,4.61,18.46,13.45,10.76,11,36,36.74,48,28,27.33-19.88,26.21-28.19,21.89-41.16-2.88-8.63-5.26-15.78-6.67-19.19a13.29,13.29,0,0,
                1-4.62-4.54,14,14,0,0,1-1.06-12,31.79,31.79,0,0,0-7.58-4.71c-28.65-13.83-90.75-91.62-102.22-128-1.58-5.06-10.17-13-29.54-20a173.7,173.7,0,
                0,0-25.4-7.06c-20.32,18.61-45.25,21.57-60.11,14.49-11.89-5.64-16.55-17.46-11.32-28.71,5.39-11.62,21.37-16.41,48.84-14.63,4.07.27,8.42.7,
                12.94,1.29a71,71,0,0,0,6.31-10.6c12.7-26.1,16.53-44.19,17.54-54.63C972.55,716,787.18,739.56,736,885.75c-17.8,50.86-19.18,102.73-4.69,
                151q25.62-8.2,58.48-10.27c67.4-4.22,93.3-40.93,103.21-67.45-23-20.79-30.12-46.79-27.85-64.14,2.34-17.88,14.35-29.31,29.9-28.77,17.89.78,
                30.09,22.69,30.4,54.52A128.43,128.43,0,0,1,922.43,949Zm-29.32-57c-1.83,1.15-2.51,6.18-2.51,6.24-.92,7,1.31,18.28,9,29.62C900.77,909.43,
                896.59,895.77,893.11,892Z' transform='translate(-647.86 -685.77)'/>
        </SvgIcon>
    );
};

export default SvgLogoPegaseo;
