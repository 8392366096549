import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Paper, Stack, Typography } from '@mui/material';
import {
    AbcOutlined as AbcOutlinedIcon,
    InfoOutlined as InfoOutlinedIcon,
    PlayArrowOutlined as PlayArrowOutlinedIcon,
    StopOutlined as StopOutlinedicon,
    StopRounded as StopRoundedIcon,
    UpdateOutlined as UpdateOutlinedIcon,
} from '@mui/icons-material';
import { ImEye } from 'react-icons/im';
import ListingRowData from '../ListingRowData/ListingRowData';
import PostActionButton from '../PostActionButton/PostActionButton';
import ISiteSchedule from '../../models/ISiteSchedule';
import cssStyles from './SiteScheduleListingStyles';

const SiteScheduleRow: React.FC<{
    siteSchedule: ISiteSchedule,
    loading: boolean,
    onStopSchedule?: ((siteSchedule: ISiteSchedule) => void)|undefined,
}> = ({ siteSchedule, loading, onStopSchedule }) => {
    // Use of react-router-dom hook
    const navigate = useNavigate();

    return (
        <Paper style={true === loading ? { opacity: 0.3, pointerEvents: 'none'} : undefined}>
            <Stack direction='row' alignItems='center' paddingX={5} paddingY={1}>
                <Stack width={1/3}>
                    <Typography sx={cssStyles.flexTypography} variant='h6'>
                        {siteSchedule.site.domain}
                        <Typography variant='body2'>{siteSchedule.siteCategory.name}</Typography>
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                        {`${siteSchedule.project.name} - ${siteSchedule.postType.name}`}
                    </Typography>
                </Stack>
                <Stack direction='row' gap={3}>
                    <ListingRowData
                        icon={InfoOutlinedIcon}
                        tooltip='Prochaine publication'
                        title={siteSchedule.toPublishAt ? moment(siteSchedule.toPublishAt).format('DD/MM/YYYY HH:mm') : 'Terminé'}
                        color={siteSchedule.toPublishAt ? 'green' : undefined}
                    />
                    <ListingRowData
                        icon={UpdateOutlinedIcon}
                        tooltip='Fréquence de publication'
                        title={`${siteSchedule.postNumber} article${1 < siteSchedule.postNumber ? 's' : ''}${siteSchedule.periodicity ? ` / ${siteSchedule.periodicity}h` : ''}`}
                    />
                    <Stack>
                        <ListingRowData
                            icon={PlayArrowOutlinedIcon}
                            tooltip='Début de la publication'
                            title={moment(siteSchedule.startAt).format('DD/MM/YYYY HH:00')}
                        />
                        <ListingRowData
                            icon={StopOutlinedicon}
                            tooltip='Fin de la publication'
                            title={moment(siteSchedule.endAt ?? siteSchedule.startAt).format('DD/MM/YYYY HH:00')}
                        />
                    </Stack>
                    <ListingRowData
                        icon={AbcOutlinedIcon}
                        tooltip='Type de génération'
                        title={siteSchedule.optimized ? 'Optimisé' : `${siteSchedule.estimatedNbrWords} mots`}
                    />
                </Stack>
                <Stack direction='row' gap={1} marginLeft='auto'>
                    {
                        siteSchedule.toPublishAt && (
                            <PostActionButton
                                tooltip='Arrêter la programmation'
                                icon={StopRoundedIcon}
                                onClick={() => onStopSchedule && onStopSchedule(siteSchedule)}
                            />
                        )
                    }
                    <PostActionButton
                        tooltip='Voir les articles déjà publiés'
                        icon={ImEye}
                        onClick={() => navigate('scheduled_posts', { state: siteSchedule })}
                    />
                </Stack>
            </Stack>
        </Paper>
    );
};

export default SiteScheduleRow;
