import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgCreateFromForm: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#40596B' d='M479.466,85.333c0-11.733-9.6-21.333-21.333-21.333H52.799c-11.733,0-21.333,9.6-21.333,21.333V364.8h448V85.333z' />
            <path fill='#ACB3BA' d='M410.132,498.133h-19.2c-18.133,0-32-13.867-32-32v-25.6H151.999v25.6c0,18.133-13.867,32-32,32h-19.2c-3.2,0-6.4,3.2-6.4,6.4l0,0c0,3.2,3.2,6.4,6.4,6.4h309.333c3.2,0,6.4-3.2,6.4-6.4l0,0C416.532,501.333,413.332,498.133,410.132,498.133z' />
            <rect x='63.469' y='96' fill='#FFFFFF' width='384' height='235.73' />
            <rect x='63.469' y='96' fill='#84DBFF' width='384' height='235.73' />
            <path fill='#CDD6E0' d='M31.466,364.8v55.467c0,11.733,9.6,21.333,21.333,21.333h405.333c11.733,0,21.333-9.6,21.333-21.333V364.8H31.466z' />
            <circle fill='#ACB3BA' cx='255.469' cy='403.2' r='17.07' />
            <rect x='151.999' y='441.6' fill='#9EAAB5' width='205.87' height='16' />
            <path fill='#F2F2F2' d='M337.599,0H140.266c-11.733,0-21.333,9.6-21.333,21.333v310.4h273.066V54.4L337.599,0z' />
            <path fill='#CDD6E0' d='M337.599,41.6c0,7.467,5.333,12.8,12.8,12.8h41.6L337.599,0V41.6z' />
            <rect x='161.599' y='42.667' fill='#FFD15C' width='57.6' height='57.6' />
            <rect x='161.599' y='132.27' fill='#CDD6E0' width='57.6' height='57.6' />
            <rect x='161.599' y='221.87' fill='#FF7058' width='57.6' height='57.6' />
            <g>
                <rect x='235.199' y='65.07' fill='#4CDBC4' width='112' height='11.733' />
                <rect x='235.199' y='154.67' fill='#4CDBC4' width='112' height='11.733' />
                <rect x='235.199' y='244.27' fill='#4CDBC4' width='112' height='11.733' />
            </g>
            <g>
                <polygon fill='#FFFFFF' points='180.799,92.8 164.799,75.733 172.266,68.267 180.799,77.867 208.532,50.133 215.999,57.6' />
                <polygon fill='#FFFFFF' points='180.799,182.4 164.799,165.333 172.266,157.867 180.799,166.4 208.532,139.733 215.999,147.2' />
                <polygon fill='#FFFFFF' points='180.799,270.933 164.799,254.933 172.266,247.467 180.799,256 208.532,229.333 215.999,236.8' />
            </g>
        </SvgIcon>
    );
};

export default SvgCreateFromForm;
