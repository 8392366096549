import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    container: {
        display: 'flex',
        padding: 5,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    deadEmoji: {
        height: 'auto',
        marginBottom: 4,
    },
    errorStructure: {
        width: 1,
        '&::before, &::after': {
            content: '""',
            display: 'block',
            height: 8,
            width: 1,
            bgcolor: 'primary.main',
        },
    },
};

export default cssStyles;
