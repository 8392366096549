import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { clearActiveStepper, setActiveStepperData } from '../../store/slices/activeStepperSlice';
import OrderAPIs from '../../APIs/OrderAPIs';
import Stepper from '../Stepper/Stepper';
import MainInfo from './MainInfo';
import ImageSelection from './ImageSelection';
import ContentEdition from './ContentEdition';
import Summary from './Summary';
import Spinner from '../Spinner/Spinner';
import StepperValidation from './StepperValidation';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IOrder from '../../models/IOrder';

const PostForm: React.FC = () => {
    // Use of react-router-dom
    // state is Order ID
    const location = useLocation();
    const state: number = location.state as number;

    // Use of hooks
    const [order, setOrder] = React.useState<IOrder|null>(null);
    const stepValidationRef = React.useRef<(() => void)|undefined>(undefined);
    const isBusy = React.useRef<boolean>(false);
    const isMounted = React.useRef<boolean>(true);
    const withConstraints = React.useRef<boolean>(true);

    // Use of redux
    const dispatch = useAppDispatch();

    // Callback to handle order busy API call
    const handleOrderBusy = React.useCallback((orderIsBusy: boolean) => {
        // Set oder busy
        order && isBusy.current !== orderIsBusy &&
            OrderAPIs.patchOrder(order.id, { isBusy: orderIsBusy })
                // On successful call
                .then((data) => {
                    if (null !== data) {
                        // Update order to set busy true/false
                        isBusy.current = orderIsBusy;
                    }
                });
    }, [order]);

    // Callback to handle window beforeunload event
    const handleWindowEvents = React.useCallback((_: BeforeUnloadEvent|FocusEvent) => {
        // Set order no longer busy
        handleOrderBusy(false);
    }, [handleOrderBusy]);

    // useEffect when component is mounting to listen to window events
    React.useEffect(() => {
        // Set order busy
        handleOrderBusy(true);
        // Listen to window events to change order status when window is
        // Closing, focused, not focused
        window.addEventListener('beforeunload', handleWindowEvents);
    }, [handleWindowEvents, handleOrderBusy]);

    // useEffect when component is unmounting
    React.useEffect(() => () => {
        // Remove event listeners
        window.removeEventListener('beforeunload', handleWindowEvents);
        // Set order no longer busy
        handleOrderBusy(false);
        // This useEffect is used to cancel all async subscriptions
        isMounted.current = false;
    }, [handleWindowEvents, handleOrderBusy]);

    // useEffect when component is mounting
    React.useEffect(() => {
        // Retrieve Order resource by its ID
        OrderAPIs.getOrder({ id: state, detailed: true })
            .then((data: Array<IOrder>|null) => {
                // If the Order resource is found
                if (data && 1 === data.length) {
                    const order = data[0];
                    // If order has Post data, update redux state
                    order.post && dispatch(setActiveStepperData({ data: {
                        identifier: order.post.id,
                        keyword: order.keyword.name,
                        title: order.title ?? order.post.title,
                        metaDesc: order.post.metaDesc,
                        imageChoice: 'update',
                        imageUrl: order.post.imageUrl ?? order.imageUrl,
                        html: order.post.html,
                        nbrWords: order.post.nbrWords,
                        optimization: { score: order.post.optimization, words: order.post.optimizationWords ?? [] },
                    }}));

                    // Determine if user has form constraints
                    withConstraints.current = ![EOrderStatuses.VERIFIED, EOrderStatuses.UNPUBLISHED].includes(order.status.slug)
                    // Update order state
                    setOrder(order);
                }
            });
    }, [state, dispatch]);

    // useEffect when component is unmounted
    React.useEffect(() => () => {
        isMounted.current = false;
        // Clear active stepper redux state
        dispatch(clearActiveStepper());
    }, [dispatch]);

    return (
        order ? (
            <Stepper
                awareOfDashboardMenu
                steps={[
                    {
                        label: 'Informations principales',
                        component: <MainInfo order={order} withConstraints={withConstraints.current} formikFormId='mainInfo' />,
                        formikFormId: 'mainInfo',
                    },
                    {
                        label: 'Choisir une image',
                        component: <ImageSelection order={order} withConstraints={withConstraints.current} formikFormId='imageSelection' />,
                        formikFormId: 'imageSelection',
                    },
                    {
                        label: 'Écrire le contenu',
                        component: <ContentEdition order={order} withConstraints={withConstraints.current} stepValidationRef={stepValidationRef} />,
                        stepValidationRef: stepValidationRef,
                    },
                    {
                        label: 'Résumé & validation',
                        component: <Summary />,
                    },
                ]}
                validationComponent={(<StepperValidation withConstraints={withConstraints.current} order={order} />)}
            />
        ) : (<Spinner />)
    );
};

export default PostForm;
