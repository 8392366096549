import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Stack } from '@mui/material';
import {
    Lock as LockIcon,
    LockOpen as LockOpenIcon,
} from '@mui/icons-material';

const HttpsRenderer: React.FC<ICellRendererParams> = props => {
    return (
        <Stack>
            {
                true === props.value ?
                    (<LockIcon fontSize='small' color='success' />)
                    : (<LockOpenIcon fontSize='small' color='warning' />)
            }
        </Stack>
    );
};

export default HttpsRenderer;
