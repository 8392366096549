import ICssStyles from '../../interfaces/ICssStyles';

// Define background color (must be HEX value)
const backgroundColorLight: string = '#FFFFFF';
const backgroundColorDark: string = '#272727';

const cssStyles: ICssStyles = {
    orderBox: {
        position: 'relative',
        backgroundImage: 'none',
        backgroundColor: theme => 'light' === theme.palette.mode ? backgroundColorLight : backgroundColorDark,
        borderRadius: '5px',
        overflow: 'hidden',
    },
    orderBoxSyntax: {
        position: 'absolute',
        top: 30,
        right: -40,
        transform: 'rotate(45deg)',
        width: 180,
        lineHeight: 1.8,
        userSelect: 'none',
    },
    orderBoxHeader: {
        padding: 4,
        backgroundImage: theme => 'light' === theme.palette.mode ?
            `linear-gradient(${backgroundColorLight}26, ${backgroundColorLight})`
            : `linear-gradient(${backgroundColorDark}26, ${backgroundColorDark})`,
    },
    orderCompanyLogo: {
        fontSize: '2rem',
        fontWeight: 700,
        height: 60,
        width: 60,
    },
};

export default cssStyles;
