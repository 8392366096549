/**
 * Define information for each potential pathname of the application
 *
 * This file is required for InlineNavigation components
 */
const inlineNavigationConfig: any = {
    dashboard: 'Tableau de bord',
    account: 'Mon compte',
    user: 'Utilisateur',
    users: 'Utilisateurs',
    profile: 'Profil',
    company: 'Société/Entreprise',
    params: 'Paramètres du compte',
    tokens: 'Acheter des jetons',
    publication_schedule: 'Programmation des publications',
    scheduled_posts: 'Articles programmés',
    sites: 'Sites du compte',
    schedule: 'Programmer',
    form: 'Formulaire',
    public_place: 'Place publique',
    projects: 'Mes projets',
    information: 'Informations',
    orders: 'Mes commandes',
    templates: 'Commander',
    posts: 'Mes articles',
    todo: 'À rédiger',
    unverified: 'En attente de validation',
    refused: 'Refusés',
    archived: 'Historique',
    proposed: "Mes propositions d'article",
    failed: 'Missions échouées',
    payments: 'Mes paiements',
    validation: 'Paiements à valider',
    archives: 'Paiements archivés',
    invoices: 'Mes factures',
    contracts: 'Mes contrats',
    documents: 'Mes documents',
    draft_posts_listing: 'Mes articles sauvegardés',
    draft_posts_published: 'Mes articles publiés',
    draft_posts_publish: 'Publier un article',
    importation: 'Importation',
    tools: 'Outils',
    indexing_status: "Vérifier l'indexation d'une URL",
    duplicate_text: 'Duplication de texte',
    redact_tool: 'Outil de rédaction',
    current_ranking: 'Positionnement actuel',
    traduction_text: "Traduction d'un texte",
    paraphrase_text: 'Paraphraser un texte',
    website_building: "Création d'un site",
    configure_backlinks: 'Configuration des redirections',
    generate_titles: 'Générer des titres',
    ai_generation_post: 'Générer un article',
    dev_area: 'Espace de développement',
    dev_lab: 'Laboratoire de tests',
};

export default inlineNavigationConfig;
