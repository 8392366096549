import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';
import IDuplicationScore from '../interfaces/IDuplicationScore';
import IKeywordTitlesResult from '../interfaces/IKeywordTitlesResult';
import IBacklink from '../interfaces/IBacklink';
import IBacklinkProcessResult from '../interfaces/IBacklinkProcessResult';

/**
 * BridgeAPIs class.
 */
export default class BridgeAPIs extends AbstractAuthAPIs {
    /**
     * Get Pixabay hits.
     *
     * @param query
     */
    public static async getPixabayHits(query: any) {
        return GenericAPIs.apiGetCall('api_bridge/pixabay_hits', this.getApiKey(), query);
    }

    /**
     * Get optimization data
     *
     * @param body
     */
    public static async getOptimizationData(body: any) {
        return GenericAPIs.apiPostCall('api_bridge/optimization_data', body, this.getApiKey());
    }

    /**
     * Get AI assistant response
     *
     * @param body
     */
    public static async getOpenAIResponse(body: any) {
        return GenericAPIs.apiPostCall('api_bridge/open_ai/completions', body, this.getApiKey());
    }

    /**
     * Get paraphrase of text using AI
     *
     * @param body
     */
    public static async getOpenAiParaphrase(body: any) {
        return GenericAPIs.apiPostCall('api_bridge/open_ai/paraphrase', body, this.getApiKey());
    }

    /**
     * Get WordPress categories
     *
     * @param body
     */
    public static async getWordPressCategories(body: any) {
        return GenericAPIs.apiPostCall('api_bridge/wordpress/categories', body, this.getApiKey());
    }

    /**
     * Get WordPress categories by site
     *
     * @param id
     */
    public static async getWordPressCategoriesBySiteId(id: number|string) {
        return GenericAPIs.apiGetCall(`api_bridge/wordpress/${id}/categories`, this.getApiKey());
    }

    /**
     * Get WordPress redirects
     *
     * @param id
     */
    public static async getRedirects(query: { login: string, password: string, domain: string }): Promise<Array<IBacklink>|null> {
        return GenericAPIs.apiGetCall('api_bridge/wordpress/redirects', this.getApiKey(), query);
    }

    /**
     * Create WordPress redirects
     *
     * @param id
     */
    public static async postWpRedirects(body: { login: string, password: string, domain: string, redirects: Array<IBacklink> }): Promise<IBacklinkProcessResult|null> {
        return GenericAPIs.apiPostCall('api_bridge/wordpress/redirects', body, this.getApiKey());
    }

    /**
     * Build a website from A to Z
     *
     * @param body
     */
    public static async websiteBuilding(body: any) {
        return GenericAPIs.apiPostCall(`api_bridge/website_building`, body, this.getApiKey());
    }

    /**
     * Build multiple website
     *
     * @param body
     */
    public static async multipleWebsiteBuilding(body: any) {
        return GenericAPIs.apiPostCall(`api_bridge/multiple_website_building`, body, this.getApiKey());
    }

    /**
     * Generate a Post from params
     *
     * @param body
     */
    public static async autoPostGeneration(body: any) {
        return GenericAPIs.apiPostCall(`api_bridge/auto_post_gen`, body, this.getApiKey());
    }

    /**
     * Generate a Post from params
     *
     * @param body
     */
    public static async postAiGenerationPost(body: any) {
        return GenericAPIs.apiPostCall(`api_bridge/ai_generation/post`, body, this.getApiKey());
    }

    /**
     * Generate an optimized Post from params
     *
     * @param body
     */
    public static async postAiGenerationPostOptimized(body: any) {
        return GenericAPIs.apiPostCall(`api_bridge/ai_generation/post/optimized`, body, this.getApiKey());
    }

    /**
     * Dtermine if URLs are indexed
     */
    public static async getUrlsIndexed(urls: Array<string>): Promise<Array<string>|null> {
        return GenericAPIs.apiGetCall(`api_bridge/urls_indexed`, this.getApiKey(), { urls });
    }

    /**
     * Get text duplication score
     */
    public static async getDuplicationScore(text: string): Promise<IDuplicationScore|null> {
        return GenericAPIs.apiPostCall(`api_bridge/duplication_score`, text, this.getApiKey());
    }

    /**
     * Get current ranking of an URL
     */
    public static async getCurrentRanking(url: string, keyword: string) {
        return GenericAPIs.apiGetCall(`api_bridge/current_ranking`, this.getApiKey(), { url, keyword });
    }

    /**
     * Get french translation of given english text
     *
     * @param text
     */
    public static async postTextTranslation(text: string, from: string, to: string) {
        return GenericAPIs.apiPostCall(`api_bridge/text_translation`, { text, from, to }, this.getApiKey());
    }

    /**
     * Get keyword titles
     */
    public static async getKeywordTitles(keyword: string, type: string, maxTitles?: number|undefined): Promise<IKeywordTitlesResult|null> {
        return GenericAPIs.apiPostCall(`api_bridge/ai_generation/titles`, { keyword, type, maxTitles }, this.getApiKey());
    }
};
