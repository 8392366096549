import ISite from '../models/ISite';
import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * SiteAPIs class.
 */
export default class SiteAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of Site ressources.
     *
     * @param query
     */
    public static async getSite(query: any) {
        return GenericAPIs.apiGetCall('sites', this.getApiKey(), query);
    }

    /**
     * Post multiple Site resources.
     *
     * @param body
     */
    public static async postMultipleSite(body: Array<any>): Promise<Array<ISite> | null> {
        return GenericAPIs.apiPostCall('sites/multiple_post', { sites: body }, this.getApiKey());
    }

    /**
     * Refresh Sites status.
     *
     * @param body
     */
    public static async refreshStatus(siteIds: Array<number>) {
        return GenericAPIs.apiPostCall(`sites/refresh_status`, { sites: siteIds }, this.getApiKey());
    }

    /**
     * Update WHOIS information of Sites.
     *
     * @param body
     */
    public static async updateWhoisInformation(siteIds: Array<number>) {
        return GenericAPIs.apiPostCall(`sites/whois`, { sites: siteIds }, this.getApiKey());
    }

    /**
     * Patch a Site resource.
     *
     * @param id
     * @param body
     */
    public static async patchSite(id: number, body: any) {
        return GenericAPIs.apiPatchCall(`sites/${id}`, null, body, this.getApiKey());
    }

    /**
     * Delete a Site resource.
     *
     * @param id
     */
    public static async deleteSite(id: number) {
        return GenericAPIs.apiDeleteCall(`sites/${id}`, this.getApiKey());
    }

    /**
     * Delete multiple Site resources at once.
     */
    public static async deleteMultipleSite(ids: Array<number>) {
        return GenericAPIs.apiPostCall(`sites/multiple_delete`, ids, this.getApiKey());
    }

    /* ********************************************************************************* */

    /**
     * Get collection of SiteCategory ressources.
     *
     * @param query
     */
     public static async getSiteCategory(query: any) {
        return GenericAPIs.apiGetCall('sites_categories', this.getApiKey(), query)
    }

    /**
     * Post a SiteCategory resource.
     *
     * @param body
     */
    public static async postSiteCategory(body: any) {
        return GenericAPIs.apiPostCall('sites_categories', body, this.getApiKey());
    }

    /**
     * Delete a SiteCategory resource.
     *
     * @param id
     */
    public static async deleteSiteCategory(id: number) {
        return GenericAPIs.apiDeleteCall(`sites_categories/${id}`, this.getApiKey());
    }
};
