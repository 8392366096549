import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgDeadEmoji: React.FC<{
    sx?: SxProps<Theme>|undefined,
    color?: 'primary'|'secondary'|undefined,
}> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path d='m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s
            -132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469
            181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469
            181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469
            -112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531
            407.019531c-124.617188 0-226-101.382812-226-226s101.382812-226 226-226 226 101.382812 226
            226-101.382812 226-226 226zm0 0' />
            <path d='m145.28125 263.765625 31.476562-31.472656 31.472657 31.472656 21.210937-21.214844
            -31.472656-31.472656 31.472656-31.472656-21.210937-21.210938-31.472657 31.472657-31.476562
            -31.472657-21.210938 21.210938 31.472657 31.472656-31.472657 31.472656zm0 0' />
            <path d='m303.769531 263.765625 31.472657-31.472656 31.476562 31.472656 21.210938-21.214844
            -31.472657-31.472656 31.472657-31.472656-21.210938-21.210938-31.476562 31.472657-31.472657
            -31.472657-21.210937 21.210938 31.472656 31.472656-31.472656 31.472656zm0 0' />
            <path d='m101.648438 341h167.296874v32.371094c0 31.226562 25.402344 56.628906 56.625 56.628906
            31.226563 0 56.628907-25.402344 56.628907-56.628906v-32.371094h28.152343v-30h-308.703124zm250.550781
            32.371094c0 14.683594-11.945313 26.628906-26.628907 26.628906-14.679687 0-26.625-11.945312-26.625
            -26.628906v-32.371094h53.253907zm0 0' />
        </SvgIcon>
    );
};

export default SvgDeadEmoji;
