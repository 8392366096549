import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    Box,
    Button,
	FormHelperText,
	IconButton,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
    Tooltip
} from '@mui/material';
import { Add, Delete, DeleteForever, Upload } from '@mui/icons-material';
import { BiDuplicate } from 'react-icons/bi';
import { MdCreate } from 'react-icons/md';
import FinalInput from '../FinalComponents/FinalInput';
import { emptyGroupedBacklink, TGroupedBackLink } from './ConfigureBacklinks';

const DispayBacklinks: React.FC = () => {
    // Use of redux hook
    const dispatch = useAppDispatch();

    return (
        <>
            <FieldArray<TGroupedBackLink>
                name='backlinks'
                subscription={{}}
                render={({ fields }) => (
                    <Box width={1}>
                        <TableContainer component={Paper}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' sx={{ maxWidth: 10 }}>
                                            <Tooltip title='Occurences'>
                                                <Box>
                                                    <BiDuplicate height={25} width={25} />
                                                </Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align='center' sx={{ maxWidth: 10 }}>
                                            <Tooltip title='Redirection existante ?'>
                                                <Box>
                                                    <MdCreate height={25} width={25} />
                                                </Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>{`URL source`}</TableCell>
                                        <TableCell>{`URL cible`}</TableCell>
                                        <TableCell align='center'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        fields.length && 0 < fields.length ? 
                                            fields.map((fieldName, rowIndex) => (
                                                <TableRow key={fieldName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align='center'>
                                                        <Field name={`${fieldName}.count`} render={({ input: { value: backlinkOccurrences }}) => backlinkOccurrences} />
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Field name={`${fieldName}.id`} render={({ input: { value: backlinkId }}) => backlinkId ? 'Oui' : 'Non'} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FinalInput name={`${fieldName}.sourceUrl`} size='small' fullWidth />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FinalInput name={`${fieldName}.targetUrl`} size='small' fullWidth />
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <IconButton onClick={() => fields.remove(rowIndex)}>
                                                            <Delete color='error' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={3} align='center'>{`Aucune donnée`}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack direction='row' gap={1} marginTop={1}>
                            <Button 
                                variant='outlined'
                                startIcon={<Add />}
                                onClick={() => fields.push(emptyGroupedBacklink)}
                            >
                                {`Ajouter un élément`}
                            </Button>
                            <Button 
                                variant='outlined'
                                startIcon={<Upload />}
                                onClick={() => dispatch(openModal({ name: 'importCsvModal' }))}
                            >
                                {`Charger un fichier CSV`}
                            </Button>
                            <Field
                                name='backlinks'
                                subscription={{}}
                                render={({ input: { onChange }}) => (
                                    <Button 
                                        variant='outlined'
                                        color='error'
                                        startIcon={<DeleteForever />}
                                        onClick={() => onChange([])}
                                    >
                                        {`Tout supprimer`}
                                    </Button>
                                )}
                            />
                        </Stack>
                    </Box>
                )}
            />
            <Field
                name='backlinks'
                subscription={{ error: true, touched: true }}
                render={({ meta: { error }}) => (
                    'string' === typeof error && <FormHelperText error>{error}</FormHelperText>
                )}
            />
        </>
    );
}

export default DispayBacklinks;
