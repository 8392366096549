import React from 'react';
import useInterval from '../../hooks/useInterval';
import { useAppSelector } from '../../store/hooks';
import { ITextEditorState } from '../../store/slices/textEditorSlice';
import PostAPIs from '../../APIs/PostAPIs';
import Tools from '../../helpers/Tools';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IPostFormStepperData from '../../interfaces/IPostFormStepperData';
import IOrder from '../../models/IOrder';
import IPost from '../../models/IPost';

const interval: number = Number(process.env.REACT_APP_AUTOSAVE_INTERVAL);

const AutoSave: React.FC<{ order: IOrder, withConstraints: boolean }> = props => {
    // Use of hooks
    const [order, setOrder] = React.useState<IOrder>(props.order);
    // Use of redux
    const postFormStepperData: IPostFormStepperData = useAppSelector(state => state.activeStepper.data);
    const textEditorState: ITextEditorState = useAppSelector(state => state.textEditor);

    // useInterval to save post every X seconds
    useInterval(() => {
        // Create body request with data
        const bodyRequest = {
            // Do not update status if user is admin
            status: props.withConstraints && EOrderStatuses.CORRECTED !== order.status.slug && EOrderStatuses.SAVED !== order.status.slug ?
                EOrderStatuses.REFUSED === order.status.slug ?
                    EOrderStatuses.CORRECTED : EOrderStatuses.SAVED
                : undefined,
            title: postFormStepperData.title,
            metaDesc: postFormStepperData.metaDesc,
            // Remove duplicate <p></p> from html
            html: Tools.removeDuplicateTags(textEditorState.content.html ?? ''),
            nbrWords: textEditorState.content.nbrWords,
            optimization: textEditorState.optimization?.score,
            optimizationWords: textEditorState.optimization?.words,
        };

        // Patch the post by its ID
        PostAPIs.patchPost(postFormStepperData.identifier!, Tools.convertToBodyRequest(bodyRequest))
            .then((data: IPost|null) => null !== data &&
                // Update order status with response
                setOrder(currentOrder => ({
                    ...currentOrder,
                    status: data.order.status,
                }))
            );
    }, interval * 1000, []);

    return null;
};

export default AutoSave;
