import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setStepperIsLoading } from '../../store/slices/activeStepperSlice';
import OrderAPIs from '../../APIs/OrderAPIs';
import OtherAPIs from '../../APIs/OtherAPIs';
import Tools from '../../helpers/Tools';
import { Button } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import IDuplicateOrder from '../../interfaces/IDuplicateOrder';
import IOrderFormStepperData from '../../interfaces/IOrderFormStepperData';
import IUploadFile from '../../models/IUploadFile';
import IOrder from '../../models/IOrder';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IOrderBody from '../../interfaces/IOrderBody';

const StepperValidation: React.FC = () => {
    // Use of hooks
    const isMounted = React.useRef<boolean>(true);

    // Use of redux
    const dispatch = useAppDispatch();
    const orderFormState: IOrderFormStepperData = useAppSelector(state => state.activeStepper.data);
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Use react-router-dom hooks
    const navigate = useNavigate();

    // Callback to handle redirect after order creation or update
    const thenApiCall = React.useCallback((orders: Array<IOrder>|null) => {
        // Redirect to order listing
        if (true === isMounted.current) {
            null !== orders ?
                // On successful API call, redirect
                navigate('../..')
                // Else stop stepper loading
                : dispatch(setStepperIsLoading(false));
        }
    }, [navigate, dispatch]);

    // Callback to handle the final validation
    const handleFinalValidation = React.useCallback(async () => {
        // Loading while processing
        dispatch(setStepperIsLoading(true));
        // Handle order duplicates
        const duplicates = orderFormState.duplicates?.map((duplicate: IDuplicateOrder) => ({
            editor: duplicate.editor?.id ?? null,
            status: duplicate.editor ?
                // If order is public then status is 'proposed' else it's 'todo'
                'public' === orderFormState.privacy?.value ? EOrderStatuses.PROPOSED : EOrderStatuses.TODO
                // Order is unassigned by default if no editor
                : EOrderStatuses.UNASSIGNED,
            site: duplicate.destinationWebsite?.website.value ?? null,
            category: duplicate.destinationWebsite?.category ?? null,
            autoPublish: duplicate.autoPublish ?? null,
            toPublishAt: duplicate.toPublishAt ?? null,
        }));

        // Create clean body request
        let body: IOrderBody = {
            status: orderFormState.editor ?
                // If order is public then status is 'proposed' else it's 'todo'
                'public' === orderFormState.privacy?.value ? EOrderStatuses.PROPOSED : EOrderStatuses.TODO
                // Order is unassigned by default if no editor
                : EOrderStatuses.UNASSIGNED,
            postType: orderFormState.postType!.value,
            activitySector: orderFormState.activitySector!.value,
            keyword: orderFormState.keyword!.value,
            // Set imageKeyword only if imageUploaded and imageBank are not defined
            imageKeyword: (orderFormState.imageUploaded || orderFormState.imageBank) ? null : orderFormState.imageKeyword,
            // Set imageUrl either by imageUploaded or by imageBank or null
            imageUrl: orderFormState.imageUploaded ?? orderFormState.imageBank ?? null,
            title: orderFormState.title,
            metaDesc: orderFormState.metaDesc,
            // Set minOptimization only if freeOptimization is not checked
            minOptimization: true === orderFormState.freeOptimization ? null : orderFormState.minOptimization,
            minNbrWords: orderFormState.minNbrWords,
            syntaxLevel: orderFormState.syntaxLevel!.value,
            brief: orderFormState.brief,
            site: orderFormState.destinationWebsite?.website.value ?? null,
            category: orderFormState.destinationWebsite?.category ?? null,
            // Set autoPublish if destination website is defined
            autoPublish: orderFormState.destinationWebsite ? orderFormState.autoPublish : false,
            toPublishAt: orderFormState.toPublishAt,
            deadlineAt: orderFormState.deadlineAt,
            links: orderFormState.mandatoryLinks ?? null,
            editor: orderFormState.editor?.id ?? null,
            isPublic: 'public' === orderFormState.privacy?.value,
            isByApplyment: 'public' === orderFormState.privacy?.value && 'tender' === orderFormState.publicChoice?.value,
            isFirstServed: 'public' === orderFormState.privacy?.value && 'first' === orderFormState.publicChoice?.value,
            price: orderFormState.price ?? null,
            project: orderFormState.project!.value,
            user: instanceUserId,
            owner: instanceUserId,
            duplicates: duplicates,
            // Send only user id to server
            sharedUsers: orderFormState.shares?.map(user => user.id),
        };

        // Handle image upload
        if (orderFormState.imageUploaded && orderFormState.imageUploaded.startsWith('blob:')) {
            // Get File with object url
            // We do that because we can't store File instance in redux.
            // So we create an ObjectURL when file is selected and retreive the File from ObjectURL.
            const file = await fetch(orderFormState.imageUploaded)
                .then((response: Response) => response.blob());

            // If file has been successfully retrieved
            if ((file instanceof File || file instanceof Blob) && file.type.includes('image')) {
                // Upload file on server
                await OtherAPIs.postUploadFile(file, orderFormState.keyword!.label)
                    // On successful API call, set the image url
                    .then((data: IUploadFile) => {
                        body.imageUrl = data.url;
                    });
            }
        }

        // Convert to body request
        body = Tools.convertToBodyRequest(body);

        // Call API to create or update orders
        undefined === orderFormState.idOrder ?
            OrderAPIs.postOrder(body).then(thenApiCall)
            : OrderAPIs.patchOrder(orderFormState.idOrder, body).then(thenApiCall);
    }, [instanceUserId, orderFormState, thenApiCall, dispatch]);

    // useEffect when component is unmounted
    React.useEffect(() => () => {
        // Set isMounted to false to prevent states changes
        isMounted.current = false;
    }, []);

    return (
        <Button
            variant='contained'
            onClick={handleFinalValidation}
            endIcon={<CheckCircleIcon />}
        >
            {`Valider`}
        </Button>
    );
};

export default StepperValidation;
