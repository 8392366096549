import React from 'react';
import moment from 'moment-timezone';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import { Button, Stack, TextField } from '@mui/material';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import GenericModal from './GenericModal';

const ModalPublicationDate: React.FC<{
    title?: string|undefined,
    name?: string|undefined,
    onValidation: (orderId: number, toPublishAt: string|null) => void,
}> = props => {
    // Use of hooks
    const [toPublishAt, setToPublishAt] = React.useState<Date|null>(null);
    const modalName = React.useRef<string>(props.name ?? 'modalToPublishAt');

    // Use of redux
    const dispatch = useAppDispatch();
    const params: { orderId: number, toPublishAt: string }|undefined = useAppSelector(state => state.activeModal.params);

    // useEffect whenever toPubishAt changes
    React.useEffect(() => {
        params?.toPublishAt && setToPublishAt(moment(params.toPublishAt).toDate());
    }, [params]);

    return (
        <GenericModal
            name={modalName.current}
            title={props.title ?? 'Mise à jour de la date de publication'}
            width={1/3}
        >
            <Stack alignItems='start' gap={2}>
                <DateTimePicker
                    upperLabel='Date de publication'
                    value={toPublishAt}
                    views={['day', 'hours']}
                    minDateTime={moment().add(1, 'h').toDate()}
                    renderInput={(forwardProps) => <TextField size='small' {...forwardProps} />}
                    onChange={setToPublishAt}
                />
                <Stack direction='row' gap={2}>
                    <Button
                        variant='outlined'
                        color='success'
                        onClick={() => {
                            props.onValidation(params!.orderId, moment(toPublishAt).format('YYYY-MM-DD HH:mm:00'));
                            dispatch(closeModal());
                        }}
                    >
                        {`Valider`}
                    </Button>
                    <Button
                        variant='outlined'
                        color='error'
                        onClick={() => {
                            props.onValidation(params!.orderId, null);
                            dispatch(closeModal());
                        }}
                    >
                        {`Supprimer la date de publication`}
                    </Button>
                </Stack>
            </Stack>
        </GenericModal>
    );
};

export default ModalPublicationDate;
