import React from 'react';
import moment from 'moment-timezone';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import PostAPIs from '../../APIs/PostAPIs';
import {
    Stack,
    Box,
    Typography,
} from '@mui/material';
import {
    Check as CheckIcon,
    Close as CloseIcon,
    Info as InfoIcon,
    Language as LanguageIcon,
    Numbers as NumbersIcon,
    Percent as PercentIcon,
    PlaylistAddCheck as PlaylistAddCheckIcon,
    QuestionMark as QuestionMarkIcon,
} from '@mui/icons-material';
import { ImEye } from 'react-icons/im';
import { BiMedal } from 'react-icons/bi';
import { MdOutlineUnpublished } from 'react-icons/md';
import { FiArrowUpRight, FiArrowDownRight } from 'react-icons/fi';
import RankingRowData from './RankingRowData';
import ListingRowData from '../ListingRowData/ListingRowData';
import PostActionButton from '../PostActionButton/PostActionButton';
import IOrder from '../../models/IOrder';
import IPost from '../../models/IPost';
import cssStyles from './ProjectInformationStyles';

const PublishedOrder: React.FC<{ order: IOrder }> = props => {
    // Use of hooks
    const [order, setOrder] = React.useState<IOrder>(props.order);
    const [loading, setLoading] = React.useState<string>('');

    // Store Post of order state which can not be undefined
    const post: IPost = order.post!;

    // Use of redux
    const dispatch = useAppDispatch();

    // Callback to handle Order indexing status update
    const handleRankingCalculation = React.useCallback(() => {
        // Disable component during API call
        setLoading('ranking');
        // Call API to retrieve ranking of Post on given keyword
        PostAPIs.postPostRanking({ post: post.id, keyword: order.keyword })
            // On successful API call, force Orders state refresh
            .then((data: IPost|null) => null !== data &&
                setOrder({ ...order, post: data })
            )
            .finally(() => setLoading(''));
    }, [order, post]);

    return (
        <Stack direction='row' sx={'' !== loading ? cssStyles.orderStructureLoading : undefined }>
            <Box sx={cssStyles.orderInfo}>
                <Typography fontWeight={700} color='primary.main'>
                    {props.order.keyword.name}
                </Typography>
                <Stack direction='row' justifyContent='space-evenly' alignItems='center'>
                    <RankingRowData ranking={post.ranking} minWidth={110} />
                    <Stack alignItems='flex-start' rowGap={1}>
                        <ListingRowData
                            tooltip='Site de destination'
                            icon={LanguageIcon}
                            content={order.site ? `${order.site.domain} (${order.siteCategory!.name})` : new URL(order.post!.wpPostLink!).hostname.replace('www.', '')}
                        />
                        <ListingRowData
                            icon={post.indexation ?
                                    0 !== post.indexation.evolution ?
                                        post.indexation.evolution > 0 ?
                                            FiArrowUpRight : FiArrowDownRight
                                    : post.indexation.isIndexed ?
                                        CheckIcon : CloseIcon
                                : QuestionMarkIcon}
                            tooltip={post.indexation ?
                                    post.indexation.isIndexed ?
                                        "L'article est indéxé" : "L'article n'est pas indéxé"
                                    : 'Indexation inconnue'}
                            color={post.indexation ?
                                    post.indexation.isIndexed ?
                                    'success.main' : 'error.main'
                                    : 'textSecondary'}
                            content={post.indexation ? moment(post.indexation.createdAt).fromNow() : 'Indexation à tester'}
                        />
                    </Stack>
                    <Stack alignItems='flex-start' rowGap={1}>
                        <ListingRowData
                            tooltip="Type d'article"
                            icon={InfoIcon}
                            content={order.postType.name}
                        />
                        <ListingRowData
                            tooltip="Score d'optimisation"
                            icon={PercentIcon}
                            content={order.post?.optimization ?? '-'}
                        />
                        <ListingRowData
                            tooltip="Nombre de mots"
                            icon={NumbersIcon}
                            content={order.post?.nbrWords ?? '0'}
                        />
                    </Stack>
                    <Stack>
                        <Typography variant='body2' color='textSecondary' sx={{ fontStyle: 'italic' }}>
                            {`Publié le`}
                        </Typography>
                        <Typography variant='body2'>
                            {moment(post.publishedAt).format('DD/MM/YYYY à HH:mm')}
                        </Typography>
                    </Stack>
                    <Typography fontWeight='bold' color={!props.order.price ? 'secondary.main' : undefined}>
                        {`${props.order.price ?? '15.00'} €`}
                    </Typography>
                </Stack>
            </Box>
            <Stack direction='row' gap={2} sx={cssStyles.orderAction}>
                <PostActionButton
                    icon={PlaylistAddCheckIcon}
                    tooltip="Preuve d'indexation"
                    onClick={() => window.open(`https://google.fr/search?q=site:${post.wpPostLink!}`, '_blank')}
                />
                <PostActionButton
                    icon={BiMedal}
                    tooltip='Positionnement Google'
                    loading={'ranking' === loading}
                    disabled={null === post.indexation || false === post.indexation.isIndexed}
                    onClick={handleRankingCalculation}
                />
                {
                    post.wpPostLink && (
                        <>
                            {
                                props.order.site && (
                                    <PostActionButton
                                        icon={MdOutlineUnpublished}
                                        tooltip="Dépublier l'article"
                                        onClick={() => dispatch(openModal({ name: 'confirmUnpublishModal', params: props.order.id }))}
                                    />
                                )
                            }
                            <PostActionButton
                                icon={ImEye}
                                tooltip="Voir l'article"
                                onClick={() => window.open(post.wpPostLink!, '_blank')}
                            />
                        </>
                    )
                }
            </Stack>
        </Stack>
    );
};

export default PublishedOrder;
