import React from 'react';
import { Typography, Stack } from '@mui/material';
import NamespacePanel from '../NamespacePanel/NamespacePanel';
import SvgCreateFromForm from '../Svg/SvgCreateFromForm';
import SvgOrderDuplication from '../Svg/SvgOrderDuplication';
import SvgImport from '../Svg/SvgImport';

const OrderTemplates: React.FC = () => {
    return (
        <>
            <Typography component='h1' variant='h4' align='center' paddingBottom={4} fontWeight='bold'>
                {`Choisissez une méthode de création`}
            </Typography>
            <Stack direction='row' flexWrap='wrap' justifyContent='center' gap={2}>
                <NamespacePanel
                    svg={<SvgCreateFromForm sx={{ fontSize: '6rem' }} />}
                    header='Création depuis un formulaire'
                    text="Création d'une commande à partir d'un formulaire. Simple et efficace !"
                    to='form'
                />
                <NamespacePanel
                    svg={<SvgOrderDuplication sx={{ fontSize: '6rem' }} />}
                    header="Duplication d'une commande"
                    text="Création d'une commande à partir d'une autre commande existante.
                    Pour gagner du temps !"
                    to='#'
                />
                <NamespacePanel
                    svg={<SvgImport sx={{ fontSize: '6rem' }} />}
                    header='Importation'
                    text='Créez une ou plusieurs commandes depuis un fichier texte formaté.'
                    to='#'
                />
            </Stack>
        </>
    );
};

export default OrderTemplates;
