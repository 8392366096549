import { ReactElement, ReactNode } from 'react';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import { store } from '../store/store';
import ISelectOption from '../interfaces/ISelectOption';
import IScopesGroup from '../models/IScopesGroup';
import ILink from '../interfaces/ILink';
import IOrder from '../models/IOrder';

/**
 * Tools class.
 */
export default class Tools {
    /**
     * Fetch an object by a path
     *
     * Exemple path : 'path1.path2.path3'
     *
     * The recursive method will try to access the data at object[path1][path2][path3]
     *
     * @param object the object to fetch
     * @param path the path of variable names separated by a dot. Ex: 'path1.path2.path3'
     *
     * @returns value in object under path given
     */
    public static getObjectValueByPath(object: any, path: string): any {
        // Split the path into array
        const pathArray = path.split('.');

        // If the array contains only one element then the recursive method is over
        if (1 === pathArray.length) {
            return null === object ? null : object[pathArray[0]];
        }
        // Set the with the next variable of the patch
        object = object[pathArray[0]];
        // Remove the current variable from the path
        pathArray.splice(0, 1);

        // Recursively call the getObjectValueByPath method with object and path as a string separated by dot
        return this.getObjectValueByPath(object, pathArray.join('.'));
    }

    /**
     * Convert associative array to a potential body request for API
     *
     * @param values
     * @param removeEmptyValues true to remove empty values from body request
     */
    public static convertToBodyRequest(values: any, removeEmptyValues: boolean = false): any {
        // Retrieve array entries
        const arrayEntries: Array<any> = Object.entries(values);

        // For each entry, search for empty value
        arrayEntries.forEach(([key, value], index) => {
            if ('' === value) {
                arrayEntries[index] = [key, true === removeEmptyValues ? undefined : null];
            } else if (Array.isArray(value) && 0 === value.length && true === removeEmptyValues) {
                arrayEntries[index] = [key, undefined];
            } else if (value instanceof Object) {
                arrayEntries[index] = [key, this.convertToBodyRequest(value, removeEmptyValues)];
            } else if (value instanceof String) {
                arrayEntries[index] = [key, value.trim()];
            }
        });

        return Object.fromEntries(arrayEntries);
    }

    /**
     * Convert given array of object to an array of select options
     *
     * @param array
     * @param keyValue key in array to map options values
     * @param keyLabel key in array to map options labels
     * @param keyIcon  key in array to map options icons
     */
    public static convertToSelectOptions(array: Array<{}>|null, keyValue: string, keyLabel: string, keyIcon?: string|undefined): Array<ISelectOption> {
        // Conversion
        return array?.map((object: any) => ({
            value: object[keyValue],
            label: object[keyLabel],
            icon: keyIcon ? object[keyIcon] : undefined,
        })) ?? [];
    }

    /**
     * Similar to strip_tags for PHP
     *
     * @param html
     */
    public static stripTags(html: string|null|undefined): string {
        // initialize result
        let clearString: string = '';

        if (html) {
            // Create factice div & insert html
            const div = document.createElement('div');
            div.innerHTML = html.replaceAll('</', ' </');
            clearString = div.textContent || div.innerText || '';
        }

        return clearString.trim();
    }

    /**
     * Count words in string
     *
     * @param plainText
     */
    public static countWords(plainText: string): number {
        // New line, carriage return, line feed
        const regex = /(?:\r\n|\r|\n)/g;
        // Replace above characters with space
        const cleanString = plainText.replace(regex, ' ').trim();
        // Matches words
        const wordArray = cleanString.match(/(?:[\w]+['’‘-]*)+/gu);

        return wordArray ? wordArray.length : 0;
    }

    /**
     * Remove duplicate empty tags
     *
     * @param html the html to browse
     * @param tag the tag to search (ex: 'p')
     */
    public static removeDuplicateTags(html: string, tag: string = 'p'): string {
        const contentToFind = `<${tag}></${tag}><${tag}></${tag}>`;
        while (html.indexOf(contentToFind) !== -1) {
            html = html.replaceAll(contentToFind, `<${tag}></${tag}>`);
        }

        return html;
    }

    /**
     * Compare if at least one scope from needle is included in haystack scopes
     *
     * @param needle   array of scopes to compare with haystack
     * @param haystack array of scopes
     *
     * @returns boolean
     */
    public static isScoped(needle: Array<string>, haystack: Array<string>): boolean {
        const dbScopesGroups: Array<IScopesGroup>|null = store.getState().scopesGroups.data;
        let convertedScopes: Array<string> = [];

        if (!dbScopesGroups) {
            return false;
        }

        haystack.forEach((scope: string) => {
            const convertedScope = dbScopesGroups.find((value: IScopesGroup) => value.name === scope);

            convertedScopes = undefined === convertedScope ?
                [ ...convertedScopes, scope ] : [ ...convertedScopes, ...(convertedScope.scopes) ];
        });

        return needle.some((scope: string) => convertedScopes.includes(scope));
    }

    /**
     * https://mui.com/components/avatars/#letter-avatars
     *
     * @param string
     *
     * @returns color associated to given string
     */
    public static stringToColor(string: string): string {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    /**
     * Render a given component as html element
     *
     * @param component the component to render
     *
     * @returns HTMLElement the rendered component
     */
    public static renderComponent(component: ReactElement): HTMLElement {
        // Create new div
        const domNode = document.createElement('div');
        // Force react to render component has html in created div
        ReactDOM.render(component, domNode);

        // Return created div
        return domNode;
    }

    /**
     * Transform a basic number into an invoice number
     *
     * / ! \ CAUTION / ! \ Do not change this function unless you are 100% sure of what you are doing.
     * This function can mess up all customers factures number incrementation so
     * ACT KNOWINGLY !!
     *
     * @param number
     */
    public static transformToInvoiceNumber(number: number): string {
        return `GP${moment().format('Y')}${number.toString().padStart(4, '0')}`
    }

    /**
     * Use to scroll to the top or bottom of the dashboard
     *
     * @param direction
     */
    public static scrollInDashboard(direction: 'top'|'bottom'): void {
        // Select element by id
        const element = document.querySelector('#dashboardContent');

        // Scroll to the top of this component
        element && element.scrollTo({
            behavior: 'smooth',
            ...('top' === direction ? { top: 0, left: 0 } : { top: element.scrollHeight })
        });
    }

    /**
     * Get identifier from UUID
     *
     * @param uuid
     *
     * @returns string
     */
    public static getIdentifierFromUuid(uuid: string): string {
        return uuid.split('-')[0];
    }

    /**
     * Retrieve criterias from Order
     *
     * @param order
     *
     * @returns Array<string>
     */
    public static getOrderCriterias(order: IOrder): Array<string> {
        // Number words is always defined
        const criterias = [];
        if (order.minNbrWords) {
            criterias.push(`Nombre de mots minimum dans l'article : ${order.minNbrWords}`)
        }
        // Define syntax level
        if (order.syntaxLevel && 'Libre' !== order.syntaxLevel.name) {
            criterias.push(`Niveau d'orthographe : ${order.syntaxLevel.name} (${order.syntaxLevel.description})`)
        }
        // Define post min optimization
        if (order.minOptimization) {
            criterias.push(`Optimisation minimum de l'article : ${order.minOptimization}%`);
        }
        // Define mandatory links
        if (order.links && 0 < order.links.length) {
            criterias.push(`Liens obligatoires à retrouver dans l'article : ${order.links.map((link: ILink) => link.url).join(', ')}`);
        }
        // Define deadline
        if (order.deadlineAt) {
            criterias.push(
                `La rédaction de l'article doit impérativement avoir lieu avant le :
                ${moment(order.deadlineAt).format('DD/MM/Y à H:mm')}`
            );
        }

        return criterias;
    }

    /**
     * Simple generate Post html from array of titles & contents
     *
     * @param titles
     * @param contents
     *
     * @returns string
     */
    public static generatePostHtml(post: any): string {
        let html: string = '';

        post.forEach(({ title, content }: any, index: number) => {
            html += 0 === index ? `<h1>${title}</h1>` : `<p></p><h2>${title}</h2>`;
            html += '<p></p>';
            html += `<p>${content}</p>`;
        });

        return html;
    };

    /**
     * Find and replace string by JSX
     *
     * @param string  the text to browse
     * @param find    string or array of string to search
     * @param replace function that replace the string with JSX
     *
     * @returns Array of ReactNode
     */
    public static replaceStringByJsx(string: string, find: Array<string>|string, replace: (match: string, index: number) => JSX.Element): Array<ReactNode> {
        find = Array.isArray(find) ? find : [find];
        // Split text in sentences
        let sentences = string.split(/(\b[^.!?\n]+\S+)/);

        // Replace each sentence with a JSX element if it matchs
        return sentences.map(sentence => {
            // Find sentence match
            const indexOfSentence = find.indexOf(sentence);

            // If sentence match
            return indexOfSentence !== -1 ?
                // Replace sentence with JSX element
                replace(sentence, indexOfSentence)
                // Else return sentence
                : `${sentence} `;
        });
    }

    /**
     * Compare two string with normalizing accents and case insensitive
     *
     * @param string1
     * @param string2
     *
     * @returns true if both string matches
     */
    public static safeStringCompare(string1: string, string2: string): boolean {
        string1 = string1.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();
        string2 = string2.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();
        return string1 === string2;
    }

    /**
     * Search if a string is in an array of string with normalizing accents and case insensitive
     *
     * @param string1
     * @param string2
     *
     * @returns true if both string matches
     */
    public static safeStringInclude(haystack: Array<string>, needle: string): boolean {
        needle = needle.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();
        return haystack.some((string: string) => {
            string = string.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();

            return string === needle;
        });
    }
};
