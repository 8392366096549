import React from 'react';
import { Field } from 'react-final-form';
import SelectActivitySector from '../SelectEntities/SelectActivitySector';
import SelectProject from '../SelectEntities/SelectProject';
import SelectProjectPostType from '../SelectEntities/SelectProjectPostType';
import ISelectOption from '../../interfaces/ISelectOption';

const ProjectStep: React.FC = () => (
    <>
        <Field
            name='postType'
            render={({ input: { onChange: postTypeOnChange }}) => (
                <SelectProject
                    name='project'
                    // Reset post type when project is changed
                    onChange={() => postTypeOnChange(null)}
                    sx={{ marginBottom: 2 }}
                />
            )}
        />
        <Field<ISelectOption>
            name='project'
            subscription={{ value: true }}
            render={({ input: { value: projectOption }}) => (
                <SelectProjectPostType
                    name='postType'
                    projectId={Number(projectOption.value)}
                    sx={{ marginBottom: 2 }}
                />
            )}
        />
        <SelectActivitySector name='activitySector' />
    </>
);

export default ProjectStep;
