import React from 'react';
import {
    SxProps,
    Theme,
    Stack,
    Typography,
} from '@mui/material';

const HomePanel: React.FC<{
    header: string,
    color?: string|undefined,
    offsetLeft?: number|undefined,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Define base padding
    const basePaddingX: number = 4;

    return (
        <Stack
            paddingY={3}
            paddingLeft={basePaddingX + (props.offsetLeft ?? 0)}
            paddingRight={basePaddingX}
            marginLeft={props.offsetLeft ? -props.offsetLeft : undefined}
            sx={{
                backgroundColor: 'white',
                borderRadius: 3,
                ...(props.offsetLeft && { zIndex: -1 }),
                ...props.sx,
                '&>*': { color: props.color ?? undefined },
            }}
        >
            <Typography paddingBottom='1.5vh' sx={{ textTransform: 'uppercase', fontSize: { xs: '2vh' } }}>
                {props.header}
            </Typography>
            {props.children}
        </Stack>
    );
};

export default HomePanel;
