import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    Chip,
    Typography,
    Stack,
    Button,
} from '@mui/material';
import ModalSingleInput from '../Modal/ModalSingleInput';

const PostTypesManagement: React.FC<{
    initialPostTypes: Array<string>,
    onChange: (postTypes: Array<string>) => void
}> = props => {
    // Use of hooks
    const [postTypes, setPostTypes] = React.useState<Array<string>>(props.initialPostTypes);
    const modalName = React.useRef<string>('singleInputModalPostType');

    // Use of redux
    const dispatch = useAppDispatch();

    // useEffect each time our hook value changes
    React.useEffect(() => {
        // Call given prop callback
        props.onChange(postTypes);
    }, [props, postTypes])

    return (
        <>
            <ModalSingleInput
                name={modalName.current}
                modalTitle="Nouveau type d'article"
                inputLabel="Nom du type d'article"
                onValidation={value => setPostTypes([ ...postTypes, value ])}
            />
            {
                0 === postTypes.length ? (
                    <Typography variant='body2'>
                        {`Aucun type d'article ...`}
                    </Typography>
                ) : (
                    <Stack direction='row' flexWrap='wrap' gap={1}>
                        {
                            postTypes.map((value: string, index: number) => (
                                <Chip
                                    key={index}
                                    label={value}
                                    variant='outlined'
                                    onDelete={() => setPostTypes(postTypes.filter((_, key) => key !== index))}
                                />
                            ))
                        }
                    </Stack>
                )
            }
            <Button
                variant='outlined'
                onClick={() => dispatch(openModal({ name: modalName.current }))}
                sx={{ marginTop: 3 }}
            >
                {`Ajouter un type d'article`}
            </Button>
        </>
    );
};

export default PostTypesManagement;
