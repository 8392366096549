import React from 'react';
import { ResponsiveStyleValue, SystemStyleObject } from '@mui/system';
import {
    SxProps,
    Theme,
    Box,
} from '@mui/material';

const SvgStyledBox: React.FC<{
    backgroundColor: SystemStyleObject<Theme>|ResponsiveStyleValue<any>|((theme: Theme) => ResponsiveStyleValue<any>),
    color: string|undefined,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    return (
        <Box
            component='svg'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1112.14 248.38'
            sx={props.sx}
        >
            <path
                fill={props.color}
                d='M1233,1095h99c63,0,88.87-31.15,124.2-83L1568,848l-335,.38Z'
                transform='translate(-455.86 -847.62)'
            />
            <path
                fill={props.backgroundColor}
                d='M456,1096l819-.11a189.9,189.9,0,0,0,157.18-83.34L1544,847.62H455.86Z'
                transform='translate(-455.86 -847.62)'
            />
        </Box>
    );
};

export default SvgStyledBox;
