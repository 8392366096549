import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { SxProps, Theme } from '@mui/material';
import { FormikProps } from 'formik';
import FormikInputSelectGroup from '../FormikComponent/FormikInputSelectGroup';
import ICountry from '../../models/ICountry';

const FormikPhone: React.FC<{
    formikProps: FormikProps<any>,
    prefixName: string,
    phoneName: string,
    mask?: string|undefined,
    label?: string|undefined,
    upperLabel?: string|undefined,
    disabled?: boolean|undefined,
    countryId?: number|string|undefined,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Use of redux
    const countries: Array<ICountry> = useAppSelector(state => state.countries.data!);

    return (
        <FormikInputSelectGroup
            {...props}
            selectName={props.prefixName}
            inputName={props.phoneName}
            mask={props.mask ?? '_'}
            // Change format based on prefix
            format={countries.find(country => country.phonePrefix === props.formikProps.values[props.prefixName])?.phoneFormat ?? countries[0].phoneFormat}
            options={countries.map(country => ({ label: country.phonePrefix, value: country.phonePrefix }))}
        />
    );
};

export default FormikPhone;
