import React from 'react';
import { Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import KeywordDisplay from '../KeywordDisplay/KeywordDisplay';
import PostEditor from '../PostEditor/PostEditor';
import SaveDraftPost from './SaveDraftPost';
import IOptimizationData from '../../interfaces/IOptimizationData';
import IDraftPost from '../../models/IDraftPost';

const DraftPostEditor: React.FC<{ draftPost: IDraftPost, onPreviousClick: () => void }> = props => {
    // Construct optimization data
    const optiData: IOptimizationData = {
        score: props.draftPost.optimization,
        words: props.draftPost.optimizationWords,
        prevPlainText: null,
    };

    return (
        <>
            <Tooltip title='Précédent' placement='right'>
                <IconButton
                    onClick={() => props.onPreviousClick()}
                    sx={{ marginBottom: 3 }}
                >
                    <ArrowBackIcon fontSize='large' color='secondary' />
                </IconButton>
            </Tooltip>
            <KeywordDisplay keyword={props.draftPost.keyword} />
            <PostEditor initialHtml={props.draftPost.html} keyword={props.draftPost.keyword} optimizationData={optiData} />
            <Divider sx={{ marginY: 4 }} />
            <SaveDraftPost autosave draftPost={props.draftPost} keyword={props.draftPost.keyword} />
            <Typography variant='caption' sx={{ marginTop: 2 }}>
                {`Prix de l'article : ${props.draftPost.price} €`}
            </Typography>
        </>
    );
};

export default DraftPostEditor;
