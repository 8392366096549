import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useTimeout from '../../hooks/useTimeout';
import { closeModal, IActiveModalState, isModalOpened } from '../../store/slices/activeModalSlice';
import {
    SxProps,
    Theme,
    Box,
    TextField,
    InputAdornment,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Pagination,
    Skeleton,
    Stack,
    Typography,
    Button,
    Paper,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import GenericModal from './GenericModal';
import ImageWithLoad from '../ImageWithLoad/ImageWithLoad';
import IPaginatorResponse from '../../interfaces/IPaginatedResponse';
import IUser from '../../models/IUser';
import cssStyles from './ModalStyles';

interface IModalSelectUsers {
    page: number,
    pagination: number|null,
    selection: Array<number>|null,
    data: Array<IUser>,
    filter: string,
};

const ModalSelectUsers: React.FC<{
    name?: string|undefined,
    selectUsers?: Array<number>|undefined,
    excludeUsers?: Array<number>|undefined,
    singleSelect?: boolean|undefined,
    getUsers?: ((params: any) => Promise<IPaginatorResponse<IUser>|null>)|undefined,
    onValidation: (
        params: any,
        selected: Array<IUser>,
        // Base on props.selectedUsersIds
        newSelected: Array<IUser>|undefined,
        unselected: Array<IUser>|undefined
    ) => void,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'selectUsersModal');
    const initialModalData = React.useRef<IModalSelectUsers>({
        page: 1,
        pagination: null,
        selection: null,
        data: [],
        filter: '',
    });
    const [modalData, setModalData] = React.useState<IModalSelectUsers>(initialModalData.current);

    // Use of redux
    const dispatch = useAppDispatch();
    const activeModalState: IActiveModalState = useAppSelector(state => state.activeModal);
    const modalOpened = useAppSelector(isModalOpened(modalName.current));

    // Define const
    const itemsPerPage: number = 12;
    const imageHeight: number = 160;

    // Callback used during modal validation
    const handleModalValidation = React.useCallback(() => {
        // Call given props callback
        null !== modalData.data && null !== modalData.selection && props.onValidation &&
        // Call given prop callback to validate modal
            props.onValidation(
                activeModalState.params,
                // Filter on selected Users
                modalData.data.filter((value: IUser) => modalData.selection!.includes(value.id)),
                // Filter on selected Users that are not in props.selectedUsersIds
                props.selectUsers && modalData.data.filter((value: IUser) =>
                    modalData.selection!.includes(value.id) &&
                    !props.selectUsers!.includes(value.id)
                ),
                // Search for initialy selected Users that are no longer selected
                props.selectUsers?.flatMap((id: number) =>
                    // If User ID is not in modalData selection
                    !modalData.selection?.some(value => value === id) ?
                        // User not selected, retrieve user data in modalData data
                        modalData.data.find(value => value.id === id)!
                        // User is still selected, do not add user to unselected Users list
                        : []
                ),
            );
        // Reset modal data
        setModalData(initialModalData.current);
        // Submission also close modal
        dispatch(closeModal());
    }, [props, activeModalState.params, modalData.data, modalData.selection, dispatch]);

    // useEffect when component is mounting
    useTimeout(() => {
        // Only request users when modal is opened
        if (modalOpened) {
            // Pagination & filter params
            const paramsToGive = {
                name: null === modalData.filter || '' === modalData.filter ? undefined : modalData.filter,
                page: modalData.page,
                itemsPerPage: itemsPerPage,
            };

            // Call given prop callback with generated params
            props.getUsers && props.getUsers(paramsToGive)
                .then((response: IPaginatorResponse<IUser>|null) =>
                    // Update modal selection data
                    setModalData(modalData => ({
                        ...modalData,
                        data: response?.data ?
                            props.excludeUsers ?
                            // Do not show User if it's in the list of Users to not display
                                response.data.flatMap(user =>
                                    props.excludeUsers!.includes(user.id) ?
                                        []
                                        : user
                                )
                                : response.data
                            : [],
                        pagination: response?.count ? Math.ceil(response.count / itemsPerPage) : null,
                    }))
            );
        }
    }, 1000, [modalOpened, modalData.filter, modalData.page, props]);

    // useEffect whenever modal filter or page values changes
    React.useEffect(() => {
        // Reset modal data
        setModalData(modalData => ({ ...modalData, data: [] }));
    }, [modalData.filter, modalData.page]);

    // useEffect when props.selectedUsers value changes
    React.useEffect(() => {
        // Update modal selection data
        setModalData((modalData) => ({ ...modalData, selection: props.selectUsers ?? null }));
    }, [props.selectUsers]);

    return (
        <GenericModal
            name={modalName.current}
            title='Sélectionnez les utilisateurs'
            onClose={() => setModalData(initialModalData.current)}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <TextField
                    variant='standard'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    placeholder='Filtrer par nom ...'
                    value={modalData.filter}
                    sx={cssStyles.searchInput}
                    onChange={(event) => setModalData({ ...modalData, filter: event.currentTarget.value })}
                />
                {
                    null !== modalData.pagination && modalData.pagination > 1 && (
                        <Pagination
                            shape='rounded'
                            size='small'
                            boundaryCount={2}
                            count={modalData.pagination}
                            page={modalData.page}
                            onChange={(_, page: number) => setModalData({ ...modalData, page: page })}
                        />
                    )
                }
            </Stack>
            <Box component={ImageList} rowHeight={imageHeight} minHeight={488} marginY={3} cols={4} gap={6} alignContent='start'>
                {
                    null === modalData.data || 0 === modalData.data.length ? (
                        [...Array(itemsPerPage)].map((_, index: number) => (
                            <Skeleton key={index} variant='rectangular' height={imageHeight} />
                        ))
                    ) : modalData.data.map((user: IUser) => {
                        // User is selected or not
                        const userSelected = null !== modalData.selection && modalData.selection.some((value: number) => value === user.id);

                        return (
                            <ImageListItem
                                key={user.id}
                                onClick={() => setModalData({
                                    ...modalData,
                                    selection: userSelected ?
                                        // Remove user from selected
                                        modalData.selection!.filter((value: number) => value !== user.id)
                                        // Add user to selected
                                        : props.singleSelect ?
                                            // If modal is single select, set user as selected
                                            [user.id]
                                            // Else, add user to existing selected users
                                            : [ ...modalData.selection ?? [], user.id ],
                                })}
                                sx={{
                                    ...cssStyles.imageListItem,
                                    ...(userSelected && cssStyles.imageListItemSelected),
                                } as SxProps<Theme>}
                            >
                                {
                                    user.avatar ? (
                                        <Box>
                                            <ImageWithLoad
                                                imageSrc={user.avatar ?? undefined}
                                                height={imageHeight}
                                                width={1}
                                                sx={{ objectFit: 'cover' }}
                                            />
                                            <ImageListItemBar
                                                title={`${user.name} ${user.lastname}`}
                                                sx={cssStyles.imageListItemBar}
                                            />
                                        </Box>
                                    ) : (
                                        <Paper component={Stack} height={1} justifyContent='center' alignItems='center' square>
                                            <Typography variant='h6' align='center'>
                                                {`${user.name} ${user.lastname}`}
                                            </Typography>
                                        </Paper>
                                    )
                                }
                            </ImageListItem>
                        )
                    })
                }
            </Box>
            <Stack direction='row' justifyContent='flex-end'>
                <Button
                    disabled={null === modalData.selection}
                    variant='outlined'
                    color='inherit'
                    onClick={handleModalValidation}
                >
                    {`Valider`}
                </Button>
            </Stack>
        </GenericModal>
    );
};

export default ModalSelectUsers;
