import React from 'react';
import moment from 'moment-timezone';
import {
    Stack,
    StackProps,
    Tooltip,
    Typography,
} from '@mui/material';
import EvolutionChip from './EvolutionChip';
import IPostRanking from '../../models/IPostRanking';

const RankingRowData: React.FC<{ ranking: IPostRanking|null }&StackProps> = ({ ranking, ...stackProps }) => {
    return (
        <Stack direction='row' alignItems='center' columnGap={2} {...stackProps}>
            {
                ranking ? (
                    <>
                        <Tooltip title={`Le ${moment(ranking.updatedAt ?? ranking.createdAt).format('DD/MM/Y à H:mm')}`}>
                            <Typography fontWeight={700}>
                                {ranking.rank ?? '101+'}
                            </Typography>
                        </Tooltip>
                        <EvolutionChip evolution={ranking.evolution} />
                    </>
                ) : (
                    <>
                        <Typography fontWeight={700}>
                            {`-`}
                        </Typography>
                        <EvolutionChip evolution={0} />
                    </>
                )
            }
        </Stack>
    );
};

export default RankingRowData;
