import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import ProjectAPIs from '../../APIs/ProjectAPIs';
import Tools from '../../helpers/Tools';
import {
    Paper,
    Stack,
    Typography,
    IconButton,
    FormHelperText,
    Backdrop,
    CircularProgress,
    Button
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import FinalUpperLabel from '../FinalComponents/FinalUpperLabel';
import Keywords from './Keywords';
import ModalImportCsv from '../Modal/ModalImportCsv';
import ModalSingleInput from '../Modal/ModalSingleInput';
import PublishData from './PublishData';
import { CategoryScheduleType } from './CategoriesSchedule';
import IKeyword from '../../models/IKeyword';
import IProjectKeyword from '../../models/IProjectKeyword';
import ICsvData from '../../interfaces/ICsvData';

const Categories: React.FC<{
    name: string,
    categories: Array<string>,
    projectId?: number|null|undefined,
}> = props => {
    // Use of hooks
    const [loading, setLoading] = React.useState<boolean>(false);
    const [keywords, setKeywords] = React.useState<Array<IKeyword>|null>(null);
    const mounted = React.useRef<boolean>(true);
    const categoriesRef = React.useRef<Array<string>>(props.categories);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Callback to handle add Keyword to project
    const handleAddProjectKeyword = React.useCallback((keyword: string, formFieldName: string, pushElement: (name: string, value: CategoryScheduleType['keywordTitles'][0]) => void) => {
        // Call API to create a new ProjectKeyword resource
        ProjectAPIs.postMultipleProjectKeyword([{ project: props.projectId, keywords: [keyword], user: instanceUserId }])
            .then((data) => {
                // Add new Keyword to state
                if (true === mounted.current && null !== data) {
                    const createdKeyword = data[0].keyword;
                    // Adding new keyword to state if it doesn't exist
                    !keywords?.some(keyword => keyword.id === createdKeyword.id) && setKeywords([...keywords ?? [], createdKeyword]);
                    // Add new Keyword to view
                    pushElement(formFieldName, { keyword: { value: String(createdKeyword.id), label: createdKeyword.name }, title: '', imagesSearchTerm: '' })
                }
            });
    }, [props.projectId, instanceUserId, keywords]);

    // Callback to import keywords in a single category
    const handleCsvImportKeyword = React.useCallback((csvData: ICsvData, formFieldName: string, pushElement: (name: string, value: CategoryScheduleType['keywordTitles'][0]) => void) => {
        setLoading(true);
        // Call API to create ProjectKeyword resources with CSV data
        ProjectAPIs.postMultipleProjectKeyword([{ project: props.projectId, keywords: csvData.map(data => data.keyword), user: instanceUserId }])
            .then((data) => {
                // Add new Keywords to form state
                if (true === mounted.current && null !== data) {
                    data.forEach(createdProjectKeyword => {
                        const createdKeyword = createdProjectKeyword.keyword;
                        // Adding new keyword to state if it doesn't exist
                        !keywords?.some(keyword => keyword.id === createdKeyword.id) && setKeywords([...keywords ?? [], createdKeyword]);
                        // Find linked title in CSV data
                        const csvLine = csvData.find(line => (line.keyword as string).toLowerCase() === createdKeyword.name.toLowerCase())!;
                        // Add new Keyword to view
                        setTimeout(() => pushElement(formFieldName, { 
                            keyword: { value: String(createdKeyword.id), label: createdKeyword.name }, 
                            title: csvLine.title as string, 
                            imagesSearchTerm: csvLine.imagesSearchTerm as string ,
                        }));
                    });
                }
            })
            .finally(() => setLoading(false));
    }, [instanceUserId, keywords, props.projectId]);

    // Callback to import keywords in categories
    const handleCsvImportCategorieKeywords = React.useCallback((csvData: ICsvData, pushElement: (name: string, value: CategoryScheduleType['keywordTitles'][0]) => void) => {
        setLoading(true);
        // Remove unknown categories from CSV data
        csvData = csvData.filter(data => Tools.safeStringInclude(props.categories, data.category as string));
        // Call API to create ProjectKeyword resources with CSV data
        ProjectAPIs.postMultipleProjectKeyword([{ project: props.projectId, keywords: csvData.map(data => data.keyword), user: instanceUserId }])
            .then((data) => {
                // Add new Keywords to form state
                if (true === mounted.current && null !== data) {
                    data.forEach(createdProjectKeyword => {
                        const createdKeyword = createdProjectKeyword.keyword;
                        // Adding new keyword to state if it doesn't exist
                        !keywords?.some(keyword => keyword.id === createdKeyword.id) && setKeywords([...keywords ?? [], createdKeyword]);
                        // Find linked title in CSV data
                        const csvLine = csvData.find(line => Tools.safeStringCompare((line.keyword as string), createdKeyword.name))!;
                        // Retrieve category index in the list of categories
                        const categoryId = props.categories.findIndex(category => Tools.safeStringCompare((csvLine.category as string), category));
                        // Generate final-form-array field name
                        const fieldName = `categories[${categoryId}].keywordTitles`;
                        // Add new Keyword to view
                        setTimeout(() => pushElement(fieldName, {
                            keyword: { value: String(createdKeyword.id), label: createdKeyword.name },
                            title: csvLine.title as string,
                            imagesSearchTerm: csvLine.imagesSearchTerm as string ,
                        }));
                    });
                }
            })
            .finally(() => setLoading(false));
    }, [instanceUserId, keywords, props.categories, props.projectId]);

    // useEffect whenever the projectId changes
    React.useEffect(() => {
        // Reset keywords state
        setKeywords(null);
        // If projectId is defined, search for related keywords
        props.projectId && ProjectAPIs.getProjectKeyword({ project: props.projectId })
            .then((data: Array<IProjectKeyword>|null) =>
                null !== data && setKeywords(data.map(projectKeyword => projectKeyword.keyword))
            );
    }, [props.projectId]);

    // useEffect when component is unmounted
    React.useEffect(() => () => {
        mounted.current = false;
    }, []);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <FormSpy
                subscription={{}}
                render={({ form: { mutators: reactFinalFormArrays } }) => (
                    <>
                        {
                            props.projectId && (
                                <>
                                    <ModalSingleInput
                                        name='addKeywordModal'
                                        modalTitle='Ajouter un mot-clé'
                                        inputLabel='Intitulé du mot-clé'
                                        onValidation={(value, formFieldName: string) => handleAddProjectKeyword(value, formFieldName, reactFinalFormArrays.push)}
                                    />
                                    <ModalImportCsv
                                        name='importCsvKeywords'
                                        modalTitle='Importer des mots-clés'
                                        expectedHeaders={[{ label: 'Mot-clé', value: 'keyword' }, { label: 'Titre', value: 'title' }, { label: "Terme de recherche d'images", value: 'imagesSearchTerm' }]}
                                        dataValidation={{ keyword: { required: true, type: 'string' }}}
                                        onValidation={(data, formFieldName) => handleCsvImportKeyword(data, formFieldName, reactFinalFormArrays.push)}
                                    />
                                    <ModalImportCsv
                                        name='importCsvCategoriesKeywords'
                                        modalTitle='Importer les mots-clés des catégories WordPress'
                                        expectedHeaders={[{ label: 'Catégorie WordPress', value: 'category' }, { label: 'Mot-clé', value: 'keyword' }, { label: 'Titre', value: 'title' }, { label: "Terme de recherche d'images", value: 'imagesSearchTerm' }]}
                                        dataValidation={{ category: { required: true, type: 'string' }, keyword: { required: true, type: 'string' }}}
                                        onValidation={(data) => handleCsvImportCategorieKeywords(data, reactFinalFormArrays.push)}
                                    />
                                </>
                            )
                        }
                        <ModalSingleInput
                            name='modalAddMultipleKeywords'
                            modalTitle='Ajouter des mots-clés'
                            inputLabel='Nombre de mots-clés à ajouter'
                            valueType='number'
                            onValidation={(value, keywordFieldArrayName: string) => {
                                for (let i = 0; i < Number(value); i++) {
                                    // Add new element to fieldArray
                                    reactFinalFormArrays.push(keywordFieldArrayName, null)
                                }
                            }}
                        />
                    </>
                )}
            />
            <Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' marginBottom={2}>
                    <FinalUpperLabel name={props.name} label='Liste des catégories du site web' />
                    <Button 
                        variant='outlined'
                        disabled={!props.projectId}
                        onClick={() => dispatch(openModal({ name: 'importCsvCategoriesKeywords' }))}
                    >
                        {`Importer les catégories et mots-clés`}
                    </Button>
                </Stack>
                <Stack gap={1}>
                    <FieldArray
                        name={props.name}
                        subscription={{}}
                        render={({ fields }) => fields.map((name, index) => (
                            // For each categories
                            <Paper key={name} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                                <Stack direction='row' alignItems='center' width={1}>
                                    <Typography variant='h5' gutterBottom>
                                        {categoriesRef.current[index]}
                                    </Typography>
                                    <IconButton
                                        color='error'
                                        onClick={() => {
                                            // Remove field from FieldArray
                                            fields.remove(index);
                                            // Remove category from state (so title is updated)
                                            categoriesRef.current = categoriesRef.current.filter((_, i) => i !== index);
                                        }}
                                        sx={{ marginLeft: 'auto' }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Stack>
                                <PublishData parentName={name} />
                                <Keywords
                                    parentName={name}
                                    keywords={keywords}
                                />
                            </Paper>
                        ))}
                    />
                </Stack>
                <Field
                    name={props.name}
                    subscription={{ error: true, touched: true }}
                    render={({ meta: { error, touched } }) => (
                        touched && 'string' === typeof error ? (
                            <FormHelperText error>{error}</FormHelperText>
                        ) : null
                    )}
                />
            </Stack>
        </>
    );
};

export default Categories;
