import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { IInstanceState, instanceIsGranted, instanceIsScoped } from '../../store/slices/instanceSlice';
import { Container } from '@mui/material';
import InlineNavigation from '../InlineNavigation/InlineNavigation';
import Spinner from '../Spinner/Spinner';
import EUserRoles from '../../interfaces/EUserRoles';

const Route: React.FC<{
    element: JSX.Element,
    contained?: boolean|undefined,
    withNavigation?: boolean|undefined,
    logged?: boolean|undefined,
    allowScopes?: Array<string>|undefined,
}> = props => {
    // Use of hook
    const [routeReady, isRouteReady] = React.useState<boolean>(false);

    // Use of redux
    const instanceState: IInstanceState = useAppSelector(state => state.instance);
    const instanceIsDev: boolean = useAppSelector(instanceIsGranted(EUserRoles.DEV));
    const instanceCanAccessRoute: boolean = useAppSelector(instanceIsScoped(props.allowScopes));

    // Use of react-router-dom
    const { pathname } = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        if ('/' === pathname) {
            // Case 1, empty path or not found pathname
            // Redirect either to dashboard or sign in
            navigate(instanceState.isLogged ? '/dashboard' : '/sign_in', { replace :true });
        } else {
            if (true === props.logged && !instanceState.isLogged) {
                // Case 2, route is accessible when unlogged and user is not logged
                // Redirect to sign in
                navigate('/sign_in', { replace : true });
            } else if (false === props.logged && instanceState.isLogged) {
                // Case 3, route is accessible when unlogged and user is logged
                // Redirect to dashboard
                navigate('/dashboard', { replace : true });
            } else if (false === instanceIsDev && instanceState.isLogged && props.allowScopes) {
                // Case 4, route needs current logged user to have specific scopes on account to be accessible
                instanceCanAccessRoute ?
                    // Set route as ready
                    isRouteReady(true)
                    // Redirect to dashboard
                    : navigate('/dashboard', { replace : true });
            } else {
                // Case 5, route conditions are met
                // Set route as ready
                isRouteReady(true);
            }
        }
    }, [pathname, props, navigate, instanceState, instanceIsDev, instanceCanAccessRoute]);

    // Define content that will be rendered in any cases
    const Content: JSX.Element = (
        <>
            {/** Show or hide InlineNavigation component */}
            {props.withNavigation && <InlineNavigation />}
            {/** Render given element/component */}
            {props.element}
        </>
    );

    return (
        true === routeReady ? (
            props.contained ? (
                <Container sx={{ paddingX: 4, paddingTop: 3, paddingBottom: 6, maxWidth: { xs: 1400 } }}>
                    {Content}
                </Container>
            ) : Content
        ) : (<Spinner isFullscreen />)
    );
};

export default Route;
