import React from 'react';
import { FormSpy } from 'react-final-form';
import { useAppSelector } from '../../store/hooks';
import { appMenuWidthClose, appMenuWidthOpen } from '../../configs/materialUI';
import {
    useTheme,
    SxProps,
    Theme,
    Button,
    Box,
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
    ArrowCircleDown as ArrowCircleDownIcon,
    CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import makeCssStyles from './FinalStepperFormStyles';

const StepperControls: React.FC<{
    isLastStep: boolean,
    activeStep: number,
    stepName: string,
    stepHasValidationSchema: boolean|undefined,
    onAction: (action: 'inc'|'desc', form: any) => void,
    awareOfDashboardMenu?: boolean|undefined,
    nextStepText?: string|undefined,
    previousStepText?: string|undefined,
    validationText?: string|undefined,
}> = props => {
    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme);

    // Use of redux
    const menuIsOpened: boolean = useAppSelector(state => state.dashboardMenu.open);

    return (
        <Box sx={{
            ...cssStyles.stepperControlsContainer,
            ...(props.awareOfDashboardMenu && {
                ...cssStyles.awareDashboardMenu,
                paddingLeft: menuIsOpened ? appMenuWidthOpen : appMenuWidthClose,
            }),
        } as SxProps<Theme>}>
            <Stack sx={{
                ...cssStyles.stepperControls,
                justifyContent: 0 === props.activeStep ? 'flex-end' : 'space-between',
            }}>
                <Typography sx={cssStyles.currentStep} color='primary'>
                    {`${props.activeStep + 1} - `}
                    <Typography component='span' color='text.primary'>
                        {props.stepName}
                    </Typography>
                </Typography>
                <FormSpy
                    subscription={{ pristine: true, valid: true, submitting: true,  }}
                    render={({ pristine, valid, submitting, form }) => (
                        <>
                            {
                                props.activeStep > 0 && (
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        disabled={submitting}
                                        startIcon={<ArrowCircleDownIcon sx={{ transform: 'rotate(90deg)' }} />}
                                        onClick={() => props.onAction('desc', form)}
                                        sx={cssStyles.controlButton}
                                    >
                                        {props.previousStepText ?? `Étape précédente`}
                                    </Button>
                                )
                            }
                            <LoadingButton
                                variant={props.isLastStep ? 'contained' : 'outlined'}
                                color={props.isLastStep ? 'primary' : undefined}
                                disabled={props.stepHasValidationSchema && (pristine || !valid)}
                                loading={submitting}
                                endIcon={
                                    props.isLastStep ?
                                        <CheckCircleIcon />
                                        : <ArrowCircleDownIcon sx={{ transform: 'rotate(-90deg)' }} />
                                }
                                onClick={() => props.onAction('inc', form)}
                                sx={cssStyles.controlButton}
                            >
                                {props.isLastStep ? props.validationText ?? `Valider` : props.nextStepText ?? `Étape suivante`}
                            </LoadingButton>
                        </>
                    )}
                />
            </Stack>
        </Box>
    );
};

export default StepperControls;
