import React from 'react';
import {
    Card,
    CardMedia,
    Skeleton,
    Typography,
    CardActions,
    Button
} from '@mui/material';

const DisplayCard: React.FC<{
    title: string,
    width?: number|undefined,
    image?: string|undefined,
    elevation?: number|undefined,
    onDelete?: (() => void)|undefined,
}> = props => {
    return (
        <Card elevation={props.elevation} sx={{ width: props.width ?? 200 }}>
            {
                props.image ? (
                    <CardMedia height={100} component='img' image={props.image} />
                ) : (
                    <Skeleton variant='rectangular' height={100} animation={false} />
                )
            }
            <Typography variant='h6' align='center' paddingY={1}>
                {props.title}
            </Typography>
            {
                props.onDelete && (
                    <CardActions sx={{ justifyContent: 'center', paddingTop: 0 }}>
                        <Button size='small' onClick={props.onDelete}>
                            {`Supprimer`}
                        </Button>
                    </CardActions>
                )
            }
        </Card>
    );
};

export default DisplayCard;
