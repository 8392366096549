import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgCheckDuplication: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#CDD6E0' d='M366.4,221.867H255.467c-7.467,0-13.867-6.4-13.867-13.867v-14.933h-14.933V208	c0,16,12.8,28.8,28.8,28.8h109.867c7.467,0,13.867,6.4,13.867,13.867c0,7.467-6.4,13.867-13.867,13.867h-185.6c-16,0-28.8,12.8-28.8,28.8v5.333h14.933v-5.333c0-7.467,6.4-13.867,13.867-13.867H366.4c16,0,28.8-12.8,28.8-28.8S382.4,221.867,366.4,221.867z' />
            <path fill='#4CDBC4' d='M491.2,513.067H21.867c-11.733,0-21.333-9.6-21.333-21.333v-179.2c0-11.733,9.6-21.333,21.333-21.333H491.2c11.733,0,21.333,9.6,21.333,21.333v179.2C512.533,503.467,502.933,513.067,491.2,513.067z' />
            <g>
                <rect x='21.867' y='312.53' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='81.6' y='312.53' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='141.33' y='312.53' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='202.13' y='312.53' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='261.87' y='312.53' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='322.67' y='312.53' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='382.4' y='312.53' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='442.13' y='312.53' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='81.6' y='442.67' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='382.4' y='442.67' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='21.867' y='377.6' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='81.6' y='377.6' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='141.33' y='377.6' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='202.13' y='377.6' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='261.87' y='377.6' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='322.67' y='377.6' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='382.4' y='377.6' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='442.13' y='377.6' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='21.867' y='442.67' fill='#F2F2F2' width='49.07' height='49.07' />
                <rect x='141.33' y='442.67' fill='#F2F2F2' width='229.33' height='49.07' />
                <rect x='442.13' y='442.67' fill='#F2F2F2' width='49.07' height='49.07' />
            </g>
            <circle fill='#54C0EB' cx='235.2' cy='102.4' r='101.33' />
            <g>
                <path fill='#84DBFF' d='M336.533,102.4c-2.133,3.2-4.267,5.333-6.4,8.533c-7.467,11.733-10.667,25.6-14.933,39.467c-1.067,3.2-4.267,9.6-7.467,7.467c-9.6-7.467,0-26.667-7.467-38.4c-2.133-4.267-5.333-7.467-9.6-9.6c-8.533-4.267-18.133,0-26.667-3.2c-7.467-2.133-12.8-10.667-12.8-18.133c0-8.533,4.267-16,9.6-21.333c5.333-5.333,13.867-7.467,20.267-5.333c2.133,1.067,4.267,3.2,7.467,4.267c7.467,2.133,13.867-1.067,21.333-4.267c3.2-1.067,6.4-3.2,5.333-6.4c-1.067-2.133-3.2-3.2-5.333-3.2c-4.267,0-8.533,1.067-12.8,1.067c-4.267,1.067-9.6,0-12.8-3.2c-2.133-2.133-4.267-4.267-6.4-4.267c-1.067,0-2.133,1.067-2.133,2.133c-4.267,4.267-10.667,4.267-16,3.2c-1.067,0-2.133,0-2.133-1.067c-1.067-1.067,0-3.2,1.067-5.333c3.2-4.267,7.467-7.467,9.6-12.8l0,0c3.2-6.4-1.067-13.867-8.533-14.933c-4.267,0-7.467-1.067-10.667-4.267S243.733,4.267,244.8,0C296,6.4,336.533,49.067,336.533,102.4z' />
                <path fill='#84DBFF' d='M212.8,30.933c-3.2,8.533-12.8,11.733-19.2,18.133c-4.267,4.267-7.467,9.6-10.667,14.933l-1.067,2.133c-3.2,6.4-10.667,8.533-18.133,6.4c-1.067,0-2.133-1.067-3.2-1.067c-5.333,0-11.733,4.267-11.733,9.6c0,1.067,0,2.133,0,4.267c1.067,2.133,4.267,4.267,6.4,4.267l0,0c4.267,0,8.533,2.133,10.667,7.467l0,0c2.133,5.333-4.267,9.6-9.6,6.4c-6.4-4.267-10.667-11.733-12.8-18.133c-2.133-6.4-3.2-11.733-3.2-18.133l0,0C152,36.267,177.6,13.867,209.6,5.333c1.067,2.133,2.133,5.333,3.2,7.467C213.867,18.133,214.933,24.533,212.8,30.933z' />
                <path fill='#84DBFF' d='M240.533,136.533c-2.133,2.133-4.267,4.267-6.4,6.4c-10.667,11.733-9.6,33.067-22.4,42.667c-2.133,2.133-5.333,3.2-5.333,6.4c0,1.067,0,2.133,0,3.2c0,2.133,1.067,3.2,1.067,5.333c-4.267-1.067-8.533-2.133-11.733-4.267c-2.133-4.267-5.333-8.533-6.4-12.8c-1.067-6.4,1.067-13.867,0-20.267c-3.2-11.733-16-17.067-20.267-27.733c-2.133-7.467,0-14.933,3.2-22.4c2.133-4.267,5.333-8.533,9.6-9.6c3.2-1.067,7.467-1.067,10.667,1.067c13.867,4.267,23.467,19.2,38.4,20.267c4.267,0,9.6,0,11.733,4.267C242.667,130.133,241.6,134.4,240.533,136.533z' />
            </g>
        </SvgIcon>
    );
};

export default SvgCheckDuplication;
