import React from 'react';
import moment from 'moment-timezone';
import { Document, Text, Font, Link } from '@react-pdf/renderer';
import Tools from '../../helpers/Tools';
import Page from '../elements/Page';
import GothicNormal from '../../fonts/Gothic/Gothic-Normal.ttf';
import GothicLight from '../../fonts/Gothic/Gothic-Light.ttf';
import GothicSemiBold from '../../fonts/Gothic/Gothic-SemiBold.ttf';
import GothicBold from '../../fonts/Gothic/Gothic-Bold.ttf';
import IOrder from '../../models/IOrder';
import IUser from '../../models/IUser';
import ICompany from '../../models/ICompany';
import Title1 from '../elements/Title1';
import Bold from '../elements/Bold';
import Title2 from '../elements/Title2';

const Contract: React.FC<{
    order: IOrder,
    user: IUser,
    company: ICompany,
    criterias: Array<string>,
    commission: number,
}> = props => {
    // Register font
    Font.register({ family: 'Gothic', fonts: [
        { src: GothicNormal },
        { src: GothicLight, fontWeight: 'light' },
        { src: GothicSemiBold, fontWeight: 700 },
        { src: GothicBold, fontWeight: 900 },
    ]});

    const date = moment().format('DD/MM/Y à H:mm');
    const missionNumber = Tools.getIdentifierFromUuid(props.order.publicId);

    return (
        <Document>
            <Page style={{ fontFamily: 'Gothic', fontSize: 10 }}>
                <Text style={{ fontWeight: 900, fontSize: 21 }}>
                    {`Contrat de prestation de services`}
                </Text>
                <Title1 text={`Mission N°${missionNumber}`} />
                <Title1 text='Entre les soussignés :' style={{ textTransform: 'uppercase', paddingTop: 0 }} />
                <Text>
                    <Bold text='La société : ' />
                    {props.company.name}
                </Text>
                <Text>
                    <Bold text='Immatriculée sous le numéro : ' />
                    {props.company.siret}
                </Text>
                <Text>
                    <Bold text='Auto-entrepreneur' />
                    {`, situé(e) au : `}
                    <Bold text={`${props.company.address} ${props.company.postalCode} ${props.company.city}`} />
                    {` Représentée par `}
                    <Bold text={`${props.user.civility} ${props.user.name} ${props.user.lastname}`} />
                </Text>
                <Text>
                    {`ci-après dénommée 'Le Rédacteur'`}
                </Text>
                <Title1 text="d'une part, et" style={{ textTransform: 'uppercase' }} />
                <Text>
                    {`La société : READY UP, SARL au capital de 1000,00€, dont le siège social est : 10 RUE ALBERT FERRASSE 47550 BOÉ`}
                </Text>
                <Text>
                    {`SIRET n°90530702100014`}
                </Text>
                <Text>
                    {`ci-après dénommée '${process.env.REACT_APP_NAME}.fr'`}
                </Text>
                <Title1 text="d'autre part" style={{ textTransform: 'uppercase' }} />
                <Title1 text='Après avoir préalablement expose que :' style={{ textTransform: 'uppercase', paddingTop: 0 }} />
                <Text>
                    {`${process.env.REACT_APP_NAME}.fr s'est engagé vis-à-vis de son client final, Le Donneur d'Ordres, à lui fournir une prestation de : `}
                    <Bold text='Rédaction' />
                    {`. ${process.env.REACT_APP_NAME}.fr par le biais de sa plateforme '${process.env.REACT_APP_NAME}.fr' recherche à sous-traiter cette prestation. Le Rédacteur qui dispose des compétences et des moyens nécessaires à la réalisation de cette prestation, a proposé ses services et a souhaité percevoir, à ce titre, une rémunération. Les parties se sont donc rapprochées, afin d'arrêter les conditions et modalités de leurs accords.`}
                </Text>
                <Title1 text='Ceci exposé,' />
                <Title1 text='il a été convenu ce qui suit :' style={{ paddingTop: 0 }} />


                <Title1 text='Article 1 - Objet' style={{ textTransform: 'uppercase' }} />
                <Title2 text='1.1 - Contrat' />
                <Text>
                    {`Le présent contrat est un contrat de prestation de services réalisé au docimile ou sur le lieu de travail habituel du Rédacteur et ayant pour objet la réalisation d'une mission de `}
                    <Bold text='Rédaction.' />
                </Text>
                <Text>
                    {`- N° de la mission : ${missionNumber}`}
                </Text>
                <Text>
                    {`- Nom de la mission : Article sur ${props.order.keyword.name}`}
                </Text>
                <Text>
                    {`- Date de commencement : ${date}`}
                </Text>
                <Text>
                    {`- Donneur d'Ordres : Web Conseil Expertise`}
                </Text>
                <Text>
                    {`La mission réalisée et correspondant  aux critères définis à l'article 2, donnera lieu à une rémunération de : `}
                    <Bold text={`${props.order.price} € HT.`} />
                </Text>
                <Text>
                    {`Comme convenu dans les conditions générales d'utilisation, le prix après commission est de : `}
                    <Bold text={`${(Number(props.order.price) * (1 - (props.commission / 100))).toFixed(2)} € HT.`} />
                </Text>
                <Text>
                    {`Le Rédacteur, s'il le souhaite, aura la possibilité à tout moment de changer d'abonnement afin d'obtenir la rémunération la plus avantageuse.`}
                </Text>
                <Text>
                    {`Une fois la mission terminée, le Rédacteur pourra demander le paiement de cette dernière. Ce paiement sera réalisé dans un délai de 8 jours maximum après la demande.`}
                </Text>
                <Text>
                    {`Les frais pouvant être engagés à la réalisation de cette mission sont à la charge du Rédacteur. Ils seront donc déduis par READY UP du montant dû conformément aux Conditions Générales d’Utilisation.`}
                </Text>
                <Text>
                    {`(Détail des tarifs : `}
                    <Link src={`https://${process.env.REACT_APP_NAME}.fr/tarifs`}>
                        {`https://${process.env.REACT_APP_NAME}.fr/tarifs`}
                    </Link>
                    {`).`}
                </Text>


                <Title1 text='Article 2 - Objectif de la mission' style={{ textTransform: 'uppercase' }} />
                <Title2 text='2.1 - Définition de la mission' style={{ paddingTop: 0 }} />
                <Text>
                    {`Rédaction d’un article`}
                </Text>
                <Title2 text='2.2 - Objectif de la mission' />
                <Text>
                    {`Respecter tous les critères demandés`}
                </Text>
                <Title2 text='2.3 - Critères demandés' />
                <Text>
                    {`Pour qu'une mission soit validée et donc payée, elle doit correspondre rigoureusement au(x) critère(s) suivant(s) :`}
                </Text>
                {props.criterias.map((criteria, index) => (<Bold key={index} text={criteria} />))}
                <Title2 text='2.4 - Validation de la mission' />
                <Text>
                    {`Toute mission effectuée par le Rédacteur devra être contrôlée par le Donneur d’Ordres et faire l’objet d’une validation (ou d'un refus le cas échéant). ${process.env.REACT_APP_NAME}.fr fixe un délai de 24 heures ouvrables à compter de la réalisation de la mission, pour permettre au Donneur d’Ordres de procéder à sa validation. Passé ce délai, sans observation du Donneur d’Ordres, l’étape sera réputée validée sans réserve. Si le Donneur d’Ordre souhaite émettre des réserves sur une mission, elles devront être signifiées via la plateforme au Rédacteur qui pourra modifier son travail afin de compléter ou affiner cette mission. Si les réserves du Donneur d’Ordres correspondent à des besoins non décrits dans les critères de la mission définis ci-dessus, le Donneur d’Ordres ne pourra pas refuser la mission. Le Rédacteur pourra donc le cas échéant déclencher une procédure d’arbitrage telle que définie dans les Conditions Générales d’Utilisations.`}
                </Text>
                <Text>
                    {`À partir du 3ème refus de l'article, le Donneur d'Ordres est en droit mettre fin au contrat sans indemnité.`}
                </Text>
                {
                    true === props.order.isFirstServed && (
                        <>
                            <Title2 text='2.5 - Délais de la mission' />
                            <Text>
                                {`Le Rédacteur s'engage à réaliser cette mission en : `}
                                <Bold text='24h' />
                            </Text>
                        </>
                    )
                }


                <Title1 text='Article 3 - Durée du contrat' style={{ textTransform: 'uppercase' }} />
                <Text>
                    {`Le présent contrat prend effet à compter de l’acceptation de la mission par le Rédacteur. Il prendra fin lors de la clôture de cette dernière.`}
                </Text>
                {
                    true === props.order.isFirstServed && (
                        <>
                            <Title2 text='3.1 - Clôture de la mission' />
                            <Text>
                                {`Lorsque toutes les étapes de la mission seront atteintes et validées, la mission sera clotûrée. Le Rédacteur s’étant engagé à réaliser cette mission en `}
                                <Bold text='24h' />
                                {` à l’issu de ce délai la mission pourra donc à tout moment être clôturée à l’initiative du Donneur d’Ordres. Si le Donneur d’Ordres décide de clôturer la mission avant la fin de ce délai de `}
                                <Bold text='24h' />
                                {` il devra verser une pénalité́ au Rédacteur équivalente à `}
                                <Bold text='100%' />
                                {` de la valeur de la mission.`}
                            </Text>
                        </>
                    )
                }
                <Title2 text={`3.${true === props.order.isFirstServed ? '2' : '1'} - Manquements`} />
                <Text>
                    {`En cas de manquement(s) par l'une des parties à ses obligations, l'autre partie pourra résilier de plein droit le présent contrat, sans préavis et sans formalité judiciaire, et sans préjudice de tous les dommages et intérêts auxquels elle pourrait prétendre.`}
                </Text>
                <Title2 text={`3.${true === props.order.isFirstServed ? '3' : '2'} - Redressement ou liquidation judiciaire`} />
                <Text>
                    {`En cas de redressement ou liquidation judiciaire ou toutes autres procédures similaires, pour l'une ou l'autre des parties, le présent contrat sera résilié de plein droit sous réserve de l’application de l’article L621-28 du code de commerce relatif au redressement et à la liquidation judiciaire des entreprises. Toute partie pourra également mettre fin au présent contrat, sans délai, à compter de la décision des actionnaires, en cas de liquidation conventionnelle (sauf en vue d'une fusion ou d'une réorganisation).`}
                </Text>


                <Title1 text='Article 4 - Obligation de confidentialité' style={{ textTransform: 'uppercase' }} />
                <Text>
                    {`Le Rédacteur considèrera comme strictement confidentiel, et s'interdit de divulguer, toutes informations, documents, données ou concepts, dont il pourra avoir connaissance à l'occasion du présent contrat. Pour l'application de la présente clause, le Rédacteur répond de ses salariés comme de lui-même. Le prestataire, toutefois, ne saurait être tenu pour responsable d'aucune divulgation si les éléments divulgués étaient dans le domaine public à la date de la divulgation, ou s'il en avait connaissance, ou les obtenait de tiers par des moyens légitimes.`}
                </Text>


                <Title1 text='Article 5 - Loi applicable - Attribution de compétence' style={{ textTransform: 'uppercase' }} />
                <Text>
                    {`Le présent contrat est soumis au droit français. Toutes difficultés relatives à l’interprétation, à l’exécution et à l’expiration du présent contrat seront soumises, à défaut d'accord amiable, au Tribunal compétent du ressort de ${process.env.REACT_APP_NAME}.fr, auquel les parties attribuent compétence territoriale et ceci même en cas de référé, d’appel en garantie ou de pluralité de défendeurs.`}
                </Text>
            </Page>
        </Document>
    );
};

export default Contract;
