import React from 'react';
import { useField } from 'react-final-form';
import { Grid } from '@mui/material';
import TemplateImage from './TemplateImage';

// Templates information
export const wordpressTemplates = [
    { label: 'Mugu', value: 'mugu', imagePath: 'wpThemes/mugu.png', templateUrl: 'https://rarathemesdemo.com/mugu' },
    { label: 'Pendant', value: 'pendant', imagePath: 'wpThemes/pendant.png', templateUrl: 'https://wp-themes.com/pendant' },
    { label: 'Allele', value: 'allele', imagePath: 'wpThemes/allele.png', templateUrl: 'https://wp-themes.com/allele' },
    { label: 'Bricksy', value: 'bricksy', imagePath: 'wpThemes/bricksy.png', templateUrl: 'https://wp-themes.com/bricksy' },
    { label: 'Ona', value: 'ona', imagePath: 'wpThemes/ona.png', templateUrl: 'https://wp-themes.com/ona' },
];

const ChooseTemplate: React.FC = () => {
    // Use of react-final-form hook
    const { input: { value: selectedTemplate, onChange } } = useField('template');

    return (
        <Grid container spacing={2}>
            {
                wordpressTemplates.map((template) => (
                    <Grid item xs={6} key={template.value}>
                        <TemplateImage
                            selected={template.value === selectedTemplate}
                            imagePath={template.imagePath}
                            templateName={template.label}
                            templateUrl={template.templateUrl}
                            onClick={() => onChange(template.value)}
                        />
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default ChooseTemplate;
