import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Variant } from '@mui/material/styles/createTypography';
import {
    SxProps,
    Theme,
    Link,
} from '@mui/material';

const RouterLink: React.FC<{
    anchor: string,
    to: string,
    state?: Object|undefined,
    target?: string|undefined
    underline?: 'always'|'hover'|'none'|undefined,
    variant?: 'inherit'|Variant|undefined,
    color?: 'inherit'|'initial'|'primary'|'secondary'|'textPrimary'|'textSecondary'|'error'|'link'|undefined,
    sx?: SxProps<Theme>|undefined,
    onClick?: React.MouseEventHandler<any>|undefined,
}> = props => {
    // Use of react-router-dom hooks
    const navigate = useNavigate();

    // Function which handle click
    function handleClick(event: React.MouseEvent<any, MouseEvent>) {
        // Call given callback if it exists
        props.onClick && props.onClick(event);
        // Navigate to given path
        navigate(props.to, { state: props.state });
    };

    // Determine if link should be opened in new tab
    const openInNewTab: boolean = '_blank' === props.target;

    return (
        <Link
            target={props.target}
            rel={true === openInNewTab ? 'noopener noreferrer' : undefined}
            variant={props.variant}
            color={props.color ?? 'link'}
            underline={props.underline}
            href={true === openInNewTab ? props.to : undefined}
            onClick={false === openInNewTab ? event => handleClick(event) : undefined}
            sx={{ cursor: 'pointer', ...props.sx }}
            children={props.anchor}
        />
    );
};

export default RouterLink;