import React from 'react';
import { Field } from 'formik';
import {
    SxProps,
    Theme,
    FormControl,
    FormLabel,
    Slider,
    FormHelperText,
} from '@mui/material';
import IFormikFieldProps from '../../interfaces/IFormikFieldProps';

const FormikSlider: React.FC<{
    name: string,
    label?: string|undefined,
    helperText?: string|undefined,
    size?: 'medium'|'small'|undefined,
    forceError?: boolean|undefined,
    fullWidth?: boolean|undefined,
    disabled?: boolean|undefined,
    marks?: boolean|Array<{ value: number }>|undefined,
    max?: number|undefined,
    min?: number|undefined,
    step?: number|undefined,
    sx?: SxProps<Theme>|undefined,
    onChange?: ((value: number|number[]) => void)|undefined,
}> = props => {
    return (
        <Field name={props.name}>
            {({ field, meta }: IFormikFieldProps) => (
                <FormControl
                    fullWidth={props.fullWidth ?? true}
                    disabled={props.disabled}
                    error={props.forceError ?? Boolean(meta.touched && meta.error)}
                    sx={props.sx}
                >
                    {
                        props.label && (
                            <FormLabel sx={{ fontSize: { xs: 16 } }}>
                                {props.label}
                            </FormLabel>
                        )
                    }
                    <Slider
                        {...field}
                        size={props.size}
                        disabled={props.disabled}
                        marks={props.marks}
                        max={props.max}
                        min={props.min}
                        step={props.step}
                        valueLabelDisplay='auto'
                        onChange={(event, value) => {
                            field.onChange(event);
                            props.onChange && props.onChange(value);
                        }}
                        sx={{ minWidth: 1 }}
                    />
                    {
                        (Boolean(meta.touched && meta.error) || props.helperText) && (
                            <FormHelperText component='span' sx={{ margin: 0 }}>
                                {Boolean(meta.touched && meta.error) ? meta.error : props.helperText}
                            </FormHelperText>
                        )
                    }
                </FormControl>
            )}
        </Field>
    );
};

export default FormikSlider;
