import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import {
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import {
    Menu as MenuIcon,
} from '@mui/icons-material';
import IRowMenuOption from '../../interfaces/IRowMenuOption';
import IRowData from '../../interfaces/IRowData';

interface ICellManagerRendererParams extends ICellRendererParams {
    options: Array<IRowMenuOption>,
};

const ManagerRenderer: React.FC<ICellManagerRendererParams> = props => {
    // Use of hooks
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement|null>(null);
    const rowData = React.useRef<IRowData>({ index: props.rowIndex, data: props.data });
    // Remove options that does no meet the isShown condition if defined
    const options = React.useRef<Array<IRowMenuOption>>(
        props.options.filter((option) =>
            undefined === option.isShown || true === option.isShown(rowData.current)
        )
    );

    // Callback triggered on help icon click
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    // Callback used to close popover menu
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        // Show menu only if there is at least 1 option
        0 < options.current.length ? (
            <>
                <Tooltip title='Ouvrir le menu' placement='top'>
                    <IconButton
                        disableRipple
                        color={anchorEl ? 'secondary' : undefined}
                        sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
                        onClick={handleClick}
                    >
                        <MenuIcon sx={{ fontSize: '1.2rem' }} />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{ sx: { padding: 0 } }}
                >
                    {
                        options.current.map((option: IRowMenuOption, index: number) => (
                            <MenuItem
                                key={index}
                                onClick={() => option.onClick && option.onClick(rowData.current)}
                                sx={{ fontSize: { xs: '0.9rem' }, color: option.color }}
                            >
                                {option.label}
                            </MenuItem>
                        ))
                    }
                </Menu>
            </>
        ) : null
    );
};

export default ManagerRenderer;
