import React from 'react';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import { translationLanguages } from '../../configs/translationLanguages';
import { LoadingButton } from '@mui/lab';
import {
    FormLabel,
    TextField,
    Stack,
    Typography,
    Select,
    MenuItem,
} from '@mui/material';

const TranslationTool: React.FC = () => {
    // Use of hooks
    const [text, setText] = React.useState<string>('');
    const [from, setFrom] = React.useState('auto');
    const [to, setTo] = React.useState('en');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [response, setResponse] = React.useState<any>(null);

    // Function to handle API call
    const handleClickButton = () => {
        // Load during API call
        setLoading(true);
        // Reset previous result
        setResponse(null);
        // Call API to translate our text
        BridgeAPIs.postTextTranslation(text, from, to)
            // On successful API call
            .then((response: any) => {
                if (response) {
                    setLoading(false);
                    setResponse(response.text ?? 'Pas de résultat');
                }
            });
    };

    return (
        <>
            <Stack direction='row' justifyContent='space-between'>
                <FormLabel>
                    {`Texte à traduire`}
                </FormLabel>
                <Typography variant='caption' color='textSecondary'>
                    {`${text.length}/5000`}
                </Typography>
            </Stack>
            <TextField
                fullWidth
                multiline
                rows={10}
                value={text}
                disabled={loading}
                onChange={(event) => setText(event.target.value)}
                sx={{ paddingBottom: 3 }}
            />
            <Stack direction='row' alignItems='center'>
                <Typography>
                    {`Depuis :`}
                </Typography>
                <Select
                    size='small'
                    value={from}
                    onChange={event => setFrom(event.target.value)}
                    sx={{ minWidth: 250, marginX: 3 }}
                >
                    {
                        Object.entries(translationLanguages).map(([key, value], index) => (
                            <MenuItem key={index} value={key}>{value}</MenuItem>
                        ))
                    }
                </Select>
                <Typography>
                    {`Vers :`}
                </Typography>
                <Select
                    size='small'
                    value={to}
                    onChange={event => setTo(event.target.value)}
                    sx={{ minWidth: 250, marginX: 3 }}
                >
                    {
                        Object.entries(translationLanguages).map(([key, value], index) => (
                            'auto' !== key && (
                                <MenuItem key={index} value={key}>{value}</MenuItem>
                            )
                        ))
                    }
                </Select>
            </Stack>
            <LoadingButton
                variant='contained'
                loading={loading}
                disabled={0 === text.length || 5000 < text.length}
                onClick={handleClickButton}
                sx={{ marginY: 2 }}
            >
                {`Traduire le texte`}
            </LoadingButton>
            {
                null !== response && (
                    <Stack gap={2}>
                        <Typography>
                            {`Traduction : `}
                        </Typography>
                        <Typography variant='body2'>
                            {response}
                        </Typography>
                    </Stack>
                )
            }
        </>
    );
};

export default TranslationTool;
