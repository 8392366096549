import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { Button } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import IOrder from '../../models/IOrder';

const FirstServedButton: React.FC<{ order?: IOrder|undefined, disabled?: boolean|undefined }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();

    return (
        <Button
            disabled={props.disabled}
            variant='contained'
            endIcon={<EditTwoToneIcon />}
            onClick={() => dispatch(openModal({ name: 'pickOrderModal', params: props.order }))}
        >
            {`Rédiger cet article`}
        </Button>
    )
};

export default FirstServedButton;
