import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { instanceIsScoped } from '../../store/slices/instanceSlice';
import { createFadeInVariant, createStaggerVariant } from '../../configs/framerMotion';
import Motion from '../FramerMotion/Motion';
import NamespacePanel from '../NamespacePanel/NamespacePanel';
import SvgWriteContent from '../Svg/SvgWriteContent';
import SvgIndexingStatus from '../Svg/SvgIndexingStatus';
import SvgCheckDuplication from '../Svg/SvgCheckDuplication';
import SvgAutomaticGeneration from '../Svg/SvgAutomaticGeneration';
import SvgCreateSite from '../Svg/SvgCreateSite';
import SvgConfigureBacklinks from '../Svg/SvgConfigureBacklinks';
import './ToolsMenuStyles.scss';

const ToolsMenuPage1: React.FC = () => {
    // Use of redux
    const instanceLimited: boolean = useAppSelector(instanceIsScoped(['can_redact_posts_limited']));
    const instanceCanCreateSite: boolean = useAppSelector(instanceIsScoped(['manage_account_websites']));

    return (
        <Motion
            component='div'
            className='toolsMenuPage'
            variants={createStaggerVariant(0.04)}
            initial='hidden'
            animate='visible'
        >
            {
                instanceLimited ? (
                    <>
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            svg={<SvgWriteContent sx={{ fontSize: '6rem' }} />}
                            header='Rédiger du contenu'
                            text='Rédiger du contenu dans un éditeur de texte optimisé.'
                            to='redact_tool'
                        />
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            svg={<SvgAutomaticGeneration sx={{ fontSize: '6rem' }} />}
                            header='Génération automatique'
                            text='Créer un article en quelques secondes en confiant la rédaction à une IA.'
                            to='ai_generation_post'
                        />
                    </>
                ) : (
                    <>
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            svg={<SvgWriteContent sx={{ fontSize: '6rem' }} />}
                            header='Rédiger du contenu'
                            text='Rédiger du contenu dans un éditeur de texte optimisé.'
                            to='redact_tool'
                        />
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            svg={<SvgIndexingStatus sx={{ fontSize: '6rem' }} />}
                            header="Status d'indexation"
                            text="Vérifier l'indexation d'une URL."
                            to='indexing_status'
                        />
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            svg={<SvgCheckDuplication sx={{ fontSize: '6rem' }} />}
                            header="Vérifier la duplication de contenu"
                            text="Vérifier la duplication de contenu d'un texte."
                            to='duplicate_text'
                        />
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            svg={<SvgAutomaticGeneration sx={{ fontSize: '6rem' }} />}
                            header='Génération automatique'
                            text='Créer un article en quelques secondes en confiant la rédaction à une IA.'
                            to='ai_generation_post'
                        />
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            header="Positionnement d'une URL"
                            text="Retrouve le positionnement actuel d'une URL sur un mot-clé spécifique."
                            to='current_ranking'
                        />
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            header='Traduction'
                            text="Traduction d'un texte Français / Anglais"
                            to='traduction_text'
                        />
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            header='Paraphraser un article'
                            text="Reformule l'entièreté d'un texte donné en utilisant une IA."
                            to='paraphrase_text'
                        />
                        {
                            true === instanceCanCreateSite && (
                                <>
                                    <Motion<React.ComponentProps<typeof NamespacePanel>>
                                        component={NamespacePanel}
                                        variants={createFadeInVariant(0.4)}
                                        svg={<SvgConfigureBacklinks sx={{ fontSize: '6rem' }} />}
                                        header='Redirections'
                                        text="Configurer les redirections d'un site."
                                        to='configure_backlinks'
                                    />
                                </>
                            )
                        }
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            header='Générer des titres'
                            text="Générer des titres avec un mot-clé spécifique."
                            to='generate_titles'
                        />
                        <Motion<React.ComponentProps<typeof NamespacePanel>>
                            component={NamespacePanel}
                            variants={createFadeInVariant(0.4)}
                            header='Importer un fichier CSV'
                            text='Importer des sites, mot-clés, articles...'
                            to='csv_import'
                        />
                    </>
                )
            }
        </Motion>
    );
};

export default ToolsMenuPage1;
