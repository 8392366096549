import React from 'react';
import OtherAPIs from '../../APIs/OtherAPIs';
import Tools from '../../helpers/Tools';
import { SxProps, Theme } from '@mui/material';
import FinalAutocomplete from '../FinalComponents/FinalAutocomplete';
import IActivitySector from '../../models/IActivitySector';

const SelectActivitySector: React.FC<{ name: string, sx?: SxProps<Theme>|undefined }> = props => {
    // Use of hooks
    const [activitySectors, setActivitySectors] = React.useState<Array<IActivitySector>|null>(null);
    const mountedRef = React.useRef<boolean>(true);

    // useEffect when component is mounting
    React.useEffect(() => {
        // Call API to fetch activity sectors
        null === activitySectors &&
            OtherAPIs.getActivitySectors()
                // On successful API call change activitySectors hook value
                .then((activitySectors: Array<IActivitySector>) =>
                    // Do not update state if component is unmounted
                    true === mountedRef.current && setActivitySectors(activitySectors)
                );

        return () => {
            // Set mountedRef to false when component is unmounting
            mountedRef.current = false;
        };
    }, [activitySectors]);

    return (
        <FinalAutocomplete
            name={props.name}
            size='small'
            upperLabel
            fullWidth
            label="Secteur d'activité de l'article"
            loading={null === activitySectors}
            options={Tools.convertToSelectOptions(activitySectors, 'id', 'name', 'icon')}
            wrapperSx={props.sx}
        />
    );
};

export default SelectActivitySector;
