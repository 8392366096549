import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import { setTextEditorHtml } from '../../store/slices/textEditorSlice';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Youtube from '@tiptap/extension-youtube'
import {
    SxProps,
    Theme,
    Stack,
    Box,
    Typography,
} from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import Toolbar from './Toolbar';
import GenerationPanel from './GenerationPanel';
import ModalInformation from '../Modal/ModalInformation';
import RouterLink from '../RouterLink/RouterLink';
import ITextToAdd from '../../interfaces/ITextToAdd';
import cssStyles from './TextEditorStyles';

const TextEditor: React.FC<{
    allowHeadingOne?: boolean|undefined,
    withAIAssistant?: boolean|undefined,
    placeholder?: string|undefined,
    initialHtml?: string|undefined,
    hasError?: boolean|undefined,
    wrapperSx?: SxProps<Theme>|undefined,
    editorCssProps?: React.CSSProperties|undefined,
    standalone?: boolean|undefined,
    autoFocus?: boolean|undefined,
    onChange?: (html: string, plainText: string) => void|undefined,
}> = props => {
    // Use of hooks
    const boldCounter = React.useRef<number>(0);

    // Use of redux
    const dispatch = useAppDispatch();
    const textToAdd: ITextToAdd|null = useAppSelector(state => state.textEditor.textToAdd);

    // Extensions options
    Link.options.openOnClick = false;
    Link.options.HTMLAttributes = { target: '_blank', rel: 'noopener noreferer' };
    Placeholder.options.placeholder = props.placeholder ?? '';

    // Use titap hook
    const editor: Editor|null = useEditor({
        // Use of tiptap extensions
        extensions: [
            StarterKit,
            Placeholder,
            Underline,
            Link,
            Image,
            Youtube.configure({
                controls: false,
                nocookie: true,
            }),
        ],
        // Set text editor content with props initial html
        content: props.initialHtml,
        // Do not focus on text editor load
        autofocus: props.autoFocus ?? false,
        onCreate({ editor }) {
            // Set text editor html in redux state
            !props.standalone && dispatch(setTextEditorHtml({ html: editor.getHTML(), plainText: editor.getText() }));
        },
        // Editor content is updated
        // Only called when user input in editor
        // Not when editor.commands.setContent() !
        onUpdate({ editor }) {
            const html = editor.getHTML();
            const plainText = editor.getText();
            props.onChange && props.onChange(html, plainText);
            // Set text editor html in redux state
            !props.standalone && dispatch(setTextEditorHtml({ html, plainText }));
        },
    }, []);

    // useEffect which allows to add plainText editor from other component interactions
    React.useEffect(() => {
        // Listen to redux state until there is a text to add for this editor
        if (null !== textToAdd && null !== editor) {
            // If text to add have to be bold
            if (true === textToAdd.bold) {
                // Increment bold counter
                ++boldCounter.current;
                // Toggle the bold style if needed
                boldCounter.current <= parseInt(process.env.REACT_APP_MAX_BOLD_COUNTER!) &&
                    !editor.isActive('bold') && editor.commands.toggleBold();
            }
            // Insert the desired text followed by a space
            // Inserting content this way, call onUpdate event !
            editor.commands.insertContent(`${textToAdd.text} `);
            // Make sure bold mark is unset after insertion
            editor.isActive('bold') && editor.commands.unsetBold();
        }
    }, [textToAdd, editor])

    // useMemo of GenerationPanel because it does not need to re-render on editor changes
    const MemoizedGenerationPanel = React.useMemo(() => (
        <>
            <ModalInformation
                name='notEnoughTokenInfoModal'
                type='error'
                title='Attention'
                message={
                    <>
                        <Typography gutterBottom>
                            {`Vous n'avez pas assez de jetons pour générer du contenu`}
                        </Typography>
                        <Typography gutterBottom>
                            {`Vous pouvez recharger vos jetons en `}
                            <RouterLink
                                anchor='cliquant ici.'
                                to='/dashboard/account/tokens'
                                onClick={() => dispatch(closeModal())}
                            />
                        </Typography>
                        <Typography color='warning.main' display='flex' alignItems='center' columnGap={2}>
                            <WarningIcon />
                            {`Sauvegardez votre article avant de cliquer sur le lien !`}
                        </Typography>
                    </>
                }
            />
            <ModalInformation
                name='generateContentInfoModal'
                width={1/2}
                title='Informations sur la génération de contenu'
                message={
                    <>
                        <Typography gutterBottom>
                            {`Lors de la rédaction de l'article, vous avez la possibilité de générer du contenu automatiquement en échange de jetons.`}
                        </Typography>
                        <Typography>
                            {`Il existe 3 tailles de génération qui coûtent différents jetons :`}
                        </Typography>
                        <Box paddingX={1} paddingBottom={3}>
                            <Typography>
                                {`- Taille S = 1 jeton`}
                            </Typography>
                            <Typography>
                                {`- Taille M = maximum 2 jetons`}
                            </Typography>
                            <Typography>
                                {`- Taille L = maximum 3 jetons`}
                            </Typography>
                        </Box>
                        <Typography gutterBottom>
                            {`Vous pouvez générer du contenu en cliquant sur le bouton "Générer du contenu".`}
                        </Typography>
                        <Typography gutterBottom>
                            {`Les jetons seront débités après la génération du contenu.`}
                        </Typography>
                        <Typography gutterBottom>
                            {`Les tailles M et L ont un coût maximum, c'est à dire, si vous demandez une génération de contenu de taille L et qu'un texte d'une génération de taille S est retourné, seulement 1 jeton vous sera débité.`}
                        </Typography>
                        <Typography gutterBottom>
                            {`Vous pouvez recharger vos jetons en `}
                            <RouterLink
                                anchor='cliquant ici.'
                                to='/dashboard/account/tokens'
                                onClick={() => dispatch(closeModal())}
                            />
                        </Typography>
                        <Typography color='warning.main' display='flex' alignItems='center' columnGap={2}>
                            <WarningIcon />
                            {`Sauvegardez votre article avant de cliquer sur le lien !`}
                        </Typography>
                    </>
                }
            />
            <GenerationPanel />
        </>
    ), [dispatch])

    return (
        null !== editor ? (
            <Stack spacing={2} sx={{ width: 1, ...props.wrapperSx }}>
                <Toolbar editor={editor} allowHeadingOne={props.allowHeadingOne} />
                {props.withAIAssistant && (MemoizedGenerationPanel)}
                <Box sx={{
                    height: props.withAIAssistant ? 600 : 640,
                    ...cssStyles.editorBox,
                    ...(props.hasError && { border: 1, borderColor: 'error.main' })
                }}>
                    <EditorContent
                        spellCheck
                        editor={editor}
                        style={{ overflow: 'auto', height: '100%' }}
                    />
                </Box>
            </Stack>
        ) : null
    );
};

export default TextEditor;
