import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import ProjectAPIs from '../../APIs/ProjectAPIs';
import Tools from '../../helpers/Tools';
import {
    Stack,
    SxProps,
    Theme,
    Typography,
    FormHelperText,
} from '@mui/material';
import ModalSingleInput from '../Modal/ModalSingleInput';
import FinalAutocomplete from '../FinalComponents/FinalAutocomplete';
import IKeyword from '../../models/IKeyword';
import IProjectKeyword from '../../models/IProjectKeyword';

const SelectProjectKeyword: React.FC<{
    name: string,
    projectId: number|null|undefined,
    label?: string|undefined,
    fullWidth?: boolean|undefined,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Use of hooks
    const [keywords, setKeywords] = React.useState<Array<IKeyword>|null>(null);
    const mountedRef = React.useRef<boolean>(true);
    const modalName = React.useRef<string>('addProjectKeyword');

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Callback to handle add Keyword to project
    const handleAddProjectKeyword = React.useCallback((keyword: string) => {
        // Call API to create a new ProjectKeyword resource
        ProjectAPIs.postMultipleProjectKeyword([{ project: props.projectId, keywords: [keyword], user: instanceUserId }])
            .then((data) =>
                // Add new Keyword to state
                true === mountedRef.current && null !== data && null !== keywords &&
                    setKeywords([ ...keywords, ...data.map((projectKeyword: IProjectKeyword) => projectKeyword.keyword) ])
            );
    }, [props.projectId, instanceUserId, keywords]);

    // useEffect whenever given props projectId value changes
    React.useEffect(() => {
        // Reset keywords hook value
        setKeywords(null);
    }, [props.projectId])

    // useEffect when component is mounting
    React.useEffect(() => {
        null === keywords && Boolean(props.projectId) &&
            // Call API to fetch syntax levels
            ProjectAPIs.getProjectKeyword({ project: props.projectId })
                // On successful API call
                .then((projectKeywords: Array<IProjectKeyword>|null) =>
                    // Do not update state if component is unmounted
                    true === mountedRef.current && null !== projectKeywords &&
                        setKeywords(projectKeywords.map((projectKeyword: IProjectKeyword) => projectKeyword.keyword))
                );
    }, [keywords, props.projectId]);

    // useEffect when component is unmounting, set mountedRef to false
    React.useEffect(() => () => {
        mountedRef.current = false;
    }, []);

    return (
        <Stack width={1} sx={props.sx}>
            <ModalSingleInput
                name={modalName.current}
                modalTitle='Ajouter un mot-clé'
                inputLabel='Intitulé du mot-clé'
                onValidation={handleAddProjectKeyword}
            />
            <FinalAutocomplete
                name={props.name}
                size='small'
                label={props.label ?? "Mot-clé de l'article"}
                upperLabel
                fullWidth={props.fullWidth}
                disabled={!Boolean(props.projectId)}
                placeholder={!Boolean(props.projectId) ? `Sélectionnez d'abord un projet` : undefined}
                loading={Boolean(props.projectId) && null === keywords}
                options={Tools.convertToSelectOptions(keywords, 'id', 'name')}
            />
            {
                Boolean(props.projectId) && (
                    <FormHelperText>
                        <Typography
                            variant='caption'
                            component='span'
                            color='link'
                            onClick={() => Boolean(props.projectId) && dispatch(openModal({ name: modalName.current }))}
                            sx={{ cursor: 'pointer' }}
                        >
                            {`Cliquez ici `}
                        </Typography>
                        {`pour ajouter un nouveau mot-clé au projet.`}
                    </FormHelperText>
                )
            }
        </Stack>
    );
};

export default SelectProjectKeyword;
