import React from 'react';
import {
    Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import IOrderStats from '../../interfaces/IOrderStats';

const AccountOrdersStats: React.FC<{ stats: IOrderStats }> = props => {
    const keys = Object.keys(props.stats);

    return (
        // Only show if there is at least 1 stat
        0 < keys.length ? (
            <>
                <Typography gutterBottom>
                    {`Nombre de commandes par statut :`}
                </Typography>
                <TableContainer component={Paper} sx={{ maxWidth: 500 }}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>{`Statut`}</TableCell>
                                <TableCell>{`Nombre de commandes`}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                keys.map((status: string, index: number) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{
                                            backgroundColor: props.stats[status].color,
                                            color: theme => theme.palette.getContrastText(props.stats[status].color),
                                        }}>
                                            {status}
                                        </TableCell>
                                        <TableCell>
                                            {props.stats[status].nbrOrders}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        ) : null
    );
};

export default AccountOrdersStats;
