import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

// Style to apply to component
const styles = StyleSheet.create({
    stack: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
});

const Stack: React.FC<{ style?: Style|undefined }> = props => {
    return (
        <View style={{ ...styles.stack, ...props.style }}>
            {props.children}
        </View>
    );
};

export default Stack;
