import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { Formik, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import { minTitleLength, maxTitleLength, minMetaDescLength, maxMetaDescLength, minNbrOfWords } from '../../configs/seoConstraints';
import {
    Grid,
    Divider,
    Box,
    Button,
    FormHelperText,
} from '@mui/material';
import ModalDestinationPicker from '../Modal/ModalDestinationPicker';
import ModalPixabayPicker from '../Modal/ModalPixabayPicker';
import FormikInput from '../FormikComponent/FormikInput';
import FormikCheckbox from '../FormikComponent/FormikCheckbox';
import FormikSlider from '../FormikComponent/FormikSlider';
import SelectProject from './SelectProject';
import SelectProjectKeyword from './SelectProjectKeyword';
import SelectProjectPostType from './SelectProjectPostType';
import SelectActivitySector from './SelectActivitySector';
import SelectSyntaxLevel from './SelectSyntaxLevel';
import DestinationWebsite from './DestinationWebsite';
import IDestinationWebsite from '../../interfaces/IDestinationWebsite';
import IOrderFormStepperData from '../../interfaces/IOrderFormStepperData';

const MainInfo: React.FC<{ formikFormId: string }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();
    const orderFormState: IOrderFormStepperData = useAppSelector(state => state.activeStepper.data);

    // Define unaware initial values to reset form values
    const unawareInitialValues = React.useRef<any>({
        project: null,
        keyword: null,
        title: '',
        metaDesc: '',
        postType: null,
        activitySector: null,
        minOptimization: 10,
        freeOptimization: false,
        minNbrWords: 1,
        syntaxLevel: null,
        toPublish: false,
        destinationWebsite: null,
    });

    // Define initial values for formik form
    const initialValues: any = {
        project: orderFormState.project ??  unawareInitialValues.current.project,
        keyword: orderFormState.keyword ?? unawareInitialValues.current.keyword,
        title: orderFormState.title ?? unawareInitialValues.current.title,
        metaDesc: orderFormState.metaDesc ?? unawareInitialValues.current.metaDesc,
        postType: orderFormState.postType ?? unawareInitialValues.current.postType,
        activitySector: orderFormState.activitySector ?? unawareInitialValues.current.activitySector,
        minOptimization: orderFormState.minOptimization ?? unawareInitialValues.current.minOptimization,
        freeOptimization: orderFormState.freeOptimization ?? unawareInitialValues.current.freeOptimization,
        minNbrWords: orderFormState.minNbrWords ?? unawareInitialValues.current.minNbrWords,
        syntaxLevel: orderFormState.syntaxLevel ?? unawareInitialValues.current.syntaxLevel,
        toPublish: Boolean(orderFormState.destinationWebsite),
        destinationWebsite: orderFormState.destinationWebsite ?? unawareInitialValues.current.destinationWebsite,
    };

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        project: Yup
            .mixed()
            .required('Veuillez spécifier le projet sur lequel vous souhaitez commander'),
        keyword: Yup
            .mixed()
            .required("Veuillez spécifier le mot-clé de l'article"),
        title: Yup
            .string(),
        metaDesc: Yup
            .string(),
        minNbrWords: Yup
            .number()
            .required('Veuillez spécifier un nombre de mots requis')
            .min(minNbrOfWords, `Le nombre minimum de mots est fixé à ${minNbrOfWords}`),
        postType: Yup
            .mixed()
            .required("Veuillez choisir le type de l'article"),
        activitySector: Yup
            .mixed()
            .required("Veuillez choisir un secteur d'activité pour votre article"),
        syntaxLevel: Yup
            .mixed()
            .required("Veuillez choisir un niveau d'orthographe pour la rédaction de l'article"),
    });

    // Callback use to reset formik fields to their initial value
    const resetFormikFields = React.useCallback((formikProps: FormikProps<any>, fields: Array<string>) => {
        // Use pre-build formik function
        fields.forEach((field) => formikProps.setFieldValue(field, unawareInitialValues.current[field]));
    }, [unawareInitialValues]);

    // Callback to hande formik submit
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Store values under data field of active stepper redux state
        // & increment active step
        dispatch(setActiveStepperData({ data: { ...values, toPublish: undefined }, action: 'inc' }));
    }, [dispatch]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form id={props.formikFormId}>
                    {/* Modal to select image from pixabay */}
                    <ModalPixabayPicker
                        defaultKeyword={formikProps.values.keyword?.value}
                        onValidation={(imageSrc) => formikProps.setFieldValue('imageBank', imageSrc)}
                    />
                    {/* Modal to define the website destination */}
                    <ModalDestinationPicker onValidation={(destination: IDestinationWebsite) => formikProps.setFieldValue('destinationWebsite', destination)} />
                    {/* Select input with Project */}
                    <SelectProject
                        onReset={() => resetFormikFields(formikProps, ['keyword', 'postType'])}
                    />
                    {/* Select input with Keyword */}
                    <SelectProjectKeyword projectId={formikProps.values.project?.value}/>
                    <FormikInput
                        upperLabel="Titre de l'article"
                        name='title'
                        helperText='Laisse le choix au rédacteur si non spécifié'
                        warnings={[
                            {
                                condition: formikProps.values.keyword && '' !== formikProps.values.keyword.label && '' !== formikProps.values.title &&
                                        !formikProps.values.title.toLowerCase().startsWith(`${formikProps.values.keyword.label.toLowerCase()} :`),
                                text: `Votre titre devrait commencer par ${formikProps.values.keyword?.label} :`,
                            },
                            {
                                condition: '' !== formikProps.values.title && minTitleLength > formikProps.values.title.length,
                                text: `Votre titre devrait posséder au minimum ${minTitleLength} caractéres`,
                            },
                            {
                                condition: '' !== formikProps.values.title && maxTitleLength < formikProps.values.title.length,
                                text: `Votre titre ne devrait pas dépasser ${maxTitleLength} caractéres`,
                            },
                        ]}
                        sx={{ paddingY: 3 }}
                    />
                    <FormikInput
                        multiline
                        upperLabel="Meta description de l'article"
                        name='metaDesc'
                        helperText='Laisse le choix au rédacteur si non spécifié'
                        warnings={[
                            {
                                condition: '' !== formikProps.values.metaDesc && minMetaDescLength > formikProps.values.metaDesc.length,
                                text: `Votre meta description devrait posséder au minimum ${minMetaDescLength} caractéres`,
                            },
                            {
                                condition: '' !== formikProps.values.metaDesc && maxMetaDescLength < formikProps.values.metaDesc.length,
                                text: `Votre meta description ne devrait pas dépasser ${maxMetaDescLength} caractéres`,
                            },
                        ]}
                        sx={{ paddingBottom: 3 }}
                    />
                    <Grid container columnSpacing={8} rowSpacing={3}>
                        <Grid item xs={12} md={6}>
                            {/* Select input with PostType */}
                            <SelectProjectPostType projectId={formikProps.values.project?.value} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* Select input with ActivitySector */}
                            <SelectActivitySector />
                        </Grid>
                    </Grid>
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    <Grid container columnSpacing={8} rowSpacing={3} paddingBottom={3}>
                        <Grid item xs={12} md={6}>
                            <FormikSlider
                                disabled={formikProps.values.freeOptimization}
                                label='Optimisation minimum requis'
                                name='minOptimization'
                                marks={[ { value: 80 } ]}
                                min={10}
                                sx={{ paddingTop: 0 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormikCheckbox
                                name='freeOptimization'
                                label="Ne pas définir de score d'optimisation"
                                helperText="En cochant cette case, l'article ne sera soumis à aucune contrainte d'optimisation."
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormikInput
                                fullWidth={false}
                                upperLabel='Nombre minimum de mots requis'
                                type='number'
                                name='minNbrWords'
                            />
                        </Grid>
                    </Grid>
                    {/* Select input with SyntaxLevel */}
                    <SelectSyntaxLevel />
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    <FormikCheckbox
                        fullWidth={false}
                        name='toPublish'
                        label="Je souhaite publier mon article sur un de mes sites"
                        onChange={() => resetFormikFields(formikProps, ['destinationWebsite'])}
                        sx={{ paddingBottom: 1 }}
                    />
                    {
                        true === formikProps.values.toPublish && (
                            // Website destination is not defined, show button to open modal
                            formikProps.values.destinationWebsite ? (
                                <DestinationWebsite
                                    website={formikProps.values.destinationWebsite.website.label}
                                    category={formikProps.values.destinationWebsite.category.name}
                                    thematic={formikProps.values.destinationWebsite.thematic.label}
                                    onDelete={() => resetFormikFields(formikProps, ['destinationWebsite'])}
                                />
                            ) : (
                                <Box>
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        onClick={() => dispatch(openModal({ name: 'destinationPicker'}))}
                                    >
                                        {`Choisir un site de destination`}
                                    </Button>
                                    <FormHelperText sx={{ marginTop: 1 }}>
                                        {`Vous aurez toujours la possibilité d'en renseigner un plus tard.`}
                                    </FormHelperText>
                                </Box>
                            )
                        )
                    }
                </Form>
            )}
        </Formik>
    );
};

export default MainInfo;
