import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Chip, Typography } from '@mui/material';

const ColoredChipRenderer: React.FC<ICellRendererParams> = props => {
    // Retrieve initial value before getter & formatter
    const initialValue = props.colDef && props.colDef.field && props.data[props.colDef.field];

    return (
        undefined !== initialValue ? (
            <Chip
                size='small'
                label={initialValue.name}
                sx={{
                    backgroundColor: initialValue.color,
                    color: theme => theme.palette.getContrastText(initialValue.color),
                    fontSize: 'small',
                }}
            />
        ) : (
            <Typography variant='caption'>
                {`Non renseigné`}
            </Typography>
        )
    );
};

export default ColoredChipRenderer;
