import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import Tools from '../../helpers/Tools';
import IOptimizationData from '../../interfaces/IOptimizationData';
import ITextEditorContent from '../../interfaces/ITextEditorContent';
import ITextToAdd from '../../interfaces/ITextToAdd';

export interface ITextEditorState {
    content: ITextEditorContent,
    optimization: IOptimizationData,
    textToAdd: ITextToAdd|null,
};

const initialState: ITextEditorState = {
    content: {
        html: '',
        plainText: '',
        nbrWords: 0,
    },
    optimization: {
        score: null,
        words: [],
        prevPlainText: null,
    },
    textToAdd: null,
};

export const slice = createSlice({
    name: 'textEditorState',
    initialState,
    reducers: {
        setTextEditorState: (state: ITextEditorState, action: PayloadAction<any>) => {
            if (undefined !== action.payload.content) {
                state.content = action.payload.content;
            }
            if (undefined !== action.payload.optimization) {
                state.optimization = action.payload.optimization;
            }
            if (undefined !== action.payload.textToAdd) {
                state.textToAdd = action.payload.textToAdd;
            }
        },
        setTextEditorHtml: (state: ITextEditorState, action: PayloadAction<{ html: string, plainText: string, firstLaunch?: boolean|undefined }>) => {
            state.content.html = action.payload.html;
            state.content.plainText = action.payload.plainText;
            state.content.nbrWords = Tools.countWords(action.payload.plainText);
            // When function is called the first time and optimization prevPlainText is not defined
            if (true === action.payload.firstLaunch && !state.optimization.prevPlainText) {
                // Update optimization prevPlainText with current plainText
                state.optimization.prevPlainText = action.payload.plainText;
            }
        },
        setTextToAdd: (state: ITextEditorState, action: PayloadAction<ITextToAdd>) => {
            state.textToAdd = action.payload;
        },
        clearTextEditorState: (_: ITextEditorState) => {
            // Return a new value and force createSlice to replace the old one.
            return initialState;
        },
    }
});

export const { setTextEditorState, setTextEditorHtml, setTextToAdd, clearTextEditorState } = slice.actions;

/**
 * Custom selectors
 */
// Select plainText of all text editors
export const selectTextEditorPlainText = (offset?: number|undefined): any => createSelector(
    (state: any) => state.textEditor.content.plainText,
    (plainText: string) => {
        const plainTextLength = plainText.length;

        if (offset && offset <= plainTextLength) {
            plainText = plainText.substring(plainTextLength - offset, plainTextLength);
        }

        return plainText;
    }
);

export default slice.reducer;
