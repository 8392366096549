import React from 'react';
import { useLocation } from 'react-router-dom';
import {
    Container,
    Typography,
} from '@mui/material';
import Copyright from '../Copyright/Copyright';

const AskAccountValidation: React.FC = () => {
    // Use react-router-dom hooks
    const location = useLocation();
    const state: string|null = location.state as string|null;

    return (
        <Container component='main' maxWidth='md' sx={{ padding: 5 }}>
            <Typography component='h1' variant='h3' color='primary' align='center' fontWeight={700} gutterBottom>
                {`Bienvenue sur ${process.env.REACT_APP_NAME} !`}
            </Typography>
            <Typography component='h2' variant='h6' align='center' gutterBottom>
                {`Une dernière étape est nécéssaire pour valider votre inscription ...`}
            </Typography>
            <Typography component='p' color='textSecondary' align='center'>
                {`Nous vous avons envoyé une demande de confirmation à l'adresse email suivante :`}
            </Typography>
            <Typography component='p' variant='h5' color='secondary' align='center' paddingY={4} fontWeight={700}>
                {state ?? '<UNKNOWN/>'}
            </Typography>
            <Typography component='p' color='textSecondary' align='center'>
                {`Veuillez suivre le lien indiqué dans l'email pour finaliser l'inscription et ouvrir votre compte.`}
            </Typography>
            <Copyright paddingTop={10} />
        </Container>
    );
};

export default AskAccountValidation;
