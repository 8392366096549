import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import Tools from '../../helpers/Tools';
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TablePagination,
    Typography,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ModalSingleInput from '../Modal/ModalSingleInput';

const SingleValueList: React.FC<{
    data: Array<any>,
    pathToValue: string,
    modalTitle: string,
    modalName: string,
    modalInputLabel: string,
    readOnly?: boolean|undefined,
    rowsPerPage?: 5|10|25|50|100|undefined,
    onChange?: ((element: any, action: string) => void)|undefined,
}> = props => {
    // Use of hooks
    const [data, setData] = React.useState<Array<any>|null>(null);
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(props.rowsPerPage ?? 5);
    const [dataLength, setdataLength] = React.useState<number>(0);

    // Use of redux hooks
    const dispatch = useAppDispatch();

    // useEffect whenever rowsPerPage or page changes
    React.useEffect(() => {
        // Init data with a part of props.data based on rowsPerPage and page
        let newData = props.data.slice(rowsPerPage * page, rowsPerPage * (page + 1));
        // If props.data array is not empty and data based on rowsPerPage and page is empty
        // Then we need to set the pagination page on previous page and set new data
        if(0 < props.data.length && 0 === newData.length) {
            newData = props.data.slice(rowsPerPage * (page - 1), rowsPerPage * (page + 1));
            setPage(page - 1);
        }
        // Set data
        setData(newData);
        // Set data length
        setdataLength(props.data.length);
    }, [rowsPerPage, page, props.data]);

    // Callback which define onRowsPerPageChange of TablePagination
    const handleChangeRowsPerPage = React.useCallback((event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        // Set rowsPerPage based on event target value
        setRowsPerPage(parseInt(event.target.value));
        // Set page to 0 to display the first page of data
        setPage(0);
    }, []);

    // Callback which call the props onChange callback to delete an existing item
    const handleDelete = React.useCallback((index: number) => {
        // use onChange callback with the element at index in data and 'delete' action
        data && props.onChange && props.onChange(data[index], 'delete');
    }, [data, props]);

    // Callback which call the props onChange callback to add a new item
    const handleAdd = React.useCallback((value: string) => {
        // Use onChange callback with new element and 'add' action
        props.onChange && props.onChange({ value: value }, 'add');
    }, [props]);

    return (
        <>
            {
                data && 0 < data.length ? (
                    <List sx={{ padding: 0 }}>
                        {
                            data.map((mapData: any, index: number) => {
                                const value = Tools.getObjectValueByPath(mapData, props.pathToValue);

                                return (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            !props.readOnly && (
                                                <IconButton edge='end' onClick={() => handleDelete(index)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            )
                                        }
                                    >
                                        <ListItemText primary={value} />
                                    </ListItem>
                                );
                            })
                        }
                        <TablePagination
                            component='div'
                            count={dataLength}
                            page={page}
                            onPageChange={(_, newPage: number) => setPage(newPage)}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </List>
                ) : (
                    <Typography paddingBottom={2}>
                        {`Aucune donnée.`}
                    </Typography>
                )
            }
            {
                !props.readOnly && (
                    <>
                        <Button
                            variant='outlined'
                            color='secondary'
                            sx={{ marginLeft: 2 }}
                            onClick={() => dispatch(openModal({ name: props.modalName }))}
                        >
                            {`Ajouter`}
                        </Button>
                        <ModalSingleInput
                            name={props.modalName}
                            modalTitle={props.modalTitle}
                            inputLabel={props.modalInputLabel}
                            onValidation={handleAdd}
                        />
                    </>
                )
            }
        </>
    );
};

export default SingleValueList;
