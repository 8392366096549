import React from 'react';
import { AnimationProps, CustomDomComponent, motion } from 'framer-motion';

interface IMotionProps {
    component: string|React.ComponentType<any>,
    isMuiComponent?: boolean|undefined,
};

/**
 * / ! \ WARNING / ! \\
 *
 * Please make sure that you use this component by specifying TComponentProps type :
 * <Motion<React.ComponentProps<typeof MyCustomComponent\>\> ... />
 *
 * Also, make sure that the component you choose to use (the one passed via component prop),
 * can recieve an innerRef prop AND it is given to component's highest level child.
 *
 * In case the component you choose to give is an MUI component, do not forget to provide
 * isMuiComponent prop, otherwise it won't work & react will display an error in console.
 */
function Motion<TComponentProps = unknown>({ component, isMuiComponent, ...props }: IMotionProps&AnimationProps&TComponentProps) {
    // Apply motion() on given custom component
    const MotionComponent: CustomDomComponent<any> =
        motion(
            'string' === typeof component ?
                component
                // We need to forward ref because it is a functional component
                : React.forwardRef((props, motionRef) => {
                    // From prop to usable component
                    const Component = component;

                    return (
                        isMuiComponent ?
                            (<Component {...props} ref={motionRef} />)
                            : (<Component {...props} innerRef={motionRef} />)
                    );
                })
        );

    return (
        /**
         * props: AnimationProps&ComponentProps
         * AnimationProps will be consumed by motion() to animate the custom component
         * ComponentProps will be given as props to the custom component
         */
        <MotionComponent {...props} />
    );
};

export default Motion;