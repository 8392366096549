import React from 'react';
import { Field } from 'react-final-form';
import {
    SxProps,
    Theme,
    Box,
    RadioGroup,
    RadioGroupProps,
    FormControlLabel,
    Radio,
    FormHelperText,
} from '@mui/material';
import FinalUpperLabel from './FinalUpperLabel';
import FinalFieldMessage from './FinalFieldMessage';
import IRadioOption from '../../interfaces/IRadioOption';

type FinalRadioProps = {
    name: string, // force this prop to not be undefined
    label?: React.ReactNode|undefined,
    radio: Array<IRadioOption>,
    helperText?: React.ReactNode|undefined,
    fullWidth?: boolean|undefined,
    wrapperSx?: SxProps<Theme>|undefined,
    onChange?: ((option: IRadioOption) => void)|undefined,
};

const FinalRadio: React.FC<FinalRadioProps&RadioGroupProps> = ({ radio, wrapperSx, ...props }) => {
    return (
        <Box width={props.fullWidth ? 1 : undefined} sx={wrapperSx}>
            {props.label && (<FinalUpperLabel name={props.name} label={props.label} />)}
                <Field
                    name={props.name}
                    subscription={{ value: true }}
                    render={({ input: { onChange, ...inputFinalFormProps } }) => (
                        <RadioGroup
                            {...inputFinalFormProps}
                            {...props}
                            // inputFinalFormProps.value is an object is an object IRadioOption
                            value={inputFinalFormProps.value.value}
                            onChange={(_, value) => {
                                // Obviously, following value can not be unknown
                                const option = radio.find(option => option.value === value)!;
                                // Value given to onChange is an object IRadioOption
                                onChange(option);
                                // Call onChange props if defined
                                props.onChange && props.onChange(option);
                            }}
                        >
                            {
                                radio.map(({ helperText, ...option }: IRadioOption, index: number) => (
                                    <Box
                                        key={index}
                                        marginRight={props.row && index !== radio.length - 1 ? 2 : undefined}
                                    >
                                        <FormControlLabel
                                            {...option}
                                            disableTypography
                                            sx={{
                                                margin: 0,
                                                fontSize: '1rem',
                                                paddingBottom: !helperText && !props.row && index !== radio.length - 1 ? 2 : undefined,
                                                ...(option.disabled && { color: 'action.disabled' }),
                                            }}
                                            control={
                                                <Radio
                                                    disableRipple
                                                    size='small'
                                                    sx={{
                                                        padding: 0,
                                                        paddingRight: 1,
                                                        '&:hover': { backgroundColor: 'unset' },
                                                    }}
                                                />
                                            }
                                        />
                                        {
                                            helperText && (
                                                <FormHelperText
                                                    children={helperText}
                                                    sx={{
                                                        margin: 0,
                                                        paddingBottom: !props.row && index !== radio.length - 1 ? 2 : undefined,
                                                    }}
                                                />
                                            )
                                        }
                                    </Box>
                                ))
                            }
                        </RadioGroup>
                    )}
                />
            <FinalFieldMessage name={props.name} helperText={props.helperText} />
        </Box>
    );
};

export default FinalRadio;
