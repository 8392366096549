import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import NumberFormat from 'react-number-format';
import { Button, Stack, TextField, Typography } from '@mui/material';
import GenericModal from './GenericModal';

const ModalImportVideo: React.FC<{
    modalTitle: string,
    name?: string|undefined,
    onValidation: (url: string, width: number, height: number, modalParams: any) => void,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'importVideoModal');
    const [url, setUrl] = React.useState('');
    const [width, setWidth] = React.useState(640);
    const [height, setHeight] = React.useState(480);

    // Use of redux
    const dispatch = useAppDispatch();
    const modalParams = useAppSelector(state => state.activeModal.params);

    // Callback triggered on button click
    const handleClick = () => {
        props.onValidation && props.onValidation(url, width, height, modalParams);
        // Submission also close modal
        dispatch(closeModal());
        // Reset states
        setUrl('');
        setWidth(640);
        setHeight(480);
    };

    return (
        <GenericModal
            name={modalName.current}
            title={props.modalTitle}
        >
            <Stack gap={2}>
                <TextField
                    autoFocus
                    label={`URL de la vidéo`}
                    value={url}
                    onChange={(event) => setUrl(event.target.value)}
                />
                <Stack gap={2}>
                    <Typography>{`Format de la vidéo`}</Typography>
                    <NumberFormat
                        customInput={TextField}
                        label='Longueur (width)'
                        value={width}
                        onValueChange={({ value }) => setWidth(Number(value))}
                    />
                    <NumberFormat
                        customInput={TextField}
                        label='Largeur (height)'
                        value={height}
                        onValueChange={({ value }) => setHeight(Number(value))}
                    />
                </Stack>
                <Button
                    variant='outlined'
                    color='inherit'
                    disabled={!url || !width || !height}
                    onClick={() => handleClick()}
                    sx={{ marginTop: 2 }}
                >
                    {`Valider`}
                </Button>
            </Stack>
        </GenericModal>
    );
};

export default ModalImportVideo;
