/**
 * This file define SEO constraints about Post created on app
 * Some requirements are needed to optimize SEO
 * These requirements are used by Formik components to generate warnings & errors
 */
export const minTitleLength: number = parseInt(process.env.REACT_APP_MIN_TITLE_LENGTH!);
export const maxTitleLength: number = parseInt(process.env.REACT_APP_MAX_TITLE_LENGTH!);
export const minMetaDescLength: number = parseInt(process.env.REACT_APP_MIN_META_DESC_LENGTH!);
export const maxMetaDescLength: number = parseInt(process.env.REACT_APP_MAX_META_DESC_LENGTH!);
export const minNbrOfWords: number = parseInt(process.env.REACT_APP_MIN_NBR_WORDS!);
