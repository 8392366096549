import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { store } from '../store';
import IScopesGroup from '../../models/IScopesGroup';

export interface IInstanceUserState {
    id: number,
    avatar?: string|undefined,
    name: string,
    lastname: string,
    username: string,
    email: string,
    roles: Array<string>,
    bio: boolean,
};

export interface IInstanceAccountTypeState {
    id: number,
    name: string,
    slug: string,
};

export interface IInstanceCompanyState {
    id: number,
    name: string,
    iban: boolean,
    siret: boolean,
    country: boolean,
    address: boolean,
    postalCode: boolean,
    city: boolean,
    logo?: string|undefined,
};

export interface IInstanceAccountState {
    id: number,
    walletId: number,
    scopes: Array<string>,
    accountType: IInstanceAccountTypeState,
    company: IInstanceCompanyState,
};

export interface IInstanceState {
    sessionId: number|null,
    apiKey: string|null,
    user: IInstanceUserState|null,
    account: IInstanceAccountState|null,
    isLogged: boolean|undefined,
};

const initialState: IInstanceState = {
    sessionId: null,
    apiKey: null,
    user: null,
    account: null,
    isLogged: undefined,
};

export interface IInstanceUserPayload {
    avatar?: string|undefined,
    bio?: string|boolean|undefined,
};

export const slice = createSlice({
    name: 'instanceState',
    initialState,
    reducers: {
        setInstanceState: (_: IInstanceState, action: PayloadAction<IInstanceState>) => {
            // Return a new value and force createSlice to replace the previous one.
            return action.payload;
        },
        setInstanceUser: (state: IInstanceState, action: PayloadAction<IInstanceUserPayload>) => {
            if (state.user) {
                if (state.user.avatar !== action.payload.avatar) {
                    state.user.avatar = action.payload.avatar;
                }
                if (state.user.bio !== action.payload.bio) {
                    state.user.bio = action.payload.bio && '' !== action.payload.bio ? true : false;
                }
            }
        },
        setInstanceCompany: (state: IInstanceState, action: PayloadAction<IInstanceCompanyState>) => {
            if (state.account) {
                state.account.company = action.payload;
            }
        },
        setInstanceIsLogged: (state: IInstanceState, action: PayloadAction<boolean>) => {
            // Only update state if the value is different.
            if (state.isLogged !== action.payload) {
                state.isLogged = action.payload;
            }
        },
        clearInstanceState: (state: IInstanceState) => {
            // Clear state but keeping isLogged value
            state.sessionId = null;
            state.apiKey = null;
            state.user = null;
            state.account = null;
        },
    },
});

export const {
    setInstanceState,
    setInstanceUser,
    setInstanceCompany,
    setInstanceIsLogged,
    clearInstanceState,
} = slice.actions;

// Custom selector working like Symfony isGranted function
export const instanceIsGranted = (role: string) => createSelector(
    (state: any) => state.instance.user,
    (instanceUser: IInstanceUserState|null) =>
        null !== instanceUser ? instanceUser.roles.some((userRole: string) => userRole === role) : false
);

// Custom selector to check if instance is scoped by ...
export const instanceIsScoped = (scopes: Array<string>|null|undefined) => createSelector(
    (state: any) => state.instance.account?.scopes ?? null,
    (instanceScopes: Array<string>|null) => {
        if (!scopes) {
            return true;
        }

        const dbScopesGroups: Array<IScopesGroup>|null = store.getState().scopesGroups.data;
        let convertedScopes: Array<string> = [];

        if (!dbScopesGroups || null === instanceScopes) {
            return false;
        }

        scopes.forEach((scope: string) => {
            const convertedScope = dbScopesGroups.find((value: IScopesGroup) => value.name === scope);

            convertedScopes = undefined === convertedScope ?
                [ ...convertedScopes, scope ] : [ ...convertedScopes, ...convertedScope.scopes ];
        });

        return instanceScopes.some((scope: string) => convertedScopes.includes(scope));
    }
);
// Custom selector to check if instance has requirements to recover funds
export const instanceCanRecoverFunds = () => createSelector(
    (state: any) => state.instance.account?.company ?? null,
    (instanceCompany: IInstanceCompanyState|null) =>
        instanceCompany ?
            instanceCompany.iban && instanceCompany.siret &&
            instanceCompany.address && instanceCompany.country &&
            instanceCompany.postalCode && instanceCompany.city
            : false
);

// Custom basic selectors
export const selectInstanceAccountTypeName = (asSlug: boolean = false) => createSelector(
    (state: any) => state.instance.account,
    (instanceAccount: IInstanceAccountState|null) =>
        null !== instanceAccount ?
            (asSlug ? instanceAccount.accountType.slug : instanceAccount.accountType.name)
            : null
);
export const selectInstanceAccountScopes = createSelector(
    (state: any) => state.instance.account,
    (instanceAccount: IInstanceAccountState|null) =>
        null !== instanceAccount ? instanceAccount.scopes : []
);

export default slice.reducer;
