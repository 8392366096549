import React from 'react';
import { Field } from 'react-final-form';
import { FormLabel } from '@mui/material';

const FinalUpperLabel: React.FC<{ name: string, label: React.ReactNode, disabled?: boolean|undefined }> = props => {
    return (
        <Field
            name={props.name}
            subscription={{ error: true, touched: true }}
            render={({ meta: { error, touched } }) => (
                <FormLabel
                    error={error && touched}
                    disabled={props.disabled}
                >
                    {props.label}
                </FormLabel>
            )}
        />
    );
};

export default FinalUpperLabel;
