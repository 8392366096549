import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import ProjectAPIs from '../../APIs/ProjectAPIs';
import Tools from '../../helpers/Tools';
import { Typography } from '@mui/material';
import FormikAutocomplete from '../FormikComponent/FormikAutocomplete';
import ModalSingleInput from '../Modal/ModalSingleInput';
import IPostType from '../../models/IPostType';
import IProjectPostType from '../../models/IProjectPostType';

const SelectProjectPostType: React.FC<{ projectId?: number|null|undefined }> = props => {
    // Use of hooks
    const [postTypes, setPostTypes] = React.useState<Array<IPostType>|null>(null);
    const mountedRef = React.useRef<boolean>(true);
    const modalName = React.useRef<string>('addProjectPostTypeModal');

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Callback to handle add PostType to project
    const handleAddProjectPostType = React.useCallback((postTypeName: string) => {
        // Call API to create a new ProjectPostType resource
        ProjectAPIs.postProjectPostType({ project: props.projectId, postTypes: [postTypeName], user: instanceUserId })
            .then((data: Array<IProjectPostType>|null) =>
                // Add new PostType to state
                true === mountedRef.current && null !== data && null !== postTypes &&
                    setPostTypes([ ...postTypes, ...data.map((projectPostType: IProjectPostType) => projectPostType.postType) ])
            );
    }, [props.projectId, instanceUserId, postTypes]);

    // useEffect whenever given props projectId value changes
    React.useEffect(() => {
        // Reset postTypes hook value
        setPostTypes(null);
    }, [props.projectId])

    // useEffect when component is mounting
    React.useEffect(() => {
        null === postTypes && Boolean(props.projectId) &&
            // Call API to fetch syntax levels
            ProjectAPIs.getProjectPostType({ project: props.projectId })
                // On successful API call
                .then((projectPostTypes: Array<IProjectPostType>|null) =>
                    // Do not update state if component is unmounted
                    true === mountedRef.current && null !== projectPostTypes &&
                        setPostTypes(projectPostTypes.map((projectPostType: IProjectPostType) => projectPostType.postType))
                );
    }, [postTypes, props.projectId]);

    // useEffect when component is unmounting, set mountedRef to false
    React.useEffect(() => () => {
        mountedRef.current = false;
    }, []);

    return (
        <>
            <ModalSingleInput
                name={modalName.current}
                modalTitle="Ajouter type d'article"
                inputLabel="Nom du type d'article"
                onValidation={handleAddProjectPostType}
            />
            <FormikAutocomplete
                upperlabel="Type de l'article"
                name='postType'
                disabled={!Boolean(props.projectId)}
                placeholder={!Boolean(props.projectId) ? `Sélectionnez d'abord un projet` : undefined}
                loading={Boolean(props.projectId) && null === postTypes}
                options={Tools.convertToSelectOptions(postTypes, 'id', 'name')}
            />
            {
                Boolean(props.projectId) && (
                    <Typography variant='caption'>
                        <Typography
                            variant='caption'
                            component='span'
                            color='link'
                            onClick={() => Boolean(props.projectId) && dispatch(openModal({ name: modalName.current }))}
                            sx={{ cursor: 'pointer' }}
                        >
                            {`Cliquez ici `}
                        </Typography>
                        {`pour ajouter un nouveau type d'article`}
                    </Typography>
                )
            }
        </>
    );
};

export default SelectProjectPostType;
