import React from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import arrayMutators from 'final-form-arrays';
import { Field } from 'react-final-form';
import useValidationSchema from '../../hooks/useValidationSchema';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import { Alert, Backdrop, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { Done, TravelExplore } from '@mui/icons-material';
import FinalInput from '../FinalComponents/FinalInput';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import FinalForm from '../FinalForm/FinalForm';
import ModalImportCsv from '../Modal/ModalImportCsv';
import DispayBacklinks from './DisplayBacklinks';
import ResultPage from './ResultPage';
import IBacklink from '../../interfaces/IBacklink';
import IBacklinkProcessResult from '../../interfaces/IBacklinkProcessResult';
import IWebsiteData from '../../interfaces/IWebsiteData';

export type TGroupedBackLink = IBacklink & { count: number };
export const emptyGroupedBacklink: TGroupedBackLink = { sourceUrl: '', targetUrl: '', count: 1 };
type TWebsiteBacklinks = { websiteData: IWebsiteData, backlinks: Array<TGroupedBackLink> };

const ConfigureBacklinks: React.FC = () => {
    // Use react-router-dom hooks
    const state = useLocation().state as TWebsiteBacklinks|undefined;

    // Use of hook
    const [loading, setLoading] = React.useState(false);
    const [getBacklinksError, setGetBacklinksError] = React.useState<string|null>(null);
    const [result, setResult] = React.useState<IBacklinkProcessResult|null>(null);
    const onBacklinksChangeRef = React.useRef<((data: Array<TGroupedBackLink>) => void)|null>(null);

    // Define validation schema of form
    const validationSchema = useValidationSchema<TWebsiteBacklinks>({
        websiteData: Yup
            .object()
            .shape({
                domain: Yup.string().required('Veuillez renseigner le nom de domaine du site'),
                login: Yup.string().required("Veuillez renseigner l'identifiant du site"),
                password: Yup.string().required('Veuillez renseigner le mot de passe du site'),
            }),
        backlinks: Yup
            .array()
            .of(
                Yup.object().shape({
                    sourceUrl: Yup.string().optional().url('Veuillez renseigner une URL valide').required('Veuillez renseigner une URL source'),
                    targerUrl: Yup.string().optional().url('Veuillez renseigner une URL valide'),
                })
            )
            .min(1, 'Veuillez fournir au moins une redirection')
            .required('Veuillez fournir une liste de redirections'),
    });

    // useEffect when backlink value changes
    const groupCsvBacklinks = React.useCallback((backlinks: Array<IBacklink>) => {
        // Group backlinks by targetUrl
        let grouped = backlinks.reduce<Array<TGroupedBackLink>>((grouped, current) => {
            // Find existing index for the current target URL
            const existingGroupIndex = grouped.findIndex(element => element.sourceUrl === current.targetUrl);
            // If the current target URL already exists in the grouped list
            if (-1 !== existingGroupIndex) {
                // Increment existing count
                grouped[existingGroupIndex] = {
                    ...grouped[existingGroupIndex],
                    count: grouped[existingGroupIndex].count + 1,
                };
            } else {
                // Add new group
                grouped.push({ ...emptyGroupedBacklink, sourceUrl: current.targetUrl });
            }

            return grouped;
        }, []);
        // Sort by largest number of occurrences first 
        grouped = grouped.sort((a, b) => b.count - a.count);
        // Update state
        setTimeout(() => onBacklinksChangeRef.current && onBacklinksChangeRef.current(grouped));
    }, []);

    // Callback to send redirects to API
    const createRedirects = (values: TWebsiteBacklinks) => {
        // Load during API call
        setLoading(true);
        BridgeAPIs.postWpRedirects({
            ...values.websiteData!,
            redirects: Object.values(values.backlinks),
        })
            .then(result => null !== result && setResult(result))
            // Stop loading
            .finally(() => setLoading(false));
    };

    // Callback to fetch existing redirects from WordPress
    const handleGetRedirectsCall = React.useCallback((websiteData: IWebsiteData) => {
        // Load during API call
        setLoading(true);
        setGetBacklinksError(null);
        // Call API to retrieve redirects
        BridgeAPIs.getRedirects(websiteData)
            .then((result) => {
                if (null !== result) {
                    if (0 < result.length) {
                        setTimeout(() => onBacklinksChangeRef.current && onBacklinksChangeRef.current(result.map(backlink => ({ ...backlink, count: 1 }))));
                    } else {
                        setGetBacklinksError("Il n'y a pas de redirection paramétrée sur votre site.");
                    }
                } else {
                    setGetBacklinksError("Impossible de trouver des redirections. Assurez-vous d'avoir renseigné les bonnes informations et d'avoir le plugin 'Redirection' sur votre site WordPress.");
                }
            })
            // Stop loading
            .finally(() => setLoading(false));
    }, []);

    // useEffect when component mount
    React.useEffect(() => {
        // If website data is provided
        if (state?.websiteData && state.websiteData.domain && state.websiteData.login && state.websiteData.password) {
            // Fetch WordPress redirects
            handleGetRedirectsCall(state.websiteData);
        }
    }, [handleGetRedirectsCall, state?.websiteData])

    return (
        null === result ? (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                    open={loading}
                >
                    <CircularProgress color='inherit' />
                </Backdrop>
                <FinalForm<TWebsiteBacklinks>
                    initialValues={{
                        websiteData: state?.websiteData ?? { domain: '', login: '', password: '' },
                        backlinks: state?.backlinks ?? []
                    }}
                    keepDirtyOnReinitialize
                    validateSchema={validationSchema}
                    mutators={{ ...arrayMutators }}
                    onSubmit={(values) => createRedirects(values)}
                >
                    <Field
                        name='backlinks'
                        subscription={{}}
                        render={({ input: { onChange }}) => {
                            // Store onChange function for later uses
                            onBacklinksChangeRef.current = onChange;

                            return (
                                <ModalImportCsv
                                    expectedHeaders={[{ label: 'URL source', value: 'sourceUrl' }, { label: 'URL cible', value: 'targetUrl' }]}
                                    onValidation={(data) => groupCsvBacklinks(data as any)}
                                />
                            );
                        }}
                    />
                    <Stack gap={1} marginBottom={2}>
                        <Typography variant='h5' gutterBottom>{`Informations du site :`}</Typography>
                        <FinalInput disabled={Boolean(state?.websiteData)} name='websiteData.domain' size='small' label='Nom de domain' />
                        <FinalInput disabled={Boolean(state?.websiteData)} name='websiteData.login' size='small' label='Identifiant' />
                        <FinalInput disabled={Boolean(state?.websiteData)} name='websiteData.password' size='small' label='Mot de passe' />
                        <Field<IWebsiteData>
                            name='websiteData'
                            subscription={{ value: true }}
                            render={({ input: { value }}) => (
                                <Button
                                    variant='outlined'
                                    startIcon={<TravelExplore />}
                                    disabled={!value.domain || !value.login || !value.password}
                                    sx={{ alignSelf: 'flex-start' }}
                                    onClick={() => handleGetRedirectsCall(value)}
                                >
                                    {`Rechercher les redirections existantes`}
                                </Button>
                            )}
                        />
                        {
                            getBacklinksError && (
                                <Alert severity='error' onClose={() => setGetBacklinksError(null)}>{getBacklinksError}</Alert>
                            )
                        }
                    </Stack>
                    <Typography variant='h5' gutterBottom>{`Redirections du site :`}</Typography>
                    <DispayBacklinks />
                    <FinalSubmitButton
                        enabledOnPristine
                        enabledOnInvalid
                        startIcon={<Done />}
                        variant='contained'
                        children='Créer les redirections'
                        sx={{ marginTop: 2 }}
                    />
                </FinalForm>
            </>
        ) : (
            <ResultPage result={result} />
        )
    );
};

export default ConfigureBacklinks;
