import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgProfile: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <g>
                <ellipse transform="matrix(0.9806 -0.1962 0.1962 0.9806 -32.853 35.5609)" fill='#F8B64C' cx="163.067" cy="183.605" rx="11.734" ry="29.869" />
                <ellipse transform="matrix(-0.9806 -0.1962 0.1962 -0.9806 658.0483 432.2708)" fill='#F8B64C' cx="350.434" cy="183.543" rx="11.734" ry="29.869" />
            </g>
            <path fill='#F2F2F2' d='M493.347,513.094h-473.6c0,0,7.467-98.133,39.467-141.867c0,0,1.067,0,3.2-1.067c18.133-6.4,94.933-36.267,131.2-64l3.2-17.067c0,0,14.933,29.867,48,35.2c3.2,0,7.467,1.067,10.667,0c4.267,0,7.467,0,10.667,0c33.067-4.267,48-35.2,48-35.2l3.2,17.067c36.267,27.733,113.067,56.533,131.2,64c2.133,1.067,4.267,1.067,4.267,1.067C485.881,414.961,493.347,513.094,493.347,513.094z'/>
            <path fill='#CDD6E0' d='M200.014,360.561l14.933,26.667l21.333-34.133l-5.333-6.4l-17.067,25.6l-20.267-37.333C195.747,342.428,196.814,350.961,200.014,360.561z'/>
            <path fill='#F8B64C' d='M315.214,289.094c0,0-14.933,29.867-48,35.2c-3.2,0-7.467,1.067-10.667,0c-4.267,0-7.467,0-10.667,0c-33.067-4.267-48-35.2-48-35.2s1.067-6.4,1.067-12.8c0-3.2,0-6.4,0-9.6c27.733,34.133,57.6,39.467,57.6,39.467s30.933-5.333,57.6-39.467c0,3.2,0,6.4,0,9.6C314.147,282.694,315.214,289.094,315.214,289.094z'/>
            <path fill='#F7AF48' d='M314.147,276.294c-26.667,29.867-55.467,35.2-56.533,35.2h-1.067h-1.067c-2.133,0-29.867-5.333-56.533-35.2c0-3.2,0-6.4,0-9.6c27.733,34.133,57.6,39.467,57.6,39.467s30.933-5.333,57.6-39.467C314.147,269.894,314.147,273.094,314.147,276.294z'/>
            <path fill='#FFD15C' d='M354.681,156.828c-1.067,6.4-1.067,9.6-1.067,9.6c-17.067,126.933-96,139.733-96,139.733s-78.933-12.8-96-139.733c0,0-1.067-3.2-1.067-9.6l0,0c-6.4-30.933-9.6-129.067,96-126.933C362.147,27.761,358.947,125.894,354.681,156.828z'/>
            <path fill='#40596B' d='M256.547,481.094v32h160l37.333-139.733l-3.2-3.2c-18.133-6.4-94.933-36.267-131.2-64l0,0c0,0,9.6,32-20.267,90.667C298.147,396.828,273.614,445.894,256.547,481.094z'/>
            <path fill='#CDD6E0' d='M313.08,360.561l-14.933,26.667l-21.333-34.133l5.333-6.4l17.067,25.6l20.267-37.333C317.347,342.428,316.281,350.961,313.08,360.561z'/>
            <path fill='#FF7058' d='M267.214,363.761l11.733,71.467c-7.467,13.867-14.933,30.933-22.4,44.8c-6.4-13.867-14.933-29.867-22.4-44.8l11.733-71.467l-14.933-17.067l13.867-23.467l0,0c3.2,0,7.467,1.067,10.667,0c4.267,0,7.467,0,10.667,0l0,0l13.867,23.467L267.214,363.761z'/>
            <path fill='#40596B' d='M270.414,513.094H96.547L59.214,373.361l3.2-3.2c18.133-6.4,94.933-36.267,131.2-64l0,0l0,0c0,0-9.6,32,20.267,90.667c0,0,25.6,49.067,41.6,83.2C264.014,496.028,269.347,508.828,270.414,513.094z'/>
            <path fill='#334A5E' d='M152.014,148.294l9.6,27.733c0,0-4.267-83.2,27.733-84.267c0,0,46.933,19.2,68.267,23.467c0,0,46.933,6.4,72.533-23.467c0,0,19.2,10.667,12.8,33.067c0,0,9.6,38.4,9.6,50.133l9.6-25.6c4.267-12.8,21.333-72.533-26.667-87.467c0,0-4.267-54.4-81.067-61.867c0,0-56.533-3.2-103.467,61.867l9.6-4.267C160.547,57.628,139.214,71.494,152.014,148.294z'/>
        </SvgIcon>
    );
};

export default SvgProfile;
