import React from 'react';
import UserAPIs from '../../APIs/UserAPIs';
import ModalSelectUsers from './ModalSelectUsers';
import EAccountTypes from '../../interfaces/EAccountTypes';
import IUser from '../../models/IUser';

const ModalSelectFreelance: React.FC<{ modalName: string, onValidation: (params: any, user: IUser) => void }> = props => (
    <ModalSelectUsers
        name={props.modalName}
        singleSelect
        getUsers={(params: any) => UserAPIs.getUser({ ...params, accountType: EAccountTypes.EDITOR })}
        onValidation={(params: any, users: Array<IUser>|null) => users && 1 === users.length && props.onValidation(params, users[0])}
    />
);

export default ModalSelectFreelance;
