import React from 'react';
import { Button, Stack } from '@mui/material';
import Rate from '../../Rate/Rate';

const AcceptAction: React.FC<{
    acceptState: boolean,
    showPublish: boolean,
    mustRate: boolean,
    onAccept: (publish: boolean, rate?: number|undefined, comment?: string|undefined) => void,
    onClick: () => void,
}> = props => {
    // Use of hooks
    const [rate, setRate] = React.useState<number|null>(null);
    const publish = React.useRef<boolean>(false);
    const comment = React.useRef<string>('');

    return (
        false === props.acceptState ? (
            <>
                <Button
                    variant='outlined'
                    color='success'
                    onClick={() => props.mustRate ? props.onClick() : props.onAccept(false)}
                >
                    {`Valider l'article`}
                </Button>
                {
                    props.showPublish && (
                        <Button
                            variant='outlined'
                            color='info'
                            onClick={() => {
                                props.mustRate ? props.onClick() : props.onAccept(true)
                                publish.current = true;
                            }}
                        >
                            {`Publier l'article`}
                        </Button>
                    )
                }
            </>
        ) : (
            <Stack rowGap={2} alignItems='start' width={1}>
                <Rate
                    title='Note du rédacteur sur 5 :'
                    precision={0.5}
                    maxRate={5}
                    withComment
                    commentProps={{
                        multiline: true,
                        fullWidth: true,
                        rows: 2,
                    }}
                    onCommentChange={value => comment.current = value}
                    onRateChange={value => setRate(value)}
                />
                <Stack direction='row' alignItems='center' columnGap={2}>
                    <Button
                        variant='outlined'
                        color='info'
                        onClick={() => {
                            props.onClick();
                            // Reset states
                            publish.current = false;
                            comment.current = '';
                            setRate(null);
                        }}
                    >
                        {`Retour`}
                    </Button>
                    <Button
                        variant='outlined'
                        color='success'
                        onClick={() => props.onAccept(publish.current, rate!, comment.current)}
                        disabled={null === rate}
                    >
                        {`Confirmer`}
                    </Button>
                </Stack>
            </Stack>
        )
    );
};

export default AcceptAction;
