import React from 'react';
import { pdf, PDFViewer } from '@react-pdf/renderer';

const PdfIFrame: React.FC<{
    document: JSX.Element,
    height?: string|number|undefined,
    width?: string|number|undefined,
    onBlobChange?: (blob: Blob) => void|undefined,
}> = props => {
    // useEffect when pdf document blob changes
    React.useEffect(() => {
        // Call given callback if it exists
        props.onBlobChange &&
            pdf(props.document).toBlob().then(blob => props.onBlobChange!(blob));
    }, [props])

    return (
        <PDFViewer height={props.height ?? '100%'} width={props.width ?? '100%'}>
            {props.document}
        </PDFViewer>
    );
};

export default PdfIFrame;
