import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import SiteAPIs from '../../APIs/SiteAPIs';
import DataGrid from '../DataGrid/DataGrid';
import Spinner from '../Spinner/Spinner';
import IRowData from '../../interfaces/IRowData';
import ISite from '../../models/ISite';

const SitesListing: React.FC = () => {
    // Use of hooks
    const [sites, setSites] = React.useState<Array<ISite>|null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);

    // Use of react-router-dom hooks
    const navigate = useNavigate();

    // Define delete message for cofirmation modal
    const deleteMessage = `Cette action est définitive et irréversible. La publication automatique
        des articles concernant le ou les site(s) sélectionné(s) sera annulée.
        Le récapitulatif de certaines commandes pourrait devenir incomplet.`;

    // Callback to handle Site status refresh
    const refreshSiteStatus = (sites: Array<ISite>) => {
        // Load during API call
        setLoading(true);
        SiteAPIs.refreshStatus(sites.map(site => site.id))
            // On successfull call
            .then((data: Array<ISite>|null) => {
                // Update sites state
                null !== data && setSites(existing => existing!.map(site => data!.find(s => s.id === site.id) ?? site));
            })
            .finally(() => setLoading(false));
    };

    // Callback to handle Site status refresh
    const updateSitesWhoisInformation = (sites: Array<ISite>) => {
        // Load during API call
        setLoading(true);
        SiteAPIs.updateWhoisInformation(sites.map(site => site.id))
            // On successfull call
            .then((data: Array<ISite>|null) => {
                // Update sites state
                null !== data && setSites(existing => existing!.map(site => data!.find(s => s.id === site.id) ?? site));
            })
            .finally(() => setLoading(false));
    };

    // useEffect when component is mounting
    React.useEffect(() => {
        null === sites &&
            // Retrieve AccountThematic associated to instance Account
            SiteAPIs.getSite({ 'account.id': instanceAccountId })
                // Set our hook as an array of Thematics from retrieved AccountThematic
                .then((data: Array<ISite>) => data && setSites(data));
    }, [sites, instanceAccountId])

    return (
        null !== sites ? (
            <DataGrid
                checkableRows
                loading={loading}
                rowMenuOptions={[
                    {
                        label: 'Programmer la publication',
                        onClick: (rowData: IRowData) => navigate('schedule', { state: rowData.data }),
                    },
                    {
                        label: 'Voir les programmations',
                        onClick: (rowData: IRowData) => navigate('../../publication_schedule', { state: rowData.data.id }),
                    },
                    {
                        label: 'Configurer les redirections',
                        onClick: (rowData: IRowData) => navigate('/dashboard/tools/configure_backlinks', { state: { websiteData: { domain: rowData.data.domain, login: rowData.data.login, password: rowData.data.passwordApp ?? rowData.data.password }} }),
                    },
                    {
                        label: 'Mettre à jour le statut',
                        onClick: (rowData: IRowData) => refreshSiteStatus([rowData.data]),
                    },
                    {
                        label: 'Mettre à jour WHOIS',
                        onClick: (rowData: IRowData) => updateSitesWhoisInformation([rowData.data]),
                    },
                    {
                        label: 'Modifier',
                        onClick: (rowData: IRowData) => navigate('form', { state: rowData.data }),
                    },
                    {
                        label: 'Supprimer',
                        color: 'error.main',
                        // Open DataGrid confirmModal
                        onClick: (rowData: IRowData) => dispatch(openModal({ name: 'confirmModalDataGrid', params: rowData })),
                    },
                ]}
                rowName='site'
                rowData={sites}
                columnData={[
                    {
                        field: 'isOnline',
                        headerName: 'Statut',
                        sortable: true,
                        width: 85,
                        cellRenderer: 'onlineRenderer',
                    },
                    {
                        headerName: 'Thématique',
                        resizable: true,
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        valueGetter: params => params.data.thematic.name,
                    },
                    {
                        headerName: 'Propriétaire',
                        type: 'user',
                        valueGetter: params => params.data.user,
                    },
                    {
                        field: 'isHttps',
                        headerName: 'HTTPS',
                        width: 85,
                        sortable: true,
                        cellRenderer: 'httpsRenderer',
                    },
                    {
                        field: 'domain',
                        headerName: 'Nom de domaine',
                        resizable: true,
                        sortable: true,
                        sort: 'asc',
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'login',
                        headerName: 'Identifiant',
                        resizable: true,
                        sortable: true,
                        filter: 'agTextColumnFilter',
                    },
                    { field: 'password', headerName: 'Mot de passe', type: 'password' },
                    { field: 'passwordApp', headerName: "Mot de passe d'application", type: 'password' },
                    {
                        headerName: 'Articles programmés',
                        resizable: true,
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        valueGetter: params => (params.data as ISite).siteSchedules
                            // Count SiteSchedule to be published
                            .filter(schedule => schedule.toPublishAt)
                            .length,
                    },
                    {
                        field: 'owner',
                        headerName: 'Propriétaire',
                        resizable: true,
                        sortable: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'registrar',
                        headerName: 'Registrar',
                        resizable: true,
                        sortable: true,
                        filter: 'agTextColumnFilter',
                    },
                    { field: 'expireAt', headerName: 'Expire le', type: 'date' },
                    { field: 'createdAt', headerName: 'Créer le', type: 'date' },
                    { field: 'updatedAt', headerName: 'Modifié le', type: 'date' },
                ]}
                selectionActions={(selectedRows: Array<ISite>) => [
                    {
                        label: 'Mettre à jour le statut',
                        action: () => refreshSiteStatus(selectedRows),
                    },
                    {
                        label: 'Mettre à jour WHOIS',
                        action: () => updateSitesWhoisInformation(selectedRows),
                    }
                ]}
                deleteMessage={deleteMessage}
                addElementButtonText='Ajouter un site'
                onAddElement={() => navigate('form')}
                onDeleteSelection={(data: Array<number>) => SiteAPIs.deleteMultipleSite(data)}
                onDeleteRow={(id: number) => SiteAPIs.deleteSite(id)}
            />
        ) : (<Spinner />)
    );
};

export default SitesListing;
