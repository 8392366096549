import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ICsvData, { ICsvColumn } from '../../interfaces/ICsvData';
import ICsvEntityStructure from '../../interfaces/ICsvEntityStructure';

export interface IImportCsvState {
    loading: boolean,
    expectedHeaders: Array<{ label: string, value: string }>|null,
    dataValidation: ICsvEntityStructure|null,
    prefilledColumns: ICsvColumn|null,
    csvData: ICsvData|null,
    csvHeaders: Array<string>|null,
    sortedHeaders: Array<string>|null,
    sortedData: ICsvData|null,
    step: number,
};

const initialState: IImportCsvState = {
    loading: true,
    expectedHeaders: null,
    dataValidation: null,
    prefilledColumns: null,
    csvData: null,
    csvHeaders: null,
    sortedHeaders: null,
    sortedData: null,
    step: 1,
};

export const slice = createSlice({
    name: 'importCsvState',
    initialState,
    reducers: {
        setImportCsvState: (state: IImportCsvState, action: PayloadAction<Partial<IImportCsvState>>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setLoading: (state: IImportCsvState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        incrementStep: (state: IImportCsvState) => {
            ++state.step;
        },
        decrementStep: (state: IImportCsvState) => {
            --state.step;
        },
        resetImportCsvState: (state: IImportCsvState) => {
            return initialState;
        },
    },
});

export const { setImportCsvState, setLoading, incrementStep, decrementStep, resetImportCsvState } = slice.actions;

// Custom selector to check if step can be incremented
export const canIncrement = () => createSelector(
    (state: any) => state.importCsv,
    (state: IImportCsvState) => {
        if (true === state.loading) {
            return false;
        }
        switch (state.step) {
            case 1:
                return Boolean(state.csvData && state.csvHeaders);
            case 2:
                return null !== state.sortedHeaders;
            case 3:
                return null !== state.sortedData;
            default:
                return true;
        }
    },
);

export default slice.reducer;
