import React from 'react';
import { useAppSelector } from '../../store/hooks';
import OrderAPIs from '../../APIs/OrderAPIs';
import {
    Typography,
    Stack,
} from '@mui/material';
import RefusedPost from './RefusedPost';
import ModalViewPost from '../Modal/ModalViewPost';
import ModalViewRejectionMessages from '../Modal/ModalViewRejectionMessages';
import Spinner from '../Spinner/Spinner';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IOrder from '../../models/IOrder';

const PostsRefusedListing: React.FC = () => {
    // Use of hooks
    const [orders, setOrders] = React.useState<Array<IOrder>|null>(null);
    const isMounted = React.useRef<boolean>(true);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === orders &&
            // Get assigned orders of current user
            OrderAPIs.getOrder({
                editor: instanceUserId,
                statuses: [EOrderStatuses.REFUSED],
                outdatedAt: 'ASC',
            })
            // API call successful
            .then((data: Array<IOrder>|null) => true === isMounted.current && data && setOrders(data))
    }, [orders, instanceUserId]);

    // useEffect when component is unmounting
    React.useEffect(() => () => {
        // Set isMounted ref to false to prevent state changes on current requests
        isMounted.current = false;
    }, []);

    return (
        <>
            <Typography variant='h5' paddingBottom={3} color='primary.main'>
                {`Mes articles refusés :`}
            </Typography>
            {
                null !== orders ? (
                    0 < orders.length ? (
                        <>
                            <ModalViewRejectionMessages />
                            <ModalViewPost />
                            <Stack spacing={3}>
                                {
                                    orders.map((order: IOrder, index: number) => (
                                        <RefusedPost key={index} order={order} />
                                    ))
                                }
                            </Stack>
                        </>
                    ) : (
                        <Typography variant='body2' color='textSecondary' align='center'>
                            {`Aucun article refusé \\o/`}
                        </Typography>
                    )
                ) : (<Spinner />)
            }
        </>
    );
};

export default PostsRefusedListing;
