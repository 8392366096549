import React from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { IInstanceWalletState } from '../../store/slices/instanceWalletSlice';
import { Formik, FormikProps, Form, FieldArray } from 'formik';
import {
	Button,
	Divider,
	FormHelperText,
	IconButton,
	Paper,
	Stack,
	Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ModalDestinationPicker from '../Modal/ModalDestinationPicker';
import DuplicateDestinationWebsite, { duplicateSiteOptions } from './DuplicateDestinationWebsite';
import DuplicateEditor, { duplicateEditorOptions } from './DuplicateEditor';
import { privacyOptions, publicOptions } from './SecondaryInfo';
import ModalConfirmation from '../Modal/ModalConfirmation';
import IDestinationWebsite from '../../interfaces/IDestinationWebsite';
import IDuplicateOrder from '../../interfaces/IDuplicateOrder';
import IOrderFormStepperData from '../../interfaces/IOrderFormStepperData';

export const unawareInitialValues: any = {
    duplicateSiteChoice: duplicateSiteOptions[0],
    destinationWebsite: undefined,
    autoPublish: false,
    toPublish: false,
    toPublishAt: undefined,
    duplicateEditorChoice: duplicateEditorOptions[0],
    privacy: privacyOptions[0],
    publicChoice: publicOptions[0],
    editor: undefined,
};

const Duplicate: React.FC<{ formikFormId: string }> = props => {
    // Use of redux hooks
    const dispatch = useAppDispatch();
    const orderFormState: IOrderFormStepperData = useAppSelector(state => state.activeStepper.data);
    const instanceWalletState: IInstanceWalletState = useAppSelector(state => state.instanceWallet);

    // Use of hooks
    const initialDuplicateOrder: IDuplicateOrder = {
        identifier: undefined,
        duplicateSiteChoice: duplicateSiteOptions[0],
        destinationWebsite: undefined,
        autoPublish: false,
        toPublish: false,
        toPublishAt: undefined,
        // If Order is public 'none' option is not displayed, so select 'duplicate' option
        duplicateEditorChoice: 'public' === orderFormState.privacy?.value ? duplicateEditorOptions[1] : duplicateEditorOptions[0],
        // Copy initial order privacy
        privacy: orderFormState.privacy ?? privacyOptions[0],
        // Copy initial order publicChoice
        publicChoice: orderFormState.publicChoice ?? publicOptions[0],
        // If Order is public, duplicate initial editor by default
        editor: 'public' === orderFormState.privacy?.value ? orderFormState.editor : undefined,
    };

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        duplicates: Yup
            .array()
            .test(
                'wallet',
                "Vous n'avez pas les fonds nécessaires sur votre porte-monnaie. Veuillez ajuster le tarif de la commande initiale ou supprimer des duplicatas ou recharger votre porte-monnaie.",
                // Value is valid if initial order has no price or there is no duplicates or the sum of all duplicates price is less or equal to the wallet unlocked balance
                (value) => undefined === orderFormState.price || undefined === value || 0 === value.length || instanceWalletState.unlocked >= ((value.length * Number(orderFormState.price)) + Number(orderFormState.price)),
            ),
    });

    // Callback to hande form submit
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Handle duplicate data
        const formData = values.duplicates.map((duplicate: IDuplicateOrder) => ({
            ...duplicate,
            toPublish: undefined,
            // Convert toPublishAt to string
            toPublishAt: duplicate.toPublishAt ? moment(duplicate.toPublishAt).format() : undefined
        }));
        // Store values under data field of active stepper redux state
        // & increment active step
        dispatch(setActiveStepperData({ data: { duplicates: formData }, action: 'inc' }));
    }, [dispatch]);

    return (
        <Formik initialValues={{ duplicates: orderFormState.duplicates ?? [] }} validationSchema={validationSchema} onSubmit={handleFormikSubmit}>
            {(formikProps: FormikProps<any>) => (
                <Form id={props.formikFormId}>
                    {/* Modal to chose a website destination */}
                    <ModalDestinationPicker
                        name='duplicateDestinationPicker'
                        onValidation={(destination: IDestinationWebsite, index: number) => formikProps.setFieldValue(`duplicates.${index}.destinationWebsite`, destination)}
                    />
                    <ModalConfirmation
                        type='error'
                        name='duplicateAutoPublishModal'
                        title='Attention !'
                        message={(
                            <>
                                <Typography variant='body1'>
                                    {`En cochant cette option vous renoncez à tout contrôle sur la validation de l'article.`}
                                </Typography>
                                <Typography variant='body1'>
                                    {`Le virement sera effectué automatiquement après la rédaction de l'article.`}
                                </Typography>
                                <Typography variant='body1'>
                                    {`L'article sera automatiquement publié sur le site choisi.`}
                                </Typography>
                            </>
                        )}
                        onClose={({ index }) => undefined !== index && formikProps.setFieldValue(`duplicates.${index}.autoPublish`, false)}
                    />
                    {/* List of all duplicates order */}
                    <FieldArray name='duplicates'>
                        {({ remove, push }) => (
                            <>
                                {/* Add a duplicate order */}
                                <Button
                                    variant='outlined'
                                    color='secondary'
                                    onClick={() => push({ ...initialDuplicateOrder, identifier: uuidv4() })}
                                >
                                    {`Dupliquer la commande`}
                                </Button>
                                <FormHelperText sx={{ marginTop: 1 }}>
                                    {`Ajoutez et configurez des duplicatas de votre commande.`}
                                </FormHelperText>
                                {
                                    formikProps.errors.duplicates && (
                                        <FormHelperText error>
                                            {formikProps.errors.duplicates}
                                        </FormHelperText>
                                    )
                                }
                                <Stack spacing={3} sx={{ paddingTop: 2 }}>
                                    {
                                        formikProps.values.duplicates.map((duplicateOrder: IDuplicateOrder, index: number) => (
                                            <Paper key={duplicateOrder.identifier} component={Stack} padding={2} width={1}>
                                                <Stack
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    alignItems='flex-start'
                                                >
                                                    <Typography variant='h5'>
                                                        {`Duplication`}
                                                        <Typography variant='h5' component='span' color='primary' fontWeight={700}>
                                                            {` n°${index + 1}`}
                                                        </Typography>
                                                        {` de votre commande`}
                                                    </Typography>
                                                    {/* Remove duplicate order */}
                                                    <IconButton onClick={() => remove(index)}>
                                                        <CloseIcon fontSize='small' />
                                                    </IconButton>
                                                </Stack>
                                                <Stack spacing={2} paddingX={2} paddingTop={2}>
                                                    {/* Component to define website destination for the duplicate order */}
                                                    <DuplicateDestinationWebsite
                                                        formikProps={formikProps}
                                                        index={index}
                                                        modalDestinationPicker='duplicateDestinationPicker'
                                                    />
                                                    {
                                                        ('private' === orderFormState.privacy?.value || 'propose' === orderFormState.publicChoice?.value) && (
                                                            <>
                                                                <Divider sx={{ marginY: 2 }} />
                                                                {/* Component to define editor for the duplicate order */}
                                                                <DuplicateEditor
                                                                    formikProps={formikProps}
                                                                    index={index}
                                                                    initialEditor={orderFormState.editor}
                                                                    isPublic={'public' === orderFormState.privacy?.value}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                </Stack>
                                            </Paper>
                                        ))
                                    }
                                </Stack>
                            </>
                        )}
                    </FieldArray>
                </Form>
            )}
        </Formik>
    );
};

export default Duplicate;
