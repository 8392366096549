import { configureStore } from '@reduxjs/toolkit';
import dashboardMenuReducer from './slices/dashboardMenuSlice';
import activeModalReducer from './slices/activeModalSlice';
import activeStepperReducer from './slices/activeStepperSlice';
import instanceReducer from './slices/instanceSlice';
import instanceWalletReducer from './slices/instanceWalletSlice';
import scopesGroupsReducer from './slices/scopesGroupsSlice';
import orderStatusesReducer from './slices/orderStatusesSlice';
import textEditorReducer from './slices/textEditorSlice';
import countriesReducer from './slices/countriesSlice';
import openAiGenerationReducer from './slices/openAiGenerationSlice';
import importCsvReducer from './slices/importCsvSlice';

export const store = configureStore({
    reducer: {
        dashboardMenu: dashboardMenuReducer,
        activeModal: activeModalReducer,
        activeStepper: activeStepperReducer,
        instance: instanceReducer,
        instanceWallet: instanceWalletReducer,
        scopesGroups: scopesGroupsReducer,
        orderStatuses: orderStatusesReducer,
        textEditor: textEditorReducer,
        countries: countriesReducer,
        openAiGeneration: openAiGenerationReducer,
        importCsv: importCsvReducer,
    },
});

//@see https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
