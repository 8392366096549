import React from 'react';
import useTimeout from '../../hooks/useTimeout';
import {
    SxProps,
    Theme,
    Skeleton,
    CardMedia
} from '@mui/material';

const ImageWithLoad: React.FC<{
    imageSrc: string|null|undefined,
    height: number,
    width: number,
    giveUpAfter?: number|undefined
    sx?: SxProps<Theme>|undefined,
    onClick?: (() => void)|undefined,
}> = props => {
    // Use of hooks
    const [loading, isLoading] = React.useState<boolean|null>(true);

    // After 1s or given timeout
    useTimeout(() => {
        // Give up on loading
        true === loading && isLoading(false);
    }, props.giveUpAfter ?? 1000, [])

    return (
        <>
            {
                true === loading && (
                    <Skeleton
                        variant='rectangular'
                        sx={{ height: props.height, width: props.width }}
                    />
                )
            }
            <CardMedia
                component='img'
                // Zero width space
                alt='&#8203;'
                image={props.imageSrc ?? 'ezf'}
                onLoad={() => isLoading(false)}
                onClick={() => props.onClick ? props.onClick() : undefined}
                sx={{
                    display: loading ? 'none' : 'block',
                    objectFit: 'contain',
                    height: props.height,
                    width: props.width,
                    ...props.sx
                }}
            />
        </>
    );
};

export default ImageWithLoad;
