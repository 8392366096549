import React from 'react';
import {
    SxProps,
    Theme,
    Typography,
    Stack,
    Box,
    Button,
    Paper,
} from '@mui/material';

const DestinationWebsite: React.FC<{
    website: string,
    thematic: string,
    category: string,
    elevation?: number|undefined,
    index?: number|undefined,
    sx?: SxProps<Theme>|undefined,
    onDelete: (index: number) => void,
}> = props => {
    return (
        <Paper elevation={props.elevation} sx={{ maxWidth: 'max-content', padding: 2, ...props.sx }}>
            <Stack direction='row' alignItems='center'>
                <Box paddingRight={3}>
                    <Typography variant='h6' component='p'>
                        {props.website}
                        <Typography variant='h6' component='span' paddingLeft={1}>
                            {`\u00B7 ${props.category}`}
                        </Typography>
                    </Typography>
                    <Typography color='text.secondary' variant='caption' gutterBottom>
                        {props.thematic}
                    </Typography>
                </Box>
                <Button size='small' onClick={() => props.onDelete(props.index ?? 0)}>
                    {`Supprimer`}
                </Button>
            </Stack>
        </Paper>
    );
};

export default DestinationWebsite;
