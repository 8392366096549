import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import { Button, Stack, Divider } from '@mui/material';
import { Form, Formik } from 'formik';
import FormikSelect from '../FormikComponent/FormikSelect';
import FormikSlider from '../FormikComponent/FormikSlider';
import GenericModal from './GenericModal';
import IGenerationParams from '../../interfaces/IGenerationParams';
import GenerationEngineOptions from '../../interfaces/GenerationEngineOptions';

const ModalTweakGeneration: React.FC<{
    title: string,
    name?: string|undefined,
    width?: number|string|undefined,
    onValidation: (params: IGenerationParams) => void,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'tweakGenerationModal');

    // Use of redux
    const dispatch = useAppDispatch();

    // Define form initial values
    const initialValues: any = {
        offset: parseInt(process.env.REACT_APP_AI_PROMPT_OFFSET!),
        maxTokens: 800,
        temperature: 0.3,
        frequencyPenalty: 1,
        presencePenalty: 1,
        engine: { value: 'text-curie-001' },
    };

    // Callback to handle form submission
    const handleFormikSubmit = (values: any) => {
        props.onValidation({
            ...values,
            engine: values.engine.value,
        });
        dispatch(closeModal());
    };

    return (
        <GenericModal
            name={modalName.current}
            title={props.title}
            width={props.width ?? 3/7}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={handleFormikSubmit}
            >
                {({ values }) => (
                    <Form>
                        <Stack rowGap={3} paddingX={2} overflow='hidden auto' maxHeight={500}>
                            <FormikSelect
                                name='engine'
                                label='Moteur de génération'
                                options={GenerationEngineOptions}
                                helperText={
                                    values.engine.value.includes('davinci') ?
                                        'Davinci est un algorithme plus efficace mais il est également plus cher'
                                        : 'Curie est un algorithme peu efficace mais également peu couteux'
                                }
                            />
                            <FormikSlider
                                name='offset'
                                max={1000}
                                min={10}
                                step={10}
                                label='Offset dans votre texte'
                                helperText='Nombre de caractères à prendre depuis votre texte (en partant de la fin).'
                            />
                            <FormikSlider
                                name='maxTokens'
                                max={2000}
                                min={10}
                                step={10}
                                label='Taille du contenu généré'
                                helperText='Plus la valeur est élevée, plus le contenu généré sera conséquent.'
                            />
                            <FormikSlider
                                name='temperature'
                                max={1}
                                step={0.1}
                                label='Niveau de créativité'
                                helperText='Niveau de créativité du contenu généré.'
                            />
                            <FormikSlider
                                name='frequencyPenalty'
                                max={2}
                                min={-2}
                                step={0.1}
                                label='Pénalité de fréquence'
                                helperText='Probabilité que le contenu généré parle du même sujet.'
                            />
                            <FormikSlider
                                name='presencePenalty'
                                max={2}
                                min={-2}
                                step={0.1}
                                label='Pénalité de présence'
                                helperText='Probabilité que le contenu généré se répéte dans le vocabulaire employé.'
                            />
                        </Stack>
                        <Divider sx={{ marginY: 3 }}/>
                        <Stack direction='row' justifyContent='space-between'>
                            <Button variant='outlined' color='secondary' onClick={() => dispatch(closeModal())}>
                                {`Annuler`}
                            </Button>
                            <Button type='submit' variant='outlined'>
                                {`Valider`}
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </GenericModal>
    );
};

export default ModalTweakGeneration;
