import React from 'react';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import { LoadingButton } from '@mui/lab';
import {
    Stack,
    Typography,
    FormLabel,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@mui/material';

const ParaphraseTool: React.FC = () => {
    // Use of hooks
    const [text, setText] = React.useState<string>('');
    const [generation, setGeneration] = React.useState<boolean>(false);
    const [response, setResponse] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    // Function to handle API call
    const handleClickButton = (text: string) => {
        // Load during API call
        setLoading(true);
        // Reset previous result
        setResponse(null);
        // Call API to paraphrase our text
        BridgeAPIs.getOpenAiParaphrase({ prompt: text, generation })
            // On successful API call
            .then((response: any) => {
                if (response) {
                    setLoading(false);
                    setResponse(response);
                }
            });
    };

    return (
        <>
            <FormLabel>
                {`Texte à paraphraser`}
            </FormLabel>
            <TextField
                fullWidth
                multiline
                rows={10}
                value={text}
                disabled={loading}
                onChange={(event) => setText(event.target.value)}
            />
            <FormControlLabel control={<Checkbox checked={generation} onChange={e => setGeneration(e.target.checked)} />} label='Avec génération' />
            <LoadingButton
                variant='contained'
                loading={loading}
                disabled={0 === text.length}
                onClick={() => handleClickButton(text)}
                sx={{ marginY: 2 }}
            >
                {`Paraphraser le texte`}
            </LoadingButton>
            {
                response && (
                    <Stack gap={2}>
                        <Typography variant='body2'>
                            {`Taux de similitude : `}
                            <Typography fontWeight={700} component='span'>
                                {`${response.levenshtein} %`}
                            </Typography>
                        </Typography>
                        <Typography variant='body2'>
                            {response.text}
                        </Typography>
                        <Typography variant='caption' color='secondaryText'>
                            {`Prix de l'appel : ${response.price}`}
                        </Typography>
                    </Stack>
                )
            }
        </>
    )
};

export default ParaphraseTool;
