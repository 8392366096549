import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    flexTypography: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        flexWrap: 'wrap',
    },
};

export default cssStyles;
