import React from 'react';
import moment from 'moment-timezone';
import { Field } from 'react-final-form';
import {
    SxProps,
    Theme,
    FormControl,
    TextField,
    IconButton,
} from '@mui/material';
import { DateTimePickerProps } from '@mui/lab';
import { Close as CloseIcon } from '@mui/icons-material';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import FinalFieldMessage from './FinalFieldMessage';
import FinalUpperLabel from './FinalUpperLabel';

type FinalDatePickerProps = {
    name: string, // force this prop to not be undefined
    size?: 'small'|'medium'|undefined,
    helperText?: React.ReactNode|undefined,
    upperLabel?: boolean|undefined,
    fullWidth?: boolean|undefined,
    sx?: SxProps<Theme>|undefined,
    wrapperSx?: SxProps<Theme>|undefined,
};

const FinalDatePicker: React.FC<FinalDatePickerProps&Omit<DateTimePickerProps<Date>, 'onChange'|'value'|'renderInput'>> =
({ label, upperLabel, sx, wrapperSx, ...props }) => {
    // Callback to handle date change
    const handleChange = (date: Date|null, onChange: (value: string) => void) => {
        if (date && moment(date).isValid()) {
            if (undefined !== props.views && props.views.includes('hours')) {
                // Set minutes and seconds to 0
                date.setHours(date.getHours(), 0, 0, 0);
            }
            onChange(moment(date).format());
        } else {
            onChange('');
        }
    };

    return (
        <FormControl fullWidth={props.fullWidth} sx={{ alignItems: 'flex-start', ...wrapperSx }}>
            {label && upperLabel && (<FinalUpperLabel name={props.name} label={label} />)}
            <Field
                name={props.name}
                allowNull
                subscription={{ value: true, error: true, touched: true }}
                render={({ input, meta: { error, touched }}) => (
                    <DateTimePicker
                        {...input}
                        {...props}
                        label={!upperLabel ? label : undefined}
                        renderInput={(forwardProps) => (
                            <TextField
                                {...forwardProps}
                                value={moment(input.value).toDate()}
                                disabled
                                InputProps={{
                                    ...forwardProps.InputProps,
                                    placeholder: 'jj/mm/aaaa hh:mm',
                                    endAdornment: (
                                        <>
                                            {
                                                input.value && (
                                                    <IconButton size='small' onClick={() => input.onChange('')}>
                                                        <CloseIcon fontSize='inherit' />
                                                    </IconButton>
                                                )
                                            }
                                            {forwardProps.InputProps?.endAdornment ?? null}
                                        </>
                                    )
                                }}
                                onChange={(event) => handleChange(moment(event.target.value).toDate(), input.onChange)}
                                size={props.size}
                                error={error && touched}
                                sx={sx}
                            />
                        )}
                        onChange={(date) => handleChange(date, input.onChange)}
                    />
                )}
            />
            <FinalFieldMessage name={props.name} helperText={props.helperText ?? "Sélectionner une date en cliquant sur l'icône à la fin du champ."} />
        </FormControl>
    );
};

export default FinalDatePicker;
