import React from 'react';
import moment from 'moment-timezone';
import stringToIcon from '../../configs/stringToIcon';
import {
    Grid,
    Stack,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Divider,
    Paper
} from '@mui/material';
import DisplayCard from '../DisplayCard/DisplayCard';
import DisplayContent from '../DisplayContent/DisplayContent';
import RouterLink from '../RouterLink/RouterLink';
import SummaryDestinationWebsite from './SummaryDestinationWebsite';
import SummaryEditor from './SummaryEditor';
import IUser from '../../models/IUser';
import IDuplicateOrder from '../../interfaces/IDuplicateOrder';
import ILink from '../../interfaces/ILink';
import IRadioOption from '../../interfaces/IRadioOption';
import ISelectOption from '../../interfaces/ISelectOption';
import IDestinationWebsite from '../../interfaces/IDestinationWebsite';
import cssStyles from './OrderSummaryStyles';

const OrderSummary: React.FC<{
    activitySector: ISelectOption,
    keyword: ISelectOption,
    syntaxLevel: ISelectOption,
    postType: ISelectOption,
    imageChoice: IRadioOption,
    privacy: IRadioOption,
    imageUploaded?: string|undefined,
    imageBank?: string|undefined,
    imageKeyword?: string|undefined,
    title?: string|undefined,
    mandatoryLinks?: Array<ILink>|undefined,
    minNbrWords?: number|undefined,
    freeOptimization?: boolean|undefined,
    minOptimization?: number|undefined,
    metaDesc?: string|undefined,
    destinationWebsite?: IDestinationWebsite|undefined,
    price?: number|undefined,
    publicChoice?: IRadioOption|undefined,
    editor?: IUser|undefined,
    autoPublish?: boolean|undefined,
    toPublishAt?: string|undefined,
    deadlineAt?: string|undefined,
    brief?: string|undefined,
    shares?: Array<IUser>|undefined,
    duplicates?: Array<IDuplicateOrder>|undefined,
}> = props => {
    // Retrieve icon component from activitySector
    const Icon = stringToIcon(props.activitySector.icon!);

    return (
        <Stack spacing={4}>
            <Card>
                {
                    // If order has an image, display a CardMedia
                    ('file' === props.imageChoice.value || 'bank' === props.imageChoice.value) && (
                        <CardMedia
                            component='img'
                            height='140'
                            image={props.imageUploaded ?? props.imageBank ?? undefined}
                        />
                    )
                }
                {
                    // Display defined image keyword
                    'kw' === props.imageChoice.value && (
                        <CardContent>
                            <Typography align='center'>
                                {`Mot clé de l'image à la une : `}
                                <Typography component='span' color='primary'>
                                    {props.imageKeyword}
                                </Typography>
                            </Typography>
                        </CardContent>
                    )
                }
                {
                    // If user chose to not define order image
                    'none' === props.imageChoice.value && (
                        <CardContent>
                            <Typography align='center' color='primary'>
                                {`Image à la une définie par le rédacteur`}
                            </Typography>
                        </CardContent>
                    )
                }
                {
                    // Display Divider if no CardMedia rendered in previous CardContent
                    ('kw' === props.imageChoice.value || 'none' === props.imageChoice.value) && (
                        <Divider />
                    )
                }
                <CardContent>
                    {/* Display order keyword */}
                    <Typography variant='h6'>
                        {`Mot clé de l'article : `}
                        <Typography variant='h6' component='span' color='primary'>
                            {props.keyword.label}
                        </Typography>
                    </Typography>
                    {/* Display order activity sector */}
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <Typography variant='h6'>
                            {`Secteur d'activité de l'article : `}
                        </Typography>
                        <Icon color='primary' />
                        <Typography variant='h6' color='primary'>
                            {props.activitySector.label}
                        </Typography>
                    </Stack>
                    {/* Display order title */}
                    <Typography variant='h5' color='primary' fontWeight='700' paddingTop={3}>
                        {
                            null === props.title || '' === props.title ?
                                `Titre défini par le rédacteur` : props.title
                        }
                    </Typography>
                    {/* Fake order body */}
                    <Typography component='p' variant='h6' color='text.secondary' sx={cssStyles.fakePostBody}>
                        {`Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                        Ducimus id autem atque expedita repellendus dolores pariatur temporibus delectus nemo
                        amet nesciunt consectetur architecto rerum, suscipit nisi porro reiciendis modi.
                        Officiis perspiciatis blanditiis minima suscipit ut beatae, quod dignissimos molestiae
                        impedit asperiores vel facilis non facere quis eius ipsum! Corporis esse obcaecati nobis
                        blanditiis qui, odio repudiandae libero fuga! Asperiores odit et ea fugiat repellendus
                        numquam nisi ipsa tempora explicabo debitis, voluptatibus molestias quas aut fuga accusamus
                        officia commodi ab ipsum iure ratione earum rem sequi! Laudantium, distinctio modi? Iure
                        voluptatem aut perspiciatis rerum vel odio nisi odit amet, explicabo voluptate praesentium,
                        recusandae officia tempore, quis facilis culpa possimus? Sequi praesentium ipsam natus nostrum vel
                        pariatur odit voluptatem facilis soluta est.`}
                    </Typography>
                    {/* Display syntax level */}
                    <Typography>
                        {`Niveau d'orthographe pour le rédaction : `}
                        <Typography component='span' color='primary'>
                            {props.syntaxLevel.label}
                        </Typography>
                    </Typography>
                    {/* Dispay links */}
                    <Typography>
                        {`Lien(s) dans le corps de l'article : `}
                        {
                            props.mandatoryLinks && 0 < props.mandatoryLinks.length ? (
                                props.mandatoryLinks.map((link: ILink, index: number) => (
                                    <React.Fragment key={index}>
                                        <RouterLink
                                            to={link.url}
                                            anchor={link.anchor}
                                            target='_blank'
                                            sx={cssStyles.summaryLinks}
                                        />
                                        {
                                            // Add comma after link
                                            index < props.mandatoryLinks!.length - 1 && (
                                                <Typography component='span'>{`, `}</Typography>
                                            )
                                        }
                                    </React.Fragment>
                                ))
                            ) : (
                                <Typography component='span' color='primary'>
                                    {`Non renseigné`}
                                </Typography>
                            )
                        }
                    </Typography>
                    {/* Display the defined minimum number of words */}
                    <Typography>
                        {`Nombre de mots requis : `}
                        <Typography component='span' color='primary'>
                            {props.minNbrWords}
                        </Typography>
                    </Typography>
                    {/* Display the defined minimum optimization score */}
                    <Typography>
                        {`Score d'optimisation requis : `}
                        <Typography component='span' color='primary'>
                            {false === props.freeOptimization ? `${props.minOptimization}` : `Non renseigné`}
                        </Typography>
                    </Typography>
                    <Typography paddingBottom={2}>
                        {`Meta description : `}
                        <Typography component='span' color='primary'>
                            {props.metaDesc ? props.metaDesc : `Meta description définie par le rédacteur`}
                        </Typography>
                    </Typography>

                    <Typography>
                        {`Type de l'article : `}
                        <Typography component='span' color='primary'>
                            {props.postType.label}
                        </Typography>
                    </Typography>
                    {
                        // Display destination information
                        props.destinationWebsite && (
                            <>
                                <Typography>
                                    {`Site de destination : `}
                                    <Typography component='span' color='primary'>
                                        {props.destinationWebsite.website.label}
                                    </Typography>
                                </Typography>
                                <DisplayContent>
                                    <Typography>
                                        {`Thématique : `}
                                        <Typography component='span' color='primary'>
                                            {props.destinationWebsite.thematic.label}
                                        </Typography>
                                    </Typography>
                                    <Typography>
                                        {`Catégorie : `}
                                        <Typography component='span' color='primary'>
                                            {props.destinationWebsite.category.name}
                                        </Typography>
                                    </Typography>
                                </DisplayContent>
                            </>
                        )
                    }
                    {/* Display the order visibility */}
                    <Typography>
                        {`Visibilité de la commande : `}
                        <Typography component='span' color='primary'>
                            {props.privacy.label}
                        </Typography>
                    </Typography>
                    {
                        'public' === props.privacy.value && (
                            <DisplayContent>
                                {/* Display the order price */}
                                <Typography>
                                    {`Coût de rédaction de l'article : `}
                                    <Typography component='span' color='primary'>
                                        {`${props.price} € HT`}
                                    </Typography>
                                </Typography>
                                {/* Display the order visibility */}
                                <Typography>
                                    {`Choix de selection du rédacteur : `}
                                    <Typography component='span' color='primary'>
                                        {props.publicChoice?.label}
                                    </Typography>
                                </Typography>
                            </DisplayContent>
                        )
                    }
                    {
                        // Only show editor if privacy is private or privacy is public and public choice is propose
                        props.editor && ('private' === props.privacy.value || ('public' === props.privacy.value && 'propose' === props.publicChoice?.value)) && (
                            <DisplayContent>
                                {/* Display the selected editor */}
                                <SummaryEditor editor={props.editor} deadline={props.deadlineAt} />
                            </DisplayContent>
                        )
                    }
                    <Typography>
                        {`Validation de l'article : `}
                        {
                            <Typography component='span' color='primary'>
                                {
                                    // If post has a destination website
                                    props.destinationWebsite && props.autoPublish ? 'Automatique' : 'Manuelle'
                                }
                            </Typography>
                        }
                    </Typography>
                    <Typography>
                        {`Publication : `}
                        {
                            <Typography component='span' color='primary'>
                                {
                                    // If post has a publication date
                                    props.toPublishAt ?
                                        `le ${moment(props.toPublishAt).format('DD/MM/Y à HH:mm')}`
                                        : props.autoPublish ? 'Automatique' : 'Non renseigné'
                                }
                            </Typography>
                        }
                    </Typography>
                </CardContent>
            </Card>
            {
                props.brief && (
                    <Grid item xs={12}>
                        {/* Display the defined brief */}
                        <Paper sx={cssStyles.secondaryCard}>
                            <Typography>
                                {`Instructions données au rédacteur pour rédiger l'article : `}
                                <Typography component='span' color='primary'>
                                    {props.brief}
                                </Typography>
                            </Typography>
                        </Paper>
                    </Grid>
                )
            }
            {
                props.shares && 0 < props.shares.length && (
                    // Display shared users
                    <Paper sx={cssStyles.secondaryCard}>
                        <Typography variant='body1'>
                            {`La commande sera partagée avec les utilisateurs suivants :`}
                        </Typography>
                        <Stack direction='row' paddingTop={2} spacing={2}>
                            {
                                props.shares.map((user: IUser) => (
                                    <DisplayCard
                                        key={user.id}
                                        elevation={4}
                                        title={`${user.name} ${user.lastname}`}
                                        image={user.avatar ?? undefined}
                                    />
                                ))
                            }
                        </Stack>
                    </Paper>
                )
            }
            {
                props.duplicates && 0 < props.duplicates.length && (
                    // Display the defined duplicate orders /
                    <Paper sx={cssStyles.secondaryCard}>
                        <Typography paddingBottom={2}>
                            {`Duplication(s) de la commande :`}
                        </Typography>
                            <Stack spacing={2}>
                                {
                                    props.duplicates.map((duplicate: IDuplicateOrder, index: number) => (
                                            <Card key={index} elevation={2} sx={cssStyles.secondaryCard}>
                                                <Typography variant='h5' component='p' paddingBottom={3}>
                                                    {`Commande dupliquée `}
                                                    <Typography
                                                        variant='h5'
                                                        color='primary'
                                                        component='span'
                                                        fontWeight={700}
                                                   >
                                                        {`n°${index + 1}`}
                                                    </Typography>
                                                </Typography>
                                                <Grid container columnSpacing={3}>
                                                    <Grid item xs={12} lg={6}>
                                                        {/* Display duplicate destination site if it exists */}
                                                        <SummaryDestinationWebsite
                                                            destinationWebsite={duplicate.destinationWebsite}
                                                            autoPublish={duplicate.autoPublish}
                                                            toPublishAt={duplicate.toPublishAt}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        {/* Display the order visibility */}
                                                        <Typography>
                                                            {`Visibilité de la commande : `}
                                                            <Typography component='span' color='primary'>
                                                                {'public' === duplicate.privacy?.value ? 'Publique' : 'Privée'}
                                                            </Typography>
                                                        </Typography>
                                                        {/* Display defined editor of the duplicate order  */}
                                                        <SummaryEditor editor={duplicate.editor} />
                                                    </Grid>
                                                </Grid>
                                        </Card>
                                    ))
                                }
                            </Stack>
                    </Paper>
                )
            }
        </Stack>
    );
};

export default OrderSummary;
