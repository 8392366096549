import React from 'react';
import * as Yup from 'yup';
import { FormSpy } from 'react-final-form';
import useValidationSchema from '../../hooks/useValidationSchema';
import { Button, Divider, Stack, Typography } from '@mui/material';
import FinalInput from '../FinalComponents/FinalInput';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import FinalForm from '../FinalForm/FinalForm';
import GenerationOptions from './GenerationOptions';
import IPostAiGeneration from '../../interfaces/IPostAiGeneration';

const GenerationForm: React.FC<{
    defaultValues: IPostAiGeneration|null,
    onSubmit: (values: IPostAiGeneration, generationType?: 'outline'|'custom'|undefined) => void
}> = props => {
    // Define yup validation schema for final form
    const validationSchema = useValidationSchema({
        keyword: Yup
            .string()
            .required('Veuillez spécifier un mot-clé.')
            .min(3, 'Le mot-clé ne peut pas contenir moins de 3 caratères.'),
        estimatedNbrWords: Yup
            .number()
            .nullable()
            .min(100, 'Le nombre de mots ne peut pas être inférieur à 100.')
            .max(5000, 'Le nombre de mots ne peut pas être supérieur à 5000.'),
    });

    return (
        <FinalForm<IPostAiGeneration>
            initialValues={props.defaultValues ?? {
                keyword: '',
                optimized: false,
                estimatedNbrWords: null,
                h1: null,
            }}
            validateSchema={validationSchema}
            onSubmit={(values) => props.onSubmit(values)}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            gap={2}
        >
            <FinalInput
                name='keyword'
                size='small'
                fullWidth
                upperLabel
                label='Expression clé'
            />
            <FinalInput
                name='h1'
                size='small'
                fullWidth
                upperLabel
                label='Titre H1 (optionnel)'
                helperText="Vous pouvez définir le titre H1 pour votre article. La génération de l'article se fera autour de ce sujet."
            />
            <Divider flexItem sx={{ marginY: 1 }} />
            <Typography variant='h6'>{`Options de génération`}</Typography>
            <GenerationOptions />
            <Stack direction='row' columnGap={2}>
                <FinalSubmitButton variant='contained' enabledOnPristine>
                    {`Générer l'article`}
                </FinalSubmitButton>
                <FormSpy<IPostAiGeneration>
                    subscription={{ valid: true, values: true }}
                    render={({ valid, values }) => (
                        <>
                            <Button
                                variant='contained'
                                disabled={!valid || (!values.estimatedNbrWords && !Boolean(values.optimized))}
                                onClick={() => props.onSubmit(values, 'outline')}
                            >
                                {`Générer le plan de l'article`}
                            </Button>
                            <Button
                                variant='contained'
                                disabled={!valid || Boolean(values.estimatedNbrWords)}
                                onClick={() => props.onSubmit(values, 'custom')}
                            >
                                {`Créer un plan personnalisé`}
                            </Button>
                        </>
                    )}
                />
            </Stack>
        </FinalForm>
    );
};

export default GenerationForm;
