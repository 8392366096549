import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    paper: {
        backgroundColor: 'white',
        width:400,
        height : 202,
        borderRadius: '16px',
        padding: '14px 18px',
        border: 'none',
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'box-shadow ease-in-out .35s, transform ease-in-out .35s',
        // Simplify animation to prevent blur effect during scaling (at least on mozilla)
        backfaceVisibility: 'hidden',
        '&:hover': {
            boxShadow: '0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13)',
            transform: 'scale(1.03)',
        },
        '&:active':{
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.13)',
            transform: 'scale(1.0)',
        },
        '& svg' : {
            width: 80,
            height: 80,
        },
    },
    paperNotAllowed: {
        opacity: .5,
        color: 'text.disabled',
        backgroundColor: 'action.disabledBackground',
        borderColor: 'text.primary',
        cursor: 'not-allowed',
        filter: 'grayscale(90%)',
        '&:hover': {
            borderColor: 'normal',
        },
    }
};

export default cssStyles;
