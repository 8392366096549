import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import SiteAPIs from '../../APIs/SiteAPIs';
import Tools from '../../helpers/Tools';
import { Grid, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ThematicSelection from '../ThematicSelection/ThematicSelection';
import FormikInputSelectGroup from '../FormikComponent/FormikInputSelectGroup';
import FormikInput from '../FormikComponent/FormikInput';
import FormikCheckbox from '../FormikComponent/FormikCheckbox';
import SiteCategories from './SiteCategories';
import ISite from '../../models/ISite';

const SiteForm: React.FC = () => {
    // Use of redux
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Use react-router-dom hooks
    const navigate = useNavigate();
    const location = useLocation();
    const state: ISite|null = location.state as ISite|null;

    // Define initial values for formik form
    const initialValues: any = {
        thematic: state ? { value: state.thematic.id, label: state.thematic.name } : null,
        isHttps: state ? (state.isHttps ? 'https://' : 'http://') : 'https://',
        domain: state ? state.domain : '',
        login: state ? state.login : '',
        password: state ? state.password : '',
        passwordApp: state && state.passwordApp ? state.passwordApp : '',
        isOnline: state ? state.isOnline : true,
    };

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        thematic: Yup
            .object()
            .nullable()
            .required('Veuillez renseigner une thématique pour le site'),
        domain: Yup
            .string()
            .required('Veuillez renseigner le nom de domaine du site'),
        login: Yup
            .string()
            .required('Veuillez renseigner un identifiant pour le site'),
        password: Yup
            .string()
            .required("Veuillez renseigner un mot de passe d'authentification"),
    });

    // Callback after submission on formik values to create or update a Site
    const thenApiCall = React.useCallback((data: ISite|null, formikHelpers: FormikHelpers<any>) => {
        // Redirect to Sites listing or Formik form is no longer submitting
        null !== data ? navigate('..') : formikHelpers.setSubmitting(false);
    }, [navigate]);

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback((values: any, formikHelpers: FormikHelpers<any>) => {
        // Define body request in POST or PATCH case
        const bodyRequest = Tools.convertToBodyRequest({
            account: instanceAccountId,
            user: instanceUserId,
            thematic: values.thematic.value,
            isHttps: 'https://' === values.isHttps,
            isOnline: values.isOnline,
            domain: values.domain,
            login: values.login,
            password: values.password,
            passwordApp: values.passwordApp,
        });

        !state ?
            // Call API to create & add Site under instance account
            SiteAPIs.postMultipleSite([bodyRequest])
                // On successful API call
                .then((data) => thenApiCall(null !== data && 1 === data.length ? data[0] : null, formikHelpers))
            // Call API to update Site under instance account
            : SiteAPIs.patchSite(state.id, bodyRequest)
                // On successful API call
                .then((data: ISite|null) => thenApiCall(data, formikHelpers))
    }, [instanceAccountId, instanceUserId, state, thenApiCall]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form>
                    <ThematicSelection formik />
                    <FormikInputSelectGroup
                        selectName='isHttps'
                        inputName='domain'
                        upperLabel='Nom de domaine du site'
                        size='medium'
                        placeholder='Exemple: mon-site.fr'
                        options={[
                            { value: 'https://', label: 'https://'},
                            { value: 'http://', label: 'http://' },
                        ]}
                        sx={{ paddingBottom: 3 }}
                    />
                    <Grid container spacing={4} paddingBottom={3}>
                        <Grid item xs={12} md={6}>
                            <FormikInput
                                name='login'
                                upperLabel='Identifiant utilisateur'
                                helperText='Utilisé pour vous connecter au site'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} paddingBottom={3}>
                        <Grid item xs={12} md={6}>
                            <FormikInput
                                name='password'
                                type='password'
                                autoComplete={false}
                                upperLabel="Mot de passe d'authentification"
                                helperText='Le mot de passe utilisé pour vous connecter sur le site'
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormikInput
                                name='passwordApp'
                                type='password'
                                placeholder='Optionnel si WordPress <= 5.6'
                                autoComplete={false}
                                upperLabel="Mot de passe d'application"
                                helperText="Un mot de passe d'application configuré sur votre site (requis si WordPress >= 5.6)"
                                helperTextLink={{
                                    target: 'blank',
                                    anchor: "Comment générer un mot de passe d'application sur WordPress ?",
                                    url: 'https://wordpress.com/fr/support/securite/authentification-en-deux-etapes/#mots-de-passe-specifiques-aux-applications',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <FormikCheckbox
                        name='isOnline'
                        label='Le site est en ligne et accessible'
                    />
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    {
                        formikProps.values.isOnline ? (
                            <SiteCategories
                                forCreation={undefined === state}
                                formikProps={formikProps}
                            />
                        ) : (
                            <LoadingButton
                                disabled={!formikProps.isValid}
                                loading={formikProps.isSubmitting}
                                variant='contained'
                                type='submit'
                            >
                                {state ? `Modifier le site` : `Ajouter le site`}
                            </LoadingButton>
                        )
                    }
                </Form>
            )}
        </Formik>
    );
};

export default SiteForm;
