import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import Ipv4APIs from '../../APIs/Ipv4APIs';
import {
    Grid,
    Typography,
    Button,
    FormHelperText,
    Divider,
} from '@mui/material';
import ModalSingleInput from '../Modal/ModalSingleInput';
import RouterLink from '../RouterLink/RouterLink';

const Ipv4Management: React.FC = () => {
    // Use of redux
    const dispatch = useAppDispatch();

    // Callback used to create a new Ipv4 adresse using our API
    const handleAddIpv4s = React.useCallback((values: Array<string>) => {
        // Call API to post a new Ipv4
        Ipv4APIs.postIpv4({ ipv4s: values })
        // Alert on success
        .then((data: any) => data && alert(values.length + ' IPv4(s) ajoutée(s).'))
    }, [])

    // Callback used to manage loaded file
    const handleFileLoaded = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        // Check if files array is not empty
        if (null !== event.currentTarget.files) {
            // Read file content
            const file = event.currentTarget.files[0];
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = () => {
                const content = reader.result && reader.result.toString().trim().split("\n");
                // Call API to add these new Ipv4s
                content && handleAddIpv4s(content);
            };
        }
    }, [handleAddIpv4s])

    return (
        <>
            <ModalSingleInput
                name='addIpv4Modal'
                modalTitle='Nouvelle adresse IPv4'
                inputLabel='Adresse IPv4'
                onValidation={(value: string) => handleAddIpv4s([value])}
            />
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Typography variant='h5' gutterBottom>
                        {`Ajouter une nouvelle adresse IPv4`}
                    </Typography>
                    <Typography variant='body2'>
                        {`Ces adresses IPv4 seront enregistrées en base de données et sur notre WHM. Elles sont utilisées pour créer
                        les WordPress des clients ayant choisi notre système d'hébergement. Chaque adresse IPv4
                        est relié à un compte cPanel sur `}
                        <RouterLink
                            anchor={`le WHM`}
                            to={process.env.REACT_APP_WHM_URL!}
                            target='_blank'
                        />
                    </Typography>
                </Grid>
                <Grid
                    container item
                    flexDirection='column' alignItems='flex-start' justifyContent='center'
                    xs={12} md={8}
                >
                    <Button
                        variant='outlined'
                        onClick={() => dispatch(openModal({ name: 'addIpv4Modal' }))}
                    >
                        {`Ajouter une adresse IPv4`}
                    </Button>
                    <Button
                        variant='outlined'
                        component='label'
                        sx={{ marginTop: 3 }}
                    >
                        {`Charger un fichier .txt`}
                        <input type='file' accept='text/plain' onChange={(event) => handleFileLoaded(event)} hidden />
                    </Button>
                    <FormHelperText>
                        {`Le fichier doit contenir une adresse IPv4 par ligne`}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Divider variant='middle' sx={{ marginY: 4 }} />
        </>
    )
};

export default Ipv4Management;
