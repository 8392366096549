import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { openModal } from '../../store/slices/activeModalSlice';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import {
    Grid,
    Typography,
    Button,
} from '@mui/material';
import FormikRadio from '../FormikComponent/FormikRadio';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import ImagePreview from '../ImagePreview/ImagePreview';
import ModalPixabayPicker from '../Modal/ModalPixabayPicker';
import KeywordDisplay from '../KeywordDisplay/KeywordDisplay';
import IOrder from '../../models/IOrder';
import IPostFormStepperData from '../../interfaces/IPostFormStepperData';

const ImageSelection: React.FC<{ order: IOrder, withConstraints: boolean, formikFormId: string }> = props => {
    // Define max image size when preview
    const maxPreviewSize: string|number = 500;

    // Use of redux
    const dispatch = useAppDispatch();
    const postFormStepperData: IPostFormStepperData = useAppSelector(state => state.activeStepper.data);

    // Define unaware initial values to reset form values
    const unawareInitialValues = React.useRef<any>({
        imageChoice: {
            value: 'file',
            label: 'Charger une image à la une depuis mes fichiers',
        },
        imageUrl: null,
    });

    // Define initial values for formik form
    const initialValues: any = {
        imageChoice: {
            value: 'file',
            label: 'Charger une image à la une depuis mes fichiers',
        },
        imageUrl: postFormStepperData.imageUrl ?? props.order.imageUrl ?? null,
    };

    // Validation scheme of the formik form
    const validationSchema = Yup.object({
        imageUrl: Yup
            .mixed()
            .required('Veuillez sélectionner une image'),
    })

    // Callback use to reset formik fields to their initial value
    const resetFormikFields = React.useCallback((formikProps: FormikProps<any>, fields: Array<string>) => {
        // Use pre-build formik function
        fields.forEach((field) => formikProps.setFieldValue(field, unawareInitialValues.current[field]));
    }, []);

    // Callback to hande formik submit
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Store values under data field of active stepper redux state
        // & increment active step
        dispatch(setActiveStepperData({ data: values, action: 'inc' }));
    }, [dispatch]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form id={props.formikFormId}>
                    <KeywordDisplay keyword={props.order.keyword.name}/>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h5' gutterBottom>
                                {`Image de l'article`}
                            </Typography>
                            <Typography variant='body2'>
                                {`Choisissez une image en rapport avec le mot clé de l'article.
                                Le choix de l'image est obligatoire. Elle deviendra l'image à la une
                                de votre article sur votre site.`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {
                                // If URL of image is defined on Post
                                formikProps.values.imageUrl ? (
                                    <ImagePreview
                                        closable={props.order.imageUrl && props.withConstraints ? false : true}
                                        imageSrc={formikProps.values.imageUrl}
                                        maxSize={maxPreviewSize}
                                        onPreviewClose={() => resetFormikFields(formikProps, ['imageUrl'])}
                                    />
                                // Else, keyword of image was defined during order creation
                                ) : null !== props.order.imageKeyword ? (
                                        <>
                                            <Button
                                                variant='outlined'
                                                color='secondary'
                                                onClick={() => dispatch(openModal({ name: 'pixabayPicker' }))}
                                            >
                                                {`Choisir une image depuis Pixabay`}
                                            </Button>
                                            <ModalPixabayPicker
                                                defaultKeyword={props.order.imageKeyword}
                                                onValidation={(imageSrc: string) => formikProps.setFieldValue('imageUrl', imageSrc)}
                                            />
                                        </>
                                // Nothing specified about image during order creation
                                ) : (
                                    <>
                                        <FormikRadio
                                            name='imageChoice'
                                            radio={[
                                                {
                                                    value: 'file',
                                                    label: 'Charger une image à la une depuis mes fichiers',
                                                },
                                                {
                                                    value: 'bank',
                                                    label: "Choisir une image à la une depuis une banque d'images",
                                                },
                                            ]}
                                            onChange={() => resetFormikFields(formikProps, ['imageUrl'])}
                                            sx={{ paddingBottom: 1 }}
                                        />
                                        {
                                            'file' === formikProps.values.imageChoice.value ? (
                                                <ImageDropzone
                                                    error={Boolean(formikProps.errors.imageUrl)}
                                                    maxImageWeight={800}
                                                    maxPreviewSize={maxPreviewSize}
                                                    keepFileOnUnmount
                                                    sx={{ height: 'unset' }}
                                                    onDrop={(_, imageBlobUrl: string) => formikProps.setFieldValue('imageUrl', imageBlobUrl)}
                                                    onPreviewClose={() => resetFormikFields(formikProps, ['imageUrl'])}
                                                />
                                            ) : 'bank' === formikProps.values.imageChoice.value && (
                                                <>
                                                    <Button
                                                        variant='outlined'
                                                        color='secondary'
                                                        onClick={() => dispatch(openModal({ name: 'pixabayPicker' }))}
                                                    >
                                                        {`Choisir une image depuis Pixabay`}
                                                    </Button>
                                                    <ModalPixabayPicker
                                                        defaultKeyword={props.order.keyword.name}
                                                        onValidation={(imageSrc: string) => formikProps.setFieldValue('imageUrl', imageSrc)}
                                                    />
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                Boolean(formikProps.errors.imageUrl) && (
                                    <Typography color='error' component='p' variant='caption' paddingTop={1}>
                                        {formikProps.errors.imageUrl}
                                    </Typography>
                                )
                            }
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default ImageSelection;
