import React from 'react';
import moment from 'moment-timezone';
import useValidationSchema from '../../hooks/useValidationSchema';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Field } from 'react-final-form';
import PostAPIs from '../../APIs/PostAPIs';
import Tools from '../../helpers/Tools';
import {
    Grid,
    Typography,
    Divider,
} from '@mui/material';
import FinalForm from '../FinalForm/FinalForm';
import FinalInput from '../FinalComponents/FinalInput';
import FinalDatePicker from '../FinalComponents/FinalDatePicker';
import SelectProjectKeyword from '../SelectEntities/SelectProjectKeyword';
import SelectActivitySector from '../SelectEntities/SelectActivitySector';
import SelectProjectPostType from '../SelectEntities/SelectProjectPostType';
import TextEditor from '../TextEditor/TextEditor';
import DestinationPicker from '../DestinationPicker/DestinationPicker';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import ISelectOption from '../../interfaces/ISelectOption';
import IWpCategory from '../../interfaces/IWpCategory';

type ImportPostFormType = {
    keyword: ISelectOption|null,
    title: string,
    metaDesc: string,
    imageUrl: string,
    activitySector: ISelectOption|null,
    postType: ISelectOption|null,
    html: string,
    thematic: ISelectOption|null,
    website: ISelectOption|null,
    category: ISelectOption|null,
    postUrl: string,
    publishedAt: string,
};

const ImportPostForm: React.FC = () => {
    // Use of hooks
    const categories = React.useRef<Array<IWpCategory>>([]);

    // Use react-router-dom hooks
    const location = useLocation();
    const navigate = useNavigate();
    const projectId: number = location.state as number;

    // Define initial values of form
    const initialValues: ImportPostFormType = {
        keyword: null,
        title: '',
        metaDesc: '',
        imageUrl: '',
        activitySector: null,
        postType: null,
        html: '',
        thematic: null,
        website: null,
        category: null,
        postUrl: '',
        publishedAt: '',
    };

    // Define validation schemas of each form step
    const validationSchema = useValidationSchema({
        keyword: Yup
            .mixed()
            .required("Veuillez renseigner le mot-clé de l'article"),
        title: Yup
            .string()
            .required("Veuillez renseigner le titre de l'article")
            .max(255, "Le titre ne doit pas dépasser 255 caractères"),
        metaDesc: Yup
            .string()
            .required("Veuillez renseigner le meta description de l'article")
            .max(255, "La meta description ne doit pas dépasser 255 caractères"),
        activitySector: Yup
            .mixed()
            .required("Veuillez spécifier le secteur d'activité en question"),
        postType: Yup
            .mixed()
            .required("Veuillez spécifier le type d'article en question"),
        postUrl: Yup
            .string()
            .required("Veuillez renseigner l'URL de l'article"),
        publishedAt: Yup
            .string()
            .required("Veuillez spécifiez la date de publication de l'article"),
    });

    // Function to handle form submition
    const handleSubmit = async (values: ImportPostFormType) => {
        // Call API to create a Post without an Order ("ghost" Order)
        PostAPIs.postMultiplePost([Tools.convertToBodyRequest({
            project: projectId,
            keyword: values.keyword!.value,
            postType: values.postType!.value,
            activitySector: values.activitySector!.value,
            site: values.website ? values.website!.value : undefined,
            wpCategory: values.category ?
                categories.current.find(category => category.wordPressId === Number(values.category!.value))
                : undefined,
            imageUrl: values.imageUrl,
            title: values.title,
            metaDesc: values.metaDesc,
            html: values.html,
            wpPostLink: values.postUrl,
            publishedAt: values.publishedAt,
        })]).then((data) => null !== data && 1 === data.length && navigate('..', { state: projectId }));
    };

    return (
        <>
            <Typography variant='h5' gutterBottom>
                {`Importation manuel d'un article`}
            </Typography>
            <Typography variant='body2'>
                {`Ce formulaire vous permet d'importer un article qui n'a pas été commandé ni rédiger sur ${process.env.REACT_APP_NAME}.`}
            </Typography>
            <Typography variant='body2'>
                {`Pour ce faire, vous devez renseigner les informations, contenu et URL de cet article.`}
            </Typography>
            <Divider sx={{ marginY: 4 }} />
            <FinalForm<ImportPostFormType>
                initialValues={initialValues}
                validateSchema={validationSchema}
                onSubmit={values => handleSubmit(values)}
            >
                <Grid container columnSpacing={8} rowSpacing={3} paddingBottom={3}>
                    <Grid item xs={12} md={6}>
                        <SelectProjectKeyword name='keyword' projectId={projectId} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FinalInput
                            name='imageUrl'
                            upperLabel
                            label="L'URL de l'image à la une de l'article"
                            size='small'
                            fullWidth
                            placeholder='https:// ...'
                        />
                    </Grid>
                </Grid>
                <FinalInput
                    name='title'
                    upperLabel
                    label="Titre de l'article"
                    size='small'
                    fullWidth
                    wrapperSx={{ paddingBottom: 3 }}
                />
                <FinalInput
                    multiline
                    rows={2}
                    name='metaDesc'
                    upperLabel
                    label="Meta description de l'article"
                    size='small'
                    fullWidth
                    wrapperSx={{ paddingBottom: 3 }}
                />
                <Grid container columnSpacing={8} rowSpacing={3} paddingBottom={4}>
                    <Grid item xs={12} md={6}>
                        <SelectProjectPostType name='postType' projectId={projectId} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SelectActivitySector name='activitySector'/>
                    </Grid>
                </Grid>
                <Field
                    name='html'
                    render={({ input }) => (
                        <TextEditor
                            placeholder="Contenu de l'article ..."
                            onChange={html => input.onChange(html)}
                        />
                    )}
                />
                <Divider sx={{ marginY: 4 }} />
                <Typography variant='h6' sx={{ paddingBottom: 2 }}>
                    {`Renseignements sur la mise en ligne de l'article`}
                </Typography>
                <DestinationPicker
                    siteLabel="Site de l'article"
                    categoryLabel="Catégorie de l'article"
                    onCategoriesLoaded={loadedCategories => categories.current = loadedCategories}
                />
                <FinalInput
                    name='postUrl'
                    upperLabel
                    label="URL de l'article"
                    fullWidth
                    placeholder='https:// ...'
                    helperText="Renseignez bien l'URL de l'article et non pas le nom de domaine sur lequel il se trouve"
                    wrapperSx={{ paddingY : 3 }}
                />
                <FinalDatePicker
                    name='publishedAt'
                    label="Date de publication de l'article"
                    size='small'
                    maxDateTime={moment().toDate()}
                />
                <Divider sx={{ marginY: 4 }} />
                <FinalSubmitButton
                    variant='contained'
                    children={'Importer cet article'}
                    enabledOnInvalid
                />
            </FinalForm>
        </>
    );
};

export default ImportPostForm;
