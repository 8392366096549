import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import SiteScheduleScheduleAPIs from '../../APIs/SiteScheduleAPIs';
import { Divider, Stack, Typography } from '@mui/material';
import Spinner from '../Spinner/Spinner';
import SiteScheduleRow from './SiteScheduleRow';
import ISiteSchedule from '../../models/ISiteSchedule';
import { LoadingButton } from '@mui/lab';

const SiteScheduleListing: React.FC = () => {
    // Use of hooks
    const [groupedSiteSchedules, setGroupedSiteSchedules] = React.useState<{ ongoing: Array<ISiteSchedule>, ended: Array<ISiteSchedule>|null }|null>(null);
    const [elementLoading, setElementLoading] = React.useState<Array<number>>([]);
    const [loading, setLoading] = React.useState(false);

    // Use of redux hook
    const instanceAccountId = useAppSelector((state) => state.instance.account!.id);

    // Use of react-router-dom hook
    const location = useLocation();
    const siteId = location.state as number | undefined;

    // useEffect to fetch site schedules when siteSchedules is null
    React.useEffect(() => {
        null === groupedSiteSchedules &&
            SiteScheduleScheduleAPIs.getSiteSchedule({ account: instanceAccountId, site: siteId, toPublishAt: true })
                .then((data) => null !== data && setGroupedSiteSchedules({ ongoing: data, ended: null }));
    }, [groupedSiteSchedules, instanceAccountId, siteId]);

    // Callback to load ended site schedules
    const handleShowEnded = React.useCallback(() => {
        setLoading(true);
        // Call API to retrieve ended SiteSchedules
        SiteScheduleScheduleAPIs.getSiteSchedule({ account: instanceAccountId, site: siteId, toPublishAt: false })
            .then((data) => null !== data && setGroupedSiteSchedules((current) => ({ ongoing: current!.ongoing, ended: data })))
            .finally(() => setLoading(false));
    }, [instanceAccountId, siteId]);

    // Callback to stop a site schedule
    const stopSchedule = (siteSchedule: ISiteSchedule) => {
        setElementLoading((current) => [...current, siteSchedule.id]);
        SiteScheduleScheduleAPIs.patchSiteSchedule(siteSchedule.id, { toPublishAt: null })
            // On successful API call, refetch SiteSchedule
            .then((data) => {
                if (null !== data) {
                    setGroupedSiteSchedules((current) => {
                        current!.ongoing = current!.ongoing.map((sc) => (sc.id === siteSchedule.id ? data : sc));

                        return current;
                    });
                }
                // Stop element loading
                setElementLoading((current) => current.filter((id) => id !== siteSchedule.id));
            });
    };

    return null !== groupedSiteSchedules ? (
        <Stack gap={4}>
            <Stack marginY={4} gap={1}>
                <Typography variant='h5' gutterBottom>{`Programmations en cours :`}</Typography>
                {groupedSiteSchedules.ongoing.map((siteSchedule, index) => (
                    <SiteScheduleRow
                        key={index}
                        siteSchedule={siteSchedule}
                        loading={elementLoading.includes(siteSchedule.id)}
                        onStopSchedule={stopSchedule}
                    />
                ))}
                {
                    null !== groupedSiteSchedules.ended ? (
                        <>
                            <Divider sx={{ marginY: 4 }} />
                            <Typography variant='h5' gutterBottom>{`Programmations terminées :`}</Typography>
                            {groupedSiteSchedules.ended?.map((siteSchedule, index) => (
                                <SiteScheduleRow
                                    key={index}
                                    siteSchedule={siteSchedule}
                                    loading={elementLoading.includes(siteSchedule.id)}
                                />
                            ))}
                        </>
                    ) : (
                        <LoadingButton loading={loading} variant='contained' onClick={() => handleShowEnded()}>
                            {'Afficher les programmations terminées'}
                        </LoadingButton>
                    )
                }
            </Stack>
        </Stack>
    ) : (
        <Spinner />
    );
};

export default SiteScheduleListing;
