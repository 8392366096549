import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectTextEditorPlainText, setTextToAdd } from '../../store/slices/textEditorSlice';
import { IOpenAiGenerationState } from '../../store/slices/openAiGenerationSlice';
import { instanceIsGranted, selectInstanceAccountTypeName } from '../../store/slices/instanceSlice';
import { openModal } from '../../store/slices/activeModalSlice';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ModalTweakGeneration from '../Modal/ModalTweakGeneration';
import EUserRoles from '../../interfaces/EUserRoles';
import IOpenAIResponse from '../../interfaces/IOpenAIResponse';
import IGenerationParams from '../../interfaces/IGenerationParams';
import cssStyles from './TextEditorStyles';
import EAccountTypes from '../../interfaces/EAccountTypes';

const GenerationPanelButton: React.FC<{ tokens: number, offset: number }> = props => {
    // Use of hooks
    const [loading, isLoading] = React.useState<boolean>(false);
    const [spentTokens, setSpentTokens] = React.useState<number|null>(null);
    const spentTokensTimeout = React.useRef<any>(null);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceAccountId: number|undefined = useAppSelector(state => state.instance.account?.id);
    const instanceAccountTypeSlug: string|null = useAppSelector(selectInstanceAccountTypeName(true));
    const isDev: boolean = useAppSelector(instanceIsGranted(EUserRoles.DEV));
    const instanceWalletTokens: number = useAppSelector(state => state.instanceWallet.tokens)
    const plainText: string = useAppSelector(selectTextEditorPlainText(props.offset));
    const openAiGenerationState: IOpenAiGenerationState = useAppSelector(state => state.openAiGeneration);

    // Freelance editors can not tweak params
    const userCanTweakParams: boolean = EAccountTypes.EDITOR !== instanceAccountTypeSlug;

    // Callback to handle API call
    const handleGenerationCall = React.useCallback((params?: IGenerationParams|undefined, forcedPlainText?: string|undefined) => {
        // Load during API call
        isLoading(true);
        // Call API to generate content
        BridgeAPIs.getOpenAIResponse({
            prompt: forcedPlainText ?? plainText,
            length: props.tokens,
            account: instanceAccountId,
            post: openAiGenerationState.postId ?? undefined,
            params: params,
        })
            // On successful API call
            .then((data: IOpenAIResponse) => {
                if (null !== data) {
                    // Tell redux that we need to add text
                    dispatch(setTextToAdd({ text: data.text, bold: false }));
                    // If we have spent tokens
                    if (data.length) {
                        // Show how many tokens have been spent
                        setSpentTokens(data.length.spent);
                        // Clear previous timeout
                        clearTimeout(spentTokensTimeout.current);
                        // Hide spent tokens after 3 seconds
                        spentTokensTimeout.current = setTimeout(() => {
                            setSpentTokens(null);
                        }, 3000);
                    }
                }
            })
            // Stop loading
            .finally(() => isLoading(false))
    }, [dispatch, instanceAccountId, openAiGenerationState.postId, plainText, props.tokens]);

    // Callback triggered when user click on generation button
    const handleClick = React.useCallback((params?: IGenerationParams|undefined) => {
        if (false === isDev && instanceWalletTokens < props.tokens) {
            // If User has not enough tokens, display information modal
            dispatch(openModal({ name: 'notEnoughTokenInfoModal' }));
        } else if (userCanTweakParams) {
            // If Order is private, User can tweak generation params
            dispatch(openModal({ name: 'tweakGenerationModal' }));
        } else {
            // Else, User has enough tokens and Order is public, call API with default params
            handleGenerationCall(params);
        }
    }, [isDev, instanceWalletTokens, props.tokens, userCanTweakParams, dispatch, handleGenerationCall])

    // Callback triggered when user click on tweak button
    const handleTweakGeneration = React.useCallback((params: IGenerationParams) => {
        let forcedPlainText: string = plainText;
        let plainTextLength = plainText.length;
        if (params.offset <= plainTextLength) {
            forcedPlainText = plainText.substring(plainTextLength - params.offset, plainTextLength);
        }

        handleGenerationCall(params, forcedPlainText);
    }, [handleGenerationCall, plainText]);

    // useMemo for ModalTweakGeneration
    const tweakGenerationModal = React.useMemo(() => (
        userCanTweakParams ? (
            <ModalTweakGeneration
                title='Changer les paramètres de génération'
                onValidation={handleTweakGeneration}
            />
        ) : null
    ), [userCanTweakParams, handleTweakGeneration]);

    // useMemo for content generation button
    const generateButton = React.useMemo(() => (
        <LoadingButton
            sx={cssStyles.generateButton}
            variant='contained'
            loading={loading}
            disabled={0 === plainText.length}
            onClick={() => handleClick()}
        >
            {`Générer du contenu`}
        </LoadingButton>
    ), [handleClick, loading, plainText.length]);

    return (
        <>
            {tweakGenerationModal}
            <Stack direction='row' alignItems='center' columnGap={1}>
                {
                    null !== spentTokens && (
                        <Typography color='warning.main' variant='body2'>
                            {`- ${spentTokens} jeton${1 < spentTokens ? 's' : ''}`}
                        </Typography>
                    )
                }
                {
                    false === isDev && (
                        <Typography variant='body2'>
                            {`${instanceWalletTokens} jeton${1 < instanceWalletTokens ? 's' : ''}`}
                        </Typography>
                    )
                }
                {
                    '' === plainText ? (
                        <Tooltip title='Veuillez écrire du texte avant de générer du contenu'>
                            <Box component='span'>
                                {generateButton}
                            </Box>
                        </Tooltip>
                    ) : (generateButton)
                }
            </Stack>
        </>
    );
};

export default GenerationPanelButton;
