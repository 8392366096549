import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IOpenAiGenerationState {
    postId: number|null,
};

const initialState: IOpenAiGenerationState = {
    postId: null,
};

export const slice = createSlice({
    name: 'openAiGenerationState',
    initialState,
    reducers: {
        setOpenAiGenerationState: (_, action: PayloadAction<IOpenAiGenerationState>) => {
            return action.payload;
        },
        clearOpenAiGenerationState: (_: IOpenAiGenerationState) => {
            // Return a new value and force createSlice to replace the old one.
            return initialState;
        },
    }
});

export const { setOpenAiGenerationState, clearOpenAiGenerationState } = slice.actions;

export default slice.reducer;
