import React from 'react';
import CsvParser from 'papaparse';
import { useAppSelector } from '../../store/hooks';
import { Button } from '@mui/material';

const DownloadTemplate: React.FC<{}> = props => {
    // Use of redux
    const { expectedHeaders, dataValidation } = useAppSelector(state => state.importCsv);

    // Callback triggered on button click
    const downloadTemplate = React.useCallback(() => {
        if (expectedHeaders) {
            // Construct CSV with header and example line
            const csvOject = [
                expectedHeaders.map(header => header.label),
                expectedHeaders.map(header => dataValidation && Object.keys(dataValidation).includes(header.value) ? dataValidation[header.value].type : 'Exemple'),
            ];
            // Transform CSV object to string
            const csvString = CsvParser.unparse(csvOject, { delimiter: ';' });
            // Transform string to blob
            const csvBlob = new Blob([csvString], { type: 'text/csv' });
            const randomName = (Math.random() + 1).toString(36).substring(7)
            // Transform blob to file to set the file name
            const csvFile = new File([csvBlob], `${randomName}.csv`, { type: 'text/csv' });
            // Open the file in a new window
            window.open(URL.createObjectURL(csvFile));
        }
    }, [expectedHeaders, dataValidation]);

    return (
        <Button
            variant='outlined'
            sx={{ alignSelf: 'flex-start' }}
            onClick={() => downloadTemplate()}
        >
            {`Télécharger le modèle CSV`}
        </Button>
    );
};

export default DownloadTemplate;
