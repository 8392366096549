import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import { Button, Stack, TextField, Typography } from '@mui/material';
import GenericModal from './GenericModal';

const ModalImportImage: React.FC<{
    modalTitle: string,
    name?: string|undefined,
    onValidation: (url: string, alt: string, title: string, modalParams: any) => void,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'importImageModal');
    const [url, setUrl] = React.useState('');
    const [alt, setAlt] = React.useState('');
    const [title, setTitle] = React.useState('');

    // Use of redux
    const dispatch = useAppDispatch();
    const modalParams = useAppSelector(state => state.activeModal.params);

    // Callback triggered on button click
    const handleClick = () => {
        props.onValidation && props.onValidation(url, alt, title, modalParams);
        // Submission also close modal
        dispatch(closeModal());
        // Reset states
        setUrl('');
        setAlt('');
        setTitle('');
    };

    return (
        <GenericModal
            name={modalName.current}
            title={props.modalTitle}
        >
            <Stack gap={2}>
                <TextField
                    autoFocus
                    label={`URL de l'image`}
                    value={url}
                    onChange={(event) => setUrl(event.target.value)}
                />
                <Stack gap={2}>
                    <Typography>{`Attributs de l'image`}</Typography>
                    <TextField
                        label="Description textuelle de l'image (alt)"
                        value={alt}
                        onChange={(event) => setAlt(event.target.value)}
                    />
                    <TextField
                        label="Titre de l'image (title)"
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </Stack>
                <Button
                    variant='outlined'
                    color='inherit'
                    disabled={!url}
                    onClick={() => handleClick()}
                    sx={{ marginTop: 2 }}
                >
                    {`Valider`}
                </Button>
            </Stack>
        </GenericModal>
    );
};

export default ModalImportImage;
