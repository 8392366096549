import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';

const BriefDisplay: React.FC<{ brief: string|null }> = props => {
    return (
        null !== props.brief ? (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Typography variant='h5' gutterBottom>
                            {`Consignes de l'article`}
                        </Typography>
                        <Typography variant='body2'>
                            {`Les consignes à suivre pour rédiger l'article`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {props.brief}
                    </Grid>
                </Grid>
                <Divider variant='middle' sx={{ marginY: 4 }} />
            </>
        ) : null
    );
};

export default BriefDisplay;
