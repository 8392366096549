import React from 'react';
import {
    Box,
    ButtonGroup,
    Button,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Typography,
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import ISplitButtonOption from '../../interfaces/ISplitButtonAction';
import cssStyles from './SplitButtonStyles';

const SplitButton: React.FC<{
    options: Array<ISplitButtonOption>,
    label?: string|undefined,
    disabled?: boolean|undefined,
    width?: number|string|undefined
}> = props => {
    // Use of hooks
    const [open, setOpen] = React.useState<boolean>(false);

    // By default, set the first non disabled option
    const [selectedOption, setSelectedOption] = React.useState<ISplitButtonOption|null>(null);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    // useEffect when options values changes
    React.useEffect(() => {
        // Check that the selected option should be disabled with options update
        const updatedSelectedOption = props.options.find(o => o.label === selectedOption?.label);
        updatedSelectedOption && true === updatedSelectedOption.disabled
            && setSelectedOption(null);
    }, [props.options, selectedOption]);

    return (
        <Box sx={props.width ? { width: props.width } : undefined}>
            <ButtonGroup
                // Disable button if there is no option
                disabled={props.disabled || false === props.options.some(o => !o.disabled)}
                variant='contained'
                color={selectedOption?.buttonColor ?? 'secondary'}
                ref={anchorRef}
                fullWidth={undefined !== props.width}
            >
                <Button
                    onClick={selectedOption?.action}
                    color={selectedOption?.buttonColor ?? 'secondary'}
                    title={selectedOption?.label}
                    startIcon={selectedOption?.icon}
                    sx={{ ...cssStyles.actionButton, ...(null === selectedOption && { pointerEvents: 'none' }) }}
                >
                    <Typography component='span' variant='body2' sx={cssStyles.actionButtonText}>
                        {selectedOption?.label ?? props.label ?? `Sélectionner une action`}
                    </Typography>
                </Button>
                <Button
                    size='small'
                    onClick={() => setOpen((prevOpen) => !prevOpen)}
                    color={selectedOption?.buttonColor ?? 'secondary'}
                    sx={cssStyles.actionMenuButton}
                >
                    <ArrowDropDownIcon sx={false === open ? { transform: 'rotate(180deg)' } : undefined} />
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement='top-start'
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener
                                onClickAway={(event: Event) =>
                                    (!anchorRef.current || !anchorRef.current.contains(event.target as HTMLElement))
                                        && setOpen(false)
                                }
                            >
                                <MenuList>
                                    {
                                        props.options.map((option: ISplitButtonOption, index: number) => (
                                            !option.hideOnDisabled || !option.disabled ? (
                                                <MenuItem
                                                    key={index}
                                                    selected={option.label === selectedOption?.label}
                                                    onClick={() => {
                                                        setSelectedOption(option);
                                                        setOpen(false);
                                                        option.action && option.action();
                                                    }}
                                                    disabled={option.disabled}
                                                    sx={option.textColor ? { color: option.textColor } : undefined}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ) : null
                                        ))
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default SplitButton;
