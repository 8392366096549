import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { isModalOpened } from '../../store/slices/activeModalSlice';
import PostAPIs from '../../APIs/PostAPIs';
import {
    Stack,
    Divider,
    CardMedia,
    Typography,
} from '@mui/material';
import GenericModal from './GenericModal';
import PurifiedHtml from '../PurifiedHtml/PurifiedHtml';
import Spinner from '../Spinner/Spinner';
import IPost from '../../models/IPost';

const ModalViewPost: React.FC<{ title?: string|undefined, name?: string|undefined, post?: IPost|undefined }> = props => {
    // Use of hooks
    const [post, setPost] = React.useState<IPost|null>(null);
    const modalName = React.useRef<string>(props.name ?? 'viewPostModal');

    // Use of redux
    const postId: number|null = useAppSelector(state => state.activeModal.params);
    const modalIsOpened: boolean = useAppSelector(isModalOpened(modalName.current));

    // useEffect when component is mounting
    React.useEffect(() => {
        if (modalIsOpened && null === post && (null !== postId || undefined !== props.post)) {
            // Post given in props
            undefined !== props.post ?
                // Set hook post value & move on
                setPost(props.post)
                // Otherwise, call API to retrieve Post data from id given via modal params
                : PostAPIs.getPost({ id: postId })
                    // On successful call
                    .then((data: Array<IPost>|null) => null !== data && data[0] && setPost(data[0]));
        }
    }, [modalIsOpened, post, postId, props.post])

    return (
        <GenericModal
            name={modalName.current}
            title={props.title ?? "Consultation de l'article"}
            width={2/3}
            height={4/5}
            onClose={() => setPost(null)}
        >
            {
                null !== post ? (
                    <Stack gap={1}>
                        <Typography variant='body2' color='primary.main' fontWeight={700}>
                            {`Meta decription : `}
                            <Typography variant='body2' component='span' color='textPrimary'>
                                {post.metaDesc}
                            </Typography>
                        </Typography>
                        <Stack direction='row' gap={2}>
                            <Typography variant='body2' color='primary.main' fontWeight={700}>
                                {`Taux d'optimisation : `}
                                <Typography variant='body2' component='span' color='textPrimary'>
                                    {`${post.optimization}%`}
                                </Typography>
                            </Typography>
                            <Typography variant='body2' color='primary.main' fontWeight={700}>
                                {`Nombre de mots : `}
                                <Typography variant='body2' component='span' color='textPrimary'>
                                    {post.nbrWords}
                                </Typography>
                            </Typography>
                        </Stack>
                        <Divider sx={{ marginY: 2 }} />
                        <CardMedia
                            component='img'
                            src={post.imageUrl ?? undefined}
                            height={200}
                            sx={{ marginBottom: 1, borderRadius: 1 }}
                        />
                        <Typography variant='h4' paddingBottom={2}>
                            {post.title}
                        </Typography>
                        <PurifiedHtml html={post.html ?? 'Pas de contenu'} />
                    </Stack>
                ) : (<Spinner />)
            }
        </GenericModal>
    );
};

export default ModalViewPost;
