import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { instanceIsScoped } from '../../store/slices/instanceSlice';
import AccountAPIs from '../../APIs/AccountAPIs';
import {
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import Spinner from '../Spinner/Spinner';
import SingleValueList from '../SingleValueList/SingleValueList';
import RouterLink from '../RouterLink/RouterLink';
import IAccount from '../../models/IAccount';
import IAccountThematic from '../../models/IAccountThematic';

const TweakAccountParams: React.FC = () => {
    // Use of hooks
    const [account, setAccount] = React.useState<IAccount|null>(null);
    const [accountThematics, setAccountThematics] = React.useState<Array<IAccountThematic>>([]);

    // Use of redux
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);
    const instanceCanUpdateAccountParams: boolean = useAppSelector(instanceIsScoped(['account_owner', 'account_params_update']));

    // useEffect when component has finished mounting to get account of current user
    React.useEffect(() => {
        null === account &&
            // Search account of the current user ID using API
            AccountAPIs.getAccount({ id: instanceAccountId })
                // On successful call
                .then((data: Array<IAccount>) => {
                    if (data && data[0]) {
                        const account = data[0];

                        // Set the Account, AccountThematic data
                        setAccount(account);
                        setAccountThematics(account.accountThematics);
                    }
                });
    }, [account, instanceAccountId]);

    // Callback wich define onChange of SingleValueList for AccountThematic
    const handleChangeAccountThematic = React.useCallback((element: any, action: string) => {
        // If action parameter is defined as 'add'
        if ('add' === action) {
            account && element.value &&
                // Creating AccountThematic resource using API
                AccountAPIs.postAccountThematic({ account: account.id, thematic: { name: element.value } })
                    .then((accountThematic: IAccountThematic) => {
                        // On successful call add the AccountThematic to the array if it is not already in it
                        accountThematic && !accountThematics.some((acp) => acp.id === accountThematic.id) &&
                            setAccountThematics([ ...accountThematics, accountThematic]);
                    });
        } else if ('delete' === action) {
            element.id &&
                // Delete AccountThematic resource using API
                AccountAPIs.deleteAccountThematic(element.id)
                    // Set accountPostThematics with the new array filtered without the element to delete
                    .then((data: boolean|null) => data && setAccountThematics(accountThematics.filter((value) => value.id !== element.id)));
        }
    }, [account, accountThematics]);

    // Loading if the user does not have an account
    return (
        null !== account ? (
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Typography variant='h5' paddingBottom={3}>
                        {`Les thématiques de site du compte`}
                    </Typography>
                    <Typography variant='body2' gutterBottom>
                        {`La thématique d'un site est purement indiquatif et restera inconnu pour le rédacteur
                        de votre article. Elle vous servira de filtre lors de la consultation de l'ensemble
                        de vos sites.`}
                    </Typography>
                    <Typography variant='body2' color='primary'>
                        {`Il vous faut configurer au moins une thématique de site pour pouvoir commencer à `}
                        <RouterLink anchor='configurer un site.' to='../sites/form' />
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{`Thématiques de site`}</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <SingleValueList
                                readOnly={false === instanceCanUpdateAccountParams}
                                data={accountThematics}
                                pathToValue='thematic.name'
                                modalTitle='Ajouter une thématique de site'
                                modalName='modalThematics'
                                modalInputLabel='Nom de la thématique de site'
                                onChange={(element, action) => handleChangeAccountThematic(element, action)}
                            />
                        </AccordionDetails>
                </Accordion>
                </Grid>
            </Grid>
        ) : (<Spinner />)
    );
};

export default TweakAccountParams;
