import React from 'react';
import moment from 'moment-timezone';
import { useAppSelector } from '../../store/hooks';
import PaymentAPIs from '../../APIs/PaymentAPIs';
import {
    Stack,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
} from '@mui/material';
import {
    Receipt as ReceiptIcon,
} from '@mui/icons-material';
import { RiMoneyEuroCircleFill } from 'react-icons/ri';
import RecoverFundsButton from '../RecoverFundsButton/RecoverFundsButton';
import Spinner from '../Spinner/Spinner';
import IPayment from '../../models/IPayment';

const InvoicesListing: React.FC = () => {
    // Use of hooks
    const [payments, setPayments] = React.useState<Array<IPayment>|null>(null);

    // Use of redux
    const instanceWalletId: number = useAppSelector(state => state.instance.account!.walletId!);

    // useEffect when component is mounting
    React.useEffect(() => {
        // Call API to retrieve all instance wallet Payments
        PaymentAPIs.getPayment({ wallet: instanceWalletId, hasInvoice: true })
            // On successful API call, store Payments in hook
            .then((data: Array<IPayment>|null) => null !== data && setPayments(data));
    }, [instanceWalletId])

    return (
        <>
            <Stack direction='row' justifyContent='space-between' paddingBottom={4}>
                <Typography variant='h5'>
                    {`Mes factures :`}
                </Typography>
                <RecoverFundsButton to='/dashboard/payments/form' />
            </Stack>
            {
                null !== payments ? (
                    0 < payments.length ? (
                        <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ '& > th': { fontWeight: 700 } }}>
                                        <TableCell>
                                            {`Demande de paiment le`}
                                        </TableCell>
                                        <TableCell>
                                            {`Paiement effectué le`}
                                        </TableCell>
                                        <TableCell>
                                            {`Numéro de facture`}
                                        </TableCell>
                                        <TableCell>
                                            {`Facture`}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {`Montant`}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        payments.map((payment: IPayment, index: number) => (
                                            <TableRow  key={index} sx={{ '&:last-child td': { border: 0 } }}>
                                                <TableCell>
                                                    {moment(payment.createdAt).format('DD/MM/Y à H:mm')}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        payment.validatedAt ?
                                                            moment(payment.validatedAt).format('DD/MM/Y à H:mm')
                                                            : 'En attente de validation'
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {`N° ${payment.invoiceNumber}`}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        payment.invoiceUrl ? (
                                                            <Tooltip title='Voir la facture'>
                                                                <IconButton
                                                                    sx={{ border: '2px solid', color: 'primary.main' }}
                                                                    onClick={() => window.open(payment.invoiceUrl!, '_blank')}
                                                                >
                                                                    <ReceiptIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <IconButton disabled sx={{ border: '2px solid' }}>
                                                                <ReceiptIcon />
                                                            </IconButton>
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Stack
                                                        direction='row'
                                                        justifyContent='flex-end'
                                                        alignItems='center'
                                                        columnGap={1}
                                                        sx={{ ...(null === payment.validatedAt && { color: 'warning.main' }) }}
                                                    >
                                                        <Typography lineHeight={1} fontWeight={700}>
                                                            {payment.amount}
                                                        </Typography>
                                                        <RiMoneyEuroCircleFill fontSize='1.5rem' />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography variant='h6' component='p' align='center' color='textSecondary'>
                            {`Aucun paiement pour le moment ...`}
                        </Typography>
                    )
                ) : (<Spinner />)
            }
        </>
    );
};

export default InvoicesListing;
