import IProjectKeyword from '../models/IProjectKeyword';
import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * ProjectAPIs class.
 */
 export default class ProjectAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of Project resources.
     *
     * @param query
     */
    public static async getProject(query: any) {
        return GenericAPIs.apiGetCall('projects', this.getApiKey(), query);
    }

    /**
     * Create a Project resource.
     *
     * @param body
     */
    public static async postProject(body: any) {
        return GenericAPIs.apiPostCall('projects', body, this.getApiKey());
    }

    /**
     * Update a Project resource.
     *
     * @param id
     * @param body
     */
    public static async patchProject(id: number, body: any) {
        return GenericAPIs.apiPatchCall(`projects/${id}`, null, body, this.getApiKey());
    }

    /**
     * Get collection of ProjectPostType resources.
     *
     * @param query
     */
    public static async getProjectPostType(query: any) {
        return GenericAPIs.apiGetCall('projects_post_types', this.getApiKey(), query);
    }

    /**
     * Create a ProjectPostType resource.
     *
     * @param body
     */
    public static async postProjectPostType(body: any) {
        return GenericAPIs.apiPostCall('projects_post_types', body, this.getApiKey());
    }

    /**
     * Get collection of ProjectKeyword resources.
     *
     * @param query
     */
    public static async getProjectKeyword(query: any) {
        return GenericAPIs.apiGetCall('projects_keywords', this.getApiKey(), query);
    }

    /**
     * Create a ProjectKeyword resource.
     *
     * @param body
     */
    public static async postMultipleProjectKeyword(body: Array<any>): Promise<Array<IProjectKeyword> | null> {
        return GenericAPIs.apiPostCall('projects_keywords/multiple_post', { projectKeywords: body }, this.getApiKey());
    }

    /**
     * Delete a Project resource.
     *
     * @param id
     */
    public static async deleteProject(id: number) {
        return GenericAPIs.apiDeleteCall(`projects/${id}`, this.getApiKey());
    }
};
