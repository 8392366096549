import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, FormikProps, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useAppSelector } from '../../store/hooks';
import Tools from '../../helpers/Tools';
import ProjectAPIs from '../../APIs/ProjectAPIs';
import {
    Grid,
    Typography,
    Divider,
    Button,
} from '@mui/material';
import FormikInput from '../FormikComponent/FormikInput';
import KeywordsManagement from './KeywordsManagement';
import PostTypesManagement from './PostTypesManagement';
import MembersManagement from './MembersManagement';
import Spinner from '../Spinner/Spinner';
import IProject from '../../models/IProject';

const ProjectForm: React.FC = () => {
    // Use react-router-dom hooks
    const navigate = useNavigate();
    const location = useLocation();
    const projectId: number|null = location.state as number|null;

    // Use of hooks
    const [initialProject, setInitialProject] = React.useState<IProject|null>(null);
    const [loading, setLoading] = React.useState<boolean>(null !== projectId);

    // Use of redux
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        name: Yup
            .string()
            .required('Veuillez spécifier le nom de votre projet'),
    });

    const initialValues: any = {
        name: initialProject?.name ?? '',
        annualBudget: initialProject?.annualBudget ?? 0,
        moneySite: initialProject?.moneySite ?? '',
        keywords: initialProject?.keywords?.map(keyword => keyword.name) ?? [],
        postTypes: initialProject?.postTypes?.map(postType => postType.name) ?? [],
        members: initialProject?.members ?? [],
    };

    // Callback after submission on formik values to create or update a Site
    const thenApiCall = React.useCallback((data: IProject|null, formikHelpers: FormikHelpers<any>) => {
        // Redirect to Projects listing or Formik form is no longer submitting
        null !== data ? navigate('..') : formikHelpers.setSubmitting(false);
    }, [navigate]);

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback((values: any, formikHelpers: FormikHelpers<any>) => {
        // Define body request in POST or PATCH case
        const bodyRequest = Tools.convertToBodyRequest({
            account: instanceAccountId,
            user: instanceUserId,
            name: values.name,
            annualBudget: values.annualBudget,
            moneySite: values.moneySite,
            keywords: values.keywords,
            postTypes: values.postTypes,
            members: values.members,
        });

        null === projectId ?
            // Call API to create Project
            ProjectAPIs.postProject(bodyRequest)
                // On successful API call
                .then((data: IProject|null) => thenApiCall(data, formikHelpers))
            // Call API to update Site under instance account
            : ProjectAPIs.patchProject(projectId, bodyRequest)
                // On successful API call
                .then((data: IProject|null) => thenApiCall(data, formikHelpers))
    }, [instanceAccountId, instanceUserId, projectId, thenApiCall]);

    React.useEffect(() => {
        null !== projectId &&
            ProjectAPIs.getProject({ id: projectId, detailed: true })
                .then((data: Array<IProject>|null) => {
                    if (null !== data) {
                        setInitialProject(data[0]);
                        setLoading(false);
                    }
                }
            );
    }, [projectId]);

    return (
        false === loading ? (
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormikSubmit}
                >
                    {(formikProps: FormikProps<any>) => (
                        <Form>
                            <Grid container spacing={3} rowSpacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant='h5' gutterBottom>
                                        {`Nom du projet`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormikInput
                                        size='medium'
                                        name='name'
                                        placeholder='Mon incroyable projet'
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant='h5' gutterBottom>
                                        {`Budget annuel`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormikInput
                                        size='medium'
                                        name='annualBudget'
                                        type='number'
                                        suffix='€'
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant='h5' gutterBottom>
                                        {`Votre "money site"`}
                                    </Typography>
                                    <Typography variant='body2' gutterBottom>
                                        {`Un projet se fonde sur la base d'un "money site". Les commandes
                                        d'articles que vous allez passer au travers de ce projet auront pour finalité de
                                        faire monter le référencement de votre "money site".`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormikInput
                                        size='medium'
                                        name='moneySite'
                                        prefix='www.'
                                        helperText='Ce champ est faculatif'
                                    />
                                </Grid>
                            </Grid>
                            <Divider variant='middle' sx={{ marginY: 4 }} />
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant='h5' gutterBottom>
                                        {`Expressions clés du projet`}
                                    </Typography>
                                    <Typography variant='body2' gutterBottom>
                                        {`Lorsque vous commanderez de l'articles sur ce projet, vous devrez choisir parmi
                                        ces expressions clés.`}
                                    </Typography>
                                    <Typography variant='body2' fontWeight={700}>
                                        {`Vous pouvez toujours supprimer et/ou ajouter des expressions
                                        clés à un projet déjà créé.`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <KeywordsManagement
                                        initialKeywords={initialValues.keywords}
                                        onChange={keywords => { formikProps.values.keywords = keywords }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant='h5' gutterBottom>
                                        {`Types d'articles du projet`}
                                    </Typography>
                                    <Typography variant='body2' gutterBottom>
                                        {`Chaque article commandé sera relié à un type. Ce type vous servira de filtre
                                        pour retrouver vos articles plus rapidement.`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <PostTypesManagement
                                        initialPostTypes={initialValues.postTypes}
                                        onChange={postTypes => { formikProps.values.postTypes = postTypes }}
                                    />
                                </Grid>
                            </Grid>
                            <Divider variant='middle' sx={{ marginY: 4 }} />
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant='h5' gutterBottom>
                                        {`Membres du projet`}
                                    </Typography>
                                    <Typography variant='body2' gutterBottom>
                                        {`Les utilisateurs membre auront accès à ce projet et pourront passer
                                        des commandes d'articles.`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <MembersManagement
                                        initialMembers={initialValues.members}
                                        onChange={members => { formikProps.values.members = members.map(member => member.id) }}
                                    />
                                </Grid>
                            </Grid>
                            <Divider variant='middle' sx={{ marginY: 4 }} />
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={!formikProps.isValid || formikProps.isSubmitting}
                            >
                                {projectId ? 'Sauvegarder les modifications' : 'Créer le projet'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </>
        ) : (<Spinner />)
    );
};

export default ProjectForm;
