import React from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { closeModal } from '../../../store/slices/activeModalSlice';
import {
    Typography,
	Box,
	FormGroup,
	FormControlLabel,
	Checkbox,
	TextField,
	Stack,
	Button,
} from '@mui/material';

const postRefuseReasons: Array<string> = [
    'Pas assez de mots',
    "Trop de fautes d'orthographe",
    "Score d'optimisation trop bas",
    'Les liens ne sont pas respectés',
    "L'image à la une ne correspond pas",
];

const RefuseAction: React.FC<{
    refuseState: boolean,
    onClick: () => void,
    onRefuse: (reasons: Array<string>) => void,
}> = props => {
    // Use of hooks
    const [refuseMessageInput, showRefuseMessageInput] = React.useState<boolean>(false);
    const selectedRefuseReasons = React.useRef<Array<string>>([]);
    const messageValue = React.useRef<string>('');

    // Use of redux
    const dispatch = useAppDispatch();

    // useEffect when show value changes
    React.useEffect(() => {
        if (true === props.refuseState) {
            // scroll to bottom of modal to show reasons
            const scrollElement = document.querySelector('#genericModalContent');
            scrollElement?.scrollTo({ behavior: 'smooth', top: scrollElement.scrollHeight });
        }
    }, [props.refuseState]);

    return (
        <Stack>
            {
                // Show input to add a message to refuse the post
                true === props.refuseState && (
                    <>
                        <Typography>
                            {`Selectionner une ou plusieurs raisons du refus`}
                        </Typography>
                        <Box marginBottom={2} paddingLeft={2} marginLeft={2} borderLeft={1}>
                            <FormGroup>
                                {
                                    postRefuseReasons.map((reason, index) => (
                                        <FormControlLabel
                                            key={index}
                                            label={reason}
                                            name={reason}
                                            control={
                                                <Checkbox onChange={event => selectedRefuseReasons.current.push(event.target.name)} />
                                            }
                                        />
                                    ))
                                }
                                <FormControlLabel
                                    label='Autre'
                                    control={
                                        <Checkbox onChange={() => showRefuseMessageInput(show => !show)} />
                                    }
                                />
                            </FormGroup>
                            {
                                refuseMessageInput && (
                                    <TextField
                                        label='Message de refus'
                                        variant='outlined'
                                        multiline
                                        maxRows={3}
                                        fullWidth
                                        onChange={(event) => messageValue.current = event.target.value}
                                    />
                                )
                            }
                        </Box>
                    </>
                )
            }
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                {
                    // Add a button to cancel the refuse post operation
                    true === props.refuseState && (
                        <Button
                            variant='outlined'
                            onClick={() => {
                                props.onClick();
                                showRefuseMessageInput(false);
                                messageValue.current = '';
                            }}
                        >
                            {`Retour`}
                        </Button>
                    )
                }
                <Button
                    variant='outlined'
                    color='error'
                    onClick={() => {
                        // If refuseInput is false, show reasons selection
                        if (false === props.refuseState) {
                            props.onClick();
                        } else {
                            // Add 'Autre' option message value
                            messageValue.current && selectedRefuseReasons.current.push(messageValue.current);
                            // Else, call the callback to handle post refuse with reasons
                            props.onRefuse(selectedRefuseReasons.current);
                            dispatch(closeModal());
                        }
                    }}
                >
                    {false === props.refuseState ? `Refuser l'article` : `Confirmer`}
                </Button>
            </Stack>
        </Stack>
    );
};

export default RefuseAction;
