import React from 'react';
import { useAppSelector } from '../../store/hooks';
import AccountAPIs from '../../APIs/AccountAPIs';
import ModalSelectUsers from './ModalSelectUsers';
import IPaginatorResponse from '../../interfaces/IPaginatedResponse';
import IAccountUser from '../../models/IAccountUser';
import IUser from '../../models/IUser';

const ModalSelectAccountUser: React.FC<{
    modalName: string,
    selectUsers?: Array<number>|undefined,
    excludeUsers?: Array<number>|undefined,
    scopes?: Array<string>|undefined,
    singleSelect?: boolean|undefined,
    onValidation: (
        params: any,
        selected: Array<IUser>,
        // Base on props.selectedUsersIds
        newSelected: Array<IUser>|undefined,
        unselected: Array<IUser>|undefined
    ) => void,
}> = props => {
    // Use of redux
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);
    // Merge props notDisplayedUserIds and modal params notDisplayedUserIds
    const notDisplayedUserIds: Array<number> = useAppSelector(state => [ ...props.excludeUsers ?? [], ...state.activeModal.params?.notDisplayedUserIds ?? [] ]);
    // Merge props selectedUsersIds and modal params selectedUsersIds
    const selectedUsersIds: Array<number> = useAppSelector(state => [ ...props.selectUsers ?? [], ...state.activeModal.params?.selectedUsersIds ?? [] ]);

    return (
        <ModalSelectUsers
            name={props.modalName}
            singleSelect={props.singleSelect}
            getUsers={() =>
                // Call API to search for users in account with scope 'can_make_order'
                AccountAPIs.getAccountUser({ account: instanceAccountId, scopes: props.scopes })
                    // On successful API call
                    .then((data: Array<IAccountUser>|null) =>
                        // Format response data to IPaginatorResponse
                        null !== data ? {
                            // Retrieve User from AccountUser
                            data: data?.map(accountUser => accountUser.user),
                            count: data.length
                        } as IPaginatorResponse<IUser> : null
                    )
            }
            selectUsers={selectedUsersIds}
            excludeUsers={notDisplayedUserIds}
            onValidation={props.onValidation}
        />
    );
};

export default ModalSelectAccountUser;
