import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useAppSelector } from '../../store/hooks';
import OrderAPIs from '../../APIs/OrderAPIs';
import OtherAPIs from '../../APIs/OtherAPIs';
import { Stack, Typography } from '@mui/material';
import ModalConfirmation from '../Modal/ModalConfirmation';
import PublicPlaceOrder from './PublicPlaceOrder';
import PublicOrderContract from '../PublicOrderContract/PublicOrderContract';
import Spinner from '../Spinner/Spinner';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IOrder from '../../models/IOrder';
import IUploadFile from '../../models/IUploadFile';

const maxFirstServed = parseInt(process.env.REACT_APP_MAX_FIRST_SERVED!);

const PublicPlace: React.FC = () => {
    // Use of hooks
    const [publicOrders, setPublicOrders] = React.useState<Array<IOrder>|null>(null);
    const [userOrdersCount, setUserOrdersCount] = React.useState<number|null>(null);
    const [anchor, inView] = useInView({ triggerOnce: true });
    const page = React.useRef<number>(1);
    const acceptedContract = React.useRef<Blob|null>(null);

    // Use of redux hooks
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Use of react-router-dom hooks
    const navigate = useNavigate();

    // Callback to retrieve Orders & add them to hook value
    const askPublicPlaceOrders = React.useCallback(() => {
        // Call API to retrieve all public Orders
        OrderAPIs.getOrdersPublicPlace({ page : page.current })
            // On successful API call, Update our hook value
            .then((data: Array<IOrder>) =>
                data && setPublicOrders(publicOrders => null === publicOrders ? data : [ ...publicOrders, ...data ])
            );
    }, [])

    // Function used to hang an anchor on an element
    const hangAnchor = (nbrOfOrders: number, index: number) => {
        // Ne need to hang an anchor if we already know that there is no more Orders to fetch
        return nbrOfOrders % parseInt(process.env.REACT_APP_ITEMS_PER_PAGE_PUBLIC_PLACE!) === 0 &&
                // Anchor can only be hanged on a specific index
                nbrOfOrders - 6 === index ?
                    anchor : undefined;
    };

    // Callback used to choose an Order
    const handlePickOrder = React.useCallback(async (order: IOrder) => {
        // Upload Order mission contract
        await OtherAPIs.postUploadFile(acceptedContract.current!, `contract_${order.publicId}`)
            .then((data: IUploadFile) => {
                // On upload successful, update Order mission contract field with uploaded file URL
                order = { ...order, missionContract: data.url };
            });

        // Call API to update picked Order
        OrderAPIs.patchOrder(order.id, {
            status: EOrderStatuses.TODO,
            editor: instanceUserId,
            owner: instanceUserId,
            missionContract: order.missionContract,
        })
            // On successful API call, redirect to its Post edition
            .then((data: Array<IOrder>) => navigate('../posts/form', { state: data[0].id }));
    }, [instanceUserId, navigate]);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === publicOrders && askPublicPlaceOrders();
    }, [publicOrders, askPublicPlaceOrders])

    // useEffect when visiblity of anchor changes
    React.useEffect(() => {
        if (true === inView) {
            // Increment page
            page.current += 1;
            // Fetch more Orders
            askPublicPlaceOrders();
        }
    }, [inView, askPublicPlaceOrders])

    // useEffect when component is mounting
    React.useEffect(() => {
        null === userOrdersCount &&
            // Call API to get the number of Orders of the current user
            OrderAPIs.getOrder({
                editor: instanceUserId,
                statuses: [EOrderStatuses.TODO, EOrderStatuses.SAVED, EOrderStatuses.REFUSED],
                isFirstServed: true,
                count: true,
            })
                // On successful API call, update our hook value
                .then((data: number) => null !== data && setUserOrdersCount(data));
    }, [instanceUserId, userOrdersCount]);

    return (
        null !== publicOrders && null !== userOrdersCount ? (
            0 < publicOrders.length ? (
                <>
                    {
                        // Only show modal when user can pick an Order
                        maxFirstServed > userOrdersCount && (
                            <ModalConfirmation
                                name='pickOrderModal'
                                width={3/5}
                                title='Vous êtes sur le point de rédiger un nouvel article'
                                validationButtonColor='success'
                                message={(modalParams) =>
                                    <PublicOrderContract
                                        order={modalParams}
                                        commission={20}
                                        onContractGenerated={pdf => { acceptedContract.current = pdf }}
                                    />
                                }
                                onValidation={handlePickOrder}
                            />
                        )
                    }
                    <Stack spacing={4}>
                        {
                            publicOrders.map((order: IOrder, index: number) => (
                                <PublicPlaceOrder
                                    key={index}
                                    anchor={hangAnchor(publicOrders.length, index)}
                                    order={order}
                                    disableFirstServed={maxFirstServed <= userOrdersCount}
                                />
                            ))
                        }
                    </Stack>
                </>
            ) : (
                <Typography>
                    {`Aucune commande pour le moment.`}
                </Typography>
            )
        ) : (<Spinner />)
    );
};

export default PublicPlace;
