import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setTextToAdd } from '../../store/slices/textEditorSlice';
import {
    SxProps,
    Theme,
    Stack,
    Typography,
} from '@mui/material';
import IOptimizationData from '../../interfaces/IOptimizationData';
import cssStyles from './PostEditorStyles';
import OptimizationPanelButton from './OptimizationPanelButton';

const OptimizationPanel: React.FC<{ keyword: string, hideButtonAndScore?: boolean|undefined }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();
    const plainText: string = useAppSelector(state => state.textEditor.content.plainText);
    const optimizationData: IOptimizationData = useAppSelector(state => state.textEditor.optimization);
    const title: string = useAppSelector(state => state.activeStepper.data.title)

    // Split totalHtmlTextEditor in array of words
    const splittedText = React.useMemo(() => {
        // Retrieve plainText from text editor content
        return plainText
            // Transform text to lower case
            .toLocaleLowerCase()
            // Split string that matches any non-word character except accents in an array
            .split(/[^A-zÀ-ú]/g);
    }, [plainText]);

    // Callback used to handle click on keyword
    const handleWordClick = React.useCallback((value: string) => {
        // Tell redux that we need to add text in specific editor
        dispatch(setTextToAdd({ text: value, bold: true }));
    }, [dispatch]);

    return (
        <Stack alignItems='flex-start' spacing={2}>
            {
                !props.hideButtonAndScore && (
                    <>
                        <OptimizationPanelButton keyword={props.keyword} plainText={plainText} title={title} />
                        <Typography variant='h5'>
                            {`Score d'optimisation : `}
                            <Typography component='span' variant='h5' fontWeight='bold' color='primary'>
                                {`${optimizationData.score ?? 0}%`}
                            </Typography>
                        </Typography>
                    </>
                )
            }
            <Stack gap={1} sx={{ ...cssStyles.optimizationPanel, ...(props.hideButtonAndScore && { height: 700 } ) }}>
                {
                    0 < optimizationData.words.length ? (
                            optimizationData.words.map((value: string, index: number) => {
                                // Convert value to lower case and replace accents
                                const valueLC = value.toLowerCase();
                                // Check if word is already included html
                                const isIncluded = valueLC.includes(' ') || /[^A-zÀ-ú]/g.test(valueLC) ?
                                    plainText.toLocaleLowerCase().includes(valueLC)
                                    : splittedText.includes(valueLC);

                                return (
                                    <Typography
                                        key={index}
                                        variant='body2'
                                        component='p'
                                        sx={{  ...cssStyles.optimizationWord, ...(isIncluded && cssStyles.optimizationWordIncluded) } as SxProps<Theme>}
                                        onClick={() => handleWordClick(value)}
                                    >
                                        {value}
                                    </Typography>
                                );
                            })
                    ) : (
                        <Typography>
                            {`Impossible de proposer des mots-clés.`}
                        </Typography>
                    )
                }
            </Stack>
        </Stack>
    );
};

export default OptimizationPanel;
