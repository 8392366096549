import React, { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { decActiveStep, incActiveStep } from '../../store/slices/activeStepperSlice';
import { appMenuWidthClose, appMenuWidthOpen } from '../../configs/materialUI';
import {
    SxProps,
    Theme,
    useTheme,
    Box,
    Stack,
    Typography,
    Button,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { ArrowCircleDown as ArrowCircleDownIcon } from '@mui/icons-material';
import Header from './Header';
import IStepperStep from '../../interfaces/IStepperStep';
import makeCssStyles from './StepperStyles';

/**
 * / ! \ WARNING / ! \\
 * Please make sure that validationComponent is provided, the component must call dispatch(setStepperIsLoading());
 * clear active stepper redux state by calling clearActiveStepper using app dispatch.
 */
const Stepper: React.FC<{
    steps: Array<IStepperStep>,
    validationComponent?: ReactElement|undefined,
    nextStepText?: string|undefined,
    previousStepText?: string|undefined,
    validationText?: string|undefined,
    orientationBreakpoint?: string|undefined,
    awareOfDashboardMenu?: boolean|undefined,
    headerWidth?: string|number|undefined,
}> = props => {
    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme);

    // Use of redux
    const dispatch = useAppDispatch();
    const activeStep: number = useAppSelector(state => state.activeStepper.activeStep);
    const loading: boolean = useAppSelector(state => state.activeStepper.loading);
    const menuIsOpened: boolean = useAppSelector(state => state.dashboardMenu.open);

    // Callback used to manage stepper navigation
    const handleAskPreviousStep = React.useCallback(() => {
        // By default move to previous step
        dispatch(decActiveStep());
    }, [dispatch])

    // Callback used to manage stepper navigation
    const handleAskNextStep = React.useCallback(() => {
        // Get the current step
        const step: IStepperStep = props.steps[activeStep];
        // Call onSubmit callback if defined
        step.stepValidationRef && step.stepValidationRef.current ?
            setTimeout(() => {
                step.stepValidationRef!.current!();
            })
            // By default move to next step
            : dispatch(incActiveStep());
    }, [props, activeStep, dispatch])

    // useMemo to define previous step button component
    const PreviousStepButton = React.useMemo(() => (
        <Button
            variant='outlined'
            color='secondary'
            size='small'
            startIcon={<ArrowCircleDownIcon sx={{ transform: 'rotate(90deg)' }} />}
            onClick={handleAskPreviousStep}
            sx={cssStyles.controlButton}
        >
            {props.previousStepText ?? `Étape précédente`}
        </Button>
    ), [props, handleAskPreviousStep, cssStyles.controlButton])

    // useMemo to define next step button component
    const NextStepButton = React.useMemo(() => {
        // Try to retrieve a formik form id from step
        const formikFormId = props.steps[activeStep].formikFormId;
        // Determine if this is the final step
        const finalStep = activeStep === props.steps.length - 1;

        // Button will either move to next step or call formik form submition
        // Note that formik form submition need to move to next step by itself
        return (
            <Button
                type={Boolean(formikFormId) ? 'submit' : undefined}
                form={formikFormId}
                variant='outlined'
                size='small'
                endIcon={finalStep ? undefined : <ArrowCircleDownIcon sx={{ transform: 'rotate(-90deg)' }} />}
                onClick={Boolean(formikFormId) ? undefined : handleAskNextStep}
                sx={cssStyles.controlButton}
            >
                {finalStep ? props.validationText ?? `Validation` : props.nextStepText ?? `Étape suivante`}
            </Button>
        )
    }, [props, activeStep, handleAskNextStep, cssStyles.controlButton])

    return (
        <>
            <Header
                activeStep={activeStep}
                steps={props.steps}
                orientationBreakpoint={props.orientationBreakpoint}
                width={props.headerWidth}
            />
            <Box paddingY={7}>
                {props.steps[activeStep].component}
            </Box>
            <Box sx={{
                ...cssStyles.stepperControlsWrapper,
                ...(props.awareOfDashboardMenu && {
                    ...cssStyles.awareDashboardMenu,
                    paddingLeft: menuIsOpened ? appMenuWidthOpen : appMenuWidthClose,
                }),
            } as SxProps<Theme>}>
                <Stack
                    direction='row'
                    flexWrap='wrap'
                    gap={1}
                    justifyContent={0 === activeStep ? 'flex-end' : 'space-between'}
                    sx={cssStyles.stepperControls}
                >
                    <Typography sx={cssStyles.currentStep} color='primary'>
                        {`${activeStep + 1} - `}
                        <Typography component='span' color='text.primary'>
                            {props.steps[activeStep].label}
                        </Typography>
                    </Typography>
                    {
                        0 === activeStep ? (
                            NextStepButton
                        ) : (0 < activeStep && activeStep < props.steps.length - 1) ? (
                            <>
                                {PreviousStepButton}
                                {NextStepButton}
                            </>
                        ) : (
                            <>
                                {PreviousStepButton}
                                {props.validationComponent ?? NextStepButton}
                            </>
                        )
                    }
                </Stack>
            </Box>
            {
                true === loading && (
                    <Backdrop open={loading} sx={cssStyles.backdrop}>
                        <CircularProgress size='10vh' />
                    </Backdrop>
                )
            }
        </>
    );
};

export default Stepper;
