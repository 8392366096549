import React from 'react';
import DOMPurify from 'dompurify';
import { Box } from '@mui/material';

const PurifiedHtml: React.FC<{ html: string, onDoubleClick?: (() => void)|undefined }> = props => (
    <Box
        dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(props.html.replaceAll('<p></p>', '<br />'), {
                USE_PROFILES: { html: true },
                // Keep target attribute on link tags
                ADD_ATTR: ['target']
            })
        }}
        onDoubleClick={props.onDoubleClick}
    />
);

export default PurifiedHtml;
