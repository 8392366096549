import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { Stack, Typography } from '@mui/material';
import NamespacePanel from '../NamespacePanel/NamespacePanel';
import MissingBioTooltip from '../MissingBioTooltip/MissingBioTooltip';
import SvgPostToWrite from '../Svg/SvgPostToWrite';
import SvgPostAwaitingValidation from '../Svg/SvgPostAwaitingValidation';
import SvgPostRejected from '../Svg/SvgPostRejected';
import SvgPostHistory from '../Svg/SvgPostHistory';
import SvgMissionFailed from '../Svg/SvgMissionFailed';

const PostMenu: React.FC = () => {
    // Use of redux
    const instanceUserHasBio: boolean = useAppSelector(state => state.instance.user!.bio);

    // Define all panels components
    const TodoPostsPanel: JSX.Element = (
        <NamespacePanel
            notAllowed={!instanceUserHasBio}
            svg={<SvgPostToWrite sx={{ fontSize: '6rem' }} />}
            header='Mes articles à rédiger'
            text="Consulter la liste des articles qui m'ont été assignés."
            to='todo'
        />
    );
    const UnverifiedPostsPanel: JSX.Element = (
        <NamespacePanel
            notAllowed={!instanceUserHasBio}
            svg={<SvgPostAwaitingValidation sx={{ fontSize: '6rem' }} />}
            header='Mes articles en attente de validation'
            text='Consulter la liste des articles qui sont en attente de validation.'
            to='unverified'
        />
    );
    const RefusedPostsPanel: JSX.Element = (
        <NamespacePanel
            notAllowed={!instanceUserHasBio}
            svg={<SvgPostRejected sx={{ fontSize: '6rem' }} />}
            header='Mes articles refusés'
            text="Consulter la liste des articles qui m'ont été refusés."
            to='refused'
        />
    );
    const ArchivedPostsPanel: JSX.Element = (
        <NamespacePanel
            notAllowed={!instanceUserHasBio}
            svg={<SvgPostHistory sx={{ fontSize: '6rem' }} />}
            header='Historique de mes articles'
            text="Consulter l'historique de mes articles rédigés."
            to='archived'
        />
    );
    const FailedPostsPanel: JSX.Element = (
        <NamespacePanel
            svg={<SvgMissionFailed sx={{ fontSize: '6rem' }} />}
            notAllowed={!instanceUserHasBio}
            header='Mes missions échouées'
            text="Consulter l'historique de mes missions échouées."
            to='failed'
        />
    );

    return (
        <>
            <Typography component='h1' variant='h4' fontWeight='bold' paddingBottom={4}>
                {`Mes articles`}
            </Typography>
            <Stack direction='row' flexWrap='wrap' justifyContent='center' gap={2}>
                {
                    instanceUserHasBio ? (
                        <>
                            {TodoPostsPanel}
                            {UnverifiedPostsPanel}
                            {RefusedPostsPanel}
                            {ArchivedPostsPanel}
                            {FailedPostsPanel}
                        </>
                    ) : (
                        <>
                            <MissingBioTooltip wrapWithSpan>
                                {TodoPostsPanel}
                            </MissingBioTooltip>
                            <MissingBioTooltip wrapWithSpan>
                                {UnverifiedPostsPanel}
                            </MissingBioTooltip>
                            <MissingBioTooltip wrapWithSpan>
                                {RefusedPostsPanel}
                            </MissingBioTooltip>
                            <MissingBioTooltip wrapWithSpan>
                                {ArchivedPostsPanel}
                            </MissingBioTooltip>
                            <MissingBioTooltip wrapWithSpan>
                                {FailedPostsPanel}
                            </MissingBioTooltip>
                        </>
                    )
                }
            </Stack>
        </>
    );
};

export default PostMenu;
