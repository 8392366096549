import React from 'react';
import { Stack, CircularProgress, Typography, TypographyProps, StackProps } from '@mui/material';

const Spinner: React.FC<{
    isFullscreen?: boolean|undefined,
    typographyProps?: TypographyProps|undefined,
    size?: number|string|null|undefined,
}&StackProps> = ({ isFullscreen, typographyProps, size, ...stackProps }) => (
    <Stack
        justifyContent='center'
        alignItems='center'
        gap={2}
        {...stackProps}
        height={isFullscreen ? '100vh' : (stackProps.height ?? '60vh')}
        width={isFullscreen ? '100vw' : (stackProps.width ?? undefined)}
    >
        <CircularProgress size={size ?? 80} />
        {
            typographyProps?.children && (
                <Typography {...typographyProps} />
            )
        }
    </Stack>
);

export default Spinner;
