import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { Typography, Divider, Grid } from '@mui/material';
import {Check as CheckIcon } from '@mui/icons-material';
import ILink from '../../interfaces/ILink';

interface ILinkDisplay {
    url: string,
    anchor: string,
    inHtml: boolean,
};

const LinksDisplay: React.FC<{ links: Array<ILink> }> = props => {
    // Use of hook
    const [links, setLinks] = React.useState<Array<ILinkDisplay>>(props.links.map(link => ({ url: link.url, anchor: link.anchor, inHtml: false })));

    // Use of redux
    const html: string = useAppSelector(state => state.textEditor.content.html);

    // useEffect when html value changes
    React.useEffect(() => {
        const domLinks: Array<ILink> = [];
        // Create a virtual div in dom
        const htmlElement = document.createElement('div');
        // Add html value to virtual div
        htmlElement.innerHTML = html;
        // Get all links from virtual div
        htmlElement.querySelectorAll('a').forEach(link => {
            // Get link url
            let url = link.href.toLocaleLowerCase();
            // Get link anchor
            let anchor = link.innerText.toLocaleLowerCase();

            // Remove last / from url if any
            if (url.endsWith('/')) {
                url = url.substring(0, url.length - 1);
            }

            domLinks.push({ url, anchor });
        });

        // Update links display
        setLinks(links => links.map(link => {
            // Get link url
            let url = link.url.toLocaleLowerCase();
            // Get link anchor
            let anchor = link.anchor.toLocaleLowerCase();

            // Remove last / from url if any
            if (url.endsWith('/')) {
                url = url.substring(0, url.length - 1);
            }

            // If link is in html links, set inHtml to true
            const foundDomLink = domLinks.find(domLink => domLink.url === url && domLink.anchor === anchor);

            return { ...link, inHtml: undefined !== foundDomLink };
        }));
    }, [html]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Typography variant='h5' gutterBottom>
                        {`Liens obligatoires dans l'article`}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    {
                        links.map((link, index) => (
                            <Grid container spacing={2} paddingBottom={1} key={index}>
                                <Grid item xs={12} md={3}>
                                    <Typography
                                        display='flex'
                                        alignItems='center'
                                        gap={2}
                                        fontWeight={700}
                                        color={link.inHtml ? 'success.main' : undefined}
                                    >
                                        {
                                            link.inHtml && (
                                                <CheckIcon color='success' />
                                            )
                                        }
                                        {link.anchor}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography color={link.inHtml ? 'success.main' : undefined}>
                                        {link.url}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Divider variant='middle' sx={{ marginY: 4 }} />
        </>
    );
};

export default LinksDisplay;
