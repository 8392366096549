import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    Paper,
    Stack,
    Typography,
    Box,
    Avatar,
    Tooltip,
} from '@mui/material';
import {
    Key as KeyIcon,
    Title as TitleIcon,
    Settings as SettingsIcon,
    Spellcheck as SpellcheckIcon,
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    Close as CloseIcon,
    Check as CheckIcon,
} from '@mui/icons-material';
import { AiOutlineEuroCircle } from 'react-icons/ai';
import { MdEuro } from 'react-icons/md';
import { AiOutlineFieldNumber } from 'react-icons/ai';
import DeadlineTimer from '../DeadlineTimer/DeadlineTimer';
import PostActionButton from '../PostActionButton/PostActionButton';
import EAccountTypes from '../../interfaces/EAccountTypes';
import IOrder from '../../models/IOrder';
import cssStyles from './PostsProposedListingStyles';

const ProposedPost: React.FC<{ order: IOrder }> = props => {
    // Use of hooks
    const [disabled, isDisabled] = React.useState<boolean>(false);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceAccountType: string = useAppSelector(state => state.instance.account!.accountType.slug);

    return (
        <Paper sx={cssStyles.postPaper}>
            <Stack direction='row' flexWrap='wrap' width={1}>
                <Stack sx={cssStyles.leftSide}>
                    <DeadlineTimer
                        deadline={props.order.outdatedAt}
                        onDeadlineReached={() => isDisabled(true)}
                    />
                    {
                        EAccountTypes.MEMBER === instanceAccountType && (
                            <Stack alignItems='center' paddingTop={1}>
                                <Typography variant='caption' color='textSecondary' lineHeight={1}>
                                    {`Par :`}
                                </Typography>
                                <Stack direction='row' justifyContent='center' alignItems='center' columnGap={2}>
                                    <Avatar
                                        src={props.order.user.avatar ?? undefined}
                                        sx={cssStyles.avatar}
                                        children={`${props.order.user.name[0]}${props.order.user.lastname[0]}`}
                                    />
                                    <Typography sx={cssStyles.owner}>
                                        {`${props.order.user.name} ${props.order.user.lastname}`}
                                    </Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                </Stack>
                <Stack gap={1} sx={cssStyles.rightSide}>
                    <Typography sx={cssStyles.rowData}>
                        <KeyIcon sx={cssStyles.rowDataSvg} />
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataTitle}>
                            {` Mot-clé : `}
                        </Typography>
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataLabel}>
                            {props.order.keyword.name}
                        </Typography>
                    </Typography>
                    {
                        (props.order.post?.title || props.order.title) && (
                            <Typography sx={cssStyles.rowData}>
                                <TitleIcon sx={cssStyles.rowDataSvg} />
                                <Typography component='span' variant='body2' sx={cssStyles.rowDataTitle}>
                                    {` Titre : `}
                                </Typography>
                                <Typography component='span' variant='body2' sx={cssStyles.rowDataLabel}>
                                    {props.order.post?.title ?? props.order.title}
                                </Typography>
                            </Typography>
                        )
                    }
                    <Typography sx={cssStyles.rowData}>
                        <SettingsIcon sx={cssStyles.rowDataSvg} />
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataTitle}>
                            {` Taux d'optimisation : `}
                        </Typography>
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataLabel}>
                            {props.order.minOptimization ? `${props.order.minOptimization}%` : 'Libre'}
                        </Typography>
                    </Typography>
                    <Typography sx={cssStyles.rowData}>
                        <Box component={AiOutlineFieldNumber} sx={cssStyles.rowDataSvg} />
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataTitle}>
                            {` Nombre de mots : `}
                        </Typography>
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataLabel}>
                            {`${props.order.minNbrWords} minimum`}
                        </Typography>
                    </Typography>
                    <Typography sx={cssStyles.rowData}>
                        <SpellcheckIcon sx={cssStyles.rowDataSvg} />
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataTitle}>
                            {` Niveau d'orthographe : `}
                        </Typography>
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataLabel}>
                            {`${props.order.syntaxLevel.name} `}
                        </Typography>
                        <Tooltip title={props.order.syntaxLevel.description}>
                            <HelpOutlineOutlinedIcon sx={cssStyles.rowDataHelpSvg} />
                        </Tooltip>
                    </Typography>
                    <Typography sx={cssStyles.rowData}>
                        <Box component={MdEuro} sx={cssStyles.rowDataSvg} />
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataTitle}>
                            {` Tarif HT : `}
                        </Typography>
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataLabel}>
                            {`${props.order.price} €`}
                        </Typography>
                    </Typography>
                    <Typography sx={cssStyles.rowData}>
                        <Box component={AiOutlineEuroCircle} sx={cssStyles.rowDataSvg} />
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataTitle}>
                            {` Tarif HT au mot : `}
                        </Typography>
                        <Typography component='span' variant='body2' sx={cssStyles.rowDataLabel}>
                            {`${(Number(props.order.price) / props.order.minNbrWords * 100).toFixed(1)} cents €/mot`}
                        </Typography>
                    </Typography>
                    <Stack sx={cssStyles.postFooter} columnGap={2}>
                        <PostActionButton
                            disabled={disabled}
                            icon={CloseIcon}
                            tooltip='Refuser la proposition'
                            onClick={() => dispatch(openModal({ name: 'refuseOrderModal', params: props.order }))}
                        />
                        <PostActionButton
                            disabled={disabled}
                            icon={CheckIcon}
                            tooltip='Accepter la proposition'
                            onClick={() => dispatch(openModal({ name: 'acceptProposeModal', params: props.order }))}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default ProposedPost;
