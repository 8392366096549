import React from 'react';
import { useDropzone } from 'react-dropzone';
import CsvParser from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setImportCsvState } from '../../store/slices/importCsvSlice';
import { Stack, Typography } from '@mui/material';
import DisplayCsv from './DisplayCsv';
import DownloadTemplate from './DownloadTemplate';

const delimiterToGuess = [',', '\t', '|', ';', CsvParser.RECORD_SEP, CsvParser.UNIT_SEP];

const ImportCsvFile: React.FC = () => {
    // Use of hooks
    const [error, setError] = React.useState<string | null>(null);
    const [file, setFile] = React.useState<File | null>(null);

    // Use of redux hooks
    const dispatch = useAppDispatch();
    const expectedHeaders = useAppSelector(state => state.importCsv.expectedHeaders);
    const dataValidation = useAppSelector(state => state.importCsv.dataValidation);
    const csvData = useAppSelector(state => state.importCsv.csvData);
    const csvHeaders = useAppSelector(state => state.importCsv.csvHeaders);

    // Use of react-dropzone hook
    const { getRootProps, getInputProps } = useDropzone({ onDrop: ([file]) => setFile(file), accept: '.csv', maxFiles: 1 });

    // useEffect to handle file drop or select
    React.useEffect(() => {
        if (file) {
            const dataValidationKeys = dataValidation ? Object.keys(dataValidation) : [];
            setError(null);
            // Load during parsing and reset states
            dispatch(setImportCsvState({ loading: true, csvData: null, csvHeaders: null, sortedHeaders: null }));
            // Parse CSV file into javascript object
            CsvParser.parse<Record<string | number, string>, File>(file, {
                header: true,
                skipEmptyLines: true,
                dynamicTyping: true,
                delimitersToGuess: delimiterToGuess,
                transform: (value, header) => {
                    // Get lowercase value of header
                    header = String(header).toLowerCase();
                    // Retrieve the header from expected headers
                    const headerValue = expectedHeaders?.find(t => t.label.toLowerCase() === header || t.value.toLowerCase() === header)?.value;
                    // Check that the header expected value type is array
                    if (dataValidation && headerValue && dataValidationKeys.includes(headerValue) && 'array' === dataValidation[headerValue].type) {
                        // Try to find a delimiter in the value
                        const arrayDelimiter = delimiterToGuess.find(delimiter => value.includes(delimiter));

                        // If a delimiter is found, split value by the delimiter
                        return arrayDelimiter ?
                            value.split(arrayDelimiter)
                            : value;
                    }

                    return value;
                },
                // On parse complete
                complete: (results) => {
                    const data = results.data;
                    const header = Object.keys(data[0]);
                    // Update redux state
                    dispatch(setImportCsvState({ loading: false, csvData: data, csvHeaders: header, sortedHeaders: header }));
                },
                error: (error) => {
                    setError(error.message);
                    // Stop loading and reset states
                    dispatch(setImportCsvState({ loading: false, csvData: null, csvHeaders: null, sortedHeaders: null }));
                },
            });
        }
    }, [file, dispatch, expectedHeaders, dataValidation]);

    return (
        <>
            <DownloadTemplate />
            <Stack
                {...getRootProps()}
                justifyContent='center'
                alignItems='center'
                padding={5}
                border='2px dashed grey'
                borderRadius={5}
                sx={{ cursor: 'pointer' }}
            >
                <input {...getInputProps()} />
                <Stack alignItems='center' color='textSecondary'>
                    <Typography align='center' gutterBottom>
                        {'Glissez-déposez un fichier CSV ici, ou cliquez pour sélectionner un fichier CSV'}
                    </Typography>
                    <Typography variant='caption'>
                        {`Formats acceptés *.csv`}
                    </Typography>
                </Stack>
            </Stack>
            {
                csvData && csvHeaders && (
                    <DisplayCsv csvData={csvData} csvHeader={csvHeaders} hasDefinedHeaderKeys maxDisplayedRows={10} />
                )
            }
            {
                error && (
                    <Typography color='error.main'>{error}</Typography>
                )
            }
        </>
    );
};

export default ImportCsvFile;
