import React from 'react';
import * as Yup from 'yup';
import { FormApi } from 'final-form';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal, IActiveModalState } from '../../store/slices/activeModalSlice';
import useValidationSchema from '../../hooks/useValidationSchema';
import DestinationPicker from '../DestinationPicker/DestinationPicker';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import FinalForm from '../FinalForm/FinalForm';
import GenericModal from './GenericModal';
import IDestinationWebsite from '../../interfaces/IDestinationWebsite';
import IWpCategory from '../../interfaces/IWpCategory';
import ISelectOption from '../../interfaces/ISelectOption';

type FormType = {
    thematic: ISelectOption|null,
    website: ISelectOption|null,
    category: ISelectOption|null,
};

const ModalDestinationPicker: React.FC<{
    name?: string|undefined,
    onClose?: (() => void)|undefined,
    onValidation?: ((destinationWebsite: IDestinationWebsite, index: number, params: any) => void)|undefined,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'destinationPicker');
    const mountedRef = React.useRef<boolean>(true);
    const wpCategories = React.useRef<Array<IWpCategory>>([]);
    // Define initial values for formik form
    const initialValues = React.useRef<FormType>({
        thematic: null,
        website: null,
        category: null,
    });

    // Use of redux
    const dispatch = useAppDispatch();
    const activeModalState: IActiveModalState = useAppSelector(state => state.activeModal);

    // Callback triggered when modal is closing
    const handleModalClose = React.useCallback((resetFormFc: any) => {
        // Reset the form
        resetFormFc();
        // Call the given callback
        props.onClose && props.onClose();
    }, [props]);

    // Define yup validation schema
    const validateSchema = useValidationSchema<FormType>({
        thematic: Yup
            .object()
            .nullable()
            .required('Veuillez renseigner une thématique de site'),
        website: Yup
            .object()
            .nullable()
            .required('Veuillez renseigner un site de destination'),
        category: Yup
            .object()
            .nullable()
            .required('Veuillez renseigner une catégorie de destination sur le site'),
    });

    // Callback which define onSubmit of form
    const handleSubmit = React.useCallback((values: FormType, form: FormApi<FormType, Partial<FormType>>) => {
        // Find the category by selected value
        const category = wpCategories.current!.find((category: IWpCategory) => category.wordPressId === +values.category!.value)!;
        // Call given prop modal validation callback
        props.onValidation && props.onValidation(
            {
                thematic: values.thematic!,
                website: values.website!,
                category: category
            },
            activeModalState.params?.index ?? 0,
            activeModalState.params
        );
        // Submission also close modal
        dispatch(closeModal());
        handleModalClose(form.reset);
    }, [props, activeModalState, dispatch, handleModalClose]);

    // useEffect when component is unmounting
    React.useEffect(() => () => {
        // Set mountedRef to false;
        mountedRef.current = false;
    }, []);

    return (
        <GenericModal
            name={modalName.current}
            title='Sélectionner une destination'
        >
            <FinalForm<FormType>
                initialValues={initialValues.current}
                validateSchema={validateSchema}
                onSubmit={(values, form) => handleSubmit(values, form!)}
                gap={2}
            >
                <DestinationPicker onCategoriesLoaded={(categories) => wpCategories.current = categories} />
                <FinalSubmitButton
                    variant='outlined'
                    type='submit'
                    color='inherit'
                >
                    {`Valider`}
                </FinalSubmitButton>
            </FinalForm>
        </GenericModal>
    );
};

export default ModalDestinationPicker;
