import React from 'react';
import moment from 'moment-timezone';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import {
    Paper,
    Stack,
    Button,
    Typography,
    FormHelperText,
    IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import FinalDatePicker from '../FinalComponents/FinalDatePicker';
import FinalInput from '../FinalComponents/FinalInput';
import FinalCheckbox from '../FinalComponents/FinalCheckbox';
import FinalSelect from '../FinalComponents/FinalSelect';
import FinalFields from '../FinalComponents/FinalFields';
import IPeriodicityType, { periodicityTypes } from '../../interfaces/IPeriodicityType';

export type PublishDataType = {
    postNumber: number,
    estimatedNbrWords: number,
    optimized: boolean,
    startAt: string|null,
    hasPeriodicity: boolean,
    periodicityType?: IPeriodicityType|null|undefined,
    periodicity?: number|null|undefined,
    endAt?: string|null|undefined,
};

const PublishData: React.FC<{ parentName: string }> = ({ parentName }) => {
    // Initial value of publication information
    const initialPublishData: PublishDataType = {
        postNumber: 1,
        estimatedNbrWords: 500,
        optimized: false,
        startAt: null,
        hasPeriodicity: false,
        periodicityType: periodicityTypes[0],
        endAt: null,
    };

    // Callback to get FieldArray field value
    function getValue<T = any>(fieldsState: any, parentName: string, name: string): T {
        return fieldsState[`${parentName}.${name}`].input.value;
    };

    // Callback to generate helper text for periodicity
    const getHelperText = (fieldsState: any, parentName: string): React.ReactElement|null => {
        const postNumber = Number(getValue<number>(fieldsState, parentName, 'postNumber'));
        const startAt = getValue<string>(fieldsState, parentName, 'startAt');
        const hasPeriodicity = getValue<boolean>(fieldsState, parentName, 'hasPeriodicity');
        const periodicityType = getValue<IPeriodicityType>(fieldsState, parentName, 'periodicityType');
        const endDate = getValue<string>(fieldsState, parentName, 'endAt');
        // Calculate total number of post publications
        const totalPosts = endDate && startAt && periodicityType && postNumber ?
            // Diff in periodicity type (hour/day/week/month/year) between startAt and endAt
            // Multiply by the number of posts by publication
            // Add postNumber, so the first publication is counted
            moment(endDate).diff(moment(startAt), periodicityType.value as any) * postNumber + postNumber
            : null;

        return (
            postNumber && startAt ? (
                <Typography variant='body2' marginTop={2}>
                    {`Publier ${postNumber} article${1 < postNumber ? 's' : ''} le ${moment(startAt).format('ddd DD/MM/YYYY à HH:mm')}.`}
                    {
                        true === hasPeriodicity && periodicityType && (
                            <>
                                <br />
                                {`Répéter ${periodicityType.display}`}
                                {endDate ? (` jusqu'au ${moment(endDate).format('ddd DD/MM/YYYY à HH:mm')}.`) : '.'}
                                {
                                    totalPosts && (
                                        <>
                                            <br />
                                            {`Soit au total ${totalPosts} article${1 < totalPosts ? 's' : ''}.`}
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </Typography>
            ) : null
        );
    };

    return (
        <Stack gap={1}>
            <Typography>{`Dates de publication`}</Typography>
            <FieldArray
                name={`${parentName}.publishData`}
                subscription={{}}
                render={({ fields }) => (
                    // For each category's publish data
                    <>
                        <Stack direction='row' flexWrap='wrap' alignItems='flex-start' width={1} gap={2}>
                            {
                                fields.map((name, index) => (
                                    <Paper key={name}>
                                        <Stack width={1} padding={2}>
                                            <IconButton
                                                color='error'
                                                onClick={() => fields.remove(index)}
                                                sx={{ alignSelf: 'flex-end' }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <Stack direction='row' columnGap={2}>
                                                <Stack rowGap={2}>
                                                    <FinalInput
                                                        name={`${name}.postNumber`}
                                                        size='small'
                                                        upperLabel
                                                        label="Nombre d'article à publier"
                                                        numberFormatProps={{ decimalScale: 0 }}
                                                    />
                                                    <Field
                                                        name={`${name}.optimized`}
                                                        subscription={{ value: true }}
                                                        render={({ input: { value: optimized } }) => (
                                                            <FinalInput
                                                                name={`${name}.estimatedNbrWords`}
                                                                size='small'
                                                                upperLabel
                                                                label='Estimation du nombre de mots par article'
                                                                disabled={optimized}
                                                                numberFormatProps={{ decimalScale: 0 }}
                                                            />
                                                        )}
                                                    />
                                                    <FinalCheckbox
                                                        name={`${name}.optimized`}
                                                        label="Optimiser l'article sur le mot clé"
                                                    />
                                                    <FinalDatePicker
                                                        name={`${name}.startAt`}
                                                        size='small'
                                                        label='Date de publication'
                                                        upperLabel
                                                        views={['day', 'hours']}
                                                        minDateTime={moment().add(48, 'h').toDate()}
                                                    />
                                                    <FinalCheckbox
                                                        name={`${name}.hasPeriodicity`}
                                                        label='Répéter la publication'
                                                        size='small'
                                                        wrapperSx={{ justifyContent: 'center' }}
                                                    />
                                                </Stack>
                                                <Field
                                                    name={`${name}.hasPeriodicity`}
                                                    subscription={{ value: true, pristine: true }}
                                                    render={({ input: { value: hasPeriodicityValue }}) => (
                                                        true === hasPeriodicityValue && (
                                                            <Stack rowGap={2}>
                                                                <FinalSelect
                                                                    name={`${name}.periodicityType`}
                                                                    size='small'
                                                                    label='Répéter tous les'
                                                                    options={periodicityTypes}
                                                                />
                                                                <FinalFields
                                                                    names={[`${name}.startAt`, `${name}.periodicityType`]}
                                                                    subscription={{ value: true }}
                                                                    render={(fieldsState) => (
                                                                        <FinalDatePicker
                                                                            name={`${name}.endAt`}
                                                                            size='small'
                                                                            label='Dernière publication'
                                                                            upperLabel
                                                                            views={['day', 'hours']}
                                                                            // Min date is the start date + 1 periodicityType
                                                                            minDateTime={moment(getValue(fieldsState, name, 'startAt')).add(1, getValue(fieldsState, name, 'periodicityType').value).toDate()}
                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                        )
                                                    )}
                                                />
                                            </Stack>
                                            <FinalFields
                                                names={[`${name}.postNumber`, `${name}.startAt`, `${name}.hasPeriodicity`, `${name}.periodicityType`, `${name}.periodicity`, `${name}.endAt`]}
                                                subscription={{ value: true }}
                                                render={(fieldsState) => (getHelperText(fieldsState, name))}
                                            />
                                        </Stack>
                                    </Paper>
                                ))
                            }
                        </Stack>
                        <Button
                            variant='outlined'
                            onClick={() => fields.push(initialPublishData)}
                            sx={{ alignSelf: 'flex-start' }}
                        >
                            {`Ajouter une publication`}
                        </Button>
                    </>
                )}
            />
            <Field
                name={`${parentName}.publishData`}
                subscription={{ error: true, touched: true }}
                render={({ meta: { error, touched } }) => (
                    touched && 'string' === typeof error ? (
                        <FormHelperText error>{error}</FormHelperText>
                    ) : null
                )}
            />
        </Stack>
    );
};

export default PublishData;
