import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgManageSites: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <polygon fill='#F2F2F2' points='482.129,69.333 461.863,50.133 286.93,226.133 275.197,214.4 266.663,221.867 262.396,217.6 255.996,224 67.196,35.2 47.996,54.4 236.797,243.2 36.263,443.733 55.463,462.933 230.396,288 246.396,305.067 251.729,299.733 251.729,471.467 278.396,471.467 278.396,285.867 283.729,290.133 297.596,275.2 458.663,437.333 477.863,417.067 306.129,245.333' />
            <circle fill='#FF7058' cx='265.596' cy='224' r='141.87' />
            <path fill='#F1543F' d='M154.664,234.667c-6.4,0-10.667-4.267-10.667-10.667c0-66.133,54.4-120.533,120.533-120.533c6.4,0,10.667,4.267,10.667,10.667s-4.267,10.667-10.667,10.667c-54.4,0-99.2,44.8-99.2,99.2C165.329,229.333,161.064,234.667,154.664,234.667z' />
            <circle fill='#FFFFFF' cx='265.596' cy='224' r='61.867' />
            <circle fill='#FFD15C' cx='57.596' cy='44.8' r='44.8' />
            <circle opacity='0.5' fill='#FFFFFF' enableBackground='new' cx='36.266' cy='23.467' r='10.667' />
            <circle fill='#84DBFF' cx='468.266' cy='61.867' r='44.8' />
            <circle fill='#4CDBC4' cx='468.266' cy='424.53' r='44.8' />
            <circle fill='#54C0EB' cx='265.596' cy='469.33' r='44.8' />
            <circle fill='#CDD6E0' cx='45.863' cy='453.33' r='44.8' />
            <g>
                <circle opacity='0.5' fill='#FFFFFF' enableBackground='new' cx='446.926' cy='40.533' r='10.667' />
                <circle opacity='0.5' fill='#FFFFFF' enableBackground='new' cx='446.926' cy='403.2' r='10.667' />
                <circle opacity='0.5' fill='#FFFFFF' enableBackground='new' cx='244.267' cy='448' r='10.667' />
                <circle opacity='0.5' fill='#FFFFFF' enableBackground='new' cx='24.529' cy='432' r='10.667' />
            </g>
        </SvgIcon>
    );
};

export default SvgManageSites;
