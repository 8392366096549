import React from 'react';
import { FormSpy } from 'react-final-form';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

type FinalSubmitButtonProps = {
    enabledOnPristine?: boolean|undefined,
    enabledOnInvalid?: boolean|undefined,
    submitOnEnter?: boolean|undefined,
};

const FinalSubmitButton: React.FC<LoadingButtonProps&FinalSubmitButtonProps> =
({ enabledOnPristine, enabledOnInvalid, fullWidth, children, sx, submitOnEnter, ...loadingButtonProps }) => {
    return (
        <FormSpy
            subscription={{ pristine: true, valid: true, submitting: true }}
            render={({ pristine, valid, submitting, form }) => (
                <LoadingButton
                    {...loadingButtonProps}
                    disabled={(enabledOnPristine ? false : enabledOnInvalid ? false : pristine) || (enabledOnInvalid ? false : !valid)}
                    loading={submitting}
                    onClick={form.submit}
                    type={submitOnEnter ? 'submit' : undefined}
                    sx={{ ...sx, width: fullWidth ? 1 : undefined }}
                >
                    {children}
                </LoadingButton>
            )}
        />
    );
};

export default FinalSubmitButton;
