import React from 'react';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { Formik, Form, FormikProps } from 'formik';
import {
    Typography,
    Stack,
    Divider,
} from '@mui/material';
import FormikCheckbox from '../FormikComponent/FormikCheckbox';
import FormikInput from '../FormikComponent/FormikInput';
import FormikPhone from '../FormikComponent/FormikPhone';
import FormikSelect from '../FormikComponent/FormikSelect';
import RouterLink from '../RouterLink/RouterLink';
import SelectCountry from '../SelectEntities/SelectCountry';
import ISignUpStepperData from '../../interfaces/ISignUpStepperData';
import ICountry from '../../models/ICountry';

const MainInfo: React.FC<{ formikFormId: string }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();
    const signUpStepperState: ISignUpStepperData = useAppSelector(state => state.activeStepper.data);
    const countries: Array<ICountry> = useAppSelector(state => state.countries.data!);

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        civility: Yup
            .mixed()
            .required('Veuillez renseigner votre civilité'),
        name: Yup
            .string()
            .required('Veuillez renseigner votre prénom'),
        lastname: Yup
            .string()
            .required('Veuillez renseigner votre nom'),
        birthday: Yup
            .string()
            .test(
                'date',
                'Veuillez spécifier une date valide',
                function (birthday: string|undefined) {
                    return undefined !== birthday &&
                        moment(birthday, 'YYYY-MM-DD', true).isValid() &&
                        moment(birthday) < moment().subtract(18, 'years');
                }
            )
            .required('Veuillez indiquer votre date de naissance'),
        phonePrefix: Yup
            .string()
            .required('Veuillez renseigner un préfix de numéro de téléphone'),
        phone: Yup
            .string()
            .length(9, 'Veuillez renseigner un numéro de téléphone valide')
            .required('Veuillez renseigner un numéro de téléphone'),
        email: Yup
            .string()
            .email('Veuillez renseigner une adresse email valide')
            .required('Veuillez renseigner une adresse email'),
        username: Yup
            .string()
            .required("Veuillez renseigner un nom d'utilisateur"),
        password: Yup
            .string()
            .min(8, 'Votre mot de passe doit contenir un minimum de 8 caractères')
            .required('Veuillez spécifier un mot de passe'),
        confirmPassword: Yup
            .string()
            .required('Veuillez confirmer votre mot de passe')
            .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
        hasNewsletter: Yup.boolean(),
        terms: Yup
            .boolean()
            .oneOf([true], "Veuillez lire et accepter les conditions générales de ventes et d'utilisation"),
    });

    // Define yup initial values for formik form
    const initialValues: any = {
        country: signUpStepperState.country ?? null,
        civility: signUpStepperState.user?.civility ?? { value: 'M.', label: 'M.' },
        name: signUpStepperState.user?.name ?? '',
        lastname: signUpStepperState.user?.lastname ?? '',
        email: signUpStepperState.user?.email ?? '',
        username: signUpStepperState.user?.username ?? '',
        birthday: signUpStepperState.user?.birthday ?? '',
        phonePrefix: signUpStepperState.user?.phonePrefix ?? '',
        phone: signUpStepperState.user?.phone ?? '',
        password: signUpStepperState.user?.password ?? '',
        confirmPassword: signUpStepperState.user?.confirmPassword ?? '',
        hasNewsletter: signUpStepperState.user?.hasNewsletter ?? false,
        terms: signUpStepperState.terms ?? false,
    };

    // Callback to hande formik submit
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Store values under data field of active stepper redux state & increment active step
        dispatch(setActiveStepperData({ data: {
            user: {
                ...values,
                // Remove not user related fields
                country: undefined,
                terms: undefined,
            },
            terms: values.terms,
            country: values.country
        }, action: 'inc' }));
    }, [dispatch]);

    // Callback use to reset formik fields to their initial value
    const resetFormikFields = React.useCallback((formikProps: FormikProps<any>, fields: Array<string>) => {
        // Use pre-build formik function
        fields.forEach((field) => formikProps.setFieldValue(field, formikProps.initialValues[field], false));
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form id={props.formikFormId}>
                    <Stack paddingX={{ md: 15 }}>
                        <SelectCountry
                            name='country'
                            label='Pays de résidence'
                            fullWidth={false}
                            sx={{ paddingBottom: 3 }}
                            onChange={option => {
                                if (option) {
                                    // Change phonePrefix value to the selected country phone prefix and reset phone value
                                    resetFormikFields(formikProps, ['phone']);
                                    const country = countries.find(country => country.id === Number(option.value))!;
                                    formikProps.setFieldValue('phonePrefix', country.phonePrefix, false);
                                }
                            }}
                        />
                        <Stack direction={{ xs: 'column', md: 'row' }} columnGap={2} rowGap={2} paddingBottom={3}>
                            <Stack direction={{ xs: 'column', md: 'row' }} columnGap={2} rowGap={2} width={{ md: 1/2 }}>
                                <FormikSelect
                                    name='civility'
                                    options={[
                                        { value: 'M.', label: 'M.' },
                                        { value: 'Mme.', label: 'Mme.' },
                                    ]}
                                    sx={{ width: { md: 1/3 }}}
                                />
                                <FormikInput
                                    name='name'
                                    label='Prénom'
                                />
                            </Stack>
                            <FormikInput
                                name='lastname'
                                label='Nom'
                                sx={{ width: { md: 1/2 }}}
                            />
                        </Stack>
                        <Stack direction={{ xs: 'column', md: 'row' }} columnGap={2} rowGap={2} paddingBottom={3}>
                            <FormikInput
                                name='birthday'
                                label='Date de naissance'
                                type='date'
                            />
                            <FormikPhone
                                formikProps={formikProps}
                                prefixName='phonePrefix'
                                phoneName='phone'
                                label='Numéro de téléphone'
                            />
                        </Stack>
                        <Stack direction={{ xs: 'column', md: 'row' }} columnGap={2} rowGap={2} paddingBottom={3}>
                            <FormikInput
                                name='email'
                                label='Adresse email'
                            />
                            <FormikInput
                                name='username'
                                label="Nom d'utilisateur"
                            />
                        </Stack>
                        <Stack direction={{ xs: 'column', md: 'row' }} columnGap={2} rowGap={2} paddingBottom={3}>
                            <FormikInput
                                type='password'
                                name='password'
                                label='Mot de passe'
                                helperText='Le mot de passe doit avoir au minimum 8 caractères.'
                            />
                            <FormikInput
                                type='password'
                                name='confirmPassword'
                                label='Confirmer le mot de passe'
                            />
                        </Stack>
                        <Divider sx={{ marginY: 2 }} />
                        <FormikCheckbox
                            name='hasNewsletter'
                            label={
                                <Typography variant='body2'>
                                    {`Je m'inscris à la newsletter de ${process.env.REACT_APP_NAME}`}
                                </Typography>
                            }
                        />
                        <FormikCheckbox
                            name='terms'
                            label={
                                <Typography variant='body2'>
                                    {`J'ai lu et accepté les `}
                                    <RouterLink
                                        anchor={`conditions générales de ventes & d'utilisation`}
                                        to='/policy'
                                        target='_blank'
                                    />
                                </Typography>
                            }
                        />
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default MainInfo;
