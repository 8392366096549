import GenericAPIs from './GenericAPIs';

/**
 * SecurityAPIs class.
 */
export default class SecurityAPIs {
    /**
     * Sign up a new account.
     *
     * @param body
     */
    public static async signUp(body: any) {
        return GenericAPIs.apiPostCall('sign_up', body);
    }

    /**
     * Sign in an user.
     *
     * @param query
     */
    public static async signIn(query: any) {
        return GenericAPIs.apiGetCall('sign_in', null, query, false, false);
    }

    /**
     * Active account.
     *
     * (make an User ressource verified)
     */
    public static async activeAccount(customerId: string, apiKey: string) {
        return GenericAPIs.apiPatchCall(`active_account/${customerId}`, null, {}, apiKey);
    }

    /**
     * Active invitation
     *
     * @param
     */
    public static async activeInvitation(body: any) {
        return GenericAPIs.apiPostCall(`active_invitation`, body);
    }

    /**
     * Send email to reset password of an User.
     *
     * @param email
     */
    public static async forgotPassword(email: string) {
        return GenericAPIs.apiGetCall(`forgot_password`, null, { email: email }, false, false);
    }
};
