import React from 'react';
import { useAppSelector } from '../../store/hooks';
import OrderAPIs from '../../APIs/OrderAPIs';
import OtherAPIs from '../../APIs/OtherAPIs';
import Tools from '../../helpers/Tools';
import {
    useTheme,
    Alert,
    Typography,
    Stack,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import ModalConfirmation from '../Modal/ModalConfirmation';
import ProposedPost from './ProposedPost';
import RouterLink from '../RouterLink/RouterLink';
import Spinner from '../Spinner/Spinner';
import ModalRefuseOrder from '../Modal/ModalRefuseOrder';
import PublicOrderContract from '../PublicOrderContract/PublicOrderContract';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IAlert from '../../interfaces/IAlert';
import IOrder from '../../models/IOrder';
import IOrderRefuseMessage from '../../models/IOrderRefuseMessage';
import IUploadFile from '../../models/IUploadFile';
import IOrderProposal from '../../models/IOrderProposal';

const PostsProposedListing: React.FC = () => {
    // Use of MUI theme
    const theme = useTheme();

    // Use of hooks
    const [orderProposals, setOrderProposals] = React.useState<Array<IOrderProposal>|null>(null);
    const [loading, isLoading] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<IAlert|null>(null);
    const acceptedContract = React.useRef<Blob|null>(null);
    const isMounted = React.useRef<boolean>(true);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === orderProposals &&
            // Get active User's OrderProposals
            OrderAPIs.getOrderProposal({ editor: instanceUserId, isActive: true })
                // API call successful
                .then(response => true === isMounted.current && null !== response && setOrderProposals(response.data))
    }, [orderProposals, instanceUserId]);

    // useEffect when component is unmounting
    React.useEffect(() => () => {
        // Set isMounted ref to false to prevent state changes on current requests
        isMounted.current = false;
    }, []);

    // Callback used to handle action on a post proposal
    const handleAcceptOrder = React.useCallback(async (order: IOrder) => {
        // Load during API call
        isLoading(true);

        // Upload Order mission contract
        await OtherAPIs.postUploadFile(acceptedContract.current!, `contract_${order.publicId}`)
            .then((data: IUploadFile) => {
                // On upload successful, update Order mission contract field with uploaded file URL
                order = { ...order, missionContract: data.url };
            });

        // Call Order API to accept order
        OrderAPIs.patchOrder(order.id, {
            // Set status to accepted
            status: EOrderStatuses.TODO,
            missionContract: order.missionContract,
            editor: instanceUserId,
            owner: order.user.id,
        })
            // On successfull API call
            .then((data: Array<IOrder>) => {
                // Remove order from list
                if (null !== data && data[0]) {
                    setOrderProposals(orderProposals => orderProposals!.filter(orderProposal => data[0].id !== orderProposal.order.id));
                    // Display an alert
                    setAlert({
                        severity: 'success',
                        message: (
                            <>
                                <Typography>
                                    {`La proposition a bien été acceptée.`}
                                </Typography>
                                <Typography>
                                    {`Vous pouvez retrouver la commande dans `}
                                    <RouterLink
                                        anchor='Mes articles'
                                        to='../todo'
                                        color='textPrimary'
                                    />
                                    {`.`}
                                </Typography>
                            </>
                        ),
                    })
                    // Scroll to top
                    Tools.scrollInDashboard('top');
                }
            })
            // In any cases, stop loading
            .finally(() => isLoading(false));
    }, [instanceUserId]);

    // Callback used to handle action on a post proposal
    const handleRefuseOrder = React.useCallback((order: IOrder, reasons: Array<string>, message: string|undefined) => {
        // Load during API call
        isLoading(true);
        // Call Order API to refuse order
        OrderAPIs.postOrderRefuseMessage(
            Tools.convertToBodyRequest({
                order: order.id,
                reasons: reasons,
                message: message,
                user: instanceUserId,
            }, true)
        )
            // On successfull API call
            .then((data: IOrderRefuseMessage) => {
                // Remove order from list
                if (null !== data) {
                    setOrderProposals(orderProposals => orderProposals!.filter(orderProposal => data.order.id !== orderProposal.order.id));
                    // Display an alert
                    setAlert({
                        severity: 'success',
                        message: (
                            <Typography>
                                {`La proposition a bien été refusée.`}
                            </Typography>
                        ),
                    })
                    // Scroll to top
                    Tools.scrollInDashboard('top');
                }
            })
            // In any cases, stop loading
            .finally(() => isLoading(false));
    }, [instanceUserId]);

    // useMemo on PorposedPosts to prevent re-rendering when alert or backdrop hook values change
    const MemoizedProposedPosts = React.useMemo(() => (
        null !== orderProposals ? (
            orderProposals.map((orderProposal: IOrderProposal, index: number) => (
                <ProposedPost key={index} order={orderProposal.order} />
            ))
        ) : null
    ), [orderProposals])

    return (
        <>
            {
                null !== alert && (
                    <Alert sx={{ marginBottom: 3 }} onClose={() => setAlert(null)} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                )
            }
            <Typography variant='h5' paddingBottom={3} color='primary.main'>
                {`Mes propositions d'articles :`}
            </Typography>
            {
                null !== orderProposals ? (
                    0 < orderProposals.length ? (
                        <>
                            <ModalRefuseOrder onValidation={handleRefuseOrder} />
                            <ModalConfirmation
                                name='acceptProposeModal'
                                width={3/5}
                                title='Vous êtes sur le point de rédiger un nouvel article'
                                validationButtonColor='success'
                                message={(modalParams) =>
                                    <PublicOrderContract
                                        order={modalParams}
                                        commission={20}
                                        onContractGenerated={pdf => { acceptedContract.current = pdf }}
                                    />
                                }
                                onValidation={(order) => handleAcceptOrder(order)}
                            />
                            <Stack spacing={3}>
                                {MemoizedProposedPosts}
                            </Stack>
                        </>
                    ) : (
                        <Typography variant='body2' color='textSecondary' align='center'>
                            {`Aucune proposition d'article pour le moment ...`}
                        </Typography>
                    )
                ) : (<Spinner />)
            }
            {
                true === loading && (
                    <Backdrop open={loading} sx={{ zIndex: theme.zIndex.drawer - 1 }}>
                        <CircularProgress size='10vh' />
                    </Backdrop>
                )
            }
        </>
    );
};

export default PostsProposedListing;
