import React from 'react';
import { useDropzone } from 'react-dropzone';
import {
    SxProps,
    Theme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Stack,
    Typography,
} from '@mui/material';
import ImagePreview from '../ImagePreview/ImagePreview';

const ImageDropzone: React.FC<{
    maxImageWeight: number,
    innerLabel?: string|undefined,
    initialImage?: string|undefined,
    disabled?: boolean|undefined,
    error?: boolean|undefined,
    sx?: SxProps<Theme>|undefined,
    previewSx?: SxProps<Theme>|undefined,
    maxPreviewSize?: string|number|undefined,
    keepFileOnUnmount?: boolean|undefined,
    onDrop?: ((imageFile: File, imageBlobUrl: string) => void)|undefined,
    onPreviewClose?: (() => void)|undefined,
}> = props => {
    // Use of hooks
    const [image, setImage] = React.useState<any>(null);
    const [alert, setAlert] = React.useState<string|null>(null);

    // Callback used once file is dropped
    const onDrop = React.useCallback((acceptedImage: Array<File>) => {
        if (!props.disabled) {
            // Retrieve the image dropped
            const droppedImage = acceptedImage[0];

            // If image is too heavy
            if (droppedImage.size > props.maxImageWeight * 1000) {
                // Rounded dropped image size
                const roundedSize = Math.floor(droppedImage.size / 1000);
                // Set an alert to open Dialog
                setAlert(`L'image dépossée (${roundedSize}Ko) dépasse la taille maximum autorisée de ${props.maxImageWeight}Ko.`);
            }

            // If file match acceptedExtensions & is not too heavy
            if (undefined !== droppedImage && droppedImage.size < props.maxImageWeight * 1000) {
                // Generate preview object url
                const objectUrl = URL.createObjectURL(droppedImage);
                // Store image information plus its preview in our hook
                setImage({ ...droppedImage, preview: objectUrl });
                // Also call the given props callback if not undefined
                props.onDrop && props.onDrop(droppedImage, objectUrl);
            }
        }
    }, [props])

    // Use of react-dropzone hook
    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.jpeg,.jpg,.png', maxFiles: 1 });

    // useEffect to make sure to revoke the data uris to avoid memory leaks
    React.useEffect(() => () => {
        // Note that it's a double arrow useEffect so it occurs when component unmount
        null !== image && !props.keepFileOnUnmount && URL.revokeObjectURL(image.preview);
    }, [props.keepFileOnUnmount, image]);

    // useEffect when component is mounting
    React.useEffect(() => {
        // Load given initial image passed by prop
        'string' === typeof props.initialImage &&
            setImage({ preview: props.initialImage });
    }, [props.initialImage])

    // Callback triggered when closing image preview
    const handlePreviewClose = React.useCallback(() => {
        // Reset image hook value
        setImage(null);
        // Also call given callback
        props.onPreviewClose && props.onPreviewClose();
    }, [props])

    return (
        image && image.preview ? (
            <ImagePreview
                closable={!props.disabled}
                imageSrc={image.preview}
                onPreviewClose={handlePreviewClose}
                sx={props.previewSx}
                maxSize={props.maxPreviewSize}
            />
        ) : (
            <>
                <Dialog open={Boolean(alert)} onClose={() => setAlert(null)}>
                    <DialogTitle>
                        {'Votre image est trop volumineuse'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {alert}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAlert(null)}>{`OK`}</Button>
                    </DialogActions>
                </Dialog>
                <Stack
                    { ...getRootProps() }
                    justifyContent='center'
                    alignItems='center'
                    height={1}
                    padding={5}
                    border='2px dashed grey'
                    borderColor={props.error ? 'error.main' : 'grey'}
                    borderRadius={5}
                    sx={{
                        cursor: props.disabled ? 'normal' : 'pointer',
                        opacity: props.disabled ? 0.5 : 1,
                        ...props.sx,
                    }}
                >
                    <input { ...getInputProps() } disabled={props.disabled} />
                    <Stack alignItems='center' color={props.error ? 'error.main' : 'textSecondary'}>
                        <Typography align='center' gutterBottom>
                            {props.innerLabel ?? 'Glissez-déposez une image ici, ou cliquez pour sélectionner une image'}
                        </Typography>
                        <Typography variant='caption'>
                            {`Formats acceptés *.jpeg *.jpg ou *.png`}
                        </Typography>
                        <Typography variant='caption'>
                            {`La taille maximale de l'image est de ${props.maxImageWeight}Ko.`}
                        </Typography>
                    </Stack>
                </Stack>
            </>
        )
    );
};

export default ImageDropzone;
