import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * PaymentAPIs class.
 */
export default class PaymentAPIs extends AbstractAuthAPIs {
    /**
     * Get Wallet resource.
     */
    public static async getWallet(query: any) {
        return GenericAPIs.apiGetCall('wallets', this.getApiKey(), query);
    }

    /**
     * Patch Wallet resource with Payment.
     *
     * @param body
     */
    public static async patchWalletPayment(id: string|number, body: any) {
        return GenericAPIs.apiPatchCall(`wallets/${id}/payment`, null, body, this.getApiKey());
    }

    /**
     * Get Payment resource.
     */
    public static async getPayment(query: any) {
        return GenericAPIs.apiGetCall('payments', this.getApiKey(), query);
    }

    /**
     * Create Payment resource.
     */
    public static async postPayment(body: any) {
        return GenericAPIs.apiPostCall('payments', body, this.getApiKey());
    }

    /**
     * Update a Payment resource.
     *
     * @param id
     * @param body
     */
    public static async patchPayment(id: number, body: any) {
        return GenericAPIs.apiPatchCall(`payments/${id}`, null, body, this.getApiKey());
    }

    /**
     * Get next Invoice inc number for a Wallet
     */
    public static async getNextInvoiceIncNumber(id: number) {
        return GenericAPIs.apiGetCall(`payments/invoice_inc_number`, this.getApiKey(), { wallet: id });
    }
};
