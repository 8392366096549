import React from 'react';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import { Field } from 'react-final-form';
import Categories from './Categories';
import { PublishDataType } from './PublishData';
import SelectActivitySector from '../SelectEntities/SelectActivitySector';
import SelectProject from '../SelectEntities/SelectProject';
import SelectProjectPostType from '../SelectEntities/SelectProjectPostType';
import ISelectOption from '../../interfaces/ISelectOption';

export type CategoryScheduleType = {
    category: string,
    publishData: Array<PublishDataType>,
    keywordTitles: Array<{
        keyword: ISelectOption,
        title: string|null,
        imagesSearchTerm: string|null,
    }>,
};

// Default validation schema of the whole component
export const categoriesScheduleSchema = Yup.array().of(
    Yup.object().shape({
        publishData: Yup.array()
        .nullable()
        .min(1, 'Veuillez renseigner au moins une date de publication')
        .of(
            Yup.object().shape({
                postNumber: Yup.number().min(1, 'Au moins un article est requis').required("Veuillez renseigner un nombre d'articles à publier"),
                startAt: Yup.string().nullable()
                    .test(
                        'min',
                        "La date de publication doit être au minimum 48h après la date actuelle",
                        (date) => Boolean(date) && moment(date).isSameOrAfter(moment().add(2, 'days')),
                    )
                    .required('Veuillez renseigner une date de publication'),
                endAt: Yup.string().nullable()
                    .test(
                        'min',
                        "La date de fin de publication doit être au minimum après la date de publication",
                        function (value: string|null|undefined) {
                            // Valid if startAt is not defined
                            return !Boolean(this.parent.startAt) ||
                                // Valid if hasPeriodicity is not checked or if endAt is not defined
                                false === this.parent.hasPeriodicity || !value ||
                                // Valid if after startAt + 1 periodicityType
                                moment(value).isSameOrAfter(moment(this.parent.startAt).add(1, this.parent.periodicityType.value));
                        }
                    )
            }),
        ),
        keywordTitles: Yup.array()
        .nullable()
        .min(1, 'Veuillez renseigner au moins un mot-clé')
        .of(
            Yup.object().shape({
                keyword: Yup.mixed<ISelectOption>().nullable()
                    .test(
                        'empty',
                        'Veuillez renseigner un mot-clé',
                        function (option) {
                            return Boolean(option?.value)
                        }
                    ),
                title: Yup.string().nullable(),
                imagesSearchTerm: Yup.string().nullable(),
            }),
        ),
    }),
).nullable().min(1, 'Veuillez sélectionner au moins une catégorie');

const CategoriesSchedule: React.FC<{ name: string, categories: Array<string> }> = props => (
    <>
        <SelectProject name='project' />
        <Field<ISelectOption>
            name='project'
            subscription={{ value: true }}
            render={({ input: { value: projectOption }}) => (
                <SelectProjectPostType
                    name='postType'
                    projectId={projectOption ? Number(projectOption.value) : null}
                />
            )}
        />
        <SelectActivitySector name='activitySector' />
        <Field<ISelectOption>
            name='project'
            subscription={{ value: true }}
            render={({ input: { value: projectOption }}) => (
                <Categories
                    name={props.name}
                    categories={props.categories}
                    projectId={projectOption ? Number(projectOption.value) : null}
                />
            )}
        />
    </>
);

export default CategoriesSchedule;
