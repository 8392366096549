import React from 'react';
import { useAppSelector } from '../../store/hooks';
import AccountAPIs from '../../APIs/AccountAPIs';
import UserAPIs from '../../APIs/UserAPIs';
import Tools from '../../helpers/Tools';
import { Stack, Typography } from '@mui/material';
import Contract from '../../pdf/documents/Contract';
import PdfIFrame from '../PdfIFrame/PdfIFrame';
import Spinner from '../Spinner/Spinner';
import IOrder from '../../models/IOrder';
import IUser from '../../models/IUser';
import ICompany from '../../models/ICompany';

const PublicOrderContract: React.FC<{
    order: IOrder|null,
    commission: number,
    onContractGenerated: (pdf: Blob) => void,
}> = props => {
    // Use of hooks
    const [user, setUser] = React.useState<IUser|null>(null);
    const [company, setCompany] = React.useState<ICompany|null>(null);
    const criterias = React.useRef<Array<string>|null>(null);

    // Use of redux hooks
    const instanceUserId = useAppSelector(state => state.instance.user!.id);
    const instanceCompanyId = useAppSelector(state => state.instance.account!.company.id);

    // useEffect when the component is mounted
    React.useEffect(() => {
        // Get the user information from API
        null === user &&
            UserAPIs.getUser({ id: instanceUserId })
                .then(response =>
                    null !== response && response.data[0] &&
                        setUser(response.data[0])
                );
    }, [instanceUserId, user]);

    // useEffect when the component is mounted
    React.useEffect(() => {
        // Get the company information from API
        null === company &&
            AccountAPIs.getCompany({ id: instanceCompanyId })
                .then((data: Array<ICompany>) => null !== data && setCompany(data[0]));
    }, [instanceCompanyId, company]);

    // useEffect when the component is mounted
    React.useEffect(() => {
        // Define order criterias
        if (null === criterias.current && null !== props.order) {
            criterias.current = Tools.getOrderCriterias(props.order);
        }
    }, [props.order]);

    return (
        null !== user && null !== company && null !== criterias.current && null !== props.order ? (
            <>
                <PdfIFrame
                    document={<Contract order={props.order} user={user} company={company} criterias={criterias.current} commission={props.commission} />}
                    height={'300px'}
                    onBlobChange={props.onContractGenerated}
                />
                <Stack direction='row' paddingTop={4}>
                    <Typography variant='h6' fontWeight={700} width={1/3}>
                        {`Gain après rédaction :`}
                    </Typography>
                    <Stack width={2/3}>
                        <Typography variant='h5'>
                            {`${props.order.price} € HT - `}
                            <Typography variant='h5' color='secondary' component='span'>
                                {`${props.commission}%`}
                            </Typography>
                            {` = `}
                            <Typography variant='h5' color='success.main' component='span' fontWeight={700}>
                                {`${(Number(props.order.price) * (1 - (props.commission / 100))).toFixed(2)} € HT`}
                            </Typography>
                        </Typography>
                        <Typography variant='caption' color='secondary'>
                            {`Les ${props.commission}% correspondent à la commission prise par ${process.env.REACT_APP_NAME}`}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack direction='row' paddingTop={4}>
                    <Typography variant='h6' fontWeight={700} width={1/3} lineHeight={1.1}>
                        {`Rappel des critères à respecter :`}
                    </Typography>
                    <Stack width={2/3}>
                        {
                            criterias.current.map((criteria, index) => (
                                <Typography key={index}>
                                    {criteria}
                                </Typography>
                            ))
                        }
                    </Stack>
                </Stack>
                <Typography paddingTop={6}>
                    {`En cliquant sur le bouton 'Confirmer' ci-dessous, vous acceptez les conditions de ce contrat.`}
                </Typography>
            </>
        ) : (<Spinner />)
    );
};

export default PublicOrderContract;
