import { Theme } from '@mui/material';
import { keyframes } from '@mui/material/styles';
import ICssStyles from '../../interfaces/ICssStyles';

const pulse = keyframes({
    '0%': {
        opacity: 0.5,
    },
    '100%': {
        opacity: 1,
    },
});

const makeCssStyles = (theme: Theme): ICssStyles => ({
    headbandContainer: {
        position: 'absolute',
        top: 0,
        right: '2%',
        flexDirection: 'row',
        alignItems: 'center',
        paddingX: 2,
        paddingY: 0.5,
        color: 'white',
        backgroundColor: 'primary.main',
        zIndex: theme.zIndex.drawer,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    walletStructure: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        cursor: 'pointer',
    },
    blinkingIcon: {
        animation: `${pulse} 1s ease-out infinite alternate`,
        color: 'warning.main',
    },
});

export default makeCssStyles;
