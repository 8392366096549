import React from 'react';
import { useAppSelector } from '../../store/hooks';
import PostAPIs from '../../APIs/PostAPIs';
import Tools from '../../helpers/Tools';
import { Button, Divider, Stack, Typography } from '@mui/material';
import {
    Info as InfoIcon,
    Key as KeyIcon,
    Numbers as NumbersIcon,
    Percent as PercentIcon,
    TextSnippet as TextSnippetIcon,
    Title as TitleIcon,
} from '@mui/icons-material';
import GenericModal from './GenericModal';
import Spinner from '../Spinner/Spinner';
import PurifiedHtml from '../PurifiedHtml/PurifiedHtml';
import IRejectedPost from '../../models/IRejectedPost';

const ModalViewRejectedPost: React.FC<{
    title: string,
    name?: string|undefined,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'viewRejectedPostModal');
    const [rejectedPost, setRejectedPost] = React.useState<IRejectedPost|null>(null);

    // Use of redux
    const rejectedPostId: number|null = useAppSelector(state => state.activeModal.params);
    const isOpened: boolean = useAppSelector(state => state.activeModal.name === modalName.current);

    // useEffect on modal open
    React.useEffect(() => {
        // On modal open fetch requested draft post
        true === isOpened && null !== rejectedPostId &&
            // Call API to retrieve draft post
            PostAPIs.getRejectedPost({ id: rejectedPostId, detailed: true })
                // On successful API call
                .then(response => true === isOpened && undefined !== response?.data && 1 === response.count &&
                    // Update draft post state
                    setRejectedPost(response.data[0])
                );
    }, [isOpened, rejectedPostId]);

    return (
        <GenericModal
            name={modalName.current}
            title={props.title}
            width={2/3}
            height={4/5}
        >
            {
                null !== rejectedPost ? (
                    <Stack rowGap={3}>
                        <Stack rowGap={1}>
                            <Typography variant='h5' display='flex' alignItems='center' gap={1}>
                                <KeyIcon />
                                {rejectedPost.keyword}
                            </Typography>
                            <Typography display='flex' alignItems='center'gap={1}>
                                <PercentIcon />
                                {`Optimisation : ${rejectedPost.optimization}%`}
                            </Typography>
                            <Typography display='flex' alignItems='center'gap={1}>
                                <NumbersIcon />
                                {`Nombre de mots : ${rejectedPost.nbrWords}`}
                            </Typography>
                            {
                                rejectedPost.title && (
                                    <Typography display='flex' alignItems='center'gap={1}>
                                        <TitleIcon />
                                        {rejectedPost.title}
                                    </Typography>
                                )
                            }
                            {
                                rejectedPost.metaDesc && (
                                    <Typography display='flex' alignItems='center'gap={1}>
                                        <TextSnippetIcon />
                                        {rejectedPost.metaDesc}
                                    </Typography>
                                )
                            }
                            {
                                rejectedPost.refusalReasons && 0 < rejectedPost.refusalReasons.length && (
                                    <>
                                        <Typography display='flex' alignItems='center'gap={1}>
                                            <InfoIcon />
                                            {`Raisons de l'échec :`}
                                        </Typography>
                                        <Stack paddingLeft={5}>
                                            {
                                                rejectedPost.refusalReasons.map((reason, index) => (
                                                    <Typography key={index}>
                                                        {`- ${reason}`}
                                                    </Typography>
                                                ))
                                            }
                                        </Stack>
                                    </>
                                )
                            }
                        </Stack>
                        <Divider />
                        <Stack direction='row' gap={2}>
                            <Button
                                variant='outlined'
                                sx={{ alignSelf: 'start' }}
                                onClick={() => navigator.clipboard.writeText(rejectedPost.html)}
                            >
                                {`Copier le contenu HTML`}
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{ alignSelf: 'start' }}
                                onClick={() => navigator.clipboard.writeText(Tools.stripTags(rejectedPost.html))}
                            >
                                {`Copier le texte brut`}
                            </Button>
                        </Stack>
                        <PurifiedHtml html={rejectedPost.html} />
                    </Stack>
                ) : (
                    <Spinner />
                )
            }
        </GenericModal>
    );
};

export default ModalViewRejectedPost;
