import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgManageUsers: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <rect x='296.57' y='182.47' fill='#F8B64C' width='98.13' height='80' />
            <rect x='274.17' y='257.13' fill='#54C0EB' width='144' height='66.13' />
            <g>
                <rect x='329.74' y='256.706' transform='matrix(0.7071 0.7071 -0.7071 0.7071 293.5634 -164.3802)' fill='#FFFFFF' width='30.933' height='30.933' />
                <polygon fill='#FFFFFF' points='361.632,323.265 329.632,323.265 334.966,276.331 356.299,276.331' />
            </g>
            <g>
                <path fill='#40596B' d='M510.966,278.465v44.8H385.099l5.333-66.133l4.267-45.867l0,0l96,37.333C502.432,253.931,510.966,265.665,510.966,278.465z'/>
                <path fill='#40596B' d='M306.166,323.265H180.299v-44.8c0-12.8,7.467-24.533,20.267-29.867l96-37.333l0,0l4.267,45.867L306.166,323.265z'/>
            </g>
            <g>
                <path fill='#334A5E' d='M417.099,281.665c-1.067,0-1.067,1.067,0,2.133l0,0l33.067,39.467h-66.133l5.333-66.133l4.267-45.867l48,44.8c1.067,0,0,1.067,0,2.133L417.099,281.665z'/>
                <path fill='#334A5E' d='M274.166,281.665c1.067,0,1.067,1.067,0,2.133l0,0l-33.067,39.467h66.133l-5.333-66.133l-4.267-45.867l-48,44.8c-1.067,0,0,1.067,0,2.133L274.166,281.665z'/>
            </g>
            <polygon opacity='0.1' fill='#40596B' enableBackground='new' points='394.699,182.465 394.699,211.265 359.499,238.998 318.966,209.131 341.366,182.465 '/>
            <g>
                <ellipse transform='matrix(-0.9962 0.0872 -0.0872 -0.9962 566.3727 216.4422)' fill='#F8B64C' cx='278.459' cy='120.591' rx='14.933' ry='22.4' />
                <ellipse transform='matrix(-0.9962 -0.0872 0.0872 -0.9962 813.5463 276.7471)' fill='#F8B64C' cx='412.818' cy='120.605' rx='14.933' ry='22.4' />
            </g>
            <path fill='#FFD15C' d='M417.099,86.465v23.467c0,5.333,0,9.6-1.067,14.933c-1.067,8.533-3.2,16-5.333,23.467c-5.333,18.133-16,36.267-28.8,50.133l-6.4,7.467c-6.4,6.4-14.933,10.667-24.533,10.667h-10.667c-9.6,0-18.133-4.267-24.533-10.667l-6.4-7.467c-9.6-10.667-17.067-22.4-22.4-34.133c-5.333-12.8-9.6-25.6-11.733-39.467c-1.067-5.333-1.067-9.6-1.067-14.933c0-1.067,0-1.067,0-1.067V87.531c0-6.4,1.067-13.867,3.2-19.2c8.533-29.867,36.267-51.2,68.267-51.2c33.067,0,59.733,22.4,68.267,51.2C416.032,73.665,417.099,80.065,417.099,86.465z'/>
            <g>
                <polygon fill='#84DBFF' points='345.632,250.731 394.699,211.265 387.232,292.331' />
                <polygon fill='#84DBFF' points='345.632,250.731 296.566,211.265 304.032,292.331' />
            </g>
            <path fill='#2A425A' d='M408.566,123.798l6.4-2.133l4.267-10.667c4.267-12.8,7.467-27.733,7.467-41.6c0-39.467-20.267-35.2-20.267-35.2C391.499,0.065,344.566,0.065,344.566,0.065c-69.333-2.133-78.933,49.067-78.933,76.8c0,8.533,2.133,17.067,4.267,25.6s6.4,18.133,6.4,18.133h4.267c0,0-8.533-51.2,9.6-61.867c0,0,19.2,29.867,80,23.467c0,0-23.467,0-27.733-8.533c0,0,39.467,14.933,59.733-16C401.099,58.731,412.832,91.798,408.566,123.798z'/>
            <rect x='117.37' y='371.26' fill='#F8B64C' width='98.13' height='80' />
            <rect x='94.97' y='445.93' fill='#54C0EB' width='144' height='66.13' />
            <g>
                <rect x='150.578' y='445.625' transform='matrix(0.7071 0.7071 -0.7071 0.7071 374.6739 17.6391)' fill='#FFFFFF' width='30.933' height='30.933' />
                <polygon fill='#FFFFFF' points='182.432,512.065 150.432,512.065 155.766,465.131 177.099,465.131' />
            </g>
            <g>
                <path fill='#40596B' d='M331.766,467.265v44.8H205.899l5.333-66.133l4.267-45.867l0,0l96,37.333C323.232,442.731,331.766,454.465,331.766,467.265z'/>
                <path fill='#40596B' d='M126.966,512.065H1.099v-44.8c0-12.8,7.467-24.533,20.267-29.867l96-37.333l0,0l4.267,45.867L126.966,512.065z'/>
            </g>
            <g>
                <path fill='#334A5E' d='M237.899,470.465c-1.067,0-1.067,1.067,0,2.133l0,0l33.067,39.467h-66.133l5.333-66.133l4.267-45.867l48,44.8c1.067,0,0,1.067,0,2.133L237.899,470.465z'/>
                <path fill='#334A5E' d='M94.966,470.465c1.067,0,1.067,1.067,0,2.133l0,0l-34.133,39.467h66.133l-5.333-66.133l-4.267-45.867l-48,44.8c-1.067,0,0,1.067,0,2.133L94.966,470.465z'/>
            </g>
            <polygon opacity='0.1' fill='#40596B' enableBackground='new' points='215.499,371.265 215.499,400.065 180.299,427.798 139.766,397.931 161.099,371.265 '/>
            <g>
                <ellipse transform='matrix(-0.9962 0.0872 -0.0872 -0.9962 225.1159 608.9023)' fill='#F8B64C' cx='99.259' cy='309.368' rx='14.933' ry='22.4' />
                <ellipse transform='matrix(-0.9962 -0.0872 0.0872 -0.9962 439.3561 638.0497)' fill='#F8B64C' cx='233.614' cy='309.429' rx='14.933' ry='22.4' />
            </g>
            <path fill='#FFD15C' d='M237.899,275.265v23.467c0,5.333,0,9.6-1.067,14.933c-1.067,8.533-3.2,16-5.333,23.467c-5.333,18.133-16,36.267-28.8,50.133l-6.4,7.467c-6.4,6.4-14.933,10.667-24.533,10.667h-10.667c-9.6,0-18.133-4.267-24.533-10.667l-6.4-7.467c-9.6-10.667-17.067-22.4-22.4-34.133c-6.4-11.733-9.6-25.6-11.733-39.467c-1.067-5.333-1.067-9.6-1.067-14.933c0-1.067,0-1.067,0-1.067v-21.333c0-6.4,1.067-13.867,3.2-19.2c8.533-29.867,36.267-51.2,68.267-51.2 c33.067,0,59.733,22.4,68.267,51.2C236.832,262.465,237.899,268.865,237.899,275.265z'/>
            <g>
                <polygon fill='#84DBFF' points='166.432,439.531 215.499,400.065 208.032,481.131' />
                <polygon fill='#84DBFF' points='166.432,439.531 117.366,400.065 124.832,481.131' />
            </g>
            <path fill='#2A425A' d='M229.366,312.598l6.4-2.133l4.267-10.667c4.267-13.867,7.467-27.733,7.467-41.6c0-39.467-20.267-35.2-20.267-35.2C212.3,188.865,164.3,188.865,164.3,188.865c-69.333-1.067-78.933,49.067-78.933,77.867c0,8.533,2.133,17.067,4.267,25.6s6.4,18.133,6.4,18.133h4.267c0,0-8.533-51.2,9.6-61.867c0,0,19.2,29.867,80,23.467c0,0-23.467,0-27.733-8.533c0,0,39.467,14.933,59.733-16C221.899,247.531,233.632,280.598,229.366,312.598z'/>
        </SvgIcon>
    );
};

export default SvgManageUsers;
