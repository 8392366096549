import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconType } from 'react-icons';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
    SxProps,
    Theme,
    SvgIconTypeMap,
    Paper,
    Stack,
    Box,
    Typography,
} from '@mui/material';
import IInnerRefProp from '../../interfaces/IInnerRefProps';
import cssStyles from './NamespacePanelStyles';

const NamespacePanel: React.FC<{
    header: string,
    to: string,
    notAllowed?: boolean|undefined,
    svg?: IconType|OverridableComponent<SvgIconTypeMap<{},'svg'>>|JSX.Element|undefined,
    state?: any,
    text?: string|undefined,
}&IInnerRefProp> = props => {
    // Use of react-router-dom hooks
    const navigate = useNavigate();

    return (
        <Paper
            ref={props.innerRef}
            component={Stack}
            direction='row'
            justifyContent='center'
            alignItems='center'
            variant='outlined'
            gap={2}
            sx={{ ...cssStyles.paper, ...(props.notAllowed && cssStyles.paperNotAllowed) } as SxProps<Theme>}
            onClick={() => !props.notAllowed && navigate(props.to, { state: props.state })}
        >
            <Stack width={104} height={104} alignItems='center' justifyContent='center'>
                {props.svg ?? (<p>SVG</p>)}
            </Stack>
            <Box width={7 / 12}>
                <Typography component='h2' variant='body1' color='primary' fontWeight={700} gutterBottom>
                    {props.header}
                </Typography>
                {
                    props.text && (
                        <Typography variant='body2'>
                            {props.text}
                        </Typography>
                    )
                }
            </Box>
        </Paper>
    );
};

export default NamespacePanel;
