import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgRefillTokens: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <g>
                <path fill='#FF7058' d='M463.783,410.667c0,115.2-92.8,100.267-208,100.267s-208,14.933-208-100.267s92.8-294.4,208-294.4S463.783,296.533,463.783,410.667z'/>
                <path fill='#FF7058' d='M357.117,17.067c-23.467,6.4-42.667,17.067-59.733,29.867C289.917,29.867,277.117,13.867,260.05,0c-17.067,13.867-29.867,29.867-38.4,46.933c-16-12.8-36.267-23.467-59.733-29.867c-32,68.267,11.733,144,97.067,169.6l0,0l0,0l0,0l0,0l0,0l0,0C345.383,161.067,389.117,85.333,357.117,17.067z'/>
            </g>
            <path fill='#40596B' d='M350.717,147.2h-182.4c-7.467,0-12.8-5.333-12.8-12.8l0,0c0-7.467,5.333-12.8,12.8-12.8h182.4c7.467,0,12.8,5.333,12.8,12.8l0,0C363.517,140.8,358.184,147.2,350.717,147.2z'/>
            <path fill='#334A5E' d='M367.783,172.8H151.25c-7.467,0-12.8-5.333-12.8-12.8l0,0c0-7.467,5.333-12.8,12.8-12.8h217.6c7.467,0,12.8,5.333,12.8,12.8l0,0C381.65,166.4,375.25,172.8,367.783,172.8z'/>
            <circle fill='#FFD15C' cx='255.78' cy='340.27' r='102.4' />
            <circle fill='#F8B64C' cx='255.78' cy='340.27' r='83.2' />
            <path fill='#FFD15C' d='M250.45,376.533v-32c-9.6-3.2-17.067-7.467-22.4-12.8c-4.267-5.333-7.467-12.8-7.467-21.333s2.133-14.933,7.467-21.333c5.333-6.4,12.8-8.533,21.333-9.6V272h10.667v7.467c8.533,1.067,14.933,4.267,19.2,8.533c5.333,4.267,7.467,10.667,9.6,18.133l-19.2,2.133c-1.067-6.4-4.267-9.6-9.6-11.733V326.4c11.733,3.2,20.267,7.467,24.533,12.8c4.267,5.333,6.4,11.733,6.4,20.267c0,9.6-3.2,17.067-8.533,23.467c-5.333,6.4-13.867,10.667-23.467,11.733v13.867h-10.667v-13.867c-8.533-1.067-16-4.267-21.333-9.6s-8.533-12.8-10.667-23.467l20.267-2.133c1.067,4.267,2.133,7.467,4.267,10.667C245.117,373.333,247.25,375.467,250.45,376.533z M250.45,295.467c-3.2,1.067-5.333,3.2-7.467,5.333c-2.133,2.133-3.2,5.333-3.2,8.533s1.067,5.333,2.133,7.467c1.067,2.133,4.267,4.267,7.467,5.333v-26.667L250.45,295.467L250.45,295.467z M261.117,377.6c4.267-1.067,7.467-2.133,9.6-5.333s3.2-6.4,3.2-9.6s-1.067-6.4-3.2-8.533c-2.133-2.133-5.333-4.267-9.6-5.333V377.6z'/>
        </SvgIcon>
    );
};

export default SvgRefillTokens;
