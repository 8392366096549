import React from 'react';
import moment from 'moment-timezone';
import { Typography, Stack, Avatar } from '@mui/material';
import IUser from '../../models/IUser';
import cssStyles from './OrderSummaryStyles';

const SummaryEditor: React.FC<{ editor: IUser|null|undefined, deadline?: string|undefined }> = props => {
    return (
        props.editor ? (
            <>
                <Typography gutterBottom>
                    {`Rédaction confiée à :`}
                </Typography>
                <Stack direction='row' alignItems='center' paddingBottom={2}>
                    <Avatar
                        src={props.editor.avatar ?? undefined}
                        sx={cssStyles.summaryEditorAvatar}
                        children={`${props.editor.name[0]}${props.editor.lastname[0]}`}
                    />
                    <Typography color='primary'>
                        {`${props.editor.name} ${props.editor.lastname}`}
                    </Typography>
                </Stack>
                {
                    props.deadline && (
                        <Typography gutterBottom>
                            {`Deadline pour la rédaction de l'article: `}
                            <Typography component='span' color='primary'>
                                {moment(props.deadline).format('DD/MM/Y à HH:mm')}
                            </Typography>
                        </Typography>
                    )
                }
            </>
        ) : (
            <Typography>
                {`Rédacteur : `}
                <Typography component='span' color='primary'>
                    {`Non renseigné`}
                </Typography>
            </Typography>
        )
    );
};

export default SummaryEditor;
