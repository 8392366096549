import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    formStructure: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'background.default',
        margin: 'auto',
        zIndex: 2,
        width: {
            md: 720,
            xs: 1,
        },
    },
    videoFilter: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: 1,
        width: 1,
        zIndex: 1,
        opacity: 0.3,
        backgroundColor: 'royalblue',
        display: {
            md: 'block',
            xs: 'none',
        },
    },
    backgroundVideo: {
        position: 'fixed',
        top: 0,
        left: 0,
        display: {
            md: 'block',
            xs: 'none',
        },
        '@media(min-aspect-ratio: 16/9)': {
            width: 1,
            height: 'auto',
        },
        '@media(max-aspect-ratio: 16/9)': {
            width: 'auto',
            height: 1,
        },
    },
};

export default cssStyles;
