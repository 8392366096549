import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Stack,
    Tooltip,
    IconButton,
    Typography,
    Divider,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import Article from './Article';
import Copyright from '../Copyright/Copyright';
import SubArticle from './SubArticle';
import SimpleText from './SimpleText';
import RouterLink from '../RouterLink/RouterLink';

const Policy: React.FC = () => {
    // Use react-router-dom hooks
    const navigate = useNavigate();

    return (
        <Container component='main' maxWidth='md' sx={{ padding: 5 }}>
            <Stack alignItems='flex-start' spacing={2} paddingBottom={1}>
                {
                    // Can't goBack if there is no previous location
                    <Tooltip title='Précédent'>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize='large' color='secondary' />
                        </IconButton>
                    </Tooltip>
                }
                <Typography component='h1' variant='h3' color='primary' fontWeight={700}>
                    {`Conditions générales de ventes & d'utilisation`}
                </Typography>
            </Stack>
            <Divider variant='middle' sx={{ marginY: 4 }} />
            <SimpleText>
                {`Il s'agit d'un document important que vous devez examiner soigneusement avant de choisir d'utiliser ou non le site de `}
                <RouterLink
                    to={`https://www.${process.env.REACT_APP_NAME}.fr`}
                    anchor={`${process.env.REACT_APP_NAME}.fr`}
                    target='_blank'
                />
                {`.`}
            </SimpleText>
            <SimpleText>
                {`La section suivante décrit les conditions dans lesquelles ${process.env.REACT_APP_NAME} vous offres accès à ses services.`}
            </SimpleText>
            <Article
                title={`Acceptation des Conditions Générales d'Utilisation`}
                content={
                    <>
                        <SimpleText>
                            {`Ces Conditions Générales d’Utilisation sont un contrat entre vous et READY-UP si vous êtes un utilisateur de `}
                            <RouterLink
                                to={`https://www.${process.env.REACT_APP_NAME}.fr`}
                                anchor={`${process.env.REACT_APP_NAME}.fr`}
                                target='_blank'
                            />
                            {`. L’accès au site vous est offert sous réserve de votre acceptation des Conditions Générales d’Utilisation. Vous voudrez bien examiner les modalités et conditions énoncées ci-dessous avant d'utiliser le Site. Dès ce moment, votre utilisation du site vaut acceptation de ces conditions générales d'utilisation. Si vous n'acceptez pas une de ces conditions générales, vous devez immédiatement cesser d'utiliser le Site. En outre vous voudrez bien noter que nous pouvons fermer, suspendre ou limiter l'accès à votre compte ${process.env.REACT_APP_NAME}.fr (« Compte ») ou les services que nous fournissons sur le site (« Services »), et/ou limiter l'accès à vos fonds si vous réalisez des activités qui sont en violation de ces Conditions Générales d’Utilisation et de ses politiques connexes intégrées par renvoi.`}
                        </SimpleText>
                    </>
                }
            />
            <Article
                title={`Modification des Conditions Générales d'Utilisation`}
                content={`READY-UP peut amender ou modifier les présentes Conditions Générales d’Utilisation, en tout ou en partie de temps à autre, sans préavis (sauf si cette notification préalable est requise par la loi), en affichant un Contrat d'utilisation modifié sur le Site ou toute information liée. Une fois cette version mise à jour, les nouvelles Conditions Générales d’Utilisation seront en vigueur au moment de leur publication.`}
            />
            <Article
                title={`${process.env.REACT_APP_NAME}.fr`}
                content={`Les termes « ${process.env.REACT_APP_NAME}.fr », « Le Site » ou « Nous » font référence à la société READY-UP.`}
            />
            <Article
                title={`1. Admissiblité`}
                titleSx={{ textTransform: 'uppercase' }}
                content={`${process.env.REACT_APP_NAME}.fr offre ses services à ses utilisateurs. Nous aurons une discrétion absolue quant à savoir si nous acceptons ou non un candidat pour la participation aux services. Le site est disponible uniquement aux personnes physiques ou morales qui peuvent former des contrats juridiquement contraignants en vertu du droit applicable. Sans limiter ce qui précède, ce site n'est pas disponible pour les personnes de moins de 18 ans. Si les utilisateurs ne sont pas admissibles, ils ne peuvent pas utiliser les services de ${process.env.REACT_APP_NAME}.fr y compris, mais sans s'y limiter, ceux qui exigent la capacité de l'utilisateur pour former des contrats juridiquement contraignants. Chaque utilisateur est responsable de ce qui se passe sur son compte et doit signaler toute utilisation non autorisée.`}
            />
            <Article
                title={`2. Utilisateurs`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SimpleText>
                            {`Les termes «Vous», « Votre », « Utilisateur » ou « Utilisateurs » se réfèrent à toute personne accédant à nos services ou au site pour une raison quelconque.`}
                        </SimpleText>
                        <SimpleText>
                            {`${process.env.REACT_APP_NAME}.fr est une plateforme qui permet aux utilisateurs qui souhaitent fournir des services professionnels (les « Rédacteurs ») et aux utilisateurs qui cherchent de tels services professionnels (les « Donneurs d’ordres ») de satisfaire leurs besoins. Le site permet aux utilisateurs d'identifier l'autre et de travailler ensemble en ligne.`}
                        </SimpleText>
                        <SimpleText>
                            {`${process.env.REACT_APP_NAME}.fr permet également aux Entreprises de faire travailler leurs propres collaborateurs sur un logiciel en ligne.`}
                        </SimpleText>
                    </>
               }
            />
            <Article
                title={`3. Relation entre vous et ${process.env.REACT_APP_NAME}.fr`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`3.1 Résiliation`}
                            content={`${process.env.REACT_APP_NAME}.fr se réserve le droit de mettre fin à la participation de tout utilisateur sans raison, y compris tout Utilisateur que ${process.env.REACT_APP_NAME}.fr juge être en violation des Conditions Générales d’Utilisation. Sans limiter ce qui précède, ${process.env.REACT_APP_NAME}.fr peut mettre fin à la participation d'un utilisateur s'il pense qu'il crée des problèmes, responsabilités juridiques (réelle ou potentielle), porte atteinte aux droits de propriété intellectuelle de quelqu'un d'autre, s'engage dans des activités frauduleuses, immorales ou illégales, ou pour d'autres raisons similaires.`}
                        />
                        <SubArticle
                            title={`3.2 Relations entre utilisateurs`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Les parties lors d’une mission sont le Rédacteur, le Donneur d’Ordres et ${process.env.REACT_APP_NAME}.fr. Le rôle de ${process.env.REACT_APP_NAME}.fr se limite à :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(a) la mise à disposition de la salle virtuelle pour permettre la sélection des Rédacteurs et des missions,`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(b) la mise à disposition d’une plateforme de rédaction afin de permettre aux utilisateurs d’assurer et de contrôler les missions,`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(c) assurer le paiement du travail du Rédacteur effectué conformément au cahier des charges en lui achetant ses prestations et en les revendant aux Donneurs d’Ordres,`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(d) et la mise à disposition d'un processus de règlement des conflits lorsqu’il y a un désaccord sur l'exécution satisfaisante d'une mission.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Bien que ${process.env.REACT_APP_NAME}.fr fournit un environnement virtuel pour la définition des missions et l'évaluation de l'aptitude du Rédacteur, chaque Rédacteur et chaque Donneur d’Ordres reconnaît et accepte, en dépit de toute recommandation formulée par ${process.env.REACT_APP_NAME}.fr, qu’il est responsable d'identifier et ensuite de prendre sa propre décision sur la pertinence ou non (le cas échéant) d'un Rédacteur pour effectuer ou réaliser une mission ou d'un Donneur d’Ordres à fournir des missions réalisables. ${process.env.REACT_APP_NAME}.fr n'a pas à enquêter sur la pertinence des candidatures des Rédacteurs et le Donneur d’Ordres reconnaît qu'il est de sa seule responsabilité d'évaluer la capacité d'un Rédacteur à compléter avec succès une mission.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Une fois l'attribution par le Donneur d’Ordres et l'acceptation par le Rédacteur d'une mission sur le site, le Rédacteur s'engage à effectuer cette mission par le biais de la plateforme de téléprospection de ${process.env.REACT_APP_NAME}.fr en conformité avec les conventions suivantes :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(1) les Conditions Générales d’Utilisation, et ,`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(2) le Contrat de Prestation de Service incluant les termes de la mission attribuée et acceptée sur le site, dans la mesure où ils sont compatibles avec les Conditions Générales d’Utilisation.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Vous acceptez de ne pas entrer dans les dispositions contractuelles en conflit avec les Conditions Générales d’Utilisation. Toute disposition d'une mission qui entre en conflit avec les Conditions Générales d’Utilisation est nulle.`}
                                    </SimpleText>
                                </>
                           }
                        />
                        <SubArticle
                            title={`3.3 Responsabilités de l'utilisateur`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Lorsque vous entrez dans une transaction, vous créez un contrat juridiquement contraignant avec ${process.env.REACT_APP_NAME}.fr, sauf si l'opération est interdite par la loi ou par les présentes Conditions Générales d’Utilisation.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Vous êtes responsable et vous devez vous assurer que vous respectez vos obligations envers les autres utilisateurs. Si vous ne le faites pas, vous en serez tenu pour seul responsable. Vous devez vous assurer que vous êtes au courant de la législation nationale, des lois internationales, des lois, ordonnances et règlements pertinents à votre situation en tant que Donneur d’Ordres ou Rédacteur, ou dans toute autre utilisation que vous faites du site.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Si un autre utilisateur ne respecte pas les obligations envers vous, vous êtes seul responsable de l'application des droits que vous pourriez avoir. ${process.env.REACT_APP_NAME}.fr n’a aucune responsabilité ni compétence pour faire respecter les droits de l'utilisateur.`}
                                    </SimpleText>
                                </>
                           }
                        />
                        <SubArticle
                            title={`3.4 Relation entre utilisateurs`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Chaque utilisateur reconnaît et accepte que les relations entre les utilisateurs et ${process.env.REACT_APP_NAME}.fr sont purement commerciales. Rien dans les présentes Conditions Générales d’Utilisation crée un partenariat, une coentreprise, agence ou de la relation employeur/employé entre :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(1) les utilisateurs, ou`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(2) entre ${process.env.REACT_APP_NAME}.fr et tout utilisateur.`}
                                    </SimpleText>
                                </>
                           }
                        />
                        <SubArticle
                            title={`3.5 Services offerts par ${process.env.REACT_APP_NAME}.fr`}
                            content={
                                <>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr propose un large éventail d'outils, de contenus, de services, d’avantages et autres ressources sur son site pour permettre aux utilisateurs de gérer efficacement et de manière rentable leur entreprise. Vous n'avez pas le droit de copier, vendre ou autrement exploiter à des fins commerciales les services ou le contenu (en tout ou en partie), l'accès à ce service ou à l'utilisation de ce service.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr propose :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Une marketplace permettant la mise en relation et de collaboration entre des Rédacteurs indépendants (Rédacteurs) et des entreprises (Donneurs d’Ordres).`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Un logiciel en mode SaaS (en ligne) dédié à la téléprospection et à la relation clients.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Des outils d’élaboration dynamique de fichiers de prospection (aspirateur d’annuaires, module de piges immobilières, autos…).`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Des formations en téléprospection.`}
                                    </SimpleText>
                                </>
                           }
                        />
                        <SubArticle
                            title={`3.6 Conditions d'utilisation`}
                            content={
                                <>
                                    <SimpleText>
                                        {`L'Utilisateur comprend et accepte les conditions suivantes :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Le site ${process.env.REACT_APP_NAME}.fr est un site web dynamique sensible au facteur temps. En tant que tel, les informations sur le site seront changées fréquemment. Il est possible que certaines informations soient considérées comme offensantes, préjudiciables ou inexactes, et dans certains cas, peuvent être mal étiquetées ou faussement étiquetées accidentellement par ${process.env.REACT_APP_NAME}.fr ou accidentellement ou volontairement par un tiers.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr fournit un accès sans surveillance systématique au contenu des tiers. ${process.env.REACT_APP_NAME}.fr agit uniquement comme un lieu et/ou un portail, et n'a aucune responsabilité sur le contenu des tiers sur son site, que ce soit découlant des lois du droit d'auteur ou autre propriété intellectuelle, diffamation, vie privée, obscénité ou toute autre discipline juridique. Ce site peut contenir des liens vers des sites Web de tiers. ${process.env.REACT_APP_NAME}.fr ne contrôle pas systématiquement les sites web dont les liens se trouvent sur le site. ${process.env.REACT_APP_NAME}.fr, par conséquent, ne peut pas approuver le contenu, les produits, les services, les pratiques, les politiques ou les performances des sites Web auxquels renvoient ces liens.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr n'a pas les ressources nécessaires pour mener le contrôle, la recherche, vérifier, valider, ou approuver le contenu qui est reçu ou consulté sur son site web. ${process.env.REACT_APP_NAME}.fr s'attend à ce que l'utilisateur entreprenne des activités et pratiques nécessaires et fasse preuve de prudence et de bon sens lors de l'utilisation du site ${process.env.REACT_APP_NAME}.fr pour son compte.`}
                                    </SimpleText>
                                </>
                           }
                        />
                        <SubArticle
                            title={`3.7 Promotion`}
                            content={`Vous reconnaissez et acceptez que ${process.env.REACT_APP_NAME}.fr puisse afficher votre nom d'utilisateur, le logo et description publique de vos missions et votre profil si vous êtes Rédacteur dans le cadre du site ${process.env.REACT_APP_NAME}.fr et/ou d'autres documents de marketing ${process.env.REACT_APP_NAME}.fr.`}
                        />
                        <SubArticle
                            title={`3.8 Avis: consentement au contact`}
                            content={`Vous consentez à ${process.env.REACT_APP_NAME}.fr le droit de vous contacter par voie électronique ou téléphonique (voix, sms…) dans le cadre de la prestation de services décrite dans les présentes Conditions Générales d’Utilisation et/ou lors des campagnes de commercialisation de nouveaux produits ou services conformément au RGPD.`}
                        />
                    </>
               }
            />
            <Article
                title={`4. Frais`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`4.1 Abonnements`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Afin de permettre aux utilisateurs d’utiliser nos services, la possibilité leur est offerte de contracter divers abonnements. Le tarif des abonnements dépend de la formule retenue par l’utilisateur (Bronze, Argent, Or, Premium) ainsi que de la durée de son engagement (3 mois, 6 mois, 12 mois) et est indiqué sur le site internet. En tout état de cause, l’utilisateur ayant contracté un abonnement, s’engage à l’honorer jusqu’à son terme.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Conformément aux dispositions de l'article L. 121-20-2 1° du Code de la Consommation, l’utilisateur reconnaît et accepte expressément que son abonnement (avec ses avantages) commence immédiatement après son acceptation et par conséquence, qu’aucune demande de rétractation, d'annulation ou de remboursement ne sera recevable pour la période souscrite.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Il est précisé que lorsque l’utilisateur communique à ${process.env.REACT_APP_NAME}.fr ses coordonnées bancaires, celles-ci ne sont pas conservées par ${process.env.REACT_APP_NAME}.fr.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Afin d'optimiser la sécurité des transactions, ${process.env.REACT_APP_NAME}.fr a choisi le système de paiement de la société PayBox. Les garanties accordées par ${process.env.REACT_APP_NAME}.fr au titre de la sécurité des transactions sont identiques à celles obtenues respectivement par ${process.env.REACT_APP_NAME}.fr de l’éditeur du système de paiement précité.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Le prix de l’abonnement est payable mensuellement et le paiement s’effectuera, à terme à échoir (paiement par avance), lors de la contraction de l'abonnement puis à chaque mois, par prélèvement automatique, jusqu’à résiliation de l’abonnement, que la résiliation soit à l’initiative de l’utilisateur ou à celle de ${process.env.REACT_APP_NAME}.fr.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Le paiement de la mensualité sera effectué par prélèvement via carte bancaire. L’utilisateur, autorise les dits prélèvements conformément à l’ordre de paiement réalisé avec sa carte bancaire et s’engage irrévocablement à maintenir cette autorisation pendant toute la durée du présent contrat sauf à utiliser une nouvelle carte bancaire. Une fois la période d’engagement passée, ce contrat se poursuivra par tacite reconduction pour des périodes d’engagement équivalentes.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`L’utilisateur pourra demander l'arrêt de son abonnement à tout moment par un simple clic depuis son espace client, mais l’abonnement ne prendra fin qu'à l'issu de la période d'engagement (3 mois, 6 mois ou 12 mois) choisie par l’utilisateur. A défaut d'action de la part de l’utilisateur avant la date anniversaire du dit abonnement, ce dernier se renouvellera par tacite reconduction pour des périodes d’engagement équivalentes.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`A défaut de paiement exact à son échéance de l’une des mensualités, le contrat d’abonnement sera de plein droit résilié. A titre d’indemnité contractuelle de résiliation anticipée et pour compensation de préjudice en résultant, le solde des mensualités de la période contractuelle en cours deviendra immédiatement et de plein droit exigible. De plus, à défaut de régularisation dans les 90 jours, toutes les sommes qui auront été versées sur la plateforme par l’utilisateur demeureront acquises à ${process.env.REACT_APP_NAME}.fr d’une manière irrévocable et définitive ainsi que le reconnait l’utilisateur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`A défaut d’éléments contraires apportés par l’utilisateur, les registres informatisés, conservés dans les systèmes informatiques de ${process.env.REACT_APP_NAME}.fr et de ses partenaires dans des conditions raisonnables de sécurité, seront considérés comme les preuves des communications, des commandes, des validations et des paiements intervenus entre l’utilisateur et ${process.env.REACT_APP_NAME}.fr. Ces informations feront foi sauf à en apporter la preuve écrite et contraire par l’utilisateur. L’archivage de ces éléments est effectué sur un support fiable et durable de manière à correspondre à une copie fidèle et durable conformément à l’article 1348 du code civil.`}
                                    </SimpleText>
                                </>
                           }
                        />
                        <SubArticle
                            title={`4.2 Commissions marketplace`}
                            content={`En fonction de la formule choisie par l’utilisateur (Bronze, Argent, Or, Premium), ${process.env.REACT_APP_NAME}.fr peu prélever des commissions sur chaque transaction et peut facturer divers services. Tous les frais sont indiqués sur le site. Tous les frais seront évalués en Euros (€). Votre compte et toutes les transactions sont faites et affichées en Euros (€).`}
                        />
                    </>
               }
            />
            <Article
                title={`5. Les paiements des missions de la marketplace`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`5.1 Modalités de paiement`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Afin d’assurer au Rédacteur qu’il sera payé pour chaque mission correctement effectuée et de permettre au Donneur d’Ordres de ne payer que pour des prestations correctement réalisées, ${process.env.REACT_APP_NAME}.fr agit en tant qu’intermédiaire.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Pour démarrer une mission, le Donneur d’Ordres doit posséder et bloquer les crédits nécessaires à sa réalisation. Le cas échéant, il pourra les acheter en ligne sur le site ${process.env.REACT_APP_NAME}.fr. Un crédit a une valeur de 1.00€ HT.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Bronze, ${process.env.REACT_APP_NAME}.fr ajoutera une commission de 30.00% sur chaque crédit acheté.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Argent, ${process.env.REACT_APP_NAME}.fr ajoutera une commission de 15.00% sur chaque crédit acheté.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Or, ${process.env.REACT_APP_NAME}.fr ajoutera une commission de 5.00% sur chaque crédit acheté.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Premium, ${process.env.REACT_APP_NAME}.fr ajoutera une commission de 0.00% sur chaque crédit acheté.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Une fois la mission terminée et ses différentes étapes validées par le Donneur d’Ordres, ${process.env.REACT_APP_NAME}.fr paye le Rédacteur pour la prestation effectuée en créditant ses « Gains Disponibles » de la valeur correspondante.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Basique, une commission de 20.00% est prélevée sur chaque étape payée au Rédacteur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Bronze, une commission de 15.00% est prélevée sur chaque étape payée au Rédacteur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Argent, une commission de 10.00% est prélevée sur chaque étape payée au Rédacteur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Or, une commission de 5.00% est prélevée sur chaque étape payée au Rédacteur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Premium, une commission de 0.00% est prélevée sur chaque étape payée au Rédacteur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Les frais de communications téléphoniques sont :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Argent, pris en charge par ${process.env.REACT_APP_NAME}.fr à hauteur de 25€/mois puis à la charge du Rédacteur au-delà.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Or, pris en charge par ${process.env.REACT_APP_NAME}.fr à hauteur de 45€/mois puis à la charge du Rédacteur au-delà.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- Dans le cas d’une formule Premium, pris en charge par ${process.env.REACT_APP_NAME}.fr à hauteur de 60€/mois puis à la charge du Rédacteur au-delà.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Détail des tarifs de communication : `}
                                        <RouterLink
                                            to={`https://www.${process.env.REACT_APP_NAME}.fr/tarifs`}
                                            anchor={`${process.env.REACT_APP_NAME}.fr/tarifs`}
                                            target='_blank'
                                        />
                                        {`.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Le Rédacteur peut à tout moment demander à ${process.env.REACT_APP_NAME}.fr de lui verser sur son compte Bancaire tout ou partie des « Gains Disponibles » qu’il possède. Pour chaque versement, ${process.env.REACT_APP_NAME}.fr réalisera une facture au nom et pour le compte du Rédacteur selon le mandat de facturation défini à l’article 6. Tout frais bancaires éventuels seront à la charge exclusive du bénéficiaire (Notamment mais sans s’y limiter, pour les Rédacteurs ayant un compte hors zone SEPA.).`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Si l’objectif de la mission initialement fixé n’est pas atteint, les crédits non utilisés, qui sont non-remboursables, seront toutefois débloqués et pourront de nouveau être utilisés par le Donneur d’Ordres pour d’autres missions.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`5.2 Interruption des missions`}
                            content={`Si pour une raison ou pour une autre, le Donneur d’Ordres souhaite interrompre une mission en cours alors que le temps défini initialement n’est pas écoulé, il devra payer une indemnité correspondant à 35% de la valeur des RDV ou des étapes qu’il restait à prendre au Rédacteur pour atteindre l’objectif (15.00% pour le Rédacteur et 20.00% pour ${process.env.REACT_APP_NAME}.fr). En revanche, lorsque ce temps est écoulé, le Donneur d’Ordres peut à tout moment et gratuitement arrêter la mission et ainsi débloquer les fonds correspondants pour par exemple, les attribuer à une autre mission.`}
                        />
                        <SubArticle
                            title={`5.3 Validation des prestations`}
                            content={`Dans le cas où le Donneur d’Ordres n'a fourni aucune instruction à ${process.env.REACT_APP_NAME}.fr par rapport à une validation d’une étape de mission, au bout de 24 heures (du lundi 8h00 au Vendredi 20h00), cette dernière sera automatiquement validée et sera payée au Rédacteur en fin de mission.`}
                        />
                    </>
               }
            />
            <Article
                title={`6. Mandat de facturation`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SimpleText>
                            {`Pour des raisons pratiques et de logistique, le Rédacteur souhaite confier, dans le respect des règles économiques et fiscales applicables, à ${process.env.REACT_APP_NAME}.fr, l’établissement et l’émission de ses factures de Missions, ce que ${process.env.REACT_APP_NAME}.fr consent à faire.`}
                        </SimpleText>
                        <SubArticle
                            title={`6.1 Object du mandat`}
                            content={`Par les présentes, le Rédacteur donne expressément mandat à ${process.env.REACT_APP_NAME}.fr, qui accepte d’établir en son nom et pour son compte les factures originales relatives aux Missions, ceci conformément à la réglementation fiscale et économique en vigueur, et en particulier les dispositions des articles 242 nonies, I de l’annexe II au code général des impôts et 289, I-2 du code général des impôts.`}
                        />
                        <SubArticle
                            title={`6.2 Obligations de ${process.env.REACT_APP_NAME}.fr`}
                            content={
                                <>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr s’oblige à établir les factures relatives aux Missions conformément aux principes posés par l’article 6 du présent document, au nom et pour le compte du Rédacteur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr s’oblige à ce que les factures originales, émise par ses soins au nom et pour le compte du Rédacteur en application du présent mandat de facturation, présentent les mêmes formes que si elles avaient été émises par le Rédacteur lui-même, en particulier en ce qui concerne les mentions obligatoires visées par la réglementation fiscale. Concernant la numérotation des factures celle-ci sera établie par séries distinctes. En pratique, ${process.env.REACT_APP_NAME}.fr utilisera une séquence de facturation chronologique et continue distincte pour chacun de ses Rédacteurs. Toutefois, le Rédacteur pourra modifier et choisir lui-même la numérotation de ses factures au moment de leur édition. Il s’engage à respecter les lois fiscales en vigueur et dégage ${process.env.REACT_APP_NAME}.fr de toutes responsabilités en cas d’irrégularité et en assumera toutes les conséquences.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`De plus, ${process.env.REACT_APP_NAME}.fr s’engage également à ce que les factures originales des Missions émises par ses soins portent la mention « facture établie par ${process.env.REACT_APP_NAME}.fr » au nom et pour le compte de « nom du Rédacteur ».`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Le double de chaque facture sera adressé au Rédacteur et l’original conservé par ${process.env.REACT_APP_NAME}.fr`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`6.3 Obligations du Rédacteur`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Le Rédacteur conserve l’entière responsabilité de ses obligations légales et fiscales en matière de facturation au titre des factures originales émises au nom et pour son compte par ${process.env.REACT_APP_NAME}.fr en application du présent mandat de facturation, notamment en ce qui concerne les obligations en matière de TVA.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Dans ce cadre, le Rédacteur prend expressément l’engagement de :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- déclarer auprès de l’administration fiscale la TVA collectée dans le respect de la réglementation fiscale applicable;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- de verser au Trésor Public la TVA mentionnée sur les factures établies en son nom et pour son compte par ${process.env.REACT_APP_NAME}.fr;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- de réclamer immédiatement le double de la facture si cette-dernière ne lui ait pas parvenu;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`- et de signaler sans délai toute modification dans les mentions concernant l'identification de son entreprise.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`6.4 Contestation des factures émises par ${process.env.REACT_APP_NAME}.fr pour le compte du Rédacteur`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Conformément aux dispositions de l’article 242 nonies de l’annexe II au code général des impôts, les factures émises dans le cadre du présent mandat de facturation n’auront pas besoin d’être authentifiées de manière formelle par le Rédacteur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Le Rédacteur pourra toutefois contester les informations contenues dans les factures de Missions établies au titre du présent mandat de facturation, dans un délai de 30 jours à compter de l’émission desdites factures.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Dans l’hypothèse d’une contestation, le Rédacteur émettra sans délai une facture rectificative.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`6.5 Durée du mandat`}
                            content={`Le présent mandat de facturation, qui prend effet à compter de l’acceptation des présentes Conditions Générales d’Utilisation, est conclu pour la même durée et dans les mêmes conditions que ces dernières.`}
                        />
                    </>
               }
            />
            <Article
                title={`7. Comptes`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`7.1 Ouverture de compte`}
                            content={`Pour devenir un utilisateur et accéder au site et ses services, vous devez vous inscrire et ouvrir un "compte". Vous vous engagez à fournir des informations vraies, exactes et complètes comme demandé dans le formulaire d'inscription et de mettre à jour ces informations pour maintenir leur véracité, leur exactitude et leur exhaustivité.`}
                        />
                        <SubArticle
                            title={`7.2 Comptes`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Vous convenez que vous ne recevrez pas d'intérêts ou autres gains sur les fonds que gère ${process.env.REACT_APP_NAME}.fr. ${process.env.REACT_APP_NAME}.fr peut percevoir des intérêts sur ces fonds.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Tous les montants sont exprimés en Euros (€) et tous les paiements seront effectués en Euros (€). ${process.env.REACT_APP_NAME}.fr se réserve le droit de suspendre un retrait si la source des fonds est soupçonnée d'être frauduleuse. Les fonds reçus à partir d'un compte ayant fait un dépôt frauduleux (par exemple de carte de crédit volée) seront inversés immédiatement. Si dans une situation de paiement frauduleux un retrait a déjà été traité, vous serez tenu de retourner les fonds sur votre compte ${process.env.REACT_APP_NAME}.fr ou face à un refus, nous ferons appel à toutes les voies de recours disponibles pour récupérer les fonds.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Tout utilisateur que ${process.env.REACT_APP_NAME}.fr retire du service en raison de la violation des Conditions Générales d’Utilisation ne recevra pas de crédit ou de paiement et deviendra responsable de certains frais décrits dans les présentes Conditions Générales d’Utilisation.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Vous comprenez et acceptez que ${process.env.REACT_APP_NAME}.fr ne détiendra pas vos fonds sur un compte distinct, mais bien sur un compte globale. ${process.env.REACT_APP_NAME}.fr pourra mélanger vos fonds à d'autres fonds devant être versés à d’autres utilisateurs. En outre, vous comprenez que ces fonds amalgamés pourraient être utilisés pour payer d'autres utilisateurs ou pour tous autres achats à des fins générales.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`7.3 Comptes inactifs`}
                            content={`Les comptes utilisateurs (Rédacteurs et Donneurs d’Ordres) qui seront inactifs pendant plus de 365 jours ("Comptes inactifs") seront supprimés par ${process.env.REACT_APP_NAME}.fr ainsi que les crédits éventuels qui s’y trouveraient. Aucune réclamation ne pourra être faite, les crédits ne seront ni réutilisables ni remboursables.`}
                        />
                        <SubArticle
                            title={`7.4 Maintien des fonds`}
                            content={`${process.env.REACT_APP_NAME}.fr, à sa seule discrétion, peut placer une retenue sur tout ou partie des paiements que vous recevez lorsque ${process.env.REACT_APP_NAME}.fr estime qu'il peut y avoir un haut niveau de risque associé avec vous, votre compte, ou tout ou partie de vos transactions. Si ${process.env.REACT_APP_NAME}.fr met une emprise sur tout ou partie des paiements que vous recevez, vous recevrez un avis de nos actions et la manière de résoudre le problème. Si vous êtes impliqué dans un litige, dans certaines circonstances ${process.env.REACT_APP_NAME}.fr peut placer une retenue temporaire sur les fonds dans votre compte pour couvrir le montant de toute responsabilité éventuelle. Si le différend est réglé en votre faveur, ${process.env.REACT_APP_NAME}.fr lèvera le maintien temporaire. Si vous perdez le différend, ${process.env.REACT_APP_NAME}.fr peut retirer les fonds de votre compte.`}
                        />
                        <SubArticle
                            title={`7.5 Impôts`}
                            content={`Vous êtes responsable de payer tous les impôts ou taxes, qui peuvent être applicables en fonction de votre situation.`}
                        />
                        <SubArticle
                            title={`7.6 Authentification de l'identité`}
                            content={`Vous autorisez ${process.env.REACT_APP_NAME}.fr, directement ou par des tiers, à faire des demandes que nous considérons nécessaires afin de valider votre identité en conformité avec la législation et la réglementation en vigueur, ainsi que pour nos propres mesures anti-fraude. Il peut s'agir de vous demander de plus amples informations, vous obligeant à fournir votre date de naissance, un numéro d'identification du contribuable et d'autres informations qui nous permettront de vous identifier raisonnablement, vous obligeant à prendre des mesures pour confirmer la propriété de votre adresse e-mail ou d'instruments financiers; commander un rapport de crédit, ou de vérifier vos informations des bases de données de tiers ou par d'autres sources. Nous pouvons demander à voir le permis de conduire ou d'autres documents d’identification pertinents. En outre, dans certains cas, nous pouvons vous demander de fournir une identification photographique. ${process.env.REACT_APP_NAME}.fr se réserve le droit de fermer, suspendre ou limiter l'accès à votre compte utilisateur dans le cas où nous serions incapables d'obtenir ou de vérifier les informations requises par la présente clause.`}
                        />
                    </>
               }
            />
            <Article
                title={`8. éviter les commissions de la marketplace`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`8.1 Interdiction de négociation à l'extérieur du site`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Il est strictement interdit de tenter de négocier les frais d'une mission avec un autre utilisateur directement (en dehors de ${process.env.REACT_APP_NAME}.fr) après que cette mission ait été créée / ouverte et avant que cette mission ait été fermée (c'est à dire pendant une mission en cours). Cela vaut également pour toute mission qui a été fermée sans sélection de Rédacteur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Il est strictement interdit de sous-enchérir sur des missions pour tenter de renégocier le prix réel en privé, évitant les frais.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Nous croyons que nos commissions sont très raisonnables et justifiés pour le service que nous offrons, nous ne tolérerons donc absolument aucune tentative de fraude ou de sous-enchère sur le Site.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`8.2 Email`}
                            content={`Les utilisateurs acceptent de ne pas publier leur adresse e-mail sur le site, sauf dans le formulaire d'inscription 'e-mail', ou lorsqu'elle leur sera demandée par ${process.env.REACT_APP_NAME}.fr. Vous pouvez utiliser la boîte de réception des messages privés sur le site pour communiquer. Cela ne s'applique pas seulement aux adresses e-mail, mais à toutes les méthodes de communication, y compris le téléphone, ICQ, AIM, MSN Messenger, GTalk, Skype, Yahoo...`}
                        />
                        <SubArticle
                            title={`8.3 Fournir des informations de contact`}
                            content={`Sauf autorisation expressément accordée par ${process.env.REACT_APP_NAME}.fr, il vous est interdit d'entrer en contact directement avec un autre utilisateur en dehors de ${process.env.REACT_APP_NAME}.fr. Cela inclus de donner votre adresse e-mail, Skype ID, numéro ICQ, numéro de téléphone, ou tout autre moyen de contact avec l'extérieur de ce site. ${process.env.REACT_APP_NAME}.fr vous fournit une messagerie électronique, qui devrait être suffisant pour la planification et la communication avec d'autres utilisateurs.`}
                        />
                    </>
               }
            />
            <Article
                title={`9. Contenu de l'utilisateur`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`9.1 Contenu`}
                            content={
                                <>
                                    <SimpleText>
                                        {`L'Utilisateur est seul responsable du contenu ou de toute autre information qu’il fournit à ${process.env.REACT_APP_NAME}.fr. L'Utilisateur comprend et accepte les conditions suivantes :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• ${process.env.REACT_APP_NAME}.fr agit uniquement comme un lieu pour la distribution en ligne et publication de contenu d'utilisateur. Cependant, aucune garantie quant à la répartition réelle ou publication de contenu d'utilisateur sont faites ou implicite. ${process.env.REACT_APP_NAME}.fr a le droit (mais non l'obligation) de prendre toute mesure jugée appropriée par rapport au contenu de l'utilisateur s’il croit que ce contenu peut créer une responsabilité pour ${process.env.REACT_APP_NAME}.fr, nuire à son fonctionnement ou à la réputation de l'entreprise.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Tout le contenu présenté sur le site est soumis à l'approbation des administrateurs du site. Le Site se réserve le droit de rejeter, d'approuver ou de modifier le contenu de l'utilisateur selon la discrétion des administrateurs du site. Ces lignes directrices, qui englobent tous les aspects objectivement quantifiables et esthétiques du contenu soumis par les utilisateurs, sont définies uniquement par le site et appliquées exclusivement dans le site.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`9.2 Octroi de license`}
                            content={`En soumettant du contenu d'utilisateur à ${process.env.REACT_APP_NAME}.fr (y compris, mais non limité à la création de votre compte, en postant une mission, en complétant un profil, en envoyant de messages à travers ou à ${process.env.REACT_APP_NAME}.fr…), vous accordez à ${process.env.REACT_APP_NAME}.fr un droit dans le monde entier, exclusif, cessible perpétuel irrévocable, libre de redevance pour utiliser, copier, modifier, afficher et exécuter le contenu d'utilisateur.`}
                        />
                    </>
               }
            />
            <Article
                title={`10. Restriction d'utilisation`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`10.1 Publicité`}
                            content={`Les utilisateurs sont interdits de publicité d'un site Web sur le site. Toute URL affiché dans une soumission, une description de mission, ou dans un message, est interdite.`}
                        />
                        <SubArticle
                            title={`10.2 Donneur d'Ordres`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Le Donneur d’Ordres convient que :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Il ne pourra pas utiliser son compte pour poster des descriptions de missions fausses ou trompeuses.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Il ne pourra pas publier des descriptions de missions qui sont jugées par ${process.env.REACT_APP_NAME}.fr, comme inappropriées.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Il ne falsifiera pas son identité.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Il se conformera à toutes les règles de ${process.env.REACT_APP_NAME}.fr qui sont ou seront diffusée sur le site.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`10.3 Rédacteur`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Le Rédacteur accepte que :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Il ne falsifiera pas son identité.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Il se conformera à toutes les règles de ${process.env.REACT_APP_NAME}.fr qui sont ou seront diffusée sur le site.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`10.4 Interdiction d'utiliser le contenu du site`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Le contenu et les informations sur le Site (y compris, mais sans s'y limiter, les messages, données, informations, textes, musiques, sons, photos, graphiques, vidéos, commentaires, la réputation, les avis, les icônes, les logiciels, codes ou autres), ainsi que l'infrastructure utilisée pour fournir de tels contenus et informations, sont notre propriété. Tous les utilisateurs s'engagent à ne pas modifier, copier, distribuer, transmettre, afficher, exécuter, reproduire, publier, créer des œuvres dérivées, transférer, vendre ou revendre toute information, logiciel, produits ou services obtenus à partir ou par l'intermédiaire de ce site. En outre, vous acceptez de ne pas :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• accéder, contrôler ou copier tout contenu ou information de ce site en utilisant un robot, spider, scraper ou tout autre moyen automatisé ou tout processus manuel dans ce but sans notre autorisation expresse et écrite;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• violer les restrictions des en-têtes d'exclusion des robots sur ce site ou de contourner d'autres mesures prises pour prévenir ou limiter l'accès à ce site;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• prendre aucune mesure qui impose, ou peut imposer, à notre discrétion, une charge déraisonnable ou disproportionnée à notre infrastructure;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• incorporer une partie de ce site sur un autre site sans notre autorisation écrite préalable; ou tenter de modifier, traduire, adapter, décompiler, désassembler, désosser des programmes ou des logiciels utilisés par nous en relation avec le site ou les services.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`10.5 Restrictions générales`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Les utilisateurs conviennent que :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Les utilisateurs n’utiliseront aucun mécanisme de collecte automatisé ou tout processus manuel pour surveiller ou copier des pages Web comprenant le site ou le contenu sans l'autorisation préalable écrite de ${process.env.REACT_APP_NAME}.fr.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Les utilisateurs ne diffuseront aucun message commercial non sollicité ('spam') par le biais du compte de l'utilisateur.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Les utilisateurs ne contacteront pas les Donneurs d’Ordres ou Rédacteurs à travers le Site ou par l'information acquise à partir de notre site avec l'intention de contourner l'utilisation de nos services.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Les utilisateurs ne se livreront pas à des attaques personnelles, critiques négatives ou déloyales ou à d'autres formes de comportement ou de pratiques en ligne manquant de courtoisie et de professionnalisme.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• Les utilisateurs ne pourront pas créer plusieurs comptes utilisateurs pour une raison quelconque, ou dans n'importe quelle circonstance. Si vous créez une mission, vous ne pouvez pas créer un autre compte pour enchérir sur elle, et vous ne pouvez pas vous attribuer cette mission.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`10.6 Conséquences de la résiliation`}
                            content={
                                <>
                                    <SimpleText>
                                        {`En cas de résiliation, les utilisateurs trouvés en violation des Conditions Générales d’Utilisation ne recevront aucun crédit ni aucun paiement de ${process.env.REACT_APP_NAME}.fr.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Sans limiter les autres recours de ${process.env.REACT_APP_NAME}.fr, dans la mesure où vous êtes en violation de ces Conditions Générales d’Utilisation, vous devez payer tous les frais engendrés par cette situation et nous rembourser toutes les pertes et coûts (y compris tout le temps des employés ${process.env.REACT_APP_NAME}.fr ) et des dépenses raisonnables (y compris les frais juridiques) liées à une enquête sur une telle infraction, et la collecte de ces frais.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Vous acceptez de payer les dommages-intérêts éventuellement dus à ${process.env.REACT_APP_NAME}.fr en raison d'actions qui violeraient les Conditions Générales d’Utilisation, y compris (sans limitation) les pénalités et autres frais connexes. Si vous vous engagez dans ces activités, alors ${process.env.REACT_APP_NAME}.fr peut vous imposer une pénalité allant jusqu'à 3 000€ pour chaque violation et/ou ${process.env.REACT_APP_NAME}.fr peut agir en justice contre vous pour recouvrer les pertes qui sont au-delà du montant facturé. Vous reconnaissez et acceptez qu’une pénalité de 3000€ est actuellement raisonnable en pré-estimation minimum des dommages de ${process.env.REACT_APP_NAME}.fr.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Vous acceptez que ${process.env.REACT_APP_NAME}.fr est en droit de déduire ces frais directement à partir de tout solde existant dans votre compte ou de tout autre compte vous appartenant.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`En cas de résiliation, vous n'aurez droit à aucune réclamation contre ${process.env.REACT_APP_NAME}.fr à l'égard d'une telle suspension ou résiliation de votre abonnement.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`10.7 Vérification des comptes`}
                            content={`Si vous ne remplissez pas la vérification des comptes à la demande dans les 12 mois suivant la date de la demande, votre compte peut être dénoncé.`}
                        />
                    </>
               }
            />
            <Article
                title={`11. Services de règlement des conflits sur la marketplace`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`11.1 Services de règlement des différends`}
                            content={
                                <>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr offre un service de règlement des différends pour les utilisateurs. Vous reconnaissez et acceptez que :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(I) ${process.env.REACT_APP_NAME}.fr ne fournit pas de services juridiques,`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(II) ${process.env.REACT_APP_NAME}.fr ne vous conseille pas sur les questions juridiques, et`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`(III) si vous désirez avoir un avocat, vous chercherez un indépendant autorisé à pratiquer le droit dans votre juridiction. Vous ne pourrez pas compter sur ${process.env.REACT_APP_NAME}.fr pour un tel avocat.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`En cas de litige entre un Donneur d’Ordres et un Rédacteur en ce qui concerne le paiement d'étape de mission, le Donneur d’Ordres et le Rédacteur peuvent choisir d'utiliser les services de règlement des différends offerts par ${process.env.REACT_APP_NAME}.fr`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Vous acceptez d'indemniser et (dans la mesure permise par la loi) maintenez ${process.env.REACT_APP_NAME}.fr à l'abri de tout dommage ou responsabilité que vous pourriez subir suite à l'utilisation des services de règlement des différends.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr répondra aux litiges initiés par un Rédacteur et acceptés par le Donneur d’Ordres en conformité avec les services de règlement des différends énoncés dans la présente clause 11.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`11.2 Autres litigesends`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Vous reconnaissez et acceptez, dans le cas où un différend surgit entre vous et un autre utilisateur lors d’une mission, de tenter d'abord de le résoudre à l’amiable, y compris en ce qui concerne la qualité des services fournis.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Vous acceptez que tout litige, qui n'est pas lié à un paiement d'étape de mission doit être réglé à l’amiable et qu’il ne peut être traité par « le service de règlement des différends ». Toutefois, ${process.env.REACT_APP_NAME}.fr aura les pleins droits et le pouvoir de prendre une décision pour tous les différends.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Si vous continuez à avoir des difficultés ou des problèmes liés à un différend avec un autre utilisateur, nous vous invitons à nous contacter aux coordonnées indiquées à l'article 17.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`En ce qui concerne les différends avec d'autres utilisateurs du Site, vous acceptez d'indemniser ${process.env.REACT_APP_NAME}.fr de toutes les réclamations, demandes et dommages, directs et indirects, de quelque nature, connus et inconnus, qui est lié à ce différend, nos décisions ou de l'utilisation des services de règlement des différends de paiements d'étapes mission et/ou pour d'autres différends.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`11.3 L'équipe des différends`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Les deux parties de l'affaire du différend peuvent choisir de soumettre leur différend à l'arbitrage de l'équipe des différends. Le rôle de l'équipe des différends s'étend à prendre toutes les mesures nécessaires pour résoudre le cas d'une manière impartiale et probante. Vous reconnaissez que le verdict de l'équipe des différends est définitif, obligatoire et irréversible.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Toutes les réclamations et les actions (y compris, mais sans s'y limiter, des actions faites par erreur) des parties sur le système des différends sont irrecevables. Vous acceptez que l'équipe des différends et ${process.env.REACT_APP_NAME}.fr sont par les présentes libérés des dommages directs et indirects induits par ces revendications.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`11.4 Processus de règlement des différends`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Le Rédacteur plaignant peut contester la « non-validation » d’une étape de mission par le Donneur d’Ordres. A ce stade, les deux parties peuvent négocier via la messagerie instantanée. S’il n’arrive pas à se mettre d’accord, le Rédacteur pourra cliquer sur le bouton « Demander un arbitrage » et payer les frais de l'arbitrage pour régler ce litige.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Le Donneur d’Ordres dispose de 24 heures (du lundi 8h00 au Vendredi 20h00) pour payer aussi les frais.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Si le Donneur d’Ordres ne paie pas les frais d'arbitrage dans les 24 heures (du lundi 8h00 au Vendredi 20h00), le résultat sera en faveur du Rédacteur qui sera remboursé de ces frais. Le différend sera résolu uniquement sur la base des Critères définis dans le contrat de prestation de service et de l’enregistrement du ou des appels.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`La partie qui remporte le différend sera remboursée des frais d'arbitrage.`}
                                    </SimpleText>
                                </>
                            }
                        />
                    </>
               }
            />
            <Article
                title={`12. Enregistrement et traitement de vos données personnelles`}
                titleSx={{ textTransform: 'uppercase' }}
                content={`Consultez notre politique de protection des données personnelles pour en savoir plus.`}
            />
            <Article
                title={`13. Responsabilités`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`13.1 Aucune garantie`}
                            content={
                                <>
                                    <SimpleText>
                                        {`${process.env.REACT_APP_NAME}.fr met en place les mesures nécessaires afin d’assurer la sécurité du système et l’intégrité des informations clients. De plus, afin de se prémunir d’éventuelles attaques ou pertes d’informations, ${process.env.REACT_APP_NAME}.fr sous-traite à un prestataire la gestion ainsi que la maintenance de ses serveurs qui réalise régulièrement des copies de sauvegarde sur différends serveurs distants. Toutefois, nos services, le site et l'ensemble du contenu sont fournis sans aucune garantie d'aucune sorte, expresse ou implicite. Sans limiter ce qui précède, ${process.env.REACT_APP_NAME}.fr ne représente ni ne garantit que:`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• le site sera précis, fiable, ininterrompu, sécurisé ou sans erreur;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• les défauts du site seront corrigés;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• le site ou le serveur qui le rend disponible sont exempts de virus ou autres composants nuisibles.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`13.2 Légalité des listes d'appels`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Le Donneur d’Ordres a l’obligation de s’assurer que son fichier de prospection respecte les lois en vigueur dans le ou les pays concernés par ses missions de téléprospection. Il est à noter qu’en France, toute prospection commerciale de consommateurs par des professionnels, par voie téléphonique, ayant pour objet la vente d'équipements ou la réalisation de travaux pour des logements en vue de la réalisation d'économies d'énergie ou de la production d'énergies renouvelables est interdite.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`En cas de plaintes ou de procédures judiciaires, le Donneur d’Ordres est le seul responsable des prospects ciblés et ${process.env.REACT_APP_NAME}.fr, en tant que simple outil d'appels et de mise en relation ne peut s’assurer de l’application stricte de ces mesures et décline donc toute responsabilité en cas de leur non-application et se retournera donc systématiquement contre le Donneur d’Ordres en cas de poursuit`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`13.3 Application des mesures légales`}
                            content={`En fonction de la législation en vigueur dans le pays où se déroule la mission (notamment en France), les Rédacteurs doivent, le cas échéant, avertir leur interlocuteur (Clients, prospects, partenaires…) que la conversation est enregistrée et ce, dès le début de l’appel. Ils doivent également dans le cadre des missions BtoC (auprès de particuliers) en France et dans tout pays imposant cette mesure, leur indiquer que « tout particulier peut s'inscrire gratuitement sur la liste d'opposition au démarchage téléphonique s'il le souhaite ». En cas de manquement des Rédacteurs, il revient aux Donneurs d’Ordres, soit de faire appliquer cette procédure, soit d’arrêter la mission en cours. ${process.env.REACT_APP_NAME}.fr, en tant que simple outil d'appels et de mise en relation ne peut s’assurer de l’application de ces mesures et décline donc toute responsabilité en cas de leur non-application et se retournera donc systématiquement contre les Donneurs d’Ordres en cas de poursuite.`}
                        />
                        <SubArticle
                            title={`13.4 Identifiants d’appelant`}
                            content={`Lors du paramétrage des missions, pour des raisons éthiques et de performance, il est fortement recommandé aux Donneurs d’ordres d’indiquer le numéro de téléphone qu’ils souhaitent utiliser comme identifiant d’appelant. L’identifiant d’appelant est le numéro de téléphone qui s’affiche sur le téléphone du prospect lorsqu’il reçoit un appel. Si aucun identifiant d’appelant n’a été paramétré par le Donneur d’Ordres, un numéro de téléphone lui sera alloué par défaut pour chaque appel. Une fois l’appel émis, que le prospect ait décroché ou non, ce dernier pourra connaitre l’identité de la société du Donneur d’Ordres en recomposant le numéro qui lui aura été présenté. En effet, afin de se conformer à la réglementation et pour éviter tout démarchage abusif, un message automatique lui déclinera les coordonnées de la société qui est à l’origine de l’appel. Pour renforcer ces mesures de protection, le prospect aura également la possibilité, en utilisant son clavier téléphonique, de refuser tout rappel de cette société. Afin de respecter son choix et de garantir une utilisation étique de nos services, dans le cas où un prospect effectuerait cette action, il ne sera plus possible de recontacter ce dernier en utilisant ${process.env.REACT_APP_NAME}.fr.`}
                        />
                    </>
               }
            />
            <Article
                title={`14. Limitation de responsabilité`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SubArticle
                            title={`14.1 Limitation de responsabilité`}
                            content={
                                <>
                                    <SimpleText>
                                        {`En aucun cas ${process.env.REACT_APP_NAME}.fr, ses sociétés affiliées ou personnels responsables, ne pourront être tenus pour responsable, pour :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• des dommages directs, spéciaux, indirects ou consécutifs pouvant être encourus par vous;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• toute perte de revenus, de chiffre d’affaires ou de bénéfices (directs ou indirects) qui peuvent être encourus par vous;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• toute réclamation, perte ou dommage qui peut être encourue par vous en tant que résultat d'une de vos transactions impliquant le Site. Nonobstant les dispositions ci-dessus, rien dans les présentes Conditions Générales d’Utilisation ont pour but de limiter ou d'exclure toute responsabilité de la part de ${process.env.REACT_APP_NAME}.fr et de ses filiales et entités dans la mesure où la législation applicable interdit une telle exclusion ou limitation, y compris ceux relevant de la concurrence et de la consommation.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`14.2 Limitations de responsabilité des outils d’élaboration dynamique de fichiers`}
                            content={
                                <>
                                    <SimpleText>
                                        {`Les outils d’élaboration dynamique de fichiers de prospections (aspirateur d’annuaires, module de piges immobilières, autos…) vous permettent d'effectuer une recherche manuelle fiche par fiche directement sur les sites d’annonces ou sur les annuaires de votre choix. Ces outils interviennent uniquement pour vous assister dans le traitement des données correspondant à votre recherche afin de vous éviter de recopier les informations qui vous sont utiles. Vous ne devez en aucun cas utiliser les outils d’élaborations dynamique de fichiers comme des « robots » qui auraient pour but d'effectuer plusieurs recherches et de collecter automatiquement des données pour vous permettre de constituer un fichier ou une base de données. De plus, en tant qu'initiateur des données collectées, vous acceptez et reconnaissez que ${process.env.REACT_APP_NAME}.fr ne revendique aucune propriété sur ces données et qu’une fois ces données utilisées, en tant que propriétaire, vous ne conserverez que celles strictement nécessaires à vos relations commerciales et que vous vous conformerez de manière générale à la loi française, la loi en vigueurs dans votre pays et à celle du pays hébergeant le site ou l'annuaire en question.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`En outre, vous garantissez que vous disposez de tous les droits nécessaires pour consulter, copier et utiliser les données collectées dans les sites et annuaires que vous paramétrez dans les outils d’élaboration dynamique de fichiers. ${process.env.REACT_APP_NAME}.fr se dégage de toute responsabilité en cas de fraude, d'abus ou de négligence de votre part. Vous devez vous assurer que les données collectées soient libre d'accès ou le cas échéant obtenir l'autorisation du site, de l'annuaire ou de la personne morale propriétaire de ces données avant toute aspiration. Vous reconnaissez et comprenez que bien qu'un site ou un annuaire puisse être accessible via les outils d’élaboration dynamique de fichiers, cela ne vous exonère en rien des obligations citées ci-dessus. En cas de doute, vous ne devez en aucun cas utiliser ces outils et en cas d'utilisation, vous ne pourrez en aucun cas vous dégager de vos responsabilités. A la demande de titulaires de droits tiers, vous devrez cesser de traiter de quelque manière que ce soit les données collectées dont ils sont propriétaires, si vous ne disposiez pas des licences appropriées pour traiter ces données.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`La possibilité d’utiliser des données statistiques rendues publiques par divers organismes (Etat, INSEE...) a pour but de focaliser les recherches sur les zones qui concentrent le plus d'individus correspondant au profil que vous recherché. Cette utilisation n'a pour objectif que d'affiner votre recherche et vous ne devez en aucun cas essayer d'identifier les individus concernés par ces données, (de manière directe ou indirecte).`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`De manière générale, vous êtes tenu de vous assurer que les données collectées ne peuvent en aucune manière :`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• contenir du contenu diffamatoire envers toute personne, obscène, offensant, haineux ou incendiaire, promouvoir du matériel sexuellement explicite ou promouvoir la violence ou promouvoir la discrimination fondée sur la race, le sexe, la religion, la nationalité, le handicap, l'orientation sexuelle ou l'âge;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• enfreindre les règles relatives aux données personnelles en vigueur en France ou dans votre pays;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• être susceptibles de tromper toute personne ou de promouvoir toute forme d'activité illégale ;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• porter atteinte ou être considérées comme une violation de tout droit de propriété intellectuelle de toute personne, y compris, mais sans s'y limiter, le droit d'auteur, le droit de brevet, le droit de base de données, le droit de marque, le droit de conception;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• être menaçantes, abusives ou envahissant la vie privée d'une autre personne, ou causer de la gêne, des désagréments ou de l'anxiété inutile ou être susceptibles de harceler, bouleverser, embarrasser, alarmer ou ennuyer toute autre personne;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• être utilisées pour usurper l'identité d'une personne, ou être en mesure d'induire en erreur un tiers en ce qui concerne votre identité ou votre affiliation avec une personne ou peut donner l'impression qu'elles proviennent de ${process.env.REACT_APP_NAME}.fr;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• être utilisées pour promouvoir, soutenir ou assister tout acte illégal tel que, mais sans s'y limiter , les violations du droit d'auteur et les actes de négligence;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• être utilisées pour distribuer, accéder, transmettre ou stocker tout Virus en utilisant le Service.`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`Vous acceptez que tout manquement à se conformer à la présente clause constituera une violation substantielle des présentes conditions d'utilisation, et qu'un tel manquement puisse entraîner ${process.env.REACT_APP_NAME}.fr à prendre tout ou partie des actions suivantes `}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• retirer immédiatement, temporairement ou définitivement tout ou partie des droits d'utilisation du Service;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• émettre un avertissement;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`• intenter une action en justice contre vous ou tout affilié, y compris des procédures pour le remboursement de tous les frais et dépenses en vertu des présentes, mais sans s'y limiter, les frais administratifs et juridiques raisonnables engagés par nous en raison de la violation;`}
                                    </SimpleText>
                                    <SimpleText>
                                        {`En utilisant le Service, vous reconnaissez et acceptez que les actions décrites dans cette clause sont illimitées, et nous pouvons prendre toute autre action que nous jugeons raisonnablement appropriée.`}
                                    </SimpleText>
                                </>
                            }
                        />
                        <SubArticle
                            title={`14.3 Limitations de Compétences`}
                            content={`Comme certaines juridictions ne permettent pas l'exclusion ou les limitations établies ci-dessus, certaines de ces exclusions ou limitations peuvent ne pas vous êtes appliqué. Dans ce cas, la responsabilité sera limitée autant que possible légalement en vertu de la législation applicable.`}
                        />
                    </>
               }
            />
            <Article
                title={`15. Loi applicable`}
                titleSx={{ textTransform: 'uppercase' }}
                content={`Les présentes Conditions Générales d’Utilisation sont soumises au droit français. Toutes difficultés relatives à l’interprétation, à l’exécution et à l’expiration des présentes seront soumises, à défaut d'accord amiable, au Tribunal compétent du ressort de ${process.env.REACT_APP_NAME}.fr, auquel les parties attribuent compétence territoriale et ceci même en cas de référé, d’appel en garantie ou de pluralité de défendeurs.`}
            />
            <Article
                title={`16. Général`}
                titleSx={{ textTransform: 'uppercase' }}
                content={`Les dispositions des présentes Conditions Générales d’Utilisation sont séparables, et si une disposition des présentes Conditions Générales d’Utilisation est jugée invalide ou inapplicable, cette disposition peut être enlevée et les dispositions restantes seront appliquées. Les présentes Conditions Générales d’Utilisation peuvent être cédées par ${process.env.REACT_APP_NAME}.fr à une tierce partie sans votre consentement dans le cas d'une vente ou tout autre transfert de tout ou partie des actifs de ${process.env.REACT_APP_NAME}.fr. Dans le cas de vente ou de transfert, vous resterez lié par les Conditions Générales d’Utilisation. Les titres sont à titre de référence seulement et ne définissent, limitent, interprètent ou décrivent la portée ou l'étendue d'une telle section. Notre incapacité à agir à l'égard d'une violation réelle ou prévue par vous ou d'autres ne renonce pas à notre droit d'agir en cas de violations ultérieures ou similaires. Rien dans le présent article ne peut exclure ou limiter votre responsabilité découlant de la fraude ou de fausse déclaration.`}
            />
            <Article
                title={`17. Nous contacter`}
                titleSx={{ textTransform: 'uppercase' }}
                content={
                    <>
                        <SimpleText>
                            {`Si vous avez des questions concernant ces conditions ou si vous souhaitez signaler des violations de ces conditions, merci de contacter contact@${process.env.REACT_APP_NAME}.fr`}
                        </SimpleText>
                        <SimpleText>
                            {`Merci de nous contacter pour signaler des violations des termes et conditions.`}
                        </SimpleText>
                    </>
                }
            />
            <Copyright paddingTop={10} align='left' />
        </Container>
    );
};

export default Policy;
