import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PaymentAPIs from '../../APIs/PaymentAPIs';
import {
    Alert,
    Collapse,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import FormikInput from '../FormikComponent/FormikInput';

const small: number = parseInt(process.env.REACT_APP_AI_GENERATION_SMALL!);
const medium: number = parseInt(process.env.REACT_APP_AI_GENERATION_MEDIUM!);
const large: number = parseInt(process.env.REACT_APP_AI_GENERATION_LARGE!);
const tokensPerEuro: number = parseInt(process.env.REACT_APP_TOKENS_PER_EURO!);

const AddTokens: React.FC = () => {
    // Use of hooks
    const [alert, setAlert] = React.useState<boolean>(false);
    // Use of redux hooks
    const instanceWallet = useAppSelector(state => state.instanceWallet);
    const instanceWalletId = useAppSelector(state => state.instance.account!.walletId);

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        amount: Yup.number()
            .required('Veuillez spécifier un montant')
            .min(0.1, 'Le montant minimum pour recharger vos jetons est de 0.1€')
            .max(instanceWallet.unlocked, "Vous n'avez pas les fonds nécessaires sur votre porte-monnaie"),
    });

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback(async (values: any) => {
        // Call API to add tokens
        return PaymentAPIs.patchWalletPayment(instanceWalletId, { amount: values.amount, credit: false, convertToTokens: true })
            // On successful API call, show alert message
            .then(() => setAlert(true));
    }, [instanceWalletId]);

    return (
        <Stack rowGap={2}>
            <Collapse in={alert}>
                <Alert action={
                    <IconButton size='small' onClick={() => setAlert(false)}>
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                }>
                    {`Vos jetons ont bien été crédités.`}
                </Alert>
            </Collapse>
            <Typography gutterBottom>
                {`Vous avez actuellement ${instanceWallet.tokens} jeton(s)`}
            </Typography>
            <Formik
                initialValues={{ amount: '' }}
                validationSchema={validationSchema}
                onSubmit={handleFormikSubmit}
                >
                {({ values, isValid, isSubmitting }) => (
                    <Form>
                        <Stack rowGap={3} alignItems='start'>
                            <FormikInput
                                name='amount'
                                upperLabel='Montant à prélever'
                                type='number'
                                placeholder='0.00 €'
                                numberFormatProps={{
                                    suffix: '.00 €',
                                    allowNegative: false,
                                    fixedDecimalScale: true,
                                    decimalScale: 0,
                                    thousandSeparator: ' ',
                                }}
                                inputSx={{ maxWidth: 300 }}
                                suffix={`max ${instanceWallet.unlocked} €`}
                                helperText='Ce montant sera prélever sur vos crédits disponibles'
                            />
                            <Stack>
                                <Typography>
                                    {`Soit ${'' !== values.amount ? Number(values.amount) * tokensPerEuro : 0} jetons au total`}
                                </Typography>
                                <Typography>
                                    {`~ ${'' !== values.amount ? Math.round(Number(values.amount) * tokensPerEuro / small) : 0} génération(s) S`}
                                </Typography>
                                <Typography>
                                    {`~ ${'' !== values.amount ? Math.round(Number(values.amount) * tokensPerEuro / medium) : 0} génération(s) M`}
                                </Typography>
                                <Typography>
                                    {`~ ${'' !== values.amount ? Math.round(Number(values.amount) * tokensPerEuro / large) : 0} génération(s) L`}
                                </Typography>
                            </Stack>
                            <LoadingButton
                                variant='contained'
                                type='submit'
                                disabled={!isValid}
                                loading={isSubmitting}
                                sx={{ marginY: 2 }}
                            >
                                {`Valider le rechargement`}
                            </LoadingButton>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Stack>
    );
};

export default AddTokens;
