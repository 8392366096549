import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import { Box, Typography, Button } from '@mui/material';
import GenericModal from './GenericModal';

const ModalInformation: React.FC<{
    title: string,
    message: string|React.ReactElement,
    name?: string|undefined,
    width?: number|string|undefined,
    type?: 'error'|'warning'|undefined,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'confirmModal');

    // Use of redux
    const dispatch = useAppDispatch();

    return (
        <GenericModal
            name={modalName.current}
            title={props.title}
            width={props.width ?? 1/3}
            type={props.type}
        >
            <Box paddingX={1} marginBottom={3}>
                {
                    'string' === typeof props.message ? (
                        <Typography align='center' paddingBottom={1}>
                            {props.message}
                        </Typography>
                    ) : props.message
                }
            </Box>
            <Button
                variant='outlined'
                color={props.type ?? 'primary'}
                onClick={() => dispatch(closeModal())}
            >
                {`Ok`}
            </Button>
        </GenericModal>
    );
};

export default ModalInformation;
