import React from 'react';
import {
    SxProps,
    Theme,
    Card,
    CardMedia,
    IconButton,
} from '@mui/material';
import { HighlightOffTwoTone as HighlightOffTwoToneIcon } from '@mui/icons-material';
import cssStyles from './ImagePreviewStyles';

const ImagePreview: React.FC<{
    imageSrc: string,
    maxSize?: string|number|undefined,
    sx?: SxProps<Theme>|undefined,
    closable?: boolean|undefined,
    onPreviewClose?: (() => void)|undefined,
}> = props => {
    return (
        <Card sx={{ ...cssStyles.card, ...props.sx } as SxProps<Theme>}>
            <CardMedia
                component='img'
                alt='Chargement ...'
                image={props.imageSrc}
                sx={{
                    ...cssStyles.cardMedia,
                    maxHeight: props.maxSize ?? 250,
                    maxWidth: props.maxSize ?? 250,
                }}
            />
            {
                props.closable && (
                    <IconButton sx={cssStyles.closeIcon} onClick={props.onPreviewClose}>
                        <HighlightOffTwoToneIcon fontSize='small' />
                    </IconButton>
                )
            }
        </Card>
    );
};

export default ImagePreview;
