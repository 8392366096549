import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeModal } from '../../../store/slices/activeModalSlice';
import PostAPIs from '../../../APIs/PostAPIs';
import OrderAPIs from '../../../APIs/OrderAPIs';
import Tools from '../../../helpers/Tools';
import {
	Stack,
	Typography,
	CardMedia,
	Divider,
    Alert,
    AlertTitle,
    Button,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import GenericModal from '../GenericModal';
import PostActions from './PostActions';
import PostHtml from './PostHtml';
import EditableInput from '../../EditableInput/EditableInput';
import Spinner from '../../Spinner/Spinner';
import EOrderStatuses from '../../../interfaces/EOrderStatuses';
import IOrder from '../../../models/IOrder';
import IPost from '../../../models/IPost';

interface IOrderData {
    order?: IOrder|undefined,
    canEdit?: boolean|undefined,
    showActions?: boolean|undefined,
};

interface IPostData {
    title: string|undefined,
    html: string|undefined,
    metaDesc: string|undefined,
};

const initialPostData: IPostData = {
    title: undefined,
    html: undefined,
    metaDesc: undefined,
};

const ModalConsultPost: React.FC<{
    title: string,
    name?: string|undefined,
    onAccept?: ((order: IOrder, publish: boolean, rate?: number|undefined, comment?: string|undefined) => void)|undefined,
    onRefuse?: ((order: IOrder, reasons: Array<string>) => void)|undefined,
    onUnassign?: ((order: IOrder) => void)|undefined,
}> = props => {
    // Use of hooks
    const [postData, setPostData] = React.useState<IPostData>(initialPostData);
    const [orderData, setOrderData] = React.useState<IOrderData|null>(null);
    const modalName = React.useRef<string>(props.name ?? 'consultPostModal');

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceAccountScopes: Array<string> = useAppSelector(state => state.instance.account!.scopes);
    const orderId: number|undefined = useAppSelector(state => state.activeModal.params?.orderId);
    const isOpened: boolean = useAppSelector(state => state.activeModal.name === modalName.current);

    // Callback to reset Order data before closing the modal
    const handleCloseModal = React.useCallback(() => {
        setOrderData(null);
        dispatch(closeModal());
    }, [dispatch]);

    // Callback to handle post update
    const handlePostUpdate = React.useCallback(() => {
        orderData?.order?.post &&
            // Call API to update post data
            PostAPIs.patchPost(orderData.order.post.id, postData)
                // On successful API call
                .then((data: IPost|null) => {
                    // Update modal params to force modal to rerender with new data
                    if (true === isOpened && data) {
                        // Reset postData state
                        setPostData(initialPostData);
                        // Update order post data
                        setOrderData(orderData => ({ ...orderData!, order: { ...orderData!.order!, post: data }}));
                    }
                });
    }, [isOpened, postData, orderData]);

    // useEffect whenever orderId value changes
    React.useEffect(() => {
        // Reset postData state
        setPostData(initialPostData);
        // Request Order data
        true === isOpened && undefined !== orderId && OrderAPIs.getOrder({ id: orderId, detailed: true })
            // On successful API call
            .then((orders: Array<IOrder>|null) => {
                if (true === isOpened && orders && 1 === orders.length) {
                    const order = orders[0];
                    setOrderData({
                        order: order,
                        // Order status must be VERIFIED or UNPUBLISHED && instance user is account_owner or can_make_order
                        canEdit: [EOrderStatuses.VERIFIED, EOrderStatuses.UNPUBLISHED].includes(order.status.slug) && Tools.isScoped(instanceAccountScopes, ['account_owner', 'can_make_order']),
                        // Order status must be unverified && user is account_owner or can_make_order
                        showActions: EOrderStatuses.UNVERIFIED === order.status.slug && Tools.isScoped(instanceAccountScopes, ['account_owner', 'can_make_order']),
                    });
                }
            });
    }, [isOpened, orderId, instanceAccountScopes]);

    return (
        <GenericModal
            disableCloseOnEscape
            name={modalName.current}
            title={props.title}
            width={2/3}
            height={4/5}
            onClose={() => setOrderData(null)}
        >
            {
                orderData?.order?.post ? (
                    <>
                        {
                            true === orderData.canEdit && (
                                <Typography
                                    gutterBottom
                                    variant='body2'
                                    display='flex'
                                    alignItems='center'
                                    columnGap={1}
                                >
                                    <InfoIcon />
                                    {`Il est possible de modifier des informations en effectuant un double clique.`}
                                </Typography>
                            )
                        }
                        <Stack
                            spacing={1}
                            paddingBottom={(postData.title || postData.html || postData.metaDesc) ? 7 : undefined}
                        >
                            {
                                // Show alert to inform the user that the post has been refused
                                EOrderStatuses.REFUSED === orderData.order.status.slug && orderData.order.post.postRefuseMessages && 0 < orderData.order.post.postRefuseMessages.length && (
                                    <Alert severity='warning' sx={{ width: 'fit-content' }}>
                                        <AlertTitle>
                                            {`Article refusé par `}
                                            <Typography component='span' fontWeight={700}>
                                                {`${orderData.order.post.postRefuseMessages[0].user.name} ${orderData.order.post.postRefuseMessages[0].user.lastname}`}
                                            </Typography>
                                        </AlertTitle>
                                        {
                                            orderData.order.post.postRefuseMessages[0].reasons.map((reason, index) => (
                                                <Typography key={index}>
                                                    {`> ${reason}`}
                                                </Typography>
                                            ))
                                        }
                                    </Alert>
                                )
                            }
                            {
                                (orderData.order.imageUrl || orderData.order.post.imageUrl) && (
                                    <CardMedia
                                        component='img'
                                        image={orderData.order.post.imageUrl ?? orderData.order.imageUrl ?? undefined}
                                        height={200}
                                        sx={{ marginBottom: 1, borderRadius: 1 }}
                                    />
                                )
                            }
                            <EditableInput
                                canBeEmpty
                                canEdit={true === orderData.canEdit}
                                placeholder="Titre de l'article"
                                variant='h4'
                                value={undefined !== postData.title ? postData.title : orderData.order.post.title!}
                                onChange={(title: string) => setPostData(postData => ({ ...postData, title }))}
                            />
                            <PostHtml
                                canEdit={true === orderData.canEdit}
                                html={undefined !== postData.html ? postData.html : orderData.order.post.html!}
                                onChange={(html: string) => setPostData(postData => ({ ...postData, html }))}
                            />
                            <Typography color='primary.main' fontWeight={700}>
                                {`Meta description :`}
                            </Typography>
                            <EditableInput
                                canBeEmpty
                                multiline
                                canEdit={true === orderData.canEdit}
                                placeholder="Meta description de l'article"
                                value={undefined !== postData.metaDesc ? postData.metaDesc : orderData.order.post.metaDesc!}
                                onChange={(metaDesc: string) => setPostData(postData => ({ ...postData, metaDesc }))}
                            />
                            <Typography>
                                <Typography component='span' color='primary.main' fontWeight={700}>
                                    {`Score d'optimisation :`}
                                </Typography>
                                {` ${orderData.order.post.optimization ?? 0}%`}
                                {orderData.order.minOptimization ? ` / ${orderData.order.minOptimization}%` : null}
                            </Typography>
                            <Typography>
                                <Typography component='span' color='primary.main' fontWeight={700}>
                                    {`Nombre de mots :`}
                                </Typography>
                                {` ${orderData.order.post.nbrWords ?? 0}`}
                                {orderData.order.minNbrWords ? ` / ${orderData.order.minNbrWords}` : null}
                            </Typography>
                        </Stack>
                        {
                            (postData.title || postData.html || postData.metaDesc) && (
                                <Stack
                                    direction='row'
                                    width={1}
                                    spacing={2}
                                    paddingY={2}
                                >
                                    <Button
                                        variant='outlined'
                                        onClick={handlePostUpdate}
                                    >
                                        {`Sauvegarder les modifications`}
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        color='error'
                                        onClick={() => setPostData(initialPostData)}
                                    >
                                        {`Annuler`}
                                    </Button>
                                </Stack>
                            )
                        }
                        {
                            true === orderData.showActions && (
                                <>
                                    <Divider sx={{ marginY: 3 }} />
                                    <PostActions
                                        order={orderData.order}
                                        onAccept={(publish, rate, comment) => {
                                            props.onAccept && props.onAccept(orderData.order!, publish, rate, comment);
                                            handleCloseModal();
                                        }}
                                        onRefuse={(reasons: Array<string>) => {
                                            props.onRefuse && props.onRefuse(orderData.order!, reasons);
                                            handleCloseModal();
                                        }}
                                        onUnassign={() => {
                                            props.onUnassign && props.onUnassign(orderData.order!);
                                            handleCloseModal();
                                        }}
                                    />
                                </>
                            )
                        }
                    </>
                ) : (<Spinner />)
            }
        </GenericModal>
    );
};

export default ModalConsultPost;
