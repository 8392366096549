import React from 'react';
import { useAppSelector } from '../../store/hooks';
import AccountAPIs from '../../APIs/AccountAPIs';
import Tools from '../../helpers/Tools';
import {
    Grid,
    Typography,
    Divider,
} from '@mui/material';
import RouterLink from '../RouterLink/RouterLink';
import FinalAutocomplete from '../FinalComponents/FinalAutocomplete';
import FormikAutocomplete from '../FormikComponent/FormikAutocomplete';
import IThematic from '../../models/IThematic';
import IAccountThematic from '../../models/IAccountThematic';

const ThematicSelection: React.FC<{ formik?: boolean|undefined}> = ({ formik }) => {
    // Use of hooks
    const [thematics, setThematics] = React.useState<Array<IThematic>|null>(null);

    // Use of redux
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === thematics &&
            // Retrieve AccountThematic associated to the instance Account
            AccountAPIs.getAccountThematic({ 'account.id': instanceAccountId })
            // Set our hook as an array of Thematics from retrieved AccountThematic
            .then((data: Array<IAccountThematic>) =>
                // Map threw returned data to only retrieve an array of Thematics
                data && setThematics(data.map(accountThematic => accountThematic.thematic)))
    }, [thematics, instanceAccountId])

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Typography variant='h5' gutterBottom>
                        {`Choisir la thématique du site`}
                    </Typography>
                    <Typography variant='body2' gutterBottom>
                        {`Purement indiquatif, elle vous servira
                        de filtre lors de la consultation de l'ensemble de vos sites.`}
                    </Typography>
                    <Typography variant='body2' color='primary'>
                        {`Vous pouvez gérer l'ensemble de vos thématiques en `}
                        <RouterLink anchor='cliquant-ici.' to='/dashboard/account/params' />
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    {
                        formik ? (
                            <FormikAutocomplete
                                name='thematic'
                                upperlabel='Vos thématiques'
                                options={Tools.convertToSelectOptions(thematics, 'id', 'name')}
                            />
                        ) : (
                            <FinalAutocomplete
                                name='thematic'
                                upperLabel
                                label='Vos thématiques'
                                options={Tools.convertToSelectOptions(thematics, 'id', 'name')}
                            />
                        )
                    }
                </Grid>
            </Grid>
            <Divider variant='middle' sx={{ marginY: 4 }} />
        </>
    )
};

export default ThematicSelection;
