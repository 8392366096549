import React from 'react';
import OtherAPIs from '../../APIs/OtherAPIs';
import stringToIcon from '../../configs/stringToIcon';
import {
    FormControlLabel,
    Typography,
    Checkbox,
    Grid,
    SxProps,
    Theme,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import Spinner from '../Spinner/Spinner';
import IActivitySector from '../../models/IActivitySector';

const DisplayActivitySectors: React.FC<{
    selected?: Array<number>|undefined,
    onSelectionChanges: (selected: Array<number>) => void,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Use of hooks
    const [activitySectors, setActivitySectors] = React.useState<Array<IActivitySector>|null>(null);
    const [selectedActivitySectors, setSelectedActivitySectors] = React.useState<Array<number>>(props.selected ?? []);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === activitySectors &&
            // Call API to retrieve ActivitySectors
            OtherAPIs.getActivitySectors()
            // On successful API call, store data returned resources in hook
            .then((data: Array<IActivitySector>) => data && setActivitySectors(data));
    }, [activitySectors])

    // Callback used when an activity sector selection is made
    const handleActivitySectorSelection = React.useCallback((activitySectorId: number) => {
        // Either add it or remove it
        const newSelected = selectedActivitySectors.includes(activitySectorId) ?
            selectedActivitySectors.filter(asId => asId !== activitySectorId)
            : [ ...selectedActivitySectors, activitySectorId ];
        setSelectedActivitySectors(newSelected);
        props.onSelectionChanges(newSelected);
    }, [props, selectedActivitySectors])

    return (
        null !== activitySectors ? (
            <Grid container sx={props.sx}>
                {
                    activitySectors.map((activitySector: IActivitySector, index: number) => {
                        // Retrieve Icon of this ActivitySector
                        const Icon = stringToIcon(activitySector.icon);
                        // Define selected state
                        const selected = selectedActivitySectors ?
                            selectedActivitySectors.includes(activitySector.id) : undefined;

                        return (
                            <Grid key={index} item xs={12} md={6}>
                                <FormControlLabel
                                    label={
                                        <Typography
                                            paddingLeft={1}
                                            sx={{
                                                color: selected ? 'success.main' : undefined,
                                                userSelect: 'none',
                                            }}>
                                            {activitySector.name}
                                        </Typography>
                                    }
                                    control={
                                        <>
                                            {
                                                selected && (
                                                    <CheckIcon color='success' sx={{ marginRight: 1 }} />
                                                )
                                            }
                                            <Checkbox
                                                icon={<Icon />}
                                                checkedIcon={<Icon color='success' />}
                                                checked={selected}
                                                onClick={() => handleActivitySectorSelection(activitySector.id)}
                                            />
                                        </>
                                    }
                                />
                            </Grid>
                        );
                    })
                }
            </Grid>
        ) : (<Spinner />)
    );
};

export default DisplayActivitySectors;
