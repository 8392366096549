import React from 'react';
import { Routes, Route as ReactRouterRoute } from 'react-router-dom';
import Route from './Route/Route';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import Policy from './Policy/Policy';
import AskAccountValidation from './AskAccountValidation/AskAccountValidation';
import ActiveAccount from './ActiveAccount/ActiveAccount';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import ActiveInvitation from './ActiveInvitation/ActiveInvitation';
import UnsubscribeNewsletter from './UnsubscribeNewsletter/UnsubscribeNewsletter';
import Dashboard from './Dashboard/Dashboard';
import NotFound from './NotFound/NotFound';

const AppTree: React.FC = () => {
    return (
        <Routes>
            <ReactRouterRoute
                path='sign_in'
                element={<Route element={<SignIn />} logged={false} />}
            />
            <ReactRouterRoute
                path='sign_up'
                element={<Route element={<SignUp />} logged={false} />}
            />
            <ReactRouterRoute
                path='policy'
                element={<Policy />}
            />
            <ReactRouterRoute
                path='ask_account_validation'
                element={<Route element={<AskAccountValidation />} logged={false} />}
            />
            <ReactRouterRoute
                path='active_account/:uuid/:apiKey'
                element={<Route element={<ActiveAccount />} logged={false} />}
            />
            <ReactRouterRoute
                path='forgot_password'
                element={<Route element={<ForgotPassword />} logged={false} />}
            />
            <ReactRouterRoute
                path='reset_password/:uuid/:apiKey'
                element={<Route element={<ResetPassword />} logged={false} />}
            />
            <ReactRouterRoute
                path='active_invitation/:uuid'
                element={<ActiveInvitation />}
            />
            <ReactRouterRoute
                path='unsubscribe_newsletter/:uuid'
                element={<UnsubscribeNewsletter />}
            >
                <ReactRouterRoute
                    path=':apiKey'
                    element={<UnsubscribeNewsletter />}
                />
            </ReactRouterRoute>
            {/** Dashboard route use defined redux states */}
            <ReactRouterRoute
                path='dashboard/*'
                element={<Route element={<Dashboard />} logged={true}/>}
            />
            {/** All others routes */}
            <ReactRouterRoute
                path='*'
                element={<Route element={<NotFound />} />}
            />
        </Routes>
    );
};

export default AppTree;
