import React from 'react';
import { useAppSelector } from '../../store/hooks';
import HomeAPIs from '../../APIs/HomeAPIs';
import {
    useTheme,
    Typography,
} from '@mui/material';
import HomePanel from '../Home/HomePanel';
import CountdownBox from './CountdownBox';
import RouterLink from '../RouterLink/RouterLink';
import IOrder from '../../models/IOrder';

interface ICountdownsData {
    todo: IOrder|null,
    refused: IOrder|null,
    proposed: IOrder|null,
};

const PanelCountdowns: React.FC = () => {
    // Use of MUI hooks
    const theme = useTheme();

    // Use of hooks
    const [countdownsData, setCountdownsData] = React.useState<ICountdownsData|null>(null);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id!);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === countdownsData &&
            // Call API to retrieve editor countdowns data
            HomeAPIs.getEditorCountdowns({ id: instanceUserId })
                // On successful API call
                .then((data: ICountdownsData|null) => null !== data && setCountdownsData(data));
    }, [countdownsData, instanceUserId])

    return (
        <HomePanel header='il vous reste ...' sx={{ flex: 1 }}>
            <Typography variant='body2' paddingBottom={1}>
                {`Pour accepter `}
                {
                    null !== countdownsData && null !== countdownsData.proposed ? (
                        <RouterLink
                            anchor='un article'
                            to='posts/proposed'
                            color='inherit'
                        />
                    ) : (
                        'un article'
                    )
                }
            </Typography>
            <CountdownBox
                date={countdownsData?.proposed?.outdatedAt}
                color={theme.palette.primary.light}
                sx={{ marginBottom: 4 }}
            />
            <Typography variant='body2' paddingBottom={1}>
                {`Pour rédiger `}
                {
                    null !== countdownsData && null !== countdownsData.todo ? (
                        <RouterLink
                            anchor='cet article'
                            to='posts/form'
                            state={countdownsData.todo.id}
                            color='inherit'
                        />
                    ) : (
                        'un article'
                    )
                }
            </Typography>
            <CountdownBox
                date={countdownsData?.todo?.outdatedAt}
                color={theme.palette.secondary.light}
                sx={{ marginBottom: 4 }}
            />
            <Typography variant='body2' paddingBottom={1}>
                {`Pour corriger `}
                {
                    null !== countdownsData && null !== countdownsData.refused ? (
                        <RouterLink
                            anchor='un article'
                            to='posts/refused'
                            color='inherit'
                        />
                    ) : (
                        'un article'
                    )
                }
            </Typography>
            <CountdownBox
                date={countdownsData?.refused?.outdatedAt}
                color='#3ecfed'
            />
        </HomePanel>
    );
};

export default PanelCountdowns;
