import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    SxProps,
    Theme,
    Stack,
    Chip,
    Avatar,
    Tooltip,
} from '@mui/material';
import ILink from '../../interfaces/ILink';

const MandatoryLinks: React.FC<{
    links: Array<ILink>,
    onDelete: (index: number) => void,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Use of redux hooks
    const dispatch = useAppDispatch();

    // Callback triggered when link chip is deleted
    const handleChipDelete = React.useCallback((event: any, index: number) => {
        // Prevent link redirection
        event.preventDefault();
        // Call given prop delete callback
        props.onDelete(index);
    }, [props]);

    return (
        <Stack direction='row' spacing={1} sx={props.sx}>
            {
                props.links.map((link: ILink, index: number) => (
                    <Tooltip key={index} title={link.url} placement='bottom'>
                        <Chip
                            clickable
                            label={link.anchor}
                            avatar={(
                                <Avatar>{index+1}</Avatar>
                            )}
                            onClick={() => dispatch(openModal({ name: 'linkCreator', params: { link: link, position: index }}))}
                            onDelete={(event) => handleChipDelete(event, index)}
                        />
                    </Tooltip>
                ))
            }
        </Stack>
    );
};

export default MandatoryLinks;
