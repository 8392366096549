import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserAPIs from '../../APIs/UserAPIs';
import {
    Typography,
    Button,
    Stack,
    Container,
} from '@mui/material';
import Copyright from '../Copyright/Copyright';
import Spinner from '../Spinner/Spinner';
import IUser from '../../models/IUser';

const UnsubscribeNewsletter: React.FC = () => {
    // Use of hooks
    const [isUnsubscribed, setIsUnsubscribed] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    // Use react-router-dom hooks
    const navigate = useNavigate();
    const { uuid, apiKey } = useParams<any>();

    // CallBack when unsubscribe button is pressed
    const handleUnsubscribeNewsletter = React.useCallback(() => {
        if (undefined !== uuid && '' !== uuid) {
            // Show spinner during API call
            setIsLoading(true);
            // Call API to patch the user with hasNewsletter to false
            UserAPIs.patchUser(uuid, { hasNewsletter: false }, apiKey)
                // On successful API call
                // Set isUnsubscribed to true to show confirm message
                .then((data: IUser|null) => data && setIsUnsubscribed(true))
                // In any case stop loading
                .finally(() => setIsLoading(false));
        }
    }, [uuid, apiKey]);

    return (
        !isLoading ? (
            <Container component='main' maxWidth='md' sx={{ padding: 5 }}>
                {
                    !isUnsubscribed ? (
                        <>
                            <Typography component='h1' variant='h4' color='primary' fontWeight='bold' align='center' gutterBottom>
                                {`Êtes-vous sûr de vouloir vous désabonner de la newsletter de ${process.env.REACT_APP_NAME} ?`}
                            </Typography>
                            <Typography variant='body2' align='center' gutterBottom>
                                {`
                                    La newsletter de ${process.env.REACT_APP_NAME} vous permet de recevoir par mail des informations importantes 2 fois par mois.
                                    Parmis ces informations vous pouvez compter : les nouvelles fonctionnalités et mises à jour de ${process.env.REACT_APP_NAME},
                                    des articles sur le reférencement, des offres commerciales...
                                `}
                            </Typography>
                        </>
                    ) : (
                        <Typography component='h1' variant='h4' color='primary' fontWeight='bold' align='center' gutterBottom>
                            {`Vous êtes bien désinscrit de la newsletter de ${process.env.REACT_APP_NAME}.`}
                        </Typography>
                    )
                }
                <Stack alignItems='center'>
                    {
                        !isUnsubscribed && (
                            <Button variant='contained' onClick={handleUnsubscribeNewsletter} sx={{ marginY: 2 }}>
                                {`Je me désabonne`}
                            </Button>
                        )
                    }
                    <Button variant='outlined' onClick={() => navigate('/')}>
                        {`${isUnsubscribed ? 'Revenir' : 'Annuler et revenir'} à l'accueil`}
                    </Button>
                </Stack>
                <Copyright paddingTop={10} />
            </Container>
        ) : (<Spinner isFullscreen />)
    );
};

export default UnsubscribeNewsletter;
