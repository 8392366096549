import React from 'react';
import { Field } from 'react-final-form';
import {
    SxProps,
    Theme,
    Stack,
    FormControlLabel,
    Checkbox,
    CheckboxProps,
} from '@mui/material';
import FinalFieldMessage from './FinalFieldMessage';

type FinalCheckboxProps = {
    name: string, // force this prop to not be undefined
    label: string|number|React.ReactElement,
    helperText?: React.ReactNode|undefined,
    fullWidth?: boolean|undefined,
    wrapperSx?: SxProps<Theme>|undefined,
};

const FinalCheckbox: React.FC<CheckboxProps&FinalCheckboxProps> = ({ wrapperSx, helperText, ...props }) => {
    return (
        <Stack sx={{ width: props.fullWidth ? 1 : undefined, ...wrapperSx }}>
            <Field
                name={props.name}
                type='checkbox'
                subscription={{ value: true }}
                render={({ input }) => (
                    <FormControlLabel
                        disableTypography
                        label={props.label}
                        sx={{
                            margin: 0,
                            fontSize: '1.1rem',
                            ...(props.disabled && { color: 'action.disabled' }),
                        }}
                        control={
                            <Checkbox
                                {...input}
                                {...props}
                                disableRipple
                                sx={{ padding: 0, paddingRight: 1 }}
                            />
                        }
                    />
                )}
            />
            <FinalFieldMessage name={props.name} helperText={helperText} />
        </Stack>
    );
};

export default FinalCheckbox;
