import { Variant, VariantLabels, Variants } from 'framer-motion';

export const createStaggerVariant = (delay: number = 0.05): Variants => {
    return {
        visible: {
            transition: {
                staggerChildren: Number(`${delay}`),
            },
        },
        hidden: {
            transition: {
                staggerChildren: Number(`${delay}`),
            },
        },
    };
};

export const createFadeInVariant = (duration: number = 0.15): Variants => {
    return {
        visible: {
            opacity: 1,
            transition: {
                duration: Number(`${duration}`),
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: Number(`${duration}`),
            },
        },
    };
};

export const createVariableTranslateVariant = (variantsCount: number, duration: number = 1): Variants => {
    let variantsMap: Map<VariantLabels, Variant> = new Map();

    for (let i = 1; i <= variantsCount; i++) {
        variantsMap.set(String(i), {
            x: `-${(i - 1) * 100}vw`,
            transition: {
                ease: easeBundle.fourth,
                duration: Number(`${duration}`),
            }
        });
    }

    return Object.fromEntries(variantsMap);
};

export const easeBundle = {
    first: [0.65, 0, 0.35, 1],
    second: [0.83, 0, 0.17, 1],
    third: [0.85, 0, 0.15, 1],
    fourth: [0.65, 0, 0.35, 1],
    fifth: [0.68, 0, 0.32, 1],
};
