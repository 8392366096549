import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import {
    Avatar,
    Stack,
    Box,
    Typography,
} from '@mui/material';

const UserRenderer: React.FC<ICellRendererParams> = props => {
    return (
        props.value ? (
            <Stack direction='row' alignItems='center' spacing={1.5}>
                <Avatar
                    src={props.value.avatar}
                    sx={{ fontSize: '0.7rem', fontWeight: 700, width: 30, height: 30 }}
                    children={`${props.value.name[0]}${props.value.lastname[0]}`}
                />
                <Box component='span' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {`${props.value.name} ${props.value.lastname}`}
                </Box>
            </Stack>
        ) : (
            <Typography variant='caption'>
                {`Non renseigné`}
            </Typography>
        )
    );
};

export default UserRenderer;
