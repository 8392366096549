import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal, isModalOpened } from '../../store/slices/activeModalSlice';
import {
    Modal,
    Fade,
    Box,
    Typography,
    IconButton,
    Stack,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import cssStyles from './ModalStyles';

const GenericModal: React.FC<{
    name: string,
    title: string,
    width?: number|string|undefined,
    height?: number|string|undefined,
    padding?: number|string|undefined,
    disableCloseOnEscape?: boolean|undefined,
    type?: 'error'|'warning'|undefined,
    onClose?: (() => void)|undefined,
}> = props => {
    // Use of redux
    const dispatch = useAppDispatch();
    const modalIsOpened: boolean = useAppSelector(isModalOpened(props.name));

    // Callback triggered when modal is closing
    const handleModalClose = React.useCallback(() => {
        props.onClose && props.onClose();
        // Close modal using redux state
        dispatch(closeModal());
    }, [props, dispatch])

    // useEffect when component is dismounted
    React.useEffect(() => () => {
        dispatch(closeModal());
    }, [dispatch]);

    return (
        <Modal
            open={modalIsOpened}
            onClose={(_, reason) => !props.disableCloseOnEscape && 'escapeKeyDown' === reason && handleModalClose()}
        >
            <Fade in={modalIsOpened}>
                <Stack
                    width={props.width ?? 800}
                    height={props.height}
                    border={props.type ? 'solid 2px' : undefined}
                    borderColor={props.type ? `${props.type}.main` : undefined}
                    sx={cssStyles.container}
                >
                    <Box sx={{ ...cssStyles.headband, ...{ bgcolor: props.type ? `${props.type}.main` : 'primary.main' }}}>
                        <Typography component='h2' variant='h5' sx={cssStyles.headbandTitle}>
                            {props.title}
                        </Typography>
                        <IconButton onClick={handleModalClose}>
                            <CloseIcon sx={cssStyles.headbandCloseIcon} />
                        </IconButton>
                    </Box>
                    <Box id='genericModalContent' padding={props.padding ?? 3} overflow='auto' height={1}>
                        {props.children}
                    </Box>
                </Stack>
            </Fade>
        </Modal>
    );
};

export default GenericModal;
