import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { instanceIsScoped, selectInstanceAccountTypeName } from '../../store/slices/instanceSlice';
import { Typography, Stack } from '@mui/material';
import NamespacePanel from '../NamespacePanel/NamespacePanel';
import SvgProfile from '../Svg/SvgProfile';
import SvgAccountInformation from '../Svg/SvgAccountInformation';
import SvgAccountParameters from '../Svg/SvgAccountParameters';
import SvgManageSites from '../Svg/SvgManageSites';
import SvgManageScheduledPosts from '../Svg/SvgManageScheduledPosts';
import SvgManageUsers from '../Svg/SvgManageUsers';
import SvgRefillTokens from '../Svg/SvgRefillTokens';
import EAccountTypes from '../../interfaces/EAccountTypes';

const AccountMenu: React.FC = () => {
    // Use of redux
    const instanceAccountTypeSlug: string|null = useAppSelector(selectInstanceAccountTypeName(true));
    const instanceCanManageAccountInfo: boolean = useAppSelector(instanceIsScoped(['manage_account_info']));
    const instanceCanManageAccountParams: boolean = useAppSelector(instanceIsScoped(['manage_account_params']));
    const instanceCanManageAccountWebsites: boolean = useAppSelector(instanceIsScoped(['manage_account_websites']));
    const instanceCanManageAccountUsers: boolean = useAppSelector(instanceIsScoped(['manage_account_users']));

    // Function used to know if instance account type match given array
    function accountIsTyped(accountTypes: Array<string>) {
        return instanceAccountTypeSlug ?
            accountTypes.includes(instanceAccountTypeSlug)
            : false;
    };

    return (
        <>
            <Typography component='h1' variant='h4' fontWeight='bold' paddingBottom={4}>
                {`Mon compte`}
            </Typography>
            <Stack direction='row' flexWrap='wrap' justifyContent='center' gap={2}>
                <NamespacePanel
                    svg={<SvgProfile sx={{ fontSize: '6rem' }} />}
                    header='Mon profil public'
                    text='Consultation et/ou modifications de vos informations personnelles.'
                    to='user'
                />
                {
                    (instanceCanManageAccountInfo || accountIsTyped([EAccountTypes.EDITOR])) && (
                        <NamespacePanel
                            svg={<SvgAccountInformation sx={{ fontSize: '6rem' }} />}
                            header='Mes informations de compte'
                            text='Consultation et/ou modifications des informations du compte.'
                            to='company'
                        />
                    )
                }
                {
                    accountIsTyped([EAccountTypes.CORPORATION, EAccountTypes.MEMBER]) &&
                    instanceCanManageAccountParams && (
                        <NamespacePanel
                            svg={<SvgAccountParameters sx={{ fontSize: '6rem' }} />}
                            header='Paramètres du compte'
                            text="Consultation et/ou modifications des type d'articles, de vos thématiques
                            et bien d'autres !"
                            to='params'
                        />
                    )
                }
                {
                    accountIsTyped([EAccountTypes.CORPORATION, EAccountTypes.MEMBER]) &&
                    instanceCanManageAccountWebsites && (
                        <>
                            <NamespacePanel
                                svg={<SvgManageSites sx={{ fontSize: '6rem' }} />}
                                header='Gestion des sites'
                                text='Consultation, ajouts et/ou modifications des sites liés au compte.'
                                to='sites'
                            />
                            <NamespacePanel
                                svg={<SvgManageScheduledPosts sx={{ fontSize: '6rem' }} />}
                                header='Gestion des programmations'
                                text="Consultation et modifications des programmations d'articles du compte."
                                to='publication_schedule'
                            />
                        </>
                    )
                }
                {
                    accountIsTyped([EAccountTypes.CORPORATION, EAccountTypes.MEMBER]) &&
                    instanceCanManageAccountUsers && (
                        <NamespacePanel
                            svg={<SvgManageUsers sx={{ fontSize: '6rem' }} />}
                            header='Gestion des utilisateurs'
                            text={`Consultation invitation et/ou gestion des droits des collaborateurs
                            liés à votre compte.`}
                            to='#'
                        />
                    )
                }
                {
                    accountIsTyped([EAccountTypes.CORPORATION, EAccountTypes.EDITOR]) && (
                        <NamespacePanel
                            svg={<SvgRefillTokens sx={{ fontSize: '6rem' }} />}
                            header='Recharger mes jetons'
                            text={`Recharger mes jetons de génération de contenu.`}
                            to='tokens'
                        />
                    )
                }
            </Stack>
        </>
    );
};

export default AccountMenu;
