import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { IInstanceAccountState } from '../../store/slices/instanceSlice';
import ProjectAPIs from '../../APIs/ProjectAPIs';
import {
    Typography,
    Tooltip,
    IconButton,
    Button,
    Stack,
    Paper,
} from '@mui/material';
import {
    ModeEditOutlineOutlined as ModeEditOutlineOutlinedIcon,
    Delete as DeleteIcon,
    Bookmark as BookmarkIcon,
    BookmarkRemove as BookmarkRemoveIcon,
    Numbers as NumbersIcon
} from '@mui/icons-material';
import { ImEye } from 'react-icons/im';
import ModalConfirmation from '../Modal/ModalConfirmation';
import Spinner from '../Spinner/Spinner';
import EAccountTypes from '../../interfaces/EAccountTypes';
import IProject from '../../models/IProject';
import cssStyles from './ProjectsListingStyles';

const ProjectsListing: React.FC = () => {
    // Use of hooks
    const [projects, setProjects] = React.useState<Array<IProject>|null>(null);
    const isMounted = React.useRef<boolean>(true);

    // Use of react-router-dom hooks
    const navigate = useNavigate();

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);
    const instanceAccount: IInstanceAccountState = useAppSelector(state => state.instance.account!);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === projects &&
            // Get Project by user if account type is not corporation or by account if account type is corporation
            ProjectAPIs.getProject({
                user: EAccountTypes.CORPORATION === instanceAccount.accountType.slug ? undefined : instanceUserId,
                account: EAccountTypes.CORPORATION === instanceAccount.accountType.slug ? instanceAccount.id : undefined,
                ordersCount: true,
                spentBudget: true,
            })
                // On successful API call
                .then((data: Array<IProject>|null) =>
                    true === isMounted.current && null !== data &&
                        setProjects(data)
                );
    }, [projects, instanceUserId, instanceAccount]);

    // useEffect when component is unmouting
    React.useEffect(() => () => {
        // Set ref to false to prevent state changes
        isMounted.current = false;
    }, []);

    // Callback used to delete a Project
    const handleDeleteProject = React.useCallback((projectId: number) => {
        if (null !== projects) {
            // Call API to delete Project resource
            ProjectAPIs.deleteProject(projectId)
                // On successful API call, remove this Project from our hook value
                .then(() => setProjects(projects.filter(project => project.id !== projectId)));
        }
    }, [projects])

    return (
        null !== projects ? (
            <>
                <Typography variant='h5' gutterBottom>
                    {`Liste de tous vos projets :`}
                </Typography>
                <Typography>
                    {`Budget global annuel : ${projects.reduce((acc: number, project: IProject) => acc + Number(project.annualBudget ?? 0), 0)} €`}
                </Typography>
                <Typography>
                    {`Budget global dépensé : ${projects.reduce((acc: number, project: IProject) => acc + Number(project.spentBudget ?? 0), 0)} €`}
                </Typography>
                {
                    0 === projects.length ? (
                        <Typography variant='h6' align='center' color='textSecondary'>
                            {`Vous n'êtes lié à aucun projet pour le moment ...`}
                        </Typography>
                    ) : (
                        <>
                            <ModalConfirmation
                                title='Confirmation de la suppression'
                                message={(
                                    <>
                                        <Typography align='center'>
                                            {`La suppression du projet engendrera la suppresion de toutes les commandes et articles
                                            qui étaient liées à ce projet. Les articles déjà publiés ne seront en aucun cas dépubliés mais
                                            vous perdrez l'historique et la consultation des ces derniers.`}
                                        </Typography>
                                        <Typography align='center' fontWeight='bold' paddingY={4}>
                                            {`Êtes-vous sûr de vouloir supprimer le projet ?`}
                                        </Typography>
                                    </>
                                )}
                                width={2/5}
                                onValidation={(modalParams: any) => handleDeleteProject(modalParams.projectId)}
                            />
                            <Stack marginY={4} gap={1}>
                                {
                                    projects.map((project: IProject, index: number) => (
                                        <Paper key={index}>
                                            <Stack direction='row' paddingX={5} paddingY={1}>
                                                <Stack width={1.5/3}>
                                                    <Typography variant='h6'>
                                                        {project.name}
                                                    </Typography>
                                                    <Typography variant='body2' color='textSecondary'>
                                                        {project.moneySite ?? 'Pas de money site associé'}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction='row' gap={3}>
                                                    <Tooltip title='Budget annuel alloué' followCursor>
                                                        <Typography sx={cssStyles.flexTypography}>
                                                            <BookmarkIcon />
                                                            {`${project.annualBudget ?? 0} €`}
                                                        </Typography>
                                                    </Tooltip>
                                                    <Tooltip title='Budget actuel dépensé' followCursor>
                                                        <Typography sx={cssStyles.flexTypography}>
                                                            <BookmarkRemoveIcon />
                                                            {`${project.spentBudget ?? 0} €`}
                                                        </Typography>
                                                    </Tooltip>
                                                    <Tooltip title="Nombre d'articles commandés" followCursor>
                                                        <Typography sx={cssStyles.flexTypography}>
                                                            <NumbersIcon />
                                                            {project.ordersCount}
                                                        </Typography>
                                                    </Tooltip>
                                                </Stack>
                                                <Stack direction='row' alignItems='center' marginLeft='auto'>
                                                    {/* Information action */}
                                                    <Tooltip title='Voir les articles publiés'>
                                                        <IconButton onClick={() => navigate('information', { state: project.id })}>
                                                            <ImEye />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {/* Update action */}
                                                    <Tooltip title='Modifier'>
                                                        <IconButton onClick={() => navigate('form', { state: project.id })}>
                                                            <ModeEditOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {/* Delete action */}
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton onClick={() => dispatch(openModal({ name: 'confirmModal', params: { projectId: project.id } }))}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </Stack>
                                        </Paper>
                                    ))
                                }
                            </Stack>
                        </>
                    )
                }
                <Button
                    variant='contained'
                    onClick={() => navigate('form')}
                >
                    {`Créer un nouveau projet`}
                </Button>
            </>
        ) : (<Spinner />)
    );
};

export default ProjectsListing;
