import React from 'react';
import { Style } from '@react-pdf/types';
import { Text } from '@react-pdf/renderer';

const Title1: React.FC<{ text: string, style?: Style|undefined }> = props => {
    return (
        <Text style={{ fontWeight: 700, fontSize: 15, paddingVertical: 5, ...props.style }}>
            {props.text}
        </Text>
    );
};

export default Title1;
