import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { instanceIsScoped } from '../../store/slices/instanceSlice';
import ProjectAPIs from '../../APIs/ProjectAPIs';
import Tools from '../../helpers/Tools';
import { SxProps, Theme } from '@mui/material';
import FinalAutocomplete from '../FinalComponents/FinalAutocomplete';
import ISelectOption from '../../interfaces/ISelectOption';
import IProject from '../../models/IProject';

const SelectProject: React.FC<{
    name: string,
    onChange?: ((option: ISelectOption|null) => void)|undefined,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Use of hooks
    const [projects, setProjects] = React.useState<Array<IProject>|null>(null);
    const mountedRef = React.useRef<boolean>(true);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);
    const isUserAccountOwner: boolean = useAppSelector(instanceIsScoped(['account_owner']));

    // useEffect when component is mounting
    React.useEffect(() => {
        null === projects &&
            // Call API to fetch projects of the instance user
            ProjectAPIs.getProject(isUserAccountOwner ? { account: instanceAccountId } : { user: instanceUserId })
                // On successful API call
                .then((projects: Array<IProject>|null) =>
                    // Do not update hook if component is unmounted or null value returned
                    true === mountedRef.current && null !== projects && setProjects(projects)
                );
    }, [projects, instanceUserId, instanceAccountId, isUserAccountOwner]);

    // useEffect when component is unmounting, set mountedRef to false
    React.useEffect(() => () => {
        mountedRef.current = false;
    }, []);

    return (
        <FinalAutocomplete
            name={props.name}
            size='small'
            label='Projet sur lequel commander'
            upperLabel
            fullWidth
            loading={null === projects}
            options={Tools.convertToSelectOptions(projects, 'id', 'name')}
            onChange={props.onChange}
            wrapperSx={props.sx}
        />
    );
};

export default SelectProject;
