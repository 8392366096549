import React from 'react';
import moment from 'moment-timezone';
import useValidationSchema from '../../hooks/useValidationSchema';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import GenericModal from './GenericModal';
import FinalForm from '../FinalForm/FinalForm';
import FinalInput from '../FinalComponents/FinalInput';
import FinalDatePicker from '../FinalComponents/FinalDatePicker';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import IOrder from '../../models/IOrder';

const ModalExternalPublication: React.FC<{
    title?: string|undefined,
    name?: string|undefined,
    onValidation: (order: IOrder, postUrl: string, publishedAt: string) => void,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'externalPublicationModal');

    // Use of redux
    const dispatch = useAppDispatch();
    const params: IOrder = useAppSelector(state => state.activeModal.params);

    // Define validation schema of form
    const validationSchema = useValidationSchema({
        postUrl: Yup
            .string()
            .required("Veuillez renseigner l'URL de l'article"),
        publishedAt: Yup
            .string()
            .required("Veuillez renseigner la date de publication de l'article"),
    });

    // Function used to submit form
    const onSubmit = async (values: any) => {
        // Call given callback
        props.onValidation(params, values.postUrl, values.publishedAt);
        // Close modal
        dispatch(closeModal());
    };

    return (
        <GenericModal
            name={modalName.current}
            title={props.title ?? 'Renseigner une publication externe'}
            width={1/3}
        >
            <FinalForm
                initialValues={{ postUrl: '', publishedAt: '' }}
                validateSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <FinalInput
                    name='postUrl'
                    upperLabel
                    label="URL de l'article"
                    size='small'
                    fullWidth
                    placeholder='https:// ...'
                    wrapperSx={{ paddingBottom: 3 }}
                />
                <FinalDatePicker
                    name='publishedAt'
                    upperLabel
                    label="Date de publication de l'article"
                    size='small'
                    fullWidth
                    maxDateTime={moment().toDate()}
                    wrapperSx={{ paddingBottom: 3 }}
                />
                <FinalSubmitButton
                    variant='contained'
                    children='Valider'
                />
            </FinalForm>
        </GenericModal>
    );
};

export default ModalExternalPublication;
