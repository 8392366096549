import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IWallet from '../../models/IWallet';

export interface IInstanceWalletState {
    loaded: boolean,
    locked: number,
    unlocked: number,
    tokens: number,
};

const initialState: IInstanceWalletState = {
    loaded: false,
    locked: 0,
    unlocked: 0,
    tokens: 0,
};

export const slice = createSlice({
    name: 'instanceWalletState',
    initialState,
    reducers: {
        setInstanceWalletState: (state: IInstanceWalletState, action: PayloadAction<IWallet>) => {
            state.loaded = true;
            state.locked = action.payload.locked;
            state.unlocked = action.payload.unlocked;
            state.tokens = action.payload.tokens;
        },
        clearInstanceWalletState: (_: IInstanceWalletState) => {
            // Return a new value and force createSlice to replace the old one.
            return initialState;
        },
    },
});

export const { setInstanceWalletState, clearInstanceWalletState } = slice.actions;

export default slice.reducer;
