import React from 'react';
import moment from 'moment-timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import OrderAPIs from '../../APIs/OrderAPIs';
import { CircularProgress, Divider, Paper, Stack, Typography } from '@mui/material';
import {
    Abc as AbcIcon,
    Add as AddIcon,
    Check as CheckIcon,
    Edit as EditIcon,
    Euro as EuroIcon,
    Percent as PercentIcon,
    Publish as PublishIcon,
} from '@mui/icons-material';
import { AiOutlineForm } from 'react-icons/ai';
import { ImEye } from 'react-icons/im';
import { MdArticle, MdOutlineUnpublished } from 'react-icons/md';
import ListingRowData from '../ListingRowData/ListingRowData';
import ModalConfirmation from '../Modal/ModalConfirmation';
import ModalConsultPost from '../Modal/ModalConsultPost/ModalConsultPost';
import PostActionButton from '../PostActionButton/PostActionButton';
import SiteScheduleKeywordsListing from './SiteScheduleKeywordsListing';
import IOrder from '../../models/IOrder';
import ISiteSchedule from '../../models/ISiteSchedule';

const ScheduledPosts: React.FC<{}> = props => {
    // Use of hooks
    const [orders, setOrders] = React.useState<Array<IOrder>|null>(null);
    const [loadingOrders, setLoadingOrders] = React.useState<Array<number>>([]);

    // Use of redux hooks
    const dispatch = useAppDispatch();

    // Use of react-router-dom hook
    const navigate = useNavigate();
    const location = useLocation();
    const siteSchedule = location.state as ISiteSchedule;

    // useEffect to fetch orders on component mount
    React.useEffect(() => {
        null === orders && OrderAPIs.getOrder({ siteSchedule: siteSchedule.id, toPublishAt: 'desc' })
            .then(data => null !== data && setOrders(data));
    }, [orders, siteSchedule])

    // Callback to handle deletion of a DraftPost
    const handlePublicationState = ({ orderId, publish }: { orderId: number, publish: boolean }) => {
        setLoadingOrders(current => [...current, orderId]);
        OrderAPIs.patchMultipleOrders({ orders: [orderId], data: { publish }})
            // On successful API call, refetch Orders
            .then((data) => {
                setOrders(order => order!.map(o => o.id === orderId ? data[0] : o ));
                setLoadingOrders(current => current.filter(o => o !== orderId));
            });
    };

    return (
        <Stack gap={2}>
            <SiteScheduleKeywordsListing siteScheduleId={siteSchedule.id} />
            <Divider />
            {
                null !== orders ? (
                    <>
                        <ModalConfirmation
                            name='confirmPublicationState'
                            title='Êtes-vous sûr ?'
                            message='Veuillez confirmer le changement de statut de publication'
                            onValidation={handlePublicationState}
                        />
                        <ModalConsultPost title="Consultation de l'article" />
                        <Stack gap={1}>
                            <Typography variant='h5' gutterBottom>{`Articles programmés sur le site ${siteSchedule.site.domain} :`}</Typography>
                            {orders.map((order, index) => (
                                <Paper key={index} style={loadingOrders.includes(order.id) ? { opacity: 0.3, pointerEvents: 'none'} : undefined}>
                                    <Stack direction='row' alignItems='center' justifyContent='space-between' paddingX={5} paddingY={1} gap={3}>
                                        <Stack alignItems='flex-start' width={1/3}>
                                            <ListingRowData
                                                icon={AbcIcon}
                                                variant='h6'
                                                title={order.keyword.name}
                                                tooltip='Mot clé'
                                            />
                                            <ListingRowData
                                                icon={AbcIcon}
                                                tooltip='Titre'
                                                title={order.post!.title}
                                            />
                                        </Stack>
                                        <Stack direction='row' gap={3}>
                                            <ListingRowData
                                                icon={MdArticle}
                                                tooltip='Nombre de mots'
                                                title={order.post!.nbrWords}
                                            />
                                            <ListingRowData
                                                icon={PercentIcon}
                                                tooltip="Score d'optimisation"
                                                title={order.post!.optimization}
                                            />
                                            <ListingRowData
                                                icon={EuroIcon}
                                                tooltip='Prix de la génération'
                                                title={Number(order.post!.generationPrice).toFixed(2)}
                                            />
                                        </Stack>
                                        <Stack alignItems='flex-start'>
                                            <ListingRowData
                                                icon={AddIcon}
                                                tooltip='Date de publication prévue'
                                                title={moment(order.toPublishAt).format('DD/MM/YYYY HH:00')}
                                            />
                                            <ListingRowData
                                                icon={CheckIcon}
                                                tooltip='Date de publication effective'
                                                title={order.post?.publishedAt ? moment(order.post.publishedAt).format('DD/MM/YYYY HH:00') : 'Non publié'}
                                            />
                                        </Stack>
                                        <Stack direction='row' gap={1}>
                                            {
                                                order.post?.publishedAt ? (
                                                    <>
                                                        <PostActionButton
                                                            icon={MdOutlineUnpublished}
                                                            tooltip="Dépublier l'article"
                                                            onClick={() => dispatch(openModal({ name: 'confirmPublicationState', params: { orderId: order.id, publish: false } }))}
                                                        />
                                                        <PostActionButton
                                                            icon={ImEye}
                                                            tooltip="Voir l'article"
                                                            onClick={() => window.open(order.post!.wpPostLink!, '_blank')}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <PostActionButton
                                                            icon={PublishIcon}
                                                            tooltip="Publier l'article"
                                                            onClick={() => dispatch(openModal({ name: 'confirmPublicationState', params: { orderId: order.id, publish: true } }))}
                                                        />
                                                        <PostActionButton
                                                            icon={EditIcon}
                                                            tooltip="Modifier rapidement l'article"
                                                            onClick={() => dispatch(openModal({ name: 'consultPostModal', params: { orderId: order.id } }))}
                                                        />
                                                        <PostActionButton
                                                            icon={AiOutlineForm}
                                                            tooltip="Modifier l'article depuis le formulaire"
                                                            onClick={() => navigate('/dashboard/posts/form', { state: order.id })}
                                                        />
                                                    </>
                                                )
                                            }
                                        </Stack>
                                    </Stack>
                                </Paper>
                            ))}
                        </Stack>
                    </>
                ) : (
                    <CircularProgress size={20} />
                )
            }
        </Stack>
    );
};

export default ScheduledPosts;
