import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    actionButton: {
        justifyContent: 'left',
        minWidth: 0,
    },
    actionButtonText: {
        lineHeight: 'unset',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    actionMenuButton: {
        maxWidth: '50px',
    }
};

export default cssStyles;
