import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    editorBox: {
        width: 1,
        border: '1px solid',
        borderColor: 'action.disabled',
        borderRadius: 1,
        overflow: 'hidden',
        '&:hover': {
            borderColor: 'text.primary',
        },
        '&:focus-within': {
            borderColor: 'primary.main',
        },
        '.ProseMirror': {
            width: 1,
            height: 1,
            padding: 2,
            whiteSpace: 'pre-wrap' || 'break-spaces',
            '&:after': {
                content: '""',
                display: 'block',
                height: '32px',
                width: 1,
            },
            'p.is-editor-empty:first-of-type:before': {
                content: 'attr(data-placeholder)',
                float: 'left',
                pointerEvents: 'none',
                height: '0px',
                color: '#777b7e',
            },
            'h1': {
                fontSize: '2.2rem',
            },
            'strong': {
                fontWeight: 'bold',
            },
            'ul, ol': {
                paddingLeft: '1rem',
            },
            'a': {
                color: 'link',
                textDecoration: 'underline',
            },
        },
    },
    headingToolbarButton: {
        height: '43.43px',
        width: '43.43px',
    },
    headingToolbarButtonText: {
        fontSize: {
            xs: '1.3rem',
        },
    },
    generationLengthButton: {
        paddingX: '15px',
        height: '25px',
        fontWeight: 700,
        borderRadius: 2,
        border: '1px solid',
        transition: '.2s ease-in-out',
    },
    generationLengthButtonSelected: {
        color: 'white',
        borderColor: 'primary.main',
        backgroundColor: 'primary.main',
    },
    generateButton: {
        height: '30px',
    },
};

export default cssStyles;
