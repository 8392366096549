import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflow: 'hidden',
        maxHeight: '98vh',
    },
    headband: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 1,
        paddingX: 2,
        paddingY: 1,
        bgcolor: 'primary.main',
    },
    headbandTitle: {
        color: 'white',
        fontWeight: 700,
    },
    headbandCloseIcon: {
        color: 'white',
    },
    searchInput: {
        minWidth: 300,
    },
    imageListItem: {
        overflow: 'hidden',
        boxSizing: 'border-box',
        cursor: 'pointer',
        objectFit: 'cover',
        border: '2px solid transparent',
        transition: 'border .3s',
        '&:hover': {
            borderColor: 'primary.main',
        },
    },
    imageListItemSelected: {
        borderColor: 'primary.main',
    },
    imageListItemBar: {
        textAlign: 'center',
        fontWeight: 700,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
};

export default cssStyles;
