import React from 'react';
import { SxProps, SvgIcon, Theme } from '@mui/material';

const SvgPublishedDraftPosts: React.FC<{
    sx?: SxProps<Theme>|undefined,
    color?: 'primary'|'secondary'|undefined,
    viewBox?: string|undefined,
}> = props => {
    return (
        <SvgIcon
            viewBox={props.viewBox ?? '0 0 512 512'}
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle fill='#54C0EB' cx="230.4" cy="236.931" r="230.4" />
            <g>
                <path fill='#84DBFF' d="M460.8,235.868c-4.267,6.4-9.6,12.8-13.867,20.267c-17.067,26.667-24.533,58.667-35.2,88.533c-3.2,8.533-9.6,22.4-17.067,17.067c-22.4-17.067-1.067-60.8-17.067-85.333c-5.333-8.533-12.8-17.067-22.4-22.4c-19.2-8.533-41.6,0-60.8-6.4c-17.067-5.333-28.8-23.467-29.867-41.6c-1.067-18.133,8.533-36.267,22.4-48c12.8-10.667,30.933-17.067,46.933-11.733c6.4,2.133,11.733,5.333,18.133,7.467c16,4.267,32-2.133,46.933-8.533c6.4-3.2,13.867-8.533,12.8-14.933c-1.067-5.333-7.467-7.467-11.733-7.467c-9.6-1.067-19.2,2.133-29.867,3.2c-9.6,1.067-21.333,0-27.733-6.4c-4.267-4.267-8.533-10.667-13.867-8.533c-2.133,1.067-4.267,3.2-5.333,5.333c-8.533,9.6-23.467,8.533-36.267,7.467c-2.133,0-4.267-1.067-5.333-2.133c-3.2-3.2,0-8.533,3.2-11.733c8.533-8.533,17.067-17.067,22.4-28.8l0,0c7.467-14.933-3.2-33.067-20.267-34.133c-9.6-2.133-19.2-4.267-25.6-10.667c-7.467-7.467-10.667-18.133-8.533-28.8C369.067,18.268,459.733,116.401,460.8,235.868z" />
                <path fill='#84DBFF' d="M180.267,73.734c-8.533,18.133-28.8,27.733-43.733,41.6c-9.6,9.6-17.067,22.4-23.467,35.2l-2.133,3.2c-7.467,14.934-24.534,20.267-40.534,14.934c-3.2-1.067-5.333-1.067-8.533-1.067c-12.8,0-25.6,9.6-27.733,21.333c0,3.2,0,6.4,1.067,8.533c2.132,5.334,8.532,8.534,14.932,8.534l0,0c10.667,0,20.267,6.4,23.467,16l0,0c4.267,12.8-10.667,22.4-21.333,14.933c-13.867-10.667-23.467-25.6-29.867-41.6c-5.333-13.867-7.467-27.733-7.467-42.667l0,0C41.6,85.468,100.267,32.134,172.8,14.001c2.133,5.333,4.267,11.733,6.4,17.067C183.467,44.934,185.6,60.934,180.267,73.734z" />
                <path fill='#84DBFF' d="M243.2,312.668c-3.2,5.333-8.533,9.6-13.867,14.933c-24.533,27.733-22.4,74.667-51.2,97.067c-5.333,4.267-11.733,7.467-12.8,13.867c-1.067,3.2,0,5.333,0,8.533c1.067,4.267,1.067,8.533,2.133,11.733c-9.6-2.133-18.133-5.333-26.667-9.6c-5.333-9.6-11.733-19.2-13.867-28.8c-3.2-14.933,2.133-30.933-1.067-44.8c-6.4-25.6-36.267-39.467-45.867-64c-5.333-16-1.067-34.133,6.4-50.133c4.267-9.6,11.733-19.2,21.333-22.4c7.467-2.133,16-1.067,23.467,1.067c30.935,10.666,53.335,42.666,86.402,45.866c9.6,1.067,22.4,0,27.733,8.533C248.533,299.868,246.4,307.334,243.2,312.668z" />
            </g>
            <path fill='#40596B' d="M484.267,335.068c0-4.267,0-8.533-1.067-12.8c9.6-6.4,19.2-12.8,28.8-19.2c-3.2-13.867-7.467-26.667-12.8-39.467c-11.733,0-23.467,1.067-35.2,1.067c-4.267-7.467-9.6-14.933-14.933-21.333c4.267-10.667,7.467-21.333,11.733-33.067c-10.667-9.6-22.4-17.067-34.133-24.533c-9.6,7.467-18.133,14.933-27.733,21.333c-7.467-3.2-16-6.4-24.533-8.533c-3.2-10.667-6.4-22.4-9.6-33.067c-13.867-1.067-27.733-1.067-41.6,0c-3.2,11.733-6.4,22.4-9.6,33.067c-8.533,2.133-17.067,4.267-24.533,8.533c-8.533-7.467-18.133-13.867-27.733-21.333c-11.733,7.467-23.467,16-34.133,24.533c4.267,10.667,8.533,21.333,11.733,33.067c-5.333,6.4-10.667,13.867-14.933,21.333c-11.733,0-23.467-1.067-35.2-1.067c-5.333,12.8-9.6,26.667-12.8,39.467c9.6,6.4,19.2,12.8,28.8,19.2c0,4.267-1.067,8.533-1.067,12.8s0,8.533,1.067,12.8c-9.6,6.4-19.2,12.8-28.8,19.2c3.2,13.867,7.467,26.667,12.8,39.467c11.733,0,23.467-1.067,35.2-1.067c4.267,7.467,9.6,14.933,14.933,21.333c-4.267,10.667-7.467,21.333-11.733,33.067c10.667,9.6,22.4,17.067,34.133,24.533c9.6-7.467,18.133-14.933,27.733-21.333c7.467,3.2,16,6.4,24.533,8.533c3.2,10.667,6.4,22.4,9.6,33.067c13.867,1.067,27.733,1.067,41.6,0c3.2-11.733,6.4-22.4,9.6-33.067c8.533-2.133,17.067-4.267,24.533-8.533c8.533,7.467,18.133,13.867,27.733,21.333c11.733-7.467,23.467-16,34.133-24.533c-4.267-10.667-8.533-21.333-11.733-33.067c5.333-6.4,10.667-13.867,14.933-21.333c11.733,0,23.467,1.067,35.2,1.067c5.333-12.8,9.6-26.667,12.8-39.467c-9.6-6.4-19.2-12.8-28.8-19.2C483.2,344.668,484.267,340.401,484.267,335.068z M344.533,407.601c-40.533,0-72.533-32-72.533-72.533s32-72.533,72.533-72.533s72.533,32,72.533,72.533S384,407.601,344.533,407.601z" />
            <path fill='#F8B64C' d="M416,263.601L416,263.601l-26.667,26.667l0,0c25.6,25.6,25.6,66.133,0,91.733s-66.133,25.6-91.733,0l0,0l-26.667,26.667l0,0c39.467,39.467,104.533,39.467,144,0C456.533,368.134,456.533,303.068,416,263.601z" />
            <path fill='#FFD15C' d="M298.667,290.268c25.6-25.6,66.133-25.6,91.733,0l26.667-26.667c-39.467-39.467-104.533-39.467-144,0s-39.467,103.467,0,144l26.667-26.667C273.067,356.401,273.067,314.801,298.667,290.268z" />
        </SvgIcon>
    );
};

export default SvgPublishedDraftPosts;
