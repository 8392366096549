import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgAccountParameters: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#ACB3BA' d='M308.933,205.6c-18.133-18.133-42.667-24.533-66.133-20.267l35.2,35.2L264.133,263.2l-42.667,13.867l-35.2-35.2c-5.333,24.533,1.067,49.067,19.2,67.2c20.267,20.267,49.067,25.6,73.6,18.133l45.867,45.867L370.8,327.2l-45.867-45.867C334.533,254.667,328.133,225.867,308.933,205.6z' />
            <path fill='#84DBFF' d='M464.667,255.733c0-6.4,0-12.8-1.067-20.267c13.867-9.6,28.8-19.2,43.733-28.8c-5.333-20.267-11.733-40.533-19.2-59.733c-17.067,1.067-35.2,1.067-52.267,2.133c-6.4-11.733-14.933-22.4-23.467-32c6.4-16,11.733-33.067,18.133-49.067c-16-13.867-33.067-25.6-51.2-37.333C365.467,41.333,351.6,52,338.8,62.667c-11.733-5.333-24.533-9.6-37.333-11.733C297.2,33.867,291.867,17.867,287.6,0.8c-21.333-1.067-42.667-1.067-64,0c-4.267,17.067-9.6,34.133-13.867,50.133c-12.8,3.2-25.6,7.467-37.333,11.733c-13.867-10.667-27.733-21.333-40.533-32c-18.133,10.667-35.2,23.467-51.2,37.333C86,85.067,92.4,101.067,98.8,117.067c-8.533,9.6-16,20.267-23.467,32C58.267,148,40.133,148,23.067,146.933c-8.533,19.2-14.933,39.467-19.2,59.733c14.933,9.6,28.8,19.2,43.733,28.8c-1.067,6.4-1.067,12.8-1.067,20.267c0,6.4,0,12.8,1.067,19.2c-13.867,9.6-28.8,19.2-43.733,28.8C9.2,324,15.6,344.267,23.067,363.467c17.067-1.067,35.2-1.067,52.267-2.133c6.4,11.733,14.933,22.4,23.467,32c-6.4,16-11.733,33.067-18.133,49.067c16,13.867,33.067,25.6,51.2,37.333c13.867-10.667,27.733-21.333,40.533-32c11.733,5.333,24.533,9.6,37.333,11.733c4.267,17.067,9.6,33.067,13.867,50.133c21.333,2.133,41.6,2.133,62.933,0c4.267-17.067,9.6-34.133,13.867-50.133c12.8-3.2,25.6-7.467,37.333-11.733c13.867,10.667,27.733,21.333,40.533,32c18.133-10.667,35.2-23.467,51.2-37.333c-6.4-16-11.733-33.067-18.133-49.067c8.533-9.6,16-20.267,23.467-32c17.067,1.067,35.2,1.067,52.267,2.133c8.533-19.2,14.933-39.467,19.2-59.733c-14.933-9.6-28.8-19.2-43.733-28.8C464.667,268.533,464.667,262.133,464.667,255.733z M323.867,324c-37.333,38.4-99.2,38.4-137.6,0c-38.4-37.333-38.4-99.2,0-137.6c37.333-38.4,99.2-38.4,137.6,0c19.2,19.2,28.8,43.733,28.8,68.267S343.067,305.867,323.867,324z' />
            <path fill='#F2F2F2' d='M363.333,148c-59.733-59.733-156.8-59.733-216.533,0s-59.733,156.8,0,216.533c29.867,29.867,69.333,44.8,107.733,44.8c39.467,0,77.867-14.933,107.733-44.8C423.067,303.733,423.067,207.733,363.333,148zM323.867,324c-37.333,38.4-99.2,38.4-137.6,0c-38.4-37.333-38.4-99.2,0-137.6c37.333-38.4,99.2-38.4,137.6,0c19.2,19.2,28.8,43.733,28.8,68.267S343.067,305.867,323.867,324z' />
        </SvgIcon>
    );
};

export default SvgAccountParameters;
