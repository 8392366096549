import React from 'react';
import moment from 'moment-timezone';
import frLocale from 'date-fns/locale/fr';
import {
    LocalizationProvider,
    DateTimePicker as MuiDateTimePicker,
    DateTimePickerProps
} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { FormLabel, Stack } from '@mui/material';

const DateTimePicker: React.FC<{ upperLabel?: React.ReactNode|undefined } & DateTimePickerProps<Date>> = ({ upperLabel, label, onChange, ...props }) => (
    <Stack>
        {
            upperLabel && (
                <FormLabel>
                    {upperLabel}
                </FormLabel>
            )
        }
        <LocalizationProvider dateAdapter={DateAdapter} locale={frLocale}>
            <MuiDateTimePicker
                {...props}
                label={!upperLabel ? label : undefined}
                onChange={(date: Date|null) => {
                    if (moment(date).isValid() || null === date) {
                        // Fix min. & sec. to 0 in specific views
                        if (null !== date && undefined !== props.views && props.views.includes('hours')) {
                            date = moment(date).toDate();
                            // Set minutes and seconds to 0
                            date.setHours(date.getHours(), 0, 0, 0);
                        }
                        onChange(date);
                    }
                }}
            />
        </LocalizationProvider>
    </Stack>
);

export default DateTimePicker;
