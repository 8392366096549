import React from 'react';
import { useAppSelector } from '../../store/hooks';
import PostAPIs from '../../APIs/PostAPIs';
import { Typography, Stack } from '@mui/material';
import FailedPost from './FailedPost';
import Spinner from '../Spinner/Spinner';
import ModalViewRejectedPost from '../Modal/ModalViewRejectedPost';
import IRejectedPost from '../../models/IRejectedPost';

const PostsFailedListing: React.FC = () => {
    // Use of hooks
    const [rejectedPosts, setRejectedPosts] = React.useState<Array<IRejectedPost>|null>(null);
    const isMounted = React.useRef<boolean>(true);

    // Use of redux
    const instanceUserId = useAppSelector(state => state.instance.user!.id);

    // useEffect when component is mounting to load DraftPosts
    React.useEffect(() => {
        null === rejectedPosts &&
            // Get DraftPosts of current user
            PostAPIs.getRejectedPost({ user: instanceUserId })
                // API call successful
                .then(response => true === isMounted.current && null !== response && setRejectedPosts(response.data))
    }, [rejectedPosts, instanceUserId]);

    // useEffect when component is unmounting
    React.useEffect(() => () => {
        // Set isMounted ref to false to prevent state changes on current requests
        isMounted.current = false;
    }, []);

    return (
        <>
            <ModalViewRejectedPost title='Informations de la mission échouée' name='viewRejectedPostModal' />
            <Typography variant='h5' paddingBottom={3} color='primary.main'>
                {`Mes missions échouées :`}
            </Typography>
            {
                null !== rejectedPosts ? (
                    0 < rejectedPosts.length ? (
                        <Stack spacing={3}>
                            {
                                rejectedPosts.map((draftPost, index) => (
                                    <FailedPost key={index} rejectedPost={draftPost} />
                                ))
                            }
                        </Stack>
                    ) : (
                        <Typography variant='body2' color='textSecondary' align='center'>
                            {`Aucune mission échouée pour le moment ...`}
                        </Typography>
                    )
                ) : (<Spinner />)
            }
        </>
    );
};

export default PostsFailedListing;
