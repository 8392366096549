import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    Typography,
    Stack,
    Button,
} from '@mui/material';
import DisplayCard from '../DisplayCard/DisplayCard';
import ModalSelectAccountUser from '../Modal/ModalSelectAccountUser';
import IUser from '../../models/IUser';

const MembersManagement: React.FC<{
    initialMembers: Array<IUser>,
    onChange: (members: Array<IUser>) => void
}> = props => {
    // Use of hook
    const [members, setMembers] = React.useState<Array<IUser>>(props.initialMembers);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceUserId = useAppSelector(state => state.instance.user!.id);

    // useEffect each time our hook value changes
    React.useEffect(() => {
        // Call given prop callback
        props.onChange(members);
    }, [props, members])

    return (
        <>
            <ModalSelectAccountUser
                modalName='selectUsersMembersModal'
                selectUsers={members.map(member => member.id)}
                excludeUsers={[instanceUserId]}
                scopes={['account_owner', 'can_make_order']}
                onValidation={(_, users) => setMembers(users)}
            />
            {
                0 === members.length ? (
                    <Typography variant='body2'>
                        {`Le projet ne possède aucun membre ...`}
                    </Typography>
                ) : (
                    <Stack direction='row' flexWrap='wrap' gap={1}>
                        {
                            members.map((member: IUser, index: number) => (
                                <DisplayCard
                                    key={index}
                                    width={250}
                                    image={member.avatar ?? undefined}
                                    title={`${member.name} ${member.lastname}`}
                                    onDelete={() => setMembers(members.filter(hookMember => hookMember.id !== member.id))}
                                />
                            ))
                        }
                    </Stack>
                )
            }
            <Button
                variant='outlined'
                onClick={() => dispatch(openModal({ name: 'selectUsersMembersModal' }))}
                sx={{ marginTop: 3 }}
            >
                {`Ajouter un membre`}
            </Button>
        </>
    );
};

export default MembersManagement;
