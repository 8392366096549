import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgMissionFailed: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#F8B64C' d='M256,86.4c51.2,0,98.133,21.333,132.267,54.4l49.067-49.067C391.467,45.867,327.467,17.067,256,17.067c-70.4,0-134.4,28.8-181.333,74.667l49.067,49.067C157.867,106.667,204.8,86.4,256,86.4z' />
            <path fill='#54C0EB' d='M123.733,140.8L74.667,91.733C28.8,138.667,0,202.667,0,273.067s28.8,134.4,74.667,181.333l49.067-49.067c-34.133-34.133-54.4-81.067-54.4-132.267C69.333,221.867,89.6,174.933,123.733,140.8z' />
            <path fill='#F1543F' d='M437.333,91.733L388.267,140.8c34.133,34.133,54.4,81.067,54.4,132.267s-21.333,98.133-54.4,132.267l49.067,49.067C483.2,408.533,512,344.533,512,273.067C512,202.667,483.2,138.667,437.333,91.733z' />
            <path fill='#FFD15C' d='M388.267,140.8l-46.933,46.933c-22.4-21.333-52.267-35.2-85.333-35.2s-64,13.867-85.333,35.2l0,0L123.733,140.8C157.867,106.667,204.8,86.4,256,86.4S354.133,106.667,388.267,140.8z' />
            <path fill='#84DBFF' d='M170.667,358.4l-46.933,46.933c-34.133-34.133-54.4-81.067-54.4-132.267s21.333-98.133,54.4-132.267l46.933,46.933c-21.333,21.333-35.2,52.267-35.2,85.333C134.4,306.133,148.267,337.067,170.667,358.4z' />
            <path fill='#FF7058' d='M442.667,273.067c0,51.2-21.333,98.133-54.4,132.267L341.333,358.4c21.333-22.4,35.2-52.267,35.2-85.333s-13.867-64-35.2-85.333l46.933-46.933C422.4,174.933,442.667,221.867,442.667,273.067z' />
            <path fill='#40596B' d='M420.267,171.733L281.6,277.333c-2.133,12.8-12.8,21.333-25.6,21.333c-14.933,0-26.667-11.733-26.667-26.667s11.733-26.667,26.667-26.667c3.2,0,5.333,0,8.533,1.067L420.267,171.733z' />
            <path fill='#CDD6E0' d='M347.733,494.933H164.267c-22.4,0-40.533-18.133-40.533-40.533l0,0c0-22.4,18.133-40.533,40.533-40.533h182.4c22.4,0,40.533,18.133,40.533,40.533l0,0C388.267,476.8,370.133,494.933,347.733,494.933z'/>
            <circle fill='#FFFFFF' cx='256' cy='273.07' r='10.667' />
        </SvgIcon>
    );
};

export default SvgMissionFailed;
