import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDashboardMenuState {
    open: boolean,
    extension: string|null,
};

const initialState: IDashboardMenuState = {
    open: true,
    extension: null,
};

export const slice = createSlice({
    name: 'dashboardMenuState',
    initialState,
    reducers: {
        isDashboardMenuOpen: (state: IDashboardMenuState, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        isDashboardMenuExtensionOpen: (state: IDashboardMenuState, action: PayloadAction<string|null>) => {
            state.extension = action.payload;
        },
    },
});

export const { isDashboardMenuOpen, isDashboardMenuExtensionOpen } = slice.actions;

export default slice.reducer;
