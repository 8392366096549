import React from 'react';
import { FieldSubscription } from 'final-form';
import { Field, FieldRenderProps } from 'react-final-form';

type FieldRenderPropsArray = {
    [key: string]: FieldRenderProps<any>
};

/**
 * Component that permits to listen to a group of final-form fields
 */
const FinalFields: React.FC<{
    names: Array<string>,
    subscription?: FieldSubscription,
    // 2 way of rendering the fields
    render?: ((props: FieldRenderPropsArray) => React.ReactElement|null)|undefined,
    children?: ((props: FieldRenderPropsArray) => React.ReactElement|null)|undefined,
    // Only used for recursive calls
    fieldsState?: FieldRenderPropsArray|undefined,
}> = props => {
    // Extract first name from names array
    const [name, ...rest] = props.names;

    return (
        props.names.length ? (
            <Field name={name} subscription={props.subscription}>
                {fieldState => (
                    <FinalFields
                        names={rest}
                        subscription={props.subscription}
                        render={props.render || props.children}
                        fieldsState={{ ...props.fieldsState, [name]: fieldState }}
                    />
                )}
            </Field>
        ) : (
            props.render ?
                props.render(props.fieldsState!)
                : props.children ?
                    props.children(props.fieldsState!)
                    : null
        )
    );
};

export default FinalFields;
