import React from 'react';
import { Field } from 'formik';
import moment from 'moment-timezone';
import frLocale from 'date-fns/locale/fr';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/lab';
import {
    SxProps,
    Theme,
    FormControl,
    FormLabel,
    TextField,
    FormHelperText,
} from '@mui/material';
import IFormikFieldProps from '../../interfaces/IFormikFieldProps';

const FormikDatePicker: React.FC<{
    name: string,
    label?: string|undefined,
    upperLabel?: string|undefined,
    helperText?: string|React.ReactElement|undefined,
    size?: 'medium'|'small'|undefined,
    forceError?: boolean|undefined,
    fullWidth?: boolean|undefined,
    disabled?: boolean|undefined,
    views?: Array<'day'|'hours'|'minutes'|'month'|'seconds'|'year'>|undefined,
    minDateTime?: Date|undefined,
    maxDateTime?: Date|undefined,
    sx?: SxProps<Theme>|undefined,
    inputSx?: SxProps<Theme>|undefined,
    shouldDisableDate?: ((date: Date) => boolean)|undefined,
    onChange?: ((date: Date|null) => void)|undefined,
}> = props => {
    return (
        <Field name={props.name}>
            {({ field, form, meta }: IFormikFieldProps) => (
                <FormControl
                    fullWidth={props.fullWidth ?? true}
                    disabled={props.disabled}
                    error={props.forceError ?? Boolean(meta.touched && meta.error)}
                    sx={props.sx}
                >
                    {
                        props.upperLabel && (
                            <FormLabel sx={{ fontSize: { xs: 16 } }}>
                                {props.upperLabel}
                            </FormLabel>
                        )
                    }
                    <LocalizationProvider dateAdapter={DateAdapter} locale={frLocale}>
                        <DateTimePicker
                            {...field}
                            value={field.value ?? null}
                            disabled={props.disabled}
                            views={props.views}
                            ampm={false}
                            label={props.label}
                            minDateTime={props.minDateTime}
                            maxDateTime={props.maxDateTime}
                            shouldDisableDate={
                                undefined !== props.shouldDisableDate ?
                                    (date) => props.shouldDisableDate!(date)
                                    : undefined
                            }
                            renderInput={(forwardProps) => (
                                <TextField
                                    {...forwardProps}
                                    sx={props.inputSx}
                                    size={props.size ?? 'small'}
                                    error={props.forceError ?? Boolean(meta.touched && meta.error)}
                                    onBlur={() => form.setFieldTouched(props.name)}
                                />
                            )}
                            onChange={(date: Date|null) => {
                                if (moment(date).isValid()) {
                                    if (undefined !== props.views && props.views.includes('hours')) {
                                        date = moment(date).toDate();
                                        // Set minutes and seconds to 0
                                        date.setHours(date.getHours(), 0, 0, 0);
                                    }
                                    form.setFieldValue(props.name, date);
                                    props.onChange && props.onChange(date);
                                }
                            }}
                        />
                    </LocalizationProvider>
                    {
                        (Boolean(meta.touched && meta.error) || props.helperText) && (
                            <FormHelperText component='span'>
                                {Boolean(meta.touched && meta.error) ? meta.error : props.helperText}
                            </FormHelperText>
                        )
                    }
                </FormControl>
            )}
        </Field>
    );
};

export default FormikDatePicker;
