import React from 'react';
import {
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip
} from '@mui/material';
import {
    CheckCircle as CheckIcon,
    Cancel as CancelIcon,
} from '@mui/icons-material';

const EditableInput: React.FC<{
    value: string,
    canEdit?: boolean|undefined,
    placeholder?: string|undefined,
    multiline?: boolean|undefined,
    canBeEmpty?: boolean|undefined,
    variant?: 'button'|'caption'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6'|'inherit'|'subtitle1'|'subtitle2'|'body1'|'body2'|'overline'|undefined,
    onChange: (value: string) => void,
}> = props => {
    const [edit, setEdit] = React.useState<boolean>(false);
    const [value, setTitle] = React.useState<string>(props.value);
    const [emptyError, setEmptyError] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    // Callback to handle input reset
    const handleReset = React.useCallback(() => {
        setTitle(props.value);
        setEdit(false);
    }, [props.value])

    // Callback to handle input validation
    const handleValidate = React.useCallback(() => {
        if (!props.canBeEmpty && !value) {
            setEmptyError(true);
        } else {
            props.onChange(value);
            setEdit(false);
        }
    }, [props, value]);

    // Callback to handle escape key press to reset edited field
    const handleEsc = React.useCallback((event: KeyboardEvent) => {
        'Escape' === event.key ?
            handleReset()
            : 'Enter' === event.key && !props.multiline &&
                handleValidate();
    }, [props.multiline, handleReset, handleValidate]);

    // Callback to handle click on document
    const handleClick = React.useCallback((event) => {
        // If user click oustide of inputRef container, reset form
        inputRef.current !== event.target && !inputRef.current?.contains(event.target) &&
            handleValidate();
    }, [handleValidate]);

    // useEffect whenever edit state changes
    React.useEffect(() => {
        // Listen to keyup and click events
        if (true === edit) {
            inputRef.current!.addEventListener('keyup', handleEsc);
            document.addEventListener('click', handleClick);
        }

        // On unmount, stop listening to click event
        // Keyup event will be removed automatically
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [edit, handleEsc, handleClick]);

    // useEffect whenever props.value value changes
    React.useEffect(() => {
        // Update title state with new value
        setTitle(props.value);
    }, [props.value]);

    return (
        false === edit ? (
            <Typography
                variant={props.variant}
                onDoubleClick={true === props.canEdit ? () => setEdit(true) : undefined}
            >
                {Boolean(value) ? value : 'Contenu vide'}
            </Typography>
        ) : (
            <TextField
                fullWidth
                autoFocus
                multiline={props.multiline}
                ref={inputRef}
                variant='outlined'
                placeholder={props.placeholder}
                InputProps={{
                    endAdornment:(
                        <InputAdornment position='end'>
                                <Tooltip title='Annuler les modifications'>
                                    <IconButton onClick={handleReset}>
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Valider les modifications'>
                                    <IconButton color='success' onClick={handleValidate}>
                                        <CheckIcon />
                                    </IconButton>
                                </Tooltip>
                        </InputAdornment>
                    )
                }}
                helperText={emptyError ? (
                    <Typography variant='caption' color='error'>
                        {`Le champ ne peut pas être vide.`}
                    </Typography>
                ) : undefined}
                value={value}
                onChange={(event) => setTitle(event.target.value)}
            />
        )
    );
};

export default EditableInput;
