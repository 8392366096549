import React from 'react';
import { Field, FieldMetaProps } from 'formik';
import Tools from '../../helpers/Tools';
import NumberFormat, { FormatInputValueFunction, NumberFormatProps } from 'react-number-format';
import {
    SxProps,
    Theme,
    FormControl,
    FormLabel,
    TextField,
    InputAdornment,
    TextFieldProps,
    FormHelperText,
    Stack,
    Typography,
} from '@mui/material';
import RouterLink from '../RouterLink/RouterLink';
import IFormikFieldProps from '../../interfaces/IFormikFieldProps';
import ILink from '../../interfaces/ILink';

/**
 * As explained here https://github.com/s-yadav/react-number-format/issues/233,
 * customInput always pass a new function reference,
 * which ends up creating a new input element on each re-render, hence loosing the focus.
 * Creating the customInput component outside solve this issue.
 *
 * Also, size prop has a meaning in NumberFormat and cannot be forwarded to TextField,
 * So we need to define two customInput depending on given size.
 */
const CustomTextField = React.forwardRef((forwardProps: TextFieldProps, ref) => (
    <TextField inputRef={ref} {...forwardProps} />
));
const CustomSmallTextField = React.forwardRef((forwardProps: TextFieldProps, ref) => (
    <TextField inputRef={ref} {...forwardProps} size='small' />
));

const FormikInput: React.FC<{
    name: string,
    type?: 'text'|'number'|'password'|'date'|undefined,
    label?: string|undefined,
    upperLabel?: string|undefined,
    placeholder?: string|undefined,
    autoComplete?: boolean|undefined,
    autoFocus?: boolean|undefined,
    disabled?: boolean|undefined,
    forceError?: boolean|undefined,
    warnings?: Array<any>|undefined,
    fullWidth?: boolean|undefined,
    helperText?: string|React.ReactElement|undefined,
    helperTextLink?: ILink|undefined,
    size?: 'medium'|'small'|undefined,
    variant?: 'filled'|'outlined'|'standard'|undefined,
    multiline?: boolean|undefined,
    minRows?: number|undefined,
    maxRows?: number|undefined,
    mask?: Array<string>|string|undefined,
    format?: string|FormatInputValueFunction|undefined,
    prefix?: string|undefined,
    suffix?: string|undefined,
    numberFormatProps?: NumberFormatProps|undefined,
    countWords?: boolean|undefined,
    sx?: SxProps<Theme>|undefined,
    inputSx?: SxProps<Theme>|undefined,
    onChange?: ((value: any) => void)|undefined,
}> = props => {
    // Use of hooks
    const [warning, hasWarning] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement|null>(null);

    // Define common props for both components
    const commonProps: any = {
        fullWidth: props.fullWidth ?? true,
        margin: 'none',
        autoFocus: props.autoFocus,
        disabled: props.disabled,
        variant: props.variant,
        label: props.label,
        multiline: props.multiline,
        minRows: props.multiline ? props.minRows ?? 2 : undefined,
        maxRows: props.multiline ? props.maxRows ?? 2 : undefined,
        placeholder: props.placeholder,
        FormHelperTextProps: { component: 'span' },
    };

    // useEffect whenever warnings prop value change
    React.useEffect(() => {
        // Setup a hook to know if there is at least one warning
        props.warnings &&
            hasWarning(props.warnings.some((warning) => true === warning.condition));
    }, [props.warnings])

    // Function used to generate helper text from given props
    const generateHelperText = function(meta: FieldMetaProps<any>) {
        const finalHelperText = props.helperTextLink ? (
            <Stack>
                <Typography variant='caption'>
                    {props.helperText}
                </Typography>
                <RouterLink
                    anchor={props.helperTextLink.anchor}
                    to={props.helperTextLink.url}
                    target={props.helperTextLink.target}
                />
            </Stack>
        ) : props.helperText;

        return Boolean(meta.touched && meta.error) ? meta.error : warning ? undefined : finalHelperText;
    };

    return (
        <Field name={props.name}>
            {({ field, form, meta }: IFormikFieldProps) => (
                <FormControl
                    fullWidth={props.fullWidth ?? true}
                    disabled={props.disabled}
                    error={props.forceError ?? Boolean(meta.touched && meta.error)}
                    sx={{
                        ...props.sx,
                        position: !props.countWords ? 'relative' : undefined,
                    }}
                >
                    {
                        props.upperLabel && (
                            <FormLabel sx={{ fontSize: { xs: 16 } }}>
                                {props.upperLabel}
                            </FormLabel>
                        )
                    }
                    {
                        props.format || props.mask || 'number' === props.type ? (
                            <NumberFormat
                                {...commonProps}
                                {...props.numberFormatProps}
                                name={field.name}
                                value={field.value}
                                autoComplete='off'
                                format={props.format}
                                mask={props.mask}
                                error={props.forceError ?? Boolean(meta.touched && meta.error)}
                                helperText={generateHelperText(meta)}
                                customInput={
                                    !props.size || 'small' === props.size ?
                                        CustomSmallTextField : CustomTextField
                                }
                                InputProps={{
                                    sx: props.inputSx,
                                    ...(props.prefix ? {
                                        startAdornment: (
                                            <InputAdornment
                                                position='start'
                                                onClick={() => inputRef.current && inputRef.current.focus()}
                                            >
                                                {props.prefix}
                                            </InputAdornment>)
                                    } : undefined),
                                    ...(props.suffix ? {
                                        endAdornment: (
                                            <InputAdornment
                                                position='end'
                                                onClick={() => inputRef.current && inputRef.current.focus()}
                                            >
                                                {props.suffix}
                                            </InputAdornment>)
                                    } : undefined),
                                }}
                                onBlur={field.onBlur}
                                onValueChange={(values: NumberFormatProps) => {
                                    form.setFieldValue(field.name, values.value);
                                    props.onChange && props.onChange(values.value);
                                }}
                            />
                        ) : (
                            <TextField
                                {...field}
                                {...commonProps}
                                value={field.value ?? ''}
                                autoComplete={props.autoComplete ? 'on' : 'off'}
                                size={props.size ?? 'small'}
                                type={props.type}
                                error={props.forceError ?? Boolean(meta.touched && meta.error)}
                                helperText={generateHelperText(meta)}
                                inputRef={inputRef}
                                InputLabelProps={'date' === props.type ? { shrink: true } : undefined}
                                InputProps={{
                                    ...(props.prefix ? {
                                        startAdornment: (
                                            <InputAdornment
                                                position='start'
                                                onClick={() => inputRef.current && inputRef.current.focus()}
                                            >
                                                {props.prefix}
                                            </InputAdornment>)
                                    } : undefined),
                                    ...(props.suffix ? {
                                        endAdornment: (
                                            <InputAdornment
                                                position='end'
                                                onClick={() => inputRef.current && inputRef.current.focus()}
                                            >
                                                {props.suffix}
                                            </InputAdornment>)
                                    } : undefined),
                                    sx: {
                                        ...props.inputSx,
                                        paddingBottom: props.countWords ? 4 : undefined,
                                    },
                                    endAdornment: props.countWords ? (
                                        <Typography
                                            variant='caption'
                                            position='absolute'
                                            bottom={5}
                                            right={10}
                                            color='textSecondary'
                                        >
                                            {`${Tools.countWords(field.value)} mot(s)`}
                                        </Typography>
                                    ) : undefined
                                }}
                                onChange={(event) => {
                                    field.onChange(event);
                                    props.onChange && props.onChange(event.currentTarget.value);
                                }}
                            />
                        )
                    }
                    {
                        // If there is at least one warning
                        warning && props.warnings && props.warnings.map((warning, index: number) => (
                            true === warning.condition && (
                                <FormHelperText key={index} component='span' sx={{ color: 'warning.main' }}>
                                    {warning.text}
                                </FormHelperText>
                            )
                        ))
                    }
                </FormControl>
            )}
        </Field>
    );
};

export default FormikInput;
