import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { useTheme } from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import MissingBioTooltip from '../MissingBioTooltip/MissingBioTooltip';
import makeCssStyles from './DashboardHeadbandStyles';

const HeadbandMissingBio: React.FC = () => {
    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme);

    // Use of redux
    const instanceUserHasBio: boolean = useAppSelector(state => state.instance.user!.bio);

    return (
        false === instanceUserHasBio ? (
            <MissingBioTooltip>
                <ReportGmailerrorredIcon fontSize='small' sx={cssStyles.blinkingIcon} />
            </MissingBioTooltip>
        ) : null
    );
};

export default HeadbandMissingBio;
