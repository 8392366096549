import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    confetti: {
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    },
};

export default cssStyles;
