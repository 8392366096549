import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { setOpenAiGenerationState } from '../../store/slices/openAiGenerationSlice';
import { clearTextEditorState } from '../../store/slices/textEditorSlice';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import PostAPIs from '../../APIs/PostAPIs';
import Tools from '../../helpers/Tools';
import { Stack } from '@mui/material';
import Spinner from '../Spinner/Spinner';
import ContentEditionValidation from './ContentEditionValidation';
import KeywordDisplay from '../KeywordDisplay/KeywordDisplay';
import BriefDisplay from '../BriefDisplay/BriefDisplay';
import LinksDisplay from './LinksDisplay';
import PostEditor from '../PostEditor/PostEditor';
import SavePost from './SavePost';
import AutoSave from './AutoSave';
import IOptimizationData from '../../interfaces/IOptimizationData';
import IPostFormStepperData from '../../interfaces/IPostFormStepperData';
import IOrder from '../../models/IOrder';

const ContentEdition: React.FC<{
    order: IOrder,
    withConstraints: boolean,
    stepValidationRef: React.MutableRefObject<(() => void)|undefined>,
}> = props => {
    // Use of hooks
    const isMounted = React.useRef<boolean>(true);

    // Use of redux
    const dispatch = useAppDispatch();
    const postFormStepperData: IPostFormStepperData = useAppSelector(state => state.activeStepper.data);

    // useEffect to handle optimization data before post creation
    React.useEffect(() => {
        undefined === postFormStepperData.optimization &&
            // Call API to retrieve optimization data with post keyword
            BridgeAPIs.getOptimizationData({ query: props.order.keyword.name, content: '' })
                // On successful API call
                .then((optiData: IOptimizationData) =>
                    optiData && true === isMounted.current &&
                        // Set optimization data in redux
                        dispatch(setActiveStepperData({ data: { optimization: { ...optiData, prevPlainText: null }}}))
                );
    }, [postFormStepperData.optimization, props.order.keyword.name, dispatch]);

    // useEffect to create a post with optimization data
    React.useEffect(() => {
        if (undefined !== postFormStepperData.optimization && undefined === postFormStepperData.identifier) {
            // Create body request for the new post
            const bodyRequest = {
                order: props.order.id,
                title: postFormStepperData.title,
                metaDesc: postFormStepperData.metaDesc,
                optimization: postFormStepperData.optimization!.score,
                optimizationWords: postFormStepperData.optimization!.words,
            };

            // Call API to create a new post
            PostAPIs.postMultiplePost([Tools.convertToBodyRequest(bodyRequest)])
                // On successful API call
                .then((data) => {
                    if (data && 1 === data.length && true === isMounted.current) {
                        const createdPost = data[0];
                        // Reset canCreatePost and askOptimization states and set appReady to true
                        const postData = {
                            identifier: createdPost.id,
                            html: createdPost.html,
                            nbrWords: createdPost.nbrWords,
                        };
                        // Set post data in redux
                        dispatch(setActiveStepperData({ data: postData }));
                        // Set Post ID in text editor redux state (for OpenAI requests)
                        dispatch(setOpenAiGenerationState({ postId: createdPost.id }));
                    }
                });
        } else {
            // Set Post ID in text editor redux state (for OpenAI requests)
            dispatch(setOpenAiGenerationState({ postId: postFormStepperData.identifier! }));
        }
    }, [props.order.id, props.order.isPublic, postFormStepperData, dispatch]);

    // useEffect when component is unmounting
    React.useEffect(() => () => {
        // Clear redux state
        dispatch(clearTextEditorState());
    }, [dispatch]);

    return (
        postFormStepperData.identifier ? (
            <>
                <KeywordDisplay keyword={props.order.keyword.name}/>
                <BriefDisplay brief={props.order.brief} />
                {
                    0 < props.order.links.length && (
                        <LinksDisplay links={props.order.links} />
                    )
                }
                <Stack spacing={2} alignItems='flex-end'>
                    <PostEditor
                        keyword={props.order.keyword.name}
                        initialHtml={postFormStepperData.html}
                        optimizationData={postFormStepperData.optimization}
                        hideButtonAndScore={null === props.order.minOptimization}
                    />
                    <SavePost order={props.order} withConstraints={props.withConstraints} />
                </Stack>
                <ContentEditionValidation
                    order={props.order}
                    withConstraints={props.withConstraints}
                    stepValidationRef={props.stepValidationRef}
                />
                <AutoSave order={props.order} withConstraints={props.withConstraints} />
            </>
        ) : (<Spinner />)
    );
};

export default ContentEdition;
