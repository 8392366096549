import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgAutomaticGeneration: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#334A5E' d='M135.256,136.054c-14.911,14.91-43.666,14.91-74.551,1.064l314.176,314.176c-13.845-31.95-13.845-59.64,1.065-74.551c14.91-14.91,43.665-14.91,74.551-1.065L136.32,61.504C150.165,92.389,151.231,121.144,135.256,136.054z' />
            <g>
                <path fill='#40596B' d='M73.485,7.189l61.77,61.77l-17.04,50.055l-50.055,17.04L6.39,74.284c-10.65,34.08-2.13,72.42,25.56,99.045c39.405,39.405,102.24,39.405,141.646,0s39.405-102.24,0-141.646C145.905,5.059,107.565-3.462,73.485,7.189z' />
                <path fill='#40596B' d='M437.716,505.61l-61.771-61.77l17.04-50.055l50.055-17.04l61.771,61.77c10.65-34.08,2.13-72.42-25.56-99.045c-39.405-39.405-102.24-39.405-141.646,0s-39.405,102.24,0,141.646C365.296,507.74,403.636,516.26,437.716,505.61z' />
            </g>
            <g>
                <polygon fill='#FF7058' points='40.47,359.705 85.167,404.069 357.807,131.428 313.111,85.999 309.916,89.194 	' />
                <rect x='76.838' y='289.44' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -144.3715 283.5827)' fill='#FF7058' width='386.582' height='53.246' />
            </g>
            <rect x='40.151' y='252.84' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -129.2366 246.9214)' fill='#F1543F' width='386.582' height='53.246' />
            <path fill='#F8B64C' d='M450.496,172.264L338.671,60.439l53.25-53.25c9.585-9.585,24.495-9.585,33.015,0l79.875,78.81c9.585,9.585,9.585,23.43,0,33.015L450.496,172.264z' />
            <polygon fill='#F2F2F2' points='422.806,201.019 309.916,89.194 338.671,60.439 450.496,172.264 ' />
            <polygon fill='#FFD05C' points='40.47,359.705 0,512 152.295,471.53 ' />
            <path fill='#41596B' d='M14.91,456.62c7.455,5.325,14.91,10.65,22.365,18.105c7.455,7.454,13.845,14.911,18.106,23.43L0,512L14.91,456.62z' />
        </SvgIcon>
    );
};

export default SvgAutomaticGeneration;
