import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import { resetImportCsvState } from '../../store/slices/importCsvSlice';
import ImportCsvStepper from '../ImportCsvSteps/ImportCsvStepper';
import GenericModal from './GenericModal';
import ICsvEntityStructure from '../../interfaces/ICsvEntityStructure';
import ICsvData, { ICsvColumn } from '../../interfaces/ICsvData';

const ModalImportCsv: React.FC<{
    expectedHeaders: Array<{ label: string, value: string }>,
    dataValidation?: ICsvEntityStructure|undefined,
    prefilledColumns?: ICsvColumn|undefined,
    modalTitle?: string|undefined,
    name?: string|undefined,
    onValidation: (data: ICsvData, modalParams: any) => void
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'importCsvModal');

    // Use of redux
    const dispatch = useAppDispatch();
    const modalParams = useAppSelector(state => state.activeModal.params);

    return (
        <GenericModal
            name={modalName.current}
            title={props.modalTitle ?? 'Importer un fichier CSV'}
            width={0.9 / 1}
            onClose={() => dispatch(resetImportCsvState())}
        >
            <ImportCsvStepper
                expectedHeaders={props.expectedHeaders}
                dataValidation={props.dataValidation}
                prefilledColumns={props.prefilledColumns}
                onValidation={data => {
                    props.onValidation(data, modalParams);
                    dispatch(resetImportCsvState());
                    dispatch(closeModal());
                }}
            />
        </GenericModal>
    );
};

export default ModalImportCsv;
