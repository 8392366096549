import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal, isModalOpened } from '../../store/slices/activeModalSlice';
import PostAPIs from '../../APIs/PostAPIs';
import {
    Stack,
    Typography,
    Box,
    Button,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import GenericModal from './GenericModal';
import IPost from '../../models/IPost';
import EOrderStatuses from '../../interfaces/EOrderStatuses';

const ModalViewRejectionMessages: React.FC<{ title?: string|undefined, name?: string|undefined, post?: IPost|undefined }> = props => {
    // Use of hooks
    const [post, setPost] = React.useState<IPost|null>(null);
    const modalName = React.useRef<string>(props.name ?? 'viewRejectionMessages');

    // Use of react-router-dom hooks
    const navigate = useNavigate();

    // Use of redux
    const dispatch = useAppDispatch();
    const postId: number|null = useAppSelector(state => state.activeModal.params);
    const modalIsOpened: boolean = useAppSelector(isModalOpened(modalName.current));

    // useEffect when component is mounting
    React.useEffect(() => {
        if (modalIsOpened && null === post && (null !== postId || undefined !== props.post)) {
            // Post given in props
            undefined !== props.post ?
                // Set hook post value & move on
                setPost(props.post)
                // Otherwise, call API to retrieve Post data from id given via modal params
                : PostAPIs.getPost({ id: postId })
                    // On successful call
                    .then((data: Array<IPost>|null) => null !== data && data[0] && setPost(data[0]));
        }
    }, [modalIsOpened, post, postId, props.post])

    // Function used to transform a numeric counter into a literal one
    function literalCounter(counter: number) {
        const assoc: { [key: number|string]: string } = {
            1: 'première',
            2: 'deuxième',
        };

        return assoc[counter] ?? `${counter}eme`;
    };

    // Callback used to handle correction choice
    const handlePostCorrection = React.useCallback(() => {
        // Call API to set Post in correction
        PostAPIs.patchPost(post!.id, { status: EOrderStatuses.CORRECTED })
            // On successful API call
            .then((data : IPost|null) => {
                if (null !== data) {
                    // Redirect to post edition
                    navigate('../form', { state: post!.order.id });
                    // Close modal
                    dispatch(closeModal());
                }
            });
    }, [navigate, post, dispatch]);

    return (
        null !== post && post.postRefuseMessages && post.postRefuseMessages[0] ? (
            <GenericModal
                name={modalName.current}
                title={props.title ?? 'Consultation des messages de refus'}
                width='45%'
                onClose={() => setPost(null)}
            >
                <Typography variant='body2'>
                    {`C'est la `}
                    <Typography component='span' variant='body2' fontWeight={700}>
                        {literalCounter(post.postRefuseMessages.length)}
                    </Typography>
                    {` fois que cette article est refusé.`}
                </Typography>
                <Stack direction='row' columnGap={1} color='warning.main'>
                    <WarningIcon fontSize='small' sx={{ verticalAlign: 'middle' }} />
                    <Typography component='span' variant='body2'>
                        {`Attention, comme indiqué dans les CGU, à partir du troisième refus, la mission peut être annulée par le Donneur d'Ordres sans indemnité. Vous perdrez alors la rédaction de cet article.`}
                    </Typography>
                </Stack>
                <Box paddingY={2}>
                    <Typography fontWeight={700} gutterBottom>
                        {`Votre article a été refusé pour les raisons suivantes :`}
                    </Typography>
                    {
                        post.postRefuseMessages[0].reasons.map((rejectionMsg: string, index: number) => (
                            <Typography key={index} paddingLeft={1}>
                                {`- ${rejectionMsg}`}
                            </Typography>
                        ))
                    }
                </Box>
                <Stack direction='row' justifyContent='space-between' paddingX={1} paddingTop={2}>
                    <Button
                        disabled
                        variant='outlined'
                        color='inherit'
                        sx={{ marginRight: 2 }}
                    >
                        {`Démarrer un litige`}
                    </Button>
                    <Button
                        variant='outlined'
                        color='success'
                        onClick={handlePostCorrection}
                    >
                        {`Corriger mon article`}
                    </Button>
                </Stack>
            </GenericModal>
        ) : (
            <Backdrop open={modalIsOpened && null === post}>
                <CircularProgress size='10vh' />
            </Backdrop>
        )
    );
};

export default ModalViewRejectionMessages;
