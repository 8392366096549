import React from 'react';
import moment from 'moment-timezone';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Stack,
    Paper,
    CardMedia,
    Tooltip,
    IconButton,
    Button,
} from '@mui/material';
import {
    ContentCopy as ContentCopyIcon,
    AccountBalance as AccountBalanceIcon,
    Receipt as ReceiptIcon,
} from '@mui/icons-material';
import ModalConfirmation from '../Modal/ModalConfirmation';
import IPayment from '../../models/IPayment';
import cssStyles from './PaymentValidationStyles';

const PaymentPaper: React.FC<{ payment: IPayment, onValidation: () => void }> = props => {
    // Use of hooks
    const [isDialogOpen, openDialog] = React.useState<boolean>(false);

    // Use of redux
    const dispatch = useAppDispatch();

    // Callback to handle clipboard copy
    function handleCopy(response: string|null) {
        response && navigator.clipboard.writeText(response);
    };

    return (
        <>
            <Dialog open={isDialogOpen} onClose={() => openDialog(false)}>
                <DialogTitle>
                    {`IBAN de la société`}
                </DialogTitle>
                <DialogContent>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        <DialogContentText>
                            {props.payment.wallet.account.company!.iban}
                        </DialogContentText>
                        <IconButton size='small' onClick={() => handleCopy(props.payment.wallet.account.company!.iban)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Stack>
                </DialogContent>
            </Dialog>
            <ModalConfirmation
                title='Confirmation de paiement'
                message={
                    <>
                        <Typography align='center' paddingBottom={2}>
                            {`Assurez-vous d'avoir bien effectué le virement d'un montant de `}
                            <Typography component='span' fontWeight={700} color='primary'>
                                {`${props.payment.amount} €`}
                            </Typography>
                                {` sur le compte de la société `}
                            <Typography component='span' fontWeight={700}>
                                {`${props.payment.wallet.account.company!.name}`}
                            </Typography>
                        </Typography>
                        <Typography align='center'>
                            {`Confirmer la validation de ce paiement ?`}
                        </Typography>
                    </>
                }
                validationButtonColor='primary'
                onValidation={() => props.onValidation()}
            />
            <Stack alignItems='flex-start'>
                <Stack component={Paper} sx={cssStyles.paymentTag}>
                    <Typography variant='body2'>
                        {`Demandé le : ${moment(props.payment.createdAt).format('DD/MM/Y à H:mm')}`}
                    </Typography>
                </Stack>
                <Stack component={Paper} sx={cssStyles.paymentPaper}>
                    <Stack sx={cssStyles.companyInfo}>
                        <CardMedia
                            component='img'
                            src={props.payment.wallet.account.company!.logo!}
                            // Zero width space
                            alt='&#8203;'
                            sx={cssStyles.companyLogo}
                        />
                        <Typography sx={cssStyles.companyName}>
                            {props.payment.wallet.account.company!.name}
                        </Typography>
                    </Stack>
                    <Typography component='p' variant='h5' fontWeight='bold' color='primary'>
                        {`${props.payment.amount} €`}
                    </Typography>
                    <Stack direction='row' spacing={2}>
                        <Tooltip title='Voir IBAN'>
                            <IconButton
                                sx={cssStyles.iconButton}
                                onClick={() => openDialog(true)}
                            >
                                <AccountBalanceIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Voir la facture'>
                            <IconButton
                                sx={cssStyles.iconButton}
                                onClick={() => props.payment.invoiceUrl && window.open(props.payment.invoiceUrl, '_blank')}
                            >
                                <ReceiptIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Button
                        variant='contained'
                        onClick={() => dispatch(openModal({ name: 'confirmModal' }))}
                    >
                        {'Valider le paiement'}
                    </Button>
                </Stack>
            </Stack>
        </>
    );
};

export default PaymentPaper;
