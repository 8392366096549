import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { clearTextEditorState, setTextEditorState } from '../../store/slices/textEditorSlice';
import { Grid } from '@mui/material';
import OptimizationPanel from './OptimizationPanel';
import TextEditor from '../TextEditor/TextEditor';
import Spinner from '../Spinner/Spinner';
import IOptimizationData from '../../interfaces/IOptimizationData';

const PostEditor: React.FC<{
    keyword: string,
    initialHtml?: string|undefined,
    optimizationData?: IOptimizationData|undefined,
    hideButtonAndScore?: boolean|undefined,
}> = props => {
    // Use of hook
    const [mounted, isMounted] = React.useState<boolean>(false);

    // Use of redux
    const dispatch = useAppDispatch();

    // useEffect when component is mounting
    React.useEffect(() => {
        if (false === mounted) {
            // Dispatch text editor in redux state
            dispatch(setTextEditorState({ optimization: props.optimizationData }));
            // Change our hook value because our component is now mounted
            isMounted(true);
        }
    }, [mounted, props, dispatch]);

    // useEffect when component is unmount
    React.useEffect(() => () => {
        // Clear all textEditor redux state
        dispatch(clearTextEditorState());
    }, [dispatch])

    return (
        mounted ? (
            <Grid container spacing={2}>
                <Grid item md={12} lg={4}>
                    <OptimizationPanel keyword={props.keyword} hideButtonAndScore={props.hideButtonAndScore} />
                </Grid>
                <Grid item md={12} lg={8}>
                    <TextEditor
                        allowHeadingOne
                        withAIAssistant
                        placeholder='Contenu du paragraphe'
                        initialHtml={props.initialHtml}
                    />
                </Grid>
            </Grid>
        ): (<Spinner />)
    );
};

export default PostEditor;
