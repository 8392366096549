import { createAsyncThunk, createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import OtherAPIs from '../../APIs/OtherAPIs';
import IOrderStatus from '../../models/IOrderStatus';

export interface IOrderStatusesState {
    status: boolean|null,
    data: Array<IOrderStatus>|null,
    error: string|null,
};

const initialState: IOrderStatusesState = {
    status: null,
    data: null,
    error: null,
};

export const fetchOrderStatuses = createAsyncThunk(
    'orderStatuses.fetchAll',
    // Only fetch if there is no existing data in state
    async (_, { getState }) => null === (getState() as any).orderStatuses.data && await OtherAPIs.getOrderStatuses()
);

export const slice = createSlice({
    name: 'orderStatusesState',
    initialState,
    reducers: {
        clearOrderStatusesState: (_: IOrderStatusesState) => {
            // Return a new value and force createSlice to replace the old one.
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrderStatuses.fulfilled, (state: IOrderStatusesState, action: PayloadAction<Array<IOrderStatus>>) => {
            state.status = true;
            state.data = action.payload;
        });
        builder.addCase(fetchOrderStatuses.rejected, (state: IOrderStatusesState, action: any) => {
            state.status = false;
            state.error = action.error.message;
        });
    },
});

export const { clearOrderStatusesState } = slice.actions;

// Custom selector to get order status by slug
export const selectOrderStatusBySlug = (slug: string): any => createSelector(
    (state: any) => state.orderStatuses.data,
    (orderStatus: Array<IOrderStatus>|null) => {
        return orderStatus && orderStatus.find(os => os.slug === slug);
    }
);

// Custom selector to get order status by slug
export const selectOrderStatusByName = (name: string): any => createSelector(
    (state: any) => state.orderStatuses.data,
    (orderStatus: Array<IOrderStatus>|null) => {
        return orderStatus && orderStatus.find(os => os.name === name);
    }
);

export default slice.reducer;
