import React from 'react';
import useSSE from '../../hooks/useSSE';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IInstanceWalletState, setInstanceWalletState } from '../../store/slices/instanceWalletSlice';
import PaymentAPIs from '../../APIs/PaymentAPIs';
import {
    useTheme,
    Tooltip,
    Stack,
    Typography,
} from '@mui/material';
import { RiMoneyEuroCircleLine, RiMoneyEuroCircleFill } from 'react-icons/ri';
import IWallet from '../../models/IWallet';
import makeCssStyles from './DashboardHeadbandStyles';

const HeadbandWallet: React.FC = () => {
    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceWalletId: number|null = useAppSelector(state => state.instance.account?.walletId ?? null);
    const instanceWalletState: IInstanceWalletState = useAppSelector(state => state.instanceWallet);

    // useSSE on instance account wallet if it exists
    useSSE(`wallets/${instanceWalletId}`, eventData => dispatch(setInstanceWalletState(eventData)));

    // useEffect when component is mounting
    React.useEffect(() => {
        // Retrieve instance wallet
        false === instanceWalletState.loaded && null !== instanceWalletId &&
            PaymentAPIs.getWallet({ id: instanceWalletId })
            // On successful API call
            .then((data: Array<IWallet>) =>
                // Store wallet information in redux state
                data && data[0] && dispatch(setInstanceWalletState(data[0]))
            );
    }, [instanceWalletState.loaded, instanceWalletId, dispatch])

    return (
        <Stack direction='row' alignItems='center' columnGap={2}>
            <Tooltip title='Crédits bloqués' followCursor>
                <Stack sx={cssStyles.walletStructure} columnGap={1}>
                    <Typography color='lightgray' sx={{ userSelect: 'none' }}>
                        {instanceWalletState.loaded ? instanceWalletState.locked : '_.__'}
                    </Typography>
                    <RiMoneyEuroCircleLine fill='lightgray' />
                </Stack>
            </Tooltip>
            <Tooltip title='Crédits disponibles' followCursor>
                <Stack sx={cssStyles.walletStructure} columnGap={1}>
                    <Typography sx={{ userSelect: 'none' }}>
                        {instanceWalletState.loaded ? instanceWalletState.unlocked : '_.__'}
                    </Typography>
                    <RiMoneyEuroCircleFill />
                </Stack>
            </Tooltip>
        </Stack>
    );
};

export default HeadbandWallet;
