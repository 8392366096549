import React from 'react';
import SiteScheduleScheduleAPIs from '../../APIs/SiteScheduleAPIs';
import { CircularProgress, Stack, Typography } from '@mui/material';
import ISiteScheduleKeyword from '../../models/ISiteScheduleKeyword';

const SiteScheduleKeywordsListing: React.FC<{ siteScheduleId: number }> = (props) => {
    // Use of hooks
    const [siteScheduleKeywords, setSiteScheduleKeywords] = React.useState<Array<ISiteScheduleKeyword> | null>(null);

    // useEffect to fetch SiteScheduleKeywords on component mount
    React.useEffect(() => {
        null === siteScheduleKeywords &&
            SiteScheduleScheduleAPIs.getSiteScheduleKeywords({ siteSchedule: props.siteScheduleId }).then(
                (data) => null !== data && setSiteScheduleKeywords(data)
            );
    }, [siteScheduleKeywords, props.siteScheduleId]);

    return (
        <Stack>
            <Typography>{`Mots-clés renseignés pour cette programmation :`}</Typography>
            {null !== siteScheduleKeywords ? (
                siteScheduleKeywords.map((siteScheduleKeyword) => (
                    <Typography key={siteScheduleKeyword.id} marginLeft={2}>
                        - {siteScheduleKeyword.keyword.name}
                        {siteScheduleKeyword.title ? ` (H1 : ${siteScheduleKeyword.title})` : null}
                    </Typography>
                ))
            ) : (
                <CircularProgress size={20} />
            )}
        </Stack>
    );
};

export default SiteScheduleKeywordsListing;
