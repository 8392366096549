import React from 'react';
import { Field } from 'react-final-form';
import DestinationPicker from '../DestinationPicker/DestinationPicker';
import FinalInput from '../FinalComponents/FinalInput';
import FinalRadio from '../FinalComponents/FinalRadio';
import IRadioOption from '../../interfaces/IRadioOption';
import IWpCategory from '../../interfaces/IWpCategory';

const Destination: React.FC<{ onCategoriesLoaded: ((categories: Array<IWpCategory>) => void) }> = props => (
    <>
        <FinalRadio
            row
            name='destinationChoice'
            label="Destination de l'article"
            radio={[
                { value: 'intern', label: 'Interne' },
                { value: 'extern', label: 'Externe' },
            ]}
            wrapperSx={{ marginBottom: 2 }}
        />
        <Field<IRadioOption>
            name='destinationChoice'
            subscription={{ value: true }}
            render={({ input: { value: destinationChoice } }) => (
                'intern' === destinationChoice.value ? (
                    <DestinationPicker onCategoriesLoaded={props.onCategoriesLoaded} />
                ) : (
                    <FinalInput
                        name='externalUrl'
                        fullWidth
                        upperLabel
                        size='small'
                        label='Lien du site de destination'
                        placeholder='https:// ...'
                    />
                )
            )}
        />
    </>
);

export default Destination;
