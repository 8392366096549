import React from 'react';
import { useAppSelector } from '../../store/hooks';
import UserAPIs from '../../APIs/UserAPIs';
import {
    useTheme,
    Typography,
    Stack,
} from '@mui/material';
import PanelOverview from './PanelOverview';
import PanelFunds from './PanelFunds';
import PanelIncomes from './PanelIncomes';
import PanelCountdowns from './PanelCountdowns';
import PanelGlobalRating from './PanelGlobalRating';
import PanelRatingsDetail from './PanelRatingsDetail';
import Spinner from '../Spinner/Spinner';
import IPaginatorResponse from '../../interfaces/IPaginatedResponse';
import IUser from '../../models/IUser';
import cssStyles from './HomeEditorStyles';

const HomeEditor: React.FC = () => {
    // Use of MUI hooks
    const theme = useTheme();

    /// Use of hooks
    const [user, setUser] = React.useState<IUser|null>(null);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id!);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === user &&
            // Call API to retrieve editor incomes data
            UserAPIs.getUser({ id: instanceUserId, withRatings: true })
                // On successful API call
                .then((paginator: IPaginatorResponse<IUser>|null) =>
                    null !== paginator && setUser(paginator.data[0]));
    }, [user, instanceUserId])

    return (
        <>
            <Typography variant='h4' sx={cssStyles.title}>
                {`Tableau de bord`}
            </Typography>
            <Stack columnGap={4} sx={cssStyles.content}>
                {
                    null !== user ? (
                        <>
                        <Stack width={1/4} height={1} rowGap={4}>
                            <PanelOverview />
                            <PanelCountdowns />
                        </Stack>
                        <Stack width={`calc(75% - ${theme.spacing(4)})`} height={1} rowGap={4}>
                            <Stack direction='row' height={3/7}>
                                <PanelFunds />
                                <PanelIncomes />
                            </Stack>
                            <Stack direction='row' height={4/7} columnGap={4}>
                                <PanelGlobalRating rating={user.globalRating}/>
                                <PanelRatingsDetail
                                    averageRating={user.averageRating}
                                    completedRating={user.completedRating}
                                    firstTryRating={user.firstTryRating}
                                />
                            </Stack>
                        </Stack>
                        </>
                    ) : (<Spinner width={1} />)
                }
            </Stack>
        </>
    );
};

export default HomeEditor;
