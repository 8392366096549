import React from 'react';
import DisplayActivitySectors from '../DisplayActivitySectors/DisplayActivitySectors';

const ActivitySectors: React.FC<{
    selected: Array<number>,
    onSelectionChanges: (selected: Array<number>) => void,
}> = props => {
    // Use of hooks
    const [selectedActivitySectors, setSelectedActivitySectors] = React.useState<Array<number>>(props.selected);

    return (
        <DisplayActivitySectors
            selected={selectedActivitySectors}
            onSelectionChanges={(selected) => {
                setSelectedActivitySectors(selected);
                props.onSelectionChanges(selected);
            }}
            sx={{ paddingX: 4 }}
        />
    );
};

export default ActivitySectors;
