import React from 'react';
import moment from 'moment-timezone';
import { useAppSelector } from '../../store/hooks';
import OrderAPIs from '../../APIs/OrderAPIs';
import Tools from '../../helpers/Tools';
import {
    Stack,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
} from '@mui/material';
import { RiMoneyEuroCircleFill } from 'react-icons/ri';
import { FaHandshake } from 'react-icons/fa';
import RecoverFundsButton from '../RecoverFundsButton/RecoverFundsButton';
import Spinner from '../Spinner/Spinner';
import IOrder from '../../models/IOrder';

const ContractsListing: React.FC = () => {
    // Use of hooks
    const [orders, setOrders] = React.useState<Array<IOrder>|null>(null);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id!);

    // useEffect when component is mounting
    React.useEffect(() => {
        // Call API to retrieve all Orders where user is the editor
        OrderAPIs.getOrder({ editor: instanceUserId, maxResults: 30, assignedAt: 'DESC' })
            // On successful API call, store Orders in hook
            .then((data: Array<IOrder>|null) => null !== data && setOrders(data));
    }, [instanceUserId])

    return (
        <>
            <Stack direction='row' justifyContent='space-between' paddingBottom={4}>
                <Typography variant='h5'>
                    {`Mes 30 derniers contrats :`}
                </Typography>
                <RecoverFundsButton to='/dashboard/payments/form' />
            </Stack>
            {
                null !== orders ? (
                    0 < orders.length ? (
                        <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ '& > th': { fontWeight: 700 } }}>
                                        <TableCell>
                                            {'Date de signature le'}
                                        </TableCell>
                                        <TableCell>
                                            {`Numéro de contrat`}
                                        </TableCell>
                                        <TableCell>
                                            {`Contrat`}
                                        </TableCell>
                                        <TableCell>
                                            {`Mot clé de l'article`}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {`Montant`}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        orders.map((order: IOrder, index: number) => (
                                            <TableRow  key={index} sx={{ '&:last-child td': { border: 0 } }}>
                                                <TableCell>
                                                    {
                                                        order.assignedAt ?
                                                            moment(order.assignedAt).format('DD/MM/Y à H:mm')
                                                            : order.missionContract ?
                                                                'Voir contrat'
                                                                : 'Pas de contrat'
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {`N° ${Tools.getIdentifierFromUuid(order.publicId)}`}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        order.missionContract ? (
                                                            <Tooltip title='Voir le contrat'>
                                                                <IconButton
                                                                    sx={{ border: '2px solid', color: 'primary.main' }}
                                                                    onClick={() => window.open(order.missionContract!, '_blank')}
                                                                >
                                                                    <FaHandshake />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <IconButton disabled sx={{ border: '2px solid' }}>
                                                                <FaHandshake />
                                                            </IconButton>
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {order.keyword.name}
                                                </TableCell>
                                                <TableCell>
                                                    <Stack
                                                        direction='row'
                                                        justifyContent='flex-end'
                                                        alignItems='center'
                                                        columnGap={1}
                                                        sx={{ color: 'primary.main' }}
                                                    >
                                                        <Typography lineHeight={1} fontWeight={700}>
                                                                {order.price}
                                                        </Typography>
                                                        <RiMoneyEuroCircleFill fontSize='1.5rem' />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography variant='h6' component='p' align='center' color='textSecondary'>
                            {`Aucun contrat pour le moment ...`}
                        </Typography>
                    )
                ) : (<Spinner />)
            }
        </>
    );
};

export default ContractsListing;
