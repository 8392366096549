import React from 'react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setStepperIsLoading } from '../../store/slices/activeStepperSlice';
import SecurityAPIs from '../../APIs/SecurityAPIs';
import Tools from '../../helpers/Tools';
import { Button } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import ISignUpStepperData from '../../interfaces/ISignUpStepperData';
import IAccount from '../../models/IAccount';

const StepperValidation: React.FC = () => {
    // Use of hooks
    const isMounted = React.useRef<boolean>(true);

    // Use of redux
    const dispatch = useAppDispatch();
    const signUpStepperState: ISignUpStepperData = useAppSelector(state => state.activeStepper.data);

    // Use react-router-dom hooks
    const navigate = useNavigate();

    // Handle stepper final validation
    const handleFinalValidation = () => {
        // Loading while processing
        dispatch(setStepperIsLoading(true));

        // Build the body to request to our API
        const bodyRequest = {
            user: {
                civility: signUpStepperState.user!.civility!.value,
                name: signUpStepperState.user!.name,
                lastname: signUpStepperState.user!.lastname,
                email: signUpStepperState.user!.email,
                username: signUpStepperState.user!.username,
                password: signUpStepperState.user!.password,
                hasNewsletter: signUpStepperState.user?.hasNewsletter,
                birthday: moment(signUpStepperState.user!.birthday).format(),
                phonePrefix: signUpStepperState.user!.phonePrefix,
                phone: signUpStepperState.user!.phone,
            },
            company: {
                country: signUpStepperState.country!.value,
                companyType: signUpStepperState.company!.companyType!.value,
                name: signUpStepperState.company!.name,
                siret: signUpStepperState.company?.siret,
                address: signUpStepperState.company?.address,
                postalCode: signUpStepperState.company?.postalCode,
                city: signUpStepperState.company?.city,
                iban: signUpStepperState.company?.iban,
                email: signUpStepperState.company?.email,
                phonePrefix: signUpStepperState.company?.phonePrefix,
                phone: signUpStepperState.company?.phone,
            },
            activitySectors: signUpStepperState.activitySectors,
        };

        // Setup a new account
        SecurityAPIs.signUp(Tools.convertToBodyRequest(bodyRequest, true))
            // Redirect on successful call
            .then((data: IAccount|null) => null === data ?
                dispatch(setStepperIsLoading(false))
                //: navigate('/ask_account_validation', { state: bodyRequest.user.email })
                : navigate('/sign_in')
            );
    };

    // useEffect when component is unmounted
    React.useEffect(() => () => {
        // Set isMounted to false to prevent states changes
        isMounted.current = false;
    }, []);

    return (
        <Button
            variant='contained'
            onClick={handleFinalValidation}
            endIcon={<CheckCircleIcon />}
        >
            {`S'inscrire`}
        </Button>
    );
};

export default StepperValidation;
