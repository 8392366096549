import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { IInstanceAccountState } from '../../store/slices/instanceSlice';
import Invoice from '../../pdf/documents/Invoice';
import AccountAPIs from '../../APIs/AccountAPIs';
import PdfIFrame from '../PdfIFrame/PdfIFrame';
import Spinner from '../Spinner/Spinner';
import IAccount from '../../models/IAccount';

const InvoicePdf: React.FC<{
    number: string,
    amount: string|null,
    height?: string|number|undefined,
    width?: string|number|undefined,
    onBlobChange: (blob: Blob) => void,
}> = props => {
    // Use of hooks
    const [account, setAccount] = React.useState<IAccount|null>(null);

    // Use of reduxe
    const instanceAccount: IInstanceAccountState = useAppSelector(state => state.instance.account!);

    // useEffect when component is mounting
    React.useEffect(() => {
        // Call API to retrieve instance Account
        AccountAPIs.getAccount({ id: instanceAccount.id })
            // On succesfull API call, store Account data in our hook value
            .then((data: Array<IAccount>|null) => null !== data && data[0] && setAccount(data[0]))
    }, [instanceAccount.id])

    return (
        null !== account ? (
            <PdfIFrame
                document={
                    <Invoice
                        account={account}
                        number={props.number}
                        amount={props.amount}
                    />
                }
                height={props.height}
                width={props.width}
                onBlobChange={props.onBlobChange}
            />
        ) : (<Spinner width={1} />)
    );
};

export default InvoicePdf;
