import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import Tools from '../../helpers/Tools';
import {
    Stack,
    Button,
    Typography,
    FormHelperText,
    IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import FinalAutocomplete from '../FinalComponents/FinalAutocomplete';
import FinalInput from '../FinalComponents/FinalInput';
import { CategoryScheduleType } from './CategoriesSchedule';
import IKeyword from '../../models/IKeyword';

const Keywords: React.FC<{
    parentName: string,
    keywords: Array<IKeyword>|null,
}> = props => {
    // Use of redux hooks
    const dispatch = useAppDispatch();
    const fieldName = `${props.parentName}.keywordTitles`;

    return (
        <Stack gap={1}>
            <Typography gutterBottom>{`Mots-clés`}</Typography>
            <FieldArray<CategoryScheduleType['keywordTitles'][0]>
                name={fieldName}
                subscription={{}}
                render={({ fields }) => (
                    // For each category's keywords
                    <>
                        <Stack direction='row' flexWrap='wrap' rowGap={4} columnGap={2} marginBottom={2}>
                            {
                                fields.map((name, index) => (
                                    <Stack direction='row' alignItems='center' key={name}>
                                        <IconButton color='error' onClick={() => fields.remove(index)}>
                                            <CloseIcon />
                                        </IconButton>
                                        <Stack gap={1} minWidth={200}>
                                            <FinalAutocomplete
                                                name={`${name}.keyword`}
                                                label='Mot clé'
                                                size='small'
                                                disabled={!props.keywords}
                                                options={Tools.convertToSelectOptions(props.keywords, 'id', 'name')}
                                            />
                                            <FinalInput
                                                name={`${name}.title`}
                                                label='Titre H1'
                                                size='small'
                                                disabled={!props.keywords}
                                            />
                                            <FinalInput
                                                name={`${name}.imagesSearchTerm`}
                                                label="Terme de recherche d'images"
                                                size='small'
                                                disabled={!props.keywords}
                                            />
                                        </Stack>
                                    </Stack>
                                ))
                            }
                        </Stack>
                        <Stack direction='row' gap={2}>
                            <Button
                                variant='outlined'
                                disabled={!props.keywords}
                                onClick={() => fields.push({ keyword: { label: '', value: '' }, title: '', imagesSearchTerm: '' })}
                            >
                                {`Ajouter un mot-clé`}
                            </Button>
                            <Button
                                variant='outlined'
                                disabled={!props.keywords}
                                onClick={() => dispatch(openModal({ name: 'modalAddMultipleKeywords', params: fieldName }))}
                            >
                                {`Ajouter plusieurs mots-clés`}
                            </Button>
                            <Button
                                variant='outlined'
                                disabled={!props.keywords}
                                onClick={() => dispatch(openModal({ name: 'addKeywordModal', params: fieldName }))}
                            >
                                {`Créer un nouveau mot-clé`}
                            </Button>
                            <Button
                                variant='outlined'
                                disabled={!props.keywords}
                                onClick={() => dispatch(openModal({ name: 'importCsvKeywords', params: fieldName }))}
                            >
                                {`Importer des mots clés par CSV`}
                            </Button>
                        </Stack>
                    </>
                )}
            />
            <Field
                name={fieldName}
                subscription={{ error: true, touched: true }}
                render={({ meta: { error, touched } }) => (
                    touched && 'string' === typeof error ? (
                        <FormHelperText error>{error}</FormHelperText>
                    ) : null
                )}
            />
        </Stack>
    );
};

export default Keywords;
