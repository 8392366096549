import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgCollapse: React.FC<{ sx?: SxProps<Theme>|undefined, onClick?: (() => void)|undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
            onClick={() => props.onClick && props.onClick()}
        >
            <path d='m21 20c-.5523 0-1-.4477-1-1v-14c0-.55229.4477-1 1-1s1 .44771 1 1v14c0 .5523-.4477 1-1 1z' />
            <path d='m18 12c0 .5523-.4477 1-1 1h-11.58579l2.2929 2.2929c.39052.3905.39052 1.0237 0
                1.4142-.39053.3905-1.02369.3905-1.41422 0l-4-4c-.39052-.3905-.39052-1.0237 0-1.4142l4-4.00001c.39053-.39052
                1.02369-.39052 1.41422 0 .39052.39053.39052 1.02369 0 1.41422l-2.2929 2.29289h11.58579c.5523 0 1 .4477 1 1z' />
        </SvgIcon>
    );
};

export default SvgCollapse;
