import React from 'react';
import { TValidationSchema } from '../../hooks/useValidationSchema';
import { appStepperControlsHeight } from '../../configs/materialUI';
import { FormApi } from 'final-form';
import { SystemProps } from '@mui/system';
import FinalForm, { FinalFormProps } from '../FinalForm/FinalForm';
import StepperHeader, { StepperHeaderProps } from './StepperHeader';
import StepperControls from './StepperControls';

type FinalStepProps = {
    children: React.ReactElement,
    name?: string|undefined,
    validationSchema?: TValidationSchema|undefined,
};

export function FinalStep(props: FinalStepProps){
    return React.cloneElement(props.children, { name: props.name, validationSchema: props.validationSchema });
};

type FinalStepperFormProps = {
    orientationBreakpoint?: string|undefined,
    headerWidth?: string|number|undefined,
    formProps?: SystemProps|undefined,
};

export function FinalStepperForm<T = unknown>(props: Omit<FinalFormProps<T>, 'validationSchema'>&FinalStepperFormProps&Omit<StepperHeaderProps, 'activeStep'|'onStepClick'|'stepsElements'>) {
    // Use of hooks
    const [activeStep, setActiveStep] = React.useState(0);
    const childrenArray = React.Children.toArray(props.children);
    const activeChild: any = childrenArray[activeStep];
    const isLastStep = activeStep === childrenArray.length - 1;

    // Function used to handle stepper control
    const handleControlAction = (action: 'inc'|'desc', form: FormApi<any>) => {
        if ('inc' === action) {
            // Either move to next step or submit entire form
            isLastStep ?
                form.submit()
                : setActiveStep(Math.min(activeStep + 1, childrenArray.length));
        } else {
            setActiveStep(Math.max(activeStep - 1, 0));
        }
    };

    return (
        <>
            <StepperHeader
                activeStep={activeStep}
                stepsElements={childrenArray}
                orientationBreakpoint={props.orientationBreakpoint}
                headerWidth={props.headerWidth}
                onStepClick={setActiveStep}
            />
            <FinalForm<T>
                initialValues={props.initialValues}
                validateSchema={activeChild.props.validationSchema}
                onSubmit={props.onSubmit}
                mutators={props.mutators}
                {...props.formProps}
                // Padding to prevent text going behind fixed controls
                paddingBottom={appStepperControlsHeight}
            >
                {activeChild}
                <StepperControls
                    awareOfDashboardMenu
                    stepHasValidationSchema={Boolean(activeChild.props.validationSchema)}
                    isLastStep={isLastStep}
                    activeStep={activeStep}
                    stepName={activeChild.props.name}
                    onAction={handleControlAction}
                />
            </FinalForm>
        </>
    );
};
