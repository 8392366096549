import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgImport: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#F2F2F2' d='M408,512H102.933C91.2,512,81.6,502.4,81.6,490.667V21.333C81.6,9.6,91.2,0,102.933,0h306.133C420.8,0,430.4,9.6,430.4,21.333v469.333C429.333,502.4,419.733,512,408,512z' />
            <rect x='117.87' y='54.4' fill='#84DBFF' width='276.27' height='366.93' />
            <g>
                <path fill='#CDD6E0' d='M285.333,35.2H225.6c-4.267,0-7.467-3.2-7.467-7.467l0,0c0-4.267,3.2-7.467,7.467-7.467h59.733c4.267,0,7.467,3.2,7.467,7.467l0,0C292.8,32,289.6,35.2,285.333,35.2z' />
                <circle fill='#CDD6E0' cx='255.47' cy='466.13' r='30.933' />
            </g>
            <path fill='#FFFFFF' d='M336.533,145.067c-3.2-21.333-22.4-38.4-44.8-38.4c-3.2,0-6.4,0-9.6,1.067c-10.667-18.133-29.867-29.867-52.267-29.867C195.733,77.867,168,105.6,168,139.733c0,2.133,0,4.267,1.067,6.4c-16,6.4-27.733,21.333-27.733,39.467l0,0c0,23.467,19.2,42.667,42.667,42.667h140.8c23.467,0,42.667-19.2,42.667-42.667l0,0C368.533,166.4,354.667,150.4,336.533,145.067z' />
            <polygon fill='#FF7058' points='280,249.6 280,170.667 230.933,170.667 230.933,249.6 197.867,249.6 255.467,315.733 313.067,249.6 ' />
            <rect x='153.07' y='342.4' fill='#54C0EB' width='203.73' height='29.867' />
        </SvgIcon>
    );
};

export default SvgImport;
