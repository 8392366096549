import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import { Numbers as NumbersIcon, Percent as PercentIcon } from '@mui/icons-material';
import { ImEye } from 'react-icons/im';
import ListingRowData from '../ListingRowData/ListingRowData';
import PostActionButton from '../PostActionButton/PostActionButton';
import IRejectedPost from '../../models/IRejectedPost';
import cssStyles from './PostsFailedListingStyles';

const FailedPost: React.FC<{ rejectedPost: IRejectedPost }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();

    return (
        <Paper sx={cssStyles.postPaper}>
            <Stack sx={cssStyles.container}>
                <Stack sx={cssStyles.leftSide}>
                    <Typography variant='body2' color='textSecondary'>
                        {props.rejectedPost.keyword}
                    </Typography>
                </Stack>
                <Divider orientation='vertical' flexItem />
                <Stack sx={cssStyles.rightSide}>
                    <ListingRowData
                        icon={PercentIcon}
                        title='Optimisation :'
                        content={`${props.rejectedPost.optimization}%`}
                    />
                    <ListingRowData
                        icon={NumbersIcon}
                        title='Nombre de mots :'
                        content={props.rejectedPost.nbrWords}
                    />
                    <PostActionButton
                        icon={ImEye}
                        tooltip="Voir l'article"
                        onClick={() => dispatch(openModal({ name: 'viewRejectedPostModal', params: props.rejectedPost.id }))}
                    />
                </Stack>
            </Stack>
        </Paper>
    );
};

export default FailedPost;
