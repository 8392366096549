import React from 'react';
import {
    Stack,
    Typography,
} from '@mui/material';
import {
    ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';

const EvolutionChip: React.FC<{ evolution: number }> = props => {
    // Define some variables
    const backgroundColor = 0 < props.evolution ? 'success.main' : 0 === props.evolution ? 'lightgrey' : 'error.main';
    const rotationArrow = 0 < props.evolution ? undefined : 0 === props.evolution ? 'rotate(90deg)' : 'rotate(180deg)';

    return (
        <Stack
            direction='row'
            alignItems='center'
            color='white'
            paddingRight={1}
            sx={{ backgroundColor: backgroundColor, borderRadius: 1 }}
        >
            <ArrowDropUpIcon fontSize='small' sx={{ transform: rotationArrow }} />
            <Typography variant='body2'>
                {0 < props.evolution ? props.evolution : -props.evolution}
            </Typography>
        </Stack>
    )
};

export default EvolutionChip;
