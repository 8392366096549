import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { Editor } from '@tiptap/react';
import {
    ToggleButton,
    ToggleButtonGroup,
    Stack,
    Typography,
} from '@mui/material';
import {
    FormatBold as FormatBoldIcon,
    FormatItalic as FormatItalicIcon,
    FormatUnderlined as FormatUnderlinedIcon,
    LinkOff as LinkOffIcon,
    Link as LinkIcon,
    FormatListBulleted as FormatListBulletedIcon,
    FormatListNumbered as FormatListNumberedIcon,
    Undo as UndoIcon,
    Image as ImageIcon,
    YouTube as YouTubeIcon,
} from '@mui/icons-material';
import ModalImportVideo from '../Modal/ModalImportVideo';
import ModalImportImage from '../Modal/ModalImportImage';
import ToolbarAwareButtons from './ToolbarAwareButtons';
import cssStyles from './TextEditorStyles';

const Toolbar: React.FC<{ editor: Editor, allowHeadingOne?: boolean|undefined }> = props => {
    // Use of redux hook
    const dispatch = useAppDispatch();

    // Callback used to insert link on selection
    const handleSetLink = React.useCallback(() => {
        // Prompt dialog window to input an url
        const url: string|null = window.prompt('URL cible du lien :');
        // Dialog successful
        url && props.editor.chain().focus().setLink({ href: url }).run();
    }, [props.editor]);

    // Callback to add an image
    const handleAddImage = React.useCallback((src: string, alt: string, title: string) => {
        props.editor.chain().focus().setImage({ src, alt, title }).run();
    }, [props.editor]);

    // Callback to add a youtube video
    const handleAddVideo = React.useCallback((src: string, width: number, height: number) => {
        props.editor.commands.setYoutubeVideo({
            src,
            width,
            height,
        });
    }, [props.editor]);

    // useMemo of component which listen to redux state
    const MemoizedToolbarAwareButtons = React.useMemo(() => {
        return <ToolbarAwareButtons />
    }, [])

    return (
        <>
            <ModalImportVideo modalTitle='Importer une vidéo Youtube' onValidation={handleAddVideo} />
            <ModalImportImage modalTitle='Importer une image' onValidation={handleAddImage} />
            <Stack direction='row' flexWrap='wrap' alignItems='center' gap={2} width={1}>
                <ToggleButtonGroup size='small'>
                    <ToggleButton
                        value='bold'
                        selected={props.editor.isActive('bold')}
                        onClick={() => props.editor.chain().focus().toggleBold().run()}
                    >
                        <FormatBoldIcon/>
                    </ToggleButton>
                    <ToggleButton
                        value='italic'
                        selected={props.editor.isActive('italic')}
                        onClick={() => props.editor.chain().focus().toggleItalic().run()}
                    >
                        <FormatItalicIcon />
                    </ToggleButton>
                    <ToggleButton
                        value='underline'
                        selected={props.editor.isActive('underline')}
                        onClick={() => props.editor.chain().focus().toggleUnderline().run()}
                    >
                        <FormatUnderlinedIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup size='small'>
                    {
                        props.allowHeadingOne && (
                            <ToggleButton
                                value='h1'
                                selected={props.editor.isActive('heading', { level: 1 })}
                                onClick={() => props.editor.chain().focus().toggleHeading({ level: 1 }).run()}
                                sx={cssStyles.headingToolbarButton}
                            >
                                <Typography fontWeight='bold' sx={cssStyles.headingToolbarButtonText}>
                                    {`H1`}
                                </Typography>
                            </ToggleButton>
                        )
                    }
                    <ToggleButton
                        value='h2'
                        selected={props.editor.isActive('heading', { level: 2 })}
                        onClick={() => props.editor.chain().focus().toggleHeading({ level: 2 }).run()}
                        sx={cssStyles.headingToolbarButton}
                    >
                        <Typography fontWeight='bold' sx={cssStyles.headingToolbarButtonText}>
                            {`H2`}
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value='h3'
                        selected={props.editor.isActive('heading', { level: 3 })}
                        onClick={() => props.editor.chain().focus().toggleHeading({ level: 3 }).run()}
                        sx={cssStyles.headingToolbarButton}
                    >
                        <Typography fontWeight='bold' sx={cssStyles.headingToolbarButtonText}>
                            {`H3`}
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value='h4'
                        selected={props.editor.isActive('heading', { level: 4 })}
                        onClick={() => props.editor.chain().focus().toggleHeading({ level: 4 }).run()}
                        sx={cssStyles.headingToolbarButton}
                    >
                        <Typography fontWeight='bold' sx={cssStyles.headingToolbarButtonText}>
                            {`H4`}
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup exclusive size='small'>
                    <ToggleButton
                        value='link'
                        selected={props.editor.isActive('link')}
                        onClick={handleSetLink}
                    >
                        <LinkIcon />
                    </ToggleButton>
                    <ToggleButton
                        value='unlink'
                        disabled={!props.editor.isActive('link')}
                        onClick={() => props.editor.chain().focus().unsetLink().run()}
                    >
                        <LinkOffIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup exclusive size='small'>
                    <ToggleButton
                        value='bullet-list'
                        selected={props.editor.isActive('bulletList')}
                        onClick={() => props.editor.chain().focus().toggleBulletList().run()}>
                        <FormatListBulletedIcon />
                    </ToggleButton>
                    <ToggleButton
                        value='ordered-list'
                        selected={props.editor.isActive('orderedList')}
                        onClick={() => props.editor.chain().focus().toggleOrderedList().run()}
                    >
                        <FormatListNumberedIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup exclusive size='small'>
                    <ToggleButton
                        value='undo'
                        onClick={() => props.editor.chain().focus().undo().run()}
                    >
                        <UndoIcon />
                    </ToggleButton>
                    <ToggleButton
                        value='redo'
                        onClick={() => props.editor.chain().focus().redo().run()}
                    >
                        <UndoIcon sx={{ transform: 'scaleX(-1)' }} />
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup exclusive size='small'>
                    <ToggleButton
                        value='image'
                        onClick={() => dispatch(openModal({ name: 'importImageModal' }))}
                    >
                        <ImageIcon />
                    </ToggleButton>
                    <ToggleButton
                        value='video'
                        onClick={() => dispatch(openModal({ name: 'importVideoModal' }))}
                    >
                        <YouTubeIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
                {MemoizedToolbarAwareButtons}
            </Stack>
        </>
    );
};

export default Toolbar;
