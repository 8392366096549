import React from 'react';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { Formik, Form, FormikProps } from 'formik';
import { Stack, Typography } from '@mui/material';
import FormikInput from '../FormikComponent/FormikInput';
import FormikPhone from '../FormikComponent/FormikPhone';
import SelectCompanyType from '../SelectEntities/SelectCompanyType';
import ISignUpStepperData from '../../interfaces/ISignUpStepperData';
import ICountry from '../../models/ICountry';

const SecondaryInfo: React.FC<{ formikFormId: string }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();
    const signUpStepperState: ISignUpStepperData = useAppSelector(state => state.activeStepper.data);
    const countries: Array<ICountry> = useAppSelector(state => state.countries.data!);

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        name: Yup
            .string()
            .required("Veuillez spécifier le nom de l'entreprise"),
        companyType: Yup
            .mixed()
            .required("Veuillez spécifier le type de l'entreprise"),
        email: Yup
            .string()
            .email('Veuillez rentrer une adresse email valide'),
        siret: Yup
            .string()
            .length(14, 'Veuillez renseigner un numéro de SIRET valide'),
        phone: Yup
            .string()
            .length(9, 'Veuillez renseigner un numéro de téléphone valide')
            .nullable(),
    });

    // Define yup initial values for formik form
    const initialValues: any = {
        name: signUpStepperState.company?.name ?? '',
        companyType: signUpStepperState.company?.companyType ?? null,
        siret: signUpStepperState.company?.siret ?? '',
        address: signUpStepperState.company?.address ?? '',
        postalCode: signUpStepperState.company?.postalCode ?? '',
        city: signUpStepperState.company?.city ?? '',
        iban: signUpStepperState.company?.iban ?? '',
        email: signUpStepperState.company?.email ?? '',
        phonePrefix: signUpStepperState.company?.phonePrefix ?? countries.find(country => country.id === Number(signUpStepperState!.country!.value))!.phonePrefix,
        phone: signUpStepperState.company?.phone ?? '',
    };

    // Callback to hande formik submit
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Store values under data field of active stepper redux state & increment active step
        dispatch(setActiveStepperData({ data: { company: values }, action: 'inc' }));
    }, [dispatch]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form id={props.formikFormId}>
                    <Stack paddingX={{ md: 15 }} rowGap={2}>
                        <Typography variant='h6'>
                            {`Information de l'entreprise`}
                        </Typography>
                        <SelectCompanyType
                            name='companyType'
                            label='Statut juridique'
                        />
                        <Stack direction={{ xs: 'column', md: 'row' }} columnGap={2} rowGap={2}>
                            <FormikInput
                                name='name'
                                label="Nom"
                            />
                            <FormikInput
                                name='siret'
                                label="Numéro SIRET (falcultatif)"
                            />
                        </Stack>

                        <Typography variant='h6'>
                            {`Information de localisation et de contact (falcultatif)`}
                        </Typography>
                        <FormikInput
                            name='address'
                            label="Adresse"
                        />
                        <Stack direction={{ xs: 'column', md: 'row' }} columnGap={2} rowGap={2}>
                            <FormikInput
                                name='postalCode'
                                label="Code postal"
                            />
                            <FormikInput
                                name='city'
                                label="Ville"
                            />
                        </Stack>
                        <Stack direction={{ xs: 'column', md: 'row' }} columnGap={2} rowGap={2}>
                            <FormikInput
                                name='email'
                                label="Email"
                            />
                            <FormikPhone
                                formikProps={formikProps}
                                prefixName='phonePrefix'
                                phoneName='phone'
                                label='Numéro de téléphone'
                            />
                        </Stack>
                        <Typography variant='body2' color='textSecondary' paddingTop={2}>
                            {`Les champs facultatifs pourront être renseignés plus tard.`}
                        </Typography>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default SecondaryInfo;
