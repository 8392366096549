import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { clearActiveStepper, setActiveStepperData } from '../../store/slices/activeStepperSlice';
import Stepper from '../Stepper/Stepper';
import Duplicate from './Duplicate';
import ImageSelection, { imageSelectionOptions } from './ImageSelection';
import MainInfo from './MainInfo';
import Summary from './Summary';
import SecondaryInfo, { privacyOptions, publicOptions } from './SecondaryInfo';
import Spinner from '../Spinner/Spinner';
import Share from './Share';
import StepperValidation from './StepperValidation';
import IRadioOption from '../../interfaces/IRadioOption';
import IOrder from '../../models/IOrder';

const OrderForm: React.FC = () => {
    // Use of hooks
    // Loading while data is being handled
    const [loading, isLoading] = React.useState<boolean>(true);
    const stepValidationRef = React.useRef<(() => void)|undefined>(undefined);

    // Use of redux
    const dispatch = useAppDispatch();

    // Use react-router-dom hooks
    const location = useLocation();
    const order: IOrder|null = location.state as IOrder|null;

    // useEffect when component is mounting
    React.useEffect(() => {
        // If an Order is given by location state (ex: edit an existing order)
        const data = null !== order ? {
            project: { label: order.project.name, value: order.project.id },
            idOrder: order.id,
            keyword: { label: order.keyword.name, value: order.keyword.id },
            title: order.title,
            metaDesc: order.metaDesc,
            postType: { label: order.postType.name, value: order.postType.id },
            activitySector: { label: order.activitySector.name, value: order.activitySector.id },
            minOptimization: order.minOptimization,
            freeOptimization: null === order.minOptimization,
            minNbrWords: order.minNbrWords,
            syntaxLevel: { label: order.syntaxLevel.name, value: order.syntaxLevel.id },
            destinationWebsite: order.site && order.siteCategory ? {
                thematic: { label: order.site.thematic.name, value: order.site.thematic.id },
                website: { label: order.site.domain, value: order.site.id },
                category: order.siteCategory,
            } : undefined,
            autoPublish: order.autoPublish,
            toPublishAt: order.toPublishAt,
            deadlineAt: order.deadlineAt,
            brief: order.brief,
            mandatoryLinks: order.links,
            privacy: true === order.isPublic ? privacyOptions[0] : privacyOptions[1],
            publicChoice: true === order.isPublic ?
                    true === order.isByApplyment ?
                        // Applyment
                        publicOptions[0]
                        : true === order.isFirstServed ?
                            // First served
                            publicOptions[1]
                            // Proposition
                            : publicOptions[2]
                : undefined,
            editor: order.editor,
            price: order.price,
            imageChoice: imageSelectionOptions.find((option: IRadioOption) =>
                option.value === (
                    order!.imageKeyword ?
                        'kw'
                        : null !== order!.imageUrl ? 'file' : 'none'
                )
            ),
            imageKeyword: order.imageKeyword,
            imageUploaded: order.imageUrl,
            imageBank: order.imageUrl,
            duplicates: undefined,
            shares: undefined,
        } : null;

        // Update redux store if needed
        null !== data && dispatch(setActiveStepperData({ data: data }));
        // Stop loading
        isLoading(false);
    }, [order, dispatch]);

    // useEffect when component is unmounted
    React.useEffect(() => () => {
        // Clear active stepper redux state
        dispatch(clearActiveStepper());
    }, [dispatch]);

    return (
        false === loading ? (
            <Stepper
                awareOfDashboardMenu
                steps={[
                    {
                        label: 'Info. principales',
                        component: (<MainInfo formikFormId='mainInfo' />),
                        formikFormId: 'mainInfo',
                    },
                    {
                        label: 'Info. secondaires',
                        component: (<SecondaryInfo formikFormId='secondaryInfo' />),
                        formikFormId: 'secondaryInfo',
                    },
                    {
                        label: 'Définir une image',
                        component: (<ImageSelection formikFormId='imageSelection' />),
                        formikFormId: 'imageSelection',
                    },
                    {
                        label: 'Partager',
                        component: (<Share stepValidationRef={stepValidationRef} />),
                        stepValidationRef: stepValidationRef,
                    },
                    {
                        label: 'Dupliquer',
                        component: (<Duplicate formikFormId='duplicateOrder' />),
                        formikFormId: 'duplicateOrder',
                    },
                    {
                        label: 'Résumé & validation',
                        component: (<Summary />),
                    },
                ]}
                validationComponent={(<StepperValidation />)}
            />
        ) : (<Spinner />)
    );
};

export default OrderForm;
