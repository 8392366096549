import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ICsvData from '../../interfaces/ICsvData';

const DisplayCsv: React.FC<{
    csvHeader: Array<string>,
    csvData: ICsvData,
    hasDefinedHeaderKeys: boolean,
    maxDisplayedRows?: number|undefined,
}> = ({ csvHeader, csvData, hasDefinedHeaderKeys, maxDisplayedRows }) => {
    const dataLength = csvData.length;
    const displayedData = maxDisplayedRows ? csvData.slice(0, maxDisplayedRows) : csvData;

    return (
        <>
            <TableContainer component={Paper}>
                <Table stickyHeader size='small'>
                    <TableHead>
                        <TableRow>
                            {
                                csvHeader.map(key => (
                                    <TableCell key={key}>{hasDefinedHeaderKeys ? key : `Colonne ${Number(key) + 1}`}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedData.map((row, rowIndex) => (
                            <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                {
                                    Object.values(row).map((column, columnIndex) => (
                                        <TableCell key={rowIndex + columnIndex}>
                                            {
                                                Array.isArray(column) ?
                                                    column.map((data, index) => (
                                                        <React.Fragment key={index}>
                                                            {data}
                                                            <br />
                                                        </React.Fragment>
                                                    ))
                                                    : column
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                0 < dataLength && (
                    <Typography variant='caption'>
                        {`Nombre de lignes total : ${dataLength}`}
                        {maxDisplayedRows && ` (${maxDisplayedRows} affichées)`}
                    </Typography>
                )
            }
        </>
    );
};

export default DisplayCsv;
