import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    fakePostBody: {
        paddingBottom: 2,
        fontFamily: 'Redacted',
        lineHeight: 0.9,
        userSelect: 'none',
    },
    secondaryCard: {
        paddingX: 4,
        paddingY: 3
    },
    summaryLinks: {
        color: 'primary.main',
        textDecorationLine: 'underline',
        textDecorationColor: 'primary.main',
    },
    summaryEditorAvatar: {
        height: 50,
        width: 50,
        marginX: 2,
        fontWeight: 700,
    },
};

export default cssStyles;
