import React from 'react';
import { Field } from 'react-final-form';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import {
    SxProps,
    Theme,
    FormControl,
    FormHelperText,
    TextField,
    TextFieldProps,
    InputAdornment,
    InputProps,
    FilledInputProps,
    OutlinedInputProps,
} from '@mui/material';
import FinalUpperLabel from './FinalUpperLabel';

type FinalInputProps = {
    name: string, // force this prop to not be undefined
    upperLabel?: boolean|undefined,
    wrapperSx?: SxProps<Theme>|undefined,
    warnings?: ((inputValue: any) => Array<{ condition: boolean, text: string }>)|undefined,
    prefix?: string|undefined,
    suffix?: string|undefined,
    numberFormatProps?: NumberFormatPropsBase<TextFieldProps>|undefined,
};

const FinalInput: React.FC<TextFieldProps&FinalInputProps> =
({ upperLabel, label, sx, helperText, warnings, wrapperSx, InputProps, ...props }) => {
    // Use of hooks
    const inputRef = React.useRef<HTMLInputElement|null>(null);

    // Define InputProps from given props
    const customInputProps: Partial<InputProps>|Partial<FilledInputProps>|Partial<OutlinedInputProps>|undefined =
        props.prefix || props.suffix ? {
            ...(props.prefix ? {
                startAdornment: (
                    <InputAdornment position='start' onClick={() => inputRef.current && inputRef.current.focus()}>
                        {props.prefix}
                    </InputAdornment>)
            } : undefined),
            ...(props.suffix ? {
                endAdornment: (
                    <InputAdornment position='end' onClick={() => inputRef.current && inputRef.current.focus()}>
                        {props.suffix}
                    </InputAdornment>)
            } : undefined),
        } : undefined;

    return (
        <FormControl fullWidth={props.fullWidth} sx={wrapperSx}>
            {upperLabel && (<FinalUpperLabel {...props} label={label} />)}
            <Field
                name={props.name}
                subscription={{ value: true, error: true, touched: true }}
                render={({ input: { onChange, value, ...inputFinalFormProps }, meta: { error, touched }}) => (
                    <>
                        {
                            props.numberFormatProps ? (
                                <NumberFormat
                                    inputRef={inputRef}
                                    {...inputFinalFormProps}
                                    {...props.numberFormatProps}
                                    value={value}
                                    type='text'
                                    autoComplete='off'
                                    customInput={TextField}
                                    label={!upperLabel ? label : undefined}
                                    error={error && touched}
                                    size={props.size}
                                    placeholder={props.placeholder}
                                    InputProps={{
                                        ...InputProps,
                                        ...customInputProps,
                                        disabled: props.disabled,
                                    }}
                                    // Need to forward value change function
                                    onValueChange={({ value }) => onChange(value)}
                                    sx={sx}
                                />
                            ) : (
                                <TextField
                                    inputRef={inputRef}
                                    {...inputFinalFormProps}
                                    {...props}
                                    value={value}
                                    label={!upperLabel ? label : undefined}
                                    error={error && touched}
                                    InputLabelProps={'date' === props.type ? { shrink: true } : undefined}
                                    InputProps={{ ...InputProps, ...customInputProps }}
                                    onChange={onChange}
                                    sx={sx}
                                />
                            )
                        }
                        {
                            touched && error ?
                                (<FormHelperText error>{error}</FormHelperText>)
                            : touched && warnings && warnings(value).some(warning => true === warning.condition) ?
                                warnings(value).map((warning, index: number) => (
                                    true === warning.condition && (
                                        <FormHelperText key={index} component='span' sx={{ color: 'warning.main' }}>
                                            {warning.text}
                                        </FormHelperText>
                                    )
                                ))
                            : 'string' === typeof helperText ?
                                (<FormHelperText>{helperText}</FormHelperText>)
                                : helperText ?? null
                        }
                    </>
                )}
            />
        </FormControl>
    );
};

export default FinalInput;
