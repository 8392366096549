import { AxiosResponse, AxiosError } from 'axios';
import Api from '../helpers/Api';
import Toaster from '../helpers/Toaster';
import IApiError from '../interfaces/IApiError';

/**
 * APIs class.
 */
export default class GenericAPIs {
    /**
     * Get something from API.
     *
     * @param resourceUrl
     * @param apiKey
     * @param query
     * @param successToast
     * @param errorToast
     */
    public static async apiGetCall(resourceUrl: string, apiKey: string|null = null, query: any = null, successToast: boolean = false, errorToast: boolean = true) {
        // Call API baseUrl with GET method on /url
        return Api.get(resourceUrl, { params: query, headers: { ...(apiKey && { 'X-Api-Key': apiKey }) } })
            .then((response: AxiosResponse) => {
                // If we have a response, create toast if necessary
                successToast && this.createSuccessCallToast(response);

                // We manage the fact that the response can be null
                null === response.data && this.createEmptyCallToast();

                // We also return this response
                return response.data;
            })
            .catch((error: AxiosError) => {
                // If an error occurs, we create an error toast
                errorToast && this.createErrorCallToast(error);

                return null;
            });
    }

    /**
     * Delete something from API.
     *
     * @param resourceUrl
     * @param apiKey
     * @param successToast
     */
    public static async apiDeleteCall(resourceUrl: string, apiKey: string|null = null, successToast: boolean = false) {
        // Call API baseUrl with DELETE method on /url
        return Api.delete(resourceUrl, { headers: { ...(apiKey && { 'X-Api-Key': apiKey }) } })
            .then((response: AxiosResponse) => {
                // If we have a response, create toast if necessary
                successToast && this.createSuccessCallToast(response);

                // We also return true as a response
                return true;
            })
            .catch((error: AxiosError) => {
                // If an error occurs, we create an error toast
                this.createErrorCallToast(error);

                return null;
            });
    }

    /**
     * Post something on API.
     *
     * @param resourceUrl
     * @param body
     * @param apiKey
     * @param config
     * @param successToast
     */
    public static async apiPostCall(resourceUrl: string, body: any, apiKey: string|null = null, successToast: boolean = false) {
        // Call API baseUrl with POST method on /url
        return Api.post(resourceUrl, body, { headers: { ...(apiKey && { 'X-Api-Key': apiKey }) } })
            .then((response: AxiosResponse) => {
                // If we have a response, create toast if necessary
                successToast && this.createSuccessCallToast(response);

                // We manage the fact that the response can be null
                null === response.data && this.createEmptyCallToast();

                // We also return this response
                return response.data;
            })
            .catch((error: AxiosError) => {
                // If an error occurs, we create an error toast
                this.createErrorCallToast(error);

                return null;
            });
    }

    /**
     * Post something on API with FormData.
     *
     * @param resourceUrl
     * @param formData
     * @param apiKey
     * @param config
     * @param successToast
     */
    public static async apiPostFormDataCall(resourceUrl: string, formData: FormData, apiKey: string|null = null, successToast: boolean = false) {
        // Call API baseUrl with POST method on /url
        return Api.post(resourceUrl, formData, { headers: { 'Content-Type': 'multipart/form-data', ...(apiKey && { 'X-Api-Key': apiKey }) } })
            .then((response: AxiosResponse) => {
                // If we have a response, create toast if necessary
                successToast && this.createSuccessCallToast(response);

                // We manage the fact that the response can be null
                null === response.data && this.createEmptyCallToast();

                // We also return this response
                return response.data;
            })
            .catch((error: AxiosError) => {
                // If an error occurs, we create an error toast
                this.createErrorCallToast(error);

                return null;
            });
    }

    /**
     * Put something on API.
     *
     * @param resourceUrl
     * @param additionalPath
     * @param body
     * @param apiKey
     * @param successToast
     */
    public static async apiPutCall(resourceUrl: string, additionalPath: string|null, body: any, apiKey: string|null = null, successToast: boolean = false) {
        // Customize url depending on params
        const finalUrl = `${resourceUrl}${additionalPath ? `/${additionalPath}` : ''}`;

        // Call API baseUrl with PUT method on /url
        return Api.put(finalUrl, body, { headers: { ...(apiKey && { 'X-Api-Key': apiKey }) } })
            .then((response: AxiosResponse) => {
                // If we have a response, create toast if necessary
                successToast && this.createSuccessCallToast(response);

                // We manage the fact that the response can be null
                null === response.data && this.createEmptyCallToast();

                // We also return this response
                return response.data;
            })
            .catch((error: AxiosError) => {
                // If an error occurs, we create an error toast
                this.createErrorCallToast(error);

                return null;
            });
    }

    /**
     * Patch something on API.
     *
     * @param resourceUrl
     * @param additionalPath
     * @param body
     * @param apiKey
     * @param successToast
     */
    public static async apiPatchCall(resourceUrl: string, additionalPath: string|null, body: any, apiKey: string|null = null, successToast: boolean = false) {
        // Customize url depending on params
        const finalUrl = `${resourceUrl}${additionalPath ? `/${additionalPath}` : ''}`;

        // Call API baseUrl with PUT method on /url
        return Api.patch(finalUrl, body, { headers: { ...(apiKey && { 'X-Api-Key': apiKey }) } })
            .then((response: AxiosResponse) => {
                // If we have a response, create toast if necessary
                successToast && this.createSuccessCallToast(response);

                // We manage the fact that the response can be null
                null === response.data && this.createEmptyCallToast();

                // We also return this response
                return response.data;
            })
            .catch((error: AxiosError) => {
                // If an error occurs, we create an error toast
                this.createErrorCallToast(error);

                return null;
            });
    }

    /**
     * Create success toast based on an axios response.
     *
     * @param response
     */
    protected static createSuccessCallToast(response: AxiosResponse) {
        Toaster.createToast(
            "Succès de l'appel API",
            `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`,
            'success'
        );
    }

    /**
     * Create error toast based on an axios response.
     *
     * @param response
     */
    protected static createErrorCallToast(error: AxiosError) {
        error.response ?
            error.response.data && error.response.data.errors ?
                error.response.data.errors.forEach((error: IApiError) =>
                    Toaster.createToast("Erreur lors de l'appel API", error.message, 'error', 10000)
                ) : Toaster.createToast(
                    "Erreur lors de l'appel API",
                    `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`,
                    'error',
                    10000
                )
            : Toaster.createToast(
                "Erreur lors de l'appel API",
                'Une erreur interne est survenue. Prenez contact avec votre administrateur'
            );
    }

    /**
     * Create empty warn toast based on an axios response.
     */
    protected static createEmptyCallToast() {
        Toaster.createToast('Problème de réponse API', 'La réponse est vide', 'warning');
    }
};
