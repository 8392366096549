import React from 'react';
import { Field } from 'formik';
import {
    SxProps,
    Theme,
    FormControl,
    FormLabel,
    RadioGroup,
    Stack,
    FormControlLabel,
    Radio,
    FormHelperText,
} from '@mui/material';
import IRadioOption from '../../interfaces/IRadioOption';
import IFormikFieldProps from '../../interfaces/IFormikFieldProps';

const FormikRadio: React.FC<{
    name: string,
    radio: Array<IRadioOption>,
    label?: string|undefined,
    direction?: 'row'|'column'|undefined,
    disabled?: boolean|undefined,
    fullWidth?: boolean|undefined,
    size?: 'small'|'medium'|undefined,
    sx?: SxProps<Theme>|undefined,
    onChange?: ((option: IRadioOption) => void)|undefined,
}> = props => {
    return (
        <Field name={props.name}>
            {({ form, field, meta }: IFormikFieldProps) => (
                <FormControl
                    fullWidth={props.fullWidth ?? true}
                    disabled={props.disabled}
                    sx={props.sx}
                >
                    {
                        props.label && (
                            <FormLabel sx={{ fontSize: { xs: 16 } }}>
                                {props.label}
                            </FormLabel>
                        )
                    }
                    <RadioGroup
                        {...field}
                        // field.value is an object IRadioOption
                        value={field.value.value}
                        row={props.direction ? 'row' === props.direction : false}
                        onChange={(_, value) => {
                            // Obviously, following value can not be unknown
                            const option = props.radio.find(option => option.value === value)!;
                            form.setFieldValue(props.name, option);
                            props.onChange && props.onChange(option);
                        }}
                    >
                        {
                            props.radio.map((radio: IRadioOption, index: number) => (
                                <Stack
                                    key={index}
                                    paddingBottom={
                                        'row' === props.direction ?
                                            undefined
                                            : index === props.radio.length - 1 ? 0 : 1
                                    }
                                    paddingRight={3}
                                >
                                    <FormControlLabel
                                        value={radio.value}
                                        label={radio.label}
                                        disabled={radio.disabled}
                                        control={<Radio size={props.size} />}
                                    />
                                    {
                                        radio.helperText && (
                                            <FormHelperText component='span' sx={{ margin: 0 }}>
                                                {radio.helperText}
                                            </FormHelperText>
                                        )
                                    }
                                </Stack>
                            ))
                        }
                    </RadioGroup>
                    {
                        Boolean(meta.touched && meta.error) && (
                            <FormHelperText error component='span' sx={{ margin: 0 }}>
                                {meta.error}
                            </FormHelperText>
                        )
                    }
                </FormControl>
            )}
        </Field>
    );
};

export default FormikRadio;
