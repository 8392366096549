import React from 'react';
import { useAppSelector } from '../../store/hooks';
import IPostFormStepperData from '../../interfaces/IPostFormStepperData';
import {
    Card,
    CardContent,
    CardMedia,
    Divider,
    Typography,
} from '@mui/material';
import PurifiedHtml from '../PurifiedHtml/PurifiedHtml';

const Summary: React.FC = () => {
    const postFormStepperData: IPostFormStepperData = useAppSelector(state => state.activeStepper.data);

    return (
        <Card>
            <CardMedia
                component='img'
                image={postFormStepperData.imageUrl}
                height={250}
            />
            <CardContent>
                <Typography variant='h5' fontWeight='bold'>{postFormStepperData.title}</Typography>
                <Divider sx={{ marginY: 2 }} />
                <PurifiedHtml html={postFormStepperData.html ?? ''} />
            </CardContent>
        </Card>
    );
};

export default Summary;
