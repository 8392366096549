import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Button, Stack } from '@mui/material';
import GenericModal from './GenericModal';
import FormikInput from '../FormikComponent/FormikInput';
import FormikSelect from '../FormikComponent/FormikSelect';

const ModalLinkCreator: React.FC<{
    defaultAnchor?: string|undefined,
    defaultUrl?: string|undefined,
    maxPosition: number,
    onClose?: (() => void)|undefined,
    onValidation?: ((values: any, modalParams: any) => void)|undefined,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>('linkCreator');

    // Use of redux
    const dispatch = useAppDispatch();
    const modalParams: any = useAppSelector(state => state.activeModal.params);

    // Define initial values for formik form
    const initialValues: any = {
        anchor: modalParams?.link?.anchor ?? props.defaultAnchor ?? '',
        url: modalParams?.link?.url ?? props.defaultUrl ?? 'https://',
        position: {
            label: `${modalParams?.position ? modalParams.position+1 : 1}`,
            value: `${modalParams?.position ?? props.maxPosition ?? 0}`,
        },
    };

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        anchor: Yup
            .string()
            .required("Veuillez renseigner l'ancre")
            .max(255, "L'ancre ne doit pas dépasser 255 caractères."),
        url: Yup
            .string()
            .required("Veuillez renseigner l'URL")
            .url("L'URL indiquée n'est pas une URL valide")
            .max(255, "L'url ne doit pas dépasser 255 caractères."),
    });

    // Callback triggered when modal is closing
    const handleModalClose = React.useCallback((resetFormFc: any) => {
        // Reset the form
        resetFormFc();
        // Call the given callback
        props.onClose && props.onClose();
    }, [props]);

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback((values: any, formikHelpers: FormikHelpers<any>) => {
        // Call given prop modal validation callback
        props.onValidation && props.onValidation(values, modalParams);
        // Submission also close modal
        dispatch(closeModal());
        // Reset form
        handleModalClose(formikHelpers.resetForm);
    }, [props, modalParams, dispatch, handleModalClose]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <GenericModal
                    name={modalName.current}
                    title={undefined === modalParams?.link ? "Création d'un lien" : "Modification d'un lien"}
                    onClose={() => handleModalClose(formikProps.resetForm)}
                >
                    <Form>
                        <Stack spacing={2} alignItems='start'>
                            <FormikInput
                                variant='filled'
                                label='Ancre du lien'
                                name='anchor'
                            />
                            <FormikInput
                                variant='filled'
                                label='URL du lien'
                                name='url'
                            />
                            {
                                undefined !== modalParams?.link && (
                                    <FormikSelect
                                        name='position'
                                        label='Position du lien'
                                        options={
                                            Array.from(Array(props.maxPosition).keys()).map(
                                                (value: number) => ({
                                                    value: `${value}`,
                                                    label: `${value + 1}`,
                                                }),
                                            )
                                        }
                                    />
                                )
                            }
                            <Button
                                variant='outlined'
                                type='submit'
                                color='inherit'
                                disabled={!formikProps.isValid}
                            >
                                {undefined === modalParams?.link ? 'Valider' : 'Modifier'}
                            </Button>
                        </Stack>
                    </Form>
                </GenericModal>
            )}
        </Formik>
    );
};

export default ModalLinkCreator;
