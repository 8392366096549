import React from 'react';
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import IBacklinkProcessResult from '../../interfaces/IBacklinkProcessResult';

const ResultPage: React.FC<{ result: IBacklinkProcessResult }> = props => (
    <Stack gap={1}>
        <Typography variant='h5'>{`Redirections réussies`}</Typography>
        <TableContainer component={Paper}>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>{`URL source`}</TableCell>
                        <TableCell>{`URL cible`}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.result.success.map((backlink, rowIndex) => (
                        <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>
                                {backlink.sourceUrl}
                            </TableCell>
                            <TableCell>
                                {backlink.targetUrl}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Typography variant='h5' marginTop={4}>{`Redirections echouées`}</Typography>
        <TableContainer component={Paper}>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>{`URL source`}</TableCell>
                        <TableCell>{`URL cible`}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.result.error.map((backlink, rowIndex) => (
                        <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>
                                {backlink.sourceUrl}
                            </TableCell>
                            <TableCell>
                                {backlink.targetUrl}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Stack>
);

export default ResultPage;
