import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { instanceCanRecoverFunds } from '../../store/slices/instanceSlice';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    ButtonProps,
} from '@mui/material';
import RouterLink from '../RouterLink/RouterLink';

const RecoverFundsButton: React.FC<{
    to: string,
    buttonProps?: ButtonProps|undefined,
}> = props => {
    // Use of hooks
    const [isDialogOpen, openDialog] = React.useState<boolean>(false);

    // Use of react-router-dom hooks
    const navigate = useNavigate();

    // Use of redux
    const canRecoverFunds: boolean = useAppSelector(instanceCanRecoverFunds());

    // Function used to either redirect or alert missing IBAN
    function handleRedirection() {
        // Depending on Company information presence
        canRecoverFunds ?
            // Redirect to Payment form
            navigate(props.to)
            // Open alert dialog to display missing IBAN
            : openDialog(true);
    };

    return (
        <>
            <Dialog open={isDialogOpen} onClose={() => openDialog(false)}>
                <DialogTitle>
                    {'Informations manquantes'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Mince... Il nous manque des informations concernant votre société/entreprise.`}
                    </DialogContentText>
                    <DialogContentText>
                        {`Vérifiez que vous aillez bien renseigné toutes les informations requises sur votre
                        votre société/entreprise, pour activer la récupération des fonds.`}
                    </DialogContentText>
                    <DialogContentText paddingTop={2}>
                        {`Pour ce faire, `}
                        <RouterLink
                            anchor='cliquez ici'
                            to='/dashboard/account/company'
                            // Set object state to true to highlight required fields in company form
                            state={true}
                        />
                        {`.`}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Button
                variant='contained'
                onClick={() => handleRedirection()}
                {...props.buttonProps}
            >
                {'Récupérer mes fonds'}
            </Button>
        </>
    );
};

export default RecoverFundsButton;
