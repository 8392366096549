import React from 'react';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { IInstanceWalletState } from '../../store/slices/instanceWalletSlice';
import { FormikProps, Formik, Form } from 'formik';
import {
    Divider,
    Typography,
    Button,
    FormHelperText,
    Stack,
} from '@mui/material';
import FormikInput from '../FormikComponent/FormikInput';
import FormikRadio from '../FormikComponent/FormikRadio';
import FormikCheckbox from '../FormikComponent/FormikCheckbox';
import FormikDatePicker from '../FormikComponent/FormikDatePicker';
import ModalLinkCreator from '../Modal/ModalLinkCreator';
import ModalConfirmation from '../Modal/ModalConfirmation';
import ModalSelectFreelance from '../Modal/ModalSelectFreelance';
import MandatoryLinks from './MandatoryLinks';
import DisplayCard from '../DisplayCard/DisplayCard';
import DisplayContent from '../DisplayContent/DisplayContent';
import ILink from '../../interfaces/ILink';
import IOrderFormStepperData from '../../interfaces/IOrderFormStepperData';
import IRadioOption from '../../interfaces/IRadioOption';
import IUser from '../../models/IUser';
import ModalSelectAccountUser from '../Modal/ModalSelectAccountUser';

// Define privacy options
export const privacyOptions: Array<IRadioOption> = [
    {
        label: 'Publique',
        value: 'public',
        helperText: "En visibilité publique, vous autorisez l'ensemble des rédacteurs de la platforme à postuler pour la rédaction de votre article.",
    },
    {
        label: 'Privée',
        value: 'private',
        helperText: "En visibilité privée, c'est à vous de confier la rédaction de l'article à un de vos rédacteurs.",
    },
];

// Define public choice options
export const publicOptions: Array<IRadioOption> = [
    {
        label: "Appel d'offre",
        value: 'tender',
        helperText: "Un appel d'offre a un tarif fixé. Chaque rédacteur souhaitant effectuer cette commande devra déposer une candidature. Vous aurez le choix sur quel rédacteur effectuera votre commande.",
    },
    {
        label: 'Premier servi',
        value: 'first',
        helperText: "Le premier rédacteur à postuler pour votre commande se verra assigné celle-ci automatiquement.",
    },
    {
        label: 'Proposition',
        value: 'propose',
        helperText: "Vous choisissez vous-même le rédacteur et vous lui envoyez une proposition qu'il devra accepter. Une fois l'offre acceptée, le rédacteur sera assigné automatiquement à votre commande.",
    },
];

// Define minimum deadline hours
const minDeadlineHours = parseInt(process.env.REACT_APP_MIN_DEADLINE_HOURS!);

const SecondaryInfo: React.FC<{ formikFormId: string }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();
    const orderFormState: IOrderFormStepperData = useAppSelector(state => state.activeStepper.data);
    const instanceWalletState: IInstanceWalletState = useAppSelector(state => state.instanceWallet);

    // Define unaware initial values to reset form values
    const unawareInitialValues = React.useRef<any>({
        brief: '',
        mandatoryLinks: [],
        privacy: privacyOptions[0],
        publicChoice: publicOptions[0],
        editor: null,
        autoPublish: false,
        toPublish: false,
        toPublishAt: null,
        deadlineAt: null,
        price: '',
    });

    // Define initial values for formik form
    const initialValues: any = {
        brief: orderFormState.brief ?? unawareInitialValues.current.brief,
        mandatoryLinks: orderFormState.mandatoryLinks ?? unawareInitialValues.current.mandatoryLinks,
        privacy: orderFormState.privacy ?? unawareInitialValues.current.privacy,
        publicChoice: orderFormState.publicChoice ?? unawareInitialValues.current.publicChoice,
        editor: orderFormState.editor ?? unawareInitialValues.current.editor,
        // Reset existing publish values if destinationWebsite is not set
        autoPublish: orderFormState.destinationWebsite ?
            orderFormState.autoPublish ?? unawareInitialValues.current.autoPublish
            : unawareInitialValues.current.autoPublish,
        // toPublish = true if toPublishAt is set
        toPublish: Boolean(orderFormState.toPublishAt),
        // Only defined toPublishAt if destinationWebsite is set
        toPublishAt: orderFormState.destinationWebsite ?
            // Try to convert toPublishAt to date
            (orderFormState.toPublishAt ?
                moment(orderFormState.toPublishAt).toDate()
                : unawareInitialValues.current.toPublishAt)
            : unawareInitialValues.current.toPublishAt,
        deadlineAt: orderFormState.deadlineAt ?
            moment(orderFormState.deadlineAt).toDate() : unawareInitialValues.current.deadlineAt,
        price: orderFormState.price ?? unawareInitialValues.current.price,
    };

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        price: Yup
            .string()
            .test(
                'required',
                'Veuillez spécifier un coût pour la rédaction de cet article',
                function (price: string|undefined) {
                    return 'public' === this.parent.privacy.value ? undefined !== price : true;
                }
            )
            .test(
                'max',
                "Vous n'avez pas les fonds nécessaires sur votre porte-monnaie",
                function (price: string|undefined) {
                    return 'public' === this.parent.privacy.value ?
                        undefined !== price && (Number(instanceWalletState.unlocked) + (orderFormState.price ? Number(orderFormState.price) : 0)) >= Number(price)
                        : true;
                }
            ),
        toPublishAt: Yup
            .mixed()
            .test(
                'required',
                "Veuillez spécifier une date de publication de l'article",
                function (toPublishAt: any) {
                    return true === this.parent.toPublish ? Boolean(toPublishAt) : true;
                }
            ),
        editor: Yup
            .mixed()
            .test(
                'required',
                "Veuillez spécifier le rédacteur de l'article",
                function (editor: any) {
                    return 'public' === this.parent.privacy.value && 'propose' === this.parent.publicChoice.value ? Boolean(editor) : true;
                }
            ),
        deadlineAt: Yup
            .mixed()
            .test(
                'min',
                `La deadline doit être au minimum ${minDeadlineHours} heures après la date de création`,
                function (deadlineAt: any) {
                    return 'propose' === this.parent.publicChoice.value ?
                        // Check that deadline is after creation date + X hours
                        deadlineAt && moment(deadlineAt).isSameOrAfter(moment().add(minDeadlineHours, 'hours'))
                        : true;
                }
            )
            .test(
                'weekend',
                `La deadline ne doit être pas un week-end`,
                function (deadlineAt: any) {
                    return 'propose' === this.parent.publicChoice.value ?
                        // Check that deadline is not in a weekend day
                        deadlineAt && deadlineAt.getDay() % 6 !== 0
                        : true;
                }
            ),
    });

    // Callback use to reset formik fields to their initial value
    const resetFormikFields = React.useCallback((formikProps: FormikProps<any>, fields: Array<string>) => {
        // Use pre-build formik function
        fields.forEach((field) => formikProps.setFieldValue(field, unawareInitialValues.current[field]));
    }, [unawareInitialValues]);

    // Callback to hande formik submit
    const handleFormikSubmit = React.useCallback((values: any) => {
        // Store values under data field of active stepper redux state
        // & increment active step
        dispatch(setActiveStepperData({ data: {
            ...values,
            // Remove toPublish because we don't need it in the database
            toPublish: undefined,
            // Convert toPublishAt to string if possible
            toPublishAt: values.toPublishAt ? moment(values.toPublishAt).format() : null,
            // Convert deadlineAt to string
            deadlineAt: values.deadlineAt ? moment(values.deadlineAt).format() : null,
        }, action: 'inc' }));
    }, [dispatch]);

    // Callback to handle link creation/update
    const handleLink = React.useCallback((values: any, modalParams: any, formikProps: FormikProps<any>) => {
        // Get current links values
        let currentLinks: Array<ILink> = [...formikProps.values.mandatoryLinks];
        // Remove existing link if defined at position
        if (undefined !== modalParams?.position) {
            currentLinks = currentLinks.filter((_, index: number) => index !== modalParams.position);
        }
        // Add link at requested index
        currentLinks.splice(Number(values.position.value), 0, { url: values.url, anchor: values.anchor });
        // Update formik form values
        formikProps.setFieldValue('mandatoryLinks', currentLinks);
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
        >
            {(formikProps: FormikProps<any>) => (
                <Form id={props.formikFormId}>
                    {/* Modal to add a link */}
                    <ModalLinkCreator
                        maxPosition={formikProps.values.mandatoryLinks.length}
                        onValidation={(values: any, modalParams: any) => handleLink(values, modalParams, formikProps)}
                    />
                    <ModalConfirmation
                        type='error'
                        name='autoPublishModal'
                        title='Attention !'
                        message={(
                            <>
                                <Typography variant='body1'>
                                    {`En cochant cette option vous renoncez à tout contrôle sur la validation de l'article.`}
                                </Typography>
                                {
                                    'public' === formikProps.values.privacy.value && (
                                        <Typography variant='body1'>
                                            {`Le virement sera effectué automatiquement après la rédaction de l'article.`}
                                        </Typography>
                                    )
                                }
                                <Typography variant='body1'>
                                    {`L'article sera automatiquement publié sur le site choisi.`}
                                </Typography>
                            </>
                        )}
                        onClose={() => formikProps.setFieldValue('autoPublish', false)}
                    />
                    <FormikInput
                        multiline
                        minRows={3}
                        upperLabel='Informations supplémentaires'
                        placeholder='Donnez des consignes précises au rédacteur'
                        name='brief'
                    />
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    {/* Button to open the modal 'ModalLinkCreator' */}
                    <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() => dispatch(openModal({ name: 'linkCreator' }))}
                    >
                        {`Ajouter un lien obligatoire`}
                    </Button>
                    <FormHelperText sx={{ marginTop: 1 }}>
                        {`Le rédacteur se devra d'introduire dans l'article le(s) lien(s) défini(s).`}
                    </FormHelperText>
                    {
                        // Display links
                        0 < formikProps.values.mandatoryLinks.length && (
                            <MandatoryLinks
                                links={formikProps.values.mandatoryLinks}
                                sx={{ paddingTop: 3 }}
                                onDelete={(index: number) =>
                                    formikProps.setFieldValue('mandatoryLinks',
                                        formikProps.values.mandatoryLinks.filter((_: any, key: number) => key !== index)
                                    )
                                }
                            />
                        )
                    }
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    {/* ChooseEditor to handle public or private choice */}
                    <Typography variant='h6' gutterBottom>
                        {`Choix du rédacteur`}
                    </Typography>
                    <Stack alignItems='start' spacing={2}>
                        {/* Handle privacy choice */}
                        <FormikRadio
                            name='privacy'
                            label={'Définir la visibilité de la commande'}
                            radio={privacyOptions}
                            onChange={() =>
                                // Reset linked sub fields
                                resetFormikFields(formikProps, ['publicChoice', 'price', 'editor', 'autoPublish', 'toPublish', 'toPublishAt', 'deadlineAt'])
                            }
                        />
                        <DisplayContent paddingLeft={3}>
                            {
                                // Privacy is public
                                'public' === formikProps.values.privacy.value ? (
                                    <>
                                        {/* Handle price */}
                                        <FormikInput
                                            name='price'
                                            upperLabel="Définir le coût de rédaction de l'article"
                                            helperText={(
                                                <>
                                                    <FormHelperText>
                                                        {`Vous devez disposer d'un nombre de crédits supérieur ou égal au montant fixé.`}
                                                    </FormHelperText>
                                                    <FormHelperText>
                                                        {`Une fois la commande créée, ce montant sera automatiquement retiré de votre cagnotte.`}
                                                    </FormHelperText>
                                                </>
                                            )}
                                            fullWidth={false}
                                            type='number'
                                            placeholder='10.00 € HT'
                                            numberFormatProps={{
                                                suffix: ' € HT',
                                                decimalSeparator: '.',
                                                allowNegative: false,
                                                fixedDecimalScale: true,
                                                decimalScale: 2,
                                                thousandSeparator: ' ',
                                            }}
                                            inputSx={{ maxWidth: 200 }}
                                        />
                                        <Divider variant='middle' sx={{ marginY: 4 }} />
                                        {/* Handle editor choice */}
                                        <FormikRadio
                                            name='publicChoice'
                                            label='Choix du rédacteur'
                                            radio={publicOptions}
                                            onChange={() =>
                                                // Reset linked sub fields
                                                resetFormikFields(formikProps, ['editor', 'autoPublish', 'toPublish', 'toPublishAt', 'deadlineAt'])
                                            }
                                        />
                                        {
                                            // publicChoice is propose, show button to select an editor
                                            'propose' === formikProps.values.publicChoice.value && null === formikProps.values.editor && (
                                                <>
                                                    <ModalSelectFreelance
                                                        modalName='selectFreelanceModal'
                                                        onValidation={(_, user: IUser) => {
                                                            // Update editor in formik
                                                            formikProps.setFieldValue('editor', user);
                                                            // Reset linked sub fields
                                                            resetFormikFields(formikProps, ['autoPublish', 'toPublish', 'toPublishAt']);
                                                        }}
                                                    />
                                                    <Button
                                                        variant='outlined'
                                                        color='secondary'
                                                        onClick={() => dispatch(openModal({ name: 'selectFreelanceModal' }))}
                                                        sx={{ marginTop: 3 }}
                                                    >
                                                        {`Choisir un Freelance`}
                                                    </Button>
                                                    {
                                                        formikProps.errors.editor && true === formikProps.touched.editor && (
                                                            <FormHelperText error>
                                                                {formikProps.errors.editor}
                                                            </FormHelperText>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    // Privacy is private, show button to select an editor
                                    null === formikProps.values.editor && (
                                        <>
                                            <ModalSelectAccountUser
                                                modalName='selectEditorModal'
                                                singleSelect
                                                scopes={['can_redact_posts']}
                                                onValidation={(_, [user]) => {
                                                    // Update editor in formik
                                                    user && formikProps.setFieldValue('editor', user);
                                                    // Reset linked sub fields
                                                    resetFormikFields(formikProps, ['autoPublish', 'toPublish', 'toPublishAt']);
                                                }}
                                            />
                                            <Button
                                                variant='outlined'
                                                color='secondary'
                                                onClick={() => dispatch(openModal({ name: 'selectEditorModal' }))}
                                            >
                                                {`Choisir un rédacteur`}
                                            </Button>
                                        </>
                                    )
                                )
                            }
                            {
                                // Editor is selected
                                formikProps.values.editor && ('private' === formikProps.values.privacy.value || 'propose' === formikProps.values.publicChoice.value) && (
                                    <Stack spacing={3} padding={'public' === formikProps.values.privacy.value ? 2 : undefined}>
                                        {/* Display editor information */}
                                        <DisplayCard
                                            width={250}
                                            image={formikProps.values.editor.avatar ?? undefined}
                                            title={`${formikProps.values.editor.name} ${formikProps.values.editor.lastname}`}
                                            onDelete={() =>
                                                // Reset linked sub fields
                                                resetFormikFields(formikProps, ['editor', 'autoPublish', 'toPublish', 'toPublishAt'])
                                            }
                                        />
                                        {
                                            // If destination is defined, show autoPublish
                                            orderFormState.destinationWebsite && (
                                                <Stack spacing={2}>
                                                    {/* Handle autoPublish */}
                                                    <FormikCheckbox
                                                        name='autoPublish'
                                                        label="Publier automatiquement l'article à la fin de la rédaction"
                                                        helperText={(
                                                            <>
                                                                {`Une fois l'article rédigé, il sera automatiquement publié sur le site de destination.`}
                                                                <br />
                                                                {`Si vous choisissez de programmer la publication, l'article sera automatiquement validé une fois rédigé.`}
                                                            </>
                                                        )}
                                                        // If checked, open modal to confirm
                                                        onChange={(checked) => true === checked && dispatch(openModal({ name: 'autoPublishModal' }))}
                                                        sx={{ marginTop: 2 }}
                                                    />
                                                    {/* Handle post publish date */}
                                                    <Stack>
                                                        <FormikCheckbox
                                                            name='toPublish'
                                                            label='Programmer la publication'
                                                            onChange={() =>
                                                                // Reset linked sub field
                                                                resetFormikFields(formikProps, ['toPublishAt'])
                                                            }
                                                        />
                                                        <FormikDatePicker
                                                            name='toPublishAt'
                                                            // Restrict to h+1 date minimum
                                                            minDateTime={moment().add(1, 'h').toDate()}
                                                            helperText={
                                                                <>
                                                                    {`Une fois l'article validé, celui-ci sera automatiquement publié sur votre site à une date données.`}
                                                                    <br />
                                                                    {`La publication automatique des articles s'effectue une fois toutes les heures.`}
                                                                </>
                                                            }
                                                            views={['day', 'hours']}
                                                            disabled={!formikProps.values.toPublish}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            )
                                        }
                                    </Stack>
                                )
                            }
                        </DisplayContent>
                    </Stack>
                    {
                        ('private' === formikProps.values.privacy.value || 'propose' === formikProps.values.publicChoice.value) && (
                            <>
                                <Divider variant='middle' sx={{ marginY: 4 }} />
                                <Typography variant='h6' gutterBottom>
                                    {`Choix d'une deadline`}
                                </Typography>
                                <FormikDatePicker
                                    name='deadlineAt'
                                    minDateTime={moment().add(minDeadlineHours, 'hours').toDate()}
                                    maxDateTime={moment().add(1, 'month').toDate()}
                                    shouldDisableDate={date => date.getDay() % 6 === 0}
                                    views={['day', 'hours']}
                                    inputSx={{ width: 250 }}
                                />
                            </>
                        )
                    }
                </Form>
            )}
        </Formik>
    );
};

export default SecondaryInfo;
