import React from 'react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import {
    Add as AddIcon,
    ContentCopy as ContentCopyIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    EuroSymbol as EuroSymbolIcon,
    Numbers as NumbersIcon,
    Percent as PercentICon,
    SyncAlt as SyncAltIcon
} from '@mui/icons-material';
import { ImEye } from 'react-icons/im';
import { MdPublish } from 'react-icons/md';
import PostActionButton from '../PostActionButton/PostActionButton';
import ListingRowData from '../ListingRowData/ListingRowData';
import IDraftPost from '../../models/IDraftPost';
import cssStyles from './DraftPostStyles';

const DraftPost: React.FC<{
    draftPost: IDraftPost,
    onDuplicate: (draftPost: IDraftPost) => void
}> = props => {
    // Use react-router-dom hooks
    const navigate = useNavigate();

    // Use of redux
    const dispatch = useAppDispatch();

    return (
        <Paper sx={cssStyles.postPaper}>
            <Stack sx={cssStyles.container}>
                <Stack sx={cssStyles.leftSide}>
                    <Typography variant='body2' color='textSecondary'>
                        {props.draftPost.keyword}
                    </Typography>
                </Stack>
                <Divider orientation='vertical' flexItem />
                <Stack sx={cssStyles.rightSide}>
                    <ListingRowData
                        icon={PercentICon}
                        tooltip='Optimisation'
                        content={props.draftPost.optimization}
                    />
                    <ListingRowData
                        icon={NumbersIcon}
                        tooltip='Nombre de mots'
                        content={props.draftPost.nbrWords}
                    />
                    <ListingRowData
                        icon={EuroSymbolIcon}
                        tooltip='Prix'
                        content={props.draftPost.price ?? '0'}
                    />
                    <Stack>
                        <ListingRowData
                            icon={AddIcon}
                            tooltip='Date de création'
                            content={moment(props.draftPost.createdAt).format('DD/MM/YYYY HH:mm')}
                        />
                        <ListingRowData
                            icon={SyncAltIcon}
                            tooltip='Dernière sauvegarde'
                            content={moment(props.draftPost.updatedAt ?? props.draftPost.createdAt).format('DD/MM/YYYY HH:mm')}
                        />
                    </Stack>
                    <Stack direction='row' justifyContent='flex-end' gap={1}>
                        <PostActionButton
                            icon={ImEye}
                            tooltip="Voir l'article"
                            onClick={() => dispatch(openModal({ name: 'viewDraftPostModal', params: props.draftPost.id }))}
                        />
                        <PostActionButton
                            icon={EditIcon}
                            tooltip="Editer l'article"
                            onClick={() => navigate('/dashboard/tools/redact_tool', { state: props.draftPost })}
                        />
                        <PostActionButton
                            icon={ContentCopyIcon}
                            tooltip="Dupliquer l'article"
                            onClick={() => props.onDuplicate(props.draftPost)}
                        />
                        <PostActionButton
                            icon={DeleteIcon}
                            tooltip="Supprimer l'article"
                            onClick={() => dispatch(openModal({ name: 'confirmModal', params: props.draftPost.id }))}
                        />
                        <PostActionButton
                            icon={MdPublish}
                            tooltip="Publier l'article"
                            disabled={Boolean(props.draftPost.order)}
                            onClick={() => navigate('../draft_posts_publish', { state: props.draftPost.id })}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default DraftPost;
