import React from 'react';
import {
    Rating,
    Stack,
    TextField,
    TextFieldProps,
    Typography
} from '@mui/material';

const Rate: React.FC<{
    title?: string|undefined,
    maxRate?: number|undefined,
    precision?: number|undefined,
    ratingSize?: 'small'|'medium'|'large'|undefined,
    withComment?: boolean|undefined,
    commentLabel?: string|undefined,
    commentProps?: TextFieldProps|undefined,
    onRateChange?: ((value: number|null) => void)|undefined,
    onCommentChange?: ((value: string) => void)|undefined,
}> = props => {
    return (
        <>
            <Stack direction='row' alignItems='center' columnGap={3}>
                {
                    props.title && (
                        <Typography>{props.title}</Typography>
                    )
                }
                <Rating
                    size={props.ratingSize ?? 'large'}
                    precision={props.precision ?? 0.5}
                    max={props.maxRate ?? 5}
                    onChange={(_, value) => props.onRateChange && props.onRateChange(value)}
                />
            </Stack>
            {
                props.withComment && (
                    <TextField
                        {...props.commentProps}
                        label={props.commentLabel ?? 'Commentaire'}
                        onChange={(e) => props.onCommentChange && props.onCommentChange(e.target.value)}
                    />
                )
            }
        </>
    );
};

export default Rate;
