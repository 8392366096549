import { Theme } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

// Declaration of Material-UI custom constantes
export const appMenuWidthOpen: string = '240px';
export const appMenuWidthClose: any = { sm: '64px', xs: '48px' };
export const appStepperControlsHeight: string = '56px';

// Module augmentation to add custom properties
declare module '@mui/material/styles/createPalette' {
    interface Palette {
        appBarColor?: string|undefined,
        link: string,
    }
    interface PaletteOptions {
        appBarColor?: string|undefined,
        link: string,
    }
};

// Default Material-UI theme used if User does not have an UITheme
export const UIDefaultTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        background: {
            default: 'whitesmoke',
        },
        primary: {
            main: '#253544',
            //main: '#ff0000', // Used for tests
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#3498db',
            //main: '#00ffff', // Used for tests
            contrastText: '#ffffff',
        },
        link: '#64b5f6',
        tonalOffset: 0.1,
    },
};

// Typography used by Material-UI & must be merged with UITheme
export const UITypography: TypographyOptions = {
    fontSize: 16,
    fontFamily: 'RadioCanada',
    fontWeightBold: 900,
};

// Overrides MaterialUI components props & must be called with UITheme
export function overrideComponentsUsingTheme(theme: Theme): Theme {
    return {
        ...theme,
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'initial',
                        padding: '0.5vh 1.7vh',
                    },
                    text: {
                        padding: '0.5vh 1.7vh',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: 'unset',
                        border: '2px solid',
                        borderColor: 'lightgray',
                        borderRadius: '5px',
                    },
                },
            },
            MuiAlert: {
                defaultProps: {
                    variant: 'light' === theme.palette.mode ? undefined : 'filled',
                },
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(2),
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        display: 'block',
                        paddingBottom: '6px',
                        color: theme.palette.text.primary,
                        [theme.breakpoints.up('xs')]: {
                            fontSize: '16px',
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        margin: 0,
                        paddingTop: '6px',
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    thumb: {
                        '&:hover': { boxShadow: 'unset' },
                    },
                },
            },
            MuiStep: {
                styleOverrides: {
                    root: {
                        // Otherwise connectors are not well aligned
                        minHeight: theme.spacing(4),
                    },
                },
            },
            MuiStepConnector: {
                styleOverrides: {
                    vertical: {
                        // Otherwise connectors are not well aligned
                        marginLeft: '21px',
                    },
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        // Otherwise icons are truncated for some reason ...
                        transform: 'scale(0.98)',
                    },
                    text: {
                        fontWeight: 'bold',
                    },
                },
            },
            MuiStepButton: {
                styleOverrides: {
                    root: {
                        padding: `0 ${theme.spacing(2)}`,
                    },
                },
            },
            MuiAutocomplete: {
                defaultProps: {
                    loadingText: 'Chargement...',
                    noOptionsText: 'Pas de résultat',
                    clearText: 'Supprimer',
                    closeText: 'Fermer',
                    openText: 'Ouvrir',
                },
                styleOverrides: {
                    root: {
                        '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                            paddingRight: '39px',
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#ffffff',
                    },
                },
            },
        },
    };
};
