import React from 'react';
import { Field } from 'formik';
import {
    SxProps,
    Theme,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormHelperText,
} from '@mui/material';
import IFormikFieldProps from '../../interfaces/IFormikFieldProps';

const FormikCheckbox: React.FC<{
    name: string,
    label: string|React.ReactElement,
    helperText?: string|React.ReactElement|undefined,
    forceError?: boolean|undefined,
    fullWidth?: boolean|undefined,
    size?: 'small'|'medium'|undefined,
    disabled?: boolean|undefined,
    sx?: SxProps<Theme>|undefined,
    onChange?: ((checked: boolean) => void)|undefined,
}> = props => {
    return (
        <Field name={props.name}>
            {({ field, meta }: IFormikFieldProps) => (
                <FormControl
                    fullWidth={props.fullWidth ?? true}
                    disabled={props.disabled}
                    error={props.forceError ?? Boolean(meta.touched && meta.error)}
                    sx={props.sx}
                >
                    <FormControlLabel
                        label={props.label}
                        sx={{ userSelect: 'none' }}
                        checked={true === field.value}
                        control={
                            <Checkbox
                                {...field}
                                size={props.size}
                                onChange={(event, checked) => {
                                    field.onChange(event);
                                    props.onChange && props.onChange(checked);
                                }}
                            />
                        }
                    />
                    {
                        (Boolean(meta.touched && meta.error) || props.helperText) && (
                            <FormHelperText component='span' sx={{ margin: 0 }}>
                                {Boolean(meta.touched && meta.error) ? meta.error : props.helperText}
                            </FormHelperText>
                        )
                    }
                </FormControl>
            )}
        </Field>
    );
};

export default FormikCheckbox;
