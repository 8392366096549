import React from 'react';
import { Field } from 'react-final-form';
import {
    SxProps,
    Theme,
    Stack,
    Select,
    SelectProps,
    MenuItem,
} from '@mui/material';
import FinalUpperLabel from './FinalUpperLabel';
import FinalFieldMessage from './FinalFieldMessage';
import ISelectOption from '../../interfaces/ISelectOption';

type FinalSelectProps = {
    name: string, // force this prop to not be undefined
    options: Array<ISelectOption>,
    helperText?: React.ReactNode|undefined,
    wrapperSx?: SxProps<Theme>|undefined,
    onChange?: ((selected: ISelectOption|null) => void)|undefined,
};

const FinalSelect: React.FC<Omit<SelectProps, 'onChange'>&FinalSelectProps> = props => {
    return (
        <Stack sx={{ width: props.fullWidth ? 1 : undefined, ...props.wrapperSx }}>
            {props.label && (<FinalUpperLabel name={props.name} label={props.label} />)}
            <Field
                name={props.name}
                subscription={{ value: true, error: true, touched: true }}
                render={({ input: { onChange, ...inputFinalFormProps }, meta: { error, touched }}) => (
                    <Select
                        {...inputFinalFormProps}
                        {...props}
                        label={undefined}
                        // inputFinalFormProps.value is an object ISelectedOption
                        value={inputFinalFormProps.value ? inputFinalFormProps.value.value : null}
                        error={error && touched}
                        onChange={(event) => {
                            // Obviously, following value can not be unknown
                            const option = props.options.find(option => option.value === event.target.value)!;
                            // Value given to onChange is an object ISelectedOption
                            onChange(option);
                            props.onChange && props.onChange(option);
                        }}
                    >
                        {
                            props.options.map((option: ISelectOption, index: number) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))
                        }
                    </Select>
                )}
            />
            <FinalFieldMessage name={props.name} helperText={props.helperText} />
        </Stack>
    );
};

export default FinalSelect;
