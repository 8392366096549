import React from 'react';
import * as Yup from 'yup';
import { Divider, Stack, Typography } from '@mui/material';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import useValidationSchema from '../../hooks/useValidationSchema';
import FinalInput from '../FinalComponents/FinalInput';
import FinalSelect from '../FinalComponents/FinalSelect';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import FinalForm from '../FinalForm/FinalForm';
import IKeywordTitlesResult from '../../interfaces/IKeywordTitlesResult';
import ISelectOption from '../../interfaces/ISelectOption';

type FormValues = {
    keyword: string,
    type: ISelectOption,
    maxTitles: number,
}

const GenerateTitles: React.FC = () => {
    // Use of hooks
    const [generationResult, setGenerationResult] = React.useState<IKeywordTitlesResult|undefined>(undefined);

    // Define validation schema of form
    const validationSchema = useValidationSchema({
        keyword: Yup
            .string()
            .required("Veuillez renseigner le mot-clé sur lequel tester votre URL"),
        maxTitles: Yup
            .number()
            .max(100, "Le nombre maximum de titres à générer ne peut pas dépasser 100"),
    });

    // Callback used to submit form
    const onSubmit = (values: FormValues) => {
        setGenerationResult(undefined);
        // Call API to retrieve current ranking
        return BridgeAPIs.getKeywordTitles(values.keyword, values.type.value, values.maxTitles)
            .then(data => null !== data && setGenerationResult(data));
    };

    return (
        <>
            <FinalForm<FormValues>
                initialValues={{ keyword: '', type: { value: 'h1', label: '' }, maxTitles: 20 }}
                keepDirtyOnReinitialize
                validateSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <Stack direction='row' paddingBottom={3} gap={2}>
                    <FinalInput
                        name='keyword'
                        upperLabel
                        label='Mot-clé'
                        wrapperSx={{ width: 1/2 }}
                    />
                    <FinalSelect
                        name='type'
                        label='Type de titre'
                        options={[
                            { value: 'h1', label: 'H1' },
                            { value: 'h2', label: 'H2' },
                            { value: 'h3', label: 'H3' },
                        ]}
                    />
                    <FinalInput
                        name='maxTitles'
                        upperLabel
                        label='Nombre de titres'
                    />
                </Stack>
                <FinalSubmitButton
                    submitOnEnter
                    variant='contained'
                    children='Générer les titres'
                />
            </FinalForm>
            {
                undefined !== generationResult && (
                    <>
                        <Divider sx={{ marginY: 3 }} />
                        <Typography gutterBottom>{`Prix de génération : ${generationResult.price} €`}</Typography>
                        <Typography gutterBottom>{`Temps de traitement : ${generationResult.executionTime.toFixed(2)} secondes`}</Typography>
                        <Stack>
                            <Typography variant='h6'>{`${generationResult.titles.length} titres générés :`}</Typography>
                            {generationResult.titles.map((title, index) => <Typography key={index}>- {title}</Typography>)}
                        </Stack>
                    </>
                )
            }
        </>
    );
};

export default GenerateTitles;
