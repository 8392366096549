import React from 'react';
import { SxProps, Theme, Typography } from '@mui/material';

const SimpleText: React.FC<{
    sx?: SxProps<Theme>|undefined,
}> = props => {
    return (
        <Typography variant='body2' color='textPrimary' align='justify' gutterBottom sx={props.sx}>
            {props.children}
        </Typography>
    );
};

export default SimpleText;
