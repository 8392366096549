import { useRef, useEffect } from 'react';

type TUseInterval = (callback: () => void, delay: number, deps?: unknown[]) => void;

const useInterval: TUseInterval = (callback, delay, deps = []) => {
    // Define references
    const refCallback = useRef<() => void>(callback);

    // Remember the latest callback if it changes
    useEffect(() => {
        refCallback.current = callback;
    }, [callback]);

    /**
     * The timer is restarted every time an item in `deps` changes.
     *
     * The `react-hooks/exhaustive-deps` ESLint warning is disabled due to the fact that
     * the linter isn't able to detect misused `useEffect` dependencies when given a computed
     * array (such as `deps`).
     */
    useEffect(() => {
        // Setup interval
        const timerID = setInterval(() => refCallback.current(), delay);

        // Clear interval if component unmount
        return () => clearTimeout(timerID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

export default useInterval;
