import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ITextEditorState } from '../../store/slices/textEditorSlice';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import PostAPIs from '../../APIs/PostAPIs';
import Tools from '../../helpers/Tools';
import { LoadingButton } from '@mui/lab';
import { Save as SaveIcon } from '@mui/icons-material';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IPostFormStepperData from '../../interfaces/IPostFormStepperData';
import IPost from '../../models/IPost';
import IOrder from '../../models/IOrder';

const SavePost: React.FC<{ order: IOrder, withConstraints: boolean }> = props => {
    // Use of hooks
    const [loading, setLoading] = React.useState<boolean>(false);
    const [order, setOrder] = React.useState<IOrder>(props.order);

    // Use of redux
    const dispatch = useAppDispatch();
    const postFormStepperData: IPostFormStepperData = useAppSelector(state => state.activeStepper.data);
    const textEditorState: ITextEditorState = useAppSelector(state => state.textEditor);

    // Callback to handle API call to save the post
    const handlePostSave = React.useCallback(() => {
        // Make button load during API call
        setLoading(true);
        // Create body request with data
        const bodyRequest = {
            // Do not update status if user is admin
            status: props.withConstraints && EOrderStatuses.CORRECTED !== order.status.slug && EOrderStatuses.SAVED !== order.status.slug ?
                EOrderStatuses.REFUSED === order.status.slug ?
                    EOrderStatuses.CORRECTED : EOrderStatuses.SAVED
                : undefined,
            title: postFormStepperData.title,
            metaDesc: postFormStepperData.metaDesc,
            // Remove duplicate <p></p> from html
            html: Tools.removeDuplicateTags(textEditorState.content.html ?? ''),
            nbrWords: textEditorState.content.nbrWords,
            optimization: textEditorState.optimization?.score,
            optimizationWords: textEditorState.optimization?.words,
        };

        // Patch the post by its ID
        PostAPIs.patchPost(postFormStepperData.identifier!, Tools.convertToBodyRequest(bodyRequest))
            // On successfull API call
            .then((data: IPost) => {
                if (data) {
                    // Create new PostFormStepperData
                    const newPostFormData = {
                        html: data.html,
                        nbrWords: data.nbrWords,
                        optimization: {
                            score: data.optimization,
                            words: data.optimizationWords,
                        },
                    };
                    // Update order status with response
                    setOrder(currentOrder => ({
                        ...currentOrder,
                        status: data.order.status,
                    }));
                    // Update post data in redux
                    dispatch(setActiveStepperData({ data: newPostFormData }));
                }
            // In any cases
            }).finally(() => {
                // Remove loading in 5 seconds
                setTimeout(() => {
                    setLoading(false);
                }, 5000);
            });
    }, [props.withConstraints, order.status.slug, postFormStepperData, textEditorState, dispatch]);

    return (
        <LoadingButton
            variant="contained"
            onClick={handlePostSave}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
        >
            {`Sauvegarder l'article`}
        </LoadingButton>
    );
};

export default SavePost;
