import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';
import IPaginatorResponse from '../interfaces/IPaginatedResponse';
import IUser from '../models/IUser';

/**
 * UserAPIs class.
 */
export default class UserAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of User resources.
     *
     * @param query
     */
    public static async getUser(query: any): Promise<IPaginatorResponse<IUser>|null> {
        return GenericAPIs.apiGetCall('users', this.getApiKey(), query);
    }

    /**
     * Patch an User resource.
     *
     * @param id
     * @param body
     * @param apiKey
     */
    public static async patchUser(id: number|string, body: any, apiKey?: string|undefined) {
        return GenericAPIs.apiPatchCall(`users/${id}`, null, body, apiKey ?? this.getApiKey());
    }
};
