import React from 'react';
import {
    Stack,
    Typography,
    Rating,
} from '@mui/material';
import HomePanel from '../Home/HomePanel';
import RatingBox from './RatingBox';
import { IRating } from '../../models/IUser';

const PanelRatingsDetail: React.FC<{
    averageRating: IRating|null,
    completedRating: IRating|null,
    firstTryRating: IRating|null,
}> = props => {
    return (
        <HomePanel header='detail des évaluations' sx={{ width: 3/4 }}>
            <Stack direction='row' justifyContent='space-evenly' alignItems='center' height={1}>
                <RatingBox>
                    <Typography variant='body2' lineHeight={1} align='center'>
                        {`Donneurs d'Ordres`}
                    </Typography>
                    <Typography variant='caption'>
                        {`${props.averageRating?.count ?? 0} note(s)`}
                    </Typography>
                    <Typography variant='h4' fontWeight={700}>
                        {props.averageRating?.average ?? '_._'}
                    </Typography>
                    <Rating value={props.averageRating?.average ?? 0} precision={0.1} size='small' readOnly />
                </RatingBox>
                <RatingBox>
                    <Typography variant='body2' lineHeight={1} align='center'>
                        {`Missions terminées`}
                    </Typography>
                    <Typography variant='caption'>
                        {`${props.completedRating?.count ?? 0} mission(s)`}
                    </Typography>
                    <Typography variant='h4' fontWeight={700}>
                        {props.completedRating?.average ?? '_._'}
                    </Typography>
                    <Rating value={props.completedRating?.average ?? 0} precision={0.1} size='small' readOnly />
                </RatingBox>
                <RatingBox>
                    <Typography variant='body2' lineHeight={1} align='center'>
                        {`Articles validés du premier coup`}
                    </Typography>
                    <Typography variant='caption'>
                        {`${props.firstTryRating?.count ?? 0} articles(s)`}
                    </Typography>
                    <Typography variant='h4' fontWeight={700}>
                        {props.firstTryRating?.average ?? '_._'}
                    </Typography>
                    <Rating value={props.firstTryRating?.average ?? 0} precision={0.1} size='small' readOnly />
                </RatingBox>
            </Stack>
        </HomePanel>
    );
};

export default PanelRatingsDetail;
