import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgIndexingStatus: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#40596B' d='M379.067,509.601l-124.8-390.4l-124.8,390.4h28.8l17.067-52.267h155.733v-2.133l18.133,54.4H379.067L379.067,509.601z M301.2,361.334h-93.867l12.8-38.4H288.4L301.2,361.334z M278.8,294.134h-49.067l24.533-74.667L278.8,294.134zM186,427.468l12.8-39.467v1.067h110.933v-3.2l13.867,41.6L186,427.468L186,427.468z' />
            <circle fill='#84DBFF' cx='254.27' cy='158.671' r='50.13' />
            <g>
                <path fill='#F2F2F2' d='M398.267,316.534c-3.2,0-7.467-1.067-9.6-4.267c-5.333-5.333-5.333-14.933,0-20.267c73.6-73.6,73.6-194.133,0-267.733c-6.4-5.333-6.4-14.933,0-20.267c6.4-5.333,14.933-5.333,20.267,0c85.333,85.333,85.333,222.933,0,308.267C405.733,314.401,401.467,316.534,398.267,316.534z' />
                <path fill='#F2F2F2' d='M358.8,276.001c-3.2,0-7.467-1.067-9.6-4.267c-5.333-5.333-5.333-14.933,0-20.267c25.6-25.6,38.4-58.667,38.4-93.867s-13.867-69.333-38.4-93.867c-5.333-5.333-5.333-14.933,0-20.267c5.333-5.333,14.933-5.333,20.267,0C400.4,74.401,416.4,113.868,416.4,157.601c0,42.667-17.067,83.2-46.933,114.133C365.2,274.934,362,276.001,358.8,276.001z' />
                <path fill='#F2F2F2' d='M318.267,236.534c-3.2,0-7.467-1.067-9.6-4.267c-5.333-5.333-5.333-14.933,0-20.267c14.933-14.933,22.4-34.133,22.4-54.4s-7.467-39.467-22.4-54.4c-5.333-5.333-5.333-14.933,0-20.267s14.933-5.333,20.267,0c20.266,20.268,30.933,45.868,30.933,74.668c0,27.733-10.667,54.4-30.933,74.667C325.733,235.468,322.533,236.534,318.267,236.534z' />
                <path fill='#F2F2F2' d='M110.267,316.534c-3.2,0-7.467-1.067-9.6-4.267c-40.533-40.533-64-96-64-153.6c0-58.667,22.4-113.067,64-153.6c5.333-5.333,14.933-5.333,20.267,0c5.333,4.267,5.333,13.867-1.067,19.2c-35.2,35.2-54.4,83.2-54.4,134.4c0,50.133,19.2,98.133,55.467,134.4c5.333,5.333,5.333,14.933,0,20.267C117.733,314.401,113.467,316.534,110.267,316.534z' />
                <path fill='#F2F2F2' d='M149.733,276.001c-3.2,0-7.467-1.067-9.6-4.267c-62.933-62.933-62.933-165.333,0-228.266c5.333-5.333,14.933-5.333,20.267,0s5.333,14.933,0,20.267c-52.267,52.267-52.267,136.533,0,187.733c5.333,5.333,5.333,14.933,0,20.267C157.2,274.934,154,276.001,149.733,276.001z' />
                <path fill='#F2F2F2' d='M190.267,236.534c-3.2,0-7.467-1.067-9.6-4.267c-20.267-20.266-30.934-45.866-30.934-74.666c0-27.733,10.667-54.4,30.933-74.667c5.333-5.333,14.933-5.333,20.267,0c5.333,5.333,5.333,14.933,0,20.267c-14.933,14.933-22.4,34.133-22.4,54.4s7.467,39.467,22.4,54.4c5.333,5.333,5.333,14.933,0,20.267C197.733,235.468,193.467,236.534,190.267,236.534z' />
            </g>
        </SvgIcon>
    );
};

export default SvgIndexingStatus;
