
import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { IInstanceAccountState } from '../../store/slices/instanceSlice';
import OrderAPIs from '../../APIs/OrderAPIs';
import PostAPIs from '../../APIs/PostAPIs';
import Tools from '../../helpers/Tools';
import { Backdrop, CircularProgress, Alert } from '@mui/material';
import AccountOrdersStats from './AccountOrdersStats';
import DataGrid from '../DataGrid/DataGrid';
import ModalConsultPost from '../Modal/ModalConsultPost/ModalConsultPost';
import Spinner from '../Spinner/Spinner';
import ModalConfirmation from '../Modal/ModalConfirmation';
import ModalSelectFreelance from '../Modal/ModalSelectFreelance';
import ModalSelectAccountUser from '../Modal/ModalSelectAccountUser';
import ModalDestinationPicker from '../Modal/ModalDestinationPicker';
import ModalOrderSummary from '../Modal/ModalOrderSummary';
import ModalPublicationDate from '../Modal/ModalPublicationDate';
import ModalExternalPublication from '../Modal/ModalExternalPublication';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IDestinationWebsite from '../../interfaces/IDestinationWebsite';
import IRowData from '../../interfaces/IRowData';
import IAlert from '../../interfaces/IAlert';
import IOrderStats from '../../interfaces/IOrderStats';
import EAccountTypes from '../../interfaces/EAccountTypes';
import IOrder from '../../models/IOrder';
import IPostRefuseMessage from '../../models/IPostRefuseMessage';
import IUser from '../../models/IUser';
import IOrderProposal from '../../models/IOrderProposal';
import IOrderStatus from '../../models/IOrderStatus';
import IPost from '../../models/IPost';

const OrdersListing: React.FC = () => {
    // Use of hooks
    const [loading, isLoading] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<IAlert|null>(null);
    const [orders, setOrders] = React.useState<Array<IOrder>|null>(null);
    const [orderStats, setOrderStats] = React.useState<IOrderStats>({});
    const selectedOrders = React.useRef<Array<IOrder>|null>(null);
    const isMounted = React.useRef<boolean>(true);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceAccount: IInstanceAccountState = useAppSelector(state => state.instance.account!);
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);
    const orderStatuses: Array<IOrderStatus> = useAppSelector(state => state.orderStatuses.data!);

    // Use of react-router-dom hooks
    const navigate = useNavigate();

    // Callback to handle orders updates
    const updateOrders = React.useCallback((data: Array<IOrder>|null, message?: string|undefined, remove?: boolean|undefined) => {
        if (true === isMounted.current && null !== data) {
            setOrders((orders: Array<IOrder>|null) => (
                orders!.flatMap((order: IOrder) => (
                    // Return updated order if defined else return the initial order
                    data.find((updatedOrder: IOrder) => updatedOrder.id === order.id) ?? (true === remove ? [] : order)
                ))
            ));
            // Reset selected orders
            selectedOrders.current = null;
            // Show alert with message if defined
            message && setAlert({ message, severity: 'success' });
        }
    }, []);

    // Callback to handle selection errors
    const handleSelectionErrors = (newSelectedOrders: Array<IOrder>) => {
        // Define default errors values to false
        let privacy = false;
        let assignStatus = false;
        let publish = false;
        let unpublish = false;
        let empty = false;
        let destination = false;

        // If there is at least 1 selected order
        if (newSelectedOrders && 0 < newSelectedOrders.length) {
            // Get the value of isPublic of the first selected order
            const isPublic: boolean = newSelectedOrders[0].isPublic;
            // Verify that all selected orders have the same value for isPublic
            newSelectedOrders.forEach((order: IOrder) => {
                // If isPublic differs from one order to another set privacy to true
                if (isPublic !== order.isPublic) {
                    privacy = true;
                }
                // If order has a bad status to be assigned or unassigned
                if (true === order.isByApplyment || true === order.isFirstServed || ![EOrderStatuses.UNASSIGNED, EOrderStatuses.PROPOSE_REFUSED, EOrderStatuses.TODO, EOrderStatuses.REFUSED].includes(order.status.slug)) {
                    assignStatus = true;
                }
                // If order has a bad status to be assigned or has no site configured
                if (null === order.site || ![EOrderStatuses.VERIFIED, EOrderStatuses.UNPUBLISHED].includes(order.status.slug)) {
                    publish = true;
                }
                // If order has a bad status to be unassigned or has no site configured
                if (null === order.site || ![EOrderStatuses.UNKNOWN, EOrderStatuses.UNINDEXED, EOrderStatuses.PUBLISHED].includes(order.status.slug)) {
                    unpublish = true;
                }
                // If order has a bad status to change destination website
                if ([EOrderStatuses.UNKNOWN, EOrderStatuses.UNINDEXED, EOrderStatuses.PUBLISHED, EOrderStatuses.ARCHIVED].includes(order.status.slug)) {
                    destination = true;
                }
            });
        } else {
            empty = true;
        }

        // Return errors
        return { empty, privacy, assignStatus, publish, unpublish, destination };
    };

    // Callback to handle assign/unassign an Order
    const handleAssignApiCall = React.useCallback((order: IOrder, editor: IUser|null) => {
        // Load during API call
        isLoading(true);

        // If Order is public by proposal and it's an assignment
        if (order.isPublic && false === order.isByApplyment && false === order.isFirstServed && null !== editor) {
            // Call API to create a new OrderProposal
            OrderAPIs.postOrderProposal({
                order: order.id,
                assigner: instanceUserId,
                editor: editor.id,
            })
                .then((data: IOrderProposal|null) => {
                    // Update order and display alert message
                    null !== data && updateOrders(
                        [{
                            ...order,
                            // Set Order status to PROPOSED
                            status: orderStatuses.find(status => status.slug === EOrderStatuses.PROPOSED)!,
                            proposedEditor: editor,
                        }],
                        'La commande a bien été proposé au rédacteur.'
                    );
                })
                // In any cases
                .finally(() => isLoading(false));
        } else {
            // Call API to assign/unassign Order
            OrderAPIs.patchOrder(order.id, {
                // If editor = null, the process will unassign the order
                editor: editor?.id ?? null,
                owner: instanceUserId,
                status: null == editor ? EOrderStatuses.UNASSIGNED : EOrderStatuses.TODO,
            })
                // On successful API call
                .then((data: Array<IOrder>|null) =>
                    null !== data && data[0] &&
                        // Update order and display alert message
                        updateOrders(data, `La commande a bien été ${null === editor ? 'désassignée' : 'assignée'}.`)
                )
                // In any cases
                .finally(() => isLoading(false));
            }
    }, [instanceUserId, orderStatuses, updateOrders]);

    // Callback to handle wordpress post publish or unpublish
    const handleWpPost = React.useCallback((data: Array<IOrder>, publish: boolean) => {
        // Load during API Call
        isLoading(true);
        const ids = data.map(order => order.id);
        // Handle publish or unpublish API call
        OrderAPIs.patchMultipleOrders({ orders: ids, data: { publish }})
            // On successful API call
            .then((data: Array<IOrder>|null) =>
                // Update orders to force update orders stats
                null !== data && updateOrders(data, 1 === data?.length ?
                    `La commande a bien été ${true === publish ? 'publiée' : 'dépubliée'}.`
                    : `Les commandes ont bien été ${true === publish ? 'publiées' : 'dépubliées'}.`
                )
            )
            // In any cases
            .finally(() => isLoading(false));
    }, [updateOrders]);

    // Callback to accept post
    const handleAcceptPost = React.useCallback((order: IOrder, publish: boolean, rate?: number|undefined, comment?: string|undefined) => {
        // Load during API Call
        isLoading(true);
        // Call API to patch status of the order
        OrderAPIs.patchOrder(order.id, Tools.convertToBodyRequest({
            status: EOrderStatuses.VERIFIED,
            publish: publish ? true : undefined,
            rating: rate ? { rate, comment } : undefined,
        }))
            // On successful API call, call onChange callback
            .then((data: Array<IOrder>|null) =>
                // Update orders to force update orders stats
                null !== data && updateOrders(data, `L'article a bien été ${publish ? 'publié' : 'validé'}.`)
            )
            // In any cases
            .finally(() => isLoading(false));
    }, [updateOrders]);

    // Callback to refuse post with message
    const handleRefusePost = React.useCallback((order: IOrder, reasons: Array<string>) => {
        // Load during API Call
        isLoading(true);
        // Call API to add a refuse message and patch status of the order
        PostAPIs.postPostRefuseMessage({ post: order.post!.id, reasons, user: instanceUserId })
            // On successful API call
            .then((data: IPostRefuseMessage|null) =>
                // Update orders
                null !== data && updateOrders([{
                    ...order,
                    status: data.post.order.status,
                    post: {
                        ...order.post!,
                        postRefuseMessages: [
                            ...order.post!.postRefuseMessages ?? [],
                            ...[data]
                        ]
                    },
                }], "L'article a bien été refusé.")
            )
            // In any cases, stop loading
            .finally(() => isLoading(false));
    }, [instanceUserId, updateOrders]);

    // Callback to handle OrderShare
    const handleOrderShare = React.useCallback(({ order }: { order: IOrder }, _, newSelected: Array<IUser>|undefined, unselected: Array<IUser>|undefined) => {
        // Construct body request
        const bodyRequest = Tools.convertToBodyRequest({
            order: order.id,
            user: instanceUserId,
            create: newSelected ? newSelected.map(user => user.id) : undefined,
            delete: unselected ? unselected.map(sharedUser => sharedUser.id) : undefined,
        }, true);

        // If body request contains data to create or delete
        (bodyRequest.create || bodyRequest.delete) &&
            // Create & delete OrderShare resources
            OrderAPIs.updateMultipleOrderShare(bodyRequest)
                // On successful API call, update Orders state
                .then(() => updateOrders([{
                    ...order,
                    sharedUsers: [
                        // Remove existing share users that have been deleted
                        ...(order.sharedUsers?.filter(sharedUser => !unselected?.some(user => user.id === sharedUser.id)) ?? []),
                        // Add new shared users
                        ...(newSelected ?? [])
                    ],
                }], 'La commande a bien été mise à jour.'));
    }, [instanceUserId, updateOrders]);

    // Callback to handle site change
    const handleDestinationWebsite = React.useCallback((selectedOrders: Array<IOrder>, destination: IDestinationWebsite) => {
        // Load during API Call
        isLoading(true);
        // Call API to change site of selected orders
        OrderAPIs.patchMultipleOrders({
            orders: selectedOrders.map(order => order.id),
            data: { site: destination.website.value, category: destination.category }
        })
            // On successful API call
            .then((data: Array<IOrder>|null) =>
                // Update orders to force update orders stats
                null !== data && updateOrders(
                    data, 1 === selectedOrders.length ?
                        `Le site de la commande a bien été changé.`
                        : `Le site des commandes a bien été changé.`
                )
            )
            // In any cases
            .finally(() => isLoading(false));
    }, [updateOrders]);

    // Callback to handle publication date update
    const handlePublicationDate = React.useCallback((orderId: number, toPublishAt: string|null) => {
        // Load during API Call
        isLoading(true);
        // Call API to patch publication date of the order
        OrderAPIs.patchOrder(orderId, { toPublishAt })
            // On successful API call, call onChange callback
            .then((data: Array<IOrder>|null) =>
                // Update orders to force update orders stats
                null !== data && updateOrders(data, `La date de publication a bien été mise à jour.`)
            )
            // In any cases
            .finally(() => isLoading(false));
    }, [updateOrders]);

    // Callback used to specify an external publication of a Post
    const handleExternalPublication = React.useCallback((order: IOrder, postUrl: string, publishedAt: string) => {
        // Load during API Call
        isLoading(true);

        // Call API to patch publication date of the order
        PostAPIs.patchPost(order.post!.id, { wpPostLink: postUrl, publishedAt: publishedAt, status: EOrderStatuses.EXTERNAL_PUBLISH })
            // On successful API call, call onChange callback
            .then((data: IPost|null) =>
                // Update orders to force update orders stats
                null !== data && updateOrders([{
                    ...order,
                    status: data.order.status,
                    post: data,
                }], 'La publication externe a bien été renseigné.')
            )
            // In any cases
            .finally(() => isLoading(false));
    }, [updateOrders]);

    // Callback to verify that the order is not busy & trigger given callback
    const triggerIfNotBusy = React.useCallback((order: IOrder, callback: () => void) => {
        // Load during API Call
        isLoading(true);
        // Call API to verify that order is not busy
        OrderAPIs.getOrder({ id: order.id })
            // On successful API call
            .then((orders: Array<IOrder>) => {
                // If order is not busy, call callback
                if (false === orders[0].isBusy) {
                    callback();
                } else {
                    // Else display to the user that the order is busy
                    setAlert({
                        message: 'Vous ne pouvez pas agir sur cette commande car celle-ci est en cours de modification.',
                        severity: 'warning',
                    });
                }
            })
            // In any cases
            .finally(() => isLoading(false));
    }, []);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === orders &&
            // Get orders of instance user
            OrderAPIs.getOrder({
                user: EAccountTypes.CORPORATION === instanceAccount.accountType.slug ? undefined : instanceUserId,
                account: EAccountTypes.CORPORATION === instanceAccount.accountType.slug ? instanceAccount.id : undefined,
             })
                // On successful API call
                .then((data: Array<IOrder>|null) => null !== data && true === isMounted.current && setOrders(data ?? []));
    }, [orders, instanceAccount, instanceUserId]);

    // useEffect whenever orders value change
    React.useEffect(() => {
        // Calculate orders stats and update state
        setOrderStats(
            // Regroup and count Order statuses
            orders?.reduce((acc: IOrderStats, order: IOrder) => {
                acc[order.status.name] = {
                    color: order.status.color,
                    nbrOrders: acc[order.status.name]?.nbrOrders ? acc[order.status.name].nbrOrders + 1 : 1,
                };

                return acc;
            }, {}) ?? {}
        );
    }, [orders]);

    // useEffect when component is unmouting
    React.useEffect(() => () => {
        // Set ref to false to prevent state changes
        isMounted.current = false;
    }, []);

    return (
        null !== orders && null !== orderStats ? (
            <>
                {
                    true === loading && (
                        <Backdrop open={true === loading} sx={{ zIndex: theme => theme.zIndex.drawer - 1 }}>
                            <CircularProgress size='10vh' />
                        </Backdrop>
                    )
                }
                {
                    null !== alert && (
                        <Alert sx={{ marginBottom: 3 }} onClose={() => setAlert(null)} severity={alert.severity}>
                            {alert.message}
                        </Alert>
                    )
                }
                {/* Modal to consult post data */}
                <ModalConsultPost
                    title="Consultation de l'article"
                    onAccept={handleAcceptPost}
                    onRefuse={handleRefusePost}
                    onUnassign={order => handleAssignApiCall(order, null)}
                />
                {/* Modal to select select an Editor */}
                <ModalSelectAccountUser
                    modalName='selectEditorModal'
                    singleSelect
                    scopes={['can_redact_posts']}
                    onValidation={(order: IOrder, [user]) => user && handleAssignApiCall(order, user)}
                />
                {/* Modal to select a Freelance */}
                <ModalSelectFreelance modalName='selectFreelanceModal' onValidation={handleAssignApiCall} />
                {/* Confirmation modal to unassign orders */}
                <ModalConfirmation
                    name='unassignModal'
                    title='Êtes-vous sûr de vouloir désassigner ces commandes ?'
                    message="Désassigner une commande ne supprime pas le contenu de l'article en cours de rédaction."
                    onValidation={(modalParams: any) => handleAssignApiCall(modalParams, null)}
                />
                {/* Confirmation modal to unpublish posts */}
                <ModalConfirmation
                    name='unpublishOrdersModal'
                    title='Êtes-vous sûr de vouloir dépublier ce(s) commande(s) ?'
                    message="La suppression de l'article sur votre site WordPress est définitive et irréversible."
                    width={1/2}
                    onValidation={(params: any) => handleWpPost(params, false)}
                />
                {/* Confirmation modal to publish posts */}
                <ModalConfirmation
                    name='publishOrdersModal'
                    title='Êtes-vous sûr de vouloir publier ce(s) commande(s) ?'
                    message="Une fois publiée, le statut de la commande passe à 'Publié'. Si une erreur survient pendant la publication, le statut de la commande passe à 'Non publié'."
                    width={1/2}
                    onValidation={(params: any) => handleWpPost(params, true)}
                />
                {/* Modal to select users to share selected orders */}
                <ModalSelectAccountUser
                    modalName='shareOrdersModal'
                    excludeUsers={[instanceUserId]}
                    scopes={['account_owner', 'can_make_order']}
                    onValidation={handleOrderShare}
                />
                {/* Modal to select destination website */}
                <ModalDestinationPicker
                    name='destinationModal'
                    onValidation={(data: IDestinationWebsite, _, selectedOrders: Array<IOrder>) => handleDestinationWebsite(selectedOrders, data)}
                />
                {/* Modal to update publication date */}
                <ModalPublicationDate onValidation={handlePublicationDate} />
                {/* Modal to specify an external publication */}
                <ModalExternalPublication onValidation={handleExternalPublication} />
                {/* Modal to consult related Order summary */}
                <ModalOrderSummary />
                <AccountOrdersStats stats={orderStats} />
                <DataGrid
                    checkableRows
                    rowName='commande'
                    rowData={orders}
                    gridEmptyMessage='Aucune commande'
                    addElementButtonText='Ajouter une commande'
                    deleteMessage={
                        `Cette action est définitive et irréversible.
                        Les articles associés à ce ou ces commande(s) seront supprimés également.`
                    }
                    // Selection deletion button will be avaible only if ...
                    canDeleteSelection={(data: Array<IOrder>) => data.every(order => [EOrderStatuses.UNASSIGNED, EOrderStatuses.PROPOSE_REFUSED].includes(order.status.slug))}
                    onAddElement={() => navigate('templates/form')}
                    onDeleteRow={id => OrderAPIs.deleteMultipleOrder([id])}
                    onDeleteSelection={ids => OrderAPIs.deleteMultipleOrder(ids)}
                    onSelectionChanged={handleSelectionErrors}
                    sx={{ marginTop: 2 }}
                    columnData={[
                        {
                            field: 'status',
                            headerName: 'Statut',
                            sort: 'asc',
                            type: 'status',
                            valueGetter: params => params.data.status.name,
                        },
                        {
                            headerName: 'Projet',
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            valueGetter: params => params.data.project.name,
                        },
                        {
                            headerName: 'Mot clé',
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            valueGetter: params => params.data.keyword.name,
                        },
                        {
                            headerName: 'Type',
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            valueGetter: params => params.data.postType.name,
                        },
                        {
                            headerName: 'Assignée à',
                            type: 'user',
                            valueGetter: params =>  EOrderStatuses.PROPOSED === params.data.status.slug ?
                                params.data.proposedEditor
                                : params.data.editor,
                        },
                        {
                            headerName: 'Envoi pour vérification le',
                            type: 'datetime',
                            field: 'unverifiedAt'
                        },
                        {
                            headerName: 'Site',
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            valueGetter: params => params.data.site?.domain ?? params.data.post?.wpPostLink ?? 'Non renseigné',
                        },
                        {
                            headerName: 'Catégorie',
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            valueGetter: params => params.data.siteCategory?.name ?? 'Non renseigné',
                        },
                        {
                            headerName: 'Validation',
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            valueGetter: params => params.data.site && params.data.autoPublish ? `Automatique` : `Manuelle`,
                        },
                        {
                            headerName: 'Publication',
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            valueGetter: params => (
                                params.data.toPublishAt ?
                                    moment(params.data.toPublishAt).format('DD/MM/Y à HH:mm')
                                : params.data.autoPublish ? `Automatique` : `À publier`
                            ),
                        },
                        {
                            headerName: 'Visibilité',
                            field: 'isPublic',
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            valueFormatter: params => params.value ? 'Publique' : 'Privée',
                        },
                        {
                            headerName: 'Créateur',
                            type: 'user',
                            valueGetter: params => params.data.user,
                        },
                        { headerName: 'Créé le', field: 'createdAt', type: 'datetime' },
                        { headerName: 'Identifiant', field: 'publicId', type: 'copyCell' },
                    ]}
                    rowMenuOptions={[
                        {
                            label: "Consulter l'article",
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: 'consultPostModal', params: { orderId: rowData.data.id } })),
                            // Only show when editor started to write post
                            isShown: (rowData: IRowData) => ![EOrderStatuses.UNASSIGNED, EOrderStatuses.TODO, EOrderStatuses.PROPOSED, EOrderStatuses.PROPOSE_REFUSED].includes(rowData.data.status.slug),
                        },
                        {
                            label: "Consulter l'article WordPress",
                            onClick: (rowData: IRowData) =>
                                window.open(
                                    `https://${rowData.data.site.domain}?p=${rowData.data.post.wpPostId!}`,
                                    '_blank',
                                    'noopener,noreferrer'
                                ),
                            isShown: (rowData: IRowData) => [EOrderStatuses.UNKNOWN, EOrderStatuses.PUBLISHED, EOrderStatuses.UNINDEXED].includes(rowData.data.status.slug),
                        },
                        {
                            label: 'Consulter le récapitulatif',
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: 'orderSummaryModal', params: { orderId: rowData.data.id } })),
                        },
                        {
                            label: "Reprogrammer la publication",
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: 'modalToPublishAt', params: { orderId: rowData.data.id, toPublishAt: rowData.data.toPublishAt } })),
                            isShown: (rowData: IRowData) => (
                                // Order must not be published
                                ![EOrderStatuses.UNKNOWN, EOrderStatuses.UNINDEXED, EOrderStatuses.PUBLISHED, EOrderStatuses.ARCHIVED].includes(rowData.data.status.slug) &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: "Modifier l'article",
                            onClick: (rowData: IRowData) => triggerIfNotBusy(rowData.data, () => navigate('/dashboard/posts/form', { state: rowData.data.id })),
                            isShown: (rowData: IRowData) => (
                                // Order status should not be verified or higher
                                [EOrderStatuses.VERIFIED, EOrderStatuses.UNPUBLISHED].includes(rowData.data.status.slug) &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: 'Assigner la commande',
                            // Show freelance modal or account editors in parent component
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: rowData.data.isPublic ? 'selectFreelanceModal' : 'selectEditorModal', params: rowData.data })),
                            // Disabled when empty or different privacy or bad status
                            isShown: (rowData: IRowData) => (
                                false === rowData.data.isFirstServed &&
                                false === rowData.data.isByApplyment &&
                                null === rowData.data.editor &&
                                null === rowData.data.proposedEditor &&
                                [EOrderStatuses.UNASSIGNED, EOrderStatuses.PROPOSE_REFUSED, EOrderStatuses.TODO, EOrderStatuses.REFUSED].includes(rowData.data.status.slug) &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: 'Désassigner la commande',
                            // Show freelance modal or account editors in parent component
                            onClick: (rowData: IRowData) => dispatch(openModal( { name: 'unassignModal', params: rowData.data })),
                            // Disabled when empty or different privacy or bad status
                            isShown: (rowData: IRowData) => (
                                false === rowData.data.isPublic &&
                                null !== rowData.data.editor &&
                                [EOrderStatuses.UNASSIGNED, EOrderStatuses.PROPOSE_REFUSED, EOrderStatuses.TODO, EOrderStatuses.REFUSED].includes(rowData.data.status.slug) &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: "Dépublier l'article",
                            color: 'error.main',
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: 'unpublishOrdersModal', params: [rowData.data] })),
                            isShown: (rowData: IRowData) => (
                                // Order status must be published
                                [EOrderStatuses.UNKNOWN, EOrderStatuses.PUBLISHED, EOrderStatuses.UNINDEXED].includes(rowData.data.status.slug) &&
                                // Order must have a attached site
                                null !== rowData.data.site &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: "Publier l'article",
                            color: 'success.main',
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: 'publishOrdersModal', params: [rowData.data] })),
                            isShown: (rowData: IRowData) => (
                                // Order status must be verified or unpublished
                                [EOrderStatuses.VERIFIED, EOrderStatuses.UNPUBLISHED].includes(rowData.data.status.slug) &&
                                // Order must have a attached site
                                null !== rowData.data.site &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: "Renseigner la publication",
                            color: 'success.main',
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: 'externalPublicationModal', params: rowData.data })),
                            isShown: (rowData: IRowData) => (
                                // Order status must be verified or unpublished
                                [EOrderStatuses.VERIFIED, EOrderStatuses.UNPUBLISHED].includes(rowData.data.status.slug) &&
                                // Order must have a attached site
                                null === rowData.data.site &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: 'Modifier la destination',
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: 'destinationModal', params: [rowData.data] })),
                            isShown: (rowData: IRowData) => (
                                // Order must not be published
                                ![EOrderStatuses.UNKNOWN, EOrderStatuses.UNINDEXED, EOrderStatuses.PUBLISHED, EOrderStatuses.ARCHIVED].includes(rowData.data.status.slug) &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: 'Partager la commande',
                            onClick: (rowData: IRowData) => dispatch(openModal({
                                name: 'shareOrdersModal',
                                params: {
                                    selectedUsersIds: (rowData.data as IOrder).sharedUsers?.map((user: IUser) => user.id),
                                    notDisplayedUserIds: [rowData.data.user.id],
                                    order: rowData.data
                                },
                            })),
                            isShown: _ => (
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: 'Modifier la commande',
                            onClick: (rowData: IRowData) => triggerIfNotBusy(rowData.data, () => navigate('templates/form', { state: rowData.data })),
                            isShown: (rowData: IRowData) => (
                                // Order can't be edited till the editor entirely processed the post (ex: when status is 'Non validé')
                                [EOrderStatuses.UNASSIGNED, EOrderStatuses.PROPOSE_REFUSED].includes(rowData.data.status.slug) &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                        {
                            label: 'Supprimer la commande',
                            color: 'error.main',
                            // Open DataGrid confirmModal
                            onClick: (rowData: IRowData) => dispatch(openModal({ name: 'confirmModalDataGrid', params: rowData })),
                            isShown: (rowData: IRowData) => (
                                // Order status must be unsassigned
                                [EOrderStatuses.UNASSIGNED, EOrderStatuses.PROPOSE_REFUSED].includes(rowData.data.status.slug) &&
                                // Only show when instance user is account_owner or can_make_order
                                Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order'])
                            ),
                        },
                    ]}
                    selectionActions={(data: Array<IOrder>) => {
                        selectedOrders.current = data;
                        const errors = handleSelectionErrors(data);

                        return [
                            {
                                label: 'Publier les articles',
                                // Disabled when empty or bad status to publish
                                disabled: !Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order']) || errors.empty || errors.publish,
                                hideOnDisabled: true,
                                action: () => dispatch(openModal({ name: 'publishOrdersModal', params: data })),
                            },
                            {
                                label: 'Dépublier les articles',
                                // Disabled when empty or bad status to unpublish
                                disabled: !Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order']) || errors.empty || errors.unpublish,
                                hideOnDisabled: true,
                                action: () => dispatch(openModal({ name: 'unpublishOrdersModal', params: data })),
                            },
                            {
                                label: 'Modifier la destination',
                                // Order must not be published and user is account_owner or can_make_order
                                disabled: !Tools.isScoped(instanceAccount.scopes, ['account_owner', 'can_make_order']) || errors.destination || errors.empty,
                                hideOnDisabled: true,
                                action: () => dispatch(openModal({ name: 'destinationModal', params: data })),
                            },
                        ];
                    }}
                />
            </>
        ) : (<Spinner />)
    );
};

export default OrdersListing;
