import { CookieSetOptions } from 'universal-cookie';
import moment from 'moment-timezone';

/**
 * Default options for all cookies generated in this app
 */
const cookieOptions: CookieSetOptions = {
    path: '/',
    expires: moment().add(1, 'week').toDate(),
    secure: true,
    sameSite: 'strict',
};

export default cookieOptions;
