import React from 'react';
import useInterval from '../../hooks/useInterval';
import useSSE from '../../hooks/useSSE';
import { useAppSelector } from '../../store/hooks';
import { Stack, Typography, LinearProgress } from '@mui/material';

type LoadingType = {
    step: number,
    totalSteps: number,
    percentage: number,
};

const GenerationLoader: React.FC = () => {
    // Use of hook
    const [progress, setProgress] = React.useState<number>(0);
    const nextStepPercentage = React.useRef<number>(100);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // useSSE on loading messages
    useSSE(`loading/ai_generation_post/${instanceUserId}`, (data: LoadingType) => {
        // Calculate next step percentage
        nextStepPercentage.current = Math.ceil((100 / data.totalSteps) * (data.step + 1));
        // Update progress state
        setProgress(Math.min(100, data.percentage));
    });

    // useInterval to fake update progress every second
    useInterval(() => {
        // Stop fake update if current progress is 95%
        if (progress < 95) {
            // Generate a random number between 0 and 3
            const random = Math.floor(Math.random() * 3);
            setProgress(Math.min(nextStepPercentage.current, progress + random));
        }
    }, 1000, [progress]);

    return (
        <Stack alignItems='center' rowGap={2} width={1} paddingTop={10}>
            <Typography variant='h5' fontWeight={700}>
                {`${progress}%`}
            </Typography>
            <LinearProgress
                value={progress}
                variant='determinate'
                sx={{ width: 1/2, height: 32, borderRadius: '10px' }}
            />
        </Stack>
    );
};

export default GenerationLoader;
