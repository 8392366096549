import React from 'react';
import { Field } from 'formik';
import stringToIcon from '../../configs/stringToIcon';
import {
    SxProps,
    Theme,
    FormControl,
    FormLabel,
    Autocomplete,
    Box,
    TextField,
    FormHelperText,
    CircularProgress,
} from '@mui/material';
import ISelectOption from '../../interfaces/ISelectOption';
import IFormikFieldProps from '../../interfaces/IFormikFieldProps';

const FormikAutocomplete: React.FC<{
    name: string,
    options: Array<ISelectOption>,
    upperlabel?: string|undefined,
    label?: string|undefined,
    disabled?: boolean|undefined,
    placeholder?: string|undefined,
    forceError?: boolean|undefined,
    fullWidth?: boolean|undefined,
    size?: 'small'|'medium'|undefined,
    helperText?: string|undefined,
    loading?: boolean|undefined,
    sx?: SxProps<Theme>|undefined,
    onChange?: ((option: ISelectOption|null) => void)|undefined,
}> = props => {
    // Use of hooks
    const [open, setOpen] = React.useState(false);

    return (
        <Field name={props.name}>
            {({ field, form, meta }: IFormikFieldProps) => (
                <FormControl
                    fullWidth={props.fullWidth ?? true}
                    disabled={props.disabled}
                    error={props.forceError ?? Boolean(meta.touched && meta.error)}
                    sx={props.sx}
                >
                    {
                        props.upperlabel && (
                            <FormLabel sx={{ fontSize: { xs: 16 } }}>
                                {props.upperlabel}
                            </FormLabel>
                        )
                    }
                    <Autocomplete
                        {...field}
                        options={props.options}
                        getOptionLabel={(option: ISelectOption) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        disabled={props.disabled}
                        size={props.size ?? 'small'}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        loading={props.loading}
                        renderOption={(props, option) => {
                            // Retrieve option icon if it exists
                            const Icon = option.icon ? stringToIcon(option.icon) : undefined;

                            return (
                                <Box {...props} component='li'>
                                    {Icon ? <Icon fontSize='small' sx={{ marginRight: 1 }} /> : null}
                                    {option.label}
                                </Box>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={props.label}
                                placeholder={props.placeholder}
                                error={props.forceError ?? Boolean(meta.touched && meta.error)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {props.loading ? <CircularProgress color='inherit' size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                                onBlur={() => form.setFieldTouched(props.name, true)}
                            />
                        )}
                        onChange={(_, option: any) => {
                            form.setFieldValue(props.name, option);
                            props.onChange && props.onChange(option);
                        }}
                        onInputChange={(event, value, reason) => {
                            if ('clear' === reason) {
                                form.setFieldValue(props.name, null);
                                props.onChange && props.onChange(null);
                            };
                        }}
                        sx={{ minWidth: 1 }}
                    />
                    {
                        (Boolean(meta.touched && meta.error) || props.helperText) && (
                            <FormHelperText component='span'>
                                {Boolean(meta.touched && meta.error) ? meta.error : props.helperText}
                            </FormHelperText>
                        )
                    }
                </FormControl>
            )}
        </Field>
    );
};

export default FormikAutocomplete;
