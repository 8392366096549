import React from 'react';
import moment from 'moment-timezone';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import {
    Add as AddIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
    Numbers as NumbersIcon,
    Percent as PercentICon,
    SyncAlt as SyncAltIcon
} from '@mui/icons-material';
import { BsWordpress } from 'react-icons/bs';
import { ImEye } from 'react-icons/im';
import { MdOutlineUnpublished } from 'react-icons/md';
import PostActionButton from '../PostActionButton/PostActionButton';
import ListingRowData from '../ListingRowData/ListingRowData';
import IDraftPost from '../../models/IDraftPost';
import cssStyles from './DraftPostStyles';

const DraftPost: React.FC<{ draftPost: IDraftPost }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();

    return (
        <Paper sx={cssStyles.postPaper}>
            <Stack sx={cssStyles.container}>
                <Stack sx={cssStyles.leftSide}>
                    <Typography variant='body2' color='textSecondary'>
                        {props.draftPost.keyword}
                    </Typography>
                </Stack>
                <Divider orientation='vertical' flexItem />
                <Stack sx={cssStyles.rightSide}>
                    <ListingRowData
                        icon={PercentICon}
                        tooltip='Optimisation'
                        content={props.draftPost.optimization}
                    />
                    <ListingRowData
                        icon={NumbersIcon}
                        tooltip='Nombre de mots'
                        content={props.draftPost.nbrWords}
                    />
                    <ListingRowData
                        icon={CheckCircleOutlineIcon}
                        tooltip='Publié/Programmé le'
                        color={props.draftPost.order?.post?.publishedAt ? 'success.main' : undefined}
                        content={props.draftPost.order?.post?.publishedAt ?
                            moment(props.draftPost.order.post.publishedAt).format('DD/MM/YYYY HH:mm')
                            : moment(props.draftPost.order!.toPublishAt).format('DD/MM/YYYY HH:mm')
                        }
                    />
                    <Stack>
                        <ListingRowData
                            icon={AddIcon}
                            tooltip='Date de création'
                            content={moment(props.draftPost.createdAt).format('DD/MM/YYYY HH:mm')}
                        />
                        <ListingRowData
                            icon={SyncAltIcon}
                            tooltip='Dernière sauvegarde'
                            content={moment(props.draftPost.updatedAt).format('DD/MM/YYYY HH:mm')}
                    />
                    </Stack>
                    <Stack direction='row' justifyContent='flex-end' gap={1}>
                        <PostActionButton
                            icon={ImEye}
                            tooltip="Voir l'article"
                            onClick={() => dispatch(openModal({ name: 'viewDraftPostModal', params: props.draftPost.id }))}
                        />
                        <PostActionButton
                            icon={MdOutlineUnpublished}
                            tooltip="Dépublier l'article"
                            onClick={() => dispatch(openModal({ name: 'confirmUnpublishModal', params: props.draftPost.id }))}
                        />
                        {
                            props.draftPost.order!.post!.publishedAt && props.draftPost.order!.post!.wpPostLink && (
                                <PostActionButton
                                    icon={BsWordpress}
                                    tooltip="Voir l'article WordPress"
                                    onClick={() => window.open(
                                        props.draftPost.order!.post!.wpPostLink!,
                                        '_blank',
                                        'noopener,noreferrer'
                                    )}
                                />
                            )
                        }
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default DraftPost;
