import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * HomeAPIs class.
 */
export default class HomeAPIs extends AbstractAuthAPIs {
    /**
     * Get Editor Posts stats.
     *
     * @param query
     */
    public static async getEditorOverview(query: any) {
        return GenericAPIs.apiGetCall(`home/editor/overview`, this.getApiKey(), query);
    }

    /**
     * Get Editor incomes.
     *
     * @param query
     */
    public static async getEditorIncomes(query: any) {
        return GenericAPIs.apiGetCall(`home/editor/incomes`, this.getApiKey(), query);
    }

    /**
     * Get Wallet most urgent countdowns
     *
     * @param query
     */
     public static async getEditorCountdowns(query: any) {
        return GenericAPIs.apiGetCall(`home/editor/countdowns`, this.getApiKey(), query);
    }
};
