import { toast, TypeOptions } from 'react-toastify';
import ToastMessage from '../components/Toast/ToastMessage';

/**
 * Toaster class.
 */
export default class Toaster {
    /**
     * Create a Toast on App.
     *
     * @param title
     * @param message
     * @param type
     * @param duration
     */
     public static createToast(title: string, message: string, type: TypeOptions = 'error', duration: number = 5000) {
        toast(
            <ToastMessage
                title={title}
                message={message}
            />, {
                type: type,
                autoClose: duration
            }
        );
    }
};
