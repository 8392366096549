import React from 'react';
import { useAppSelector } from '../../store/hooks';
import Tools from '../../helpers/Tools';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import SvgHtml5 from '../Svg/SvgHtml5';
import ITextEditorContent from '../../interfaces/ITextEditorContent';

const ToolbarAwareButtons: React.FC = () => {
    // Use of redux
    const textEditorContent: ITextEditorContent = useAppSelector(state => state.textEditor.content);

    // Callback to handle various copy
    const handleCopy = React.useCallback((asHtml?: boolean|undefined) => {
        // Setup final content to copy
        const withoutDuplicatesTags = Tools.removeDuplicateTags(textEditorContent.html);
        const finalContent = asHtml ?
            withoutDuplicatesTags
            : Tools.stripTags(withoutDuplicatesTags);

        // Write this content in clipboard
        navigator.clipboard.writeText(finalContent);
    }, [textEditorContent.html]);

    return (
        <>
            <ToggleButtonGroup exclusive size='small'>
                <ToggleButton
                    value='copy-plain'
                    title='Copier le texte brut'
                    onClick={() => handleCopy()}
                >
                    <ContentCopyIcon />
                </ToggleButton>
                <ToggleButton
                    value='copy-html'
                    title='Copier le texte HTML'
                    onClick={() => handleCopy(true)}
                >
                    <SvgHtml5 />
                </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant='body2'>
                {`Nbr. de mots : ${textEditorContent.nbrWords}`}
            </Typography>
        </>
    );
};

export default ToolbarAwareButtons;
