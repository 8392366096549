import React from 'react';
import { Tooltip, ButtonBase, SxProps, Theme } from '@mui/material';
import cssStyles from './TextEditorStyles';

const GenerationButton: React.FC<{
    tokens: number,
    selected: boolean,
    text: string,
    isMax?: boolean,
    onClick: () => void,
}> = props => {
    return (
        <Tooltip title={`Coûte ${props.isMax ? 'maximum' : ''} ${props.tokens} jeton${props.tokens > 1 ? 's' : ''}`}>
            <ButtonBase
                onClick={props.onClick}
                sx={{
                    ...cssStyles.generationLengthButton,
                    ...(props.selected && cssStyles.generationLengthButtonSelected),
                } as SxProps<Theme>}
            >
                {props.text}
            </ButtonBase>
        </Tooltip>
    );
};

export default GenerationButton;
