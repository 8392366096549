import React from 'react';
import {
    useMediaQuery,
    Stepper,
    Step,
    StepButton,
} from '@mui/material';

export type StepperHeaderProps = {
    activeStep: number,
    orientationBreakpoint?: string|undefined,
    headerWidth?: string|number|undefined,
    onStepClick: (stepIndex: number) => void,
    stepsElements: (React.ReactChild | React.ReactFragment | React.ReactPortal)[]
};

const StepperHeader: React.FC<StepperHeaderProps> = props => {
    return (
        <Stepper
            activeStep={props.activeStep}
            orientation={useMediaQuery(`(min-width: ${props.orientationBreakpoint ?? '900px'}`) ? 'horizontal' : 'vertical'}
            sx={props.headerWidth ? { margin: 'auto', maxWidth: props.headerWidth } : undefined}
        >
            {
                props.stepsElements.map((stepElement: any, index: number) => (
                    <Step key={index} completed={index < props.activeStep}>
                        <StepButton onClick={() => props.onStepClick(index)}>
                            {stepElement.props.name}
                        </StepButton>
                    </Step>
                ))
            }
        </Stepper>
    );
};

export default StepperHeader;
