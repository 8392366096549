import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';
import { setImportCsvState } from '../../store/slices/importCsvSlice';
import DisplayCsv from './DisplayCsv';
import ICsvData from '../../interfaces/ICsvData';

const ValidateStep: React.FC = () => {
    // Use of redux hooks
    const dispatch = useDispatch();
    const { expectedHeaders, prefilledColumns, csvData, csvHeaders, sortedHeaders, sortedData, dataValidation } = useAppSelector(state => state.importCsv);

    // useEffect when redux data changes
    React.useEffect(() => {
        // Reset redux sorted data state
        dispatch(setImportCsvState({ loading: true, sortedData: null }));
        let tmpCsvData: ICsvData = [];
        // For each expected header
        for (const [index, header] of Object.entries(expectedHeaders!)) {
            if (sortedHeaders![+index]) {
                // For each line and columns of CSV data
                for (const [line, columns] of Object.entries(csvData!)) {
                    let columnValue = columns[sortedHeaders![+index]];
                    if (dataValidation && dataValidation[header.value]) {
                        // Make sure that the column value is an array if need
                        columnValue = 'array' === dataValidation[header.value].type && columnValue && !Array.isArray(columnValue) ? [columnValue] : columnValue;
                    }
                    // Update CSV data at the current line
                    tmpCsvData[+line] = {
                        // Keep existing data for this line (previous columns)
                        ...tmpCsvData[+line],
                        // Add the structure key with the data from the CSV column
                        [header.value]: columnValue,
                    };
                }
            }
        }

        // Update redux sorted data state
        dispatch(setImportCsvState({ loading: false, sortedData: tmpCsvData }));
    }, [expectedHeaders, prefilledColumns, csvData, csvHeaders, sortedHeaders, dataValidation, dispatch]);

    return (
        <DisplayCsv 
            hasDefinedHeaderKeys
            maxDisplayedRows={10}
            csvData={sortedData ?? []}
            // Show expected headers with user CSV headers
            csvHeader={expectedHeaders!.flatMap((expectedHeader, index) => sortedHeaders![+index] ? `${expectedHeader.label} (${sortedHeaders![index]})` : [])}
        />
    );
};

export default ValidateStep;
