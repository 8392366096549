import React from 'react';
import { useLocation } from 'react-router-dom';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import {
    Tooltip,
    IconButton,
    Stack,
    Typography,
    Divider,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import SaveDraftPost from '../DraftPostEditor/SaveDraftPost';
import KeywordChoice from './KeywordChoice';
import KeywordDisplay from '../KeywordDisplay/KeywordDisplay';
import PostEditor from '../PostEditor/PostEditor';
import Spinner from '../Spinner/Spinner';
import IOptimizationData from '../../interfaces/IOptimizationData';
import IDraftPost from '../../models/IDraftPost';

const RedactTool: React.FC = () => {
    // Use react-router-dom hooks
    const location = useLocation();
    const state: IDraftPost|null  = location.state as IDraftPost|null;

    // Use of hooks
    const [keyword, setKeyword] = React.useState<string|null>(state?.keyword ?? null);
    const [optiData, setOptiData] = React.useState<IOptimizationData|null>(null !== state ? {
        score: state.optimization,
        words: state.optimizationWords,
        prevPlainText: '',
    } : null);

    // useEffect once a keyword is set
    React.useEffect(() => {
        null !== keyword && null === optiData &&
            // Call API to retrieve optimization data around this keyword
            BridgeAPIs.getOptimizationData({ query: keyword })
            // On successfull API CALL
            .then((data: IOptimizationData) => data && setOptiData(data));
    }, [keyword, optiData])

    // Callback triggered when user whant to change his keyword
    const handlePreviousClick = React.useCallback(() => {
        // Reset previous keyword
        setKeyword(null);
        // Reset previous optimization data
        setOptiData(null);
    }, [])

    return (
        null === keyword ? (
            <KeywordChoice onChoice={setKeyword} />
        ) : null !== optiData ? (
            <>
                <Tooltip title='Précédent' placement='right'>
                    <IconButton
                        onClick={handlePreviousClick}
                        sx={{ marginBottom: 3 }}
                    >
                        <ArrowBackIcon fontSize='large' color='secondary' />
                    </IconButton>
                </Tooltip>
                <KeywordDisplay keyword={keyword} />
                <PostEditor initialHtml={state?.html} keyword={keyword} optimizationData={optiData} />
                <Divider sx={{ marginY: 4 }} />
                <SaveDraftPost autosave draftPost={state ?? undefined} keyword={keyword} />
            </>
        ) : (
            <Stack alignItems='center'>
                <Spinner height='30vh' />
                <Typography variant='h6'>
                    {`En attente des données d'optimisation ...`}
                </Typography>
            </Stack>
        )
    );
};

export default RedactTool;
