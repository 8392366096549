import React from 'react';
import { useAppSelector } from '../../store/hooks';
import AccountAPIs from '../../APIs/AccountAPIs';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import SiteAPIs from '../../APIs/SiteAPIs';
import Tools from '../../helpers/Tools';
import { useField } from 'react-final-form';
import FinalAutocomplete from '../FinalComponents/FinalAutocomplete';
import ISelectOption from '../../interfaces/ISelectOption';
import IWpCategory from '../../interfaces/IWpCategory';
import IAccountThematic from '../../models/IAccountThematic';
import ISite from '../../models/ISite';

interface IDestinationPickerValues {
    thematics: Array<ISelectOption>|null,
    sites: Array<ISelectOption>|null,
    categories: Array<ISelectOption>|null,
};

const DestinationPicker: React.FC<{
    thematicLabel?: string|undefined,
    siteLabel?: string|undefined,
    categoryLabel?: string|undefined,
    onCategoriesLoaded?: ((categories: Array<IWpCategory>) => void)|undefined,
}> = props => {
    // Use of hooks
    const [values, setValues] = React.useState<IDestinationPickerValues>({
        thematics: null, sites: null, categories: null
    });
    const mountedRef = React.useRef<boolean>(true);

    // Use of final-form hooks
    const { input: { value: thematicValue }} = useField('thematic', { subscription: { value: true, pristine: true }});
    const { input: { value: siteValue, onChange: siteOnChange }} = useField('website', { subscription: { value: true, pristine: true }});
    const { input: { onChange: categoryOnChange }} = useField('category');

    // Use of redux
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);

    // useEffect when component is mounting
    React.useEffect(() => {
        // API call not already done
        true === mountedRef.current && null === values.thematics &&
            // Search for all thematic of instance account
            AccountAPIs.getAccountThematic({ 'account.id': instanceAccountId })
                // On successful API call
                .then((data: Array<IAccountThematic>|null) => {
                    // Do not update state if component is unmounted
                    if (true === mountedRef.current && null !== data) {
                        const thematics = data.map((accountThematic: IAccountThematic) => accountThematic.thematic);
                        // Set options for thematics
                        setValues({
                            ...values,
                            thematics: Tools.convertToSelectOptions(thematics, 'id', 'name'),
                        });
                    }
                });
    }, [values, instanceAccountId]);

    // useEffect triggered on thematicValue change
    React.useEffect(() => {
        if (true === mountedRef.current) {
            thematicValue ?
                // Search for all sites by given account and selected thematic
                SiteAPIs.getSite({ 'account.id': instanceAccountId, 'thematic.id': thematicValue.value })
                    // On successful API call
                    .then((data: Array<ISite>|null) =>
                        // Do not update state if component is unmounted
                        true === mountedRef.current && null !== data && 0 < data.length &&
                            // Set options for sites
                            setValues(existing => ({
                                ...existing,
                                sites: Tools.convertToSelectOptions(data, 'id', 'domain'),
                            }))
                    )
                // Reset some modal options
                : setValues(existing => ({ ...existing, sites: null, categories: null }));
        }
    }, [instanceAccountId, thematicValue]);

    // useEffect triggered on siteValue change
    React.useEffect(() => {
        if (true === mountedRef.current) {
            siteValue ?
                // Search for all site categories by selected site option
                BridgeAPIs.getWordPressCategoriesBySiteId(siteValue.value)
                    // On successful API call
                    .then((data: Array<IWpCategory>|null) => {
                        // Do not update state if component is unmounted
                        if (true === mountedRef.current && null !== data && 0 < data.length) {
                            props.onCategoriesLoaded && props.onCategoriesLoaded(data);
                            // Set options for categories
                            setValues(existing => ({
                                ...existing,
                                categories: Tools.convertToSelectOptions(data, 'wordPressId', 'name'),
                            }));
                        }
                    })
                // Reset categories options
                : setValues(existing => ({ ...existing, categories: null }));
        }
    }, [props, siteValue]);

    // useEffect when component is unmounting
    React.useEffect(() => () => {
        // Set mountedRef to false;
        mountedRef.current = false;
    }, []);

    return (
        <>
            <FinalAutocomplete
                fullWidth
                upperLabel
                label={props.thematicLabel ?? 'Chercher par thématique de site'}
                name='thematic'
                size='small'
                options={values.thematics ?? []}
                loading={null === values.thematics || 0 === values.thematics.length}
                onChange={() => {
                    // Reset sub fields
                    siteOnChange(null);
                    categoryOnChange(null)
                }}
                wrapperSx={{ marginBottom: 2 }}
            />
            <FinalAutocomplete
                // Disabled till thematics are loaded and selected
                disabled={!Boolean(thematicValue) || null === values.thematics || 0 === values.thematics?.length}
                fullWidth
                upperLabel
                label={props.siteLabel ?? 'Site de destination'}
                name='website'
                size='small'
                options={values.sites ?? []}
                // Not disabled & API call not finished
                loading={Boolean(thematicValue) && (null === values.sites || 0 === values.sites.length)}
                onChange={() => categoryOnChange(null)}
                wrapperSx={{ marginBottom: 2 }}
            />
            <FinalAutocomplete
                // Disabled till sites are loaded and selected
                disabled={!Boolean(siteValue) || null === values.sites || 0 === values.sites.length}
                fullWidth
                upperLabel
                label={props.categoryLabel ?? 'Catégorie de destination sur le site'}
                name='category'
                size='small'
                options={values.categories ?? []}
                // Not disabled & API call not finished
                loading={Boolean(siteValue) && (null === values.categories || 0 === values.categories.length)}
            />
        </>
    );
};

export default DestinationPicker;
