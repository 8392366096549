import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgOrderDuplication: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#F8B64C' d='M491.85,49.183v53.333h-288V15.05c0-7.467,6.4-13.867,13.867-13.867h83.2c7.467,0,13.867,6.4,13.867,13.867v20.267H479.05C485.45,35.316,491.85,41.716,491.85,49.183z' />
            <path fill='#FFD15C' d='M479.05,299.85h-262.4c-7.467,0-12.8-5.333-13.867-12.8l-20.267-169.6c-1.067-8.533,5.333-14.933,13.867-14.933H498.25c8.533,0,14.933,7.467,13.867,14.933l-19.2,169.6C491.85,294.516,485.45,299.85,479.05,299.85z' />
            <rect x='220.92' y='56.65' fill='#F2F2F2' width='253.87' height='45.867' />
            <rect x='237.98' y='73.72' fill='#CDD6E0' width='219.73' height='17.07' />
            <path fill='#F8B64C' d='M308.383,260.383v53.333h-288V226.25c0-7.467,6.4-13.867,13.867-13.867h83.2c7.467,0,13.867,6.4,13.867,13.867v20.267h164.267C303.05,246.516,308.383,251.85,308.383,260.383z' />
            <path fill='#FFD15C' d='M295.583,511.05h-262.4c-7.467,0-12.8-5.333-13.867-12.8l-19.2-169.6c-1.067-8.533,5.333-14.933,13.867-14.933H315.85c8.533,0,14.933,7.467,13.867,14.933l-20.267,169.6C309.45,505.716,303.05,511.05,295.583,511.05z' />
            <rect x='38.516' y='266.78' fill='#F2F2F2' width='253.87' height='45.867' />
            <rect x='54.52' y='284.92' fill='#CDD6E0' width='219.73' height='17.07' />
            <g>
                <path fill='#FF7058' d='M92.916,101.45v36.267h28.8L81.183,178.25L40.65,137.716h30.933V101.45c0-42.667,34.133-77.867,77.867-77.867v21.333C117.45,44.916,92.916,70.516,92.916,101.45z' />
                <path fill='#FF7058' d='M418.25,410.783v-36.267h-28.8l40.533-40.533l40.533,40.533h-30.933v36.267c0,42.667-34.133,77.867-77.867,77.867v-21.333C392.65,467.316,418.25,441.716,418.25,410.783z' />
            </g>
        </SvgIcon>
    );
};

export default SvgOrderDuplication;
