import React from 'react';
import useTimeout from '../../hooks/useTimeout';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import { useAppSelector } from '../../store/hooks';
import { IInstanceWalletState } from '../../store/slices/instanceWalletSlice';
import OtherAPIs from '../../APIs/OtherAPIs';
import PaymentAPIs from '../../APIs/PaymentAPIs';
import Tools from '../../helpers/Tools';
import { Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Spinner from '../Spinner/Spinner';
import InvoicePdf from './InvoicePdf';
import FormikInput from '../FormikComponent/FormikInput';
import IPayment from '../../models/IPayment';
import IUploadFile from '../../models/IUploadFile';

interface IPdfFlagsValues {
    amount: string|null,
    number: string|null,
    disabled?: boolean|undefined,
};

const PaymentForm: React.FC = () => {
    // Use of hooks
    const [invoiceIncNumber, setInvoiceIncNumber] = React.useState<number|null>(null);
    const [pdfFlags, setPdfFlags] = React.useState<IPdfFlagsValues>({
        amount: null,
        number: null,
        disabled: false,
    });
    const [pdfValues, setPdfValues] = React.useState<IPdfFlagsValues>({
        amount: null,
        number: null,
    });
    const formikRef = React.useRef<FormikProps<any>|null>(null);

    // Use of redux
    const instanceWallet: IInstanceWalletState = useAppSelector(state => state.instanceWallet);
    const instanceWalletId: number = useAppSelector(state => state.instance.account!.walletId);

    // Use of react-router-dom
    const navigate = useNavigate();

    // useEffect when component is mounting
    React.useEffect(() => {
        null === invoiceIncNumber &&
            // Call API to retrieve invoice number we need to used for this instance wallet
            PaymentAPIs.getNextInvoiceIncNumber(instanceWalletId)
                // On successful API call, store invoice number in our hook value
                .then((data: number|null) => null !== data && setInvoiceIncNumber(data));
    }, [invoiceIncNumber, instanceWalletId])

    // useTimeout each time pdf flags change
    useTimeout(() => {
        (null !== pdfFlags.amount || null !== pdfFlags.number) &&
            // After some time, clone pdf flags into pdf values
            setPdfValues({ amount: pdfFlags.amount, number: pdfFlags.number });
    }, 1000, [pdfFlags.amount, pdfFlags.number])

    // Define initial values for formik form
    const initialValues: any = {
        amount: '',
        number: '',
        invoicePdf: null,
    };

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        amount: Yup
            .string()
            .required('Veuillez spécifier le montant à récupérer')
            .test(
                'min',
                'Le montant ne peut pas être inférieur à 50 € HT',
                function (amount: string|undefined) {
                    return undefined !== amount && Number(amount) >= 50;
                }
            )
            .test(
                'max',
                "Vous n'avez pas les fonds nécessaires sur votre porte-monnaie",
                function (amount: string|undefined) {
                    return undefined !== amount && Number(instanceWallet.unlocked) >= Number(amount);
                }
            ),
        invoicePdf: Yup
            .mixed()
            .required('La facture ne peut pas être générée')
    });

    // Callback to hande formik submit
    const handleFormikSubmit = React.useCallback(async (values: any) => {
        // Define invoice number
        // By default take the customer's invoice number, else take the generated invoice number
        const invoiceNumber: string = '' !== values.number ? values.number : Tools.transformToInvoiceNumber(invoiceIncNumber!);

        if (null !== values.invoicePdf && 'string' !== typeof values.invoicePdf) {
            // Await API call to upload the pdf blob
            await OtherAPIs.postUploadFile(values.invoicePdf, invoiceNumber)
                // On successful call, change formik value with uploaded file url
                .then((data: IUploadFile) => values.invoicePdf = data.url);
        }

        // Use formik form values to setup a new Payment
        'string' === typeof values.invoicePdf &&
            PaymentAPIs.postPayment({
                wallet: instanceWalletId,
                isCredit: false,
                amount: values.amount,
                reason: 'Récupération de fonds',
                invoiceIncNumber: invoiceIncNumber,
                invoiceNumber: invoiceNumber,
                invoiceUrl: values.invoicePdf,
            })
                // On successful API call, return to PaymentsListing component
                .then((data: IPayment) => null !== data && navigate('/dashboard/invoices'));
    }, [instanceWalletId, invoiceIncNumber, navigate]);

    // useMemo on InvoicePdf as it does not need to be re-rendered with Formik fields
    const MemoizedInstanceInvoiceViewer = React.useMemo(() => (
        null !== invoiceIncNumber ? (
            <InvoicePdf
                height={700}
                width='60%'
                number={
                    pdfValues.number && '' !== pdfValues.number ?
                        pdfValues.number
                        : Tools.transformToInvoiceNumber(invoiceIncNumber)
                }
                amount={pdfValues.amount}
                onBlobChange={blob => {
                    formikRef.current && formikRef.current.setFieldValue('invoicePdf', blob);
                    setPdfFlags(pdfFlags => ({ ...pdfFlags, disabled: false }));
                }}
            />
        ) : null
    ), [pdfValues, invoiceIncNumber])

    return (
        <>
            <Typography variant='h5' paddingBottom={3}>
                {`Nouvelle demande de paiement :`}
            </Typography>
            {
                null !== invoiceIncNumber ? (
                    <Stack direction='row' alignItems='flex-start' justifyContent='space-between' spacing={5}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleFormikSubmit}
                            innerRef={formikRef}
                        >
                            {(formikProps: FormikProps<any>) => (
                                <Stack component={Form} alignItems='flex-start' width='40%'>
                                    <FormikInput
                                        name='amount'
                                        upperLabel='Saisissez le montant à récupérer de vos fonds'
                                        type='number'
                                        placeholder={`${instanceWallet.unlocked} € HT`}
                                        numberFormatProps={{
                                            suffix: ' € HT',
                                            decimalSeparator: '.',
                                            allowNegative: false,
                                            fixedDecimalScale: true,
                                            decimalScale: 2,
                                            thousandSeparator: ' ',
                                        }}
                                        helperText='Le montant ne peut pas être inférieur à 50 € HT'
                                        inputSx={{ maxWidth: 200 }}
                                        sx={{ paddingBottom: 3 }}
                                        onChange={(value: string) => setPdfFlags({ ...pdfFlags, amount: value, disabled: true })}
                                    />
                                    <Typography variant='body2' fontWeight={700}>
                                        {`Par défault, chaque demande de paiement s'accompagne d'une facture dont le numéro
                                        est incrémental et généré automatiquement.`}
                                    </Typography>
                                    <Typography variant='body2'>
                                        {`Si toute fois cette génération automatique ne vous convient pas, ou si vous avez
                                        votre propre système de numération, nous vous invitons à le changer.`}
                                    </Typography>
                                    <FormikInput
                                        name='number'
                                        upperLabel='Numéro de facture'
                                        placeholder='GP-XXXX, 00001, ABCD01 ...'
                                        inputSx={{ maxWidth: 300 }}
                                        helperText='Généré par nos soins si non renseigné'
                                        sx={{ paddingTop: 3 }}
                                        onChange={(value: string) => setPdfFlags({ ...pdfFlags, number: value, disabled: true })}
                                    />
                                    <LoadingButton
                                        variant='contained'
                                        type='submit'
                                        disabled={!formikProps.isValid || pdfFlags.disabled}
                                        loading={formikProps.isSubmitting}
                                        sx={{ marginTop: 4 }}
                                    >
                                        {`Demander le paiement`}
                                    </LoadingButton>
                                </Stack>
                            )}
                        </Formik>
                        {MemoizedInstanceInvoiceViewer}
                    </Stack>
                ) : (<Spinner />)
            }
        </>
    );
};

export default PaymentForm;
