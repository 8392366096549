import React from 'react';
import { useAppSelector } from '../../store/hooks';
import HomeAPIs from '../../APIs/HomeAPIs';
import {
    Stack,
    Typography,
    Avatar,
} from '@mui/material';
import HomePanel from '../Home/HomePanel';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import cssStyles from './HomeEditorStyles';

const PanelOverview: React.FC = () => {
    // Use of hooks
    const [overviewData, setOverviewData] = React.useState<any>(null);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id!);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === overviewData &&
            // Call API to retrieve Posts overview data
            HomeAPIs.getEditorOverview({ id: instanceUserId })
                // On successful API call
                .then((data: any) => null !== data && setOverviewData(data));
    }, [overviewData, instanceUserId])

    return (
        <HomePanel header="vue d'ensemble">
            <Stack direction='row' justifyContent='center'>
                <Stack direction='row' alignItems='center' columnGap={1}>
                    <Typography variant='body2' width={88} align='center'>
                        {`article(s) en rédaction`}
                    </Typography>
                    <Avatar sx={{ ...cssStyles.postNumber, backgroundColor: 'primary.main' }}>
                        {overviewData ? overviewData[EOrderStatuses.TODO] : '_'}
                    </Avatar>
                </Stack>
                <Stack direction='row' alignItems='center' columnGap={1} sx={{ marginBottom: '-1vh', marginLeft: '-1.5vh' }}>
                    <Avatar sx={{ ...cssStyles.postNumber, backgroundColor: 'secondary.main', zIndex: 1 }}>
                        {overviewData ? overviewData[EOrderStatuses.REFUSED] : '_'}
                    </Avatar>
                    <Typography variant='body2' maxWidth={65} align='center'>
                        {`article(s) refusé(s)`}
                    </Typography>
                </Stack>
            </Stack>
            <Stack alignItems='center' rowGap={1} sx={{ marginTop: '-1.2vh', marginRight: '-1vh' }}>
                <Avatar sx={{ ...cssStyles.postNumber, backgroundColor: '#3ecfed' }}>
                    {overviewData ? overviewData[EOrderStatuses.PROPOSED] : '_'}
                </Avatar>
                <Typography variant='body2' maxWidth={110} align='center'>
                    {`article(s) proposé(s)`}
                </Typography>
            </Stack>
        </HomePanel>
    );
};

export default PanelOverview;
