import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    postPaper: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 2,
    },
    container: {
        flexDirection: 'row',
        gap: 3,
        width: 1,
        padding: 2,
    },
    leftSide: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 0.4/3,
        '& p': {
            width: 1,
            wordWrap: 'break-word',
            textAlign: 'center',
        },
    },
    rightSide: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 2.4/3,
        height: 1,
        gap: 1,
        '& > *': {
            width: 1,
        },
    },
};

export default cssStyles;
