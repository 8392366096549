import React from 'react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setStepperIsLoading } from '../../store/slices/activeStepperSlice';
import { openModal } from '../../store/slices/activeModalSlice';
import OtherAPIs from '../../APIs/OtherAPIs';
import PostAPIs from '../../APIs/PostAPIs';
import Tools from '../../helpers/Tools';
import { Button } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import ModalRespectCriterias from '../Modal/ModalRespectCriterias';
import IPostFormStepperData from '../../interfaces/IPostFormStepperData';
import IUploadFile from '../../models/IUploadFile';
import IPost from '../../models/IPost';
import IOrder from '../../models/IOrder';
import EOrderStatuses from '../../interfaces/EOrderStatuses';

const StepperValidation: React.FC<{ order: IOrder, withConstraints: boolean }> = props => {
    // Use of hooks
    const isMounted = React.useRef<boolean>(true);
    const orderCriterias = Tools.getOrderCriterias(props.order);

    // Use of redux
    const dispatch = useAppDispatch();
    const postFormStepperData: IPostFormStepperData = useAppSelector(state => state.activeStepper.data);

    // Use react-router-dom hooks
    const navigate = useNavigate();

    // Handle stepper final validation
    const handleFinalValidation = async () => {
        // Flag for image upload
        let errorImageUploadFile = false;

        // Loading while processing
        dispatch(setStepperIsLoading(true));

        // Create body request with data
        const bodyRequest = {
            status: props.withConstraints ? EOrderStatuses.UNVERIFIED : undefined,
            imageUrl: postFormStepperData.imageUrl,
            title: postFormStepperData.title,
            metaDesc: postFormStepperData.metaDesc,
            // Remove duplicate <p></p> from html
            html: postFormStepperData.html ? Tools.removeDuplicateTags(postFormStepperData.html) : undefined,
            nbrWords: postFormStepperData.nbrWords,
            optimization: postFormStepperData.optimization!.score,
            optimizationWords: postFormStepperData.optimization!.words,
        };

        /*
         * Multiple cases possible :
         *  - If image come from user, try to upload image
         *  - If image did not changed during stepper, it remains as string url on request (no effect)
         *  - If image come from pixabay, send pixabay url & upload on server side
        */
        if (postFormStepperData.imageUrl && postFormStepperData.imageUrl.startsWith('blob:')) {
            // Get image file from object url because redux can't store File instance.
            const imageFile = await fetch(postFormStepperData.imageUrl)
                .then((response: Response) => response.blob());

            // If image file has been successfully retrieved
            if ((imageFile instanceof File || imageFile instanceof Blob) && imageFile.type.includes('image')) {
                // Upload image on server
                await OtherAPIs.postUploadFile(imageFile, props.order.keyword.name)
                    // On successful API call
                    .then((data: IUploadFile|null) => {
                        if (data) {
                            // Update our variable
                            bodyRequest.imageUrl = data.url;
                        } else {
                            // Image upload ended with an exception
                            errorImageUploadFile = true;
                        }
                    });
            } else {
                // File can not be upload as an image
                errorImageUploadFile = true;
            }
        }

        // Do not continue if image upload went wrong
        if (false === errorImageUploadFile) {
            // Patch the post by its ID
            PostAPIs.patchPost(postFormStepperData.identifier!, bodyRequest)
                // On successful API call
                .then((data: IPost|null) => {
                    if (true === isMounted.current) {
                        null !== data ?
                            // Redirect to dashboard
                            navigate('/dashboard')
                            // Else stop stepper loading
                            : dispatch(setStepperIsLoading(false));
                    }
                });
        } else {
            // Stop stepper loading
            dispatch(setStepperIsLoading(false));
        }
    };

    // useEffect when component is unmounted
    React.useEffect(() => () => {
        // Set isMounted to false to prevent states changes
        isMounted.current = false;
    }, []);

    return (
        <>
            {
                0 < orderCriterias.length && (
                    <ModalRespectCriterias
                        modalTitle='Respect des critères'
                        criterias={orderCriterias}
                        onValidation={() => props.order.outdatedAt && moment(props.order.outdatedAt).isBefore(moment()) ?
                            null : handleFinalValidation()
                        }
                    />
                )
            }
            <Button
                variant='contained'
                onClick={
                    () => 0 < orderCriterias.length ?
                        dispatch(openModal({ name: 'respectCriterias' }))
                        : handleFinalValidation()
                }
                endIcon={<CheckCircleIcon />}
            >
                {`Valider`}
            </Button>
        </>
    );
};

export default StepperValidation;
