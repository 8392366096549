import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { canIncrement, decrementStep, incrementStep, resetImportCsvState, setImportCsvState } from '../../store/slices/importCsvSlice';
import { Backdrop, Button, CircularProgress, Stack } from '@mui/material';
import ImportCsvFile from './ImportCsvFile';
import OrganizeColumns from './OrganizeColumns';
import ValidateStep from './ValidateStep';
import ValidateButton from './ValidateButton';
import ICsvEntityStructure from '../../interfaces/ICsvEntityStructure';
import ICsvData, { ICsvColumn } from '../../interfaces/ICsvData';

const maxSteps = 3;

const ImportCsvStepper: React.FC<{
    expectedHeaders: Array<{ label: string, value: string }>,
    dataValidation?: ICsvEntityStructure|null,
    prefilledColumns?: ICsvColumn|undefined,
    onValidation: (data: ICsvData) => void,
 }> = props => {
    // Use of redux hooks
    const dispatch = useAppDispatch();
    const step = useAppSelector(state => state.importCsv.step);
    const loading = useAppSelector(state => state.importCsv.loading);
    const canNext = useAppSelector(canIncrement());

    // Callback triggered at the end of the process
    const handleOnValidation = React.useCallback((csvLinesColumns: ICsvData) => {
        if (props.prefilledColumns) {
            // Add prefilled columns at the end of each CSV line
            csvLinesColumns = Object.values(csvLinesColumns).map((columns) => {
                // Add prefilled columns to final CSV data
                for (const [prefilledHeader, prefilledData] of Object.entries(props.prefilledColumns!)) {
                    columns = {
                        // Keep existing data for this line (previous columns)
                        ...columns,
                        [prefilledHeader]: prefilledData,
                    };
                }

                return columns;
            });
        }

        // Call props validation callback
        props.onValidation(csvLinesColumns);
    }, [props]);

    // On component mount, set expectedHeaders in redux state
    React.useEffect(() => {
        // Remove prefilled columns from expected headers
        let expectedHeaders = props.prefilledColumns ? props.expectedHeaders.filter(header => !Object.keys(props.prefilledColumns!).includes(header.value)) : [ ...props.expectedHeaders ];

        if (props.dataValidation) {
            const dataValidationKeys = Object.keys(props.dataValidation);
            // Ensure that required expected headers are first in the array
            expectedHeaders = expectedHeaders.sort((header1, header2) => {
                const header1Validation = dataValidationKeys.includes(header1.value) ?
                    props.dataValidation![header1.value].required
                    : false;
                const header2Validation = dataValidationKeys.includes(header2.value) ?
                    props.dataValidation![header2.value].required
                    : false;

                return Number(header2Validation) - Number(header1Validation);
            });
        }
        // Update redux state
        dispatch(setImportCsvState({
            expectedHeaders,
            dataValidation: props.dataValidation ?? null,
            prefilledColumns: props.prefilledColumns,
            loading: false,
        }));
    }, [dispatch, props.dataValidation, props.expectedHeaders, props.prefilledColumns]);

    // useEffect when component unmount
    React.useEffect(() => () => {
        dispatch(resetImportCsvState());
    }, [dispatch]);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack gap={2}>
                {
                    1 === step ? (
                        <ImportCsvFile />
                    ) : 2 === step ? (
                        <OrganizeColumns />
                    ) : 3 === step ? (
                        <ValidateStep />
                    ) : null
                }
                <Stack direction='row'>
                    {
                        1 < step && (
                            <Button variant='outlined' onClick={() => dispatch(decrementStep())}>{`Précedent`}</Button>
                        )
                    }
                    {
                        maxSteps > step ? (
                            <Button
                                variant='outlined'
                                disabled={false === canNext}
                                sx={{ marginLeft: 'auto' }}
                                onClick={() => dispatch(incrementStep())}
                            >
                                {`Suivant`}
                            </Button>
                        ) : (
                            <ValidateButton onValidation={handleOnValidation} />
                        )
                    }
                </Stack>
            </Stack>
        </>
    );
};

export default ImportCsvStepper;
