import React from 'react';
import {
    SxProps,
    Theme,
    useTheme,
    SvgIcon,
} from '@mui/material';

const SvgPegaseo: React.FC<{ primary?: boolean|undefined, sx?: SxProps<Theme>|undefined }> = props => {
    // Use of MUI hook
    const theme = useTheme();

    // Define logo colors
    const color1 = props.primary ? theme.palette.secondary.light : '#ccc';
    const color2 = props.primary ? theme.palette.primary.light : '#fff';

    return (
        <SvgIcon
            viewBox='0 0 988.89 297.16'
            xmlns='http://www.w3.org/2000/svg'
            sx={{
                width: '12.3rem',
                height: 'unset',
                ...props.sx,
            }}
        >
            <defs>
                <linearGradient id='gradient' x1='454.86' y1='985.35' x2='877' y2='985.35' gradientUnits='userSpaceOnUse'>
                    <stop offset='0' stopColor={color1} />
                    <stop offset='1' stopColor={color2} />
                </linearGradient>
            </defs>
            <path fill={color1} d='M803.26,967.31h19.57c12.1,0,21.8,7,21.8,19.74s-9.7,19.74-21.8,19.74H811.5v19.74h-8.24Zm8.24,
                7.38v24.72h10.9c7.81,0,13.73-4.38,13.73-12.36s-5.83-12.36-13.73-12.36Z' transform='translate(-454.86 -836.77)'/>
            <path fill={color1} d='M900.42,967.31h38.29v7.38H908.66V992.8h25.75v7.38H908.66v19h30.47v7.38H900.42Z' transform='translate(-454.86 -836.77)'/>
            <path fill={color1} d='M1020.08,966.45a26.56,26.56,0,0,1,18.62,7.38l-5.32,6.09a18.6,18.6,0,0,0-13.3-5.66c-11.08,0-19.23,
                7.81-19.23,22.66s8.41,23.09,19.74,23.09a21.33,21.33,0,0,0,11.67-3.27v-12.27h-12.87v-7.38h21.11v22.4a31.81,31.81,0,0,1-20.85,
                7.9c-15.28,0-27.3-10.82-27.3-30.47S1005,966.45,1020.08,966.45Z' transform='translate(-454.86 -836.77)'/>
            <path fill={color1} d='M1114.84,967.31h7.2l22.84,59.22H1136l-6.18-16.65h-23l-6.18,16.65H1092ZM1127,1002.5,1118.27,979l-8.76,23.52Z' transform='translate(-454.86 -836.77)'/>
            <path fill={color2} d='M1199,1008.76c5.06,4.12,12.19,7,18.45,7,5.58,0,8.76-2.23,
                8.76-5,0-2.41-2.06-5.07-11.08-8-14.07-4.54-19.65-11.33-19.65-19.14,0-9.44,7.64-17.33,22.32-17.33a40.34,40.34,0,0,1,21.37,
                6.43l-6.1,10.9a28.91,28.91,0,0,0-16.39-5.66c-4.81,0-7.38,2.14-7.38,4.55,0,2.66,2.66,4.8,10.73,7.47,14.59,4.8,20.34,11.15,
                20.34,19.82,0,9.44-8,17.77-24.38,17.77-8.07,0-16.22-2.75-23.51-7.38Z' transform='translate(-454.86 -836.77)'/>
            <path fill={color2} d='M1294.48,967.31h41.63v12h-27.89V991h23.86v11.33h-23.86v12.18h28.32v12h-42.06Z' transform='translate(-454.86 -836.77)'/>
            <path fill={color2} d='M1415.68,966.28c15.45,0,28.07,11.5,28.07,30.64s-12.62,30.64-28.07,30.64-28.07-11.5-28.07-30.64S1400.23,
                966.28,1415.68,966.28Zm0,49c8.07,0,14.08-6,14.08-18.37s-6-18.37-14.08-18.37-14.08,6-14.08,18.37S1407.61,1015.29,1415.68,1015.29Z' transform='translate(-454.86 -836.77)'/>
            <path fill='url(#gradient)' d='M623.29,1133.93q-4.45,0-8.91-.35c-43.2-3.44-85.62-29.52-110.71-68a146.74,146.74,0,0,1-12.17-22.78,46.69,
                46.69,0,0,0-13.87,11.37c-10.92,13.39-9.11,30.23-9.09,30.38l-13.42,0a54.06,54.06,0,0,1,11.86-39,61.08,61.08,0,0,1,19.75-15.7c-9-28.92-8.34-60,2.34-90.55,
                34.8-99.45,167.48-102.3,173.11-102.38l5.47-.06,1.29,5.31c.38,1.54,3.28,15.9-10.45,44.08-.41.85-.84,1.67-1.28,2.47,15,3.93,29.72,
                11.17,33.23,22.31,5.43,17.26,36.24,54.8,47.55,60.26,9.74,4.71,12.24,10.27,12,15,1.75,2.77,3.26,7.31,5.7,14.62,5.66,17-2.18,26.92-16.66,
                37.45-16,11.65-34.6-7.35-43.54-16.48-1.21-1.24-2.66-2.72-3.52-3.51-1.56-.38-3.89-1.08-8.13-2.4-11.73-3.64-33.57-10.41-66.28-17.63A76.67,
                76.67,0,0,1,597.44,991c-8.61,19.95-27.9,39.38-65.79,41.75a116.19,116.19,0,0,0-27.49,4.71,132.58,132.58,0,0,0,11,20.62c22.82,35,61.26,
                58.73,100.31,61.85,32,2.54,65.82-11.45,101.61-26.26,47.64-19.72,98.72-42.65,159.83-29.78l.09,14c-57-12-106.63,8.57-154.67,28.45C688.11,
                1120.48,655.62,1133.93,623.29,1133.93ZM601.74,977.58a60.21,60.21,0,0,0,18.79,7.28c33.27,7.34,55.46,14.22,67.38,17.92,3.44,1.06,6.69,
                2.07,7.52,2.22,3.14.31,5.25,2.47,9.88,7.2,5.76,5.88,19.24,19.65,25.66,15,14.62-10.64,14-15.08,11.71-22-1.54-4.61-2.81-8.44-3.57-10.26a7.27,
                7.27,0,0,1-2.47-2.43,7.48,7.48,0,0,1-.56-6.4,16.92,16.92,0,0,0-4.06-2.52c-15.33-7.4-48.54-49-54.68-68.5-.85-2.71-5.44-6.94-15.8-10.72A92.94,
                92.94,0,0,0,648,900.56c-10.87,10-24.2,11.54-32.16,7.75-6.35-3-8.85-9.34-6-15.36,2.88-6.21,11.43-8.78,26.13-7.82,2.17.14,4.5.37,6.92.69a38.77,
                38.77,0,0,0,3.37-5.67c6.8-14,8.85-23.64,9.39-29.23-27,2-126.17,14.62-153.54,92.82-9.52,27.21-10.26,55-2.51,80.78a129,129,0,0,
                1,31.29-5.49c36.05-2.26,49.91-21.9,55.21-36.08-12.28-11.13-16.12-25-14.9-34.31,1.25-9.57,7.68-15.68,16-15.4,9.56.42,16.09,12.14,
                16.26,29.17A69.33,69.33,0,0,1,601.74,977.58Zm-15.69-30.5c-1,.62-1.34,3.31-1.34,3.34-.5,3.77.7,9.78,4.84,15.85C590.15,956.41,587.91,949.11,586.05,947.08Z' transform='translate(-454.86 -836.77)'/>
        </SvgIcon>
    );
};

export default SvgPegaseo;
