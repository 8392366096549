import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * Ipv4APIs class.
 */
export default class Ipv4APIs extends AbstractAuthAPIs {
    /**
     * Get collection of Ipv4 ressources.
     *
     * @param query
     */
    public static async getIpv4(query: any) {
        return GenericAPIs.apiGetCall('ipv4s', this.getApiKey(), query);
    }

    /**
     * Post an Ipv4 resource.
     *
     * @param body
     */
    public static async postIpv4(body: any) {
        return GenericAPIs.apiPostCall('ipv4s', body, this.getApiKey());
    }

    /**
     * Delete an Ipv4 resource.
     *
     * @param id
     */
    public static async deleteIpv4(id: number) {
        return GenericAPIs.apiDeleteCall(`ipv4s/${id}`, this.getApiKey());
    }
};
