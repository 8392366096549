import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgPostAwaitingValidation: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <g>
                <rect x='79.969' y='116.051' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -65.0447 106.0039)' fill='#334A5E' width='30.933' height='30.933' />
                <rect x='401.59' y='116.359' transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 618.7449 519.9428)' fill='#334A5E' width='30.933' height='30.933' />
            </g>
            <path fill='#40596B' d='M295.467,61.867h-78.933C199.467,61.867,185.6,48,185.6,30.933S199.467,0,216.533,0h78.933C312.533,0,326.4,13.867,326.4,30.933S312.533,61.867,295.467,61.867z M216.533,16C208,16,201.6,22.4,201.6,30.933s6.4,14.933,14.933,14.933h78.933c8.533,0,14.933-6.4,14.933-14.933S304,16,295.467,16H216.533z' />
            <path fill='#334A5E' d='M272,86.4h-30.933V43.733c0-6.4,4.267-10.667,10.667-10.667h9.6c6.4,0,10.667,4.267,10.667,10.667V86.4H272z' />
            <g>
                <path fill='#40596B' d='M101.333,116.267l-22.4,22.4c-4.267,4.267-10.667,4.267-14.933,0l-6.4-6.4c-4.267-4.267-4.267-10.667,0-14.933l22.4-22.4c4.267-4.267,10.667-4.267,14.933,0l6.4,6.4C105.6,104.533,105.6,112,101.333,116.267z' />
                <path fill='#40596B' d='M410.667,116.267l22.4,22.4c4.267,4.267,10.667,4.267,14.933,0l6.4-6.4c4.267-4.267,4.267-10.667,0-14.933l-22.4-22.4c-4.267-4.267-10.667-4.267-14.933,0l-6.4,6.4C406.4,104.533,406.4,112,410.667,116.267z' />
                <circle fill='#40596B' cx='256' cy='292.27' r='219.73' />
            </g>
            <path fill='#F2F2F2' d='M256,460.8c-92.8,0-168.533-75.733-168.533-168.533S163.2,123.733,256,123.733s168.533,75.733,168.533,168.533S348.8,460.8,256,460.8z' />
            <g>
                <rect x='249.6' y='134.4' fill='#4CDBC4' width='11.733' height='26.667' />
                <rect x='249.6' y='423.47' fill='#4CDBC4' width='11.733' height='27.733' />
                <rect x='344.526' y='184.351' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -29.533 309.1353)' fill='#4CDBC4' width='27.733' height='11.733' />
                <rect x='139.433' y='388.535' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -233.9834 223.9164)' fill='#4CDBC4' width='27.733' height='11.733' />
                <rect x='387.2' y='286.93' fill='#4CDBC4' width='27.733' height='11.733' />
                <rect x='98.13' y='286.93' fill='#4CDBC4' width='27.733' height='11.733' />

                <rect x='344.822' y='388.59' transform='matrix(0.7071 0.7071 -0.7071 0.7071 383.9806 -138.0976)' fill='#4CDBC4' width='27.733' height='11.733' />
                <polygon fill='#4CDBC4' points='167.467,196.267 158.933,203.733 142.933,188.8 139.733,184.533 148.267,176 152.533,180.267 	' />
            </g>
            <path fill='#334A5E' d='M331.733,215.467c-11.733,20.267-25.6,38.4-41.6,54.4l-9.6,9.6L268.8,291.2c0-6.4-6.4-11.733-12.8-11.733h-1.067l12.8-12.8l9.6-9.6C293.333,241.067,312.533,227.2,331.733,215.467z' />
            <path fill='#FF7058' d='M280.533,280.533c-3.2-5.333-7.467-9.6-12.8-12.8c-3.2-1.067-7.467-2.133-10.667-2.133c-14.933,0-27.733,11.733-27.733,27.733c0,14.933,12.8,27.733,27.733,27.733c14.933,0,27.733-12.8,27.733-27.733C283.733,288,282.667,283.733,280.533,280.533z M256,305.067c-7.467,0-12.8-5.333-12.8-12.8c0-6.4,5.333-11.733,11.733-12.8H256c6.4,0,12.8,5.333,12.8,11.733c0,0,0,0,0,1.067C268.8,299.733,263.467,305.067,256,305.067z' />
            <path fill='#E4EAEF' d='M256,145.067c89.6,0,163.2,70.4,168.533,157.867c0-3.2,1.067-7.467,1.067-10.667c0-92.8-75.733-168.533-168.533-168.533S88.533,199.467,88.533,292.267c0,3.2,0,7.467,1.067,10.667C92.8,215.467,166.4,145.067,256,145.067z' />
            <g>
                <path fill='#3DC9AE' d='M148.267,176l4.267,4.267c-3.2,2.133-6.4,5.333-8.533,7.467l-3.2-3.2L148.267,176z' />
                <path fill='#3DC9AE' d='M249.6,134.4h11.733v10.667c-2.133,0-4.267,0-6.4,0s-4.267,0-6.4,0V134.4H249.6z' />
                <path fill='#3DC9AE' d='M372.267,184.533l-3.2,3.2c-3.2-3.2-6.4-5.333-9.6-7.467l4.267-4.267L372.267,184.533z' />
            </g>
        </SvgIcon>
    );
};

export default SvgPostAwaitingValidation;
