import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveStepperData, setStepperIsLoading } from '../../store/slices/activeStepperSlice';
import { ITextEditorState } from '../../store/slices/textEditorSlice';
import PostAPIs from '../../APIs/PostAPIs';
import Tools from '../../helpers/Tools';
import { Divider, Typography } from '@mui/material';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IPostFormStepperData from '../../interfaces/IPostFormStepperData';
import IOrder from '../../models/IOrder';
import IPost from '../../models/IPost';

interface IErrorFlags {
    optiError: boolean,
    nbrWordsError: boolean,
    htmlError: boolean,
};

const ContentEditionValidation: React.FC<{
    order: IOrder,
    withConstraints: boolean,
    stepValidationRef: React.MutableRefObject<(() => void)|undefined>,
}> = props => {
    // Use of hooks
    const [errorFlags, setErrorFlags] = React.useState<IErrorFlags>({ optiError: false, nbrWordsError: false, htmlError: false });

    // Use of redux
    const dispatch = useAppDispatch();
    const textEditorState: ITextEditorState = useAppSelector(state => state.textEditor);
    const postFormStepperData: IPostFormStepperData = useAppSelector(state => state.activeStepper.data);

    // Callback to handle step validation
    props.stepValidationRef.current = () => {
        // If there is no error or user is admin
        if (
            false === props.withConstraints
            || (false === errorFlags.optiError && false === errorFlags.nbrWordsError && false === errorFlags.htmlError)
        ) {
            // Load during API call
            dispatch(setStepperIsLoading(true));
            // Save post in database
            const bodyRequest = {
                // Do not update status if user is admin
                status: props.withConstraints && EOrderStatuses.CORRECTED !== props.order.status.slug && EOrderStatuses.SAVED !== props.order.status.slug ?
                    EOrderStatuses.REFUSED === props.order.status.slug ?
                        EOrderStatuses.CORRECTED : EOrderStatuses.SAVED
                    : undefined,
                title: postFormStepperData.title,
                metaDesc: postFormStepperData.metaDesc,
                // Remove duplicate <p></p> from html
                html: Tools.removeDuplicateTags(textEditorState.content.html ?? ''),
                nbrWords: textEditorState.content.nbrWords,
                optimization: textEditorState.optimization?.score,
                optimizationWords: textEditorState.optimization?.words,
            };

            // Patch the post by its ID
            PostAPIs.patchPost(postFormStepperData.identifier!, Tools.convertToBodyRequest(bodyRequest))
                // On successful API call
                .then((data: IPost|null) => {
                    if (null !== data) {
                        const postData = {
                            html: data.html,
                            nbrWords: data.nbrWords,
                            optimization: textEditorState.optimization
                        };
                        // Store values under data field of active stepper redux state
                        // & increment active step
                        dispatch(setActiveStepperData({ data: postData, action: 'inc' }));
                    }
                })
                // In any cases, stop loading
                .finally(() => dispatch(setStepperIsLoading(false)));
        } else {
            // Scroll to bottom to show errors
            Tools.scrollInDashboard('bottom');
        }
    };

    // useEffect when TextEditor content changes to check for errors
    React.useEffect(() => {
        // Check if nbrWords does not meet minimal requirement
        const tmpNbrWordsError = textEditorState.content.nbrWords < props.order.minNbrWords;
        // Check if html value changed
        const tmpHtmlError = null !== props.order.minOptimization &&
            undefined !== textEditorState.optimization.prevPlainText &&
            textEditorState.optimization.prevPlainText !== textEditorState.content.plainText;
        // Check if optimization score does not meet minimal requirement
        const tmpOptiError = null !== props.order.minOptimization &&
            (!textEditorState.optimization.score || textEditorState.optimization.score < props.order.minOptimization);
        // Update errorFlags state
        setErrorFlags(errors => ({
            ...errors,
            nbrWordsError: tmpNbrWordsError,
            htmlError: tmpHtmlError,
            optiError: tmpOptiError,
        }));
    }, [props.order.minNbrWords, props.order.minOptimization, textEditorState]);

    return (
        <>
            <Divider sx={{ marginY: 3 }} />
            <Typography>
                {`Nombre de mots total : ${textEditorState.content.nbrWords} / ${props.order.minNbrWords}`}
            </Typography>
            {
                props.withConstraints && undefined !== props.order.minOptimization && true === errorFlags.optiError && (
                    <Typography color='error'>
                        {`L'article ne respecte pas le score d'optimisation minimum de ${props.order.minOptimization}.`}
                    </Typography>
                )
            }
            {
                props.withConstraints && true === errorFlags.nbrWordsError && (
                    <Typography color='error'>
                        {`L'article ne respecte pas le nombre de mots minimum de ${props.order.minNbrWords}.`}
                    </Typography>
                )
            }
            {
                props.withConstraints && true === errorFlags.htmlError && (
                    <Typography color='error'>
                        {`Le contenu de l'éditeur de texte a changé, veuillez recharger le score d'optimisation.`}
                    </Typography>
                )
            }
        </>
    );
};

export default ContentEditionValidation;
