import React from 'react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumn, AgGridReact, AgGridColumnProps } from 'ag-grid-react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { agGridLocaleFr, customColumnTypes } from '../../configs/dataGrid';
import Tools from '../../helpers/Tools';
import {
    SxProps,
    useTheme,
    Theme,
    Stack,
    Box,
    Button,
    Typography,
} from '@mui/material';
import {
    DeleteForeverRounded as DeleteForeverRoundedIcon,
    FilterAltOutlined as FilterAltOutlinedIcon,
} from '@mui/icons-material';
import ManagerRenderer from './ManagerRenderer';
import OnlineRenderer from './OnlineRenderer';
import HttpsRenderer from './HttpsRenderer';
import UserRenderer from './UserRenderer';
import PasswordRenderer from './PasswordRenderer';
import ColoredChipRenderer from './ColoredChipRenderer';
import CopyCellRenderer from './CopyCellRenderer';
import AccountRenderer from './AccountRenderer';
import ModalConfirmation from '../Modal/ModalConfirmation';
import SplitButton from '../SplitButton/SplitButton';
import IRowMenuOption from '../../interfaces/IRowMenuOption';
import IRowData from '../../interfaces/IRowData';
import ISplitButtonOption from '../../interfaces/ISplitButtonAction';
import './DataGrid.scss';

const DataGrid: React.FC<{
    innerRef?: React.MutableRefObject<GridApi|null>|undefined,
    rowName: string,
    rowData: Array<any>,
    columnData: Array<AgGridColumnProps>,
    rowHeight?: number|undefined,
    height?: number|string|undefined,
    checkableRows?: boolean|undefined,
    rowMenuOptions?: Array<IRowMenuOption>|undefined,
    deleteMessage?: string|undefined,
    gridEmptyMessage?: string|undefined,
    addElementButtonText?: string|undefined,
    deleteElementButtonText?: string|undefined,
    loading?: boolean|undefined,
    sx?: SxProps<Theme>|undefined,
    selectionActions?: ((data: Array<any>) => Array<ISplitButtonOption>)|undefined,
    onSelectionChanged?: ((data: Array<any>) => void)|undefined,
    onDeleteSelection?: ((data: Array<number>) => void)|undefined,
    canDeleteSelection?: ((data: Array<any>) => boolean)|undefined,
    onAddElement?: (() => void)|undefined,
    onDeleteRow?: ((id: number) => void)|undefined,
}> = props => {
    // Use of mui theme'
    const theme = useTheme();

    // Use of hooks
    const [gridApi, setGridApi] = React.useState<GridApi|null>(null);
    const [selectedRows, setSelectedRows] = React.useState<Array<any>>([]);

    // Use of redux
    const dispatch = useAppDispatch();

    // Callback triggered when AgGridReact is ready to use
    const handleGridReady = React.useCallback((params: GridReadyEvent) => {
        // Setup grid api hook
        setGridApi(params.api);

        // Setup given prop ref if given
        if (props.innerRef) {
            props.innerRef.current = params.api;
        }
    }, [props.innerRef]);

    // Callback whenever grid selection change
    const handleSelectionChanged = React.useCallback((api: GridApi) => {
        // Retrieve selected rows data
        const rowsData = api.getSelectedRows();
        // Change selected data in hook
        setSelectedRows(rowsData);
        // Call given prop callback if it exists
        props.onSelectionChanged && props.onSelectionChanged(rowsData);
    }, [props])

    // Callback triggered when modal confirmation is validated for selection
    const handleSelectionDelete = React.useCallback(() => {
        // Remove selected rows in grid
        null !== gridApi && gridApi.applyTransaction({ remove: selectedRows });
        // Call given prop callback if it exists
        props.onDeleteSelection && props.onDeleteSelection(selectedRows.map(selectedRow => selectedRow.id));
    }, [props, gridApi, selectedRows]);

    // Callback triggered when modal confirmation is validated for single row
    const handleRowDelete = React.useCallback((rowData: IRowData) => {
        if (null !== gridApi) {
            // Retrieve node to remove from given row data
            const nodeToRemove = gridApi.getRowNode(`${rowData.index}`);
            // Remove selected site in grid
            gridApi.applyTransaction({ remove: [nodeToRemove!.data] });
        }
        // Call given prop callback if it exists
        props.onDeleteRow && props.onDeleteRow(rowData.data.id);
    }, [props, gridApi])

    // useEffect when loading value changes
    React.useEffect(() => {
        if (undefined !== props.loading && gridApi) {
            true === props.loading ?
                // Show loading overlay
                gridApi.showLoadingOverlay()
                // Hide overlay
                : gridApi.hideOverlay();
        }
    }, [gridApi, props.loading]);

    return (
        <Stack alignItems='flex-start' spacing={2} sx={props.sx}>
            <Box height={props.height ?? 600} width={1}>
                <AgGridReact
                    icons={{
                        menu: () => Tools.renderComponent(<FilterAltOutlinedIcon fontSize='small' />),
                    }}
                    animateRows
                    pagination
                    paginationAutoPageSize
                    enableCellTextSelection
                    suppressRowClickSelection
                    className={`ag-theme-alpine-${theme.palette.mode}`}
                    rowHeight={props.rowHeight ?? 50}
                    headerHeight={50}
                    localeText={{
                        ...agGridLocaleFr,
                        ...{
                            noRowsToShow: props.gridEmptyMessage ?? 'Aucun résultat',
                            noMatches: props.gridEmptyMessage ?? 'Aucun résultat'
                        }
                    }}
                    rowData={props.rowData}
                    rowSelection={props.checkableRows ? 'multiple' : undefined}
                    columnTypes={customColumnTypes}
                    components={{
                        managerRenderer: ManagerRenderer,
                        onlineRenderer: OnlineRenderer,
                        httpsRenderer: HttpsRenderer,
                        userRenderer: UserRenderer,
                        passwordRenderer: PasswordRenderer,
                        coloredChipRenderer: ColoredChipRenderer,
                        copyCellRenderer: CopyCellRenderer,
                        accountRenderer: AccountRenderer,
                    }}
                    onGridReady={handleGridReady}
                    onSelectionChanged={params => handleSelectionChanged(params.api)}
                >
                    {
                        0 < props.rowData.length && (props.checkableRows || props.rowMenuOptions) && (
                            <AgGridColumn
                                cellRendererParams={{ options: props.rowMenuOptions }}
                                type={
                                    (props.checkableRows && props.rowMenuOptions) ?
                                        'checkAndManage'
                                        : (props.checkableRows && !props.rowMenuOptions) ?
                                            'check'
                                            : 'manage'
                                }
                            />
                        )
                    }
                    {
                        props.columnData.map((columnProps: AgGridColumnProps, index: number) => (
                            <AgGridColumn
                                key={index}
                                {...columnProps}
                                lockPinned // Column cannot be pinned by dragging the column.
                                headerTooltip={columnProps.headerName}
                                minWidth={columnProps.width ?? 120}
                                maxWidth={400}
                            />
                        ))
                    }
                </AgGridReact>
            </Box>
            {
                // Display if AgGrid is ready
                null !== gridApi && (
                    <Stack direction='row' spacing={2}>
                        {
                            props.onDeleteRow && (
                                <ModalConfirmation
                                    name='confirmModalDataGrid'
                                    title='Confirmer la suppression'
                                    message={(
                                        <>
                                            <Typography align='center' paddingBottom={1}>
                                                {`Êtes-vous sûr de vouloir supprimer cet élément ?`}
                                            </Typography>
                                            {
                                                props.deleteMessage && (
                                                    <Typography align='center' variant='body2' color='textSecondary' paddingBottom={3}>
                                                        {props.deleteMessage}
                                                    </Typography>
                                                )
                                            }
                                        </>
                                    )}
                                    onValidation={handleRowDelete}
                                />
                            )
                        }
                        {
                            props.onAddElement && (
                                <Button
                                    variant='outlined'
                                    size='small'
                                    color='secondary'
                                    onClick={() => props.onAddElement && props.onAddElement()}
                                >
                                    {props.addElementButtonText ?? `Ajouter un élément`}
                                </Button>
                            )
                        }
                        {
                            props.checkableRows && props.onDeleteSelection && (
                                <>
                                    <ModalConfirmation
                                        name='__confirmModal'
                                        title='Confirmer la suppression'
                                        message={(
                                            <>
                                                <Typography align='center' paddingBottom={1}>
                                                    {`Êtes-vous sûr de vouloir supprimer ${selectedRows.length} ${props.rowName}(s) ?`}
                                                </Typography>
                                                {
                                                    props.deleteMessage && (
                                                        <Typography align='center' variant='body2' color='textSecondary' paddingBottom={3}>
                                                            {props.deleteMessage}
                                                        </Typography>
                                                    )
                                                }
                                            </>
                                        )}
                                        onValidation={handleSelectionDelete}
                                    />
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        color='error'
                                        disabled={
                                            0 === selectedRows.length ||
                                            (props.canDeleteSelection ? false === props.canDeleteSelection(selectedRows) : false)
                                        }
                                        startIcon={<DeleteForeverRoundedIcon />}
                                        onClick={() => dispatch(openModal({ name: '__confirmModal' }))}
                                    >
                                        {props.deleteElementButtonText ?? `Supprimer la sélection`}
                                    </Button>
                                </>
                            )
                        }
                        {
                            // If secondaryActions, regroup delete action and secondary action in a SplitButton
                            props.selectionActions && 0 < props.selectionActions.length && (
                                <SplitButton
                                    label='Autre action'
                                    width={300}
                                    disabled={0 === selectedRows.length}
                                    options={props.selectionActions(selectedRows)}
                                />
                            )
                        }
                    </Stack>
                )
            }
        </Stack>
    );
};

export default DataGrid;
