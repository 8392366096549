import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import OrderAPIs from '../../APIs/OrderAPIs';
import {
    Button,
    Divider,
    Stack,
    Typography
} from '@mui/material';
import DisplayCard from '../DisplayCard/DisplayCard';
import ModalSelectAccountUser from '../Modal/ModalSelectAccountUser';
import IOrderFormStepperData from '../../interfaces/IOrderFormStepperData';
import IUser from '../../models/IUser';
import IOrderShare from '../../models/IOrderShare';

const Share: React.FC<{ stepValidationRef: React.MutableRefObject<(() => void)|undefined> }> = props => {
    // Use of redux
    const dispatch = useAppDispatch();
    const orderFormState: IOrderFormStepperData = useAppSelector(state => state.activeStepper.data);
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Use of hooks
    const [selectedUsers, setSelectedUsers] = React.useState<Array<IUser>|undefined>(orderFormState.shares);

    // Callback to hande submit
    props.stepValidationRef.current = () => {
        // Store values under data field of active stepper redux state
        // & increment active step
        dispatch(setActiveStepperData({ data: { shares: selectedUsers }, action: 'inc' }));
    };

    // useEffect when component is mounting
    React.useEffect(() => {
        // Search for existing OrderShare resources if state is undefined
        orderFormState.idOrder && !orderFormState.shares &&
            OrderAPIs.getOrderShare({ order: orderFormState.idOrder })
                // On successful API call
                .then((data: Array<IOrderShare>|null) => {
                    // Update selectedUsers state
                    data && setSelectedUsers(data.map(share => share.user))
                });
    }, [orderFormState]);

    return (
        <>
            <ModalSelectAccountUser
                modalName='selectUsersShareModal'
                selectUsers={selectedUsers?.map(user => user.id)}
                excludeUsers={[instanceUserId]}
                scopes={['account_owner', 'can_make_order']}
                onValidation={(_, data: Array<IUser>|null) => setSelectedUsers(data ?? undefined)}
            />
            <Typography>
                {`Vous pouvez partager une commande avec un autre utilisateur du compte.`}
            </Typography>
            <Typography>
                {`La commande partagée sera visible sur l'ensemble des commandes de cet utilisateur.`}
            </Typography>
            <Typography paddingBottom={3}>
                {`Ce dernier se voit attribuer les mêmes droits que le propriétaire de la commande.`}
            </Typography>
            <Button
                variant='outlined'
                color='secondary'
                onClick={() => dispatch(openModal({ name: 'selectUsersShareModal' }))}
            >
                {`Sélectionner les utilisateurs du compte`}
            </Button>
            {
                undefined !== selectedUsers && 0 < selectedUsers.length && (
                    <>
                        <Divider sx={{ marginY: 3 }} />
                        <Typography variant='body1'>
                            {`La commande sera partagée avec les utilisateurs suivants :`}
                        </Typography>
                        <Stack direction='row' alignItems='stretch' paddingTop={2} spacing={2}>
                            {
                                selectedUsers.map((user: IUser) => (
                                    <DisplayCard
                                        key={user.id}
                                        title={`${user.name} ${user.lastname}`}
                                        image={user.avatar ?? undefined}
                                        onDelete={() => setSelectedUsers((data) => data!.filter((value: IUser) => value.id !== user.id))}
                                    />
                                ))
                            }
                        </Stack>
                    </>
                )
            }
        </>
    );
};

export default Share;
