import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { isDashboardMenuExtensionOpen } from '../../store/slices/dashboardMenuSlice';
import {
    SxProps,
    Theme,
    Stack,
    Box,
    SvgIconTypeMap,
    useTheme,
    ListItem,
    Tooltip,
    ListItemText,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import makeCssStyles from './DashboardMenuStyles';

const DashboardMenuItem: React.FC<{
    menuIsOpened: boolean,
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>,
    text: string,
    to?: string|undefined,
    iconNotMui?: boolean|undefined,
    extensionOnHover?: string|undefined,
    isEnd?: boolean|undefined,
}> = props => {
    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme);

    // Use of hooks
    const [selected, isSelected] = React.useState<boolean>(false);

    // Use of redux
    const dispatch = useAppDispatch();

    // Load props icon as component
    const Icon = props.icon;
    const ItemIcon: JSX.Element = (
        <Stack className='iconWrapper' sx={cssStyles.listItemWrapperIcon}>
            <Box component={Icon} sx={cssStyles.listItemIcon} />
        </Stack>
    );

    // Function to handle related menu extension display
    function handleMenuExtension(extensionName: string|null|undefined) {
        // If menu item is linked with a menu extension
        if (undefined !== extensionName) {
            // Either open or close the menu extension
            dispatch(isDashboardMenuExtensionOpen(extensionName));
            // If a menu extension has been opened then the menu item is selected
            isSelected(null !== extensionName);
        }
    };

    return (
        <ListItem
            // props.to can be undefined because item openening DME may not redirect
            component={props.to ? NavLink : Box}
            to={props.to}
            onMouseEnter={() => handleMenuExtension(props.extensionOnHover)}
            onMouseLeave={() => handleMenuExtension(null)}
            end={props.isEnd}
            className={selected ? 'active' : undefined}
            sx={{
                ...cssStyles.listItem,
                ...(false === props.menuIsOpened && { justifyContent: 'center' }),
            } as SxProps<Theme>}
        >
            {
                props.menuIsOpened ? (
                    ItemIcon
                ) : (
                    <Tooltip title={props.text} placement='right'>
                        {ItemIcon}
                    </Tooltip>
                )
            }
            {
                props.menuIsOpened && (
                    <ListItemText disableTypography sx={cssStyles.listItemText}>
                        {props.text}
                    </ListItemText>
                )
            }
        </ListItem>
    );
};

export default DashboardMenuItem;