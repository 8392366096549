import React from 'react';
import OtherAPIs from '../../APIs/OtherAPIs';
import Tools from '../../helpers/Tools';
import { Grid, Box, Typography } from '@mui/material';
import FormikAutocomplete from '../FormikComponent/FormikAutocomplete';
import ISyntaxLevel from '../../models/ISyntaxLevel';

const SelectSyntaxLevel: React.FC = () => {
    // Use of hooks
    const [syntaxLevels, setSyntaxLevels] = React.useState<Array<ISyntaxLevel>|null>(null);
    const mountedRef = React.useRef<boolean>(true);

    // useEffect when component is mounting
    React.useEffect(() => {
        // Call API to fetch syntax levels
        null === syntaxLevels &&
            OtherAPIs.getSyntaxLevels()
            // On successful API call change syntaxLevels hook value
            .then((activitySectors: Array<ISyntaxLevel>) =>
                // Do not update state if component is unmounted
                true === mountedRef.current && setSyntaxLevels(activitySectors)
            );

        return () => {
            // Set mountedRef to false when component is unmounting
            mountedRef.current = false;
        };
    }, [syntaxLevels]);

    return (
        <Grid container columnSpacing={8} rowSpacing={3}>
            <Grid item xs={12} md={6}>
                <FormikAutocomplete
                    upperlabel="Niveau d'orthographe pour la rédaction de l'article"
                    name='syntaxLevel'
                    loading={null === syntaxLevels}
                    options={Tools.convertToSelectOptions(syntaxLevels, 'id', 'name')}
                />
            </Grid>
            {
                null !== syntaxLevels && (
                    <Grid item xs={12} md={6}>
                        <Box component='ul'>
                            {
                                syntaxLevels.map((syntaxLevel: ISyntaxLevel, index: number) => (
                                    <Box key={index} component='li'>
                                        <Typography variant='body2' fontWeight='700'>
                                            {`${syntaxLevel.name} - `}
                                            <Typography variant='body2' component='span' color='text.secondary'>
                                                {syntaxLevel.description}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default SelectSyntaxLevel;
