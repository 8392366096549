import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import UserAPIs from '../../APIs/UserAPIs';
import stringToIcon from '../../configs/stringToIcon';
import {
    Avatar,
    Button,
    Paper,
    Rating,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { FormatQuote as FormatQuoteIcon } from '@mui/icons-material';
import RouterLink from '../RouterLink/RouterLink';
import Spinner from '../Spinner/Spinner';
import EAccountTypes from '../../interfaces/EAccountTypes';
import IActivitySector from '../../models/IActivitySector';
import IUser from '../../models/IUser';

const UsersListing: React.FC = () => {
    // Use of hooks
    const [users, setUsers] = React.useState<Array<IUser>|null>(null);

    // Use of redux
    const instanceUserId = useAppSelector(state => state.instance.user!.id);

    // Use of react-router-dom
    const navigate = useNavigate();

    // useEffect to retrieve editor Users
    React.useEffect(() => {
        null === users &&
            UserAPIs.getUser({ accountType: EAccountTypes.EDITOR })
                .then(response => null !== response && setUsers(response.data));
    }, [instanceUserId, users]);

    return (
        null !== users ? (
            <Stack rowGap={2}>
                {
                    users.map((user: IUser) => (
                        <Paper key={user.id} sx={{ paddingX: 4, paddingY: 6 }}>
                            <Stack direction='row'>
                                <Stack width={0.5/3} justifyContent='center' alignItems='center' paddingRight={3}>
                                    <Avatar
                                        src={user?.avatar ?? undefined}
                                        children={user.username[0]}
                                        sx={{ height: 120, width: 120, border: 5, borderColor: 'whitesmoke' }}
                                    />
                                    <Typography variant='h6'>
                                        {user.username}
                                    </Typography>
                                    {
                                        user.pricePerWord && (
                                            <Typography gutterBottom>
                                                {`${user.pricePerWord} cents €/mot`}
                                            </Typography>
                                        )
                                    }
                                    <Stack direction='row' alignItems='center' columnGap={0.5} paddingBottom={2}>
                                        {
                                            user.averageRating && (
                                                <Typography variant='h5' component='p'>
                                                    {Number(user.averageRating)}
                                                </Typography>
                                            )
                                        }
                                        <Stack>
                                            <Rating
                                                readOnly
                                                value={Number(user.averageRating ?? 0)}
                                                precision={0.1}
                                            />
                                            <Typography variant='caption' paddingLeft={0.6}>
                                                {`${user.userRatings?.length ?? 0} évaluation(s)`}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack width={2.5/3} rowGap={3} paddingX={3} borderLeft={1} borderColor='gray' alignItems='start'>
                                    {
                                        user.bio && (
                                            430 < user.bio.length ? (
                                                <Typography variant='body1' align='justify'>
                                                    <FormatQuoteIcon color='warning' sx={{ marginRight: 1 }} />
                                                    {`${user.bio.substring(0, 430)}... `}
                                                    <RouterLink
                                                        anchor='Lire la suite'
                                                        to='/dashboard/users/profile'
                                                        state={user.id}
                                                    />
                                                </Typography>
                                            ) : (
                                                <Typography variant='body1' align='justify'>
                                                    <FormatQuoteIcon color='warning' sx={{ marginRight: 1 }} />
                                                    {user.bio}
                                                </Typography>
                                            )
                                        )
                                    }
                                    <Stack columnGap={1}>
                                        <Typography gutterBottom>
                                            {`Secteurs d'activité :`}
                                        </Typography>
                                        {
                                            null !== user.activitySectors && 0 < user.activitySectors.length ? (
                                                <Stack direction={'row'} columnGap={1}>
                                                    {
                                                        user.activitySectors.map((activitySector: IActivitySector) => {
                                                            const Icon = stringToIcon(activitySector.icon);

                                                            return (
                                                                    <Tooltip key={activitySector.id} title={activitySector.name}>
                                                                        <Icon />
                                                                    </Tooltip>
                                                            );
                                                        })
                                                    }
                                                </Stack>
                                            ) : (
                                                <Typography variant='body2'>
                                                    {`Aucun secteur d'activité`}
                                                </Typography>
                                            )
                                        }
                                    </Stack>
                                    <Button
                                        variant='outlined'
                                        onClick={() => navigate('/dashboard/users/profile', { state: user.id })}
                                    >
                                        {`Voir le profil`}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    ))
                }
            </Stack>
        ) : (<Spinner />)
    );
};

export default UsersListing;
