import React from 'react';
import OtherAPIs from '../../APIs/OtherAPIs';
import Tools from '../../helpers/Tools';
import { FormHelperText } from '@mui/material';
import FormikAutocomplete from '../FormikComponent/FormikAutocomplete';
import IActivitySector from '../../models/IActivitySector';

const SelectActivitySector: React.FC = () => {
    // Use of hooks
    const [activitySectors, setActivitySectors] = React.useState<Array<IActivitySector>|null>(null);
    const mountedRef = React.useRef<boolean>(true);

    // useEffect when component is mounting
    React.useEffect(() => {
        // Call API to fetch activity sectors
        null === activitySectors &&
            OtherAPIs.getActivitySectors()
                // On successful API call change activitySectors hook value
                .then((activitySectors: Array<IActivitySector>) =>
                    // Do not update state if component is unmounted
                    true === mountedRef.current && setActivitySectors(activitySectors)
                );

        return () => {
            // Set mountedRef to false when component is unmounting
            mountedRef.current = false;
        };
    }, [activitySectors]);

    return (
        <>
            <FormikAutocomplete
                upperlabel="Secteur d'activité de l'article"
                name='activitySector'
                loading={null === activitySectors}
                options={Tools.convertToSelectOptions(activitySectors, 'id', 'name', 'icon')}
            />
            <FormHelperText>
                {`Si votre article ne s'inscrit dans aucun des secteurs d'activités,
                merci de bien vouloir nous envoyer votre proposition par ticket.`}
            </FormHelperText>
        </>
    );
};

export default SelectActivitySector;
