import React from 'react';
import { Box, BoxProps } from '@mui/material';

const DisplayContent: React.FC<BoxProps> = props => {
    return (
        <Box paddingLeft={1} marginLeft={2} borderLeft={1} borderColor='primary.main' {...props}>
            {props.children}
        </Box>
    );
};

export default DisplayContent;
