import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgAccountInformation: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#40596B' d='M469.333,512H42.667C19.2,512,0,492.8,0,469.333V42.667C0,19.2,19.2,0,42.667,0h426.667C492.8,0,512,19.2,512,42.667v426.667C512,492.8,492.8,512,469.333,512z' />
            <g>
                <path fill='#F2F2F2' d='M438.4,131.2H73.6c-10.667,0-20.267-8.533-20.267-20.267l0,0c0-10.667,8.533-20.267,20.267-20.267h365.867c10.667,0,20.267,8.533,20.267,20.267l0,0C458.667,122.667,450.133,131.2,438.4,131.2z' />
                <path fill='#F2F2F2' d='M438.4,276.267H73.6c-11.733,0-20.267-9.6-20.267-20.267l0,0c0-10.667,8.533-20.267,20.267-20.267h365.867c10.667,0,20.267,8.533,20.267,20.267l0,0C458.667,266.667,450.133,276.267,438.4,276.267z' />
                <path fill='#F2F2F2' d='M438.4,421.333H73.6c-10.667,0-20.267-8.533-20.267-20.267l0,0c0-10.667,8.533-20.267,20.267-20.267h365.867c10.667,0,20.267,8.533,20.267,20.267l0,0C458.667,411.733,450.133,421.333,438.4,421.333z' />
                <path fill='#F2F2F2' d='M458.667,110.933c0,10.667-8.533,20.267-20.267,20.267H73.6c-10.667,0-20.267-8.533-20.267-20.267c0-11.733,9.6-20.267,20.267-20.267h365.867C450.133,90.667,458.667,100.267,458.667,110.933z' />
                <path fill='#F2F2F2' d='M458.667,256c0,10.667-8.533,20.267-20.267,20.267H73.6c-11.733,0-20.267-9.6-20.267-20.267s9.6-20.267,20.267-20.267h365.867C450.133,235.733,458.667,245.333,458.667,256z' />
                <path fill='#F2F2F2' d='M458.667,401.067c0,10.667-8.533,20.267-20.267,20.267H73.6c-10.667,0-20.267-8.533-20.267-20.267c0-11.733,9.6-20.267,20.267-20.267h365.867C450.133,380.8,458.667,389.333,458.667,401.067z' />
            </g>
            <circle fill='#FFD15C' cx='172.8' cy='110.93' r='53.33' />
            <circle fill='#F8B64C' cx='151.47' cy='89.6' r='16' />
            <g>
                <circle fill='#FFD15C' cx='356.27' cy='256' r='53.33' />
                <circle fill='#FFD15C' cx='275.2' cy='401.07' r='53.33' />
            </g>
            <g>
                <path fill='#E4EAEF' d='M339.2,411.733c0,3.2,0,6.4-1.067,9.6h-13.867c2.133-6.4,4.267-12.8,4.267-20.267s-1.067-13.867-4.267-20.267h4.267C336,389.333,339.2,400,339.2,411.733z' />
                <path fill='#E4EAEF' d='M421.333,266.667c0,3.2,0,6.4-1.067,9.6H406.4c2.133-6.4,4.267-12.8,4.267-20.267s-1.067-13.867-4.267-20.267h5.333C417.067,244.267,421.333,254.933,421.333,266.667z' />
                <path fill='#E4EAEF' d='M236.8,121.6c0,3.2,0,6.4-1.067,9.6h-13.867c2.133-6.4,4.267-12.8,4.267-20.267c0-7.467-1.067-13.867-4.267-20.267h4.267C232.533,100.267,236.8,110.933,236.8,121.6z' />
            </g>
            <g>
                <circle fill='#F8B64C' cx='334.93' cy='234.67' r='16' />
                <circle fill='#F8B64C' cx='253.87' cy='379.73' r='16' />
            </g>
        </SvgIcon>
    );
};

export default SvgAccountInformation;
