import React from 'react';
import {
    Divider,
    Paper as MuiPaper,
    SxProps,
    Theme,
    Typography
} from '@mui/material';


const Paper: React.FC<{
    title: string,
    sx?: SxProps<Theme>|undefined
}> = props => {
    return (
        <MuiPaper sx={{ paddingX: 4, paddingY: 2, width: 1, ...props.sx }}>
            <Typography>
                {props.title}
            </Typography>
            <Divider sx={{ marginY: 2 }} />
            {props.children}
        </MuiPaper>
    );
};

export default Paper;
