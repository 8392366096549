import React from 'react';
import { Field } from 'react-final-form';
import { FormHelperText, FormHelperTextProps } from '@mui/material';

type FinalFieldMessageProps = {
    name: string,
    helperText?: React.ReactNode|undefined,
};

const FinalFieldMessage: React.FC<FormHelperTextProps&FinalFieldMessageProps> = ({ name, helperText, ...props}) => {
    return (
        <Field
            name={name}
            subscription={{ error: true, touched: true }}
            render={({ meta: { error, touched } }) =>
                error && touched ?
                    (<FormHelperText {...props} error>{error}</FormHelperText>)
                    : 'string' === typeof helperText ?
                        (<FormHelperText {...props}>{helperText}</FormHelperText>)
                        : helperText ?? null
            }
        />
    );
};

export default FinalFieldMessage;
