import IPaginatorResponse from '../interfaces/IPaginatedResponse';
import IDraftPost from '../models/IDraftPost';
import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * DraftPostAPIs class.
 */
export default class DraftPostAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of DraftPost ressources.
     *
     * @param query
     */
    public static async getDraftPost(query: any): Promise<IPaginatorResponse<IDraftPost>|null> {
        return GenericAPIs.apiGetCall('draft_posts', this.getApiKey(), query);
    }

    /**
     * Create a DraftPost ressource.
     *
     * @param body
     */
    public static async postDraftPost(body: any): Promise<IDraftPost|null> {
        return GenericAPIs.apiPostCall('draft_posts', body, this.getApiKey());
    }

    /**
     * Unpublish a DraftPost ressource.
     *
     * @param body
     */
    public static async unpublishDraftPost(id: number): Promise<IDraftPost|null> {
        return GenericAPIs.apiPostCall('draft_posts/unpublish', { draftPost: id }, this.getApiKey());
    }

    /**
     * Patch a DraftPost ressource.
     *
     * @param body
     */
    public static async patchDraftPost(id: number, body: any): Promise<IDraftPost|null> {
        return GenericAPIs.apiPatchCall(`draft_posts/${id}`, '', body, this.getApiKey());
    }

    /**
     * Delete a DraftPost ressource.
     *
     * @param body
     */
    public static async deleteDraftPost(id: number) {
        return GenericAPIs.apiDeleteCall(`draft_posts/${id}`, this.getApiKey());
    }
};
