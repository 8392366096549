import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgPostHistory: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <g>
                <rect x='58.167' y='453.41' fill='#F2F2F2' width='395.73' height='33.07' />
                <rect x='238.437' y='377.67' fill='#F2F2F2' width='33.07' height='51.2' />
            </g>
            <rect x='238.437' y='377.67' fill='#E4EAEF' width='33.07' height='12.8' />
            <path fill='#F8B64C' d='M411.234,44.874h-169.6c-12.8,0-22.4-10.667-22.4-22.4c0-12.8-10.667-22.4-22.4-22.4h-96c-12.8-1.067-23.467,9.6-23.467,21.333v308.267h356.267v-262.4C433.634,54.474,424.034,44.874,411.234,44.874z' />
            <path fill='#FFD15C' d='M446.434,103.54H64.567c-14.933,0-26.667,12.8-24.533,27.733L74.167,356.34C76.3,368.073,85.9,377.673,98.7,377.673h313.6c11.733,0,22.4-8.533,24.533-21.333l34.133-225.067C473.101,116.34,461.367,103.54,446.434,103.54z' />
            <g>
                <rect x='213.897' y='428.87' fill='#CDD6E0' width='82.13' height='82.13' />
                <rect x='108.297' y='56.61' fill='#CDD6E0' width='294.4' height='45.867' />
            </g>
            <rect x='101.897' y='80.07' fill='#F2F2F2' width='306.13' height='23.467' />
        </SvgIcon>
    );
};

export default SvgPostHistory;
