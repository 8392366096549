import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import OtherAPIs from '../../APIs/OtherAPIs';
import IScopesGroup from '../../models/IScopesGroup';

export interface IScopesGroupsState {
    status: boolean|null,
    data: Array<IScopesGroup>|null,
    error: string|null,
};

const initialState: IScopesGroupsState = {
    status: null,
    data: null,
    error: null,
};

export const fetchScopesGroups = createAsyncThunk(
    'scopesGroups.fetchAll',
    // Only fetch if there is no existing data in state
    async (_, { getState }) => null === (getState() as any).scopesGroups.data && await OtherAPIs.getScopesGroups()
);

export const slice = createSlice({
    name: 'scopesGroupsState',
    initialState,
    reducers: {
        clearScopesGroupsState: (_: IScopesGroupsState) => {
            // Return a new value and force createSlice to replace the old one.
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchScopesGroups.fulfilled, (state: IScopesGroupsState, action: PayloadAction<Array<IScopesGroup>>) => {
            state.status = true;
            state.data = action.payload;
        });
        builder.addCase(fetchScopesGroups.rejected, (state: IScopesGroupsState, action: any) => {
            state.status = false;
            state.error = action.error.message;
        });
    },
});

export const { clearScopesGroupsState } = slice.actions;

export default slice.reducer;
