import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import {
    CorporateFare as CorporateFareIcon,
    CorporateFareTwoTone as CorporateFareTwoToneIcon,
    BusinessCenter as BusinessCenterIcon,
    BusinessCenterTwoTone as BusinessCenterTwoToneIcon,
    ReportGmailerrorred as ReportGmailerrorredIcon,
    Badge as BadgeIcon,
    BadgeTwoTone as BadgeTwoToneIcon,
    Pets as PetsIcon,
    Brush as BrushIcon,
    BrushTwoTone as BrushTwoToneIcon,
    Balance as BalanceIcon,
    Face as FaceIcon,
    FaceTwoTone as FaceTwoToneIcon,
    Church as ChurchIcon,
    ChurchTwoTone as ChurchTwoToneIcon,
    AccountBalance as AccountBalanceIcon,
    AccountBalanceTwoTone as AccountBalanceTwoToneIcon,
    RoomService as RoomServiceIcon,
    RoomServiceTwoTone as RoomServiceTwoToneIcon,
    Park as ParkIcon,
    ParkTwoTone as ParkTwoToneIcon,
    DesktopMac as DesktopMacIcon,
    DesktopMacTwoTone as DesktopMacTwoToneIcon,
    Apartment as ApartmentIcon,
    LocalAtm as LocalAtmIcon,
    LocalAtmTwoTone as LocalAtmTwoToneIcon,
    SportsSoccer as SportsSoccerIcon,
    SportsSoccerTwoTone as SportsSoccerTwoToneIcon,
    House as HouseIcon,
    HouseTwoTone as HouseTwoToneIcon,
    HealthAndSafety as HealthAndSafetyIcon,
    HealthAndSafetyTwoTone as HealthAndSafetyTwoToneIcon,
    Biotech as BiotechIcon,
    BiotechTwoTone as BiotechTwoToneIcon,
    School as SchoolIcon,
    SchoolTwoTone as SchoolTwoToneIcon,
    Commute as CommuteIcon,
    AirplanemodeActive as AirplanemodeActiveIcon,
    Carpenter as CarpenterIcon,
    CarpenterTwoTone as CarpenterTwoToneIcon,
    Newspaper as NewspaperIcon,
    Checkroom as CheckroomIcon,
    Bed as BedIcon,
    BedTwoTone as BedTwoToneIcon,
    SportsEsports as SportsEsportsIcon,
    SportsEsportsTwoTone as SportsEsportsTwoToneIcon,
    SmokingRooms as SmokingRoomsIcon,
    DeveloperMode as DeveloperModeIcon,
    Theaters as TheatersIcon,
    TheatersTwoTone as TheatersTwoToneIcon,
} from '@mui/icons-material';

/**
 * Function used to convert a given string to a React Component
 * Can be usefull to load SVG from database string
 */
export default function stringToIcon(iconName: string, twoTone?: boolean|undefined): OverridableComponent<SvgIconTypeMap<{}, 'svg'>> {
    const icons: any = {
        corporation: twoTone ? CorporateFareTwoToneIcon : CorporateFareIcon,
        editor: twoTone ? BusinessCenterTwoToneIcon : BusinessCenterIcon,
        member: twoTone ? BadgeTwoToneIcon : BadgeIcon,
        pets: PetsIcon,
        brush: twoTone ? BrushTwoToneIcon : BrushIcon,
        balance: BalanceIcon,
        face: twoTone ? FaceTwoToneIcon : FaceIcon,
        church: twoTone ? ChurchTwoToneIcon : ChurchIcon,
        bank: twoTone ? AccountBalanceTwoToneIcon : AccountBalanceIcon,
        bell_food: twoTone ? RoomServiceTwoToneIcon : RoomServiceIcon,
        park: twoTone ? ParkTwoToneIcon : ParkIcon,
        mac: twoTone ? DesktopMacTwoToneIcon : DesktopMacIcon,
        apartment: ApartmentIcon,
        money_ticket: twoTone ? LocalAtmTwoToneIcon : LocalAtmIcon,
        football: twoTone ? SportsSoccerTwoToneIcon : SportsSoccerIcon,
        house: twoTone ? HouseTwoToneIcon : HouseIcon,
        health: twoTone ? HealthAndSafetyTwoToneIcon : HealthAndSafetyIcon,
        biotech: twoTone ? BiotechTwoToneIcon : BiotechIcon,
        study: twoTone ? SchoolTwoToneIcon : SchoolIcon,
        transport: CommuteIcon,
        plane: AirplanemodeActiveIcon,
        saw: twoTone ? CarpenterTwoToneIcon : CarpenterIcon,
        newspaper: NewspaperIcon,
        checkroom: CheckroomIcon,
        bed: twoTone ? BedTwoToneIcon : BedIcon,
        gaming: twoTone ? SportsEsportsTwoToneIcon : SportsEsportsIcon,
        smoking: SmokingRoomsIcon,
        dev: DeveloperModeIcon,
        multimedia: twoTone ? TheatersTwoToneIcon : TheatersIcon,
    };

    return icons[iconName] ?? ReportGmailerrorredIcon;
};
