import {
    Home as HomeIcon,
    AccountCircle as AccountCircleIcon,
    Receipt as ReceiptIcon,
    CreditScore as CreditScoreIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    ListAlt as ListAltIcon,
    AddBox as AddBoxIcon,
    Description as DescriptionIcon,
    Build as BuildIcon,
    PostAdd as PostAddIcon,
    Public as PublicIcon,
    FolderOpenOutlined as FolderOpenOutlinedIcon,
    PeopleAlt as PeopleAltIcon,
    Construction as ConstructionIcon,
    DriveFileRenameOutline as DriveFileRenameOutlineIcon,
} from '@mui/icons-material';
import { FaHandshake } from 'react-icons/fa';
import { ImLab } from 'react-icons/im';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import EAccountTypes from '../interfaces/EAccountTypes';
import EUserRoles from '../interfaces/EUserRoles';

export interface IDashboardMenuItem {
    icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>|undefined,
    text?: string|undefined,
    path?: string|undefined,
    extensionOnHover?: string|undefined,
    iconNotMui?: boolean|undefined,
    isDev?: boolean|undefined,
    isEnd?: boolean|undefined,
    displayedIfRoles?: Array<string>|undefined
    displayedIfAccountType?: Array<string>|undefined,
    displayedIfScopes?: Array<string>|undefined,
};

/*
 * Describe content of DashboardMenu
 *
 * CAUTION: A component under items[] must recieve & deal with props "menuOpen" & "to"
 */
export const dashboardMenuConfig: Array<IDashboardMenuItem> = [
    {
        icon: HomeIcon,
        text: 'Tableau de bord',
        path: '/dashboard',
        isEnd: true,
    },
    {
        icon: AccountCircleIcon,
        text: 'Mon compte',
        path: 'account',
    },
    {
        icon: FolderOpenOutlinedIcon,
        text: 'Mes projets',
        path:'projects',
        displayedIfAccountType: [EAccountTypes.CORPORATION, EAccountTypes.MEMBER],
        displayedIfScopes: ['account_owner', 'can_make_order'],
    },
    {
        icon: ListAltIcon,
        text: 'Mes commandes',
        path: 'orders',
        displayedIfAccountType: [EAccountTypes.CORPORATION, EAccountTypes.MEMBER],
        displayedIfScopes: ['account_owner', 'can_make_order'],
    },
    {
        icon: AddBoxIcon,
        text: 'Commander',
        path: 'orders/templates',
        displayedIfAccountType: [EAccountTypes.CORPORATION, EAccountTypes.MEMBER],
        displayedIfScopes: ['account_owner', 'can_make_order'],
    },
    {
        icon: PeopleAltIcon,
        text: 'Liste des rédacteurs',
        path: 'users',
        displayedIfAccountType: [EAccountTypes.CORPORATION],
        displayedIfScopes: ['account_owner', 'can_make_order'],
    },
    {
        icon: DescriptionIcon,
        text: 'Mes articles',
        path: 'posts',
        displayedIfAccountType: [EAccountTypes.EDITOR, EAccountTypes.MEMBER],
        displayedIfScopes: ['account_owner', 'can_redact_posts'],
    },
    {
        icon: PostAddIcon,
        text: 'Mes propositions',
        path: 'posts/proposed',
        displayedIfAccountType: [EAccountTypes.EDITOR],
    },
    {
        icon: PublicIcon,
        text: 'Place publique',
        path: 'public_place',
        displayedIfAccountType: [EAccountTypes.EDITOR],
    },
    {
        icon: ReceiptIcon,
        text: 'Mes factures',
        path: 'invoices',
        displayedIfAccountType: [EAccountTypes.EDITOR],
    },
    {
        icon: FaHandshake,
        text: 'Mes contrats',
        path: 'contracts',
        displayedIfAccountType: [EAccountTypes.EDITOR],
    },
    {
        icon: CreditScoreIcon,
        text: 'À valider',
        path: 'payments/validation',
        displayedIfRoles: [EUserRoles.DEV, EUserRoles.ADMIN],
    },
    {
        icon: AccountBalanceWalletIcon,
        text: 'Archives',
        path: 'payments/archives',
        displayedIfRoles: [EUserRoles.DEV, EUserRoles.ADMIN],
    },
    {
        icon: DriveFileRenameOutlineIcon,
        text: 'Mes documents',
        path: 'documents',
        displayedIfAccountType: [EAccountTypes.CORPORATION, EAccountTypes.MEMBER],
    },
    {
        icon: ConstructionIcon,
        text: 'Outils',
        path: 'tools',
        displayedIfAccountType: [EAccountTypes.CORPORATION, EAccountTypes.MEMBER],
    },
    {
        icon: BuildIcon,
        text: 'Espace DEV',
        path: 'dev_area',
        displayedIfRoles: [EUserRoles.DEV],
    },
    {
        icon: ImLab,
        text: 'Laboratoire',
        path: 'dev_lab',
        displayedIfRoles: [EUserRoles.DEV],
    },
];
