import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    optimizationPanel: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        height: 600,
        paddingTop: 1,
        paddingRight: 2,
        overflow: 'hidden scroll',
    },
    optimizationWord: {
        paddingY: 0.5,
        paddingX: 1,
        borderRadius: 3,
        border: '1px solid',
        borderColor: 'primary.main',
        cursor: 'pointer',
        transition: 'all .2s',
    },
    optimizationWordIncluded: {
        borderColor: '#77dd77',
        backgroundColor: '#77dd77',
        color: 'white',
    },
};

export default cssStyles;
