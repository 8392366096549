import React from 'react';
import {
    SxProps,
    Theme,
    useTheme,
    Stack,
    Box,
} from '@mui/material';
import cssStyles from './HomeEditorStyles';

const RatingBox: React.FC<{
    gradientFromColor?: string|undefined,
    gradientToColor?: string|undefined,
    sx?: SxProps<Theme>|undefined,
}> = props => {
    // Create css styles using theme & props
    const theme = useTheme();

    return (
        <Box
            sx={{
                ...cssStyles.backgroundRatingBox,
                background: `linear-gradient(
                    -45deg,
                    ${props.gradientToColor ?? theme.palette.secondary.light},
                    ${props.gradientFromColor ?? theme.palette.primary.light}
                )`,
            }}
        >
            <Stack sx={cssStyles.ratingBox} rowGap={1}>
                {props.children}
            </Stack>
        </Box>
    );
};

export default RatingBox;
