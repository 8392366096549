import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import NumberFormat from 'react-number-format';
import { Button, Stack, TextField } from '@mui/material';
import GenericModal from './GenericModal';

const ModalSingleInput: React.FC<{
    name?: string|undefined,
    modalTitle: string,
    inputLabel: string,
    valueType?: 'string'|'number'|undefined,
    onClose?: (() => void)|undefined,
    onValidation?: ((value: string, modalParams: any) => void)|undefined,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'singleInputModal');
    const [value, setValue] = React.useState<string>('');

    // Use of redux
    const dispatch = useAppDispatch();
    const modalParams = useAppSelector(state => state.activeModal.params);

    // Callback triggered when modal is closing
    const handleModalClose = () => {
        // Submission also close modal
        dispatch(closeModal());
        // Call the given callback
        props.onClose && props.onClose();
    };

    return (
        <GenericModal
            name={modalName.current}
            title={props.modalTitle}
            onClose={() => props.onClose && props.onClose()}
        >
            <Stack>
                {
                    'number' === props.valueType ? (
                        <NumberFormat
                            customInput={TextField}
                            autoFocus
                            label={props.inputLabel}
                            value={value}
                            onValueChange={({ value }) => setValue(value)}
                        />
                    ) : (
                        <TextField
                            autoFocus
                            label={props.inputLabel}
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                        />
                    )
                }
                <Button
                    variant='outlined'
                    color='inherit'
                    disabled={'' === value}
                    onClick={() => {
                        props.onValidation && props.onValidation(value.trim(), modalParams);
                        setValue('')
                        handleModalClose()
                    }}
                    sx={{ marginTop: 2 }}
                >
                    {`Valider`}
                </Button>
            </Stack>
        </GenericModal>
    );
};

export default ModalSingleInput;
