import React from 'react';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import GenerationForm from './GenerationForm';
import GenerationLoader from '../GenerationLoader/GenerationLoader';
import DraftPostEditor from '../DraftPostEditor/DraftPostEditor';
import PostOutlineForm from './PostOutlineForm';
import IPostOutline from '../../interfaces/IPostOutline';
import IPostAiGeneration from '../../interfaces/IPostAiGeneration';
import IDraftPost from '../../models/IDraftPost';

const PostAiGeneration: React.FC = () => {
    // Use of hook
    const [loading, setLoading] = React.useState<boolean>(false);
    const [draftPost, setDraftPost] = React.useState<IDraftPost|null>(null);
    const [outline, setOutline] = React.useState<IPostOutline|null>(null);
    const generationFormValues = React.useRef<IPostAiGeneration|null>(null);

    // Callback to delay end of loading so the loader has time to go to 100%
    const stopLoading = () => setTimeout(() => setLoading(false), 1000);

    // Callback to handle GenerationForm submit
    const handleGenerationSubmit = (values: IPostAiGeneration, generationType?: 'outline'|'custom'|undefined) => {
        // Save form values
        generationFormValues.current = values;
        if ('custom' === generationType) {
            // Update outline state with form values
            setOutline({
                optimized: values.optimized ?? false,
                keyword: values.keyword,
                // If user provided a custom title, add it to the outline
                outline: values.h1 ?
                    [
                        [
                            {
                                type: 'h1',
                                title: values.h1,
                            },
                        ],
                    ]
                    : [],
            });
        } else {
            // Load during API call
            setLoading(true);
            (
                false === values.optimized ? (
                    // Call API to generate post or outline
                    BridgeAPIs.postAiGenerationPost({
                        keyword: values.keyword,
                        estimatedNbrWords: !values.optimized ?
                            values.estimatedNbrWords ?? undefined
                            : undefined,
                        h1: Boolean(values.h1) ? values.h1 : undefined,
                        outlineOnly: 'outline' === generationType ? true : undefined,
                    })
                ) : (
                    // Call API to generate an optimized post
                    BridgeAPIs.postAiGenerationPostOptimized({
                        keyword: values.keyword,
                        h1: Boolean(values.h1) ? values.h1 : undefined,
                        outlineOnly: 'outline' === generationType ? true : undefined,
                    })
                )
            )
                .then(result =>
                    null !== result && 'outline' === generationType ?
                        setOutline({ ...result, optimized: values.optimized, keyword: values.keyword })
                        : setDraftPost(result)
                )
                .finally(stopLoading);
        }
    };

    // Callback to handle OutlineForm submit
    const handleOutlineSubmit = (values: IPostOutline) => {
        // Load during API call
        setLoading(true);
        // Save updated outline values
        setOutline(values);
        (
            false === values.optimized ? (
                // Call API to generate post or outline
                BridgeAPIs.postAiGenerationPost(values)
            ) : (
                // Call API to generate an optimized post
                BridgeAPIs.postAiGenerationPostOptimized(values)
            )
        )
            .then(result => null !== result && setDraftPost(result))
            .finally(stopLoading);
    };

    return (
        false === loading ? (
            null === draftPost ? (
                null === outline ? (
                    <GenerationForm defaultValues={generationFormValues.current} onSubmit={handleGenerationSubmit} />
                ) : (
                    <PostOutlineForm
                        postOutline={outline}
                        onSubmit={handleOutlineSubmit}
                        onPreviousClick={() => {
                            setDraftPost(null);
                            setOutline(null);
                        }}
                    />
                )
            ) : (
                <DraftPostEditor
                    draftPost={draftPost}
                    onPreviousClick={() => {
                        setDraftPost(null);
                        setOutline(null);
                    }}
                />
            )
        ) : (
            <GenerationLoader />
        )
    );
};

export default PostAiGeneration;
