import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { Typography } from '@mui/material';
import HomeEditor from '../HomeEditor/HomeEditor';
import EAccountTypes from '../../interfaces/EAccountTypes';

const Home: React.FC = () => {
    // Use of redux
    const instanceAccountType: string|undefined = useAppSelector(state => state.instance.account?.accountType.slug);

    return (
        instanceAccountType && EAccountTypes.EDITOR === instanceAccountType ? (
            <HomeEditor />
        ) : (
            <Typography variant='h4'>
                {`Tableau de bord`}
            </Typography>
        )
    );
};

export default Home;
