import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    paymentPaper: {
        width: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingY: 1,
        paddingX: 3,
        borderTopLeftRadius: 0,
    },
    paymentTag: {
        paddingX: 3,
        paddingY: '4px',
        borderBottom: 'none',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    companyInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 3/10,
    },
    companyLogo: {
        width: 80,
        height: 80,
        objectFit: 'contain',
    },
    companyName: {
        fontWeight: '700',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    iconButton: {
        border: '2px solid',
        color: 'primary.main',
    },
};

export default cssStyles;
