import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { appMenuWidthOpen, appMenuWidthClose } from '../../configs/materialUI';
import extensionToDMEComponent from '../../configs/extensionToDMEComponent';
import {
    useTheme,
    SxProps,
    Theme,
    Box,
} from '@mui/material';
import makeCssStyles from './DashboardMenuExtensionStyles';

const DashboardMenuExtension: React.FC = () => {
    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme);

    // Use of hooks
    const [DMEComponent, setDMEComponent] = React.useState<React.FC|null>(null);

    // Use of redux
    const menuIsOpened: boolean = useAppSelector(state => state.dashboardMenu.open);
    const extensionOpen: string|null = useAppSelector(state => state.dashboardMenu.extension);

    // useEffect whenever dashboard menu extension
    React.useEffect(() => {
        // Whenever extension in redux state is not null
        // We need to display this component
        setDMEComponent(null !== extensionOpen ? extensionToDMEComponent(extensionOpen) : null);
    }, [extensionOpen])

    return (
        <Box
            sx={{
                ...cssStyles.dashboardMenuExt,
                ...(DMEComponent ? cssStyles.dashboardMenuExtOpen : cssStyles.dashboardMenuExtClose),
                left: menuIsOpened ? appMenuWidthOpen : appMenuWidthClose,
            } as SxProps<Theme>}
        >
            {DMEComponent}
        </Box>
    );
};

export default DashboardMenuExtension;
