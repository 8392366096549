import React from 'react';
import Tools from '../../helpers/Tools';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import { LoadingButton } from '@mui/lab';
import {
    FormHelperText,
    FormLabel,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import RouterLink from '../RouterLink/RouterLink';
import IDuplicationScore from '../../interfaces/IDuplicationScore';

const DuplicationScore: React.FC = () => {
    // Use of hooks
    const [text, setText] = React.useState<string|null>(null);
    const [duplicatedNode, setDuplicatedNode] = React.useState<Array<React.ReactNode>|null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [score, setScore] = React.useState<number|null>(null);

    // Callback to handle duplicate response
    const handleDuplicates = React.useCallback((response: IDuplicationScore|null) => {
        if (null !== response) {
            // Replace duplicated sentences by a link in the text
            setDuplicatedNode(Tools.replaceStringByJsx(
                text!,
                response.duplicates.map(duplicate => duplicate.sentence),
                (_, index) => {
                    // Retrieve duplicate sentence at matched index
                    const duplicate = response.duplicates[index];

                    return (
                        <Tooltip key={index} title={duplicate.link} followCursor>
                            <span>
                                <RouterLink
                                    to={duplicate.link}
                                    anchor={`${duplicate.sentence} `}
                                    underline='none'
                                    color='error'
                                    target='_blank'
                                />
                            </span>
                        </Tooltip>
                    );
                }
            ));
            // Update score state
            setScore(response.score);
        }
        // Update loading state
        setLoading(false);
    }, [text]);

    // Function to handle API call
    const handleClickButton = (text: string) => {
        // Load during API call
        setLoading(true);
        // Reset score state
        setScore(null);
        // Call API to check if the link is indexed
        BridgeAPIs.getDuplicationScore(text!)
            // On successful API call
            .then((response: IDuplicationScore|null) => handleDuplicates(response));
    };

    return (
        <>
            <FormLabel>
                {`Texte à vérifier`}
            </FormLabel>
            <TextField
                fullWidth
                multiline
                rows={10}
                value={text ?? ''}
                disabled={loading}
                onChange={(event) => setText(event.target.value)}
            />
            <FormHelperText sx={{ color: 'warning.main' }}>
                {`Chaque phrase de votre texte coûte 20 credits (1 cent). Utilisez cet outil avec parcimonie.`}
            </FormHelperText>
            <LoadingButton
                variant='contained'
                loading={loading}
                disabled={!text}
                onClick={() => handleClickButton(text!)}
                sx={{ marginY: 2 }}
            >
                {`Vérifier la duplication`}
            </LoadingButton>
            {
                null !== score && false === loading && (
                    <Stack gap={2}>
                        <Typography variant='body2'>
                            {`Taux de duplication : `}
                            <Typography fontWeight={700} component='span'>
                                {`${score} %`}
                            </Typography>
                        </Typography>
                        {
                            null !== duplicatedNode && (
                                <Typography variant='body2'>
                                    {duplicatedNode.map(node => node)}
                                </Typography>
                            )
                        }
                    </Stack>
                )
            }
        </>
    );
};

export default DuplicationScore;
