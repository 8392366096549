import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { selectInstanceAccountTypeName, selectInstanceAccountScopes, IInstanceUserState } from '../../store/slices/instanceSlice';
import { isDashboardMenuOpen } from '../../store/slices/dashboardMenuSlice';
import { dashboardMenuConfig, IDashboardMenuItem } from '../../configs/dashboardMenu';
import Tools from '../../helpers/Tools';
import {
    useTheme,
    SxProps,
    Theme,
    Drawer,
    Stack,
    Typography,
    Avatar,
    List,
} from '@mui/material';
import SvgLogoPegaseo from '../Svg/SvgLogoPegaseo';
import SvgPegaseo from '../Svg/SvgPegaseo';
import SvgCollapse from '../Svg/SvgCollapse';
import DashboardMenuItem from './DashboardMenuItem';
import makeCssStyles from './DashboardMenuStyles';

const DashboardMenu: React.FC = () => {
    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme);

    // Use of redux
    const dispatch = useAppDispatch();
    const menuIsOpened: boolean = useAppSelector(state => state.dashboardMenu.open);
    const instanceAccountTypeSlug: string|null = useAppSelector(selectInstanceAccountTypeName(true));
    const instanceAccountScopes: Array<string> = useAppSelector(selectInstanceAccountScopes);
    const instanceUser: IInstanceUserState = useAppSelector(state => state.instance.user!);

    // Use of react-router-dom hooks
    const navigate = useNavigate();

    // Callback used to determine if a dashboardMenuItem can be shown
    const itemIsShown = (item: IDashboardMenuItem): boolean => {
        // Consider item is  shown by default
        let itemIsShown: boolean = true;

        // Conditions to pass to determine if item is shown
        if (
            item.displayedIfRoles &&
            0 === item.displayedIfRoles.filter(role => instanceUser.roles.includes(role)).length
        ) {
            itemIsShown = false;
        } else if (
            item.displayedIfAccountType &&
            false === item.displayedIfAccountType.includes(instanceAccountTypeSlug ?? '')
        ) {
            itemIsShown = false;
        } else if (
            item.displayedIfScopes &&
            false === Tools.isScoped(instanceAccountScopes, item.displayedIfScopes)
        ) {
            itemIsShown = false;
        }

        return itemIsShown;
    };

    return (
        <Drawer
            open={menuIsOpened}
            variant='permanent'
            PaperProps={{
                sx: {
                    ...cssStyles.dashboardMenu,
                    ...(menuIsOpened ? cssStyles.dashboardMenuOpen : cssStyles.dashboardMenuClose),
                } as SxProps<Theme>
            }}
        >
            <Stack alignItems={menuIsOpened ? 'flex-end' : 'center' } paddingX={2}>
                <SvgCollapse
                    onClick={() => dispatch(isDashboardMenuOpen(!menuIsOpened))}
                    sx={{
                        ...cssStyles.collapseMenuSvg,
                        transform: menuIsOpened ? undefined : 'rotate(180deg)',
                    }}
                />
            </Stack>
            <Stack
                alignItems={menuIsOpened ? undefined : 'center'}
                sx={cssStyles.appInfo}
                onClick={() => navigate('/dashboard')}
            >
                {menuIsOpened ? (<SvgPegaseo />) : (<SvgLogoPegaseo />)}
            </Stack>
            <Stack alignItems='center' paddingX={2} paddingY={3}>
                <Avatar
                    src={instanceUser.avatar}
                    children={`${instanceUser.name[0]}${instanceUser.lastname[0]}`}
                    sx={{
                        ...(menuIsOpened ? cssStyles.avatarMenuOpen : cssStyles.avatarMenuClose),
                        bgcolor: 'secondary.main',
                        fontSize: '1rem',
                        color: 'white',
                    }}
                />
                {
                    menuIsOpened && (
                        <Typography fontWeight={700} paddingY={1}>
                            {instanceUser.username}
                        </Typography>
                    )
                }
            </Stack>
            <List disablePadding>
                {
                    dashboardMenuConfig.map((item: IDashboardMenuItem, index: number) => (
                        false === itemIsShown(item) ? null : (
                            <DashboardMenuItem
                                key={index}
                                menuIsOpened={menuIsOpened}
                                icon={item.icon!}
                                iconNotMui={item.iconNotMui}
                                text={item.text!}
                                to={item.path}
                                extensionOnHover={item.extensionOnHover}
                                isEnd={item.isEnd}
                            />
                        )
                    ))
                }
            </List>
            {
                menuIsOpened && (
                    <Stack sx={cssStyles.navFooter}>
                        <Typography variant='caption' sx={cssStyles.cgv} onClick={() => navigate('/policy')}>
                            {`Conditions générales`}
                        </Typography>
                        <Typography variant='caption'>
                            {`v${process.env.REACT_APP_VERSION}`}
                        </Typography>
                    </Stack>
                )
            }
        </Drawer>
    );
};

export default DashboardMenu;
