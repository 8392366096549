import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import OtherAPIs from '../../APIs/OtherAPIs';
import ICountry from '../../models/ICountry';

export interface ICountriesState {
    status: boolean|null,
    data: Array<ICountry>|null,
    error: string|null,
};

const initialState: ICountriesState = {
    status: null,
    data: null,
    error: null,
};

export const fetchCountries = createAsyncThunk(
    'countries.fetchAll',
    // Only fetch if there is no existing data in state
    async (_, { getState }) => null === (getState() as any).countries.data && await OtherAPIs.getCountries()
);

export const slice = createSlice({
    name: 'countriesState',
    initialState,
    reducers: {
        clearCountriesState: (_: ICountriesState) => {
            // Return a new value and force createSlice to replace the old one.
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCountries.fulfilled, (state: ICountriesState, action: PayloadAction<Array<ICountry>>) => {
            state.status = true;
            state.data = action.payload;
        });
        builder.addCase(fetchCountries.rejected, (state: ICountriesState, action: any) => {
            state.status = false;
            state.error = action.error.message;
        });
    },
});

export const { clearCountriesState } = slice.actions;

export default slice.reducer;
