import React from 'react';
import { Field } from 'formik';
import NumberFormat, { FormatInputValueFunction, NumberFormatProps } from 'react-number-format';
import {
    SxProps,
    Theme,
    FormControl,
    FormLabel,
    Stack,
    Select,
    MenuItem,
    TextField,
    TextFieldProps,
} from '@mui/material';
import ISelectOption from '../../interfaces/ISelectOption';
import IFormikFieldProps from '../../interfaces/IFormikFieldProps';

/**
 * As explained here https://github.com/s-yadav/react-number-format/issues/233,
 * customInput always pass a new function reference,
 * which ends up creating a new input element on each re-render, hence loosing the focus.
 * Creating the customInput component outside solve this issue.
 *
 * Also, size prop has a meaning in NumberFormat and cannot be forwarded to TextField,
 * So we need to define two customInput depending on given size.
 */
 const CustomTextField = React.forwardRef((forwardProps: TextFieldProps, ref) => (
    <TextField inputRef={ref} {...forwardProps} />
));
const CustomSmallTextField = React.forwardRef((forwardProps: TextFieldProps, ref) => (
    <TextField inputRef={ref} {...forwardProps} size='small' />
));

const FormikInputSelectGroup: React.FC<{
    selectName: string,
    inputName: string,
    options: Array<ISelectOption>,
    type?: 'text'|'number'|'password'|undefined,
    label?: string|undefined,
    upperLabel?: string|undefined,
    placeholder?: string|undefined,
    autoComplete?: boolean|undefined,
    disabled?: boolean|undefined,
    fullWidth?: boolean|undefined,
    helperText?: string|undefined,
    size?: 'medium'|'small'|undefined,
    mask?: string|undefined,
    format?: string|FormatInputValueFunction|undefined,
    sx?: SxProps<Theme>|undefined,
    onInputChange?: ((value: any) => void)|undefined,
    onSelectChange?: ((value: any) => void)|undefined,
}> = props => {
    // Define common props for both Textfield & NumberFormat components
    const commonProps: any = {
        fullWidth: props.fullWidth ?? true,
        margin: 'none',
        label: props.label,
        disabled: props.disabled,
        placeholder: props.placeholder,
        FormHelperTextProps: { component: 'span' },
        InputProps: {
            sx: {
                borderTopLeftRadius: 'unset',
                borderBottomLeftRadius: 'unset',
            },
        },
    };

    return (
        <FormControl
            fullWidth={props.fullWidth ?? true}
            disabled={props.disabled}
            sx={props.sx}
        >
            {
                props.upperLabel && (
                    <FormLabel sx={{ fontSize: { xs: 16 } }}>
                        {props.upperLabel}
                    </FormLabel>
                )
            }
            <Stack direction='row' alignItems='flex-start'>
                <Field name={props.selectName}>
                    {({ field }: IFormikFieldProps) => (
                        <Select
                            {...field}
                            margin='none'
                            size={props.size ?? 'small'}
                            disabled={props.disabled}
                            onChange={(event) => {
                                field.onChange(event);
                                props.onSelectChange && props.onSelectChange(event.target.value);
                            }}
                            sx={{
                                minWidth: 130,
                                'fieldset': { border: 'none' },
                                borderTopRightRadius: 'unset',
                                borderBottomRightRadius: 'unset',
                                backgroundColor: theme => 'light' === theme.palette.mode ? theme.palette.grey[200] : theme.palette.grey[800],
                            }}
                        >
                            {
                                props.options.map((option: ISelectOption, index: number) => (
                                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                ))
                            }
                        </Select>
                    )}
                </Field>
                <Field name={props.inputName}>
                    {({ field, form, meta }: IFormikFieldProps) => (
                        props.format || props.mask || 'number' === props.type ? (
                            <NumberFormat
                                {...commonProps}
                                name={field.name}
                                value={field.value}
                                autoComplete='off'
                                format={props.format}
                                mask={props.mask}
                                error={Boolean(meta.touched && meta.error)}
                                helperText={Boolean(meta.touched && meta.error) ? meta.error : props.helperText}
                                customInput={
                                    !props.size || 'small' === props.size ?
                                        CustomSmallTextField : CustomTextField
                                }
                                onBlur={field.onBlur}
                                onValueChange={(values: NumberFormatProps) => {
                                    form.setFieldValue(field.name, values.value);
                                    props.onInputChange && props.onInputChange(values.value);
                                }}
                            />
                        ) : (
                            <TextField
                                {...field}
                                {...commonProps}
                                autoComplete={props.autoComplete ? 'on' : 'off'}
                                size={props.size ?? 'small'}
                                type={props.type}
                                error={Boolean(meta.touched && meta.error)}
                                helperText={Boolean(meta.touched && meta.error) ? meta.error : props.helperText}
                                onChange={(event) => {
                                    field.onChange(event);
                                    props.onInputChange && props.onInputChange(event.currentTarget.value);
                                }}
                            />
                        )
                    )}
                </Field>
            </Stack>
        </FormControl>
    );
};

export default FormikInputSelectGroup;
