import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { setActiveStep } from '../../store/slices/activeStepperSlice';
import {
    useMediaQuery,
    Stepper as MuiStepper,
    Step,
    StepButton,
} from '@mui/material';
import IStepperStep from '../../interfaces/IStepperStep';

const Header: React.FC<{
    activeStep: number,
    steps: Array<IStepperStep>,
    orientationBreakpoint?: string|undefined,
    width?: string|number|undefined,
}> = props => {
    // Use of redux
    const dispatch = useAppDispatch();

    return (
        <MuiStepper
            activeStep={props.activeStep}
            orientation={useMediaQuery(`(min-width: ${props.orientationBreakpoint ?? '900px'}`) ? 'horizontal' : 'vertical'}
            sx={props.width ? { margin: 'auto', width: props.width } : undefined}
        >
            {
                props.steps.map((step: IStepperStep, index: number) => (
                    <Step key={index} completed={index < props.activeStep}>
                        <StepButton onClick={() => dispatch(setActiveStep(index))}>
                            {step.label}
                        </StepButton>
                    </Step>
                ))
            }
        </MuiStepper>
    );
};

export default Header;
