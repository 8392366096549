import React from 'react';
import { Stack, Typography, Select, SelectChangeEvent, MenuItem, TextField, IconButton } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import IOrder from '../../models/IOrder';

export type FilterType = { value: string, label: string, type: 'string'|'number', getValue: (order: IOrder) => string|number };

// Define filter options
const filterOptions: Array<FilterType> = [
    { value: 'website', label: 'Site de publication', type: 'string', getValue: (order: IOrder) => order.site?.domain ?? new URL(order.post!.wpPostLink!).hostname.replace('www.', '') },
    { value: 'postType', label: "Type d'article", type: 'string', getValue: (order: IOrder) => order.postType.name },
    { value: 'optimization', label: "Score d'optimisation", type: 'number', getValue: (order: IOrder) => order.post?.optimization ?? 0 },
    { value: 'Nombre de mots', label: 'Nombre de mots', type: 'number', getValue: (order: IOrder) => order.post?.nbrWords ?? 0 },
];

const FilterOrders: React.FC<{ onChange: (filterBy: FilterType, filterType: string, value: string) => void, onReset: () => void }> = props => {
    // Use of hooks
    const [filterBy, setFilterBy] = React.useState<FilterType>(filterOptions[0]);
    const [filterType, setFilterType] = React.useState<string>('equal');
    const [value, setValue] = React.useState<string>('');
    const [isFilter, setIsFilter] = React.useState<boolean>(false);

    // useEffect to force filter type to 'equal' when choosen field is string type
    React.useEffect(() => {
        'string' === filterBy.type && setFilterType('equal');
    }, [filterBy]);

    return (
        <Stack direction='row' alignItems='flex-start' gap={2}>
            <Stack>
                <Typography variant='body2'>{`Filtrer par`}</Typography>
                <Select
                    value={filterBy.value}
                    size='small'
                    onChange={(event: SelectChangeEvent) => setFilterBy(filterOptions.find(option => option.value === event.target.value)!)}
                >
                    {filterOptions.map(option => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                </Select>
            </Stack>
            {
                 'number' === filterBy.type && (
                    <Select
                        value={filterType}
                        size='small'
                        onChange={(event: SelectChangeEvent) => setFilterType(event.target.value)}
                        sx={{ alignSelf: 'flex-end' }}
                    >
                        <MenuItem value='equal'>{`=`}</MenuItem>
                        <MenuItem value='less'>{`<`}</MenuItem>
                        <MenuItem value='more'>{`>`}</MenuItem>
                    </Select>
                )
            }
            <Stack>
                <Typography variant='body2'>{`Valeur`}</Typography>
                <TextField
                    value={value}
                    size='small'
                    onChange={event => setValue(event.target.value)}
                />
            </Stack>
            <Stack direction='row' alignSelf='flex-end'>
                <IconButton
                    color='success'
                    disabled={null === filterBy || null === filterType || null === value}
                    onClick={() => {
                        props.onChange(filterBy, filterType, value);
                        setIsFilter(true);
                    }}
                >
                    <Check fontSize='inherit' />
                </IconButton>
                {
                    true === isFilter && (
                        <IconButton
                            color='error'
                            onClick={() => {
                                props.onReset();
                                setIsFilter(false);
                            }}
                        >
                            <Close fontSize='inherit' />
                        </IconButton>
                    )
                }
            </Stack>
        </Stack>
    );
};

export default FilterOrders;
