import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import SecurityAPIs from '../../APIs/SecurityAPIs';
import {
    Container,
    Typography,
    Stack,
    Grid,
    Button,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import FormikInput from '../FormikComponent/FormikInput';
import FormikSelect from '../FormikComponent/FormikSelect';
import FormikCheckbox from '../FormikComponent/FormikCheckbox';
import RouterLink from '../RouterLink/RouterLink';
import Copyright from '../Copyright/Copyright';
import IAccount from '../../models/IAccount';

const ActiveInvitation: React.FC = () => {
    // Use react-router-dom hooks
    const navigate = useNavigate();
    const { uuid } = useParams<any>();

    // Define initial values for formik form
    const initialValues: any = {
        name: '',
        lastname: '',
        phonePrefix: { value: '+33', label: '+33' },
        phone: '',
        password: '',
        confirmPassword: '',
        hasNewsletter: false,
        terms: false,
    };

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        name: Yup
            .string()
            .required('Veuillez renseigner votre prénom'),
        lastname: Yup
            .string()
            .required('Veuillez renseigner votre nom'),
        phone: Yup
            .string()
            .length(9, 'Veuillez renseigner un numéro de téléphone valide')
            .required('Veuillez renseigner un numéro de téléphone'),
        password: Yup
            .string()
            .min(8, 'Votre mot de passe doit contenir un minimum de 8 caractéres')
            .required('Veuillez spécifier un mot de passe'),
        confirmPassword: Yup
            .string()
            .required('Veuillez confirmer votre mot de passe')
            .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
        terms: Yup
            .boolean()
            .oneOf([true], `Veuillez lire et accepter les conditions générales de ventes et d'utilisation`),
    });

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback((values: any, formikHelpers: FormikHelpers<any>) => {
        // Use formik form values to setup active invitation
        SecurityAPIs.activeInvitation({ ...values, phonePrefix: values.phonePrefix.value, token: uuid })
            // On successful API call
            .then((data: IAccount) => data && navigate('/ask_account_validation', { state: values.email }))
            // In any cases, retrieve back
            .finally(() => formikHelpers.setSubmitting(false));
    }, [uuid, navigate]);

    return (
        <Container component='main' maxWidth='sm' sx={{ padding: 5 }}>
            <Stack alignItems='center' spacing={1} width={1} paddingBottom={3}>
                <LockOutlinedIcon sx={{ fontSize: 56 }} color='primary' />
                <Typography component='h1' variant='h5'>
                    {`Finalisez votre inscription`}
                </Typography>
            </Stack>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormikSubmit}
            >
                {(formikProps: FormikProps<any>) => (
                    <>
                        <Form>
                            <Grid container spacing={2} paddingBottom={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormikInput
                                        autoFocus
                                        name='name'
                                        label='Prénom'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikInput
                                        name='lastname'
                                        label='Nom'
                                    />
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <FormikSelect
                                        name='phonePrefix'
                                        options={[
                                            { value: '+33', label: '+33' },
                                            { value: '+212', label: '+212' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={9} sm={9}>
                                    <FormikInput
                                        name='phone'
                                        label='Numéro de téléphone'
                                        format='0# ## ## ## ##'
                                        mask='_'
                                    />
                                </Grid>
                            </Grid>
                            <FormikInput
                                type='password'
                                name='password'
                                label='Mot de passe'
                                helperText='Le mot de passe doit avoir au minimum 8 caractères.'
                                sx={{ paddingBottom: 2 }}
                            />
                            <FormikInput
                                type='password'
                                name='confirmPassword'
                                label='Confirmer le mot de passe'
                                sx={{ paddingBottom: 2 }}
                            />
                            <FormikCheckbox
                                name='hasNewsletter'
                                label={
                                    <Typography variant='body2'>
                                        {`Je m'inscris à la newsletter de ${process.env.REACT_APP_NAME}`}
                                    </Typography>
                                }
                            />
                            <FormikCheckbox
                                name='terms'
                                label={
                                    <Typography variant='body2'>
                                        {`J'ai lu et accepté les `}
                                        <RouterLink
                                            anchor={`conditions générales de ventes & d'utilisation`}
                                            to='/policy'
                                        />
                                    </Typography>
                                }
                            />
                            <Button
                                fullWidth
                                variant='contained'
                                type='submit'
                                disabled={!formikProps.isValid || formikProps.isSubmitting}
                                sx={{ marginY: 2 }}
                            >
                                {`Valider mon inscription`}
                            </Button>
                        </Form>
                        {
                            formikProps.isSubmitting && (
                                <Backdrop open={formikProps.isSubmitting}>
                                    <CircularProgress size='10vh' />
                                </Backdrop>
                            )
                        }
                    </>
                )}
            </Formik>
            <Copyright paddingTop={10} />
        </Container>
    );
};

export default ActiveInvitation;
