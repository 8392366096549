import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveStepperData } from '../../store/slices/activeStepperSlice';
import { Typography } from '@mui/material';
import DisplayActivitySectors from '../DisplayActivitySectors/DisplayActivitySectors';
import ISignUpStepperData from '../../interfaces/ISignUpStepperData';

const SectorPreferences: React.FC = () => {
    // Use of redux
    const dispatch = useAppDispatch();
    const signUpStepperState: ISignUpStepperData = useAppSelector(state => state.activeStepper.data);

    return (
        <>
            <Typography variant='h5' gutterBottom>
                {`Séléctionnez vos secteurs d'activités :`}
            </Typography>
            <Typography variant='body2' paddingBottom={4}>
                {`Ne séléctionnez que les secteurs d'activités dans lesquels vous vous sentez le plus à
                l'aise pour rédiger du contenu. Vos secteurs d'activités préférés seront affichés sur
                votre profil et pousseront les utilisateurs à vous choisir en fonction du sujet d'un article.`}
            </Typography>
            <DisplayActivitySectors
                selected={signUpStepperState.activitySectors}
                onSelectionChanges={(selected) => dispatch(setActiveStepperData({ data: { activitySectors: selected } }))}
                sx={{ paddingX: 4 }}
            />
        </>
    );
};

export default SectorPreferences;
