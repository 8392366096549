import { Theme } from '@mui/material';
import ICssStyles from '../../interfaces/ICssStyles';

const makeCssStyles = (theme: Theme, open: boolean): ICssStyles => ({
    paperContainer: {
        position: 'relative',
        marginTop: 4,
        marginLeft: 12.5,
        paddingY: 2,
        paddingX: 3,
    },
    userInfoContainer: {
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'center', md: undefined },
        columnGap: 4,
        rowGap: 2,
        height: 1,
        paddingLeft: { xs: undefined, md: 12 },
    },
    avatar: {
        height: 200,
        width: 200,
        border: 5,
        borderColor: { xs: 'whitesmoke', md: 'white' },
        position: { xs: undefined, md: 'absolute' },
        top: { xs: undefined, md: '50%' },
        left: { xs: undefined, md: -5 },
        transform: { xs: undefined, md: 'translate(-50%, -50%)' },
        boxShadow: { xs: undefined, md: '0 0 10px rgba(0, 0, 0, 0.3)'},
    },
    userInfo: {
        width: { xs: 1, md: 0.5/3 },
        justifyContent: 'center',
        alignItems: { xs: 'center', md: 'start' },
    },
    secondaryInfoContainer: {
        width: { xs: 1, md: 2.5/3 },
        height: 1,
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 3,
    },
    ratingContainer: {
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        columnGap: 4,
        rowGap: 2,
    },
    secondaryRatingContainer: {
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        width: 1,
        columnGap: 2,
        rowGap: 1,
    },
    hiddenContainer: {
        rowGap: 2,
        maxHeight: open ? 'min-content' : 0,
        overflow: 'hidden',
        paddingBottom: open ? 6 : 0,
    },
    bio: {
        maxHeight: '300px',
        overflow: 'auto',
    },
    deployButton: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: `translate(-50%, 0)${open ? ' rotate(180deg)' : ''}`,
    },
});

export default makeCssStyles;
