import React from 'react';
import { TValidationSchema } from '../../hooks/useValidationSchema';
import { Form } from 'react-final-form';
import { FormApi, FormSubscription, Mutator, SubmissionErrors } from 'final-form';
import { Box } from '@mui/material';
import { SystemProps } from '@mui/system';

/**
 * @type T types of all fields in the form, default to unknown
 */
export type FinalFormProps<T = unknown> = {
    initialValues?: T|undefined,
    validateSchema?: TValidationSchema<T>|undefined,
    onSubmit: (
        values: T,
        form?: FormApi<T, Partial<T>>|undefined,
        callback?: (errors?: SubmissionErrors) => void
    ) => SubmissionErrors|Promise<SubmissionErrors>|void,
    mutators?: { [key: string]: Mutator<T, Partial<T>> }|undefined,
    subscription?: FormSubscription|undefined,
    keepDirtyOnReinitialize?: boolean|undefined,
    children: React.ReactNode,
};

/**
 * @type T types of all fields in the form, default to unknown
 */
function FinalForm<T = unknown>({
    initialValues,
    validateSchema,
    onSubmit,
    mutators,
    subscription,
    keepDirtyOnReinitialize,
    children,
    ...systemProps
}: SystemProps&FinalFormProps<T>) {
    return (
        <Form<T>
            initialValues={initialValues}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            mutators={mutators}
            validate={validateSchema}
            onSubmit={onSubmit}
            subscription={subscription ?? {}}
            render={({ handleSubmit }) => (
                <Box
                    component='form'
                    {...systemProps}
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit(event);
                    }}
                >
                    {children}
                </Box>
            )}
        />
    );
}

export default FinalForm;
