import React from 'react';
import { useParams } from 'react-router-dom';
import SecurityAPIs from '../../APIs/SecurityAPIs';
import {
    Container,
    Typography,
    Button,
} from '@mui/material';
import Copyright from '../Copyright/Copyright';
import Spinner from '../Spinner/Spinner';
import IUser from '../../models/IUser';

const ActiveAccount: React.FC = () => {
    // Use of hooks
    const [email, setEmail] = React.useState<string|null>(null);

    // Use react-router-dom hooks
    const { uuid, apiKey } = useParams<any>();

    // useEffect when component has finished mounting
    React.useEffect(() => {
        null === email && undefined !== uuid && undefined !== apiKey && '' !== uuid &&
            // Call API to activate account
            SecurityAPIs.activeAccount(uuid, apiKey)
                // On sucessful call
                .then((data: IUser|null) => null !== data && setEmail(data.email));
    }, [email, uuid, apiKey])

    return (
        email ? (
            <Container component='main' maxWidth='md' sx={{ padding: 5 }}>
                <Typography component='h1' variant='h3' color='primary' align='center' fontWeight='bold' gutterBottom>
                    {`Votre compte ${process.env.REACT_APP_NAME} est désormais activé !`}
                </Typography>
                <Typography component='h2' variant='h6' gutterBottom>
                    {`Vous pouvez dès à présent vous connecter sur ${process.env.REACT_APP_NAME} en utilisant l'adresse email suivante :`}
                </Typography>
                <Typography component='p' variant='h5' color='secondary' align='center' fontWeight='bold' gutterBottom>
                    {email}
                </Typography>
                <Button variant='contained' onClick={() => window.location.pathname = ''} sx={{ marginY: 2 }}>
                    {`Je souhaite me connecter`}
                </Button>
                <Copyright paddingTop={10} />
            </Container>
        ) : (
            <Spinner isFullscreen />
        )
    );
};

export default ActiveAccount;
