import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    actionButton: {
        height: 35,
        width: 35,
        padding: 0,
        border: '1px solid',
        color: 'primary.main',
        borderColor: 'primary.main',
        borderRadius: 1,
        backgroundColor: 'white',
        transition: 'background-color ease-in-out 0.2s',
        '&:hover': {
            backgroundColor: 'whitesmoke',
        },
    },
    actionButtonDisabled: {
        color: 'lightgray',
        borderColor: 'lightgray',
    },
};

export default cssStyles;
