import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    Stack,
    Typography,
    Tooltip,
    IconButton,
} from '@mui/material';
import { FaLightbulb } from 'react-icons/fa';
import GenerationPanelButton from './GenerationPanelButton';
import GenerationButton from './GenerationButton';

const GenerationPanel: React.FC = () => {
    // Define constants from env variables
    const small: number = parseInt(process.env.REACT_APP_AI_GENERATION_SMALL!);
    const medium: number = parseInt(process.env.REACT_APP_AI_GENERATION_MEDIUM!);
    const large: number = parseInt(process.env.REACT_APP_AI_GENERATION_LARGE!);
    const offset: number = parseInt(process.env.REACT_APP_AI_PROMPT_OFFSET!);

    // Use of hook
    const [tokens, setTokens] = React.useState<number>(medium);

    // Use of redux
    const dispatch = useAppDispatch();

    return (
       <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Stack direction='row' alignItems='center' spacing={1}>
                <Tooltip title="Cliquer pour plus d'informations">
                    <IconButton
                        color='primary'
                        size='small'
                        onClick={() => dispatch(openModal({ name: 'generateContentInfoModal' }))}
                    >
                        <FaLightbulb />
                    </IconButton>
                </Tooltip>
                <Typography component='p' variant='caption'>
                    {`Taille de la génération :`}
                </Typography>
                <GenerationButton
                    text='S'
                    tokens={small}
                    selected={small === tokens}
                    onClick={() => setTokens(small)}
                />
                <GenerationButton
                    text='M'
                    isMax
                    tokens={medium}
                    selected={medium === tokens}
                    onClick={() => setTokens(medium)}
                />
                <GenerationButton
                    text='L'
                    isMax
                    tokens={large}
                    selected={large === tokens}
                    onClick={() => setTokens(large)}
                />
            </Stack>
            <GenerationPanelButton tokens={tokens} offset={offset} />
        </Stack>
    );
};

export default GenerationPanel;
