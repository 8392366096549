import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';
import IPaginatorResponse from '../interfaces/IPaginatedResponse';
import IRejectedPost from '../models/IRejectedPost';
import IPost from '../models/IPost';

/**
 * PostAPIs class.
 */
export default class PostAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of Post ressources.
     *
     * @param query
     */
    public static async getPost(query: any) {
        return GenericAPIs.apiGetCall('posts', this.getApiKey(), query);
    }

    /**
     * Post a Post resource.
     *
     * @param body
     */
    public static async postMultiplePost(body: Array<any>): Promise<Array<IPost> | null> {
        return GenericAPIs.apiPostCall('posts/multiple_post', { posts: body }, this.getApiKey());
    }

    /**
     * Patch an Post resource.
     *
     * @param id
     * @param body
     */
    public static async patchPost(id: number, body: any) {
        return GenericAPIs.apiPatchCall(`posts/${id}`, null, body, this.getApiKey());
    }

    /**
     * Delete a Post resource.
     *
     * @param id
     */
    public static async deletePost(id: number) {
        return GenericAPIs.apiDeleteCall(`posts/${id}`, this.getApiKey());
    }

    /**
     * Get collection of PostRefuseMessage ressources.
     *
     * @param query
     */
    public static async getPostRefuseMessage(query: any) {
        return GenericAPIs.apiGetCall('posts_refuse_messages', this.getApiKey(), query);
    }

    /**
     * Post a PostRefuseMessage resource.
     *
     * @param body
     */
    public static async postPostRefuseMessage(body: any) {
        return GenericAPIs.apiPostCall('posts_refuse_messages', body, this.getApiKey());
    }

    /**
     * Get collection of RejectedPost ressources.
     *
     * @param query
     */
    public static async getRejectedPost(query: any): Promise<IPaginatorResponse<IRejectedPost>|null> {
        return GenericAPIs.apiGetCall('rejected_posts', this.getApiKey(), query);
    }

    /**
     * Post a PostIndexation resource.
     *
     * @param body
     */
    public static async postPostIndexation(body: any) {
        return GenericAPIs.apiPostCall('post_indexations', body, this.getApiKey());
    }

    /**
     * Update Posts ranking
     *
     * @param posts
     */
    public static async updatePostRanking(posts: Array<number>) {
        return GenericAPIs.apiPostCall(`post_rankings/multiple_update`, { posts }, this.getApiKey());
    }

    /**
     * Post a PostRanking resource.
     *
     * @param body
     */
    public static async postPostRanking(body: any): Promise<IPost|null> {
        return GenericAPIs.apiPostCall('post_rankings', body, this.getApiKey());
    }
};
