import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProjectAPIs from '../../APIs/ProjectAPIs';
import OrderAPIs from '../../APIs/OrderAPIs';
import PostAPIs from '../../APIs/PostAPIs';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import {
    AutoMode as AutoModeIcon,
    Bookmark as BookmarkIcon,
    BookmarkRemove as BookmarkRemoveIcon,
    Numbers as NumbersIcon
} from '@mui/icons-material';
import { ImDownload } from 'react-icons/im';
import { BiMedal } from 'react-icons/bi';
import Spinner from '../Spinner/Spinner';
import PublishedOrder from './PublishedOrder';
import PostActionButton from '../PostActionButton/PostActionButton';
import ListingRowData from '../ListingRowData/ListingRowData';
import FilterOrders, { FilterType } from './FilterOrders';
import SortOrders, { SortType } from './SortOrders';
import ModalConfirmation from '../Modal/ModalConfirmation';
import EOrderStatuses from '../../interfaces/EOrderStatuses';
import IOrder from '../../models/IOrder';
import IProject from '../../models/IProject';
import IPost from '../../models/IPost';

const ProjectInformation: React.FC = () => {
    // Use of hooks
    const [project, setProject] = React.useState<IProject|null>(null);
    const [orders, setOrders] = React.useState<Array<IOrder>|null>(null);
    const ordersBackup = React.useRef<Array<IOrder>>([]);
    const [isLoading, setIsLoading] = React.useState<string>('');

    // Use react-router-dom hooks
    const navigate = useNavigate();
    const location = useLocation();
    const projectId: number = location.state as number;

    // useEffect when component is mounted
    React.useEffect(() => {
        // Get Project by id
        ProjectAPIs.getProject({ id: projectId, ordersCount: true, spentBudget: true })
            // On successful API call, update Project state
            .then((data: Array<IProject>|null) => null !== data && setProject(data[0]));
    }, [projectId]);

    // useEffect when Project state is updated
    React.useEffect(() => {
        if (null !== project && null === orders) {
            // Get published Orders by project id
            OrderAPIs.getOrder({
                project: project.id,
                statuses: [
                    EOrderStatuses.PUBLISHED, EOrderStatuses.EXTERNAL_PUBLISH,
                    EOrderStatuses.UNINDEXED, EOrderStatuses.INDEXED,
                ],
                // Show Orders created from DraftPost
                showDraftPost: true,
            })
                // On successful API call, update Orders state
                .then((data: Array<IOrder>|null) => {
                    if (null !== data) {
                        ordersBackup.current = data;
                        setOrders(data);
                    }
                })
                // Stop loading
                .finally(() => setIsLoading(''));
        }
    }, [project, orders]);

    // Callback to handle Orders indexing refresh
    const handleRefreshIndexing = React.useCallback((orders: Array<number>) => {
        // Load during API call
        setIsLoading('indexing');
        OrderAPIs.updateOrderIndexingStatus(orders)
            // On successful API call, force Orders state refresh
            .then((data: Array<IOrder>|null) => null !== data ? setOrders(null) : setIsLoading(''));
    }, []);

    // Callback to handle Orders ranking refresh
    const handleRefreshRanking = React.useCallback((posts: Array<number>) => {
        // Load during API call
        setIsLoading('ranking');
        PostAPIs.updatePostRanking(posts)
            // On successful API call, force Orders state refresh
            .then((data: Array<IPost>|null) => null !== data ? setOrders(null) : setIsLoading(''));
    }, []);

    // Function to sort Orders
    const sortOrders = (orders: Array<IOrder>, sortBy: SortType, orderBy: string) => {
        // Sort array based on sortBy and orderBy
        const sortedArray = orders!.sort((a, b) => {
            let aValue: string|number = sortBy.getValue(a);
            let bValue: string|number = sortBy.getValue(b);

            if ('string' === sortBy.type) {
                const stringA = String(aValue);
                const stringB = String(bValue);
                return 'asc' === orderBy ? stringA.localeCompare(stringB) : stringB.localeCompare(stringA);
            } else {
                const numberA = Number(aValue);
                const numberB = Number(bValue);
                return 'asc' === orderBy ? numberA - numberB : numberB - numberA;
            }
        });

        // Force array mutation
        return [...sortedArray];
    };

    // Callback to handle Orders sorting
    const handleSortChange = (sortBy: SortType, orderBy: string) => {
        // Sort Orders state
        setOrders(currentOrders => sortOrders(currentOrders!, sortBy, orderBy));
        // Also sort Orders backup
        ordersBackup.current = sortOrders(ordersBackup.current, sortBy, orderBy);
    };

    // Callback to handle Orders filter
    const handleFilterChange = (filterBy: FilterType, filterType: string, value: string) => {
        // Filter Orders state
        setOrders(ordersBackup.current!.filter(order => {
            // Retrieve choosen field value
            const fieldValue = filterBy.getValue(order);

            return (
                'equal' === filterType ?
                    'string' === filterBy.type ?
                        // Search value in field value
                        (fieldValue as string).includes(value)
                        : Number(fieldValue) === Number(value)
                : 'less' === filterType ? fieldValue < value
                : fieldValue > value
            );
        }));
    };

    // Callback to handle deletion of a DraftPost
    const handleUnpublish = (orderId: number) => {
        OrderAPIs.patchMultipleOrders({ orders: [orderId], data: { publish: false }})
            // On successful API call, refetch DraftPosts
            .then(() => setOrders(order => order!.filter(o => o.id !== orderId)));
    };

    return (
        null !== project ? (
            <Stack gap={3}>
                <Paper sx={{ paddingY: 4 }}>
                    <Typography variant='h4' align='center'>
                        {project.name}
                    </Typography>
                    <Typography variant='h6' color='textSecondary' align='center' gutterBottom>
                        {project.moneySite ?? 'Pas de money site associé'}
                    </Typography>
                    <Stack direction='row' justifyContent='space-evenly'>
                        <ListingRowData
                            variant='h6'
                            icon={BookmarkIcon}
                            title={`Budget annuel : ${project.annualBudget ?? 0} €`}
                        />
                        <ListingRowData
                            variant='h6'
                            icon={BookmarkRemoveIcon}
                            title={`Budget dépensé : ${project.spentBudget ?? 0} €`}
                        />
                        <ListingRowData
                            variant='h6'
                            icon={NumbersIcon}
                            title={`Nombre d'articles commandés : ${project.ordersCount}`}
                        />
                        <Stack direction='row' columnGap={2}>
                            <PostActionButton
                                disabled={null === orders || '' !== isLoading}
                                loading={'indexing' === isLoading}
                                icon={AutoModeIcon}
                                tooltip="Actualiser l'indexation de toutes les articles"
                                onClick={() => handleRefreshIndexing(orders!.map(order => order.id))}
                            />
                            <PostActionButton
                                disabled={null === orders || '' !== isLoading}
                                loading={'ranking' === isLoading}
                                icon={BiMedal}
                                tooltip='Actualiser le positionnement de tous les articles'
                                onClick={() => handleRefreshRanking(orders!.map(order => order.post!.id))}
                            />
                            <PostActionButton
                                disabled={null === orders || '' !== isLoading}
                                icon={ImDownload}
                                tooltip='Importer un article'
                                onClick={() => navigate('importation', { state: projectId })}
                            />
                        </Stack>
                    </Stack>
                </Paper>
                {
                    '' === isLoading && null !== orders ? (
                        0 < ordersBackup.current.length ? (
                            <>
                                <Stack direction='row' gap={2}>
                                    <SortOrders onChange={handleSortChange} />
                                    <Divider flexItem orientation='vertical' />
                                    <FilterOrders
                                        onChange={handleFilterChange}
                                        onReset={() => setOrders(ordersBackup.current)}
                                    />
                                </Stack>
                                {
                                    0 < orders.length ?
                                    <>
                                        <ModalConfirmation
                                            name='confirmUnpublishModal'
                                            title='Confirmer la dépublication'
                                            message='Veuillez confirmer la dépublication de cet article'
                                            onValidation={handleUnpublish}
                                        />
                                        {
                                            orders.map(order => (
                                                <PublishedOrder
                                                    key={order.id}
                                                    order={order}
                                                />
                                            ))
                                        }
                                    </>
                                    : (
                                        <Typography>
                                            {`Aucun résultat pour ce filtre.`}
                                        </Typography>
                                    )
                                }
                            </>
                        ) : (
                            <Typography>
                                {`Aucune commande publiée pour ce projet.`}
                            </Typography>
                        )
                    ) : (
                        <Spinner />
                    )
                }
            </Stack>
        ) : (
            <Spinner />
        )
    );
};

export default ProjectInformation;
