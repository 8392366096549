import React from 'react';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import { LoadingButton } from '@mui/lab';
import {
    FormHelperText,
    FormLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';

const IndexingStatus: React.FC = () => {
    // Use of hooks
    const [url, setUrl] = React.useState<string|null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [indexed, isIndexed] = React.useState<boolean|null>(null);

    // Function used to handle API call
    function handleClickButton(url: string) {
        // Load during API call
        setLoading(true);
        // Reset indexed state
        isIndexed(null);
        // Call API to check if the link is indexed
        BridgeAPIs.getUrlsIndexed([url])
            // Update indexed state
            .then(response => null !== response && isIndexed(response.includes(url)))
            // Stop loading after 2s
            .finally(() => setTimeout(() => setLoading(false), 2000));
    };

    return (
        <>
            <FormLabel>
                {`Spécifiez l'URL d'un article de blog`}
            </FormLabel>
            <TextField
                fullWidth
                value={url ?? ''}
                placeholder='https:// ...'
                onChange={(e) => {
                    setUrl(e.target.value);
                    isIndexed(null);
                }}
            />
            <FormHelperText sx={{ color: 'warning.main' }}>
                {`Chaque requête coûte 20 credits (1 cent). Utilisez cet outil avec parcimonie.`}
            </FormHelperText>
            <LoadingButton
                variant='contained'
                loading={loading}
                disabled={!url}
                onClick={() => handleClickButton(url!)}
                sx={{ marginY: 2 }}
            >
                {`Vérifier l'indexation`}
            </LoadingButton>
            {
                null !== indexed && false === loading && (
                    <Stack direction='row' alignItems='center' gap={2}>
                        <Typography
                            color={`${true === indexed ? 'success' : 'error'}.main`}
                            display='flex'
                            alignItems='center'
                            columnGap={1}
                        >
                            {
                                true === indexed ? (
                                    <FaCheck />
                                ) : (
                                    <ImCross />
                                )
                            }
                            {`L'url `}
                            <Typography fontWeight={700} component='span'>
                                {url}
                            </Typography>
                            {` ${false === indexed ? "n'est pas" : 'est'} indexée.`}
                        </Typography>
                    </Stack>
                )
            }
        </>
    );
};

export default IndexingStatus;
