import React from 'react';
import moment from 'moment-timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import stringToIcon from '../../configs/stringToIcon';
import UserAPIs from '../../APIs/UserAPIs';
import {
    Avatar,
    Box,
    Button,
    Paper as MuiPaper,
    Rating as MuiRating,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import { FormatQuote as FormatQuoteIcon } from '@mui/icons-material';
import Paper from './Paper';
import Rating from '../Rating/Rating';
import Spinner from '../Spinner/Spinner';
import makeCssStyles from './UserProfileStyles'
import IActivitySector from '../../models/IActivitySector';
import IUser from '../../models/IUser';

const UserProfile: React.FC = () => {
    // Use of hooks
    const [user, setUser] = React.useState<IUser|null>(null);

    // Create css styles using theme & props
    const theme = useTheme();
    const cssStyles = makeCssStyles(theme, false);

    // Use of redux
    const instanceUserId = useAppSelector(state => state.instance.user!.id);

    // Use of react-router-dom
    const navigate = useNavigate();
    const userId = (useLocation().state as number|null);

    // useEffect to retrieve User info
    React.useEffect(() => {
        null === user &&
            UserAPIs.getUser({ id: userId ?? instanceUserId, withRatings: true })
                .then(response =>
                    null !== response && response.data[0] &&
                        setUser(response.data[0])
                );
    }, [userId, instanceUserId, user]);

    return (
        null !== user ? (
            <Stack direction='row' columnGap={3} rowGap={3}>
                <Stack width={0.9/3} rowGap={3} alignItems='center'>
                    <MuiPaper sx={{ paddingX: 4, paddingY: 6, width: 1 }}>
                        <Stack justifyContent='center' alignItems='center'>
                            <Avatar
                                src={user?.avatar ?? undefined}
                                children={`${user.name[0]}${user.lastname[0]}`}
                                sx={{ height: 120, width: 120, border: 5, borderColor: 'whitesmoke' }}
                            />
                            <Typography variant='h6'>
                                {user.username}
                            </Typography>
                            {
                                user.pricePerWord && (
                                    <Typography>
                                        {`${user.pricePerWord} cents €/mot`}
                                    </Typography>
                                )
                            }
                            <Typography gutterBottom>
                                {`Membre depuis ${moment(user.createdAt).fromNow(true)}`}
                            </Typography>
                            {
                                null !== user.globalRating && (
                                    <Rating rate={user.globalRating} />
                                )
                            }
                            {
                                (null === userId || instanceUserId === userId) && (
                                    <Button
                                        variant='contained'
                                        onClick={() => navigate('/dashboard/account/user/form')}
                                    >
                                        {`Modifier`}
                                    </Button>
                                )
                            }
                        </Stack>
                    </MuiPaper>
                    <Paper title="Secteurs d'activité">
                        {
                            null !== user.activitySectors && 0 < user.activitySectors.length ? (
                                <Stack rowGap={0.5}>
                                    {
                                        user.activitySectors?.map((activitySector: IActivitySector) => {
                                            const Icon = stringToIcon(activitySector.icon);

                                            return (
                                                <Typography key={activitySector.id} display='flex' alignItems='center' columnGap={1.5}>
                                                    <Icon />
                                                    {` ${activitySector.name}`}
                                                </Typography>
                                            );
                                        })
                                    }
                                </Stack>
                            ) : (
                                <Typography variant='body2'>
                                    {`Aucun secteur d'activité`}
                                </Typography>
                            )
                        }
                    </Paper>
                </Stack>
                <Stack width={2.2/3} rowGap={3}>
                    <Paper title='Présentation'>
                        {
                            user.bio ? (
                                <Typography align='justify'>
                                    <FormatQuoteIcon color='warning' sx={{ marginRight: 1 }} />
                                    {user.bio}
                                </Typography>
                            ) : (
                                <Typography variant='body2'>
                                    {`Aucune présentation`}
                                </Typography>
                            )
                        }
                    </Paper>
                    <Paper title='Évaluations'>
                        <Stack sx={cssStyles.secondaryRatingContainer}>
                            {
                                null !== user.globalRating ? (
                                    <>
                                        <Stack>
                                            <Typography>{`Notes des Donneurs d'Ordres`}</Typography>
                                            <Rating
                                                rate={user.averageRating!.average}
                                                rateCount={user.userRatings!.length}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Typography>{`Termine les missions`}</Typography>
                                            <Rating
                                                label='mission(s)'
                                                rate={user.completedRating!.average}
                                                rateCount={user.completedRating!.count}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Typography>{`Article validé au premier coup`}</Typography>
                                            <Rating
                                                label='article(s)'
                                                rate={user.firstTryRating!.average}
                                                rateCount={user.firstTryRating!.count}
                                            />
                                        </Stack>
                                    </>
                                ) : (
                                    <Typography variant='body2'>
                                        {`Aucune évaluation`}
                                    </Typography>
                                )
                            }
                        </Stack>
                    </Paper>
                    <Paper title='Commentaires'>
                        {
                            null !== user.userRatings && 0 < user.userRatings.length ? (
                                <Stack rowGap={2}>
                                    {
                                        user.userRatings?.filter(rating => null !== rating.comment).map(rating => {
                                            const Icon = stringToIcon(rating.order.activitySector.icon);

                                            return (
                                                <MuiPaper key={rating.id} sx={{ backgroundColor: 'whitesmoke', border: 'unset', padding: 3 }}>
                                                    <Stack direction='row' justifyContent='space-between'>
                                                        <Stack direction='row' columnGap={1}>
                                                            <Tooltip title={rating.order.activitySector.name}>
                                                                <Icon />
                                                            </Tooltip>
                                                            <Typography>
                                                                {`-`}
                                                            </Typography>
                                                            <Tooltip title={rating.order.syntaxLevel.description}>
                                                                <Typography>
                                                                    {rating.order.syntaxLevel.name}
                                                                </Typography>
                                                            </Tooltip>
                                                            <Typography>
                                                                {`-`}
                                                            </Typography>
                                                            <Tooltip title='Mot clé'>
                                                                <Typography>
                                                                    {rating.order.keyword.name}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Stack>
                                                        <Tooltip title={`${rating.rate}/5`}>
                                                            <Box component='span'>
                                                                <MuiRating readOnly value={Number(rating.rate)} precision={0.1} />
                                                            </Box>
                                                        </Tooltip>
                                                    </Stack>
                                                    <Typography paddingLeft={3} marginLeft={1.5} marginTop={1} borderLeft={1} borderColor='primary.main'>
                                                        {rating.comment}
                                                    </Typography>
                                                </MuiPaper>
                                            );
                                        })
                                    }
                                </Stack>
                            ) : (
                                <Typography variant='body2'>
                                    {`Aucun commentaire`}
                                </Typography>
                            )
                        }
                    </Paper>
                </Stack>
            </Stack>
        ) : (<Spinner />)
    );
};

export default UserProfile;
