import React from 'react';
import PostAPIs from '../../../APIs/PostAPIs';
import {
    Button,
	Stack,
    Tooltip,
} from '@mui/material';
import RefuseAction from './RefuseAction';
import Spinner from '../../Spinner/Spinner';
import IOrder from '../../../models/IOrder';
import IPostRefuseMessage from '../../../models/IPostRefuseMessage';
import AcceptAction from './AcceptAction';

const PostActions: React.FC<{
    order: IOrder,
    onAccept: (publish: boolean, rate?: number|undefined, message?: string|undefined) => void,
    onRefuse: (reasons: Array<string>) => void,
    onUnassign: () => void,
}> = props => {
    // Use of hooks
    const [accept, setAccept] = React.useState<boolean>(false);
    const [refuse, setRefuse] = React.useState<boolean>(false);
    const [refuseCount, setRefuseCount] = React.useState<number|null>(null);

    // useEffect when component is mounted
    React.useEffect(() => {
        null === refuseCount &&
            // Get PostRefuseMessages of the Post
            PostAPIs.getPostRefuseMessage({ post: props.order.post!.id })
                .then((data: Array<IPostRefuseMessage>|null) => null !== data && setRefuseCount(data.length))
    }, [props.order.post, refuseCount])

    return (
        null !== refuseCount ? (
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems='center' columnGap={2}>
                {
                    false === refuse && (
                        <AcceptAction
                            acceptState={accept}
                            mustRate={true === props.order.isPublic}
                            // Show publish post button for orders that are not planned and have a defined destination site
                            showPublish={Boolean(!props.order.toPublishAt && props.order.site && props.order.siteCategory)}
                            onAccept={props.onAccept}
                            onClick={() => setAccept(accept => !accept)}
                        />
                    )
                }
                {
                    false === accept && (
                        <>
                            <RefuseAction
                                refuseState={refuse}
                                onRefuse={props.onRefuse}
                                onClick={() => setRefuse(refuse => !refuse)}
                            />
                            {
                                2 <= refuseCount && false === refuse && (
                                    <Tooltip title="Comme indiqué dans les CGU, la mission peut être annulée à partir du 3ème refus.">
                                        <Button
                                            variant='contained'
                                            color='error'
                                            onClick={props.onUnassign}
                                        >
                                            {`Annuler la mission`}
                                        </Button>
                                    </Tooltip>
                                )
                            }
                        </>
                    )
                }
            </Stack>
        ) : (<Spinner />)
    );
};

export default PostActions;
