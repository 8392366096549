import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * SessionAPIs class.
 */
export default class SessionAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of Session resources.
     *
     * @param query
     */
    public static async getSession(query: any) {
        return GenericAPIs.apiGetCall('sessions', this.getApiKey(), query);
    }

    /**
     * Patch a Session resource.
     *
     * @param id
     * @param body
     */
    public static async patchSession(id: number, body: any) {
        return GenericAPIs.apiPatchCall(`sessions/${id}`, null, body, this.getApiKey());
    }
};
