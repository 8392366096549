import AbstractAuthAPIs from './AbstractAuthAPIs';
import GenericAPIs from './GenericAPIs';

/**
 * AccountAPIs class.
 */
export default class AccountAPIs extends AbstractAuthAPIs {
    /**
     * Get collection of Account resources.
     *
     * @param query
     */
    public static async getAccount(query: any) {
        return GenericAPIs.apiGetCall('accounts', this.getApiKey(), query);
    }

    /**
     * Patch an Account resource.
     *
     * @param id
     * @param body
     */
    public static async patchAccount(id: number, body: any) {
        return GenericAPIs.apiPatchCall(`accounts/${id}`, null, body, this.getApiKey());
    }

    /* ********************************************************************************* */

    /**
     * Get collection of AccountThematic resources.
     *
     * @param query
     */
    public static async getAccountThematic(query: any) {
        return GenericAPIs.apiGetCall('accounts_thematics', this.getApiKey(), query);
    }

    /**
     * Patch an AccountThematic resource.
     *
     * @param id
     * @param body
     */
    public static async patchAccountThematic(id: number, body: any) {
        return GenericAPIs.apiPatchCall(`accounts_thematics/${id}`, null, body, this.getApiKey());
    }

    /**
     * Post an AccountThematic resource.
     *
     * @param body
     */
    public static async postAccountThematic(body: any) {
        return GenericAPIs.apiPostCall('accounts_thematics', body, this.getApiKey())
    }

    /**
     * Delete an AccountThematic resource.
     *
     * @param id
     */
    public static async deleteAccountThematic(id: number) {
        return GenericAPIs.apiDeleteCall(`accounts_thematics/${id}`, this.getApiKey());
    }

    /* ********************************************************************************* */

    /**
     * Get collection of AccountType resources.
     *
     * @param query
     */
    public static async getAccountType(query: any) {
        return GenericAPIs.apiGetCall('account_types', this.getApiKey(), query);
    }

    /* ********************************************************************************* */

    /**
     * Get collection of AccountUser resources.
     *
     * @param query
     */
    public static async getAccountUser(query: any) {
        return GenericAPIs.apiGetCall('accounts_users', this.getApiKey(), query);
    }

    /* ********************************************************************************* */

    /**
     * Get collection of Company resources.
     *
     * @param query
     */
    public static async getCompany(query: any) {
        return GenericAPIs.apiGetCall('companies', this.getApiKey(), query);
    }

    /**
     * Post a Company resource.
     *
     * @param body
     */
     public static async postCompany(body: any) {
        return GenericAPIs.apiPostCall('companies', body, this.getApiKey())
    }

    /**
     * Patch a Company resource.
     *
     * @param id
     * @param body
     */
     public static async patchCompany(id: number, body: any) {
        return GenericAPIs.apiPatchCall(`companies/${id}`, null, body, this.getApiKey());
    }
};
