import React from 'react';
import { SxProps, Theme, SvgIcon } from '@mui/material';

const SvgConfigureBacklinks: React.FC<{ sx?: SxProps<Theme> | undefined }> = (props) => {
    return (
        <SvgIcon viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' sx={props.sx}>
            <polygon fill='#FFD67B' points='74.03,195.103 74.03,406.303 256.43,511.903 256.43,300.703' />
            <polygon fill='#FFD15C' points='256.43,300.703 256.43,511.903 438.83,406.303 438.83,195.103' />
            <polygon
                fill='#FF7058'
                points='438.83,195.103 257.497,300.703 256.43,300.703 74.03,195.103 256.43,89.503 257.497,90.569'
            />
            <g>
                <rect
                    x='268.046'
                    y='45.418'
                    transform='matrix(0.8669 0.4985 -0.4985 0.8669 98.3588 -172.9922)'
                    fill='#F8B64C'
                    width='210.132'
                    height='104.531'
                />
                <rect
                    x='33.776'
                    y='45.96'
                    transform='matrix(0.8669 -0.4985 0.4985 0.8669 -30.4839 82.2864)'
                    fill='#F8B64C'
                    width='210.132'
                    height='104.531'
                />
                <rect
                    x='268.229'
                    y='241.155'
                    transform='matrix(0.8669 -0.4985 0.4985 0.8669 -96.5803 225.1426)'
                    fill='#F8B64C'
                    width='210.132'
                    height='104.531'
                />
                <rect
                    x='33.542'
                    y='240.725'
                    transform='matrix(0.8669 0.4985 -0.4985 0.8669 164.5045 -30.0962)'
                    fill='#F8B64C'
                    width='210.132'
                    height='104.531'
                />
            </g>
            <path
                fill='#40596B'
                d='M123.097,211.103c0,4.267,0,8.533,1.067,12.8l132.267,76.8l133.333-76.8c0-4.267,1.067-8.533,1.067-12.8s0-8.533-1.067-12.8c9.6-6.4,18.133-12.8,27.733-18.133c-3.2-12.8-7.467-25.6-12.8-38.4c-10.667,0-22.4,1.067-33.067,1.067c-4.267-7.467-9.6-13.867-14.933-20.267c4.267-10.667,7.467-21.333,11.733-30.933c-10.667-8.533-21.333-17.067-32-23.467c-8.533,7.467-17.067,13.867-26.667,20.267c-7.467-3.2-16-6.4-24.533-7.467c-3.2-10.667-6.4-21.333-8.533-32c-13.867-1.067-26.667-1.067-40.533,0c-3.2,10.667-6.4,21.333-8.533,32c-8.533,2.133-17.067,4.267-24.533,7.467c-8.533-6.4-17.067-13.867-26.667-20.267c-11.733,7.467-22.4,14.933-32,23.467c4.267,10.667,7.467,21.333,11.733,30.933c-5.333,6.4-10.667,12.8-14.933,20.267c-10.667,0-22.4-1.067-33.067-1.067c-5.333,12.8-9.6,25.6-12.8,38.4c9.6,6.4,18.133,12.8,27.733,18.133C123.097,203.636,123.097,206.836,123.097,211.103zM212.697,167.369c24.533-24.533,64-24.533,87.467,0c11.733,11.733,18.133,27.733,18.133,43.733s-6.4,32-18.133,43.733c-24.533,24.533-62.933,24.533-87.467,0C188.164,231.369,188.164,191.903,212.697,167.369z'
            />
            <path
                fill='#CDD6E0'
                d='M199.897,267.636l12.8-12.8c-11.733-11.733-18.133-26.667-18.133-43.733c0-34.133,27.733-61.867,61.867-61.867c17.067,0,33.067,7.467,43.733,18.133l25.6-25.6c-18.133-18.133-41.6-28.8-69.333-28.8c-54.4,0-98.133,43.733-97.067,97.067c0,12.8,2.133,25.6,7.467,37.333L199.897,267.636z'
            />
            <path
                fill='#F2F2F2'
                d='M256.43,300.703l90.667-52.267c4.267-11.733,7.467-23.467,7.467-37.333c0-26.667-10.667-51.2-28.8-69.333l-25.6,25.6l0,0c10.667,10.667,18.133,26.667,18.133,43.733c0,34.133-27.733,61.867-61.867,61.867c-17.067,0-33.067-6.4-43.733-18.133l-12.8,12.8L256.43,300.703z'
            />
        </SvgIcon>
    );
};

export default SvgConfigureBacklinks;
