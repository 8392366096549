import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import UserAPIs from '../../APIs/UserAPIs';
import { Container, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { VpnKey as VpnKeyIcon } from '@mui/icons-material';
import FormikInput from '../FormikComponent/FormikInput';
import Copyright from '../Copyright/Copyright';
import IUser from '../../models/IUser';

const ResetPassword: React.FC = () => {
    // Use react-router-dom hooks
    const { uuid, apiKey } = useParams<any>();

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        password: Yup
            .string()
            .min(8, 'Votre nouveau mot de passe doit contenir un minimum de 8 caractéres')
            .required('Veuillez spécifier un nouveau un mot de passe'),
        confirmPassword: Yup
            .string()
            .required('Veuillez confirmer votre nouveau mot de passe')
            .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
    });

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback((values: any, formikHelpers: FormikHelpers<any>) => {
        undefined !== uuid && '' !== uuid &&
            // Call API to patch User
            UserAPIs.patchUser(uuid, { password: values.password }, apiKey)
                // On successful call
                .then((data: IUser) => {
                    if (data) {
                        window.location.pathname = '';
                    }
                })
                // In any cases, no longer need to load
                .finally(() => formikHelpers.setSubmitting(false));
    }, [uuid, apiKey]);

    return (
        <Container component='main' maxWidth='xs' sx={{ padding: 5 }}>
            <Typography variant='h6' align='left'>
                {`Spécifiez votre nouveau mot de passe`}
            </Typography>
            <Formik
                initialValues={{ password: '', confirmPassword: ''}}
                validationSchema={validationSchema}
                onSubmit={handleFormikSubmit}
            >
                {(formikProps: FormikProps<any>) => (
                    <Form>
                        <FormikInput
                            type='password'
                            name='password'
                            label='Mot de passe'
                            helperText='Le mot de passe doit avoir au minimum 8 caractères.'
                            sx={{ paddingY: 2 }}
                        />
                        <FormikInput
                            type='password'
                            name='confirmPassword'
                            label='Confirmer le mot de passe'
                            sx={{ paddingBottom: 4 }}
                        />
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            disabled={!formikProps.isValid}
                            loading={formikProps.isSubmitting}
                            endIcon={<VpnKeyIcon />}
                        >
                            {`Modifier mon mot de passe`}
                        </LoadingButton>
                    </Form>
                )}
            </Formik>
            <Copyright paddingTop={7} />
        </Container>
    );
};

export default ResetPassword;
