import moment from 'moment-timezone';
import { ColDef } from 'ag-grid-community';

/*
 * Specific column types containing properties that column definitions can inherit in DataGrid.
 * Column Types are designed to work on Columns only, i.e. they won't be applied to Column Groups.
 */
export const customColumnTypes: { [key: string]: ColDef } = {
    check: {
        pinned: 'left',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        lockPosition: true,
        cellClass: 'leftPinnedCell',
    },
    manage: {
        width: 55,
        pinned: 'left',
        lockPosition: true,
        cellClass: 'leftPinnedCell',
        cellRenderer: 'managerRenderer',
    },
    checkAndManage: {
        width: 80,
        pinned: 'left',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        lockPosition: true,
        cellClass: 'leftPinnedCell',
        cellRenderer: 'managerRenderer',
    },
    date: {
        width: 150,
        sortable: true,
        resizable: true,
        filter: 'agDateColumnFilter',
        valueFormatter: params => params.value ? moment(params.value).format('DD/MM/Y') : '',
        valueGetter: params => {
            // Retrieve field name of column
            const fieldName = params.colDef.field ?? 'date';
            if (params.data[fieldName]) {
                // Convert string date to JavaScript Date object
                const cellDate = moment(params.data[fieldName]).toDate();

                // Return cell date to midnight
                return cellDate.setHours(0, 0, 0, 0);
            } else {
                return '';
            }
        },
    },
    datetime: {
        width: 150,
        sortable: true,
        resizable: true,
        filter: 'agDateColumnFilter',
        valueFormatter: params => params.value ? moment(params.value).format('DD/MM/Y à HH:mm') : 'Non défini',
        valueGetter: params => {
            // Retrieve field name of column
            const fieldName = params.colDef.field ?? 'date';

            // Return cell date to midnight
            return params.data[fieldName] ? moment(params.data[fieldName]).toDate() : null;
        },
    },
    user: {
        sortable: true,
        resizable: true,
        cellRenderer: 'userRenderer',
        filter: 'agTextColumnFilter',
        valueFormatter: params => params.value ? `${params.value.name} ${params.value.lastname}` : '',
    },
    password: {
        resizable: true,
        cellRenderer: 'passwordRenderer',
    },
    status: {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        cellRenderer: 'coloredChipRenderer',
    },
    boolean: {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: params => params.colDef.field ? params.data[params.colDef.field] ? 'Oui' : 'Non' : null,
    },
    copyCell: {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        cellRenderer: 'copyCellRenderer',
    },
    account: {
        sortable: true,
        resizable: true,
        cellRenderer: 'accountRenderer',
        filter: 'agTextColumnFilter',
        valueGetter: params => {
            // Retrieve field name of column
            const fieldName = params.colDef.field ?? 'account';

            // Return same as formated value
            return params.data[fieldName] && params.data[fieldName].company ?
                params.data[fieldName].company.name
                : null;
        },
    }
};

/*
 * Locale file for French in DataGrid component
 * @see https://www.ag-grid.com/react-data-grid/localisation/
 */
export const agGridLocaleFr = {
    // Set Filter
    selectAll: '(Tout séléctionner)',
    selectAllSearchResults: '(Séléctionner tous les résultats)',
    searchOoo: 'Rechercher...',
    blanks: '(Vide)',
    noMatches: 'Aucun résultat',

    // Number Filter & Text Filter
    filterOoo: 'Filtrer...',
    equals: 'Égal à',
    notEqual: 'Pas égal à',
    empty: 'Choisir un',

    // Number Filter
    lessThan: 'Inférieur à',
    greaterThan: 'Supérieur à',
    lessThanOrEqual: 'Inférieur ou égal',
    greaterThanOrEqual: 'Supérieur ou égal',
    inRange: 'Compris entre',
    inRangeStart: 'de',
    inRangeEnd: 'à',

    // Text Filter
    contains: 'Inclus',
    notContains: "N'inclus pas",
    startsWith: 'Commence par',
    endsWith: 'Fini par',

    // Date Filter
    dateFormatOoo: 'dd/mm/yyyy',

    // Filter Conditions
    andCondition: 'ET',
    orCondition: 'OU',

    // Pagination
    to: 'à',
    of: 'sur',
    page: 'Page',

    // Other
    loadingOoo: 'Chargement...',
    noRowsToShow: 'Aucun résultat',
    //enabled: 'Enabled', No idea what it is
};
