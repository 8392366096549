import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { IInstanceWalletState } from '../../store/slices/instanceWalletSlice';
import { Typography } from '@mui/material';
import HomePanel from '../Home/HomePanel';
import RecoverFundsButton from '../RecoverFundsButton/RecoverFundsButton';
import cssStyles from './HomeEditorStyles';

const PanelFunds: React.FC = () => {
    // Use of redux
    const instanceWalletState: IInstanceWalletState = useAppSelector(state => state.instanceWallet);

    return (
        <HomePanel
            header='mes fonds'
            color='white'
            sx={{ width: 1/3, backgroundColor: theme => theme.palette.primary.main }}
        >
            <Typography sx={cssStyles.unlockedAmount}>
                {`${`${instanceWalletState.unlocked}`.replace('.', ',')} €`}
            </Typography>
            <Typography variant='caption'>
                {`Mes fonds en attente`}
            </Typography>
            <Typography variant='h6'>
                {`${`${instanceWalletState.locked}`.replace('.', ',')} €`}
            </Typography>
            <RecoverFundsButton
                to='/dashboard/payments/form'
                buttonProps={{
                    disableElevation: true,
                    sx: {
                        color: 'black',
                        fontSize: '1.5vh',
                        backgroundColor: 'white',
                        borderRadius: 2,
                        paddingY: 1,
                        marginTop: 'auto',
                        alignSelf: 'flex-start',
                        '&:hover': { backgroundColor: 'white' },
                    }
                }}
            />
        </HomePanel>
    );
};

export default PanelFunds;
