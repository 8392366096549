import React from 'react';
import { useLocation } from 'react-router-dom';
import {
    Container,
    Typography,
    Button,
} from '@mui/material';
import SvgDeadEmoji from '../Svg/SvgDeadEmoji';
import cssStyles from './NotFoundStyles';

const NotFound: React.FC<{ small?: boolean|undefined, helpButton?: boolean|undefined }> = props => {
    // Use react-router-dom hooks
    const { pathname } = useLocation();

    // useEffect triggered only in dev env
    React.useEffect(() => {
        'dev' === process.env.REACT_APP_ENV &&
            // console.log on every render
            console.info(`Pathname [${window.location.hostname}${pathname}] ended up in a 404 not found`);
    }, [pathname])

    return (
        <Container component='main' maxWidth='xs' sx={cssStyles.container}>
            <SvgDeadEmoji
                color='primary'
                sx={{ ...cssStyles.deadEmoji, width: true === props.small ? 1/2 : 1 }}
            />
            <Typography component='p' variant='h2' align='center' sx={cssStyles.errorStructure} gutterBottom>
                {`Erreur `}
                <Typography component='span' color='primary' variant='h2' fontWeight='bold'>
                    {`404`}
                </Typography>
            </Typography>
            <Typography component='p' variant='h5' align='center' gutterBottom>
                {`La page que vous cherchez semble introuvable.`}
            </Typography>
            {
                false !== props.helpButton && (
                    <Button
                        variant='contained'
                        sx={{ marginY: 4 }}
                        onClick={() => window.location.pathname = ''}
                    >
                        {`M'emmener en lieu sûr`}
                    </Button>
                )
            }
        </Container>
    );
};

export default NotFound;
