import React from 'react';
import { SvgIconTypeMap, Typography, Box, Stack, Tooltip, SxProps, Theme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { IconType } from 'react-icons';
import { Variant } from '@mui/material/styles/createTypography';

const ListingRowData: React.FC<{
    icon: IconType|OverridableComponent<SvgIconTypeMap<{},'svg'>>,
    variant?: Variant|undefined,
    title?: any,
    content?: any,
    tooltip?: any,
    color?: string,
    sx?: SxProps<Theme>|undefined,
    onClick?: (() => void)|undefined,
}> = props => {
    const content = React.useMemo(() => (
        <Stack direction='row' alignItems='center' justifyContent='center' columnGap={1} color={props.color} onClick={props.onClick} sx={props.sx}>
            <Box component={props.icon} fill='currentColor' sx={{ height: 25, width: 25 }} />
            {
                props.title && (
                    <Typography component='span' variant={props.variant ?? 'body2'}>
                        {props.title}
                    </Typography>
                )
            }
            {
                props.content && (
                    <Typography component='span' variant={props.variant ?? 'body2'}>
                        {props.content}
                    </Typography>
                )
            }
        </Stack>
    ), [props.color, props.icon, props.title, props.content, props.variant, props.onClick, props.sx]);

    return (
        props.tooltip ? (
            <Tooltip title={props.tooltip}>
                {content}
            </Tooltip>
        ) : content
    );
};

export default ListingRowData;
