import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';

const Article: React.FC<{
    title: string|React.ReactElement,
    content: string|React.ReactElement,
    titleSx?: SxProps<Theme>|undefined
    contentSx?: SxProps<Theme>|undefined,
}> = props => {
    return (
        <>
            {
                'string' === typeof props.title ? (
                    <Typography component='h2' variant='h5' color='secondary' gutterBottom sx={props.titleSx}>
                        {props.title}
                    </Typography>
                ) : props.title
            }
            {
                'string' === typeof props.content ? (
                    <Typography variant='body2' color='textPrimary' align='justify' paddingBottom={2} sx={props.contentSx}>
                        {props.content}
                    </Typography>
                ) : (
                    <Box paddingBottom={2}>
                        {props.content}
                    </Box>
                )
            }
        </>
    );
};

export default Article;
