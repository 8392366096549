import React from 'react';
import { useAppSelector } from '../../store/hooks';
import DraftPostAPIs from '../../APIs/DraftPostAPIs';
import { Typography, Stack } from '@mui/material';
import DraftPost from './DraftPost';
import ModalConfirmation from '../Modal/ModalConfirmation';
import ModalViewDraftPost from '../Modal/ModalViewDraftPost';
import Spinner from '../Spinner/Spinner';
import IDraftPost from '../../models/IDraftPost';

const DraftPostsListing: React.FC = () => {
    // Use of hooks
    const [draftPosts, setDraftPosts] = React.useState<Array<IDraftPost>|null>(null);
    const isMounted = React.useRef<boolean>(true);

    // Use of redux
    const instanceUserId = useAppSelector(state => state.instance.user!.id);

    // Callback to handle duplication of a DraftPost
    const handleDuplicate = (draftPost: IDraftPost) => {
        // Create a new Draft Post
        DraftPostAPIs.postDraftPost({
            html: draftPost.html,
            keyword: draftPost.keyword,
            nbrWords: draftPost.nbrWords,
            optimization: draftPost.optimization,
            optimizationWords: draftPost.optimizationWords,
            user: instanceUserId,
        })
            // On successful API call, refetch DraftPosts
            .then((data: IDraftPost|null) => null !== data && setDraftPosts(null));
    };

    // Callback to handle deletion of a DraftPost
    const handleDelete = (draftPostId: number) => {
        DraftPostAPIs.deleteDraftPost(draftPostId)
            // On successful API call, refetch DraftPosts
            .then(() => setDraftPosts(null));
    };

    // useEffect when component is mounting to load DraftPosts
    React.useEffect(() => {
        null === draftPosts &&
            // Get DraftPosts of current user
            DraftPostAPIs.getDraftPost({ user: instanceUserId, order: false, detailed: true })
                // API call successful
                .then(response => true === isMounted.current && null !== response && setDraftPosts(response.data))
    }, [draftPosts, instanceUserId, ]);

    // useEffect when component is unmounting
    React.useEffect(() => () => {
        // Set isMounted ref to false to prevent state changes on current requests
        isMounted.current = false;
    }, []);

    return (
        <>
            <ModalViewDraftPost title='Article sauvegardé' />
            <ModalConfirmation
                title='Confirmer la suppression'
                message='Veuillez confirmer la suppression de cet article'
                onValidation={handleDelete}
            />
            {
                null !== draftPosts ? (
                    0 < draftPosts.length ? (
                        <Stack gap={2}>
                            {
                                draftPosts.map((draftPost, index: number) => (
                                    <DraftPost
                                        key={index}
                                        draftPost={draftPost}
                                        onDuplicate={handleDuplicate}
                                    />
                                ))
                            }
                        </Stack>
                    ) : (
                        <Typography variant='body2' color='textSecondary' align='center'>
                            {`Aucun article sauvegardé`}
                        </Typography>
                    )
                ) : (<Spinner />)
            }
        </>
    );
};

export default DraftPostsListing;
