import React from 'react';
import {
    SxProps,
    Theme,
    SvgIcon,
} from '@mui/material';

const SvgPostRejected: React.FC<{ sx?: SxProps<Theme> | undefined }> = props => {
    return (
        <SvgIcon
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            sx={props.sx}
        >
            <path fill='#ACB3BA' d='M146.635,205.867v-32c0-60.8,49.067-109.867,109.867-109.867s109.867,49.067,109.867,109.867v32h64v-32C430.368,77.867,352.501,0,256.501,0S82.635,77.867,82.635,173.867v32H146.635z' />
            <g>
                <rect x='82.632' y='189.87' fill='#9CAAB5' width='64' height='16' />
                <rect x='366.371' y='189.87' fill='#9CAAB5' width='64' height='16' />
            </g>
            <path fill='#FF7058' d='M433.568,512H79.435c-23.467,0-42.667-19.2-42.667-42.667v-220.8c0-23.467,19.2-42.667,42.667-42.667h353.067c23.467,0,42.667,19.2,42.667,42.667v220.8C476.234,492.8,457.034,512,433.568,512z' />
            <g>
                <path fill='#FFD15C' d='M475.353,247.719L475.353,247.719c0-23.467-19.2-42.667-42.667-42.667H78.554c-23.467,0-41.907,19.2-41.907,42.667H475.353z' />
                <path fill='#FFD15C' d='M36.769,467.452L36.769,467.452C35.517,493.098,56.03,512,79.497,512h353.066c23.467,0,44.25-16.59,42.667-44.548l0,0H36.769z' />
            </g>
            <path fill='#334A5E' d='M303.435,338.133c0-25.6-21.333-46.933-46.933-46.933s-46.933,21.333-46.933,46.933c0,17.067,9.6,32,23.467,40.533l-6.4,48h60.8l-7.467-49.067C293.835,370.133,303.435,355.2,303.435,338.133z' />
        </SvgIcon>
    );
};

export default SvgPostRejected;
