import React from 'react';
import { Field } from 'formik';
import {
    SxProps,
    Theme,
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import IFormikFieldProps from '../../interfaces/IFormikFieldProps';
import ISelectOption from '../../interfaces/ISelectOption';

const FormikSelect: React.FC<{
    name: string,
    options: Array<ISelectOption>,
    label?: string|undefined,
    autoFocus?: boolean|undefined,
    disabled?: boolean|undefined,
    forceError?: boolean|undefined,
    fullWidth?: boolean|undefined,
    helperText?: string|undefined,
    size?: 'medium'|'small'|undefined,
    variant?: 'filled'|'outlined'|'standard'|undefined,
    sx?: SxProps<Theme>|undefined,
    onChange?: ((option: ISelectOption) => void)|undefined,
}> = props => {
    return (
        <Field name={props.name}>
            {({ field, form, meta }: IFormikFieldProps) => (
                <FormControl
                    fullWidth={props.fullWidth ?? true}
                    disabled={props.disabled}
                    error={props.forceError ?? Boolean(meta.touched && meta.error)}
                    sx={props.sx}
                >
                    {
                        props.label && (
                            <FormLabel sx={{ fontSize: { xs: 16 } }}>
                                {props.label}
                            </FormLabel>
                        )
                    }
                    <Select
                        {...field}
                        // field.value is an object ISelectedOption
                        value={field.value.value}
                        autoFocus={props.autoFocus}
                        margin='none'
                        size={props.size ?? 'small'}
                        variant={props.variant}
                        sx={{ minWidth: 1 }}
                        onChange={(event) => {
                            // Obviously, following value can not be unknown
                            const option = props.options.find(option => option.value === event.target.value)!;
                            form.setFieldValue(props.name, option);
                            props.onChange && props.onChange(option);
                        }}
                    >
                        {
                            props.options.map((option: ISelectOption, index: number) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))
                        }
                    </Select>
                    {
                        (Boolean(meta.touched && meta.error) || props.helperText) && (
                            <FormHelperText component='span'>
                                {Boolean(meta.touched && meta.error) ? meta.error : props.helperText}
                            </FormHelperText>
                        )
                    }
                </FormControl>
            )}
        </Field>
    );
};

export default FormikSelect;
