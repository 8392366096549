import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { IScopesGroupsState } from '../../store/slices/scopesGroupsSlice';
import { IOrderStatusesState } from '../../store/slices/orderStatusesSlice';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import Spinner from '../Spinner/Spinner';
import DashboardMenuExtension from '../DashboardMenuExtension/DashboardMenuExtension';
import DashboardHeadband from '../DashboardHeadband/DashboardHeadband';
import DashboardContent from '../DashboardContent/DashboardContent';

const Dashboard: React.FC = () => {
    // Use of redux
    const scopesGroupsState: IScopesGroupsState = useAppSelector(state => state.scopesGroups);
    const orderStatusesState: IOrderStatusesState = useAppSelector(state => state.orderStatuses);

    /**
     * These two redux states are important for dashboard
     * Indeed, we can't access dashboard (instead display spinner)
     * if these two states does not have a status 'true'.
     * Which means their respective API call ended successfully
     */

    return (
        (scopesGroupsState?.status && orderStatusesState?.status) ? (
            <>
                <DashboardMenu />
                <DashboardMenuExtension />
                <DashboardHeadband />
                <DashboardContent />
            </>
        ) : (<Spinner isFullscreen />)
    );
};

export default Dashboard;
