import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Divider,
    Button,
    Stack,
} from '@mui/material';
import { ArrowForward, Language as LanguageIcon } from '@mui/icons-material';
import RealisticConfettiShot from '../RealisticConfettiShot/RealisticConfettiShot';
import IWebsiteData from '../../interfaces/IWebsiteData';

const SuccessPage: React.FC<{ website: IWebsiteData }> = props => {
    // Use react-router-dom hooks
    const navigate = useNavigate();

    return (
        <>
            <RealisticConfettiShot />
            <Typography component='h1' variant='h3' color='primary' align='center' fontWeight={700} gutterBottom>
                {`Le site a été crée avec succès !`}
            </Typography>
            <Typography component='h2' variant='h6' align='center'>
                {`Pour des raisons de sécurité, il est possible que votre site ne soit pas accessible immédiatament.`}
            </Typography>
            <Typography component='h2' variant='h6' align='center'>
                {`Si toutefois votre site reste innaccessible après plus de 5 minutes d'attente,
                merci de bien vouloir prendre contact avec notre support.`}
            </Typography>
            <Divider variant='middle' sx={{ marginY: 4 }} />
            <Typography variant='h5' gutterBottom>
                {`Vos identifiants administrateur :`}
            </Typography>
            <Typography variant='body2'>
                {`Identifiant: `}
                <Typography variant='body2' color='primary' component='span' fontWeight='bold'>
                    {props.website.login}
                </Typography>
            </Typography>
            <Typography variant='body2'>
                {`Mot de passe: `}
                <Typography variant='body2' color='primary' component='span' fontWeight='bold'>
                    {props.website.password}
                </Typography>
            </Typography>
            <Stack direction='row' gap={2} marginTop={4}>
                <Button
                    variant='outlined'
                    endIcon={<LanguageIcon />}
                    rel='noopener noreferrer'
                    href={`https://www.${props.website.domain}`}
                    target='_blank'
                >
                    {`Allez sur ${props.website.domain}`}
                </Button>
                <Button 
                    variant='contained' 
                    endIcon={<ArrowForward />}
                    onClick={() => navigate('../configure_backlinks', { state: { websiteData: props.website }})}
                >
                    {`Configurer les redirections`}
                </Button>
            </Stack>
        </>
    );
};

export default SuccessPage;
