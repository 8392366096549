import React from 'react';
import { SxProps, SvgIcon, Theme } from '@mui/material';

const SvgDraftPosts: React.FC<{
    sx?: SxProps<Theme>|undefined,
    color?: 'primary'|'secondary'|undefined,
    viewBox?: string|undefined,
}> = props => {
    return (
        <SvgIcon
            viewBox={props.viewBox ?? '0 0 512 512'}
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path fill='#F2F2F2' d="M0,134.4v356.267C0,502.4,9.6,512,21.333,512h382.933L512,404.267V134.4H0z" />
            <path fill='#4CDBC4' d="M512,134.4V21.333C512,9.6,502.4,0,490.667,0H21.333C9.6,0,0,9.6,0,21.333V134.4H512z" />
            <rect y="120.53" fill='#3DC9AE' width="512" height="13.867" />
            <polygon fill='#CDD6E0' points="408.533,438.4 117.333,438.4 117.333,364.8 512,364.8 512,359.467 117.333,359.467 117.333,286.933 512,286.933 512,281.6 117.333,281.6 117.333,208 512,208 512,202.667 117.333,202.667 117.333,134.4 112,134.4 112,202.667 0,202.667 0,208 112,208 112,281.6 0,281.6 0,286.933 112,286.933 112,359.467 0,359.467 0,364.8 112,364.8 112,438.4 0,438.4 0,443.733 112,443.733 112,512 117.333,512 117.333,443.733 408.533,443.733" />
            <path fill='#54C0EB' d="M486.4,247.467c6.4-6.4,6.4-18.133,0-24.533L433.067,169.6c-6.4-6.4-18.133-6.4-24.533,0l-33.067,33.067L454.4,281.6L486.4,247.467z" />
            <g>
                <path fill='#FF7058' d="M374.2,257.867L215.267,416.8l-3-4c0-6.4-3.2-11.733-7.467-17.067c-4.267-4.267-9.6-6.4-14.933-7.467l0,0L347.733,230.4l23.467,23.467L374.2,257.867z" />
                <path fill='#FF7058' d="M426.274,309.067L267.733,467.2l0,0c-1.067-5.333-3.2-10.667-7.467-14.933c-6.703-6.763-21.93-9.797-21.93-9.797l158.479-164.669L426.274,309.067z" />
            </g>
            <path fill='#F1543F' d="M243.2,443.733c1.067-8.533-1.067-17.067-7.467-23.467c-5.333-5.333-11.733-7.467-19.2-7.467c-1.067,0-3.2,0-4.267,0L371.2,253.867l30.933,30.933L243.2,443.733z" />
            <rect x="381.242" y="200.616" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -63.6335 358.5359)" fill='#84DBFF' width="39.466" height="110.929" />
            <g>
                <path fill='#55BFE9' d="M347.733,230.4l27.733-27.733L347.733,230.4z" />
                <polygon fill='#55BFE9' points="400,226.133 400,226.133 375.467,202.667" />
                <polygon fill='#55BFE9' points="429.867,257.067 429.867,257.067 400,226.133 400,226.133" />
            </g>
            <path fill='#4CDBC4' d="M425.6,404.267c-11.733,0-21.333,9.6-21.333,21.333V512L512,404.267H425.6z" />
            <path fill='#FFD15C' d="M267.733,467.2L204.8,481.067l-38.4,8.533l8.533-38.4l14.998-62.961c0,0,10.208,2.201,14.474,6.468c4.267,4.266,8.645,11.173,8.645,17.573c8.533-1.067,15.693,0.38,22.093,6.78c3.2,3.2,6.617,7.486,7.684,11.753s0.961,7.825,0.961,12.092c6.4,0,10.079,4.029,15.413,8.296C264.533,455.467,266.667,461.867,267.733,467.2z" />
            <polygon fill='#334A5E' points="204.8,481.067 166.4,489.6 174.933,452.267 " />
        </SvgIcon>
    );
};

export default SvgDraftPosts;
