import React from 'react';
import { useAppSelector } from '../../store/hooks';
import ProjectAPIs from '../../APIs/ProjectAPIs';
import Tools from '../../helpers/Tools';
import FormikAutocomplete from '../FormikComponent/FormikAutocomplete';
import IProject from '../../models/IProject';

const SelectProject: React.FC<{ onReset: () => void }> = props => {
    // Use of hooks
    const [projects, setProjects] = React.useState<Array<IProject>|null>(null);
    const mountedRef = React.useRef<boolean>(true);

    // Use of redux
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // useEffect when component is mounting
    React.useEffect(() => {
        null === projects &&
            // Call API to fetch projects of the instance user
            ProjectAPIs.getProject({ user: instanceUserId })
                // On successful API call
                .then((projects: Array<IProject>|null) =>
                    // Do not update hook if component is unmounted or null value returned
                    true === mountedRef.current && null !== projects && setProjects(projects)
                );
    }, [projects, instanceUserId]);

    // useEffect when component is unmounting, set mountedRef to false
    React.useEffect(() => () => {
        mountedRef.current = false;
    }, []);

    return (
        <FormikAutocomplete
            upperlabel='Projet sur lequel commander'
            name='project'
            loading={null === projects}
            options={Tools.convertToSelectOptions(projects, 'id', 'name')}
            sx={{ paddingBottom: 3 }}
            onChange={option => null === option && props.onReset()}
        />
    );
};

export default SelectProject;
