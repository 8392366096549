import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal, IActiveModalState } from '../../store/slices/activeModalSlice';
import { Box, Stack, Button, Typography } from '@mui/material';
import GenericModal from './GenericModal';

const ModalConfirmation: React.FC<{
    title: string,
    message: string|React.ReactElement|((modalParams?: any) => React.ReactElement),
    name?: string|undefined,
    width?: number|string|undefined,
    type?: 'error'|'warning'|undefined,
    validationButtonColor?: 'inherit'|'primary'|'secondary'|'success'|'error'|'info'|'warning'|undefined,
    onValidation?: ((modalParams?: any) => void)|undefined,
    onClose?: ((modalParams?: any) => void)|undefined,
}> = props => {
    // Use of hooks
    const modalName = React.useRef<string>(props.name ?? 'confirmModal');

    // Use of redux
    const dispatch = useAppDispatch();
    const activeModalState: IActiveModalState = useAppSelector(state => state.activeModal);

    // Callback to handle confirm button
    const handleValidation = React.useCallback(() => {
        // Call given prop validation callback
        props.onValidation && props.onValidation(activeModalState.params);
        // Validation also close modal
        dispatch(closeModal());
    }, [activeModalState, props, dispatch]);

    // Callback to handle modal closing
    const handleClose = React.useCallback(() => {
        // Call given prop validation callback
        props.onClose && props.onClose(activeModalState.params);
        // Validation also close modal
        dispatch(closeModal());
    }, [activeModalState, props, dispatch]);

    return (
        <GenericModal
            name={modalName.current}
            title={props.title}
            width={props.width ?? 1/3}
            type={props.type}
            onClose={handleClose}
        >
            <Box paddingX={1} marginBottom={3}>
                {
                    'string' === typeof props.message ? (
                        <Typography align='center' paddingBottom={1}>
                            {props.message}
                        </Typography>
                    ) : 'function' === typeof props.message ?
                        props.message(activeModalState.params)
                        : props.message
                }
            </Box>
            <Stack direction='row' justifyContent='space-between' paddingX={1}>
                <Button
                    variant='outlined'
                    color='inherit'
                    sx={{ marginRight: 2 }}
                    onClick={handleClose}
                >
                    {`Annuler`}
                </Button>
                <Button
                    variant='outlined'
                    color={props.validationButtonColor ?? 'error'}
                    onClick={handleValidation}
                >
                    {`Confirmer`}
                </Button>
            </Stack>
        </GenericModal>
    );
};

export default ModalConfirmation;
