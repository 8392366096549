import React from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import useValidationSchema from '../../hooks/useValidationSchema';
import DraftPostAPIs from '../../APIs/DraftPostAPIs';
import OrderAPIs from '../../APIs/OrderAPIs';
import OtherAPIs from '../../APIs/OtherAPIs';
import Tools from '../../helpers/Tools';
import { FinalStep, FinalStepperForm } from '../FinalStepperForm/FinalStepperForm';
import Destination from './Destination';
import PostStep from './PostStep';
import SummaryStep from './SummaryStep';
import ProjectStep from './ProjectStep';
import Spinner from '../Spinner/Spinner';
import IRadioOption from '../../interfaces/IRadioOption';
import ISelectOption from '../../interfaces/ISelectOption';
import IWpCategory from '../../interfaces/IWpCategory';
import IDraftPost from '../../models/IDraftPost';
import IUploadFile from '../../models/IUploadFile';

export type PublishDraftPostFormType = {
    project: ISelectOption|null,
    postType: ISelectOption|null,
    activitySector: ISelectOption|null,
    destinationChoice: IRadioOption,
    externalUrl: string|null,
    thematic: ISelectOption|null,
    website: ISelectOption|null,
    category: ISelectOption|null,
    imageSelection: IRadioOption|null,
    image: { file: File|null, url: string }|null,
    title: string|null,
    metaDesc: string|null,
    toPublishAt: string|null,
};

// Function to retrieve H1 tag in HTML content
const getTitleFromHtml = (html: string|undefined) => {
    let title = '';

    if (html) {
        // Find the first H1 tag in the HTML content
        const regex = /<h1>([^<]+)/g;
        const matches = regex.exec(html);
        if (null !== matches) {
            title = Tools.stripTags(matches[1]);
        }
    }

    return title;
};

// Function to retrieve meta description from HTML content
const getMetaDescFromHtml = (html: string|undefined) => {
    let metaDesc = '';

    if (html) {
        // Find first sentence in the first paragraph of the HTML content
        const regex = /<p>([^<]+?[.!?])/g;
        const matches = regex.exec(html);
        if (null !== matches) {
            metaDesc = Tools.stripTags(matches[1]);
            metaDesc = 160 < metaDesc.length ? metaDesc.substring(0, 157) + '...' : metaDesc;
        }
    }

    return metaDesc;
};

const PublishDraftPostStepper: React.FC = () => {
    // Use of hooks
    const [draftPost, setDraftPost] = React.useState<IDraftPost|null>(null);
    const wpCategories = React.useRef<Array<IWpCategory>>([]);

    // Use of react-router-dom hooks
    const navigate = useNavigate();
    const { state: draftPostId } = useLocation();

    // Validation schema for ProjecStep
    const projectStepVS = useValidationSchema({
        project: Yup.object().nullable().required('Veuillez sélectionner un projet'),
        postType: Yup.object().nullable().required("Veuillez sélectionner un type de l'article"),
        activitySector: Yup.object().nullable().required("Veuillez sélectionner un secteur d'activité"),
    });

    // Validation schema for SiteStep
    const siteStepVS = useValidationSchema({
        destinationChoice: Yup.object().required('Veuillez sélectionner une destination'),
        externalUrl: Yup.string().nullable()
            .url('Veuillez saisir une URL valide')
            .test(
                'required',
                'Veuillez renseigner un site de destination',
                function (value: string|null|undefined) {
                    return 'extern' !== this.parent.destinationChoice.value || Boolean(value);
                }
            ),
        thematic: Yup.mixed().nullable()
            .test(
                'required',
                'Veuillez sélectionner une thématique',
                function (value: ISelectOption|undefined) {
                    return 'intern' !== this.parent.destinationChoice.value || Boolean(value);
                }
            ),
        website: Yup.mixed().nullable()
            .test(
                'required',
                'Veuillez sélectionner un site',
                function (value: ISelectOption|undefined) {
                    return 'intern' !== this.parent.destinationChoice.value || Boolean(value);
                }
            ),
            category: Yup.mixed().nullable()
            .test(
                'required',
                'Veuillez sélectionner une catégorie',
                function (value: ISelectOption|undefined) {
                    return 'intern' !== this.parent.destinationChoice.value || Boolean(value);
                }
            ),
    });

    // Validation schema for PostStep
    const postStepVS = useValidationSchema({
        image: Yup.mixed().required('Veuillez renseigner une image à la une'),
        title: Yup.string().required("Nous n'avons pas pu récupérer le titre de l'article dans le contenu HTML"),
        metaDesc: Yup.string().required('Veuillez renseigner une meta description'),
    });

    // Define form initial values
    const initialValues: PublishDraftPostFormType = {
        project: null,
        postType: null,
        activitySector: null,
        destinationChoice: { value: 'intern', label: '' },
        externalUrl: null,
        thematic: null,
        website: null,
        category: null,
        imageSelection: {
            label: '',
            value: '0',
        },
        image: null,
        title: getTitleFromHtml(draftPost?.html),
        metaDesc: getMetaDescFromHtml(draftPost?.html),
        toPublishAt: null,
    };

    // useEffect when component is mounted
    React.useEffect(() => {
        // Retrieve draft post by id
        null === draftPost &&
            DraftPostAPIs.getDraftPost({ id: draftPostId, detailed: true })
                .then(response => null !== response && 1 === response.count && setDraftPost(response.data[0]));
    }, [draftPost, draftPostId]);

    // Callback to handle form submit
    const handleSubmit = async (values: PublishDraftPostFormType) => {
        // Upload image to the server if needed
        if (values.image?.file) {
            values.image!.url = await OtherAPIs.postUploadFile(values.image!.file)
                .then((uploadedFile: IUploadFile) => uploadedFile.url);
            values.image.file = null;
        }

        // Construct body request
        const bodyRequest = {
            draftPost: draftPost!.id,
            project: values.project!.value,
            postType: values.postType!.value,
            activitySector: values.activitySector!.value,
            site: values.website?.value,
            category: values.category?.value ? wpCategories.current!.find((category: IWpCategory) => category.wordPressId === +values.category!.value)! : undefined,
            externalUrl: values.externalUrl ?? undefined,
            imageUrl: values.image!.url,
            title: values.title,
            metaDesc: values.metaDesc,
            toPublishAt: values.toPublishAt ?? null
        };

        return OrderAPIs.createFromDraftPost(bodyRequest)
            .then(data => null !== data && navigate('../draft_posts_listing'));
    };

    return (
        null !== draftPost ? (
            <FinalStepperForm<PublishDraftPostFormType>
                onSubmit={(values) => handleSubmit(values)}
                initialValues={initialValues}
                formProps={{ marginTop: 5 }}
            >
                <FinalStep
                    name='Info. de projet'
                    validationSchema={projectStepVS}
                    children={<ProjectStep />}
                />
                <FinalStep
                    name='Info. de site'
                    validationSchema={siteStepVS}
                    children={<Destination onCategoriesLoaded={categories => wpCategories.current = categories} />}
                />
                <FinalStep
                    name="Info. de l'article"
                    validationSchema={postStepVS}
                    children={<PostStep draftPost={draftPost} />}
                />
                <FinalStep
                    name='Résumé'
                    children={<SummaryStep />}
                />
            </FinalStepperForm>
        ) : (
            <Spinner />
        )
    );
};

export default PublishDraftPostStepper;
