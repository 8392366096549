import ICssStyles from '../../interfaces/ICssStyles';

const cssStyles: ICssStyles = {
    card: {
        display: 'inline-flex',
        position: 'relative',
    },
    cardMedia: {
        minWidth: 150,
        minHeight: 150,
    },
    closeIcon: {
        position: 'absolute',
        top: 1,
        right: 1,
        color: theme => 'light' === theme.palette.mode ? 'black' : 'white',
    },
};

export default cssStyles;
