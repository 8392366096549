import React from 'react';
import moment from 'moment-timezone';
import { useTheme } from '@mui/material';
import { Font, Document, View, Image, Text } from '@react-pdf/renderer';
import CompanyPage from '../elements/CompanyPage';
import Stack from '../elements/Stack';
import GothicNormal from '../../fonts/Gothic/Gothic-Normal.ttf';
import GothicLight from '../../fonts/Gothic/Gothic-Light.ttf';
import GothicSemiBold from '../../fonts/Gothic/Gothic-SemiBold.ttf';
import GothicBold from '../../fonts/Gothic/Gothic-Bold.ttf';
import IAccount from '../../models/IAccount';
import ICompany from '../../models/ICompany';
import IUser from '../../models/IUser';

const Invoice: React.FC<{
    account: IAccount,
    number: string,
    amount: string|null,
}> = props => {
    // Create css styles using theme & props
    const theme = useTheme();

    // Register font
    Font.register({ family: 'Gothic', fonts: [
        { src: GothicNormal },
        { src: GothicLight, fontWeight: 'light' },
        { src: GothicSemiBold, fontWeight: 700 },
        { src: GothicBold, fontWeight: 900 },
    ]});

    // Calculation of final price
    const price: string = props.amount && '' !== props.amount ? props.amount : '0.00';
    const TVA: string = '0.00';
    const TVAPrice: number = parseFloat(price) * parseFloat(TVA) / 100;
    const finalPrice: string = (parseFloat(price) + TVAPrice).toFixed(2);

    // Define multiple times used variable
    const company: ICompany = props.account.company!;
    const user: IUser = props.account.user;

    return (
        <Document>
            <CompanyPage user={user} company={company} style={{ fontFamily: 'Gothic', fontSize: 10 }}>
                <Stack style={{ justifyContent: 'space-between', paddingBottom: 20 }}>
                    {
                        company.logo ? (
                            <Image
                                src={company.logo}
                                style={{ maxWidth: '30%',  maxHeight: '80px', objectFit: 'contain' }}
                            />
                        ) : (
                            <View></View>
                        )
                    }
                    <View style={{ paddingRight: 30 }}>
                        <Text style={{ fontWeight: 900, fontSize: 16 }}>
                            {`Facture`}
                        </Text>
                        <Text>
                            {`N° ${props.number}`}
                        </Text>
                        <Text>
                            {`Date : ${moment().format('DD/MM/Y')}`}
                        </Text>
                    </View>
                </Stack>
                <Stack style={{ justifyContent: 'space-between', paddingBottom: 20 }}>
                    <View>
                        <Text style={{ textTransform: 'uppercase', fontWeight: 900, paddingBottom: 5 }}>
                            {company.name}
                        </Text>
                        <Text>
                            {company.address}
                        </Text>
                        <Text style={{ textTransform: 'uppercase' }}>
                            {`${company.postalCode} - ${company.city}`}
                        </Text>
                        <Text style={{ paddingTop: 10 }}>
                            {`Siret : ${company.siret}`}
                        </Text>
                    </View>
                    <View style={{ paddingRight: 60 }}>
                        <Text style={{ textTransform: 'uppercase', fontWeight: 900, paddingBottom: 5 }}>
                            {`SARL Ready Up`}
                        </Text>
                        <Text>
                            {`10 rue Albert Ferasse`}
                        </Text>
                        <Text style={{ textTransform: 'uppercase' }}>
                            {`47550 - BOE`}
                        </Text>
                    </View>
                </Stack>
                <View style={{ color: theme.palette.text.secondary, paddingBottom: 20 }}>
                    <Text>
                        {`Tél. : ${company.phone ? `${company.phonePrefix}${company.phone}` : `${user.phonePrefix}${user.phone}`}`}
                    </Text>
                    <Text>
                        {`Email: ${company.email ?? user.email}`}
                    </Text>
                    {
                        company.website && (
                            <Text>
                                {`Site web : ${company.website}`}
                            </Text>
                        )
                    }
                </View>
                <View style={{ minHeight: 350, borderBottom: '1px solid black', marginBottom: 5 }}>
                    <Stack style={{
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            fontWeight: 900,
                            alignItems: 'center',
                            padding: '5 5 2',
                            lineHeight: 1,
                            fontSize: 8,
                    }}>
                        <Text style={{ width: '40%' }}>
                            {`Libellé`}
                        </Text>
                        <Text style={{ width: '15%', textAlign: 'right' }}>
                            {`Quantité`}
                        </Text>
                        <Text style={{ width: '20%', textAlign: 'right' }}>
                            {`Prix unitaire HT`}
                        </Text>
                        <Text style={{ width: '15%', textAlign: 'right' }}>
                            {`Montant HT`}
                        </Text>
                        <Text style={{ width: '10%', textAlign: 'right' }}>
                            {`TVA`}
                        </Text>
                    </Stack>
                    {/** MAP */}
                    <Stack style={{ padding: 5, fontSize: 8 }}>
                        <Text style={{ width: '40%', textTransform: 'uppercase' }}>
                            {`001 - Récupération de fonds du porte-monnaie`}
                        </Text>
                        <Text style={{ width: '15%', textAlign: 'right' }}>
                            {`1`}
                        </Text>
                        <Text style={{ width: '20%', textAlign: 'right' }}>
                            {`${price} €`}
                        </Text>
                        <Text style={{ width: '15%', textAlign: 'right' }}>
                            {`${price} €`}
                        </Text>
                        <Text style={{ width: '10%', textAlign: 'right' }}>
                            {`${TVA}%`}
                        </Text>
                    </Stack>
                </View>
                <Stack style={{ justifyContent: 'space-between', lineHeight: 1, fontSize: 9, paddingBottom: 20 }}>
                    <Stack>
                        <Text style={{ padding: '5 5 3', minWidth: '15%', backgroundColor: '#d3d3d3' }}>
                            {'Réglement'}
                        </Text>
                        <Text style={{ padding: '5 5 3', color: theme.palette.text.secondary }}>
                            {'Virement'}
                        </Text>
                    </Stack>
                    <View style={{ minWidth: '35%' }}>
                        <Stack style={{ justifyContent: 'space-between', padding: '5 5 3', backgroundColor: '#d3d3d3' }}>
                            <Text>
                                {`Total HT`}
                            </Text>
                            <Text>
                                {`${price} €`}
                            </Text>
                        </Stack>
                        <Stack style={{ justifyContent: 'space-between', padding: '5 5 3' }}>
                            <Text>
                                {`TVA`}
                            </Text>
                            <Text>
                                {`${TVAPrice} €`}
                            </Text>
                        </Stack>
                        <Stack style={{ justifyContent: 'space-between', padding: '5 5 3', fontWeight: 900 }}>
                            <Text>
                                {`TOTAL TTC`}
                            </Text>
                            <Text>
                                {`${finalPrice} €`}
                            </Text>
                        </Stack>
                    </View>
                </Stack>
                <Text style={{ fontSize: 7, color: theme.palette.text.primary, paddingBottom: 15 }}>
                    {`Facture établie par ${process.env.REACT_APP_NAME}.fr au nom et pour le compte de ${company.name}`}
                </Text>
                <View style={{ fontSize: 6, color: theme.palette.text.secondary, paddingBottom: 10 }}>
                    <Text>
                        {`En cas de retard de paiement, une pénalité égale à trois fois le taux d'intérêt sera exigible (Article L 441-6, alinéa 12 du Code de Commerce).`}
                    </Text>
                    <Text>
                        {`Pour tout professionnel, en sus des indemnités de retard, toute somme, y compris l'acompte, non payée à sa date d'exigibilité produira de plein droit le paiement d'une indemnité
                        forfaitaire de 40 euros due au titre des frais de recouvrement (Art. 441-6, l al. 12 du code de commerce et D. 441-5 ibidem).`}
                    </Text>
                </View>
                <View style={{ fontSize: 6, color: theme.palette.text.secondary }}>
                    <Text>
                        {`Coordonnées bancaires :`}
                    </Text>
                    <Text>
                        {`IBAN : ${company.iban}`}
                    </Text>
                    <Text>
                        {`Pays : ${company.country.name}`}
                    </Text>
                </View>
            </CompanyPage>
        </Document>
    );
};

export default Invoice;
