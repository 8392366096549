import React from 'react';
import {
    Stack,
    Typography,
    Rating,
} from '@mui/material';
import HomePanel from '../Home/HomePanel';
import RatingBox from './RatingBox';

const PanelGlobalRating: React.FC<{ rating: number|null }> = props => {
    return (
        <HomePanel header='mon évaluation' sx={{ width: '30%' }}>
            <Stack justifyContent='center' alignItems='center' height={1}>
                <RatingBox gradientFromColor='#001c53' gradientToColor='#3ecfed'>
                    <Typography variant='body2' lineHeight={1} align='center'>
                        {`Moyenne générale`}
                    </Typography>
                    <Typography variant='h4' fontWeight={700}>
                        {props.rating ?? '_._'}
                    </Typography>
                    <Rating value={props.rating ?? 0} precision={0.1} size='small' readOnly />
                </RatingBox>
            </Stack>
        </HomePanel>
    );
};

export default PanelGlobalRating;
