import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IActiveModalState {
    name: string|null,
    params?: any,
};

const initialState: IActiveModalState = {
    name: null,
    params: null,
};

export const slice = createSlice({
    name: 'activeModalState',
    initialState,
    reducers: {
        openModal: (state: IActiveModalState, action: PayloadAction<IActiveModalState>) => {
            state.name = action.payload.name;
            state.params = action.payload.params ?? null;
        },
        closeModal: (state: IActiveModalState) => {
            state.name = null;
            state.params = null;
        },
    },
});

export const { openModal, closeModal } = slice.actions;

// Custom selector to check if specific modal is open
export const isModalOpened = (name: string) => createSelector(
    (state: any) => state.activeModal.name,
    (modalName: string|null) => modalName === name
);

export default slice.reducer;
