import React from 'react';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import useValidationSchema from '../../hooks/useValidationSchema';
import { Field, FormSpy } from 'react-final-form';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import {
    Grid,
    Typography,
    Divider,
    Backdrop,
    CircularProgress,
    FormHelperText,
    Button,
    Alert,
    Checkbox,
    FormControlLabel,
    Stack,
} from '@mui/material';
import { BsWordpress } from 'react-icons/bs';
import FinalForm from '../FinalForm/FinalForm';
import FinalInput from '../FinalComponents/FinalInput';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import CategoriesManagement from './CategoriesManagement';
import ChooseTemplate, { wordpressTemplates } from './ChooseTemplate';
import ModalImportCsv from '../Modal/ModalImportCsv';
import SuccessPage from './SuccessPage';
import ThematicSelection from '../ThematicSelection/ThematicSelection';
import SelectProject from '../SelectEntities/SelectProject';
import IWebsiteData from '../../interfaces/IWebsiteData';
import ISelectOption from '../../interfaces/ISelectOption';
import ICsvData from '../../interfaces/ICsvData';
import ICsvEntityStructure from '../../interfaces/ICsvEntityStructure';

type FormValues = {
    thematic: ISelectOption|null,
    domain: string,
    template: string|null,
    categories: Array<string>|null,
    nbrCategories: number|null,
    postsPerCategories: number|null,
    project: ISelectOption|null,
};

const expectedHeaders = [
    { label: 'Thématique', value: 'thematic' },
    { label: 'Nom de domaine', value: 'domain' },
    { label: 'Template WordPress', value: 'template' },
    { label: 'Catégories WordPress', value: 'categories' },
    { label: "Nombre de catégories", value: 'nbrCategories' },
    { label: "Nombre d'articles par catégories", value: 'postsPerCategories' },
    { label: 'Nom du projet', value: 'project' },
];

const dataValidation: ICsvEntityStructure = {
    'thematic': { required: true, type: 'string' },
    'domain': { required: true, type: 'string' },
    'template': { required: false, type: 'string' },
    'categories': { required: false, type: 'array' },
    'nbrCategories': { required: false, type: 'int' },
    'postsPerCategories': { required: false, type: 'int' },
    'project': { required: false, type: 'string' },
};

const WebsiteBuilding: React.FC = () => {
    // Use of hooks
    const [websiteData, setWebsiteData] = React.useState<IWebsiteData|null>(null);
    const [importedResultLength, setImportedResultLength] = React.useState<number|null>(null);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceAccountId: number = useAppSelector(state => state.instance.account!.id);
    const instanceUserId: number = useAppSelector(state => state.instance.user!.id);

    // Define initial values of the form
    const initialValues: FormValues = {
        thematic: null,
        domain: '',
        template: 'mugu',
        categories: [],
        nbrCategories: null,
        postsPerCategories: null,
        project: null,
    };

    // Define validation schema of the form
    const validationSchema = useValidationSchema({
        thematic: Yup
            .object()
            .nullable()
            .required('Veuillez renseigner une thématique pour le site'),
        domain: Yup
            .string()
            .required('Veuillez renseigner le nom de domaine du site'),
        template: Yup
            .string()
            .nullable()
            .test(
                'match',
                'Veuillez choisir une template valide',
                (template) => null === template || (undefined !== template && wordpressTemplates.map(template => template.value).includes(template)),
            ),
        postsPerCategories: Yup
            .number()
            .nullable()
            .min(1, "Le minimum d'article par catégorie est 1")
            .max(10, "Le maximum d'articles par catégorie est 10"),
        project: Yup
            .mixed<ISelectOption>()
            .nullable()
            .test(
                'required',
                'Veuillez renseigner un projet',
                function (project) {
                    return !Boolean(this.parent.postsPerCategories) || Boolean(project);
                }
            ),
    });

    // Callback used to submit form
    const onSubmit = React.useCallback((values: FormValues) => {
        // Call API to build the website
        return BridgeAPIs.websiteBuilding({
            account: instanceAccountId,
            user: instanceUserId,
            thematic: values.thematic!.value,
            domain: values.domain,
            template: values.template,
            categories: values.categories ?
                0 < values.categories.length ?
                    values.categories
                    : null
                : null,
            nbrCategories: values.nbrCategories,
            postsPerCategories: values.postsPerCategories,
            project: values.project?.value ?? null,
        })
            // On successful API call
            .then((data: IWebsiteData|null) => null !== data && setWebsiteData(data));
    }, [instanceAccountId, instanceUserId]);

    // Callback to handle CSV data
    const handleImportCsvData = React.useCallback((csvData: ICsvData) => {
        // Call API to create scheduled website building
        BridgeAPIs.multipleWebsiteBuilding(csvData).then(data => null !== data && setImportedResultLength(data.length));
    }, []);

    return (
        null === websiteData ? (
            <>
                <ModalImportCsv
                    name='multipleSiteBuildingModal'
                    modalTitle='Créer des sites en masse'
                    expectedHeaders={expectedHeaders}
                    dataValidation={dataValidation}
                    prefilledColumns={{
                        account: instanceAccountId,
                        user: instanceUserId,
                    }}
                    onValidation={(data) => handleImportCsvData(data)}
                />
                <Typography variant='h6' gutterBottom>{`Créer des sites en masse en important un fichier CSV`}</Typography>
                <Button
                    variant='outlined'
                    onClick={() => dispatch(openModal({ name: 'multipleSiteBuildingModal' }))}
                >
                    {`Importer`}
                </Button>
                {
                    importedResultLength && (
                        <Alert color='success' sx={{ marginTop: 2 }}>
                            {`Succès : ${importedResultLength} sites vont être créés dans les minutes à venir.`}
                        </Alert>
                    )
                }
                <Divider variant='middle' sx={{ marginY: 4 }} />
                <FinalForm<FormValues>
                    initialValues={initialValues}
                    validateSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    <ThematicSelection />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} paddingBottom={4}>
                            <Typography variant='h5' gutterBottom>
                                {`Choix du nom de domaine`}
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                {`Le choix de votre nom de domaine est une étape importante.
                                Pour que votre site soit accessible, veillez à relier
                                votre nom de domaine au DNS suivant :`}
                            </Typography>
                            <Typography variant='body2' color='primary'>
                                {`ns1.lwb-network.fr`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FinalInput
                                upperLabel
                                label='Nom de domaine du site'
                                size='medium'
                                name='domain'
                                prefix='https://www.'
                                helperText='Votre site sera automatiquement créé sous https://'
                            />
                        </Grid>
                    </Grid>
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h5' gutterBottom>
                                {`Choix du thème`}
                            </Typography>
                            <Typography variant='body2'>
                                {`Vous pouvez choisir le thème WordPress que vous souhaitez avoir sur votre site.`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Field
                                name='template'
                                allowNull
                                subscription={{ value: true }}
                                render={({ input }) => (
                                    <FormControlLabel
                                        label='Automatique'
                                        control={
                                            <Checkbox
                                                checked={null === input.value}
                                                onChange={event => input.onChange(event.target.checked ? null : 'mugu')}
                                            />
                                        }
                                    />
                                )} 
                            />
                            <Field
                                name='template'
                                subscription={{ error: true, modified: true }}
                                render={({ meta: { error, modified } }) => (
                                    error && modified ? (
                                        <FormHelperText error>{error}</FormHelperText>
                                    ) : null
                                )} 
                            />
                        </Grid>
                    </Grid>
                    <Field
                        name='template'
                        allowNull
                        subscription={{ value: true }}
                        render={({ input }) => (
                            null !== input.value && (
                                <ChooseTemplate />
                            )
                        )} 
                    />
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h5' gutterBottom>
                                {`Catégories du WordPress `}
                                <BsWordpress size={18} />
                            </Typography>
                            <Typography variant='body2'>
                                {`Le site créé étant un WordPress, vous avez la possibilité de créer ses
                                catégories dès à présent. Elles seront automatiquement ajoutées au site
                                durant le processus de création & déploiement.`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Field
                                name='categories'
                                allowNull
                                subscription={{ value: true }}
                                render={({ input: { value, onChange: setCategories } }) => (
                                    <>
                                        <Stack>
                                            <Field
                                                name='nbrCategories'
                                                subscription={{}}
                                                render={({ input: { onChange: setNbrCategories } }) => (
                                                            <FormControlLabel
                                                                label='Automatique'
                                                                control={
                                                                    <Checkbox
                                                                        checked={null === value}
                                                                        onChange={event => {
                                                                            setCategories(event.target.checked ? null : []);
                                                                            setNbrCategories(event.target.checked ? 4 : null);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                )}
                                            />
                                            {
                                                null === value && (
                                                    <FinalInput name='nbrCategories' numberFormatProps={{ decimalScale: 0, allowNegative: false, suffix: ' catégories' }} />
                                                )
                                            }
                                        </Stack>
                                        {
                                            null !== value && (
                                                <CategoriesManagement onChange={setCategories} />
                                            )
                                        }
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h5' gutterBottom>
                                {`Pré remplir le site`}
                            </Typography>
                            <Typography variant='body2'>
                                {`Une fois le site déployé, le pré remplir avec un nombre défini d'articles par catégorie.`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Field
                                name='postsPerCategories'
                                allowNull
                                subscription={{ value: true }}
                                render={({ input: { value, onChange } }) => (
                                    <Stack>
                                        <FormControlLabel
                                            label='Pré remplir après le déploiement'
                                            control={
                                                <Checkbox
                                                    checked={null !== value}
                                                    onChange={event => onChange(event.target.checked ? 8 : null)}
                                                />
                                            }
                                        />
                                        {
                                            null !== value && (
                                                <Stack gap={2}>
                                                    <FinalInput name='postsPerCategories' label='Articles par catégorie' upperLabel size='small' numberFormatProps={{ decimalScale: 0, allowNegative: false, suffix: ' articles par catégorie' }} />
                                                    <SelectProject name='project' />
                                                </Stack>
                                            )
                                        }
                                    </Stack>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Divider variant='middle' sx={{ marginY: 4 }} />
                    <FinalSubmitButton variant='contained' enabledOnPristine enabledOnInvalid>
                        {`Créer et déployer le site`}
                    </FinalSubmitButton>
                    <FormHelperText>
                        {`Le temps de création et déploiement d'un site peut varier entre 20sec ~ 1min.`}
                    </FormHelperText>
                    <FormSpy
                        subscription={{ submitting: true, }}
                        render={({ submitting }) => (
                            <Backdrop open={submitting}>
                                <CircularProgress size='10vh' />
                            </Backdrop>
                        )}
                    />
                </FinalForm>
            </>
        ) : (<SuccessPage website={websiteData} />)
    );
};

export default WebsiteBuilding;
