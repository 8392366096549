import React from 'react';
import { useField } from 'react-final-form';
import FinalCheckbox from '../FinalComponents/FinalCheckbox';
import FinalInput from '../FinalComponents/FinalInput';

const GenerationOptions = () => {
    // Use of react-final-form hook
    const { input: { value: optimized }} = useField('optimized', { subscription: { value: true }});
    const { input: { onChange: setEstimatedNbrWords }} = useField('estimatedNbrWords');

    // useEffect when optimized changes
    React.useEffect(() => {
        // Reset estimatedNbrWords value
        setEstimatedNbrWords(null);
    }, [optimized, setEstimatedNbrWords]);

    return (
        <>
            <FinalCheckbox
                name='optimized'
                label="Je souhaite un article optimisé SEO sur l'expression clé"
            />
            <FinalInput
                name='estimatedNbrWords'
                upperLabel
                label='Je souhaite un article contenant environ'
                placeholder='500'
                size='small'
                numberFormatProps={{ decimalScale: 0 }}
                disabled={true === optimized}
                suffix='mots'
                helperText='Par défaut un article généré sans option contient environ 500 mots.'
                sx={{ width: 200 }}
            />
        </>
    );
};

export default GenerationOptions;
