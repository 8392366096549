import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { closeModal } from '../../store/slices/activeModalSlice';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    Stack,
} from '@mui/material';
import GenericModal from './GenericModal';

const ModalRespectCriterias: React.FC<{
    name?: string|undefined,
    modalTitle: string,
    criterias: Array<string>,
    onValidation: () => void,
}> = props => {
    // Use of hooks
    const [criteriaIndexes, setCriteriaIndexes] = React.useState<Array<number>>([]);
    const modalName = React.useRef<string>(props.name ?? 'respectCriterias');

    // Use of redux
    const dispatch = useAppDispatch();

    // Callback to handle check or uncheck of checkboxes
    const handleChange = React.useCallback((checked: boolean, index: number) => {
        checked ?
            setCriteriaIndexes([ ...criteriaIndexes, index])
            : setCriteriaIndexes(criteriaIndexes => criteriaIndexes.filter(criteriaIndex => criteriaIndex !== index));
    }, [criteriaIndexes])

     // Callback used when modal is validated
     const handleValidation = React.useCallback(() => {
        // Call given prop modal validation callback
        props.onValidation && props.onValidation();
        // Validation also close modal
        dispatch(closeModal());
    }, [props, dispatch]);

    return (
        <GenericModal
            name={modalName.current}
            title={props.modalTitle}
            onClose={() => setCriteriaIndexes([])}
            width={3/7}
        >
            <Typography>
                {`Cochez les critères que vous vous êtes engagé de respecter :`}
            </Typography>
            <Stack rowGap={1} paddingY={2}>
                {
                    props.criterias.map((criteria: string, index: number) => (
                        <FormControlLabel
                            key={index}
                            control={(
                                <Checkbox
                                    size='small'
                                    onChange={(_, checked) => handleChange(checked, index)}/>
                            )}
                            label={
                                <Typography variant='body2'>
                                    {criteria}
                                </Typography>
                            }
                        />
                    ))
                }
            </Stack>
            <Button
                variant='outlined'
                disabled={props.criterias.length > criteriaIndexes.length}
                sx={{ marginTop: 2 }}
                onClick={handleValidation}
            >
                {`J'ai respecté tous ces critères`}
            </Button>
        </GenericModal>
    )
};

export default ModalRespectCriterias;
