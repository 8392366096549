import React from 'react';
import {
    Avatar,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';

const UploadAvatar: React.FC<{
    imageSrc: string|undefined,
    imageAlt: string,
    onChange?: ((image: File) => void)|undefined,
}> = props => {
    // Use of hooks
    const [error, setError] = React.useState<string|null>(null);
    const [image, setImage] = React.useState<string|undefined>(props.imageSrc);
    const imageInput = React.useRef<HTMLInputElement>(null);

    // Callback used once file is selected
    const handleFileChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        // Check if files array is not empty
        if (null !== event.currentTarget.files) {
            // Retrieve file from event
            const file = event.currentTarget.files[0];
            // Check for image size
            if (file.size > parseInt(process.env.REACT_APP_MAX_AVATAR_SIZE!) * 1000) {
                // Setup error hook value
                setError("L'image choisie est trop volumineuse.");
            } else {
                // Clear error hook value
                setError(null);
                // Change the avatar with the new image
                setImage(URL.createObjectURL(file));
                // Also call the given props callback if not undefined
                props.onChange && props.onChange(file);
            }
        }
    }, [props]);

    // useEffect to make sure to revoke the data uris to avoid memory leaks
    React.useEffect(() => () => {
        // Note that it's a double arrow useEffect so it occurs when component unmount
        undefined !== image && URL.revokeObjectURL(image);
    }, [image]);

    return (
        <Stack>
            <Stack onClick={() => imageInput.current?.click()}>
                <Tooltip title="Cliquer pour changer l'image" followCursor>
                    <Avatar
                        variant='square'
                        src={image}
                        sx={{ width: 200, height: 200, fontSize: '3rem', fontWeight: 700, marginBottom: 1, cursor: 'pointer' }}
                        children={props.imageAlt}
                    />
                </Tooltip>
                <input ref={imageInput} type='file' accept='image/png, image/jpeg' onChange={(event) => handleFileChange(event)} hidden />
            </Stack>
            {
                null !== error ? (
                    <Typography component='p' variant='caption' color='error.main'>
                        {error}
                    </Typography>
                ) : (
                    <Typography component='p' variant='caption'>
                        {`Taille maximale : ${process.env.REACT_APP_MAX_AVATAR_SIZE}Ko.`}
                    </Typography>
                )
            }
        </Stack>
    );
};

export default UploadAvatar;
