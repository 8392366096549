import React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import confetti from 'canvas-confetti';
import { Box } from '@mui/material';
import cssStyles from './RealisticConfettiShotStyles';
import useTimeout from '../../hooks/useTimeout';

const RealisticConfettiShot: React.FC<{ delay?: number|undefined }> = props => {
    // Use of hooks
    const animationInstance = React.useRef<any>(null);

    // Callback used to create a confetti shot
    const makeShot = React.useCallback((particleRatio: number, options: confetti.Options) => {
        null !== animationInstance && animationInstance.current({
            ...options,
            origin: { y: 0.7 },
            particleCount: Math.floor(200 * particleRatio),
        });
    }, [animationInstance]);

    // Callback used to fire multiple shots to create a realistic confetti single shot
    const fire = React.useCallback(() => {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        makeShot(0.2, {
            spread: 60,
        });
        makeShot(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        });
        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });
        makeShot(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }, [makeShot]);

    // useTimeout when component has finished mounting
    useTimeout(() => {
        // Fire realistic shot
        null !== animationInstance && fire();
    }, props.delay ?? 0, [animationInstance, fire])

    return (
        <Box
            component={ReactCanvasConfetti}
            refConfetti={(instance: any) => animationInstance.current = instance}
            sx={cssStyles.confetti}
        />
    );
};

export default RealisticConfettiShot;
