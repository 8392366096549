import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { openModal } from '../../store/slices/activeModalSlice';
import {
    Chip,
    Typography,
    Stack,
    Button,
} from '@mui/material';
import ModalSingleInput from '../Modal/ModalSingleInput';

const KeywordsManagement: React.FC<{
    initialKeywords: Array<string>,
    onChange: (keywords: Array<string>) => void
}> = props => {
    // Use of hooks
    const [keywords, setKeywords] = React.useState<Array<string>>(props.initialKeywords);
    const modalName = React.useRef<string>('singleInputModalKeyword');

    // Use of redux
    const dispatch = useAppDispatch();

    // useEffect each time our hook value changes
    React.useEffect(() => {
        // Call given prop callback
        props.onChange(keywords);
    }, [props, keywords])

    return (
        <>
            <ModalSingleInput
                name={modalName.current}
                modalTitle='Nouvelle expression clé'
                inputLabel="Nom de l'expression clé"
                onValidation={value => setKeywords([ ...keywords, value ])}
            />
            {
                0 === keywords.length ? (
                    <Typography variant='body2'>
                        {`Aucune expression clé ...`}
                    </Typography>
                ) : (
                    <Stack direction='row' flexWrap='wrap' gap={1}>
                        {
                            keywords.map((value: string, index: number) => (
                                <Chip
                                    key={index}
                                    label={value}
                                    variant='outlined'
                                    onDelete={() => setKeywords(keywords.filter((_, key) => key !== index))}
                                />
                            ))
                        }
                    </Stack>
                )
            }
            <Button
                variant='outlined'
                onClick={() => dispatch(openModal({ name: modalName.current }))}
                sx={{ marginTop: 3 }}
            >
                {`Ajouter une expression clé`}
            </Button>
        </>
    );
};

export default KeywordsManagement;
