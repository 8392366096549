import React from 'react';
import { useLocation, Routes, Route as ReactRouterRoute } from 'react-router-dom';
import Route from '../Route/Route';
import { Box } from '@mui/material';
import Home from '../Home/Home';
import AccountMenu from '../AccountMenu/AccountMenu';
import UsersListing from '../UsersListing/UsersListing';
import UserProfile from '../UserProfile/UserProfile';
import ProfileForm from '../ProfileForm/ProfileForm';
import CompanyForm from '../CompanyForm/CompanyForm';
import PublicPlace from '../PublicPlace/PublicPlace';
import TweakAccountParams from '../TweakAccountParams/TweakAccountParams';
import AddTokens from '../AddTokens/AddTokens';
import SiteScheduleListing from '../SiteScheduleListing/SiteScheduleListing';
import ScheduledPosts from '../SiteScheduleListing/ScheduledPosts';
import SitesListing from '../SitesListing/SitesListing';
import ScheduleSitePosts from '../ScheduleSitePosts/ScheduleSitePosts';
import SiteForm from '../SiteForm/SiteForm';
import ProjectsListing from '../ProjectsListing/ProjectsListing';
import ProjectInformation from '../ProjectInformation/ProjectInformation';
import ProjectForm from '../ProjectForm/ProjectForm';
import ImportPostForm from '../ImportPostForm/ImportPostForm';
import OrdersListing from '../OrdersListing/OrdersListing';
import OrderTemplates from '../OrderTemplates/OrderTemplates';
import OrderForm from '../OrderForm/OrderForm';
import PostForm from '../PostForm/PostForm';
import PaymentForm from '../PaymentForm/PaymentForm';
import PaymentValidation from '../PaymentValidation/PaymentValidation';
import PaymentArchives from '../PaymentArchives/PaymentArchives';
import InvoicesListing from '../InvoicesListing/InvoicesListing';
import ContractsListing from '../ContractsListing/ContractsListing';
import PostMenu from '../PostMenu/PostMenu';
import PostsTodoListing from '../PostsTodoListing/PostsTodoListing';
import PostsUnverifiedListing from '../PostsUnverifiedListing/PostsUnverifiedListing';
import PostsRefusedListing from '../PostsRefusedListing/PostsRefusedListing';
import PostsArchivedListing from '../PostsArchivedListing/PostsArchivedListing';
import PostsProposedListing from '../PostsProposedListing/PostsProposedListing';
import PostsFailedListing from '../PostsFailedListing/PostsFailedListing';
import DocumentsMenu from '../DocumentsMenu/DocumentsMenu';
import PublishDraftPostStepper from '../PublishDraftPost/PublishDraftPostStepper';
import DraftPostsPublishedListing from '../DraftPostsPublishedListing/DraftPostsPublishedListing';
import DraftPostsListing from '../DraftPostsListing/DraftPostsListing';
import ToolsMenu from '../ToolsMenu/ToolsMenu';
import IndexingStatus from '../IndexingStatus/IndexingStatus';
import DuplicationScore from '../DuplicationScore/DuplicationScore';
import PostAiGeneration from '../PostAiGeneration/PostAiGeneration';
import RedactTool from '../RedactTool/RedactTool';
import CurrentRankingTool from '../CurrentRankingTool/CurrentRankingTool';
import TraductionTool from '../TranslationTool/TranslationTool';
import ParaphraseTool from '../ParaphraseTool/ParaphraseTool';
import WebsiteBuilding from '../WebsiteBuilding/WebsiteBuilding';
import ConfigureBacklinks from '../ConfigureBacklinks/ConfigureBacklinks';
import GenerateTitles from '../GenerateTitles/GenerateTitles';
import DevelopmentArea from '../DevelopmentArea/DevelopmentArea';
import NotFound from '../NotFound/NotFound';

const DashboardContent: React.FC = () => {
    // Use react-router-dom hooks
    const { pathname } = useLocation();

    // useEffect triggered only in dev env
    React.useEffect(() => {
        'dev' === process.env.REACT_APP_ENV &&
            // console.log on every render
            console.info(`Render new dashboard content [${pathname}]`);
    }, [pathname])

    return (
        <Box
            id='dashboardContent'
            component='main'
            width={1}
            height='100vh'
            overflow='hidden auto'
            sx={{ transition: 'filter ease-in-out .2s' }}
        >
            <Routes>
                {/* ***************************** HOME ***************************** */}
                <ReactRouterRoute
                    path=''
                    element={<Home />}
                />
                {/* ***************************** USERS ***************************** */}
                <ReactRouterRoute path='users'>
                    <ReactRouterRoute
                        path=''
                        element={<Route element={<UsersListing />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='profile'
                        element={<Route element={<UserProfile />} contained withNavigation />}
                    />
                </ReactRouterRoute>
                {/* *************************** ACCOUNT **************************** */}
                <ReactRouterRoute path='account'>
                    <ReactRouterRoute
                        path=''
                        element={<Route element={<AccountMenu />} contained withNavigation />}
                    />
                    <ReactRouterRoute path='user'>
                        <ReactRouterRoute
                            path=''
                            element={<Route element={<UserProfile />} contained withNavigation />}
                        />
                        <ReactRouterRoute
                            path='form'
                            element={<Route element={<ProfileForm />} contained withNavigation />}
                        />
                    </ReactRouterRoute>
                    <ReactRouterRoute
                        path='company'
                        element={<Route element={<CompanyForm />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='params'
                        element={<Route element={<TweakAccountParams />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='tokens'
                        element={<Route element={<AddTokens />} contained withNavigation />}
                    />
                    <ReactRouterRoute path='publication_schedule/*'>
                        <ReactRouterRoute
                            path=''
                            element={<Route element={<SiteScheduleListing />} contained withNavigation />}
                        />
                        <ReactRouterRoute
                            path='scheduled_posts'
                            element={<Route element={<ScheduledPosts />} contained withNavigation />}
                        />
                    </ReactRouterRoute>
                    <ReactRouterRoute path='sites/*'>
                        <ReactRouterRoute
                            path=''
                            element={<Route element={<SitesListing />} contained withNavigation />}
                        />
                        <ReactRouterRoute
                            path='schedule'
                            element={<Route element={<ScheduleSitePosts />} contained withNavigation />}
                        />
                        <ReactRouterRoute
                            path='form'
                            element={<Route element={<SiteForm />} contained withNavigation />}
                        />
                    </ReactRouterRoute>
                </ReactRouterRoute>
                {/* ************************** PUBLIC PLACE ************************** */}
                <ReactRouterRoute
                    path='public_place'
                    element={<Route element={<PublicPlace />} contained withNavigation />}
                />
                {/* ***************************** PROJECTS **************************** */}
                <ReactRouterRoute path='projects'>
                    <ReactRouterRoute
                        path=''
                        element={
                            <Route
                                element={<ProjectsListing />}
                                contained
                                withNavigation
                                allowScopes={['account_owner', 'can_make_order', 'can_consult_orders']}
                            />
                        }
                    />
                    <ReactRouterRoute path='information'>
                        <ReactRouterRoute
                            path=''
                            element={
                                <Route
                                    element={<ProjectInformation />}
                                    contained
                                    withNavigation
                                    allowScopes={['account_owner', 'can_make_order', 'can_consult_orders']}
                                />
                            }
                        />
                        <ReactRouterRoute
                            path='importation'
                            element={
                                <Route
                                    element={<ImportPostForm />}
                                    contained
                                    withNavigation
                                    allowScopes={['account_owner', 'can_make_order', 'can_consult_orders']}
                                />
                            }
                        />
                    </ReactRouterRoute>
                    <ReactRouterRoute
                        path='form'
                        element={
                            <Route
                                element={<ProjectForm />}
                                contained
                                withNavigation
                                allowScopes={['account_owner', 'can_make_order', 'can_consult_orders']}
                            />
                        }
                    />
                </ReactRouterRoute>
                {/* ***************************** ORDERS **************************** */}
                <ReactRouterRoute path='orders'>
                    <ReactRouterRoute
                        path=''
                        element={
                            <Route
                                element={<OrdersListing />}
                                contained
                                withNavigation
                                allowScopes={['account_owner', 'can_make_order', 'can_consult_orders']}
                            />
                        }
                    />
                    <ReactRouterRoute path='templates/*'>
                        <ReactRouterRoute
                            path=''
                            element={
                                <Route
                                    element={<OrderTemplates />}
                                    contained
                                    withNavigation
                                    allowScopes={['account_owner', 'can_make_order']}
                                />
                            }
                        />
                        <ReactRouterRoute
                            path='form'
                            element={
                                <Route
                                    element={<OrderForm />}
                                    contained
                                    withNavigation
                                    allowScopes={['account_owner', 'can_make_order']}
                                />
                            }
                        />
                    </ReactRouterRoute>
                </ReactRouterRoute>
                {/* ***************************** POSTS **************************** */}
                <ReactRouterRoute path='posts'>
                    <ReactRouterRoute
                        path=''
                        element={<Route element={<PostMenu />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='todo'
                        element={<Route element={<PostsTodoListing />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='unverified'
                        element={<Route element={<PostsUnverifiedListing />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='refused'
                        element={<Route element={<PostsRefusedListing />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='archived'
                        element={<Route element={<PostsArchivedListing />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='proposed'
                        element={<Route element={<PostsProposedListing />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='failed'
                        element={<Route element={<PostsFailedListing />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='form'
                        element={<Route element={<PostForm />} contained withNavigation />}
                    />
                </ReactRouterRoute>
                {/* *************************** INVOICES ************************** */}
                <ReactRouterRoute
                    path='invoices'
                    element={<Route element={<InvoicesListing />} contained withNavigation />}
                />
                {/* *************************** CONTRACTS ************************** */}
                <ReactRouterRoute
                    path='contracts'
                    element={<Route element={<ContractsListing />} contained withNavigation />}
                />
                {/* *************************** PAYMENT ****************************** */}
                <ReactRouterRoute path='payments'>
                    <ReactRouterRoute
                        path='validation'
                        element={<Route element={<PaymentValidation />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='archives'
                        element={<Route element={<PaymentArchives />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='form'
                        element={<Route element={<PaymentForm />} contained withNavigation />}
                    />
                </ReactRouterRoute>
                {/* *************************** DOCUMENTS ****************************** */}
                <ReactRouterRoute path='documents'>
                        <ReactRouterRoute
                            path=''
                            element={<Route element={<DocumentsMenu />} contained withNavigation />}
                        />
                        <ReactRouterRoute
                            path='draft_posts_listing'
                            element={<Route element={<DraftPostsListing />} contained withNavigation />}
                        />
                        <ReactRouterRoute
                            path='draft_posts_publish'
                            element={<Route element={<PublishDraftPostStepper />} contained withNavigation />}
                        />
                        <ReactRouterRoute
                            path='draft_posts_published'
                            element={<Route element={<DraftPostsPublishedListing />} contained withNavigation />}
                        />
                </ReactRouterRoute>
                {/* *************************** TOOLS ************************** */}
                <ReactRouterRoute path='tools'>
                    <ReactRouterRoute
                        path=''
                        element={<Route element={<ToolsMenu />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='redact_tool'
                        element={<Route element={<RedactTool />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='indexing_status'
                        element={<Route element={<IndexingStatus />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='duplicate_text'
                        element={<Route element={<DuplicationScore />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='ai_generation_post'
                        element={<Route element={<PostAiGeneration />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='current_ranking'
                        element={<Route element={<CurrentRankingTool />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='traduction_text'
                        element={<Route element={<TraductionTool />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='paraphrase_text'
                        element={<Route element={<ParaphraseTool />} contained withNavigation />}
                    />
                    <ReactRouterRoute
                        path='website_building'
                        element={
                            <Route
                                element={<WebsiteBuilding />}
                                contained
                                withNavigation
                                allowScopes={['manage_account_websites']}
                            />
                        }
                    />
                    <ReactRouterRoute
                        path='configure_backlinks'
                        element={
                            <Route
                                element={<ConfigureBacklinks />}
                                contained
                                withNavigation
                                allowScopes={['manage_account_websites']}
                            />
                        }
                    />
                    <ReactRouterRoute
                        path='generate_titles'
                        element={<Route element={<GenerateTitles />} contained withNavigation />}
                    />
                    {/* <ReactRouterRoute
                        path='csv_import'
                        element={<Route element={<ImportCsv />} contained withNavigation />}
                    /> */}
                </ReactRouterRoute>
                {/* ***************************** DEV AREA ***************************** */}
                <ReactRouterRoute
                    path='dev_area'
                    element={<Route element={<DevelopmentArea />} contained withNavigation />}
                />
                {/* ***************************** DEV LAB ***************************** */}
                <ReactRouterRoute
                    path='dev_lab'
                    element={<Route element={<>{/*Test stuff here*/}</>} contained withNavigation />}
                />
                {/** ******************** All others unknown routes ******************** */}
                <ReactRouterRoute
                    path='*'
                    element={<NotFound small helpButton={false} />}
                />
            </Routes>
        </Box>
    );
};

export default DashboardContent;
